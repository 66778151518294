import React from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import JobFormAccordionTab from '../../../../pieces/accordion/jobFormAccordionTab';
import HvacFormBlock from './hvacFromBlock';


const Mechanical = (props)=> {
  const classes = {...globalUseClasses()};
  const tabs = [
    {
      name: allConstants.JOB_TYPES.HVAC_RES.api_name,
      header: allConstants.JOB_TYPES.HVAC_RES.display,
      content: HvacFormBlock
    },
  ];

  return (
    <>
      {tabs.map((tab, idx)=> {
        return(
          <JobFormAccordionTab classes={classes}
            key={idx}
            header={tab.header}
            id={`${tab.name}-header`}
            aria_controls={`${tab.name}-content`}
            alwaysOpen={true}
          >{tab.content({...props, ...{nested: true, parent: allConstants.JOB_TYPES.HVAC_RES.api_name}})}</JobFormAccordionTab>
        );
      })}
    </>
  );
};

export default Mechanical;

import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import * as allConstants from '../../constants/allConstants';
import {INSPECTION_FIELDS} from '../../constants/allConstants';
import {Card} from '../../pieces/card';
import {FormValue} from '../../pieces/displayJob/formLine';
import Spacer from '../../pieces/spacer';
import {Text} from '../../pieces/text';
import SchedulingIcon from '../../static/images/schedulingIcon.svg';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {getStatusColor} from '../../styles/styleUtils';
import Utils from '../../utils/utils';
/**
 *
 * @param {string}hersDate
 * @return {string|null}
 * description: function is used to add 3 hours to hers dateTime and return time in format: HH:MM AM/PM - HH:MM AM/PM XXX
 * (where XXX is short timezone name). As for now, timezone for HERS is PST.
 */
export const hersScheduledDate = ( hersDate)=>{
  if(!hersDate) return null;
  let startTimeArray;
  try{
    const d = new Date(Utils.formatIsoDateString(hersDate));
    const minutes = d.getMinutes();
    const hours = d.getHours();
    startTimeArray = [hours< 0 ? `0${hours}` : hours.toString(), minutes< 10 ? `0${minutes}` : minutes.toString()];

  }catch(e){}
  const dateToShow = Utils.getFormattedScheduledDate({
    startDate: hersDate,
    startTime: startTimeArray ? `${startTimeArray[0]}:${startTimeArray[1]}` : '',
    duration: 3,
    timezone: {shortName: 'PST'}
  });
  return dateToShow;
};

export const ScheduledDatesWrapper = (props)=>{

  const {SCHEDULING_FIELDS} = allConstants;

  const testResults = (()=>{
    const tests = props?.inspectionData?.[INSPECTION_FIELDS.INSPECTION_RESULTS.api_name];
    if(!tests || tests.length === 0) return '';
    const latestTest = tests[tests.length - 1];
    const testDate = Utils.formatIsoDateString(latestTest?.[SCHEDULING_FIELDS.START_DATE.api_name],
      {date: true});
    const result = latestTest?.[INSPECTION_FIELDS.RESULT.api_name];
    const color = getStatusColor(result);

    return (<>
      <Text text={'Test result:'} weight={'bold'}/>
      <Spacer x={1}/>
      <FormValue value={`${result}`} color={color}/>
      <Spacer x={1}/>
      <FormValue value={`(${testDate})`}/>
    </>);

  })();
  // hersScheduledDate(props.HERSDate);

  return(
    <Box display={'flex'} flexDirection={'column'} sx={{border: `3px solid ${STYLE_CONSTANTS.COLORS.globalBlue}`, borderRadius: '12px'}}>
      <Card paddingLarge>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'}>
          <img src={SchedulingIcon} alt=""/>
          <Spacer x={1}/>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            {props.HERSDate &&
            <Box display={'flex'} flexDirection={'row'}>
              <Text text={'HERS Test scheduled:'} weight={'bold'}/>
              <Spacer x={1}/>
              <FormValue value={hersScheduledDate(props.HERSDate)}/>
            </Box>
            }
            {props?.inspectionData && props?.inspectionData?.[SCHEDULING_FIELDS.START_DATE.api_name] &&
              <>
                <Box display={'flex'} flexDirection={'row'}>
                  <Text text={'City Inspection Scheduled:'} weight={'bold'}/>
                  <Spacer x={1}/>
                  <FormValue value={Utils.getFormattedScheduledDate({
                    startDate: props?.inspectionData?.[SCHEDULING_FIELDS.START_DATE.api_name],
                    startTime: props?.inspectionData?.[SCHEDULING_FIELDS.START_TIME.api_name],
                    endTime: props?.inspectionData?.[SCHEDULING_FIELDS.END_TIME.api_name],
                    duration: 1,
                    timezone: props?.timezone
                  })}/>
                  {testResults && <>
                    <Spacer x={2}/>
                    {testResults}
                  </>}
                </Box>
              </>
            }
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

ScheduledDatesWrapper.propTypes = {
  HERSDate: PropTypes.string,
  inspectionData: PropTypes.shape({
    [allConstants.SCHEDULING_FIELDS.START_DATE.api_name]: PropTypes.string,
    [allConstants.SCHEDULING_FIELDS.START_TIME.api_name]: PropTypes.string,
    [allConstants.SCHEDULING_FIELDS.END_TIME.api_name]: PropTypes.string,
  }),
  timezone: PropTypes.shape({
    shortName: PropTypes.string
  })
};

ScheduledDatesWrapper.defaultProps = {
  HERSDate: null,
  inspectionData: null,
  timezone: null,
};

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApiUtils  from '../utils/apiUtils';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {TimelineOppositeContent} from '@mui/lab';
import Utils from '../utils/utils';
import {Box, Paper, Typography} from '@mui/material';
import Spacer from '../pieces/spacer';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Text} from '../pieces/text';
import {urlSearchParams} from '../baseUrls';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EastIcon from '@mui/icons-material/East';

const timelineActionTypes = {
  created: 'created',
  failedToSendNotification: 'failed_to_send_notification',
  notificationRequested: 'notification_requested',
  notificationSent: 'notification_sent',
  scheduled: 'scheduled',
};

const upperCaseExceptions = {
  'canDoEUC': 'Can Do EUC',
};

const fieldNameToDisplay = (fieldName)=> {
  const ignoreFields = [];
  if(!fieldName || ignoreFields.includes(fieldName)) {
    return null;
  }
  //split on dots
  const split = fieldName.split('.');
  const formatted = split.map((item, index) => {

    if(Object.keys(upperCaseExceptions).includes(item)) {
      return upperCaseExceptions[item];
    }
    //replace capital letter with space and lowercase
    return item.replace(/([A-Z])/g, ' $1').toLowerCase();
  });
  return formatted.join(' ');
};

const actionToDisplay = (action, recordType)=> {

  if(action.indexOf('_') === -1) {
    return `${Utils.capitalizeFirstLetter(recordType)} ${action}`;
  }

  const split = action.split('_');
  const formatted = split.map((item, index) => {
    if(index === 0 && item !== 'status') {
      return Utils.capitalizeFirstLetter(item);
    }
    //replace capital letter with space and lowercase
    return item.replace(/([A-Z])/g, ' $1').toLowerCase();
  });

  if(formatted[0]?.toLowerCase() === 'status') {
    //add record type to beginning
    formatted.unshift(Utils.capitalizeFirstLetter(recordType));
  }
  return formatted.join(' ');
};

export const getTimelineButton = (props)=> {
  const button = {
    name: 'timeline',
    onClick: () => {
      const urlParams = new URLSearchParams(location.search);
      if(props.showTimeline) {

        urlParams.delete(urlSearchParams.timeline);
      } else {
        urlParams.set(urlSearchParams.timeline, 'true');
        //update url with new search params
      }
      window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`);


      props.action();
      //add to url

    },
    icon: props.showTimeline ? <ReceiptLongIcon/> : <AccessTimeIcon/>
  };
  return button;
};

export const timelineTypes = {
  city:'city',
  rater: 'rater',
  inspection: 'inspection',
};

export const RecordTimeline = (props) => {

  const [timeline, setTimeline] = React.useState([]);
  const {recordType, recordId, timelineIds, timezone} = props;


  //function to get current timezone
  const getCurrentTimeZone = () => {
    return {
      id:  Intl.DateTimeFormat().resolvedOptions().timeZone,
      shortName:  new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]
    };
  };

  const currentTimeZone = timezone ? timezone : getCurrentTimeZone();

  useEffect(async () => {
    if(!recordType || !recordId) return;
    const moduleId = timelineIds?.[recordType] || recordId;

    const getTimeline = await ApiUtils.makeApiCall('GET', `timelines?recordType=${recordType}&recordId=${moduleId}&limit=100`);
    if(getTimeline?.data?.timelines) {
      const timeline = getTimeline.data.timelines.map((item) => {
        return {
          ...item, ...{
            adjustedCreatedAt: Utils.convertUtcToTimezone(item.createdAt, currentTimeZone.id),
            timeZone: currentTimeZone.shortName
          }
        };
      });

      const groupedTimeline = Utils.groupTimelineEvents(timeline);
      setTimeline(groupedTimeline);
    }

  }, []);

  const allPropertiesAreEmpty = (record) => {
    if(!record || typeof record !== 'object') return false;
    try{
      return Object.values(record).filter((value) => value.length > 0).length === 0;
    } catch(e) {
      return false;
    }
  };

  const displayFieldChange = (record, actionType) => {
    const alwaysDisplayActions = [
      timelineActionTypes.created,
      timelineActionTypes.failedToSendNotification,
      timelineActionTypes.notificationSent,
      timelineActionTypes.notificationRequested,
      timelineActionTypes.scheduled,
    ];
    if(actionType && alwaysDisplayActions.includes(actionType)) return true;
    if(!record || (record.type === 'ADDED' && (record.newValue === null || Utils.objectIsEmpty(record.newValue) || allPropertiesAreEmpty(record.newValue)))) {
      return false;
    }

    if(Array.isArray(record)) {
      return true;
    }

    if(isArrayFieldChange(record)) return true;
    return !((!record.previousValue && !record.newValue) || (record.previousValue === record.newValue));
  };

  const isDate = (date) => {
    if(!date || typeof date !== 'string') return false;
    //regexp to match 2023-06-14T16:29:22.722Z
    const regex = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2})T[0-9]{2}:[0-9]{2}:([0-9]{2})');
    return regex.test(date);
  };

  //check if it is time: hh:mm
  const isTime = (time) => {
    if(!time || typeof time !== 'string') return false;
    //regex to check if it is time
    const regex = new RegExp('([01]?[0-9]|2[0-3]):[0-5][0-9]');
    return regex.test(time);
  };

  const getFormattedDate = (date) => {
    return Utils.formatIsoDateString(date, {
      date:true,
      timeZone: currentTimeZone.id,
    });
  };

  const formatValueToDisplay = (value) => {
    if(isDate(value)) {
      return getFormattedDate(value);
    }
    if(isTime(value)) {
      return `${Utils.timeTo12HrFormat(value)} ${currentTimeZone.shortName}`;
    }
    return value;
  };

  const isArrayFieldChange = (record) => {
    const fields = Object.keys(record);
    if(!fields.length || fields.length ===0 ) return false;
    return fields.includes('added') || fields.includes('deleted');
  };

  const getFieldChangeDescription = (record) => {
    if(Array.isArray(record)) {

      return <Box  display={'flex'} flexDirection={'column'}>
        {record.map((item, index) => {
          return <Text key={`${index}-${JSON.stringify(item)}`} text={`${item.type?.toLowerCase()} ${item.newValue || item.previousValue}`} size={'large'} weight={'bold'}/>;
        })}
      </Box>;
    }
    //return string record
    if(typeof record === 'string') {
      return (
        <Box display={'flex'} flexDirection={'row'}>
          <Text text={record} size={'large'} weight={'bold'}/>
        </Box>
      );
    }
    //display changes in list fields
    if(isArrayFieldChange(record)) {

      return (
        <Box display={'flex'} flexDirection={'column'}>
          {Object.keys(record).map((item, index) => {
            const changedValues = record[item].join(', ');
            const icon = item === 'added' ? <AddIcon/> :
              <RemoveIcon />;
            return (
              <Box key={`${item}-${index}`} display={'flex'} alignItems={'center'} sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                  color: item === 'added' ? STYLE_CONSTANTS.COLORS.globalGreen : STYLE_CONSTANTS.COLORS.globalRed,
                }
              }}>
                {icon}
                <Spacer x={1}/>
                <Text key={`${index}-${JSON.stringify(item)}`} text={changedValues} size={'large'} weight={'bold'}/>
              </Box>
            );
          })}
        </Box>
      );
    }

    return (
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{
        '& .MuiSvgIcon-root': {
          fontSize: '1rem',
          color: STYLE_CONSTANTS.COLORS.globalGreen,
        }
      }}>
        <Spacer x={1}/>
        <Text text={displayValue(formatValueToDisplay(record.previousValue))} size={'large'} weight={'bold'}/>
        <Spacer x={1}/>
        <EastIcon size={'small'}/>
        <Spacer x={1}/>
        <Text text={displayValue(formatValueToDisplay(record.newValue))} size={'large'} weight={'bold'}/>
      </Box>
    );
  };
  const skipFields = [...props.ignoreFields, ...[
    '_id',
    'id',
    'createdBy',
    'createdAt',
    'updatedAt',
    'createdByUserId',
    'inspectionStatusChangeDate',

  ]];

  const displayValue = (f)=> {
    if(f && typeof f === 'object') {
      return displayObject(f);
    }
    return f ? f.toString() : 'empty';
  };

  const displayObject = (record) => {
    if(!record) return '';
    const fields = Object.keys(record);
    return (
      <Box display={'flex'} flexDirection={'column'}>
        {fields.map((field, index) => {
          if(skipFields.includes(field)) return null;
          if(!Utils.valueIsSpecified(record[field])) return null;
          return (
            <Box key={`${field}-${index}`} display={'flex'}>
              <Text size={'large'} color={STYLE_CONSTANTS.COLORS.linkColor}
                text={`${fieldNameToDisplay(field)}:`}
              />
              <Spacer x={1}/>
              <Text text={record[field].toString()} size={'large'} weight={'bold'}/>
            </Box>
          );
        })}
      </Box>
    );
  };

  const getUserName = (item) => {
    const createdBy = item?.createdByUser;
    if(!createdBy) return '';
    return `${createdBy?.firstName ?? ''} ${createdBy?.lastName?? ''}`;
  };

  if(!Object.keys(timeline).length) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
        height: '100%',
        width: '100%',
      }}>
        <Text text={'No timeline records'} size={'large'} />
      </Box>
    );
  }

  const displayDayChanges = (timeline)=> {
    return (
      timeline.map((item, index) => {
        return (
          <TimelineItem key={`${item.id}-${index}`}>
            <TimelineOppositeContent color="text.secondary" sx={{
              flex: 0.1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
            }}>
              <Typography component="span" sx={{minWidth: '100px'}}>
                {Utils.getTimeIn12hrFormat(item.adjustedCreatedAt, {
                  includeSeconds: false,
                })}
              </Typography>
            </TimelineOppositeContent>


            <TimelineSeparator >
              <TimelineConnector sx={{
                backgroundColor: STYLE_CONSTANTS.COLORS.globalBlueHover,
              }}/>
              <TimelineDot sx={{
                width: '14px',
                height: '14px',
                backgroundColor: STYLE_CONSTANTS.COLORS.globalGreenHover,
              }}/>
              <TimelineConnector sx={{
                backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
              }}/>
            </TimelineSeparator>
            <TimelineContent>
              <Spacer y={1}/>
              <Paper sx={{
                p: '8px 16px',
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  <Text size={'large'}
                    text={actionToDisplay(item.action, item.recordType)}
                  />
                  <Spacer x={1}/>
                  <Text italic size={'large'}
                    text={'by'}
                  />
                  <Spacer x={1}/>
                  <Text size={'large'}
                    text={getUserName(item)}
                  />
                </Box>
                {item.record && !Utils.objectIsEmpty(item.record) && <>
                  <Box>
                    {Object.entries(item.record).map(([key, value]) => {
                      const displayFieldName = fieldNameToDisplay(key);
                      if(!displayFieldChange(value, item.action)) return null;
                      if(item.action === timelineActionTypes.created) return (
                      //return created record fields
                        <Box key={key}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {displayObject(value)}
                        </Box>
                      );
                      return (
                        <Box key={key}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',

                          }}>
                          {displayFieldName && <>
                            <Text size={'large'} color={STYLE_CONSTANTS.COLORS.linkColor}
                              text={displayFieldName}
                            />
                            <Spacer x={1}/>
                          </>}
                          {getFieldChangeDescription(value)}

                        </Box>
                      );
                    })
                    }
                  </Box>
                </>}
                {(Utils.isDevEnv() || Utils.isStagingEnv()) && <>
                  <Text color={STYLE_CONSTANTS.COLORS.fontPale} text={`${item.createdAt} UTC`} size={'small'}/>
                </>
                }
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
      })
    );
  };


  return (
    <Timeline align={'left'} sx={{
      '&.MuiTimeline-root': {
        padding: '0px',
        margin: '0px',
      },
    }}>
      <Box display={'flex'} justifyContent={'center'} >
        <Text text={`All dates and times are shown in ${timezone ? 'record\'s' : 'your current'} timezone:`} size={'large'}/>
        <Spacer x={1}/>
        <Text text={`${currentTimeZone.id?.replace('_', ' ')} (${currentTimeZone.shortName})`} size={'large'} fontWeight={'bold'}/>
      </Box>
      {
        Object.entries(timeline).map(([date, timeline]) => {
          return (
            <Box key={date} display={'flex'} flexDirection={'column'}>
              <Box sx={{
                padding: '8px',
                backgroundColor: STYLE_CONSTANTS.COLORS.lightBlue,
                borderRadius: '16px',
                display: 'flex',
                width: 'fit-content',
                transform: 'translateX(100%)',
                marginY: '8px',
              }}>
                <Text text={date}/>
              </Box>

              {displayDayChanges(timeline, date)}
            </Box>
          );
        })
      }



    </Timeline>

  );
};

export const useTimeline = (props)=> {
  const {moduleName, recordId, timelineIds, timezone, ignoreFields} = props;
  const [showTimeline, setShowTimeline] = useState(Utils.getUrlParameter(urlSearchParams.timeline));
  const urlParams = new URLSearchParams(location.search);

  useEffect(()=> {
    return ()=> {
      if(urlParams.has(urlSearchParams.timeline) || showTimeline) {
        urlParams.delete(urlSearchParams.timeline);
        window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`);
      }
    };
  },[]);

  const timelineButton = ()=>getTimelineButton({
    action: ()=> {
      setShowTimeline(!showTimeline);
    },
    showTimeline,
  });

  return {
    showTimeline,
    setShowTimeline,
    timelineButton: timelineButton,
    timeline: ()=> {
      return <RecordTimeline recordType={moduleName}
        recordId={recordId}
        timelineIds={timelineIds}
        timezone={timezone}
        ignoreFields={ignoreFields}
      />;
    }
  };
};

RecordTimeline.propTypes = {
  recordType: PropTypes.string,
  recordId: PropTypes.string,
  timelineIds: PropTypes.instanceOf(Object),
  timezone: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shortName: PropTypes.string,
  }),
  ignoreFields: PropTypes.instanceOf(Array),
};

RecordTimeline.defaultProps = {
  recordType: null,
  recordId: null,
  timelineIds: {},
  timezone: null,
  ignoreFields: [],
};

import React from 'react';
import {Box, Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import PropTypes from 'prop-types';

const StyledTypography = withStyles( {
  root: {
    color: STYLE_CONSTANTS.COLORS.statusColor
  }
})(Typography);

const DotLegend = (props)=> {
  return (
    <Box display="flex" flexDirection={'row'} alignItems={'center'}>
      <Box sx={{
        borderRadius: '50%',
        height: '15px',
        width: '15px',
        marginRight: '8px',
        backgroundColor: props.color,
      }}/>
      <StyledTypography variant={'body1'}>{props.text}</StyledTypography>
    </Box>
  );
};

DotLegend.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default DotLegend;

const ROOF_TYPES = {
  COMP_SHINGLE: {display: 'Comp Shingle, Shake', value: 'Comp Shingle, Shake', showOrder: 0, def: false},
  TILE: {display: 'Tile', value: 'Tile', showOrder: 1, def: false},
  FLAT_BUR: {display: 'Flat (Bur) Gap or Rock', value: 'Flat (Bur) Gap or Rock', showOrder: 2, def: false},
  PVC: {display: 'PVC', value: 'PVC', showOrder: 3, def: false},
  VITUMEN: {display: 'Modified Vitumen', value: 'Modified Vitumen', showOrder: 4, def: false},
  TPO: {display: 'TPO', value: 'TPO', showOrder: 5, def: false},
  OTHER: {display: 'Other', value: 'Other', showOrder: 6, def: false},
};
const ROOF_ACTIONS = {
  COATING: {display: 'Coating', value: 'Coating', showOrder: 0, def: false},
  TEAR_OFF: {display: 'Tear Off', value: 'Tear Off', showOrder: 1, def: false},
  GO_OVER: {display: 'Go Over', value: 'Go Over', showOrder: 2, def: false},
};
const ROOF_DECK = {
  WOOD: {display: 'Wood', value: 'Wood', showOrder: 0, def: false},
  CONCRETE: {display: 'Concrete', value: 'Concrete', showOrder: 1, def: false},
  METAL: {display: 'Metal', value: 'Metal', showOrder: 2, def: false},
  OTHER: {display: 'Other', value: 'Other', showOrder: 3, def: false},
};
const TYPE_OF_SUBSTRATE= {
  PLYWOOD1_2: {display: '1/2in Plywood', value: '1/2in Plywood', showOrder: 0, def: false},
  OSB: {display: 'OSB', value: 'OSB', showOrder: 1, def: false},
  OTHER: {display: 'Other', value: 'Other', showOrder: 2, def: false},
};
const UNDERLAYMENT_MATERAIL = {
  FELT15: {display: 'Felt #15', value: 'Felt #15', showOrder: 0, def: false},
  DUAL_FELT15: {display: 'Dual Layer Felt #15', value: 'Dual Layer Felt #15', showOrder: 1, def: false},
  FELT30: {display: 'Felt #30', value: 'Felt #30', showOrder: 2, def: false},
  FELT40: {display: 'Felt #40', value: 'Felt #40', showOrder: 3, def: false},
  OTHER: {display: 'Other', value: 'Other', showOrder: 4, def: false},
};
const TILE_ROOF_REMOVED = {
  NEW: {display: 'New', value: 'New', showOrder: 0, def: false},
  SAVED: {display: 'Saved', value: 'Saved', showOrder: 1, def: false},
  USED: {display: 'Used', value: 'Used', showOrder: 2, def: false},
};
const WASTE_TYPE = {
  SELF_HUAL: {display: 'Self Hual', value: 'Self Hual', showOrder: 0, def: false},
  THIRD_PARTY_HUAL: {display: '3rd Party Hual', value: '3rd Party Hual', showOrder: 1, def: false},
};
const GARAGE_TYPE = {
  ATTACHED: {display: 'Attached', value: 'Attached', showOrder: 0, def: false},
  DEATTACHED: {display: 'Deattached', value: 'Deattached', showOrder: 1, def: false},
};

export {ROOF_TYPES, GARAGE_TYPE, ROOF_DECK, ROOF_ACTIONS, TILE_ROOF_REMOVED,
  TYPE_OF_SUBSTRATE, WASTE_TYPE, UNDERLAYMENT_MATERAIL
};

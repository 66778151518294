import React, {useEffect, useState, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Box, List, ListItemText, Paper, FormControl, FormHelperText, ListItemButton} from '@mui/material';
import usePlacesAutocomplete, {
  getGeocode, getZipCode
} from 'use-places-autocomplete';
import {CustomInput, FloatingLabel} from './customInputComponent';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {globalUseClasses} from '../../styles/globalClasses';
import {useLabelStyles} from './inputLabelStyles';
import {formBlockTypes} from './formBlockProps';
import {useHelperTextStyles} from '../../styles/myStyles';
import * as allConstants from '../../constants/allConstants';
import {useFormContext} from 'react-hook-form';


const AddressFrom = forwardRef((props, ref)=> {
  // console.log('AF:::', props);
  const classes = {...globalUseClasses(), ...useLabelStyles()};
  const suggestionRef = React.useRef();
  const [firstRender, setFirstRender] = useState(true);
  const initialAddress = {
    line1: '',
    line2: '',
    city: '',
    state:'',
    zipCode: '',
  };

  const formController = useFormContext();

  const [address, setAddress] = useState({...props.defaultValue});
  const {field_name} = props;
  const helperTextStyles = useHelperTextStyles();

  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 50,
  });

  useEffect(()=> {
    // if(firstRender) {
    if(props.defaultValue) {
      //console.log('Need to set default', props.defaultValue);
      formController.setValue(field_name, props.defaultValue);
      setValue(props.defaultValue?.line1, false);
    }
    setFirstRender(false);
    // }
  }, [props.defaultValue]);

  useOnClickOutside(suggestionRef, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    setAddress({...address, ...{
      line1: value
    }});
  });


  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value, true);
    if(e.target.value.length === 0) {
      setAddress({...initialAddress});
      formController.setValue(field_name, initialAddress, {shouldDirty: true, shouldValidate: !firstRender});
      // debouncedSave(e);
    } else {
      setAddress({...address, ...{line1: value}});
      // debouncedSave(e);
    }
  };

  const handleSelect = ({description, structured_formatting}) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      clearSuggestions();
      const newAddress = {
        line1: structured_formatting.main_text,
        line2: '',
        city: '',
        state:'',
        zipCode: '',
      };
      let results;
      // Get latitude and longitude via utility functions
      getGeocode({address: description})
        .then((res) => {
          results = res[0];
          // console.log('getting zip', res);

          return getZipCode(results, true);})
        .then((zipCode) => {
          newAddress.zipCode = zipCode||'';

        })
        .then(() => {
          const components = results.address_components;

          components.forEach((c)=> {
            const types = c.types;
            if(types.includes('locality') || types.includes('neighborhood')) {
              newAddress.city = c.short_name;
            }
            if(types.includes('administrative_area_level_1')) {
              newAddress.state = c.short_name;
            }
          });
          setValue(structured_formatting.main_text, false);
          setAddress(newAddress);
          //console.log('Set new address', newAddress);
          formController.setValue(field_name, newAddress, {shouldDirty: true, shouldValidate: !firstRender});

        })
        .catch((error) => {
          // console.log('😱 Error: ', error);
        });
    };

  const handleSecondaryFieldsUpdate = (e, field)=> {
    setAddress({...address, ...{[field]: e.target.value}});
    formController.setValue(field, e.target.value, {shouldDirty: true});
  };

  const renderSuggestions = () => {
    return data.map((suggestion, idx) => {
      const {
        place_id,
        structured_formatting: {main_text, secondary_text},
      } = suggestion;
      return (
        <ListItemButton key={place_id} onClick={handleSelect(suggestion)}>
          <ListItemText primary={`${main_text} ${secondary_text}`} />
        </ListItemButton>
      );
    });
  };

  const commonProps = {
    readOnly: props.readOnly,
    disabled: !ready || props.viewMode || !props.isEditable ,
    placeholder:'',
    autoComplete: 'new-password',
  };

  const renderErrorMessage = (fieldName) => {
    let message = '';
    const formErrors = props.isError;

    if(formErrors) {
      if(formErrors?.[fieldName]) {
        message = formErrors[fieldName]?.message;
      }
    }

    return (
      <FormHelperText classes={{
        root:helperTextStyles.root
      }}>
        {message}
      </FormHelperText>

    );
  };

  const isError = (fieldName)=>{
    // return errorMessage?.includes(fieldName);
  };

  return (
    <Box id={`${field_name}-address`} position={'relative'} ref={ref} width={'100%'}>
      <Box className={classes.two_col_container}
        sx={{
          gridTemplateColumns: '4fr 1fr !important',
          maxWidth: props?.maxWidth ? `${props.maxWidth} !important` : 'initial',
        }}
      >
        <FormControl variant="standard" fullWidth={true} className={classes.customDenseFromControl}
        >
          <FloatingLabel apiName={`${field_name}-line1`} required={props.field.required} label={'Street Address'}/>
          <CustomInput id={`${field_name}-line1`}
            {...commonProps}
            {...formController.register(`${field_name}.line1`)}
            // fullWidth={true}
            value={value ? value :''}
            onChange={handleInput}

            error={isError(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_LINE_1.api_name)}
          />
          {renderErrorMessage(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_LINE_1.api_name)}
        </FormControl>

        <FormControl variant="standard" fullWidth={true} className={classes.customDenseFromControl}
        >

          <FloatingLabel apiName={`${field_name}-line2`} required={false} label={'Suite/Apt'}/>
          <CustomInput id={`${field_name}-line2`} name={`${field_name}.line2`}
            {...commonProps}
            {...formController.register(`${field_name}.line2`)}
            //fullWidth={!props.queries.isSM & !props.queries.isXS}
            value={address.line2 ? address.line2 : ''}
            onChange={(e)=>handleSecondaryFieldsUpdate(e, 'line2')}
          />
          {renderErrorMessage(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_LINE_2.api_name)}
        </FormControl>
      </Box>

      <Box className={classes.three_col_container} sx={{
        maxWidth: props?.maxWidth ? `${props.maxWidth} !important` : 'initial',
      }}>
        <FormControl variant="standard" fullWidth={true} className={classes.customDenseFromControl}
        >


          <FloatingLabel apiName={`${field_name}-city`} required={props.field.required} label={'City'}/>
          <CustomInput id={`${field_name}-city` } name={`${field_name}.city`}
            {...formController.register(`${field_name}.city`)}
            {...commonProps}
            //fullWidth={!props.queries.isSM & !props.queries.isXS}
            value={address.city ? address.city : ''}
            onChange={(e)=>handleSecondaryFieldsUpdate(e, 'city')}
            error={isError(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_CITY.api_name)}
          />
          {renderErrorMessage(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_CITY.api_name)}
        </FormControl>
        <FormControl variant="standard" fullWidth={true} className={classes.customDenseFromControl}
        >
          <FloatingLabel apiName={`${field_name}-state`} required={props.field.required} label={'State'}/>
          <CustomInput id={`${field_name}-state`} name={`${field_name}.state`}
            {...commonProps}
            {...formController.register(`${field_name}.state`)}
            //fullWidth={!props.queries.isSM & !props.queries.isXS}
            value={address.state ? address.state : ''}
            onChange={(e)=>handleSecondaryFieldsUpdate(e, 'state')}
            error={isError(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_STATE.api_name)}
          />
          {renderErrorMessage(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_STATE.api_name)}
        </FormControl>
        <FormControl variant="standard" fullWidth={true} className={classes.customDenseFromControl}
        >
          <FloatingLabel apiName={`${field_name}-zipCode`} required={props.field.required} label={'Zip Code'}/>
          <CustomInput id={`${field_name}-zip`} name={`${field_name}.zipCode`}
            {...formController.register(`${field_name}.zipCode`)}
            value={address.zipCode ? address.zipCode : ''}
            onChange={(e)=>handleSecondaryFieldsUpdate(e, 'zipCode')}
            error={isError(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_ZIPCODE.api_name)}
            {...commonProps}
          />
          {renderErrorMessage(allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_ZIPCODE.api_name)}
        </FormControl>
      </Box>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <Paper style={{top: '-85px',position: 'relative', zIndex: 10}} ref={suggestionRef}>
        <List>{renderSuggestions()}</List>
      </Paper>
      }

    </Box>
  );
});



AddressFrom.displayName = 'AddressForm';
AddressFrom.propTypes = {...formBlockTypes, ...{
  field: PropTypes.shape({
    api_name: PropTypes.string.isRequired,
  }).isRequired,
  field_name: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
}

};
AddressFrom.defaultProps ={
  defaultValue: {line1: '', line2:'', city: '', state:'', zipCode:''},
  readOnly: true,
};
export default AddressFrom;

import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {useFormContext} from 'react-hook-form';

export const Sanitation = (props)=> {
  const classes = {...useCreateJobStyles()};
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const subParent = `${allConstants.JOB_FIELDS.PLUMBING.SANITATION.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.PLUMBING.api_name];

  const getFieldValue = (fieldName)=> {
    const blockValues = values?.[`${subParent}`];
    return blockValues?.[fieldName];
  };
  return <Box className={classes.tab_container} sx={{p: 3, pl: 0, columnGap: {xs: '10px', md: '20px'}, display: 'flex'}}>
    <CustomFormBlock{...props}
      field={FIELDS.SANITATION_LOCATION}
      nested={true}
      parent={blockParent}
      defaultValue={getFieldValue(FIELDS.SANITATION_LOCATION.api_name)}
      renderLabel={true}
    />
    <CustomFormBlock{...props}
      field={FIELDS.JURISDICTION}
      nested={true}
      parent={blockParent}
      defaultValue={getFieldValue(FIELDS.JURISDICTION.api_name)}
      renderLabel={true}
    />
  </Box>;
};
Sanitation.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default Sanitation;

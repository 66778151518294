import React, {useEffect, useState} from 'react';
import ApiUtils from '../../../utils/apiUtils';
import PropTypes from 'prop-types';
import {Box, TableContainer, Paper, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {Text} from '../../../pieces/text';
import Spacer from '../../../pieces/spacer';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {useForm, FormProvider} from 'react-hook-form';
import FormSubmitButtons from '../../../pieces/formSubmitButtons';
import {unwrapRequestResult} from '../../../utils/unwrapRequestResult';


export const UserNotificationsPanel = (props) => {
  /**
   * @description Sorts the email preferences into two groups: statuses and other options
   * @param emailPreferences
   * @return {{statuses: (*[]|undefined), otherOptions: *[]}}
   */
  const sortOptions = (emailPreferences) => {
    const statuses = [];
    let hasStatus = false;
    const otherOptions = [];
    emailPreferences.forEach((option)=> {
      if(option.group=== 'status') {
        hasStatus = true;
        statuses.push({...option});
      } else {
        otherOptions.push(option);
      }
    });
    return {statuses: hasStatus ? statuses : undefined, otherOptions: otherOptions};
  };

  const [emailNotificationOptions, setEmailNotificationOptions] = useState([]);
  const [emailPreferences, setEmailPreferences] = useState( []);

  useEffect(()=> {
    if(props?.userData?.emailPreferences) {
      setEmailPreferences(props?.userData?.emailPreferences);
    }
  }, [props?.userData]);

  useEffect(()=> {
    const other =[];
    emailNotificationOptions.forEach((module)=> {
      const {otherOptions} = sortOptions(module.emailPreferences);
      if(otherOptions) {
        otherOptions.forEach((opt)=> {
          other.push(opt.value);
        });
      }
    });
    // setUpdatedTogglers(other);
  }, [emailPreferences]);

  const getDefaultValues = ()=> {
    const defaultValues = {};
    emailNotificationOptions.forEach((ipermitModule)=> {
      defaultValues[ipermitModule.value] = {};
      const notifyStatuses = [];

      if(ipermitModule.statuses && ipermitModule.statuses.length > 0) {
        ipermitModule.statuses.forEach((status)=> {
          //check if user want to get notifications for this status
          if(emailPreferences?.includes(status.value)) {
            notifyStatuses.push({value: status.value, display: status.display});
          }
          //if module statuses to notify on is not empty, add it to default values
          if(notifyStatuses.length > 0) {
            defaultValues[ipermitModule.value].statuses = notifyStatuses;
            defaultValues[ipermitModule.value].notifyOnStatusChange = true;
          }
        });
      } else if(ipermitModule.otherOptions && ipermitModule.otherOptions.length > 0) {
        ipermitModule.otherOptions.forEach((option)=> {
          if(emailPreferences?.includes(option.value)) {
            defaultValues[ipermitModule.value][option.value.replace(/\./g, '_')] = true;
          }
        });
      }
    });
    return defaultValues;
  };

  const statusNotificationsForm = useForm();

  if(Utils.isDevEnv()) {
    window.statusNotificationsForm = statusNotificationsForm;
  }


  useEffect(async ()=> {
    //reformat options to separate status based notifications from other options
    const options = await ApiUtils.makeApiCall('GET', 'autocomplete/get-all-user-email-preference-options');
    const departmentOptions = options?.data?.departments || [];

    const emailOptions = [];
    departmentOptions.forEach((department)=> {
      const {statuses, otherOptions} = sortOptions(department.emailPreferences);
      const departmentObj = {...department};
      if(statuses) {
        departmentObj.statuses = statuses;
      }
      if(otherOptions) {
        departmentObj.otherOptions = otherOptions;
      }
      emailOptions.push(departmentObj);
    });

    setEmailNotificationOptions(emailOptions);
  },[]);

  const inputDefaultProps = {
    viewMode: props.viewMode,
    renderLabel: true,
    isEditable: true,
    fullWidth: true,
  };

  const displayOtherOptions = (entry) => {
    const {otherOptions} = entry;
    const moduleName = entry.value;
    const formValues = getDefaultValues();
    return otherOptions.map((preference, index)=> {
      const reformattedApiName = preference.value.replace(/\./g, '_');
      const isChecked = formValues?.[moduleName]?.[reformattedApiName];
      return (
        <TableRow
          key={preference.display + index + isChecked?.toString()}
          sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
          <TableCell component="th" scope="row" style={{width: 300}}>
            {preference.display}
          </TableCell>
          <TableCell align="left" width={150}>
            <CustomFormBlock {...inputDefaultProps}
              field={{
                api_name: reformattedApiName,
                label: '',
                required: false,
                display_label:'',

              }}
              nested={true}
              key={`${reformattedApiName} ${props.viewMode}`}
              parent = {moduleName}
              inputType={inputTypes.switch}
              defaultValue={isChecked}
              renderLabel={false}/>
          </TableCell>
          <TableCell/>
        </TableRow>
      );
    });
  };

  const displayStatusOptions = (entry, component) => {
    // return null
    const {statuses} = entry;
    const moduleName = entry.value;
    if(!statuses || !moduleName) {return null;}
    const mapValueToName = {};

    const options = statuses.map((status, index)=> {
      mapValueToName[status.value] = status.display;
      return {display: status.display?.toUpperCase(), value: status.value, showOrder: index};
    });

    const selectedStatuses = [];
    const defaultValues = getDefaultValues();
    if(defaultValues?.[moduleName]?.statuses) {
      defaultValues?.[moduleName]?.statuses.forEach((status)=> {
        selectedStatuses.push(status.value);
      });
    }

    const switchChecked = statusNotificationsForm.getValues()?.[moduleName]?.notifyOnStatusChange;

    return (
      <TableRow
        key={moduleName + 'status' + JSON.stringify(selectedStatuses)}
        sx={{'&:last-child td, &:last-child th': {border: 0}}}
      >
        <TableCell component="th" scope="row" style={{width: 300}}>
          {'Status changed to:'}
        </TableCell>
        <TableCell align="left" width={150}>
          <CustomFormBlock  {...inputDefaultProps}
            field={{
              api_name: `${entry.value}.notifyOnStatusChange`,
              label: null,
              required: false,
              display_label:null,
            }}
            key={`${entry.value}.notifyOnStatusChange ${props.viewMode}`}
            inputType={inputTypes.switch}
            defaultValue={selectedStatuses.length > 0}
          />
        </TableCell>
        <TableCell sx={{
          '& .MuiSelect-multiple':{
            width: '100% !important'
          },
          '& .MuiFormControl-root': {
            maxWidth: '100%',
            visibility: switchChecked ? 'visible' : 'hidden',
          }
        }}>
          <CustomFormBlock
            {...inputDefaultProps}
            field={{
              api_name: `${entry.value}.statuses`,
              label: 'Status',
              required: false,
              display_label:''
            }}
            inputType={inputTypes.multiSelect}
            options={Utils.getOptions(options)}
            defaultValue={selectedStatuses}
            getDisplayValue={(statusValue)=> {
              return mapValueToName?.[statusValue] || statusValue;
            }}
            renderLabel={false}
          />
        </TableCell>
      </TableRow>
    );
  };

  const onSubmit = async (data) => {
    const notificationsArray = [];
    /* create array of notifications to send to backend */
    Object.entries(data).forEach(([key, value])=> {
      const notifyChecked = value?.notifyOnStatusChange;
      if (notifyChecked) {
        const statuses = value?.statuses;
        if (statuses || statuses.length > 0) {
          statuses.forEach((status) => {
            notificationsArray.push(status);
          });
        }
      }
      Object.keys(value).forEach((key) => {
        //if key contains _
        if (key.includes('_') && value[key]) {
          notificationsArray.push(key.replace(/_/g, '.'));
        }
      });
    });

    const submitData = {
      userId: props?.userData?.id,
      emailPreferences: notificationsArray
    };

    const response = await ApiUtils.makeApiCall('POST', 'users/set-email-preferences', JSON.stringify(submitData));
    if(response){
      unwrapRequestResult({
        showSuccess: true,
        result: response ,
        successMessage:  'Notification preferences updated successfully',
        id: 'edit_user_notifications',
      });
    }
    if(response?.data?.user && response?.data?.user?.emailPreferences){
      setEmailPreferences(response?.data?.user?.emailPreferences);
      props.setViewMode(true);
    }
  };

  return (
    <>
      <FormProvider {...statusNotificationsForm}>
        <form onSubmit={statusNotificationsForm.handleSubmit(onSubmit)}>
          <Spacer y={2} />
          <Text text={'Notify me by email on following changes:'} fontWeight={'bold'} size={'large'} color={STYLE_CONSTANTS.COLORS.globalBlue}/>
          <Spacer y={2} />
          {emailNotificationOptions.map((entry, index)=> {
            return (
              <Box key={index+entry.display}
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                <Text text={entry.display} fontWeight={'bold'} size={'xLarge'}/>
                <Spacer y={1} />
                <TableContainer component={Paper}>
                  <Table sx={{minWidth: 650}} aria-label="settings table" size="small">
                    <TableBody>
                      {
                        displayStatusOptions(entry)
                      }
                      {
                        displayOtherOptions(entry)
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <Spacer y={2} />
              </Box>
            );
          })}
          {!props.viewMode && <FormSubmitButtons cancelAction={()=> {
            props.setViewMode(true);

          }}/>}

        </form>
      </FormProvider>
    </>
  );
};

UserNotificationsPanel.propTypes = {
  viewMode: PropTypes.bool,
  setViewMode: PropTypes.func,
  userData: PropTypes.shape({
    id: PropTypes.string,
    emailPreferences: PropTypes.instanceOf(Array),
  }),
};

UserNotificationsPanel.defaultProps = {
  viewMode: true,
  setViewMode: ()=> {},
  userData: {
    id: '',
    emailPreferences: [],
  },
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';

export const inviteCompany = createAsyncThunk(
  'companies/invite',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}companies/invite`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(props),
        }
      );
      let data = await response.json();
      // console.log('getCurrentUserData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const inviteCompanyReducer = {
  [inviteCompany.fulfilled]: (state, {payload}) => {
    state.isFetching = false;
    state.isSuccess = true;
  },
  [inviteCompany.pending]: (state, data) => {
    state.isFetching = true;
  },
  [inviteCompany.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

// export const reinviteCompany = createAsyncThunk(
//   'users/re-invite-user',
//   async ( props, thunkAPI) => {
//     try {
//       const authToken = await ApiUtils.getAccessToken();
//       if(!authToken) {
//         return thunkAPI.rejectWithValue('AUTH_ERROR');
//       }
//
//       const response = await fetch(
//         `${base_url.api}users/re-invite-user`,
//         {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: authToken,
//           },
//           body: JSON.stringify(props),
//         }
//       );
//       let data = await response.json();
//       // console.log('getCurrentUserData response',response, response.status === 200);
//       if (response.status === 200) {
//         return {...data.data};
//       } else {
//         console.log('Rejected with', data);
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e) {
//       console.log('Error', e.response.data);
//       return thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );
//
// export const reinviteUserReducer = {
//   [reinviteUser.fulfilled]: (state, {payload}) => {
//     state.isFetching = false;
//     state.isSuccess = true;
//   },
//   [reinviteUser.pending]: (state, data) => {
//     state.isFetching = true;
//   },
//   [reinviteUser.rejected]: (state, {payload}) => {
//     state.isFetching = false;
//     state.isError = true;
//     state.errorMessage = payload?.message;
//   },
// };

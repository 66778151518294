const ELEMENTS_INSPECTIONS = {
  viewInspections: 'viewInspections',
  manageInspection: 'manageInspection',
  requestInspection: 'requestInspection',
  scheduleInspection: 'scheduleInspection',
};

const inspectionPolicies = {
  manage: 'inspection.manage',
  requestInspection: 'job.requestInspection'
};

const inspectionRules = Object.freeze({
  [ELEMENTS_INSPECTIONS.scheduleInspection]: {
    policyName: undefined,
  },
  [ELEMENTS_INSPECTIONS.viewInspections]: {
    policyName: undefined,
  },
  [ELEMENTS_INSPECTIONS.manageInspection]: {
    policyName: inspectionPolicies.manage,
  },
  [ELEMENTS_INSPECTIONS.requestInspection]: {
    policyName: inspectionPolicies.requestInspection,
  },
});

export {inspectionRules, ELEMENTS_INSPECTIONS};

const DISPLAY_JOB_TABS = {
  NOTES: {name: 'notes', label: 'Notes'},
  ATTACHMENTS: {name: 'attachments', label: 'Attachments'},
  PERMIT_INFO: {name: 'permit_info', label: 'permit info'},
  CALL_LOGS: {name: 'call_logs', label: 'Call Logs'},
  JOB_DETAILS: {name:'job_details', label: 'Job Details'},
  GENERAL_INFO: {name: 'general_info', label: 'General Info'},
  MAP: {name: 'map', label: 'Map'},
  INSPECTIONS: {name: 'inspection', label: 'City Inspection'},
};

export {DISPLAY_JOB_TABS};

/* eslint-disable */
import React from 'react';
import * as allConstants from '../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../styles/styleConstants'
import {Button, Box, Table, TableCell, TableBody, TableRow} from '@mui/material';
import * as ReactDOMServer from 'react-dom/server';
import base_url from '../../baseUrls';
import ApiUtils from '../../utils/apiUtils';
import axios from 'axios';
import Utils from '../../utils/utils';
import {Text, TextWithIconWrapper} from '../../pieces/text';
import { ReactComponent as AttachmentIconSvg} from '../../static/images/attachmentIcon.svg';
import {Card} from '../../pieces/card';
import {NoRecords} from '../../pieces/noRecords';
import Spacer from '../../pieces/spacer';
import PropTypes from 'prop-types';

const {CITY_FIELDS} = allConstants;

const ExpandableDetails = (props)=> {
  const {CITY_FIELDS} = allConstants;
  const docs = props?.data?.[CITY_FIELDS.DOCUMENTS.api_name];
  const cityData = props?.data;

  //const howToScheduleInfo = props?.data?.[allConstants.CITY_FIELDS.SETTING_INSPECTION_RULES.api_name];

  const docsTable = (()=> {
    const jobTypes = Object.values(allConstants.JOB_TYPES).map((type)=> type.value);
    const table = new Map();
    table.set(allConstants.ALL_JOBS_TYPE.value, []);
    jobTypes.forEach((jobType)=> {

      table.set(jobType,  []);
    });
    docs.forEach((doc)=> {
      let docJobTypes = [...doc?.[allConstants.JOB_FIELDS.JOB_TYPE.api_name]];
      if(docJobTypes.includes(allConstants.ALL_JOBS_TYPE.value)) {
        table.set(allConstants.ALL_JOBS_TYPE.value, table.get(allConstants.ALL_JOBS_TYPE.value).concat([doc]));
      } else {
        docJobTypes.forEach((jobType)=> {
          table.set(jobType, table.get(jobType).concat([doc]));
        });
      }
    });

    const tableRows = [];
    for (let [key, value] of  table.entries()) {
      if (value && value.length > 0) {
        tableRows.push({name: key, docs: value});
      }
    }
    return tableRows;
  })();

  const waysToScheduleTable = (()=> {
    const table = [];
    const waysToSchedule = cityData?.[CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name];

    if(!waysToSchedule) {return undefined}

    const optionDisplayNames = {}
      Object.values(allConstants.SCHEDULE_INSPECTION_OPTIONS).forEach((option)=> {
        optionDisplayNames[option.value] = option.display;
      });



    //iter object entries
    for (const [key, value] of Object.entries(waysToSchedule)) {
      table.push({name: optionDisplayNames[key], value: value});
    }
   return table;
  })();

  return (
    <Box sx={{
      padding:{xs: '14px', md: '24px'}
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',

      }}>
        <Box sx={{
          '& .MuiTypography-root': {
            textTransform: 'uppercase',
            fontSize: '14px !important',
          }
        }}>
          <Card boxShadow={true} header={
            <Text text={'How to schedule city inspection'} weight={'bold'}/>

          }>
            {waysToScheduleTable && <Table sx={{minWidth: 650}} aria-label="ways to schedule inspection table">
              <TableBody>
                {waysToScheduleTable.map((row) => (
                  <TableRow key={row.name}
                            sx={{
                              '&:last-child td, &:last-child th': {border: 0},
                              '& .MuiTableCell-root': {
                                paddingY: '4px'
                              }
                            }}
                  >
                    <TableCell component="th" scope="row" sx={{width: '120px', textTransform: 'none !important', fontWeight: '600'}}>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </Table>
              }
            {(!waysToScheduleTable || waysToScheduleTable.length === 0) && <NoRecords recordName={'data'} rowDirection={true}/>}
          </Card>
          <Spacer y={2}/>
          <Card boxShadow={true}
          header={
          <TextWithIconWrapper icon={<AttachmentIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
          <Text text={'City Required documents'} weight={'bold'}/>
           </TextWithIconWrapper>
          }
          >
            {docsTable && docsTable.length > 0 && <Table sx={{minWidth: 650}} aria-label="city documents table">
              <TableBody>
                {docsTable.map((row) => (
                  <TableRow key={row.name}
                    sx={{
                      '&:last-child td, &:last-child th': {border: 0},
                      '& .MuiTableCell-root': {
                        paddingY: '4px'
                      }
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{width: '120px', textTransform: 'none !important', fontWeight: '600'}}>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      <Box display={'grid'}
                        sx={{
                          gridTemplateColumns: {xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'},
                        }}>
                        {row.docs.map((doc, index)=> {
                          return (
                            <Button key={index + doc.fileId} className={'city-doc'}
                              disableRipple sx={{padding: '0px', justifyContent: 'left'}}
                              value={doc.fileId}
                            >{Utils.trimString(doc.name, 30)}</Button>
                          );
                        } )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
            {!docsTable || docsTable.length === 0 && <NoRecords recordName={'City Required Documents'} rowDirection={true}/>}
          </Card>

        </Box>


      </Box>

    </Box>
  );
};



class ExpandableDetailsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.data = props.data;
  }

  render() {
    return (
      <div>
        <ExpandableDetails data={this.data}/>
      </div>

    );
  }
}
ExpandableDetailsComponent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export class DetailCellRenderer {
  init(params) {
    this.element = ReactDOMServer.renderToStaticMarkup(<ExpandableDetailsComponent data={params.data}/>);
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = this.element;
    this.eButtonDoc = this.eGui.querySelectorAll('.city-doc');
    this.documentDownload = (e) => {const fetch = async () => {
      let url = `${base_url.api}files/${e.target.value}`;
      const authToken = await ApiUtils.getAccessToken();
      const data = await axios.get(url, {headers: {Authorization: authToken}, responseType: 'blob'});
      return data;
    };

    fetch()
      .then((response) => {
        const fileName = Utils.getContentDispositionFileName(response);
        const blob = new Blob([response.data], {type: 'blob'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log('Download file error: ', e);
        Utils.errorToastMessage('Please try again', 'get_files');
      });};
    this.eButtonDoc.forEach((btn)=> btn.addEventListener('click', this.documentDownload));
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as allConstants from '../../../../constants/allConstants';
import actions from '../../../../redux/actions';
import mechanicalUtils from '../mechanical/mechUtils';
import {useForm} from 'react-hook-form';
import {Box, Grid, Typography} from '@mui/material';
import TabsBlock from '../../../../pieces/tabsBlock';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import clsx from 'clsx';
import {useCreateJobStyles} from '../createJobStyles';
import EquipmentPanelWrapper from '../equipmentPanelWrapper';


const TempPowerEquipment = (props)=> {
  const dispatch = useDispatch();
  const classes = {...useCreateJobStyles()};
  const JOB_TYPES = allConstants.JOB_TYPES;

  const subforms = useSelector(state => state.subforms);
  const TEMP_POWER_FIELDS = allConstants.JOB_FIELDS.TEMPORARY_POWER;
  const SHARED_FIELDS = allConstants.JOB_FIELDS.SHARED;

  useEffect(()=> {
    saveSubform(allConstants.JOB_TYPES.TEMPORARY_POWER.api_name, formMethods);
  }, []);


  useEffect(()=> {
    return ()=> {
      const currValues = formMethods.getValues();
      dispatch(actions.addSubform({jobType: allConstants.JOB_TYPES.TEMPORARY_POWER.api_name,
        subformName: allConstants.JOB_FIELDS.TEMP_POWER_SYSTEM.api_name, subform: currValues}));
    };

  },[]);

  const polesFields = [SHARED_FIELDS.QUANTITY, allConstants.JOB_FIELDS.ELECTRICAL.AMPS, TEMP_POWER_FIELDS.CIRCUITS];
  const transformersFields = [allConstants.JOB_FIELDS.SHARED.QUANTITY, TEMP_POWER_FIELDS.KVA];
  const generatorsFields = [allConstants.JOB_FIELDS.SHARED.QUANTITY, allConstants.JOB_FIELDS.ELECTRICAL.KW];

  const getFields = (fieldArr, extraClasses=clsx(classes.small_input_box))=> {
    return (
      fieldArr.map((f)=> {
        return {
          wrapperClass: extraClasses,
          field: f,
          uniqueProps: {}
        };
      })
    );
  };

  const equipmentMap = {
    [allConstants.EQUIPMENT.TEMP_POWER_POLES.api_name]:
      {
        name: allConstants.EQUIPMENT.TEMP_POWER_POLES.api_name,
        fields: getFields(polesFields)
      },

    [allConstants.EQUIPMENT.SUB_POLES.api_name]:
      {
        name: allConstants.EQUIPMENT.SUB_POLES.api_name,
        fields: getFields(polesFields)
      },

    [allConstants.EQUIPMENT.SUB_DISTRO_PANELS.api_name]:
      {
        name: allConstants.EQUIPMENT.SUB_DISTRO_PANELS.api_name,
        fields: getFields(polesFields)
      },

    [allConstants.EQUIPMENT.TRANSFORMERS.api_name]:
      {
        name: allConstants.EQUIPMENT.TRANSFORMERS.api_name,
        fields: getFields(transformersFields)
      },

    [allConstants.EQUIPMENT.GENERATORS.api_name]:
      {
        name: allConstants.EQUIPMENT.GENERATORS.api_name,
        fields: getFields(generatorsFields)
      },
  };

  const mapPanelsToEq = {
    [TEMP_POWER_FIELDS.TEMP_POWER_POLES.api_name]: [allConstants.EQUIPMENT.TEMP_POWER_POLES.api_name],
    [TEMP_POWER_FIELDS.SUB_POLES.api_name]:[allConstants.EQUIPMENT.SUB_POLES.api_name],
    [TEMP_POWER_FIELDS.SUB_DISTRO_PANELS.api_name]: [allConstants.EQUIPMENT.SUB_DISTRO_PANELS.api_name],
    [TEMP_POWER_FIELDS.TRANSFORMERS.api_name]: [allConstants.EQUIPMENT.TRANSFORMERS.api_name],
    [TEMP_POWER_FIELDS.GENERATORS.api_name]: [allConstants.EQUIPMENT.GENERATORS.api_name],
  };

  const fields = [
    TEMP_POWER_FIELDS.TEMP_POWER_POLES, TEMP_POWER_FIELDS.SUB_POLES,
    TEMP_POWER_FIELDS.SUB_DISTRO_PANELS,
    TEMP_POWER_FIELDS.TRANSFORMERS, TEMP_POWER_FIELDS.GENERATORS
  ];

  const saveSubform = useMemo(()=> props.setEquipmentForm, []);

  const def = useMemo(()=>  mechanicalUtils.getSystemFormOptions(equipmentMap));

  const formOptions = {...def};

  const {updatedDefault} = mechanicalUtils.updateSystemDefaultValues(allConstants.JOB_FIELDS.TEMP_POWER_SYSTEM.api_name,
    allConstants.SYSTEM_CONTENT[allConstants.JOB_FIELDS.TEMP_POWER_SYSTEM.api_name], subforms,
    formOptions.defaultValues, JOB_TYPES.TEMPORARY_POWER.api_name);

  formOptions.defaultValues = {...updatedDefault};
  const shouldShowCircuitsMessage = (eqName)=> {
    const show = [allConstants.EQUIPMENT.TEMP_POWER_POLES.api_name,
      allConstants.EQUIPMENT.SUB_POLES.api_name,
      allConstants.EQUIPMENT.SUB_DISTRO_PANELS.api_name
    ];
    return show.includes(eqName);
  };

  const isEquipmentTabDisabled = (eqName)=> {
    if(props.canModifyEquipment) return false;
    try{
      const equipment = {...updatedDefault[eqName][0].fields};
      if(equipment.length > 1) return false;
      const eqValues = Object.values(equipment[0]).filter((v)=> v);
      return eqValues.length === 0;
    } catch(e){}
    return false;
  };

  const formMethods = useForm(formOptions);

  window.tempPower_form = formMethods;
  const inputDefaultProps = mechanicalUtils.getSystemInputDefaultProps(formMethods);

  const content = fields.map((f)=> {
    return {
      name: f.api_name,
      label: f.label,
      isDisabled: isEquipmentTabDisabled(f.api_name),
      panel: ()=>  <EquipmentPanelWrapper {...props}
        formMethods={formMethods}
        inputs={Object.values(equipmentMap).map((piece)=> {
          return Object.values(piece.fields);
        })}
        eqName={f.api_name}
        addFields={def.defaultValues?.[mapPanelsToEq[f.api_name]][0]?.fields[0]}
        inputDefaultProps={inputDefaultProps}
        equipmentMap={equipmentMap}
        canModifyEquipment={props.canModifyEquipment}
      >
        {shouldShowCircuitsMessage(f.api_name) && <Box mb={2} width={'100%'}>
          <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>
            ***Please separate individual Circuit values by comma, like so: 10, 20, 30***
          </Typography>
        </Box>}
      </EquipmentPanelWrapper>
    };
  });

  return (
    <Grid item xs={12} md={10} sx={{maxWidth: '840px'}}>
      {!props.canModifyEquipment && <Box mt={2}>
        <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalRed}}>You can&apos;t change selected equipment for job submitted from Service Titan</Typography>
      </Box>}
      <TabsBlock content={content} {...inputDefaultProps}/>
    </Grid>
  );
};

TempPowerEquipment.propTypes = {
  draftData: PropTypes.instanceOf(Object),
  setEquipmentForm: PropTypes.func.isRequired,
  canModifyEquipment: PropTypes.bool,
};

TempPowerEquipment.defaultProps = {
  canModifyEquipment: true,
};

export default TempPowerEquipment;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {Typography, Box} from '@mui/material';
import {Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useDatePickerStyles = makeStyles({
  date_picker_box: {
    borderRadius: '10px',
    position: 'absolute',
    zIndex: 1000,
    right: 0,
    '& .MuiCalendarPicker-viewTransitionContainer': {
      overflowY: 'hidden'
    }
  },
  date_picker: {
    '&::-webkit-scrollbar': {
      width: '0.2em',
      height:'0.5em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
      borderRadius: '4px'
    },
    maxHeight: '250px',
    background: STYLE_CONSTANTS.COLORS.backgroundMain,

  }
});

export default function MonthYearPicker(props) {
  const classes = useDatePickerStyles();
  const [value, setValue] = useState(props.defaultDate ? props.defaultDate : new Date());
  const [open, setOpen] = useState(false);

  const openHandler = ()=> {
    if(open) {
      submitHandler();
    } else {
      setOpen(true);
    }
  };

  const cancelHandler = ()=> {
    setOpen(false);
  };

  const submitHandler = ()=> {
    props.handler(value);
    setOpen(false);
  };

  const getDateText = () => {
    if (props.views.includes('month')) {
      const monthName = value.toLocaleString('default', {month: 'long'});
      return `${monthName}, ${value.getFullYear()}`;
    } else {
      return value.getFullYear();
    }
  };

  return (
    <>
      <Box position={'relative'}>
        <Button
          sx={{
            backgroundColor: STYLE_CONSTANTS.COLORS.chartBlue,
            color: STYLE_CONSTANTS.COLORS.fontDarkBlue,
            boxShadow: 'none',
            ':hover': {
              backgroundColor: STYLE_CONSTANTS.COLORS.chartBlue,
            }
          }}
          variant='contained'
          onClick={openHandler}>
          <Typography fontWeight={400} fontSize={'16px'} pr={1}>
            {getDateText()}

          </Typography>
          <KeyboardArrowDownIcon />
        </Button>
        {open && <Box className={classes.date_picker_box}>
          <LocalizationProvider dateAdapter={AdapterDateFns} >
            <StaticDatePicker classes={{root: classes.date_picker}}
              displayStaticWrapperAs="desktop"
              openTo={'year'}
              showDaysOutsideCurrentMonth
              value={value}
              views={props.views}
              orientation={'portrait'}
              minDate={new Date('2010-01-01')}
              maxDate={new Date('2050-01-01')}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Box sx={{backgroundColor:STYLE_CONSTANTS.COLORS.backgroundMain,
              borderTop: `1px solid ${STYLE_CONSTANTS.COLORS.globalBlue}`
            }} py={1}
            bottom={20} left={0} right={0} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
              <Button onClick={cancelHandler}
                sx={{color: STYLE_CONSTANTS.COLORS.globalRed, cursor: 'pointer'}}>Cancel</Button>
              <Button onClick={submitHandler}
                sx={{color: STYLE_CONSTANTS.COLORS.globalBlue, cursor: 'pointer', fontWeight:600}}>Ok</Button>
            </Box>
          </LocalizationProvider>


        </Box>}
      </Box>
    </>

  );
}

MonthYearPicker.propTypes = {
  handler: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(String),
  defaultDate: PropTypes.objectOf(Date),
};

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Typography} from '@mui/material';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CustomFormBlock from '../inputs/formBlock';
import * as allConstants from '../../constants/allConstants';
import {inputTypes} from '../inputs/formInput';
import {useCreateJobStyles} from '../../pages/jobs/createJob/createJobStyles';


const DistRow = ({label, inputProps, defaultValues})=> {
  return(<Box sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',

    '& .MuiFormControl-root': {
      width:'70px'
    }
  }}>
    <Typography sx={{
      width: '130px',
      marginRight: '16px',
    }}>{`${label}`}</Typography>
    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
      <Typography sx={{margin: '0 4px'}}>ft:</Typography>
      <CustomFormBlock {...inputProps}

        field={allConstants.JOB_FIELDS.SHARED.FT}
        defaultValue={defaultValues?.[allConstants.JOB_FIELDS.SHARED.FT.api_name]}
      />
    </Box>
    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
      <Typography sx={{margin: '0 4px 0 16px'}}>in:</Typography>
      <CustomFormBlock {...inputProps}

        field={allConstants.JOB_FIELDS.SHARED.INCH}
        defaultValue={defaultValues?.[allConstants.JOB_FIELDS.SHARED.INCH.api_name]}
      />
    </Box>

  </Box>);
};

DistRow.propTypes = {
  label: PropTypes.string.isRequired,
  inputProps: PropTypes.instanceOf(Object).isRequired,
  defaultValues: PropTypes.instanceOf(Object),
};

DistRow.defaultProps = {
  defaultValues: {}
};

const DistanceEditor = (props)=> {
  const classes = useCreateJobStyles();
  const defaultValues = props?.defaultValues;
  const commonProps = {
    nested: true,
    parent: props.blockParent,
    fullWidth: true,
    renderLabel: false,
    labelLeft: true,
    inputType: inputTypes.number,
    hidePlaceholder: true,
    isEditable: true,
  };
  const MECH_FIELDS  = allConstants.JOB_FIELDS.MECHANICAL;


  const fields = [MECH_FIELDS.BACK, MECH_FIELDS.FRONT, MECH_FIELDS.LEFT, MECH_FIELDS.RIGHT];

  return(
    <>
      <Box maxWidth={'350px'} className={classes.job_form} textTransform={'none'}>
        <Typography variant={'body1'} sx={{
          fontSize: {sx: '14px', md: '16px'},
          color: 'red'
        }}>
          Please make sure to add setbacks</Typography>
        <Typography variant={'body1'} sx={{
          fontSize: {sx: '14px', md: '16px'},
          fontWeight: '600'
        }}>Mark #1</Typography>
        {fields.map((f, index)=> {
          return  <DistRow key={`${index}-${f.label}`} label={`${f.label} Yard Distance`}
            inputProps={{...commonProps, ...{parent: `${props.blockParent}[0].${f.api_name}`}}}
            defaultValues={defaultValues?.[0]?.[f.api_name]}
          />;
        })}
        {props.marksCount === 1 &&  <Button variant={'text'}
          sx={{paddingRight: 0}}
          startIcon={<AddBoxRoundedIcon/>}
          color={'primary'}
          onClick={props.toggleMarkCount}>{'Add second location'}</Button>}

        {props.marksCount === 2 && <>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

            <Typography variant={'body1'} sx={{
              fontSize: {sx: '14px', md: '16px'},
              fontWeight: '600'
            }}>Mark #2</Typography>
            <Button variant={'text'}
              color={'secondary'}
              sx={{paddingRight: 0}}
              startIcon={<DeleteForeverOutlinedIcon/>}
              onClick={props.toggleMarkCount}>{'Remove second location'}</Button>
          </Box>

          {fields.map((f, index)=> {
            return  <DistRow key={`${index}-${f.label}`} label={`${f.label} Yard Distance`}
              inputProps={{...commonProps, ...{parent: `${props.blockParent}[1].${f.api_name}`}}}
              defaultValues={defaultValues?.[1]?.[f.api_name]}
            />;
          })}
        </>}
      </Box>
    </>
  );
};

DistanceEditor.propTypes = {
  toggleMarkCount: PropTypes.func,
  blockParent: PropTypes.string.isRequired,
  marksCount: PropTypes.number,
  defaultValues: PropTypes.instanceOf(Array)
};

DistanceEditor.defultProps = {
  toggleMarkCount: ()=> {},
  marksCount: 1,
  defaultValues: [],
};

export {DistanceEditor};
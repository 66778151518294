import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import handleErrorMessage from '../../handleErrorMessage';

/**
 * @param {Object} props
 * @param {Object} props.administrator - is mandatory
 * @param {string} props.administrator.firstName - is mandatory
 * @param {string} props.paymentMethod
 */
export const completeOnboarding = createAsyncThunk(
  'users/completeOnboarding',
  async ( props, thunkAPI) => {
    try {
      // console.log('complete onboarding', props);
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}users/complete-onboarding`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(props),
        }
      );
      let data = await response.json();
      // console.log('getCurrentUserData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const completeOnboardingReducer = {
  [completeOnboarding.fulfilled]: (state, {payload}) => {
    state.isFetching = false;
    state.isSuccess = true;
    
  },
  [completeOnboarding.pending]: (state) => {
    state.isFetching = true;
  },
  [completeOnboarding.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = handleErrorMessage(payload);
  },
};
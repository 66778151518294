const ERROR_MSG = {
  INVALID_PHONE: 'Phone number is invalid',
  REQUIRED_FIELD: 'Required',
  EMAIL_INVALID: 'Please enter valid email',
  INVALID_EMAILS: 'Please enter comma separated valid emails',
  INVALID_AMOUNT: 'Please enter valid amount',
  INVALID_NUMBER: 'Please enter valid number',
  GENERAL_INVALID: 'Please enter a value',
  SELECT_JOB_TYPE: 'Please select type of job',
  SELECT_PROPERTY_TYPE: 'Please choose a property type',
  SHOULD_NOT_CONTAIN: (arr)=> {
    if(!arr || !Array.isArray(arr) || arr.length === 0) return 'Invalid symbols';
    if(arr.length === 1) return `Symbol ${arr[0]} is not allowed here`;
    return `Symbols ${arr.join(', ')} are not allowed here`;
  },
  SHOULD_BE_UNIQUE: (field)=> {
    return `${field} should be unique`;
  },
  USER_ALREADY_EXISTS: 'This User email is already exist',
  COMPANY_ALREADY_EXISTS: 'This Company email is already exist',
  PRODUCTS_CODE_NOT_UNIQUIE: 'Product code should be unique',
  PRICE_SHOULD_BE_A_NUMBER: 'Price should be a number',
  RATER_NOT_FOUND_IN_ONYX: 'Rater not found in Onyx',
  RATER_ALREADY_EXISTS: 'Rater with this email already exists',
  ONYX_EMAIL_USED_BY_OTHER_RATER: 'Rater with this Onyx email already exists',
  NO_PERMISSION: (action, module)=> {
    return `You don't have permission to ${action} ${module?module:''}`;
  },
  CAN_NOT_DO_FOR_YOURSELF: (action)=> {
    return `You can't ${action} your own account`;
  },
};

export {ERROR_MSG};

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import * as ROOF_OPTIONS from '../../../../constants/options/roofOptions';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import Utils from '../../../../utils/utils';
import clsx from 'clsx';
import {useFormContext, useWatch} from 'react-hook-form';

export const ScopeInfo = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.REROOF;
  const subParent = `${FIELDS.REROOF_SCOPE.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.REROOF.api_name]?.[FIELDS.REROOF_SCOPE.api_name];

  const includeGarage = useWatch({
    control: formController.control,
    name: `${props.parent}.${subParent}.${FIELDS.INCLUDE_GARAGE.api_name}`}
  );

  const getFieldValue = (fieldName)=> {
    return values?.[fieldName];
  };
  const commonProps = {
    nested: true,
    parent: blockParent,
    fullWidth: true,
    renderLabel: true,
  };
  return (
    <Grid container justifyContent={'center'} columnGap={{md: 6, lg: 12}} mt={2}>

      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}
        sx={{
          '& .MuiInputBase-root textarea': {
            width: '100%',
            maxWidth: '500px'
          }
        }}>
        {/*<Box className={classes.two_col_container} alignItems={'baseline'}>*/}
        <Box className={classes.two_col_container} sx={{
          '& .MuiFormControlLabel-root': {
            margin: '0',
          },
          '& .MuiFormControl-root': {
            alignItems: 'flex-start',
          },
          // '& .MuiFormControlLabel-label': {
          //   width: '115px',
          //   // height: '86px',
          // }
        }}>
          <CustomFormBlock{...props} {...commonProps}
            inputType={inputTypes.checkbox}
            renderLabel={false}
            labelLeft={true}
            field={FIELDS.HAS_HOA}
            defaultValue={getFieldValue(FIELDS.HAS_HOA.api_name)}
          />
          <CustomFormBlock{...props} {...commonProps}
            inputType={inputTypes.checkbox}
            renderLabel={false}
            labelLeft={true}
            field={FIELDS.INCLUDE_GARAGE}
            defaultValue={getFieldValue(FIELDS.INCLUDE_GARAGE.api_name)}
          />
        </Box>
        {/*</Box>*/}
        <Box className={classes.two_col_container} alignItems={'baseline'}>
          {includeGarage && <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.GARAGE_TYPE)}
            field={FIELDS.GARAGE_TYPE}
            defaultValue={getFieldValue(FIELDS.GARAGE_TYPE.api_name)}

          />}
        </Box>

        {/*<Box className={classes.two_col_container} alignItems={'baseline'}>*/}
        {/*  */}
        {/*  */}

        {/*</Box>*/}
        <Box className={classes.two_col_container} alignItems={'baseline'}>
          <CustomFormBlock
            {...props} {...commonProps}
            field={FIELDS.AIR_CONDITION_SPACE}
            inputType={inputTypes.number}
            defaultValue={getFieldValue(FIELDS.AIR_CONDITION_SPACE.api_name)}

          />


          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.number}
            field={FIELDS.NUMBER_OF_STORIES}
            defaultValue={getFieldValue(FIELDS.NUMBER_OF_STORIES.api_name)}
          />
        </Box>
        <CustomFormBlock
          {...props} {...commonProps}
          multiline={true}
          field={allConstants.JOB_FIELDS.GENERAL.SCOPE}
          defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.ROOF_TYPES)}
            field={FIELDS.EXISTING_ROOF_TYPE}
            defaultValue={getFieldValue(FIELDS.EXISTING_ROOF_TYPE.api_name)}

          />
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.ROOF_TYPES)}
            field={FIELDS.NEW_ROOF_TYPE}
            defaultValue={getFieldValue(FIELDS.NEW_ROOF_TYPE.api_name)}
          />
        </Box>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.ROOF_ACTIONS)}
            field={allConstants.JOB_FIELDS.SHARED.ACTION}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.SHARED.ACTION.api_name)}

          />
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.ROOF_DECK)}
            field={FIELDS.ROOF_DECK}
            defaultValue={getFieldValue(FIELDS.ROOF_DECK.api_name)}
          />
        </Box>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.TYPE_OF_SUBSTRATE)}
            field={FIELDS.SUBSTRATE_TYPE}
            defaultValue={getFieldValue(FIELDS.SUBSTRATE_TYPE.api_name)}

          />
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.UNDERLAYMENT_MATERAIL)}
            field={FIELDS.UNDERLAYMENT_MATERIAL}
            defaultValue={getFieldValue(FIELDS.UNDERLAYMENT_MATERIAL.api_name)}
          />
        </Box>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(ROOF_OPTIONS.TILE_ROOF_REMOVED)}
            field={FIELDS.TILE_ROOF_REMOVED}
            defaultValue={getFieldValue(FIELDS.TILE_ROOF_REMOVED.api_name)}

          />
          <CustomFormBlock
            {...props} {...commonProps}
            field={FIELDS.SLOPE}
            defaultValue={getFieldValue(FIELDS.SLOPE.api_name)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
ScopeInfo.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default ScopeInfo;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import {Box, Grid, Typography} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import CustomFormBlock  from '../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import Utils from '../../../../utils/utils';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {makeStyles} from '@mui/styles';
import actions from '../../../../redux/actions';
import {useDispatch} from 'react-redux';
import ElectricalEquipment from './electricalSystem';

const useStyles = makeStyles(()=> {
  return {
    row_input: {
      width: '240px',
      marginRight: '20px',
      '& .MuiInputBase-root': {
        width: 'fit-content'
      }
    }
  };
});


export const ElectricalFormBlock = (props)=> {
  const dispatch = useDispatch();
  const formController = useFormContext();
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const EL_FIELDS = allConstants.JOB_FIELDS.ELECTRICAL;
  const [showRewireAlert, setShowRewireAlert] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const parsedDraft = useRef(null);

  const watchHouseRewire = formController.watch(`${props.parent}[${EL_FIELDS.HOUSE_REWIRE.api_name}]`, (props)=> {});

  useEffect(()=> {
    let draftData;
    try {
      parsedDraft.current = JSON.parse(props.draftData?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
      draftData = parsedDraft.current?.equipment;

      if(draftData) {

        const subforms = {
          [allConstants.JOB_FIELDS.ELECTRICAL_SYSTEM.api_name]: Utils.createSystemDataForRedux(draftData, allConstants.JOB_FIELDS.ELECTRICAL_SYSTEM.api_name)
        };
        dispatch(actions.addBulkSubforms({
          jobType: allConstants.JOB_TYPES.ELECTRICAL.api_name,
          subforms
        }));
      }

    } catch (e){

    }
    setIsFirstLoad(false);

  },[]);
  const canModifyEquipment = useRef(Utils.canModifyEquipment(props.draftData));

  useEffect(()=> {
    setShowRewireAlert(watchHouseRewire && watchHouseRewire !== allConstants.HOUSE_REWIRE_OPTIONS.NOT_NEEDED.value);
  },[watchHouseRewire]);

  useEffect(()=> {
    const savedValue = formController.getValues()?.[allConstants.JOB_TYPES.ELECTRICAL.api_name]?.[EL_FIELDS.HOUSE_REWIRE.api_name];
    formController.setValue(`${allConstants.JOB_TYPES.ELECTRICAL.api_name}.${EL_FIELDS.HOUSE_REWIRE.api_name}`,savedValue || allConstants.HOUSE_REWIRE_OPTIONS.NOT_NEEDED.value, {shouldDirty: true});
  },[]);

  if(isFirstLoad) {
    //don't render subforms before we set draft data, is exists to store
    return null;
  }

  const getServiceUprgadeDefault = ()=> {
    if(!props.draftData || !parsedDraft.current) {
      return formController.getValues()?.[allConstants.JOB_TYPES.ELECTRICAL.api_name]?.[EL_FIELDS.HOUSE_REWIRE.api_name] ||
        allConstants.HOUSE_REWIRE_OPTIONS.NOT_NEEDED.value;
    }
    return parsedDraft.current?.[allConstants.JOB_TYPES.ELECTRICAL.api_name]?.[EL_FIELDS.HOUSE_REWIRE.api_name] ||
      allConstants.HOUSE_REWIRE_OPTIONS.NOT_NEEDED.value;
  };

  return (
    <Box className={classes.tab_container}>
      <Grid container>
        <Grid item xs={12}>
          <Box display={'flex'} flexDirection={{xs:'column', sm:'row'}} alignItems={{xs:'left', sm:'center'}}>
            <Box sx={{width: {xs:'350px', sm:'initial !important'}}}>
              <CustomFormBlock{...props}
                field={EL_FIELDS.HOUSE_REWIRE}
                inputType={inputTypes.singleSelect}
                options={Utils.getOptions(allConstants.HOUSE_REWIRE_OPTIONS)}
                nested={true}
                parent={props.parent}
                defaultValue={getServiceUprgadeDefault()}

                renderLabel={true}
              />
            </Box>
            {showRewireAlert && <Box sx={{
              marginTop: {xs:'initial', sm:'20px'},
              marginLeft: {xs:'initial', sm:'16px'},
              minHeight: '36px',
            }}
            >
              <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}
                sx={{lineHeight: {xs:'initial', md:'36px'}}}
              >
                Warning! Please be sure to include the number of lights, switches, and outlets when rewiring a house.
              </Typography>
            </Box>
            }
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} display={'flex'} flexDirection={{xs:'column',md:'row'}}>
          <Box className={classes.row_input} sx={{width: '350px'}}>
            <CustomFormBlock{...props}
              field={EL_FIELDS.SERVICE_UPGRADE}
              nested={true}
              parent={props.parent}
              renderLabel={true}
              fullWidth
            />
          </Box>
          <Box className={classes.row_input}>
            <CustomFormBlock{...props}
              field={EL_FIELDS.LIGHTS}
              nested={true}
              parent={props.parent}
              renderLabel={true}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} display={'flex'} flexDirection={{xs:'column',md:'row'}}>
          <Box className={classes.row_input}>
            <CustomFormBlock{...props}
              field={EL_FIELDS.SWITCHES}
              nested={true}
              parent={props.parent}
              renderLabel={true}
              fullWidth
            />
          </Box>
          <Box className={classes.row_input} sx={{marginRight: '0'}}>
            <CustomFormBlock{...props}
              field={EL_FIELDS.OUTLETS}
              nested={true}
              parent={props.parent}
              renderLabel={true}
              fullWidth
            />
          </Box>

        </Grid>

        <ElectricalEquipment {...props} canModifyEquipment={canModifyEquipment.current}/>

      </Grid>
    </Box>
  );
};

ElectricalFormBlock.propTypes = {
  setEquipmentForm: PropTypes.func.isRequired,
  parent: PropTypes.string.isRequired,
  draftData: PropTypes.string,
};

ElectricalFormBlock.defaultProps = {
  draftData: null,
};

export default ElectricalFormBlock;

import React from 'react';
import {ThemeProvider, Paper, Grid, Box, Typography} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import myTheme from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import BlockedImage from '../../static/images/warningImage.png';

const BlockedPage = () => {
  const classes = globalUseClasses();
  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          <Grid container xs={12} sx={{backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain}}>
            <Grid item xs={12} sm={10} md={6} lg={4} sx={{
              margin: '200px auto'
            }}>
              <Paper sx={{
                width: '100%',
                //height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px',
                borderRadius: '10px',

              }}>
                <img src={BlockedImage} alt={''}/>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    margin: '16px auto'
                  }}>
            Please contact our Accounting Department at</Typography>
                <Box sx={{
                  backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
                  paddingX: '40px',
                  borderRadius: '4px'
                }}>
                  <Typography variant={'body1'}
                    align={'center'} style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      color: STYLE_CONSTANTS.COLORS.globalBlue,
                      margin: '16px auto',

                    }}>
                855-737-6484 x 103
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};


export default BlockedPage;

import React from 'react';
import PropTypes from 'prop-types';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import * as allConstants from '../../../../constants/allConstants';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import Utils from '../../../../utils/utils';
import {SectionWrapper} from '../pieces/sectionWrapper';
import {Grid, Box} from '@mui/material';
import {useFormContext} from 'react-hook-form';

export const WaterSoftener = (props)=> {
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const subParent = `${allConstants.JOB_FIELDS.PLUMBING.WATER_SOFTNER.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.PLUMBING.api_name];

  const getFieldValue = (fieldName)=> {
    const blockValues = values?.[`${subParent}`];
    return blockValues?.[fieldName];
  };
  return (
    <SectionWrapper>
      <Grid container sx={12}>
        <Grid item xs={12} sm={6}>
          <SectionWrapper>
            <CustomFormBlock{...props}
              inputType={inputTypes.singleSelect}
              field={allConstants.JOB_FIELDS.GENERAL.CO_OR_NEW}
              nested={true}
              parent={blockParent}
              options={Utils.getOptions(allConstants.CO_OR_NEW)}
              defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.CO_OR_NEW.api_name)}
              renderLabel={true}
            />
            <Box sx={{width: '240px',
              '& .MuiInputBase-root': {
                width: 'fit-content'
              }
            }}>
              <CustomFormBlock{...props}
                inputType={inputTypes.singleSelect}
                options={Utils.getOptions(allConstants.ANSWER_OPTIONS)}
                field={FIELDS.ELECTRICAL_PERMIT}
                nested={true}
                parent={blockParent}
                defaultValue={getFieldValue(FIELDS.ELECTRICAL_PERMIT.api_name)}
                renderLabel={true}
                fullWidth
              />
            </Box>

            <CustomFormBlock{...props}
              field={allConstants.JOB_FIELDS.SHARED.LOCATION}
              nested={true}
              parent={blockParent}
              defaultValue={getFieldValue(allConstants.JOB_FIELDS.SHARED.LOCATION.api_name)}
              renderLabel={true}
            />
          </SectionWrapper>

        </Grid>
        <Grid item>

        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormBlock{...props}
            multiline
            field={allConstants.JOB_FIELDS.GENERAL.SCOPE}
            nested={true}
            parent={blockParent}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name)}
            renderLabel={true}
          />
        </Grid>
      </Grid>


    </SectionWrapper>
  );
};

WaterSoftener.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default WaterSoftener;

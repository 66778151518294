import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, LinearProgress, Typography} from '@mui/material';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import ModalWrapper from '../../../../../pieces/modals/modalWrapper';
import {JOB_FIELDS} from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {toast} from 'react-toastify';
import Spacer from '../../../../../pieces/spacer';
import successImage from '../../../../../static/images/check-circle.png';
import warningImage from '../../../../../static/images/warningImage.png';

export const Cf1rPopupWindow = (props)=> {

  const submitAction = ()=> {
    if(props.isError) {
      props.tryAgain();
    } else if(props.cheersData?.[JOB_FIELDS.CHEERS_CF1R_PROJECT_URL.api_name]) {
      Utils.openInNewTab(props.cheersData?.[JOB_FIELDS.CHEERS_CF1R_PROJECT_URL.api_name]);
    } else {
      toast.error('CF1R document is not available');
    }
  };

  const getText = ()=> {
    if(props.isLoading) {
      return 'Creating Cf1R. Please wait...';
    } else if(props.isError) {
      return 'Error creating CF1R project on cheers. Please try again';
    }
    return 'CF1R project created on cheers. Please review and sign it';
  };

  const content =

    <Grid container alignItems={'baseline'} position={'relative'}>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} textAlign={'center'}>
          <Spacer y={3}/>
          <Box onDragStart={(e)=> e.preventDefault()}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            sx={{
              '& img':{
                width: '70px'
              },
              height: '70px'
            }}
          >
            {!props.isLoading &&

            <img src={props.isError ? warningImage : successImage} alt={''}/>
            }
          </Box>
          {props.isLoading && <LinearProgress/>}
          <Typography variant={'body1'}>
            {getText()}
          </Typography>
          <Spacer y={3}/>
          <FormSubmitButtons
            disableSubmit={props.isLoading}
            disableCancel={props.isLoading}
            submitText={props.isError ? 'Try again' : 'View CF1R'}
            submitAction={submitAction}
            cancelAction={props.close}
            applyDeleteStyles={true}
            revertColors={true}
            noOutlines={true}
            cancelText={'Ok'}/>
        </Box>
      </Grid>
    </Grid>;
  return(
    <ModalWrapper handleClose={props.onCancel}
      open={true}
      modalName={'creating-cf1r'}
      preventScroll={true}
      width={{sx: '98%', sm: '600px'}}
      padding={6}
      customStyle={{paddingTop: 2}}
    >
      {content}
    </ModalWrapper>
  );
};

Cf1rPopupWindow.propTypes = {
  close: PropTypes.func,
  cheersData: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  tryAgain: PropTypes.func,
  onCancel: PropTypes.func
};

Cf1rPopupWindow.defaultProps = {
  cheersData: {},
  isLoading: false,
  isError: false,
  tryAgain: ()=> {},
  onCancel: ()=> {},
  close: ()=> {}
};

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Popover, Typography, Box, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {useLocation, useNavigate} from 'react-router-dom';
import Utils from '../../utils/utils';
import {urlSearchParams} from '../../baseUrls';
import {NavbarLink} from './navbarLink';
import CustomIcon from '../../static/icons/Icon';
import * as allConstants from '../../constants/allConstants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const NavbarMenuButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const moduleName = Utils.getUrlParameter(urlSearchParams.moduleName);
  const isActive = module && props.module === moduleName;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=> {
    setAnchorEl(null);
  }, [location]);

  const sublinkIsActive = (sublink) => {
    const submodule = Utils.getUrlParameter(urlSearchParams.submodule);
    return submodule ? sublink.submodule === submodule : false;
  };

  const handleClick = (e) => {
    if (!props.links) {
      e.preventDefault();
      navigate(`${allConstants.PATH.MODULE}?${urlSearchParams.moduleName}=${props.module}`);
      //navigate to path

    } else {
      setAnchorEl(e.currentTarget);
    }

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'menu-popover' : undefined;

  const StartIcon = props.startIcon?.icon  ? <CustomIcon
    icon={props.startIcon.icon}
    color={isActive ? STYLE_CONSTANTS.COLORS.globalGreen : STYLE_CONSTANTS.COLORS.fontPale}
    fill={'none'}
    alt={props.startIcon.alt}
  /> : null;

  const getPath = (link) => {
    if(link.submodule){
      return `${allConstants.PATH.MODULE}?${urlSearchParams.moduleName}=${props.module}&${urlSearchParams.submodule}=${link.submodule}`;
    }
    return link.path;
  };

  const Links = props.links ? props.links.map((link, index) => {
    return (
      <NavbarLink key={index.toString()}
        text={link.text}
        path={getPath(link)}
        isActive={sublinkIsActive(link)}
        isSublink={true}
        alt={link?.alt}
      />
    );
  }) : null;


  return (
    <>
      {/*small screen component*/}
      <Box sx={{
        display: {xs: 'block', lg: 'none'},
        width: '100%',
      }}>
        <Accordion sx={{
          '&.MuiAccordion-root': {
            border: 'none',
            borderRadius: '0px',
            boxShadow: 'none',
            '& .MuiTypography-root': {
              color: STYLE_CONSTANTS.COLORS.fontPale,
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '1.75rem',
            },

          },
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              '&.MuiAccordionSummary-root': {
                minHeight: 'unset',
                paddingX: '0px',
              },
              '& .MuiAccordionSummary-content': {
                margin: '0px !important',
                paddingY: '4px',
              },
            }}>
            {StartIcon}
            <Typography> {props.buttonContent}</Typography>

          </AccordionSummary>
          <AccordionDetails sx={{
            '&.MuiAccordionDetails-root': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              paddingBottom: '0px',
              '& a': {
                minWidth: 'unset',
              }
            },
          }}>
            {Links}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/*large screen component*/}
      <Box sx={{
        display: {xs: 'none', lg: 'block'},
      }}>
        <Button aria-describedby={id} variant="text" onClick={handleClick}
          startIcon= {StartIcon}
          endIcon={props.links ? <KeyboardArrowDownIcon /> : null}
          disableRipple={true}

          style={{
            color: isActive ?  STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.fontPale,
            paddingLeft: '0px',
            paddingRight: '0px',
          }}>
          {props.buttonContent}

        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPopover-paper': {
              width: props.drowdownWidth ?? '150px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',

              '& .MuiButton-root': {
                width: '100%',
                paddingLeft: '16px',
                margin: '4px 0px',
                justifyContent: 'flex-start',
                borderRadius: '0px',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '1.75rem',
                '&:hover': {
                  backgroundColor: STYLE_CONSTANTS.COLORS.globalGreenHover,
                },
              },
            }
          }}
        >
          {Links}
        </Popover>
      </Box>

    </>
  );
};


NavbarMenuButton.propTypes = {
  buttonContent: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  startIcon: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    submodule: PropTypes.string,
  })),
  drowdownWidth: PropTypes.string,

};

NavbarMenuButton.defaultProps = {
  drowdownWidth: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import {Chip, Box} from '@mui/material';
import * as allConstants from '../../constants/allConstants';
import Utils from '../../utils/utils';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {Text} from '../text';


const colors = {
  green: '#55CB8B',
  red: '#F32929',
  yellow: '#F4F402',
  gray: STYLE_CONSTANTS.COLORS.fontPale,
};

const mapValueToColor = {
  [allConstants.COMPANY_STATUSES.ACTIVE.value.toLowerCase()]:'#39DE5D',
  [allConstants.COMPANY_STATUSES.NOT_ACTIVE.value.toLowerCase()]:'#C4C4C4',
  [allConstants.COMPANY_STATUSES.NOT_SIGNED.value.toLowerCase()]:STYLE_CONSTANTS.COLORS.fontPale,
  // [allConstants.COMPANY_STATUSES.NOT_SIGNED.value]:'#FFFFFF',
  [allConstants.COMPANY_STATUSES.BLOCKED.value.toLowerCase()]:'#DE0000',

  [allConstants.PERMIT_STATUSES.COMPLETED.value.toLowerCase()]:colors.green,
  [allConstants.PERMIT_STATUSES.HOLD.value.toLowerCase()]:colors.red,
  [allConstants.PERMIT_STATUSES.NEED_ATTENTION.value.toLowerCase()]:colors.red,
  [allConstants.PERMIT_STATUSES.CANCELLED.value.toLowerCase()]:colors.red,
  [allConstants.PERMIT_STATUSES.PENDING.value.toLowerCase()]:colors.yellow,
  [allConstants.PERMIT_STATUSES.PULLED_NOT_UPLOADED.value.toLowerCase()]:colors.yellow,
  [allConstants.PERMIT_STATUSES.APPOINTMENT_SCHEDULED.value.toLowerCase()]:colors.yellow,
  [allConstants.PERMIT_STATUSES.SUBMITTED.value.toLowerCase()]: colors.yellow,
  [allConstants.PERMIT_STATUSES.NOT_REQUIRED.value.toLowerCase()]: colors.gray,

  [allConstants.HERS_STATUSES.NEED_ATTENTION.value.toLowerCase()]:colors.red,
  [allConstants.HERS_STATUSES.CANCELLED.value.toLowerCase()]:colors.red,
  [allConstants.HERS_STATUSES.FAILED.value.toLowerCase()]:colors.red,
  [allConstants.HERS_STATUSES.HOLD_OFF.value.toLowerCase()]:colors.red,
  [allConstants.HERS_STATUSES.COMPLETED.value.toLowerCase()]: colors.green,
  [allConstants.HERS_STATUSES.EMAILED_DIRECTLY.value.toLowerCase()]: colors.green,
  [allConstants.HERS_STATUSES.SCHEDULED.value.toLowerCase()]:colors.yellow,
  [allConstants.HERS_STATUSES.READY_TO_RESCHEDULE.value.toLowerCase()]:colors.yellow,
  [allConstants.HERS_STATUSES.PENDING.value.toLowerCase()]:colors.yellow,
  [allConstants.HERS_STATUSES.RESCHEDULED.value.toLowerCase()]:colors.yellow,

  [allConstants.MAILING_STATUSES.MAILED.value.toLowerCase()]: colors.green,
  [allConstants.MAILING_STATUSES.MAILED_TO_CONTRACTOR.value.toLowerCase()]: colors.green,

  [allConstants.RESULT_OPTIONS.FAILED.value.toLowerCase()]: colors.red,
  [allConstants.RESULT_OPTIONS.PASSED.value.toLowerCase()]: colors.green,
};
const mapValueToBorderColor = {
  [allConstants.PERMIT_STATUSES.NOT_REQUIRED.value.toLowerCase()]: colors.gray,
};
const getChipOptions = (value) =>{
  const types = {outlined: 'outlined', contained:'contained'};
  const outlinedSet = new Set(
    [allConstants.COMPANY_STATUSES.NOT_SIGNED.value.toLowerCase(),
      allConstants.PERMIT_STATUSES.NEW.value.toLowerCase(),
      allConstants.PERMIT_STATUSES.APPLIED_FOR_REFUND.value.toLowerCase(),
      //allConstants.PERMIT_STATUSES.CANCELLED.value.toLowerCase(),
      allConstants.PERMIT_STATUSES.NOT_REQUIRED.value.toLowerCase(),
    ]
  );
  const color = Utils.objectHasProperty(mapValueToColor, value ) ? mapValueToColor[value] : '#000';
  const borderColor = Utils.objectHasProperty(mapValueToBorderColor, value) ? mapValueToBorderColor[value] : '#000';

  if(outlinedSet.has(value)){
    return {
      type: types.outlined,
      color: color,
      backgroundColor: 'initial',
      border: `1px solid ${borderColor}`
    };

  }
  else{
    return {
      type: types.contained,
      color: color === colors.yellow ? '#000' : '#fff',
      backgroundColor: color,
      border: 'initial',
    };
  }
};

const StatusRenderer = ({value}) => {
  if(!value) return <></>;
  const chipOptions = getChipOptions(value.toLowerCase());

  return <Chip label={Utils.capitalizeFirstLetter(value.toLowerCase())} variant={chipOptions.type} sx={{
    height: '20px',
    fontSize: '12px',
    borderRadius: '2px',
    border: `${chipOptions.border}`,
    color: `${chipOptions.color}`,
    backgroundColor: `${chipOptions.backgroundColor}`,
    '& .MuiChip-label': {
      padding: '0 6px'
    }
  }}/>;
};

export const StatusRenderWithAdditionalData = ({value})=> {
  if(!value) return <></>;

  const {status, prefix, suffix} = value;

  const chipOptions = getChipOptions(status.toLowerCase());

  return (
    <Box display={'flex'} flexDirection={'column'} sx={{lineHeight: '14px'}}>
      <Box display={'flex'} alignItems={'center'} sx={{lineHeight: '20px'}}>
        {prefix && <Text text={`${prefix}:`}/>}
        <Chip label={Utils.capitalizeFirstLetter(status.toLowerCase())} variant={chipOptions.type} sx={{
          height: '20px',
          fontSize: '12px',
          borderRadius: '2px',
          border: `${chipOptions.border}`,
          color: `${chipOptions.color}`,
          backgroundColor: `${chipOptions.backgroundColor}`,
          '& .MuiChip-label': {
            padding: '0 6px'
          }
        }}/>

      </Box>
      {suffix && <Text size={'small'} text={` ${suffix}`}/>}


    </Box>
  );
};

// Thanh 05/01/24
// StatusRenderer.propTypes = {
//   value: PropTypes.string.isRequired
// };

StatusRenderWithAdditionalData.propTypes = {
  value: PropTypes.shape({
    status: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string
  })
};

export default StatusRenderer;

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Box, ButtonBase, InputAdornment, CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import theme from '../../styles/myStyles';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import ApiUtils from '../../utils/apiUtils';
import base_url from '../../baseUrls';
import axios from 'axios';
import Utils from '../../utils/utils';
import validator from '../../utils/validators/validator';
import {useFormContext} from 'react-hook-form';
import {CustomInput} from './customInputComponent';


const useStyles = makeStyles((theme) => ({
  field: {
    '&.MuiFormControl-root': {
      height: '100%'
    },
    '& .MuiOutlinedInput-root': {
      height: 'inherit',
    },
    '& .MuiOutlinedInput-root:hover':{
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      }
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.text.secondary
    },
    '& .MuiInputBase-input': {
      backgroundColor: '#fff',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F8F8F8',
      WebkitTextFillColor: 'rgba(63, 66, 84, 1)',
    },

  },
  button: {
    width: '100%',
    // height: '100%',
    overflow: 'hidden',
    justifyContent: 'flex-end'
  }
}));

const FileInput = (props) => {
  const ref = useRef();
  const classes = useStyles();
  const [attachment, setAttachment] = useState(props?.defaultValue);
  const [fetching, setFetching] = useState(false);

  const formMethods = useFormContext();

  const saveFileId = (file)=> {
    formMethods.clearErrors(props.field_name);
    props.setValueToForm(props.field_name, file, {shouldDirty: true,  shouldTouch: true, trigger:props.field_name});
  };

  const saveFileName = (fileName)=> {
    const name = props.parent ? `${props.parent}.fileName` : 'fileName';
    props.setValueToForm(name, fileName);
  };

  const uploadFileToSessionStorage = async (file)=>{
    const reader = new FileReader();
    await reader.readAsDataURL(file);
    reader.onload = (event)=> {
      sessionStorage.setItem(props.field_name, event.target.result);
      sessionStorage.setItem(`${props.field_name}_name`, file.name);

      // Thanh 02/04/24  -- local storage
      // console.log('save file to local storage');
      // localStorage.setItem(props.field_name, event.target.result);
      // localStorage.setItem(`${props.field_name}_name`, file.name);

    };
  };


  const handleChangeUpload = (event) => {
    setFetching(true);
    const files = Array.from(event.target.files);
    const [file] = files;

    if (Array.isArray(files) && files.length !== 0) {
      // Check for valid file type
      if (validator.isValidFileType(files[0].type) === false) {
        setFetching(false);
        Utils.errorToastMessage('Invalid file type, please try uploading a different file.', 'upload_file');
        console.log('Wrong file type: ', files[0].type);
        return;
      }

      const fileData = new FormData();
      fileData.append('file', file);

      const fetch = async ()=> {
        let url = `${base_url.api}files/upload`;
        const authToken = await ApiUtils.getAccessToken();
        const data = await axios.post(url, fileData, {
          headers: {
            Authorization: authToken,
          }});
        return data;
      };
      if(props.dontUploadImmediately){
        setFetching(true);
        if(props.field.required) {
          saveFileId('file ready to upload');
          saveFileName(file.name);
        }
        uploadFileToSessionStorage(file).then(()=>{

          setAttachment(file);
          setFetching(false);


        });
        //saveFileId(file.name);

      } else {
        setFetching(true);
        fetch()
          .then((data) => {
            setFetching(false);
            // console.log('data.data.data.id!!!!!', data.data.data.id);
            file['id'] = data.data.data.id;
            setAttachment(file);
            saveFileId(props.directUpload ? data.data.data.id : file);
            saveFileName(file.name);
          })
          .catch((error) => {
            setFetching(false);
            Utils.errorToastMessage('Failed to upload file. ' + error.response.data?.message, 'upload_file');
            console.log('Upload file error: ', error);
          });
      }
    }
  };

  const handleChangeDownload = ()=> {
    if(!attachment?.id) return;
    const fetch = async () => {
      let url = `${base_url.api}files/${attachment.id}`;
      const authToken = await ApiUtils.getAccessToken();
      const data = await axios.get(url, {headers: {Authorization: authToken}, responseType: 'blob'});
      return data;
    };
    if(props.dontUploadImmediately){
      const file = JSON.parse(sessionStorage.getItem(props.field_name));
      Utils.downloadFile(file.base64, file.name, file.type);
      return;
    }
    fetch()
      .then((data) => {
        const fileName = Utils.getContentDispositionFileName(data);
        const url = window.URL.createObjectURL(new Blob([data.data], {type: 'blob'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log('Download file error: ', e);
      });
  };

  const getFileNameValue = () => {
    if (attachment?.name) {
      return attachment.name;
    } else if(attachment) {
      if (attachment?.id) {
        return attachment.id;
      } else {
        const savedToSession = sessionStorage.getItem(`${props.field_name}_name`);
        if(savedToSession) {
          return savedToSession;
        }
      }
    }
    return null;
  };

  return (
    <Box
      position="relative"
      height={'100%'}
      marginBottom={1}
      marginTop={theme.spacing(0.5)}
    >
      <Box position="absolute" top={'-4px'} bottom={0} left={0} right={0}>
        <CustomInput
          variant="outlined"
          className={classes.field}
          fullWidth
          disabled={props.viewMode || !props.isEditable}
          error={props.isError}
          value={getFileNameValue()}
          onClick={handleChangeDownload}
          endAdornment={ (attachment?.id && (props.viewMode || !props.isEditable))?
            <InputAdornment position="end"> <Box px={1} display={'flex'} padding={'6px'}>
              <FileDownloadIcon style={{color: theme.palette.info.main, transform: 'scale(1.5)', cursor: 'pointer'}}/>
            </Box>
            </InputAdornment> :<InputAdornment position="end"> <Box px={1} display={'flex'} padding={'6px'}>
              <FileUploadIcon style={{color: theme.palette.success.main, transform: 'scale(1.5)', cursor: 'pointer'}}/>
            </Box>
            </InputAdornment>}
        />
      </Box>
      <ButtonBase
        disableRipple
        className={classes.button}
        component="label"
        onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()}
        sx={{visibility: props.viewMode || !props.isEditable ? 'hidden' : 'visible',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          height: '32px',
        }}
      >
        <input
          ref={ref}
          type="file"
          hidden

          onChange={props.viewMode || !props.isEditable ? handleChangeDownload : handleChangeUpload}
        />
        <Box display={'flex'} padding={'4px 6px'}  >
          {
            fetching ? <CircularProgress size={25} color={'primary'}/> : null
          }
        </Box>

      </ButtonBase>
    </Box>
  );
};

FileInput.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.bool.isRequired,
    directUpload: PropTypes.bool,
    dontUploadImmediately: PropTypes.bool
  }
};
FileInput.defaultProps = {...formBlockDefaultProps, ...{
  directUpload: true,
  dontUploadImmediately: false
}};


export default FileInput;

import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Grow, Paper, Popper, MenuList, MenuItem} from '@mui/material';
import {printJobDetails} from '../utils/print';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ClickAwayListener} from '@mui/material';

export const PrintButton = (props)=> {
  const printPage = (e) => {

    e.stopPropagation();
    const print_data = {...props.job};

    printJobDetails(print_data);
  };
  return (
    <Button variant="contained"
      color={'primary'}
      sx={STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON}
      onClick={printPage}
    >PRINT ORDER</Button>
  );
};

export const ButtonSplit = (props)=> {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);


  const handleMenuItemClick = (e, index) => {
    setSelectedIndex(index);
    props.options[index].action();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef}
        variant={'contained'}
        color={'primary'}
        sx={{
          ...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
          ...{
            '& .MuiButton-root': {
              textTransform: 'uppercase',
              '&:hover': {
                backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
                color: STYLE_CONSTANTS.COLORS.white,
              }
            }
          }
        }}
      >
        <Button  sx={STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON}
          onClick={props.options[0].action}>{props.options[0].name}</Button>
        {props.options.length > 0 && <Button
          size="small"
          sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
            ...{
              padding: '4px 8px',
            }
          }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>}

      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      sx={{
                        '&.MuiButtonBase-root.MuiMenuItem-root': {
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: STYLE_CONSTANTS.COLORS.globalGreenHover,
                          }
                        }
                      }}
                      key={option.name}
                      selected={index === selectedIndex}
                      disabled={option.disabled}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ButtonSplit.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  })).isRequired

};

PrintButton.propTypes = {
  job: PropTypes.instanceOf(Object).isRequired
};

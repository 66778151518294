import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import axios from 'axios';
import Utils from '../../../utils/utils';
import {Box, Button, IconButton, Typography} from '@mui/material';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import CloseIcon from '@mui/icons-material/Close';


const JobJSON = (props)=> {
  const jobId = props.jobId;
  const [jobJSON, setJobJSON] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(()=> {
    // console.log('companyId', jobId);
    if(jobId) {
      const fetch = async ()=> {
        let url = `${base_url.api}jobs/${jobId}`;
        const authToken = await ApiUtils.getAccessToken();
        const jobResp = await axios.get(url, {headers: {Authorization: authToken}});
        return jobResp.data.data.job;
      };

      fetch()
        .then((d)=> {
          // console.log('GOT JOB DATA:::', d);
          const jobJSON = JSON.parse(d.jobJson);
          console.log(jobJSON);
          d.jobJson = jobJSON;
          setJobJSON(d);
        })
        .catch((e)=> {
          setError(e);
        });
    }
  }, [jobId]);



  const PrettyJson = <div><pre>{
    JSON.stringify(jobJSON ? jobJSON : error, null, 2) }</pre></div>;

  return PrettyJson;
};
JobJSON.propTypes = {
  jobId: PropTypes.string.isRequired
};

const DevHandleAlerts = (props)=> {
  const jobId = props.jobId;
  const [jobJSON, setJobJSON] = useState(undefined);

  useEffect(()=> {
    // console.log('companyId', jobId);
    if(jobId) {
      const fetch = async ()=> {
        let url = `${base_url.api}jobs/${jobId}`;
        const authToken = await ApiUtils.getAccessToken();
        const jobResp = await axios.get(url, {headers: {Authorization: authToken}});
        return jobResp.data.data.job;
      };

      fetch()
        .then((d)=> {
          // console.log('GOT JOB DATA:::', d);
          const jobJSON = JSON.parse(d.jobJson);
          d.jobJson = jobJSON;
          setJobJSON(d);
        })
        .catch((e)=> {
          console.log('ERROR on fetching job', e);
        });
    }
  }, [jobId]);

  if(!jobJSON) return <p>No data</p>;

  const jobZohoId = jobJSON.zohoId;
  const notes = jobJSON.notes;
  const internalNotes = jobJSON?.internalNotes || [];


  const notSyncedNotes = notes.filter((n)=> !n.zohoId);

  const notSyncedInternalNotes = internalNotes.filter((n)=> !n.zohoId);

  const attachments = jobJSON.attachments;

  const syncNotesToCRM = async (isInternal)=> {
    console.log('syncNotesToCRM', isInternal);
    try{
      const authToken = await ApiUtils.getAccessToken();
      const body = {
        jobId: jobId
      };
      const url = isInternal ? `${base_url.api}jobs/sync-internal-notes-with-zoho` :`${base_url.api}jobs/sync-job-notes-with-zoho`;
      const response = await fetch(

        url,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body:JSON.stringify(body),
        }
      );
      let data = await response.json();
      Utils.handleToastMessage(data, 'Notes added to CRM', undefined, 'crm_force_add_success' );
    } catch(e){
      Utils.errorToastMessage(`Error on force adding to CRM::: ${JSON.stringify(e)}`, 'crm_force_add_error');

    }
  };

  return (
    <>
      <Box ml={{xs: '16px', sm: '24px',}} sx={{
        position: 'sticky',
        top: '0',
        display: 'flex',
        justifyContent: 'flex-end',
      }}>
        <IconButton
          sx={{
            backgroundColor:STYLE_CONSTANTS.COLORS.backgroundMain,
            width: '34px',
            height: '34px'
          }}
          onClick={props.closeModal}>
          <CloseIcon/>
        </IconButton>
      </Box>
      <Typography variant={'h2'}>{`${jobJSON?.jobNumber}: `}
        <Typography><span>jobZohoId:</span>{jobZohoId}</Typography>
      </Typography>

      <Typography variant={'h3'}>Notes</Typography>
      <Typography><span>Total:</span>{notes.length}</Typography>
      <Typography><span>notSyncedNotes:</span>{notSyncedNotes.length}</Typography>
      {notes.map((n, index)=> {
        const color = n?.zohoId ? 'initial' : 'red';
        return  <Typography key={n.id + index} sx={{color: color}}>{JSON.stringify(n)}</Typography>;
      })}
      <Button variant={'outlined'} onClick={()=> syncNotesToCRM(false)}>Sync notes to CRM</Button>
      <Typography variant={'h3'}>Docs</Typography>
      <Typography><span>Total:</span>{attachments.length}</Typography>
      <Typography><span>notSyncedNotes:</span>{notSyncedNotes.length}</Typography>
      {attachments.map((n, index)=> {
        const color = n?.zohoId ? 'initial' : 'red';
        return  <Typography key={n.id + index} sx={{color: color}}>{JSON.stringify(n)}</Typography>;
      })}
      <Typography variant={'h3'}>Internal Notes</Typography>
      <Typography><span>Total:</span>{internalNotes.length}</Typography>
      <Typography><span>notSyncedNotes:</span>{notSyncedInternalNotes.length}</Typography>
      {internalNotes.map((n, index)=> {
        const color = n?.zohoId ? 'initial' : 'red';
        return  <Typography key={n.id + index} sx={{color: color}}>{JSON.stringify(n)}</Typography>;
      })}
      <Button variant={'outlined'} onClick={()=> syncNotesToCRM(true)}>Sync internal notes to CRM</Button>
      {/*<Button variant={'outlined'} onClick={syncNotesToCRM}>Sync notes to CRM</Button>*/}
    </>
  );
};
DevHandleAlerts.propTypes = {
  jobId: PropTypes.string.isRequired,
  closeModal: PropTypes.func
};

DevHandleAlerts.defaultProps = {
  closeModal: ()=> {}
};
export {JobJSON, DevHandleAlerts};

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Typography, Icon, Grid} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import {globalUseClasses} from '../../styles/globalClasses';
import {useHomePageStyles} from './homePageStyles';
import clapIcon from '../../static/images/clap-icon.svg';
import {TrapezoidButton} from './trapeziodButton';
import BookmarkPaper from './bookmarkPaper';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';
import DatePicker from '../../pieces/datePicker';
import {GENERAL_ELEMENTS, generalRules} from '../../permissions/generalAccessControl';
import {NewPermissionGate} from '../../permissions/permissionGate';
import clsx from 'clsx';
import HOME_PAGE_BUTTONS from '../../constants/homePageButtons';
import {companyAndUserUtils} from '../../utils/companyAndUserUtils';
import base_url from '../../baseUrls';
import ApiUtils from '../../utils/apiUtils';
import axios from 'axios';
import moment from 'moment';
import GettingDataProgress from '../../pieces/gettingDataProgress';
import Utils from '../../utils/utils';
import {isIpermitUser} from '../../permissions/utils';
//import {ConstructionOutlined} from '@mui/icons-material';


const bookmarkMargins = (queries)=> {
  const sm = {
    marginTop:'20px',
    marginBottom: '20px',
    marginRight:'',
    marginLeft: ''
  };
  const xs = {
    marginTop:'10px',
    marginBottom: '10px',
    marginRight:'auto',
    marginLeft: 'auto'
  };
  if(queries.isXS) {
    return xs;
  }
  return sm;
};

const Home = () => {
  const queries =CustomMediaQuery();
  const user = useSelector(state => state.user);
  const username = `${user.userName.firstName} ${user.userName.lastName}`;
  const classes = {...useHomePageStyles(queries)(), ...globalUseClasses()};
  const [selectedDate, setSelectedDate] = useState(new Date());
  const company = useSelector(state=> state.company);
  const isHERS = companyAndUserUtils.isHERSCompany(company);
  const isTestingOnly = companyAndUserUtils.isTestingOnlyCompany(company);
  const initialAnalytics = {
    jobsCreated: undefined,
    jobsWithStatusNeedsAttention: undefined,

  };
  const initialAwsAnalytics = {
    jobsCompleted: undefined,
    jobsScheduled: undefined,
    permitCompleted: undefined,

  };
  const [awsAnalyticsData, setAwsAnalyticsData] = useState({...initialAwsAnalytics});
  const [analyticsData, setAnalyticsData] = useState({...initialAnalytics});
  //console.log('USER', user);

  const datePickerHandler = (newDate)=>{
    if(newDate !== setSelectedDate) {
      setSelectedDate(newDate);
    }

  };

  const getSessionStorageKey = ()=> {
    return `${selectedDate}${company.name}`;
  };

  useEffect(()=> {
    setAnalyticsData({...initialAnalytics});
    setAwsAnalyticsData({...initialAwsAnalytics});

    const fetchData = async () => {
      let url = `${base_url.api}analytics?date=${moment(selectedDate).format('YYYY-MM-DD')}&type=${isTestingOnly ? 'hers' : 'permit'}`;
      const authToken = await ApiUtils.getAccessToken();
      const response = await axios.get(url, {
        headers: {
          method: 'POST',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken
        },
      });
      return response;
    };

    const fetchAWS = async ()=> {

      console.log('fetchAWS');

      const cachedItem = sessionStorage.getItem(getSessionStorageKey());
      if(cachedItem) {
        try{
          const cachedObj = JSON.parse(cachedItem);
          if(cachedObj?.data && (!cachedObj?.ttl || (new Date().getTime() < cachedObj.ttl))) {
            setAwsAnalyticsData(cachedObj?.data);
            return;
          }
        } catch(e){}
      }
      let url = `${base_url.api}analytics/aws?date=${moment(selectedDate).format('YYYY-MM-DD')}`;

      console.log('analytical data url=' + url);

      const authToken = await ApiUtils.getAccessToken();
      const response = await axios.get(url, {
        headers: {
          method: 'POST',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken
        },
      });
      
      console.log('response');
      console.log(response);
      
      return response;
      // let url = `https://analytics.prod.api.ipermiteraters.com/query?queryType=homePageAnalytics&company=${company.name}&date=2021-09-13`
      // const awsResponse = await axios.get(url);
      // return awsResponse;
    };

    if(!Utils.isToday(selectedDate)) {
      fetchAWS().then((analytics)=>{
        const awsPart = {
          jobsCompleted: analytics?.data?.data?.jobsCompleted|| 0,
          jobsScheduled: analytics?.data?.data?.jobsWithHERSStatusScheduled || 0,
          permitCompleted: analytics?.data?.data?.jobsWithStatusCompleted || 0,
        };
        sessionStorage.setItem(getSessionStorageKey(), JSON.stringify({
          data: awsPart,
          ttl: Utils.addMinutes(new Date(), 10).getTime()
        }));

        setAwsAnalyticsData(awsPart);

      }).catch((error) => {
        console.log('Error on getting AWS analytics', error);
      });
    }

    fetchData().then((analytics)=>{
      if(analytics?.data?.status === 'success') {
        const dataObj = {...analyticsData, ...{
          jobsCreated: analytics?.data?.data?.jobsCreated,
          jobsWithStatusNeedsAttention: analytics?.data?.data?.jobsWithStatusNeedsAttention,
        }
        };
        setAnalyticsData(dataObj);

        if(Utils.isToday(selectedDate)) {
          const awsPart = {
            jobsCompleted: analytics?.data?.data?.jobsCompleted || 0,
            jobsScheduled: analytics?.data?.data?.jobsWithHERSStatusScheduled || 0,
            permitCompleted: analytics?.data?.data?.jobsWithStatusCompleted || 0,
          };
          setAwsAnalyticsData(awsPart);
        }
      }
    }).catch((error) => {
      console.log('Error on getting analytics', error);
    });

  }, [selectedDate]);

  const welcomeBox =
    <Box sx={{
      fontWeight: '500',
      display: 'flex' ,
      flexDirection:'column',
      position: 'relative',
      width: `${queries.isSM ? '50vw' : 'unset'}`
      // paddingLeft: isXS ? myTheme.spacing(2) : isSM?myTheme.spacing(4):myTheme.spacing(6),
    }}>
      <Box display={'flex'} flexDirection={'row'}>
        <Typography variant={'h6'}>
      Welcome,

        </Typography>
        <Icon style={{height: '1.5em', marginLeft: '10px'}} aria-hidden>
          <img alt={'clap-icon'} src={clapIcon} style={{height: '20px'}}/>
        </Icon>
      </Box>
      <Typography variant={'subtitle2'} style={{fontWeight: '600'}}>
        {user?.userName?.firstName || user?.userName?.lastName ? username : ''}
      </Typography>
    </Box>;

  const todayBox =  <Box mr={2}>
    <Typography variant={'subtitle2'} style={{lineHeight: 2, fontWeight: 'bold'}}>Today&#39;s summary</Typography>

    <Typography variant={'subtitle1'}>{selectedDate.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})}</Typography>
  </Box>;

  const layoutTrapezoidButtons = (buttons) => {
    const numberOfButtonsInLayout = buttons.length;
    const trapezoidButtons = [];

    buttons.forEach((element, index) => {
      //console.log(element.text + index + numberOfButtonsInLayout);
      trapezoidButtons.push(<TrapezoidButton
        text={element.text}
        icon={element.icon}
        type={element.type}
        currentButtonNumber={index+1}
        path={element.path}
        numberedButtonLayout={numberOfButtonsInLayout}/>);
    });
    return trapezoidButtons;
  };

  return (
    <>
      <NavBar/>
      <Box className={clsx(classes.home_content_container, classes.page)} sx={{
        height: '100%'}}>
        <Grid container height={'100%'} pt={1}>
          <Grid item sm={12} lg={5} sx={{display: {xs: 'none', sm: 'flex', md: 'none', lg: 'flex'},
            flexDirection: {sm: 'row', lg: 'column'}}}>
            {welcomeBox}

            {(queries.isLG || queries.isXL) && <Box style={{
              display: 'flex',
              transform: `${queries.isXL ? 'scale(1.2)' : 'scale(0.95)'}`,
              flex: 1,
              alignItems: 'center',
              height: '100%'}} className={'circle'}>
              <Box className={classes.circle_container}>
                {/* iPermit User Buttons */}
                <NewPermissionGate user={user}
                  elementPermissions={generalRules[GENERAL_ELEMENTS.ipermitHomeButtons]}
                >
                  {layoutTrapezoidButtons([HOME_PAGE_BUTTONS.JOBS, HOME_PAGE_BUTTONS.PRODUCTS, HOME_PAGE_BUTTONS.USERS, {...HOME_PAGE_BUTTONS.COMPANY, ...{text: 'Companies'}}])}
                </NewPermissionGate>
                {/* Contractor User Buttons */}
                {!isIpermitUser(user.role) && <NewPermissionGate user={user}
                  elementPermissions={generalRules[GENERAL_ELEMENTS.contractorUserHomeButtons]}>
                  {layoutTrapezoidButtons([HOME_PAGE_BUTTONS.JOBS, HOME_PAGE_BUTTONS.CREATE_JOBS, HOME_PAGE_BUTTONS.ANALYTICS, HOME_PAGE_BUTTONS.COMPANY])}
                </NewPermissionGate>}
                {/* Contractor Admin Buttons */}
                <NewPermissionGate user={user}
                  elementPermissions={generalRules[GENERAL_ELEMENTS.contractorAdminHomeButtons]}
                >
                  {layoutTrapezoidButtons([HOME_PAGE_BUTTONS.JOBS, HOME_PAGE_BUTTONS.CREATE_JOBS, HOME_PAGE_BUTTONS.ANALYTICS, HOME_PAGE_BUTTONS.USERS, HOME_PAGE_BUTTONS.COMPANY])}
                </NewPermissionGate>
              </Box>
            </Box>}
            {queries.isSM && <Box display={'flex'}
              width={'46vw'}
              justifyContent={'space-around'}
              mb={4}>
              {todayBox}
              <Box position={'relative'}>
                <DatePicker handler={datePickerHandler}/>
              </Box>
            </Box>}

          </Grid>
          <Grid item md={5} lg={3}  xs={12} sx={{
            display: 'flex', flexDirection: 'column'}} >
            {!queries.isSM && <Box display={'flex'}
              width={queries.isXS|| queries.isSM ?'93vw' :'100%'}
              justifyContent={queries.isXS || queries.isSM || queries.isMD ?'space-between' :'left'}
              mb={queries.isXS ? 2 : 4}>
              {queries.isMD &&  welcomeBox}
              <Box display={'flex'} flexDirection={'row'}>
                {todayBox}

                <Box position={'relative'}>
                  <DatePicker handler={datePickerHandler}/>
                </Box>
              </Box>


            </Box>}
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
              sx={{width: '100%', height: '100%'}}>
              <Box sx={{
                transform: `${queries.isXL ? 'translateY(-25%) scale(1.1)' : queries.isLG ? 'translateY(-18%) scale(0.9)' : 
                  queries.isMD ? 'translateY(-18%) scale(0.9)' : 'scale(0.9)'}`,
              }}>
                <Box className={classes.hexagon}>
                  <Box pt={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Box>Jobs</Box>
                    <Box>Completed</Box>
                  </Box>

                  <Box className={classes.hexagon_ribbon}>

                    <Box>
                      {!awsAnalyticsData.jobsCompleted && awsAnalyticsData.jobsCompleted !== 0 && <GettingDataProgress/>}
                      {(awsAnalyticsData.jobsCompleted || awsAnalyticsData.jobsCompleted === 0) && awsAnalyticsData.jobsCompleted}</Box>
                  </Box>
                </Box>
              </Box>

            </Box>

          </Grid>
          <Grid item md={7} lg={4} xs={12} >
            <Box display={'flex'} sx={{flexFlow: 'column',
              height: '100%',
              alignItems: 'center',
              transform: `${queries.isXL ? 'scale(1.1)':  queries.isLG ? 'none' : 'scale(0.9)'}`,
            }}>

              <Box sx={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'center',
                minWidth: '100px',
                maxWidth: `${queries.isSM ? '100%' :'470px'}`,
              }} >
                <Grid container flexWrap={'nowrap'}
                  flexDirection={'column'} justifyContent={'space-around'}>
                  <Grid item xs={12} sm={6} sx={{
                    order: `${queries.isSM ? 1 : 1}`
                  }}> <Box width={370} sx={bookmarkMargins(queries)}>
                      <BookmarkPaper sizeQuery={queries} right icon={'arrow-up-right'} text={'Jobs Created'} count={(analyticsData.jobsCreated||0).toString()}/>
                    </Box></Grid>
                  <Grid item xs={12} sm={6} sx={{
                    order: `${queries.isSM ? 3 : 2}`
                  }}>
                    {!isTestingOnly && <Box width={370} sx={bookmarkMargins(queries)}>
                      <BookmarkPaper sizeQuery={queries} right icon={'checkmark'} text={'Permit Completed'}
                        count={(awsAnalyticsData.permitCompleted||0).toString()}
                        isFetching={!awsAnalyticsData.permitCompleted && awsAnalyticsData.permitCompleted !== 0}/>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{
                    order: `${queries.isSM ? 2 : 3}`
                  }}><Box width={370} sx={{...bookmarkMargins(queries), ...{
                      transform: 'translateX(20px)'
                    }}}>
                      <BookmarkPaper sizeQuery={queries} icon={'watch'} text={'Needs Attention Jobs'}
                        count={(analyticsData.jobsWithStatusNeedsAttention || 0).toString()}/>
                    </Box></Grid>
                  <Grid item xs={12} sm={6} sx={{
                    order: `${queries.isSM ? 4 : 4}`
                  }}>
                    {isHERS && <Box width={370} sx={{
                      ...bookmarkMargins(queries), ...{
                        transform: 'translateX(20px)'
                      }
                    }}>
                      <BookmarkPaper sizeQuery={queries} icon={'list'} text={'HERS Test Scheduled'} count={(awsAnalyticsData.jobsScheduled || 0).toString()}
                        isFetching={!awsAnalyticsData.jobsScheduled && awsAnalyticsData.jobsScheduled !== 0}
                      />
                    </Box>}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>

  );
};
export default Home;

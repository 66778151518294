import React, {useState, useCallback, useEffect, useRef} from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {createStyles, makeStyles} from '@mui/styles';
import {Select, MenuItem, Chip, Box, ListSubheader, TextField} from '@mui/material';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import {CustomInput} from './customInputComponent';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import PropTypes from 'prop-types';
import CustomSelect from './select';
import {useFetch} from '../../hooks/useFetch';
const _ = require('lodash');

const Suggestions = (props) => {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const {loading, error, list} = useFetch(query, page);
  const loader = useRef(null);
  const handleChange = (e) => {
    setQuery(e.target.value);
    e.target.focus();
  };

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, props.selected]);

  const getOptionsToRender = ()=> {
    const options = [<MenuItem aria-label="None" value="" key={'0none'} style={{padding: '0'}}/>];
    const currOptions = Array.from(list);
    // const additionalOption = verifyValueAndOptions();
    // if(additionalOption) {
    //   currOptions.unshift(additionalOption);
    // }
    currOptions.forEach((e, idx)=> {
      options.push(<MenuItem value={e.value}
        key={`${e.value}${idx}`}
        onClick={()=>{console.log('click'); props.handleChange(e.value);}}>
        {e.name}</MenuItem>);
    });

    options.push(<MenuItem value="unknown" key={'unknown'} disabled={true}><div ref={loader} /></MenuItem>);
    options.push(<MenuItem value="other" key={'other'} disabled={true}>{loading && <p>Loading...</p>}</MenuItem>);
    options.push(<MenuItem value="none" key={'none'} disabled={true}>{error &&  <p>Error!</p>}</MenuItem>);

    return  options;
  };

  return (
    <Box sx={{
      maxWidth: '300px',
    }}>
      <ListSubheader>
        <TextField
          size="small"
          value={query}
          placeholder="Type to search..."
          fullWidth
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={true}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />

      </ListSubheader>
      {getOptionsToRender()}
    </Box>
  );
};

Suggestions.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,
  handleChange: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: 2,
      backgroundColor: STYLE_CONSTANTS.COLORS.lightGray,
      height: 24,
      borderRadius: 4,
      '& .MuiChip-deleteIcon': {
        fontSize: 14,
      }
    },
    noLabel: {
      marginTop: theme.spacing(3)
    }
  })
);


const MultipleSelectWithSearch = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState( props.defaultValue || []);
  const inputRef = React.useRef();

  useEffect(()=> {
    setSelected(props.defaultValue || []);
  }, [props.viewMode]);

  const {setValueToForm, field_name} = props;

  const handleChange = useCallback((selectedValue) => {
    setSelected((previousState) => {
      const newState = _.cloneDeep(previousState);
      newState.push(selectedValue);
      setValueToForm(field_name, newState, {shouldDirty: true, shouldValidate: true});
      return newState;
    });
  }, []);

  const fieldIsDisabled = props.viewMode || props.disabled;

  const handleDelete = (e, value) => {
    e.preventDefault();
    if(fieldIsDisabled) return;
    setValueToForm(field_name, _.without(selected, value), {shouldDirty: true});
    setSelected((current) => _.without(current, value));
  };

  // const options = [];
  // const filteredOptions = props.options.filter((opt)=>!selected.includes(opt.value));
  // filteredOptions.forEach((e, idx)=> {
  //   options.push(<MenuItem value={e.value} key={`${e.value}${idx}`} onClick={()=>handleChange(e.value)}>{e.name}</MenuItem>);
  // });

  // console.log('selected', selected);



  return (
    <Box sx={{
      '& .MuiSelect-multiple': {
        width: props.width || '300px',

      },
    }}>


      <Select
        labelId={`${props.field_name}-select-label`}
        id={`${props.field_name}-select`}
        multiple
        value={selected}
        // onChange={(e)=> { console.log('on change'); e.preventDefault(); e.stopPropagation();}}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          sx: {maxHeight: '300px'},
          variant: 'menu',
          autoFocus: false,
          disableAutoFocusItem: true,
          // getContentAnchorEl: () => null,

        }}
        // onOpen={(e) => console.log('select opened', e)}
        input={<CustomInput id={`${props.field_name}-input` }
          name={props.field_name}  ref={inputRef}
          error={props.hasError}
          disabled={fieldIsDisabled}
          fullWidth={props.fullWidth}/>}
        renderValue={() => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                clickable = {!fieldIsDisabled}
                deleteIcon={
                  fieldIsDisabled ? <></> : <ClearSharpIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                className={classes.chip}
                onDelete={(e) => handleDelete(e, value)}
                // onClick={(e) => console.log('clicked chip', e.target)}
                onMouseDown={e => {
                  e.stopPropagation();
                }}

              />
            ))}
          </div>
        )}
      >
        <Suggestions selected={selected} handleChange={handleChange}/>


      </Select>

    </Box>
  );
};
MultipleSelectWithSearch.displayName = 'MultipleSelect';
MultipleSelectWithSearch.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired
  }
};
CustomSelect.defaultProps = formBlockDefaultProps;

export default MultipleSelectWithSearch;

import ApiUtils from '../../../utils/apiUtils';
import Utils from '../../../utils/utils';
import * as allConstants from '../../../constants/allConstants';

export const orderInspection = async (jobId, updateJob, gridController)=> {
  if(!jobId) {
    Utils.errorToastMessage('Job id is not provided');
    return;
  }

  const payload = {
    id: jobId
  };
  try {
    const response = await ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/order-inspection', JSON.stringify(payload));
    if(response?.status === 'success' && updateJob) {
      updateJob(response?.data?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]);
    }
    Utils.handleToastMessage(response, 'Inspection ordered successfully',
      `Error on adding inspection::: ${JSON.stringify(response?.message)}`,
      'inspection_order_success');
    if(gridController) {
      gridController.refreshOnSuccess(response);
    }
  } catch(e) {
    Utils.errorToastMessage(`Error on adding inspection::: ${JSON.stringify(e)}`, 'crm_force_add_error');
  }

};

export const jobHasInspection = (job)=> {
  const jobInspection = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name];
  return !!jobInspection;
};

export const canDoInspection = (job)=> {
  const jobType = job?.[allConstants.JOB_FIELDS.JOB_TYPE.api_name];
  const isDraft = job?.[allConstants.JOB_FIELDS.IS_DRAFT.api_name];
  if(isDraft) {
    return false;
  }
  return jobType !== allConstants.JOB_TYPES.BUSINESS_LIC.value;
};

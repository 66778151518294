import React from 'react';
import PropTypes from 'prop-types';
import {COLORS} from '../../styles/styleConstants';
import {Box, Typography, Grid, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Hidden} from '@mui/material';
import ModalWrapper from './modalWrapper';
import BlockedImage from '../../static/images/warningImage.png';

const headerText = {
  width: '70vw',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const ConfirmModal = (props)=> {
  return (
    <ModalWrapper modalName={'confirm-action'}
      handleClose={props.handleClose}
      open={props.open}
      preventScroll={true}
      width={{sx: '98%', md: '600px'}}
      padding={'16px 48px'}

    >
      <Grid container alignItems={'baseline'} position={'relative'}>
        <Box position={'absolute'} top={5} right={-25}>
          <IconButton
            sx={{backgroundColor:COLORS.backgroundMain}}
            onClick={props.handleClose}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Grid item display={'flex'} alignItems={'flex-end'}
          justifyContent={props.hideHeader ? 'center' : 'space-between'} xs={12}
          paddingBottom={3}
        >
          <Box display={'flex'} alignItems={'center'}
            borderBottom={props.hideHeader ? 'none' : `2px solid ${COLORS.globalBlue}`}
          >
            <img src={BlockedImage} alt={''} width={50}/>
            {!props.hideHeader && <Hidden smDown>
              <Typography variant={'h4'} sx={{lineHeight: '2.5em', marginLeft: '16px'}}>{props.header}</Typography>
            </Hidden>}
          </Box>

        </Grid>
        {!props.hideHeader && <Hidden smUp>
          <Grid item xs={12}>
            <Typography variant={'h5'} sx={{...headerText}}>{props.header}</Typography>
          </Grid>
        </Hidden>}
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  hideHeader: PropTypes.bool,
};
ConfirmModal.defaultProps = {
  open: false,
  header: 'Please confirm',
  hideHeader: false,
};
export default ConfirmModal;

import React from 'react';
import Box from '@mui/material/Box';
import {useCreateJobStyles} from '../createJobStyles';

const SectionWrapper = (props)=> {
  const classes = {...useCreateJobStyles()};
  return (
    <Box className={classes.tab_container} sx={{p: 3, pl: 0, columnGap: {xs: '10px', md: '20px'}, display: 'flex'}}>
      {props.children}
    </Box>
  );
};

export {SectionWrapper};

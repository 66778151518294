import {ELEMENTS_NOTES, notesRules} from '../../permissions/ipermitAccessControl/notesAccessControl';
import {ELEMENTS_ATTACHMENTS, attachmentRules} from '../../permissions/ipermitAccessControl/attachmentsAccessControl';

const NOTE_TYPES = {
  PERMIT: {api_name: 'Permit', display: 'Permit', value: 'Permit', showOrder: 0, def: false, permission: notesRules[ELEMENTS_NOTES.addPermitNote]},
  HERS: {api_name: 'HERS', display: 'HERS', value: 'HERS', showOrder: 1, def: false, permission: notesRules[ELEMENTS_NOTES.addHersNote]},
  INSPECTION: {api_name: 'Inspection', display: 'Inspection', value: 'Inspection', showOrder: 2, def: false, permission: notesRules[ELEMENTS_NOTES.addInspectionNote]},
  SCHEDULING: {api_name: 'Scheduling', display: 'Scheduling', value: 'Scheduling', showOrder: 3, def: false, permission: notesRules[ELEMENTS_NOTES.addSchedulingNote]},
};

const ATTACHMENT_TYPES = {
  PERMIT: {api_name: 'Permit', display: 'Permit', value: 'Permit', showOrder: 0, def: false, permission: attachmentRules[ELEMENTS_ATTACHMENTS.addPermitAttachment]},
  HERS: {api_name: 'HERS', display: 'HERS', value: 'HERS', showOrder: 1, def: false, permission: attachmentRules[ELEMENTS_ATTACHMENTS.addHersAttachment]},
  INSPECTION: {api_name: 'Inspection', display: 'Inspection', value: 'Inspection', showOrder: 2, def: false, permission: attachmentRules[ELEMENTS_ATTACHMENTS.addInspectionAttachment]},
  CF1R: {api_name: 'CF1R', display: 'CF1R', value: 'CF1R', showOrder: -1, def: false},
};

export {NOTE_TYPES, ATTACHMENT_TYPES};

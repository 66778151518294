import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {CustomInput} from './customInputComponent';
import {formBlockTypes, formBlockDefaultProps} from './formBlockProps';
import {InputAdornment, ClickAwayListener} from '@mui/material';
import {ReactComponent as CalendarIconSvg} from '../../static/images/calendar.svg';
import {useFormContext} from 'react-hook-form';


export default function ComposedTextField(props) {
  //console.log('Date input', props);
  const [open, setOpen] = React.useState(false);
  const [selectedDate, handleDateChange] = React.useState(props.defaultValue ? new Date (props.defaultValue) : null);

  const formMethods = useFormContext();
  const {setValueToForm, field_name} = props;
  useEffect(()=> {
    if(setValueToForm && selectedDate) {
      formMethods.clearErrors(field_name);
      setValueToForm(field_name, selectedDate?.toString(), {shouldDirty: true});
    }
  },[selectedDate, setValueToForm, field_name]);
  useEffect(()=> {
    handleDateChange(props.defaultValue ? new Date (props.defaultValue) : null);
  },[props.viewMode]);

  const isDisabled = !props.isEditable || props.viewMode;

  //console.log('inside date inpust');
  //console.log(props);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ClickAwayListener
        disableReactTree={true}
        mouseEvent={false}
        onClickAway={() => setOpen(false)}
      >
        <DatePicker
          inputFormat="MM/dd/yyyy"
          key={selectedDate}
          value={selectedDate}
          autoOk
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disabled={isDisabled}
          onChange={(date) => {
            handleDateChange(date);
          }}
          renderInput={params => (
            <CustomInput fullWidth={true}
              key={JSON.stringify(props.defaultValue)+JSON.stringify(selectedDate)}
              readOnly={isDisabled}
              // setValueToForm={props.setValueToForm}
              {...props.register(props.field_name, {required: props.field.required})}
              onClick={() => {if(!isDisabled) setOpen(true);}}
              {...params}
              error={props.isError}
              endAdornment={<InputAdornment position="end" sx={{right: '6px !important', transform: 'translateY(-2px)'}}>
                {
                  <CalendarIconSvg color={'#ced4db'}/>
                }
              </InputAdornment>}
            />
          )}
        />
      </ClickAwayListener>
    </LocalizationProvider>
  );
}

ComposedTextField.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired
  }
};
ComposedTextField.defaultProps = formBlockDefaultProps;

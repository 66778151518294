const VALID_FILE_TYPES = {
  PNG:  'image/png',
  JPG:  'image/jpeg',
  JPEG: 'image/jpeg',
  PDF:  'application/pdf',
  DOC:  'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS:  'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV:  'text/csv',
};

export default VALID_FILE_TYPES;

import React from 'react';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import * as allConstants from '../../../constants/allConstants';
import Grid from '@mui/material/Grid';
import {Box, Typography, Card, CardContent} from '@mui/material';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import {inputTypes} from '../../../pieces/inputs/formInput';
import {companyDefaultProps, companyPropTypes} from './panelProps';
import {useFormContext} from 'react-hook-form';
import {NotAllowedToEditSection} from './notAllowedToEditSection';

export const OwnerInfo = (props)=> {
  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  const formMethods = useFormContext();

  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };

  const isIpermitInvite = props.tabProps.isIpermitInvite;

  const companyData = getCompanyData();

  const getFileDefault = (field, parent)=> {
    const savedFile = sessionStorage.getItem(`${parent}.${field}`);
    const savedName = sessionStorage.getItem(`${parent}.${field}_name`);
    if(savedFile) {
      return {id: 'file ready to upload', name: savedName};
    } else {
      const formData =  getDefaultValue(parent);
      return {
        id: formData?.[field] || formData?.fileId,
        name: formData?.fileName
      };
    }
  };

  const getDefaultValue = (filed, parent = null) => {
    if (parent) {
      return companyData?.[parent]?.[filed];
    }
    return companyData?.[filed];
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%',
    nested: true,
    parent: COMPANY_FIELDS.OWNER.api_name
  };

  const getDefaultOwnerNameToDisplay = ()=> {
    let name = '';
    const ownerInfo = companyData?.[COMPANY_FIELDS.OWNER.api_name];
    if(ownerInfo) {
      const firstName = ownerInfo?.[COMPANY_FIELDS.OWNER_NAME.api_name] || '';
      const lastName = ownerInfo?.lastName || '';
      name = name+`${firstName} ${lastName}`;

    }
    return name;
  };

  return (
    <>
      <Grid container width={'100%'} spacing={3}>
        <NotAllowedToEditSection show={props.tabProps.notAllowedToEdit}/>
        <Grid item sm={12} md={6} flex={1}>
          <Grid container flex={1} spacing={0}>
            <Grid item sm={12}>
              <Card className={classes.card_block}>
                <CardContent className={classes.half_width}>
                  <CustomFormBlock
                    {...props.inputDefaultProps}
                    {...commonFieldProps}
                    field={COMPANY_FIELDS.OWNER_NAME}
                    defaultValue={getDefaultOwnerNameToDisplay()}
                    isEditable={true}

                  />
                  <CustomFormBlock
                    {...props.inputDefaultProps}
                    {...commonFieldProps}
                    inputType={inputTypes.phone}
                    field={COMPANY_FIELDS.PHONE_NUMBER}
                    defaultValue={getDefaultValue(COMPANY_FIELDS.PHONE_NUMBER.api_name, COMPANY_FIELDS.OWNER.api_name)}
                    isEditable={true}

                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card className={classes.card_block}>
                <CardContent className={classes.half_width}>
                  <Typography className={classes.card_section_header}>Owner&#39;s location information</Typography>

                  <CustomFormBlock
                    {...props.inputDefaultProps}
                    {...commonFieldProps}
                    inputType={inputTypes.address}
                    field={COMPANY_FIELDS.ADDRESS}
                    renderLabel={false}
                    defaultValue={getDefaultValue(COMPANY_FIELDS.OWNER_ADDRESS.api_name, COMPANY_FIELDS.OWNER.api_name)}

                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} flex={1} >
          <Grid container height={'100%'}>
            <Grid item sm={12} height={'100%'} >
              <Card className={classes.card_block} sx={{
                height: '100%'}}>
                <CardContent className={classes.half_width}>
                  <Typography className={classes.card_section_header}>Driver license information</Typography>

                  <CustomFormBlock
                    {...props.inputDefaultProps}
                    {...commonFieldProps}
                    inputType={inputTypes.file}
                    field={{...COMPANY_FIELDS.DRIVER_LICENSE_FILE, ...{required: !isIpermitInvite}}}
                    parent={COMPANY_FIELDS.DRIVER_LICENSE.api_name}
                    defaultValue={getFileDefault(COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name, COMPANY_FIELDS.DRIVER_LICENSE.api_name)}
                    dontUploadImmediately={props.tabProps.dontUploadImmediately}

                  />
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...props.inputDefaultProps}
                      {...commonFieldProps}
                      field={COMPANY_FIELDS.DRIVER_LICENSE_NUMBER}
                      parent={COMPANY_FIELDS.DRIVER_LICENSE.api_name}
                      defaultValue={getDefaultValue(COMPANY_FIELDS.DRIVER_LICENSE_NUMBER.api_name, COMPANY_FIELDS.DRIVER_LICENSE.api_name)}

                    />

                    <CustomFormBlock
                      {...props.inputDefaultProps}
                      {...commonFieldProps}
                      inputType={inputTypes.date}
                      field={{...COMPANY_FIELDS.DRIVER_LICENSE_EXPIRES_AT, ...{required: !isIpermitInvite}}}
                      parent={COMPANY_FIELDS.DRIVER_LICENSE.api_name}
                      defaultValue={getDefaultValue(COMPANY_FIELDS.DRIVER_LICENSE_EXPIRES_AT.api_name, COMPANY_FIELDS.DRIVER_LICENSE.api_name)}

                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

OwnerInfo.propTypes = companyPropTypes;
OwnerInfo.propTypes = companyDefaultProps;


const ELEMENTS_CF1R = {
  editCf1rInfo: 'editCf1rInfo',
  manageCf1r: 'manageCf1r',     //set pending/completed manually
};

const cf1rPolicies = {
  editCf1rInfo: 'job.createCf1r',
  manageCf1r: 'job.manageCf1r',
};

const cf1rRules = Object.freeze({
  [ELEMENTS_CF1R.editCf1rInfo]: {
    policyName: cf1rPolicies.editCf1rInfo,
  },
  [ELEMENTS_CF1R.manageCf1r]: {
    policyName: cf1rPolicies.manageCf1r,
  },
});

export {cf1rRules, ELEMENTS_CF1R};


import base_url from '../baseUrls';
import ApiUtils from './apiUtils';
import axios from 'axios';
import Utils from './utils';

const fetch = async (id) => {
  let url = `${base_url.api}files/${id}`;
  const authToken = await ApiUtils.getAccessToken();
  const data = await axios.get(url, {headers: {Authorization: authToken}, responseType: 'blob'});
  return data;
};

const downloadFile = ({id}) => {
  console.log('download file: ', id);

  fetch(id)
    .then((response) => {
      const fileName = Utils.getContentDispositionFileName(response);
      const blob = new Blob([response.data], {type: 'blob'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((e) => {
      console.log('Download file error: ', e);
      Utils.errorToastMessage('Please try again', 'get_files');
    });
};

export default downloadFile;

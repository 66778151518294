import React from 'react';
import PropTypes from 'prop-types';
import iconPath from './iconsLib';
import {v4 as uuidv4} from 'uuid';

const defaultStyles = {
  display: 'inline-block',
  verticalAlign: 'middle',
  fill: 'none',
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};

const CustomIcon = ({size, color, icon, className, style, viewBox, alt}) => {
  const styles = {...defaultStyles, ...style};
  return (
    <svg
      className={className}
      style={styles}
      viewBox={viewBox}
      width={`${size}px`}
      preserveAspectRatio="xMidYMid meet"
      //height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      stroke={color}
      fill={color}
      title={alt}
    >
      {iconPath[icon].map((p) => <g key={uuidv4()}><path d={p} /></g>)}
    </svg>
  );
};

CustomIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  alt: PropTypes.string
};

CustomIcon.defaultProps = {
  size: 24,
  color: '#000000',
  viewBox: '0 0 24 24',
  style: {},
  className: '',
  alt: 'icon'
};

export default CustomIcon;

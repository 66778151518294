import React from 'react';
import {List, ListItem, Button, Typography} from '@mui/material';
import clsx from 'clsx';
import useStyles from './analyticsMenuBarStyles';
import ActiveRoof from '../../static/images/iPermit_roof.svg';
import GrayRoof from '../../static/images/iPermit_roof_gray.svg';
import PropTypes from 'prop-types';
import AnalyticSideBarItems from './analyticSideBarItems';

const AnalyticsMenuBar = (props) => {
  // const {className, ...rest} = props;
  const classes  = useStyles();

  const handleMenu = () => {
    return AnalyticSideBarItems.map(({name, route, label}, index) => {
      const isActive = props.selected === name;
      const element = (name !== 'hers' || props.showHers) ?
        <List component="div" disablePadding key={ name }>
          <ListItem
            className={classes.item}
            disableGutters
            style={{padding:'0px'}}
            key={name}
          >
            <Button
              className={clsx({
                [classes.btnRoot] : true,
                [classes.button] : true,
              })}
              onClick={() => {props.changeView(name);}}
            >
              <img src={isActive ? ActiveRoof : GrayRoof} className={classes.nav_icon} alt={`${isActive ? 'active_nav_icon' : 'nav_icon'}`}/>
              <Typography variant={'subtitle1'} className={clsx(classes.nav_text, isActive ? '' : classes.not_active)}>{label}</Typography>
            </Button>
          </ListItem>
        </List> : null;
      return (
        element
      );
    });
  };
  return (
    <List className={clsx(classes.root)}>
      { handleMenu() }
    </List>
  );
};

AnalyticsMenuBar.propTypes = {
  showHers: PropTypes.bool,
  changeView: PropTypes.func,
  selected: PropTypes.string
};

export default AnalyticsMenuBar;

import PropTypes from 'prop-types';

const companyPropTypes = {
  tabProps: PropTypes.shape({
    companyData: PropTypes.instanceOf(Object),
    isSelfRegistering: PropTypes.bool,
    isIpermitInvite: PropTypes.bool,
    isCompleteonboarding: PropTypes.bool,
    user: PropTypes.instanceOf(Object),
  }),
  inputDefaultProps: PropTypes.shape({
    viewMode: PropTypes.bool,
    isEditable: PropTypes.bool,
    renderLabel: PropTypes.bool,
    nested: PropTypes.bool,
    parent: PropTypes.string
  })
};

const companyDefaultProps = {
  inputDefaultProps: {
    viewMode: true,
    isEditable: true,
    renderLabel: true,
    nested: false,
    parent: null,
  },
  tabProps:{
    companyData: {},
    isSelfRegistering: false,
    isIpermitInvite: false,
    isCompleteonboarding: false,
    user: {}
  }
};

export {companyPropTypes, companyDefaultProps};

import LOCAL_STORAGE_KEYS from './localStorageKeys';
import base_url from '../baseUrls';

const axios = require('axios').default;


const ApiUtils = {
  isDevelopment: ()=> {
    return process.env.NODE_ENV === 'development';
  },
  isStaging: ()=> {
    return process.env.NODE_ENV === 'staging';
  },
  isMockMode: ()=> {
    return process.env.NODE_ENV === 'development' && process.env.REACT_APP_MODE === 'mock';
  },
  getAccessToken: async () => {

    try {
      const tokensString = localStorage.getItem(LOCAL_STORAGE_KEYS.tokens);
      if (!tokensString || tokensString === 'undefined') {
        return undefined;
      }

      const tokens = JSON.parse(tokensString);

      if (tokens.accessToken.expiresAtMs - Date.now() < 60000) {
        //console.log('need to get new token');
        // Less than 1 min left for expiration, so we refetch first and then send.
        let newAccessToken = await ApiUtils.requestNewAccessToken(tokens.accessToken.token, `Bearer ${tokens.refreshToken}`);

        tokens.accessToken = newAccessToken.accessToken;
        tokens.refreshToken = newAccessToken.refreshToken;
        localStorage.setItem(LOCAL_STORAGE_KEYS.tokens, JSON.stringify(tokens));

        return `Bearer ${newAccessToken.accessToken.token}`;
      } else {

        return `Bearer ${tokens.accessToken.token}`;
      }
    } catch (error) {
      console.error('Error when getting the access token', error);
      throw error;
    }
  },
  /**
   * Requests a new access token from a server
   * @param accessToken Access token for server validation
   * @param refreshToken Refresh token that is used to get a new access token
   * @param skipThrows If set to true, will skip all the throws, used in axiosConfig. Since we need to call this
   * when we get invalid access token or refresh token(Server needs this to potentially log some malicious activities)
   * setting this to true will prevent an infinite loop situation.
   * @returns promise containing the new access token
   */
  requestNewAccessToken: async (accessToken, refreshToken, skipThrows) => {
    try {
      // console.log('Request tokens');
      const response = await axios.post(base_url.api + '/users/token', {accessToken},
        {
          headers: {
            'Authorization': refreshToken,
          },
        }
      );
      //console.log("===>" , response)
      if (skipThrows) {
        return;
      }
      // const tokensAreValid = await validator.tokensAreValid(response.data.data.tokenPair);
      //   if(!tokensAreValid) {
      //     throw {"status": "error",
      //     "errorCode": ERROR_CODES.GET_INVALID_TOKEN_PAIR,
      //     "message": "Server return invalid token object"};
      // }

      return response.data.data.tokenPair;
    } catch(error) {
      console.error('Error while requesting access code', error);
      if (!skipThrows) {
        throw error.response.data;
      }
    }
  },
  /**
   *
   * @param {string} method
   * @param {string} endpoint
   * @param {string|undefined} payload
   * @return {Promise}
   */
  makeApiCall: async (method, endpoint, payload)=> {
    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }
    const url = `${base_url.api}${endpoint}`;

    const params = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    };
    if(payload) {
      params.body = payload;
    }
    const response = await fetch(
      url,
      params
    );
    let result = await response.json();
    if(result.status === 'error' && result.errorCode === 'INVALID_ACCESS_TOKEN') {
      //todo: redirect to login page
    }
    return result;
  }
};

export default ApiUtils;

export const CALL_LOGS_FIELDS = {
  CALL_LOG: {api_name: 'calls', required: false, label: 'cals', display_label: 'cals'},
  RESULT: {api_name: 'result', required: false, label: 'results', display_label: 'results'},
  DATE: {api_name: 'date', required: false, label: 'Date', display_label: 'Date'},
  TYPE: {api_name: 'type', required: false, label: 'Call Type', display_label: 'Call Type'},
  LOGGED_BY: {api_name: 'user', required: false, label: 'Scheduler', display_label: 'Scheduler'},
  NOTE: {api_name: 'note', required: false, label: 'note', display_label: 'note'},
  DATE_AND_TIME: {api_name: 'startTime', required: false, label: 'Date & Time', display_label: 'Date & Time'},
  OUTGOING_CALL: {api_name: 'outgoingCall', required: false, label: 'Outgoing Call', display_label: 'Outgoing Call'},
  CALL_RESULT: {api_name: 'result', required: false, label: 'Call Result', display_label: 'Call Result'},
  CREATED_BY:  {api_name: 'createdByUser', required: false, label: 'Scheduler', display_label: 'Scheduler'},
  DESCRIPTION: {api_name: 'description', required: false, label: 'Note', display_label: 'Note'},
  LAST_CALL_DATE: {api_name: 'lastCallDate', required: false, label: 'last call date', display_label: 'last call date'},
  LAST_HO_CALL_DATE: {api_name: 'lastHoCallDate', required: false, label: 'last HO call', display_label: 'last HO call'},
  CALL_HO_COUNT:  {api_name: 'callHoCount', required: false, label: 'Number of calls', display_label: 'Number of calls'},
  NUMBER_OF_CALLS: {api_name: 'numberOfCalls', required: false, label: 'number of calls', display_label: 'number of calls'},
  HOMEOWNER_FAILED_CALL_COUNTER: {api_name: 'homeownerFailedCallsCounter', required: false, label: 'number of calls', display_label: 'number of calls'}
};

import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Utils from '../../utils/utils';
import {globalUseClasses} from '../../styles/globalClasses';
import {Text} from '../text';

const useStyles = makeStyles(() => ({
  line_label: {
    alignSelf: 'baseline',
  },

}));
const FormLabel = (props)=> {
  if(!props.label || props.label === '') return null;

  return (
    <Text weight={'bold'} text={`${props.label}:`} pale={props.pale}/>
  );
};

const FormValue = (props)=> {
  return (
    <Text text={Utils.reformatValue(props.value)} color={props?.color} pale={props.pale} uppercase={true}/>
  );
};

const FormLine = (props) => {
  const classes = {...useStyles(), ...globalUseClasses()};
  //console.log('will return line for value', props.value, Utils.valueIsSpecified(props.value));
  if (!props.value || !Utils.valueIsSpecified(props.value)) return null;

  return (
    <Grid container alignItems="flex-end" sx={{
      fontSize: {xs: '12px', sm:'14px'},
      flexWrap: {xs: 'nowrap', sm: 'initial'}
    }}
    >
      <Grid item xs={6} md={props.longLabel ? 6 :5} lg={props.longLabel ? 6 :4} className={classes.line_label}>
        <FormLabel label={props.label}/>
      </Grid>
      <Grid item xs={6} md={props.longLabel ? 6 :7} lg={props.longLabel ? 6 :8}>
        <FormValue value={props.value}/>
      </Grid>
    </Grid>
  );
};

FormLabel.propTypes = {
  label: PropTypes.string,
  pale: PropTypes.bool
};
FormLabel.defaultProps = {
  label: null,
  pale: false
};
FormValue.propTypes = {
  value: PropTypes.any.isRequired,
  color: PropTypes.string,
  pale: PropTypes.bool
};

FormLine.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  longLabel: PropTypes.bool
};
FormLine.defaultProps = {
  value: null,
  longLabel: false
};

FormValue.defaultProps = {
  color: null,
  pale: false
};
export {FormLine, FormLabel, FormValue};

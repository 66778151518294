import React from 'react';
import PropTypes from 'prop-types';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import * as allConstants from '../../../../../constants/allConstants';
import {useFormContext} from 'react-hook-form';


const CleanOutsPanel = (props)=> {
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const subParent = `${FIELDS.REPAIR.api_name}.${FIELDS.CLEAN_OUTS.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.PLUMBING.api_name];

  const getFieldValue = (fieldName)=> {
    const blockValues = values?.[FIELDS.REPAIR.api_name]?.[FIELDS.CLEAN_OUTS.api_name];
    return blockValues?.[fieldName];
  };
  return <>
    <CustomFormBlock{...props}
      field={FIELDS.CLEANOUT_LOCATIONS}
      nested={true}
      parent={blockParent}
      defaultValue={getFieldValue(FIELDS.CLEANOUT_LOCATIONS.api_name)}
      renderLabel={true}
    />
  </>;
};

CleanOutsPanel.propTypes = {
  parent: PropTypes.string.isRequired,
};

export {CleanOutsPanel};

import PropTypes from 'prop-types';
import React from 'react';
import {Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Utils from '../../utils/utils';
// import Utils from '../../utils';

const MultipleBarChart = (props)=> {
  //console.log('MUL', props, Math.max(...props.y))
  const maxIndex = props.y.indexOf(Math.max(...props.y));
  const maxValue = Math.max(...props.y);
  const colorSchema = {
    general: props.blue ? STYLE_CONSTANTS.COLORS.chartBlue : STYLE_CONSTANTS.COLORS.chartGreen,
    max: props.blue ? STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.globalGreen
  };

  const getDataToDisplay = ()=> {

    return props.x.map((m, i)=> {
      return {
        name: m,
        [props.dataKey]: props.y[i],

      };
    });
  };

  const data = getDataToDisplay();
  let count = Utils.adjustTickCount(maxValue);

  return (
    <ResponsiveContainer width={'100%'} height={'90%'}>
      <BarChart
        width={props.width ? props.width : 680}
        height={props.height ? props.height : 280}
        data={data}
        margin={{
          top: 24,
          left: -20,
          // bottom: props.bottom ? props.bottom : 10
        }}
        maxBarSize={38}
      >
        <XAxis dataKey="name" tickSize={0} axisLine={false} dy={data[0]?.length} interval={0} fontSize={12}
          tick={{fill: STYLE_CONSTANTS.COLORS.fontPale,  width: '80px',
            textOverflow: 'ellipsis'}}/>
        <YAxis tickSize={0} axisLine={false} tickCount={count} fontSize={12}
          tick={{fill: STYLE_CONSTANTS.COLORS.fontPale}}/>
        {/*<Bar dataKey="count" onClick={handleClick}>*/}

        <Bar dataKey={props.dataKey}>
          {data.map((entry, index) => (
            <Cell cursor="pointer" fill={index === maxIndex ? colorSchema.max : colorSchema.general} key={`cell-${index}`} />
          ))}
        </Bar>
        <Tooltip cursor={{fill: 'none'}}
          contentStyle={{border: `1px solid ${colorSchema.max}`, borderRadius: '6px'}}
          labelStyle={{color: STYLE_CONSTANTS.COLORS.fontDarkSecondary, fontWeight: '700'}}
          itemStyle={{color: colorSchema.max}}/>
      </BarChart>
    </ResponsiveContainer>

  );

};
MultipleBarChart.propTypes = {
  height: PropTypes.number,
  //   bottom: PropTypes.number,
  x: PropTypes.instanceOf(Array).isRequired,
  y: PropTypes.instanceOf(Array).isRequired,
  blue: PropTypes.bool,
  dataKey: PropTypes.string,
  width: PropTypes.string,
};
MultipleBarChart.defaultProps= {
  height: null,
  blue: false,
  dataKey: 'count',
  width: null,
};

export default MultipleBarChart;

import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Button} from '@mui/material';

export const JobActionButtonsContained = (props)=> {
  const mainColor = props.mainColor ? props.mainColor : STYLE_CONSTANTS.COLORS.globalGreen;
  return (
    <Button
      onClick={props.action}
      disabled={props.disabled}
      sx={{
        backgroundColor: mainColor,
        textTransform: 'uppercase',
        borderRadius: '10px',
        paddingX: theme=> theme.spacing(2),
        color: STYLE_CONSTANTS.COLORS.white,
        width: props.width ? props.width : '120px',
        fontSize: {xs: '12px', xl: '14px'},
        border: `1px solid ${mainColor}`,
        '&:hover':{
          borderColor: mainColor,
          backgroundColor: STYLE_CONSTANTS.COLORS.white,
          color: mainColor,
        },
        '&.Mui-disabled':{
          backgroundColor: STYLE_CONSTANTS.COLORS.lightGray,
          borderColor: STYLE_CONSTANTS.COLORS.fontPale,
          color: STYLE_CONSTANTS.COLORS.fontPale,
        }
      }}>
      {props.text}
    </Button>
  );
};

export const JobActionButtonsOutlined = (props)=> {
  const mainColor = props.mainColor ? props.mainColor : STYLE_CONSTANTS.COLORS.globalGreen;
  return(
    <Button
      variant={'outlined'}
      onClick={props.action}
      disabled={props.disabled}
      sx={{
        textTransform: 'uppercase',
        borderRadius: '10px',
        paddingX: theme=> theme.spacing(2),
        color: mainColor,
        width: props.width ? props.width : '120px',
        fontSize: {xs: '12px', xl: '14px'},
        border: `1px solid ${mainColor}`,
      }}
    >{props.text}</Button>
  );
};

const buttonDefaultProps = {
  disabled: false,
  width: '',
  mainColor: null,
};

const buttonPropTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  mainColor: PropTypes.string,
};

JobActionButtonsContained.propTypes = buttonPropTypes;
JobActionButtonsContained.defaultProps = buttonDefaultProps;

JobActionButtonsOutlined.propTypes = buttonPropTypes;
JobActionButtonsOutlined.defaultProps = buttonDefaultProps;


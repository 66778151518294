import React, {useEffect, useState} from 'react';
import base_url from '../baseUrls';
import ApiUtils from '../utils/apiUtils';
import axios from 'axios';
import Loader from '../pages/misc/loader';
import * as allConstants from '../constants/allConstants';
import BlockedPage from '../pages/misc/blockedCompany';
import PropTypes from 'prop-types';

export const RecordController = (props)=> {
  const [isFetching, setFetching] = useState(false);
  const [recordData, setRecordData] = useState();
  const [accessDenied, setAccessDenied] = useState(false);
  const recordId = props.recordId;
  const module = props.module;
  const responseTarget = props.responseTarget;


  useEffect(() => {
    if(!recordId) return;
    const fetch = async () => {
      let url = `${base_url.api}${module}/${recordId}`;

      // Thanh 01/04/24
      //console.log('URL to fetch data => ' + url);
      const authToken = await ApiUtils.getAccessToken();
      const companyResponse= await axios.get(url, {headers: {Authorization: authToken}});
      return companyResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {

        //console.log(`got record ${responseTarget}`, data.data.data[responseTarget]);
 
        setRecordData(data.data.data[responseTarget]);
        setFetching(false);
      })
      .catch((error) => {
        if(error?.response?.data?.errorCode === allConstants.ERROR_CODES.COMPANY_BLOCKED) {
          setAccessDenied(true);
        }
        setFetching(false);
        console.log(error);
      });
  }, [recordId]);

  useEffect(()=> {
    props.saveRecord(recordData);
  }, [recordData]);

  if(recordId && isFetching) return <Loader/>;
  if(accessDenied) return <BlockedPage/>;
  if(recordId && !recordData) return null;

  return (
    <>
      {props.children}
    </>
  );

};

RecordController.propTypes = {
  recordId: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  responseTarget: PropTypes.string.isRequired,
  saveRecord: PropTypes.func.isRequired
};

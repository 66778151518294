import * as allConstants from '../constants/allConstants';
import Utils from '../utils/utils';

const roleTranslation = {
  [allConstants.ROLES.COMPANY_ADMIN]: 'Admin',
  [allConstants.ROLES.CONTRACTOR]: 'User',
  [allConstants.ROLES.IPERMIT_ADMIN]: 'iPermit Admin',
  [allConstants.ROLES.IPERMIT_USER]: 'iPermit User',
};


class Translator {
  constructor(model) {
    this.model = model;
  }

  translate = (def='')=> {

    if(Utils.objectHasProperty(this.model, def)) {
      return this.model[def];
    }
    return def;
  }
}
const roleTranslator = new Translator(roleTranslation);

export {roleTranslator};

const CITY_ELEMENTS = {
  addNote: 'addNote',
  addAttachment: 'addAttachment',
  editCity: 'editCity',
  createCity: 'createCity',
  viewCity: 'viewCity',
};

const cityPolicies = {
  modify: 'city.modify',
  modifyDocs: 'city.modifyDocs',
};

const cityRules = Object.freeze({
  [CITY_ELEMENTS.addNote]: {
    policyName: cityPolicies.modify,
    contractorAccess: false,
  },
  [CITY_ELEMENTS.createCity]: {
    policyName: cityPolicies.modify,
    contractorAccess: false,
  },
  [CITY_ELEMENTS.editCity]: {
    policyName: cityPolicies.modify,
    contractorAccess: false,
  },
  [CITY_ELEMENTS.viewCity]:{
    policyName: undefined,  //unrescrticted access to view city
    contractorAccess: false,
  },
  [CITY_ELEMENTS.addAttachment]: {
    policyName: cityPolicies.modifyDocs,
    contractorAccess: false,
  }
});

export {cityRules, CITY_ELEMENTS};

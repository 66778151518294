import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';

const scrollStyles = {
  '&::-webkit-scrollbar': {
    width: '0.2em',
    height:'0.5em',
    left: 0
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
    borderRadius: '4px'
  }
};

const useAllJobsViewStyles = makeStyles(theme=> {
  return {
    search_container: {
      width: '230px',
      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
      position: 'fixed',
      zIndex: 100,
      bottom: '50px',
      top: '106px',
      //paddingTop: '20px',
      paddingBottom: '20px',
      overflowY: 'scroll',
      ...scrollStyles,
      // '&::-webkit-scrollbar': {
      //   width: '0.2em',
      //   height:'0.5em',
      //   left: 0
      // },
      // '&::-webkit-scrollbar-track': {
      //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      // },
      // '&::-webkit-scrollbar-thumb': {
      //   backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
      //   borderRadius: '4px'
      // },
    }
  };
});

export {useAllJobsViewStyles, scrollStyles};

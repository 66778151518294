import {CITY_FIELDS, JOB_FIELDS, JOB_TYPES, PERMIT_MODULE_FIELDS} from '../../constants/allConstants';
import dayjs from 'dayjs';
import Utils from '../../utils/utils';
import * as allConstants from '../../constants/allConstants';
import {hvacDisplayUtils} from '../../pages/jobs/jobDetails/details/hvac/hvacDisplayUtils';
import {solarDisplayUtils} from '../../pages/jobs/jobDetails/details/solar/solarDisplayUtils';
import {electricalDisplayUtils} from '../../pages/jobs/jobDetails/details/electrical/electricalDisplayUtils';
import {reroofUtils} from '../../pages/jobs/jobDetails/details/reroof/reroofDisplayUtils';
import {tempPowerUtils} from '../../pages/jobs/jobDetails/details/tempPower/tempPowerDisplayUtils';
import {plumbingUtils} from '../../pages/jobs/jobDetails/details/plumbing/plumbingDisplayUtils';
import {jobDisplayUtils} from '../../pages/jobs/jobDetails/details/jobDisplayUtils';


class DataPreparation{
  constructor(data, src) {
    this.data = data;
    this.src = src;
    this.additional_data = JSON.parse(data[JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
    //console.log('Additions', this.additional_data);
    this.filterOldJobsData();
  }
  filterOldJobsData(){
    /**** START: filtering old Zoho CRM jobs *****/
    const jobName = this.data?.[JOB_FIELDS.JOB_NUMBER.api_name] || this.data?.[JOB_FIELDS.JOB_NUMBER.crm_name] || '';
    //console.log('jobName', jobName);
    this.isOldJob = jobName[0] === '1' && this.src === 'zoho';
    if(this.isOldJob) {
      const additional_data = JSON.parse(this.data.Job_JSON);
      const type = this.data.Job_Type.toLowerCase();
      const default_keys = new Set(['initial_note', 'general_scope', 'new_construction', 'asbestos', 'notes', 'duplicateOf', 'duplicate_info', 'commercial', 'hvac_com'], );
      switch (type) {
      case 'business license': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='business_lic' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        delete(this.data.Property_Type);
        break;
      }
      case 'misc': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='misc' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      case 'reroof': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='reroof' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      case 'plumbing': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='plumbing' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      case 'mechanical (hvac)':
      case 'testing only': {
        console.log('filtering,', additional_data);
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='hvac_res' || key==='commercial' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      case 'electrical': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='electrical' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      case 'solar': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='solar' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      case 'temporary power': {
        this.additional_data = Object.keys(additional_data)
          .filter(key => key==='temporary_power' || default_keys.has(key))
          .reduce((obj, key) => {
            obj[key] = additional_data[key];
            return obj;
          }, {});
        break;
      }
      default: {
        break;
      }
      }
    }
    /**** END: filtering old Zoho CRM jobs *****/
  }
  getCreatedInfoLine(isoDate){
    if (!isoDate || isoDate === null) return null;
    const day =   dayjs(new Date(isoDate)).format('MM/DD/YYYY');
    const time = Utils.formatIsoTimeStringWithOptions(isoDate);
    return `${day} AT ${time}`;
  }

  reformatValue (val){
    if(!val) return  null;
    let transformed = val;
    if(typeof val === 'number' && val === 0) {
      return '0';
    }
    if (val === null || typeof val === 'string' && val.length === 0) {
      return val;
    }
    if (typeof val === 'boolean') {
      transformed = val ? 'yes' : 'no';
    }
    if (typeof val === 'number') {
      transformed = val.toString();
    }
    if (Array.isArray(val) && val.length !== 0) {
      transformed = val.join(', ');
    }
    return transformed;
  }
  getBlock(fields, data=this.data) {
    const block = [];
    fields.forEach((f)=> {
      let value;
      if (data?.[f.api_name] && Utils.valueIsSpecified(data[f.api_name])) {
        value = data[f.api_name];
      } else if (data?.[f.crm_name] && Utils.valueIsSpecified(data[f.crm_name])) {
        value = data[f.crm_name];
      }
      if(Utils.valueIsSpecified(value)) {
        let modifiedValue = this.reformatValue(value);
        // console.log('fff', f);
        if(f?.transformValue) {
          modifiedValue = f.transformValue(modifiedValue);
        }
        block.push({value: modifiedValue, label: f.print_label || f.display_label});
      }
    });
    if(block.length === 0) return null;
    return block;
  }
  dateToDisplay(v) {
    // if(v.indexOf('T') !== -1) return this.getCreatedInfoLine(v)
    return dayjs(v).format('MM/DD/YYYY');
  }

  getDatesBlock (){
    const datesFields = [
      {...JOB_FIELDS.GENERAL.START_DATE, transformValue: (v)=> this.dateToDisplay(v)},
      {...JOB_FIELDS.GENERAL.NEED_BY_DATE, transformValue: (v)=> this.dateToDisplay(v)},
      {...JOB_FIELDS.GENERAL.INSP_REQUEST_DATE, transformValue: (v)=> this.dateToDisplay(v)},
      JOB_FIELDS.GENERAL.POST_JOB_CARD,
    ];
    return this.getBlock(datesFields);
  }

  getValueAndJobNumberBlock (){
    const prefix = [];
    if(this.jobApiType === JOB_TYPES.TESTING_ONLY.api_name) {
      prefix.push({...PERMIT_MODULE_FIELDS.NUMBER, ...{crm_name: 'Permit_Number', print_label: 'Permit#'}});
      prefix.push({...PERMIT_MODULE_FIELDS.DATE,
        ...{crm_name: 'Permit_Date', print_label: 'Permit Date', transformValue: (v)=> this.dateToDisplay(v)}});

    }
    const fields = [
      JOB_FIELDS.GENERAL.HERS_PO_NUMBER,
      JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER,
      {...JOB_FIELDS.GENERAL.JOB_VALUE, transformValue: (v)=>`$${v}`},
    ];
    let newPortalPermitBlock = [];
    if(this.jobApiType === JOB_TYPES.TESTING_ONLY.api_name && this.data?.[PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name]) {
      newPortalPermitBlock = this.getBlock([prefix[0], prefix[1]], this.data[PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name]) || [];
    }
    return [...newPortalPermitBlock, ...this.getBlock([...prefix, ...fields]) || []];
  }

  getCustomerInfoBlock (){
    const hoData = this.data?.[JOB_FIELDS.GENERAL.HOMEOWNER.api_name];
    if(hoData) {
      const customerBlock = {
        name: hoData?.[JOB_FIELDS.GENERAL.HO_NAME.api_name],
        address: Utils.createAddressString(this.data?.[JOB_FIELDS.GENERAL.ADDRESS.api_name]),
        email: hoData?.[JOB_FIELDS.GENERAL.HO_EMAIL.api_name] ,
        phone: hoData?.[JOB_FIELDS.GENERAL.HO_PHONE.api_name],
        altPhone: hoData?.[JOB_FIELDS.GENERAL.ALT_HO_PHONE.api_name],
      };

      return customerBlock;
    } else {
      return  {
        name: this.data?.[JOB_FIELDS.GENERAL.HO_NAME.crm_name],
        address: this.data[JOB_FIELDS.GENERAL.JOBSITE_ADDRESS.crm_name],
        email: this.data?.[JOB_FIELDS.GENERAL.HO_EMAIL.crm_name],
        phone: this.data?.[JOB_FIELDS.GENERAL.HO_PHONE.crm_name],
        altPhone: this.data?.[JOB_FIELDS.GENERAL.ALT_HO_PHONE.crm_name],
      };

    }

  }

  getCityBlock () {
    const cityData = this.data?.[allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name];
    if (!cityData) return {value: 'unknown', label: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.display_label, notes: ''};

    let closure_code = cityData?.[CITY_FIELDS.CLOSURE_CODE.api_name] || cityData?.[CITY_FIELDS.CLOSURE_CODE.crm_name] || '';
    let hours = cityData?.[CITY_FIELDS.HOURS.api_name] || cityData?.[CITY_FIELDS.HOURS.crm_name] || undefined;
    let name = cityData?.[CITY_FIELDS.NAME.api_name] || cityData?.[CITY_FIELDS.NAME.crm_name] || '';
    const city = {label: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.display_label, value: `${name} ${closure_code}`};
    if (hours) {
      city.notes = hours;
    }
    return city;
  }

  getHeaderBlock(){
    const createdBy = this.data?.[JOB_FIELDS.ORDERED_BY.api_name] || this.data?.[JOB_FIELDS.ORDERED_BY.crm_name];
    let createdByString = '';
    if(createdBy) {
      if(Utils.objectHasProperty(createdBy, allConstants.USER_FIELDS.FIRST_NAME.api_name)) {
        const first = createdBy?.[allConstants.USER_FIELDS.FIRST_NAME.api_name] || null;
        const last = createdBy?.[allConstants.USER_FIELDS.LAST_NAME.api_name] || null;
        createdByString = `${first} ${last}`;
      } else if(Utils.objectHasProperty(createdBy, 'name')) {
        createdByString = createdBy.name;
      }
    }

    let createdTime = '';
    const dataTime = this.data?.[JOB_FIELDS.CREATE_TIME.api_name] || this.data?.[JOB_FIELDS.CREATE_TIME.crm_name] || undefined;
    if(dataTime) {
      createdTime = this.getCreatedInfoLine(dataTime);
    }

    const type =  this.data?.[JOB_FIELDS.JOB_TYPE.api_name] || this.data?.[JOB_FIELDS.JOB_TYPE.crm_name] || undefined;
    this.jobApiType = allConstants.MAP_JOB_TYPE_VALUE_TO_API?.[type];
    const hersRequired = this.data?.[JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name] || this.data?.[JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.crm_name] || false;
    const eucRequired = this.data?.[JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name] || this.data?.[JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.crm_name] || false;
    const headerObj = {
      type: {value: type, label: 'type'},
      created_by: {value: `${createdTime} BY ${createdByString}`, label: 'CREATED'},
    };
    if(Utils.valueIsSpecified(hersRequired)) {
      headerObj.hers_required = hersRequired;
    }
    if(Utils.valueIsSpecified(eucRequired)) {
      headerObj.euc_required = eucRequired;
    }
    return headerObj;
  }

  createPermitInfoRowData(data){
    const fields = [allConstants.PERMIT_MODULE_FIELDS.CHECK_NUMBER,
      allConstants.PERMIT_MODULE_FIELDS.NUMBER,
      allConstants.PERMIT_MODULE_FIELDS.AMOUNT,
      allConstants.PERMIT_MODULE_FIELDS.DATE];

    return fields.map((f)=> {
      let value = data?.[f.api_name] || data?.[f.crm_name] || '';
      if(value && value.length !== 0 && f.api_name === PERMIT_MODULE_FIELDS.DATE.api_name) {
        value = this.dateToDisplay(value);
      }
      return value;
    });

  }

  getGeneralBlock (jobType){
    const fields = [
      JOB_FIELDS.GENERAL.NEW_CONSTRUCTION,
      JOB_FIELDS.GENERAL.PROPERTY_TYPE,
      JOB_FIELDS.GENERAL.ASBESTOS,
      JOB_FIELDS.GENERAL.SQFT
    ];
    let block = this.getBlock(fields) || [];
    if(!this.data?.[JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name]) {
      const newConstr = this.getBlock([JOB_FIELDS.GENERAL.NEW_CONSTRUCTION], this.additional_data) || [];
      block = [...block, ...newConstr];
    }

    if(Utils.equalsIgnoreCase(jobType, JOB_TYPES.COMMERCIAL.value)) {
      const comInfo = this.additional_data?.[JOB_TYPES.COMMERCIAL.api_name] || this.additional_data?.[JOB_TYPES.COMMERCIAL.crm_name];
      if(comInfo) {
        const comFields = [
          JOB_FIELDS.COMMERCIAL.BUILDING_PERMIT_NUMBER,
          JOB_FIELDS.COMMERCIAL.WITNESS_TEST
        ];
        const comBlock = this.getBlock(comFields, this.additional_data) || [];
        if(comBlock) {
          block = {...block, ...comBlock};
        }
      }
    }
    return block;
  }
  getCRMPermitInfo(){
    return  [
      ['PERMIT', this.data?.Permit_Check_Number || '', this.data?.Permit_Number || '', this.data?.Permit_Amount|| '', this.data?.Permit_Date || ''],
      ['LICENSE', this.data?.License_Check_Number || '', this.data?.License_Number || '', this.data?.License_Amount || '', this.data?.License_Date || ''],
      ['MISC', this.data?.Misc_Check_Number || '', this.data?.Misc_Number || '', this.data?.Misc_Amount || '', this.data?.Misc_Date || ''],
      ['CARD POSTED:', this.data?.Post_Jobs_Card || '', 'INSPECTION:', this.data?.Inspection || ''],
      ['CHECK TOTAL:', '', '', this.data?.Check_total || ''],
    ];
  }
  getPermitInfo () {
    console.log('GETTING PERMIT INFO');
    const pInfo =  this.data?.[allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name];
    const lInfo =  this.data?.[allConstants.PERMIT_MODULE_FIELDS.LICENSE_INFO.api_name];
    const mInfo =  this.data?.[allConstants.PERMIT_MODULE_FIELDS.MISC_INFO.api_name];


    let pRow = ['PERMIT'];
    let lRow = ['LICENSE'];
    let mRow = ['MISC'];
    const cardSubrow = ['CARD POSTED:', this.data?.[allConstants.JOB_FIELDS.GENERAL.POST_JOB_CARD.api_name] || this.data?.[allConstants.JOB_FIELDS.GENERAL.POST_JOB_CARD.crm_name] || ''];
    const inspSubrow = ['INSPECTION:', this.data?.[allConstants.PERMIT_MODULE_FIELDS.INSPECTION.api_name] || this.data?.[allConstants.PERMIT_MODULE_FIELDS.INSPECTION.crm_name] || ''];
    const checkRow = ['CHECK TOTAL:', '', '', this.data?.[allConstants.PERMIT_MODULE_FIELDS.CHECK_TOTAL.api_name] || this.data?.[allConstants.PERMIT_MODULE_FIELDS.CHECK_TOTAL.crm_name] || ''];

    if(pInfo) {
      pRow = [...pRow, ...this.createPermitInfoRowData(pInfo)];
    } else {
      pRow = [...pRow, ...new Array(4).fill('')];
    }
    if(lInfo) {
      lRow = [...lRow, ...this.createPermitInfoRowData(lInfo)];
    } else {
      lRow = [...lRow, ...new Array(4).fill('')];
    }
    if(mInfo) {
      mRow = [...mRow, ...this.createPermitInfoRowData(mInfo)];
    } else {
      mRow = [...mRow, ...new Array(4).fill('')];
    }

    return [pRow, lRow, mRow, [...cardSubrow, ...inspSubrow], checkRow];
  }

  createPrintData () {
    let print_data = {};
    print_data.notes = this.data?.notes || [];
    const jobType = this.data?.[allConstants.JOB_FIELDS.JOB_TYPE.api_name] || this.data?.[allConstants.JOB_FIELDS.JOB_TYPE.crm_name];

    // Header info
    print_data.company = this.data?.company?.name || this.data?.company?.Name;
    print_data.job_name = this.data?.[JOB_FIELDS.JOB_NUMBER.api_name] || this.data?.[JOB_FIELDS.JOB_NUMBER.crm_name] || '';
    print_data.client_job_number = this.data?.[JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER.api_name] || this.data?.[JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER.crm_name] || '';

    print_data.headerBlock = this.getHeaderBlock();
    print_data.headerBlock.city = this.getCityBlock();

    print_data.customer = this.getCustomerInfoBlock();

    print_data.valueBlock = this.getValueAndJobNumberBlock();
    //Dates
    print_data.datesBlock = this.getDatesBlock();
    print_data.generalBlock = this.getGeneralBlock(jobType);
    print_data.permitTable = jobType !== JOB_TYPES.TESTING_ONLY.api_name ? window.isCRM ? this.getCRMPermitInfo() : this.getPermitInfo(jobType) : null;

    //if(jobType === )

    //BORDERED TOP LEFT BLOCK

    //COMMERCIAL generalBlock, com_table
    if(this.additional_data?.[allConstants.JOB_TYPES.COMMERCIAL.api_name] || this.additional_data?.[allConstants.JOB_TYPES.COMMERCIAL.crm_name]) {
      const comInfo = this.additional_data?.[allConstants.JOB_TYPES.COMMERCIAL.api_name] || this.additional_data?.[allConstants.JOB_TYPES.COMMERCIAL.crm_name];
      if(comInfo?.[JOB_FIELDS.COMMERCIAL.BUILDING_PERMIT_NUMBER.api_name]) {
        print_data.generalBlock.push({value: comInfo?.[JOB_FIELDS.COMMERCIAL.BUILDING_PERMIT_NUMBER.api_name]?.toUpperCase(), label: 'BUILDING PERMIT #'});
      }
      if(comInfo?.[JOB_FIELDS.COMMERCIAL.BUILDING_PERMIT_NUMBER.crm_name]) {
        print_data.generalBlock.push({value: comInfo?.[JOB_FIELDS.COMMERCIAL.BUILDING_PERMIT_NUMBER.crm_name]?.toUpperCase(), label: 'BUILDING PERMIT #'});
      }
      if(comInfo?.[JOB_FIELDS.COMMERCIAL.WITNESS_TEST.api_name]) {
        print_data.generalBlock.push({value: comInfo[JOB_FIELDS.COMMERCIAL.WITNESS_TEST.api_name].join(', ').toUpperCase(), label: 'WITNESS TESTS'});
      }
      if(comInfo?.[JOB_FIELDS.COMMERCIAL.WITNESS_TEST.crm_name]) {
        print_data.generalBlock.push({value: comInfo[JOB_FIELDS.COMMERCIAL.WITNESS_TEST.crm_name].join(', ').toUpperCase(), label: 'WITNESS TESTS'});
      }
      if(comInfo?.[JOB_FIELDS.GENERAL.SCOPE.api_name] && Utils.valueIsSpecified(comInfo?.[JOB_FIELDS.GENERAL.SCOPE.api_name])) {
        print_data.commercialScope = comInfo?.[JOB_FIELDS.GENERAL.SCOPE.api_name];
      }
      print_data.com_table = [];
      const equipment = comInfo?.equipment;
      if(equipment && equipment.length !== 0) {
        Object.entries(comInfo.equipment).forEach((entry)=> {
          if(entry[1] !== null && entry[1] !== '') {
            print_data.com_table.push({name: allConstants.EQ_API_TO_NAME_MAPPING[entry[0].toLowerCase()] || entry[0], count: entry[1]});
          }
        });
      }

    }
    /**** START: general preparation for old jobs from Zoho ****/
    if(this.isOldJob){
      return this.createPrintDataForOldCRMJob(print_data);
    }
    return this.createNewPrintData(print_data);
  }
  createNewPrintData (print_data){
    if(this.data?.notes && Array.isArray(this.data.notes) && this.data.notes.length !== 0) {
      print_data.notes = this.data.notes.map((note)=> {
        return{
          text: note.note,
          left_by: `${note?.author?.firstName} ${note?.author?.lastName}`,
          added_time: Utils.formatIsoDateString(note.createdAt),
          src: Object.values(allConstants.CONTRACTOR_ROLES).includes(note?.author?.role) ? 'contractor' : 'ipermit'
        };
      });
    }
    if(this.data?.permitNotes && Array.isArray(this.data.permitNotes) && this.data.permitNotes.length !== 0) {
      print_data.permit_notes = this.data.permitNotes.map((note)=> {
        return{
          text: `${note.note}${note.details && note.details.length !== 0 ? ' '+note.details : ''}`,
          left_by: `${note?.author?.firstName} ${note?.author?.lastName}`,
          added_time: Utils.formatIsoDateString(note.createdAt),
          src: Object.values(allConstants.CONTRACTOR_ROLES).includes(note?.author?.role) ? 'contractor' : 'ipermit'
        };
      });
    }

    if(this.data?.hersNotes && Array.isArray(this.data.hersNotes) && this.data.hersNotes.length !== 0) {
      print_data.hers_notes = this.data.hersNotes.map((note)=> {
        return{
          text: `${note.note}${note.details && note.details.length !== 0 ? ' '+note.details : ''}`,
          left_by: `${note?.author?.firstName} ${note?.author?.lastName}`,
          added_time: Utils.formatIsoDateString(note.createdAt),
          src: Object.values(allConstants.CONTRACTOR_ROLES).includes(note?.author?.role) ? 'contractor' : 'ipermit'
        };
      });
    }

    const equipment = this.additional_data?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    switch (this.jobApiType) {
    case JOB_TYPES.HVAC_RES.api_name:
    case JOB_TYPES.TESTING_ONLY.api_name: {
      let HVAC_DATA = this.additional_data[JOB_TYPES.HVAC_RES.api_name] || {};
      const TEST_ONLY_DATA = this.additional_data[JOB_TYPES.TESTING_ONLY.api_name] || {};
      HVAC_DATA = {...HVAC_DATA, ...TEST_ONLY_DATA};
      const content = hvacDisplayUtils.getEquipment(equipment, true);
      print_data.equipmentBlock = this.chunkify(content, 3);
      if(!HVAC_DATA) break;
      print_data.mechPlumbingBlock = hvacDisplayUtils.getPlumbing(HVAC_DATA, true);
      print_data.mechElectricalBlock = hvacDisplayUtils.getElectrical(HVAC_DATA, true);
      print_data.mechRadiantBlock  = hvacDisplayUtils.getInsulation(HVAC_DATA, JOB_FIELDS.MECHANICAL.RADIANT_BARRIER.api_name, true);
      print_data.mechBlownInBlock  = hvacDisplayUtils.getInsulation(HVAC_DATA, JOB_FIELDS.MECHANICAL.BLOWN_IN.api_name, true);

      if(Utils.valueIsSpecified(HVAC_DATA[JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name])) {
        print_data.more_options = HVAC_DATA[JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name];
      }
      break;
    }
    case JOB_TYPES.SOLAR.api_name: {
      const SOLAR_DATA = this.additional_data[this.jobApiType];
      print_data.solarBlock = solarDisplayUtils.getSolar(SOLAR_DATA, true);
      let scope = SOLAR_DATA?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
      if(Utils.valueIsSpecified(scope)) {
        print_data.general_scope = scope;
      }
      break;
    }
    case JOB_TYPES.ELECTRICAL.api_name: {
      const {content, scopes} = electricalDisplayUtils.getElectricalEquipment(this.additional_data?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name], this.additional_data?.[this.jobApiType], true);

      print_data.electricalEquipment = this.chunkify(content, 3);
      print_data.electricalScope =scopes;
      break;
    }
    case JOB_TYPES.REROOF.api_name: {
      print_data.reroofGeneral = reroofUtils.getScopeOfWorkInfo(this.additional_data[this.jobApiType], true);
      print_data.roofScopeBlock = reroofUtils.getScopeDetails(this.additional_data[this.jobApiType],true);
      print_data.roofMaterialBlock = reroofUtils.getMaterialInfo(this.additional_data[this.jobApiType], true);
      print_data.wasteRemovalBlock= reroofUtils.getWasteInfo(this.additional_data[this.jobApiType],true);
      print_data.roofBuildingInfoBlock = reroofUtils.getBuildingsInfo(this.additional_data[this.jobApiType],true);
      print_data.roofScopeText = reroofUtils.getScopeText(this.additional_data[this.jobApiType], true);
      break;
    }
    case JOB_TYPES.TEMPORARY_POWER.api_name: {
      const TEMP_POWER_DATA = this.additional_data[this.jobApiType];
      const content = tempPowerUtils.getEquipment(equipment, true);
      print_data.equipmentBlock = this.chunkify(content, 3);
      print_data.builderInfoBlock = tempPowerUtils.getBuilderInfo(TEMP_POWER_DATA, true);
      print_data.miscInfoBlock = tempPowerUtils.getMiscInfo(TEMP_POWER_DATA, true);
      print_data.additionalEquipmentBlock = tempPowerUtils.getAdditionalEquipment(TEMP_POWER_DATA,true);

      if(TEMP_POWER_DATA?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]?.[JOB_FIELDS.TEMPORARY_POWER.ADDITIONAL_EQUIPMENT.api_name]) {
        print_data.moreEquipment = TEMP_POWER_DATA?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]?.[JOB_FIELDS.TEMPORARY_POWER.ADDITIONAL_EQUIPMENT.api_name];
      }
      break;
    }
    case JOB_TYPES.PLUMBING.api_name: {
      const PLUMBING_DATA = this.additional_data[this.jobApiType];
      const PL_FIELDS = JOB_FIELDS.PLUMBING;
      print_data.repipeBlock = plumbingUtils.getRepipes(PLUMBING_DATA, true);
      print_data.repipeScope = PLUMBING_DATA?.[PL_FIELDS.REPIPE.api_name]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
      print_data.replaceBlock = plumbingUtils.getReplaces(PLUMBING_DATA, true);
      print_data.sanitation = plumbingUtils.getSanitation(PLUMBING_DATA, true);
      const repairsContent =plumbingUtils.getRepiars(PLUMBING_DATA, true);
      print_data.equipmentBlock = this.chunkify(repairsContent, 3);
      const fixtureData =  PLUMBING_DATA?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE_FIXTURES.api_name];
      const fixtures =  jobDisplayUtils.getDisplayFixtures(fixtureData,
        allConstants.JOB_FIELDS.PLUMBING.FIXTURES,
        true);
      print_data.plumbingTable = fixtures;
      const preparedWs = plumbingUtils.getEquipmentData(this.additional_data?.[this.jobApiType],
        this.additional_data?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name], true);
      print_data.waterSoftnerBlock = this.chunkify(preparedWs?.content, 2);
      print_data.waterSoftenerScope = preparedWs?.ws_scope;

      break;
    }
    case JOB_TYPES.MISC.api_name: {
      const miscData = this.additional_data?.[JOB_TYPES.MISC.api_name];
      if(miscData && miscData?.[JOB_FIELDS.GENERAL.SCOPE.api_name]){
        print_data.general_scope = miscData?.[JOB_FIELDS.GENERAL.SCOPE.api_name];
      }
      break;
    }
    default: break;
    }
    try{
      if(Utils.objectHasProperty(this.data, allConstants.JOB_FIELDS.DUPLICATE_OF.api_name)) {
        const duplicate =  this.data[allConstants.JOB_FIELDS.DUPLICATE_OF.api_name]?.jobNumber;
        if(duplicate && duplicate.length !== 0) {
          print_data.duplicateOf= `(#${duplicate})`;
        }
      }
    }catch (e) {
      print_data.duplicateOf = null;
    }
    return print_data;
  }

  createPrintDataForOldCRMJob (print_data) {
    /***create notes*****/
    const notes = this.additional_data.notes;
    try{
      if(Utils.objectHasProperty(this.additional_data, 'duplicate_info')) {
        const duplicate =  this.additional_data.duplicate_info?.name;
        if(duplicate && duplicate.length !== 0) {
          print_data.duplicateOf= `(#${duplicate})`;
        }
      }
    }catch (e) {
      print_data.duplicateOf = null;
    }

    if(this.data?.permitNotes && Array.isArray(this.data.permitNotes) && this.data.permitNotes.length !== 0) {
      print_data.permit_notes = this.data.permitNotes.map((note)=> {
        return{
          text: `${note.note}${note.details && note.details.length !== 0 ? ' '+note.details : ''}`,
          left_by: `${note?.author?.firstName} ${note?.author?.lastName}`,
          added_time: Utils.formatIsoDateString(note.createdAt),
          src: Object.values(allConstants.CONTRACTOR_ROLES).includes(note?.author?.role) ? 'contractor' : 'ipermit'
        };
      });
    }
    if(this.data?.hersNotes && Array.isArray(this.data.hersNotes) && this.data.hersNotes.length !== 0) {
      print_data.hers_notes = this.data.hersNotes.map((note)=> {
        return{
          text: `${note.note}${note.details && note.details.length !== 0 ? ' '+note.details : ''}`,
          left_by: `${note?.author?.firstName} ${note?.author?.lastName}`,
          added_time: Utils.formatIsoDateString(note.createdAt),
          src: Object.values(allConstants.CONTRACTOR_ROLES).includes(note?.author?.role) ? 'contractor' : 'ipermit'
        };
      });
    }

    if(!this.data?.permitNotes && this.data?.hersNotes && notes && notes.length !== 0) {
      notes.forEach(n=> print_data.notes.push(n));
    }


    //Add initial note
    if(this.additional_data.initial_note && this.additional_data.initial_note.length !== 0) {
      print_data.init_note = this.additional_data.initial_note;
    }
    //General Scope
    if(this.additional_data.general_scope && this.additional_data.general_scope.length !== 0) {
      print_data.general_scope = this.additional_data.general_scope;
    }
    //HVAC RES BLOCKS:  equipmentBlock, more_options, block_6, mechElectricalBlock
    if(this.additional_data.hvac_res) {
      if(this.additional_data.hvac_res.el_info) {
        print_data.mechElectricalBlock = [];
        if( this.additional_data.hvac_res.el_info.ac_hookup) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.ac_hookup, label: 'AC/AIR HOOKUP'});
        }
        if( this.additional_data.hvac_res.el_info.ac_cir) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.ac_cir, label: 'AC CIRCUIT'});
        }
        if( this.additional_data.hvac_res.el_info.service_upgrade) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.service_upgrade, label: 'SERVICE UPG'});
        }
        if( this.additional_data.hvac_res.el_info.dedicated_cir) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.dedicated_cir, label: 'DEDICATED CIR'});
        }
        if( this.additional_data.hvac_res.el_info.lights) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.lights, label: 'LIGHTS'});
        }
        if( this.additional_data.hvac_res.el_info.switches) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.switches, label: 'SWITCHES'});
        }
        if( this.additional_data.hvac_res.el_info.outlets) {
          print_data.mechElectricalBlock.push({value: this.additional_data.hvac_res.el_info.outlets, label: 'OUTLETS'});
        }
      }

      if(this.additional_data.hvac_res && this.additional_data.hvac_res.plum_info) {
        print_data.mechPlumbingBlock = [];
        if( this.additional_data.hvac_res.plum_info.gas_line) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.gas_line, label: 'GAS LINE'});
        }
        if( this.additional_data.hvac_res.plum_info.water_heater) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.water_heater, label: 'WATER HEATER'});
        }
        if( this.additional_data.hvac_res.plum_info.wh_type) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.wh_type, label: 'TYPE'});
        }
        if( this.additional_data.hvac_res.plum_info.gallons) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.gallons, label: 'GALLONS'});
        }
        if( this.additional_data.hvac_res.plum_info.location) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.location, label: 'LOCATION'});
        }
        if( this.additional_data.hvac_res.plum_info.action) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.action, label: 'ACTION'});
        }
        if( this.additional_data.hvac_res.plum_info.fuel_type) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.fuel_type, label: 'FUEL TYPE'});
        }
        if( this.additional_data.hvac_res.plum_info.energy_factor) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.energy_factor, label: 'EN. FACTOR'});
        }

        if( this.additional_data.hvac_res.plum_info.ins_r_value) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.ins_r_value, label: 'INSULATION R LEVEL'});
        }
        if( this.additional_data.hvac_res.plum_info.btu_input) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.btu_input, label: 'BTU INPUT'});
        }

        if( this.additional_data.hvac_res.plum_info.tankless_action) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.tankless_action, label: 'TANKLESS ACTION'});
        }
        if( this.additional_data.hvac_res.plum_info.gas_line_ft) {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.gas_line_ft, label: 'GAS LINE(FT)'});
        }
        if( this.additional_data.hvac_res.plum_info.wh_type && this.additional_data.hvac_res.plum_info.wh_type.toLowerCase() === 'tankless') {
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.gas_to_meter? 'yes': 'no', label: 'GAS TO METER'});
          print_data.mechPlumbingBlock.push({value: this.additional_data.hvac_res.plum_info.electrical ? 'yes':'no', label: 'ELECTRICAL'});
        }
      }
      print_data.equipmentBlock = [];
      const eq_count = {
        furnace: 0,
        'gas package unit':0,
        'air handler':0,
        'heat pump package unit': 0,
        'condenser': 0,
        'indoor coil': 0,
        'heat pump condenser': 0,
        'mini split':0,
        'mini split ducted':0,
        'ducts': 0,
      };
      const eq = this.additional_data.hvac_res.equipment;
      let eq_block = [];
      eq.forEach((piece)=> {

        let block = [];
        let eq_name = piece.eq_name.toLowerCase();
        if(! eq_count[eq_name]) {
          eq_count[eq_name] = 0;
        }
        eq_count[eq_name]++;
        //co_or_new: "New"

        if(piece.eq_name.toLowerCase() === 'furnace' ||
            piece.eq_name.toLowerCase() === 'gas package unit' ||
            piece.eq_name.toLowerCase() === 'air handler') {
          block = this.composeFurnance({afue: piece.afue, hspf: piece.hspf}, piece.btus);
          if(piece.tons) {
            block.push({value: piece.tons,  label: 'tons'});
          }
        } else if(piece.eq_name.toLowerCase() === 'heat pump package unit'){
          block.push({value: piece.tons,  label: 'tons'});
          // block.push({value: piece.make,  label: "make"});
          // block.push({value: piece.model,  label: "model"});
          // block.push({value: piece.serial,  label: "serial"});
        } else if(piece.eq_name.toLowerCase() === 'ducts'){
          block.push({value: piece.duct_is_new, label: 'ENTIRELY NEW'});
          block.push({value: piece.duct_type, label: 'TYPE'});
          // block.push({value: piece.supply, label: 'SUPPLY#'});
          // if(piece.return) {
          //   block.push({value: piece.return, label: 'RETURN#'});
          // }
        } else {
          block = this.composeCooling(piece.cooling_seer||piece.seer_rating, piece.tons);
        }
        if(piece.supply) {
          block.push({value: piece.supply, label: 'SUPPLY#'});
        }

        if(piece.return) {
          block.push({value: piece.return, label: 'RETURN#'});
        }
        if(piece.action) {
          block.push({value: piece.action, label: 'ACTION'});
        }
        if(piece.location) {
          block.push({value: piece.location, label: 'LOCATION'});
        }

        if(piece.make) {
          block.push({value: piece.make, label: 'MAKE'});
        }
        if(piece.model) {
          block.push({value: piece.model, label: 'MODEL'});
        }
        if(piece.serial) {
          block.push({value: piece.serial, label: 'SERIAL'});
        }
        if(piece['co_or_new']) {
          block.push({value: piece['co_or_new'], label: 'C/O or new'});
        }
        if(piece['quantity_of_zones']) {
          block.push({value: piece['quantity_of_zones'], label: 'QTY of Zones'});
        }
        if(piece['ducts_qty']) {
          block.push({value: piece['ducts_qty'], label: 'Ducts QTY'});
        }
        //quantity_of_zones
        if(piece.need_electric) {
          block.push({value: piece.need_electric, label: 'NEED ELE'});
        }
        if(piece.need_gas) {
          block.push({value: piece.need_gas, label: 'NEED GAS'});
        }
        if(block.length !== 0) {

          eq_block.push({name: `${this.getEquipmentName(piece.eq_name)}#${eq_count[eq_name]}`, fields: block});
        }
      });

      print_data.equipmentBlock = this.chunkify(eq_block, 3);
      if(this.additional_data.hvac_res && this.additional_data.hvac_res.more_options && this.additional_data.hvac_res.more_options.length !== 0) {
        print_data.more_options = this.additional_data.hvac_res.more_options;

      }
    }

    //REROOF BLOCKS block_4, block_8, block_9, block_10, block_11, block_12
    if(this.additional_data.reroof) {
      // if(this.additional_data.reroof.general_info) {
      print_data.reroofGeneral = [];
      print_data.reroofGeneral.push({value: this.additional_data.reroof.has_hoa ? 'yes' : 'no', label: 'H.O.A'});
      if(this.additional_data.reroof.air_condition_space) {
        print_data.reroofGeneral.push({value: this.additional_data.reroof.air_condition_space, label: 'AC SPACE, sqft'});
      }

      if(this.additional_data.reroof.garage) {
        print_data.reroofGeneral.push({value:'YES', label: 'INCLUDE GARAGE'});
        print_data.reroofGeneral.push({value: this.additional_data.reroof.garage, label: 'GARAGE TYPE'});
      }
      if(this.additional_data.reroof.num_of_stories) {
        print_data.reroofGeneral.push({value: this.additional_data.reroof.num_of_stories, label: 'STORIES #'});
      }

      print_data.roofScopeBlock = [];
      if(this.additional_data.reroof.existing_roof_type) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.existing_roof_type, label: 'EXISTING ROOF TYPE'});
      }
      if(this.additional_data.reroof.new_roof_type) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.new_roof_type, label: 'NEW ROOF TYPE'});
      }
      if(this.additional_data.reroof.action) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.action, label: 'ACTION'});
      }
      if(this.additional_data.reroof.roof_deck) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.roof_deck, label: 'ROOF DECK'});
      }
      if(this.additional_data.reroof.substrate_type) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.substrate_type, label: 'SUBS TYPE'});
      }
      if(this.additional_data.reroof.underlayment_material) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.underlayment_material, label: 'UNDERLAYMENT'});
      }
      if(this.additional_data.reroof.tile_roof_removed) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.tile_roof_removed, label: 'TILE ROOF REMOVED'});
      }
      if(this.additional_data.reroof.slope) {
        print_data.roofScopeBlock.push({value: this.additional_data.reroof.slope, label: 'SLOPE'});
      }

      print_data.roofMaterialBlock = [];
      if(this.additional_data.reroof.manufacturer) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.manufacturer, label: 'MANUFACTURER'});
      }
      if(this.additional_data.reroof.model_style) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.model_style, label: 'MODEL'});
      }
      if(this.additional_data.reroof.color) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.color, label: 'COLOR'});
      }
      if(this.additional_data.reroof.comp_year) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.comp_year, label: 'comp_year'});
      }
      if(this.additional_data.reroof.icc) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.icc, label: 'ICC#'});
      }
      if(this.additional_data.reroof.crrc) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.crrc, label: 'CRRC#'});
      }
      if(this.additional_data.reroof.la) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.la, label: 'LA_RR#'});
      }
      if(this.additional_data.reroof.ul) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.ul, label: 'UL #'});
      }
      if(this.additional_data.reroof.other) {
        print_data.roofMaterialBlock.push({value: this.additional_data.reroof.other, label: 'OTHER#'});
      }

      print_data.roofBuildingInfoBlock = [];
      if(this.additional_data.reroof.buildings) {
        print_data.reroof_building_block = [];
        const buildingInfo = this.additional_data.reroof.buildings[0];

        if(buildingInfo?.total_squares) {
          print_data.roofBuildingInfoBlock.push({value: buildingInfo.total_squares, label: 'TOTAL SQUARES'});
        }
        if(buildingInfo?.value_reroof) {
          print_data.roofBuildingInfoBlock.push({value: buildingInfo.value_reroof, label: 'VALUE'});
        }
        if(buildingInfo?.number_of_units) {
          print_data.roofBuildingInfoBlock.push({value: buildingInfo.number_of_units, label: '# OF UNITS'});
        }
        if(buildingInfo?.address_building) {
          print_data.roofBuildingInfoBlock.push({value: buildingInfo.address_building, label: 'ADDRESS'});
        }
        if(buildingInfo?.same_as_job_address) {
          print_data.roofBuildingInfoBlock.push({value: buildingInfo.same_as_job_address, label: 'SAME AS JOB ADDRESS'});
        }
      }

      print_data.wasteRemovalBlock = [];
      if(this.additional_data.reroof.type) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.type, label: 'WASTE TYPE'});
      }
      if(this.additional_data.reroof.weight) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.weight, label: 'APPOX. WEIGHT'});
      }
      print_data.recyclingCompanyBlock = [];
      if(this.additional_data.reroof.company) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.company, label: 'NAME'});
      }

      if(this.additional_data.reroof.phone_recycling_company) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.phone_recycling_company, label: 'PHONE'});
      }
      if(this.additional_data.reroof.alt_phone_recycling_company) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.alt_phone_recycling_company, label: 'ALT. PHONE'});
      }
      if(this.additional_data.reroof.email_recycling_company) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.email_recycling_company, label: 'EMAIL'});
      }
      if(this.additional_data.reroof.address_recycling_company) {
        print_data.wasteRemovalBlock.push({value: this.additional_data.reroof.address_recycling_company, label: 'ADDRESS'});
      }
    }

    //PLUMBING BLOCKS block_13, plumbing_table, block_14
    if(this.additional_data.plumbing) {
      console.log('Additional', JSON.stringify(this.additional_data.plumbing));
      if(this.additional_data.plumbing.scope ) {
        print_data.repipeScope = this.additional_data.plumbing.scope;
      }

      print_data.sanitation = [];
      if(this.additional_data?.plumbing?.sanitation_district) {
        print_data.sanitation.push({value: this.additional_data.plumbing.sanitation_district, label: 'district'});
      }
      if(this.additional_data?.plumbing?.jurisdiction) {
        print_data.sanitation.push({value: this.additional_data.plumbing.jurisdiction, label: 'jurisdiction'});
      }
      print_data.repipeBlock = [];
      if(this.additional_data.plumbing.repipe_fresh_water) {
        print_data.repipeBlock.push({label: 'repipe fresh water', value:'Yes'});
      }
      if(this.additional_data.plumbing.repipe_drains) {
        print_data.repipeBlock.push({label: 'repipe drains', value:'Yes'});
      }
      print_data.replaceBlock = [];
      if(this.additional_data.plumbing.replace_water_main) {
        print_data.replaceBlock.push({label: 'replace water main', value:'Yes'});
      }
      if(this.additional_data.plumbing.water_main_diameter) {
        print_data.replaceBlock.push({label: 'water main diameter', value:this.additional_data.plumbing.water_main_diameter});
      }
      if(this.additional_data.plumbing.replace_sewer_line) {
        print_data.replaceBlock.push({label: 'replace sewer line', value:'Yes'});
        //sewer_length
      }
      if(this.additional_data.plumbing.sewer_line_feet) {
        print_data.replaceBlock.push({label: 'Sewer length(ft)', value:this.additional_data.plumbing.sewer_line_feet});
      }
      if(this.additional_data.plumbing.sewer_line_diameter) {
        print_data.replaceBlock.push({label: 'sewer line diameter', value:this.additional_data.plumbing.sewer_line_diameter});
      }
      if(this.additional_data.plumbing.trenchless_pipe_bursting) {
        print_data.replaceBlock.push({label: 'trenchless pipe bursting', value:'yes'});
      }
      if(this.additional_data.plumbing.sewer_line_private_property) {
        print_data.replaceBlock.push({label: 'sewer line private property', value:'Yes'});
      }
      if(this.additional_data.plumbing.sewer_line_public_property) {
        print_data.replaceBlock.push({label: 'sewer line public property', value:'yes'});
      }

      print_data.plumbingTable = [];
      const fixtures = {
        LAVATORIES: {name: 'lavatories', label: 'lavatories'},
        TUB: {name: 'tub', label: 'tub'},
        TUB_SHOWER: {name: 'tub_shower', label: 'tub shower'},
        SHOWER: {name: 'shower_plumbing', label: 'shower'},
        TOILET: {name: 'toilet', label: 'toilet'},
        LAUNDRY_SINK: {name: 'laundry_sink', label: 'laundry sink'},
        KITCHEN_SINK: {name: 'kitchen_sink', label: 'kitchen sink'},
        WASHERS: {name: 'washers', label: 'washers'},
        ICE_MAKERS: {name: 'ice_makers', label: 'ice makers'},
        GARBAGE_DISPOSER: {name: 'garbage_disposer', label: 'garbage disposer'},
        WATER_HEATER: {name: 'water_heater', label: 'water heater'},
        HOSE_BIBBS: {name: 'hose_bibbs', label: 'hose bibbs'},
        OTHER: {name: 'other', label: 'other'},
      };
      Object.values(fixtures).forEach((field) => {
        if (this.additional_data.plumbing[field.name]) {
          print_data.plumbingTable.push({name: field.label, count: this.additional_data.plumbing[field.name]});
        }
      });

      const repairs_fields = [
        {
          name: 'repair drain line',
          fields: [{name: 'drain_length', label: 'length'},
            {name: 'drain_size', label: 'size'},
            {name: 'drain_fixtures', label: 'fixtures'},
          ],
        },
        {
          name: 'spot repairs',
          fields: [{name: 'length_spot_repairs', label: 'length'},{name: 'size_spot_repairs', label: 'size'},
            {name: 'spot_location', label: 'location'}],
        },
        {
          name: 'repair sewer',
          fields: [{name: 'sewer_length', label: 'length'}, {name: 'sewer_size', label: 'size'}],
        },
        {
          name: 'cleanouts',
          fields:  [{name: 'cleanout_locations', label: 'locations'}],
        },
        {
          name: 'liners',
          fields: [{name: 'liner_quantity', label: 'quantity'}],
        },

      ];
      const repair_content = [];
      repairs_fields.forEach((section)=> {
        const section_values = [];
        section.fields.forEach((field)=> {
          if(Utils.valueIsSpecified(this.additional_data.plumbing[field.name])) {
            field.value = this.additional_data.plumbing[field.name];
            section_values.push(this.additional_data.plumbing[field.name]);
          }
        });

        if(section_values.length !== 0) {
          repair_content.push(section);
        }
      });
      print_data.equipmentBlock = this.chunkify(repair_content, 3);


      const eq_count_plum = {
        'water heater traditional':0,
        'expansion tanks':0,
      };
      const eq = this.additional_data.plumbing.equipment;
      let eq_block = [];
      eq.forEach((piece)=> {

        let block = [];
        let eq_name = piece.eq_name.toLowerCase();
        if(!eq_count_plum[eq_name]) {
          eq_count_plum[eq_name] = 0;
        }
        eq_count_plum[eq_name]++;
        //
        // [allConstants.EQUIPMENT.WATER_SOFTNER]: [
        //     allConstants.FIELDS.CO_OR_NEW,
        //     allConstants.FIELDS.LOCATION,
        //     allConstants.FIELDS.NEED_ELECTRIC,
        // ],

        if(piece.eq_name.toLowerCase() === 'water heater traditional'){
          block.push({value: piece.quantity, label: 'quantity'});
          block.push({value: piece.gallons, label: 'gallons'});
          block.push({value: piece.location, label: 'location'});
          block.push({value: piece.fuel_type, label: 'fuel type'});
          block.push({value: piece.energy_factor, label: 'energy factor'});
          block.push({value: piece.ins_r_value, label: 'tank ins. R value'});
          block.push({value: piece.btu_input, label: 'BTU input'});
          //tank insulation R value
          //BTU input
        } else if(piece.eq_name.toLowerCase() === 'expansion tanks' && piece.quantity){
          block.push({value: piece.quantity, label: 'quantity'});
          block.push({value: piece.gallons, label: 'gallons'});

        } else if(piece.eq_name.toLowerCase() === 'water heater tankless'){
          if(piece.action){
            block.push({value: piece.action, label: 'action'});
            block.push({value: piece.gas_line, label: 'gas line'});
            block.push({value: piece.electrical, label: 'ele. permit'});

          }
        }
        block = block.filter((row)=> Utils.valueIsSpecified(row.value));
        if(block.length !== 0) {
          eq_block.push({name: `${this.getEquipmentName(piece.eq_name)}#${eq_count_plum[eq_name]}`, fields: block});
        }
      });
      if(this.additional_data.plumbing['water_softner']) {
        let block = [];
        block.push({
          value: this.additional_data.plumbing['water_softner']['co_or_new'], label: 'C/O or new'
        });
        block.push({
          value: this.additional_data.plumbing['water_softner']['location'], label: 'location'
        });
        block.push({
          value: this.additional_data.plumbing['water_softner']['need_electric_perm'], label: 'need el. permit'
        });
        if(block.length !== 0) {
          eq_block.push({name: `${this.getEquipmentName(this.additional_data.plumbing['water_softner'].eq_name)}`, fields: block});
        }
      }

      print_data.waterSoftnerBlock = this.chunkify(eq_block, 2);

    }

    //ELECTRICAL BLOCK
    if(this.additional_data.electrical) {
      const electricalEquipment =[];
      const rewire = [];
      //rewire info
      if(this.additional_data.electrical['house_rewire'] && Utils.valueIsSpecified(this.additional_data.electrical['house_rewire'])) {
        rewire.push({value: this.additional_data.electrical['house_rewire'], label: 'house rewire'});
      }
      if(this.additional_data.electrical['service_upgrade'] && Utils.valueIsSpecified(this.additional_data.electrical['service_upgrade'])) {
        rewire.push({value: this.additional_data.electrical['service_upgrade'], label: 'service upg'});
      }
      if(this.additional_data.electrical['lights'] && Utils.valueIsSpecified(this.additional_data.electrical['lights'])) {
        rewire.push({value: this.additional_data.electrical['lights'], label: 'lights'});
      }
      if(this.additional_data.electrical['switches'] && Utils.valueIsSpecified(this.additional_data.electrical['switches'])) {
        rewire.push({value: this.additional_data.electrical['switches'], label: 'switches'});
      }
      if(this.additional_data.electrical['outlets'] && Utils.valueIsSpecified(this.additional_data.electrical['outlets'])) {
        rewire.push({value: this.additional_data.electrical['outlets'], label: 'outlets'});
      }
      if(rewire.length !== 0) {
        // rewire.unshift('');
        electricalEquipment.push({name: '', fields: rewire});
      }
      const electricalScope = [];
      const eq_count = {
        'branch circuit': 0,
        'sub panel': 0,
        'ev car charging circuits': 0,
        'disconnected boxes': 0,
        'motors':0
      };

      const equipment_data = this.additional_data.electrical.equipment;
      if(equipment_data) {
        equipment_data.forEach((piece)=> {
          const block = [];
          let eq_name = piece.eq_name.toLowerCase();
          if(! eq_count[eq_name]) {
            eq_count[eq_name] = 0;
          }
          eq_count[eq_name]++;
          if(piece['quantity'] && Utils.valueIsSpecified(piece['quantity'])) {
            block.push({value: piece['quantity'], label: 'QTYY'});
          }
          if(piece['amps'] && Utils.valueIsSpecified(piece['amps'])) {
            block.push({value: piece['amps'], label: 'amps'});
          }
          if(piece['volts'] && Utils.valueIsSpecified(piece['volts'])) {
            block.push({value: piece['volts'], label: 'volts'});
          }
          if(piece['kw'] && Utils.valueIsSpecified(piece['kw'])) {
            block.push({value: piece['kw'], label: 'KW'});
          }
          if(piece.scope && piece.scope.length !== 0) {
            electricalScope.push({
              name: `${piece.eq_name}#${eq_count[eq_name]}`,
              value: piece.scope
            });
          }
          if(block.length !== 0) {

            block.unshift(`${piece.eq_name}#${eq_count[eq_name]}`);
            electricalEquipment.push({name: `${piece.eq_name}#${eq_count[eq_name]}`, fields: block});

          }

        });
        print_data.electricalEquipment = this.chunkify(electricalEquipment, 3);
        print_data.electricalScope = electricalScope;
      }


    }
    // }

    if(this.additional_data.solar) {
      const solar_data = this.additional_data.solar;
      if(solar_data?.scope && Utils.valueIsSpecified(solar_data.scope)){
        print_data.general_scope= solar_data.scope;
      }
      const solar_block = [];
      //total_kw
      if(solar_data['total_kw'] && Utils.valueIsSpecified(solar_data['total_kw'])) {
        solar_block.push({value: solar_data['total_kw'], label: 'total KW'});
      }
      if(solar_data['panels'] && Utils.valueIsSpecified(solar_data['panels'])) {
        solar_block.push({value: solar_data['panels'], label: 'panels'});
      }
      if(solar_data['inverters'] && Utils.valueIsSpecified(solar_data['inverters'])) {
        solar_block.push({value: solar_data['inverters'], label: 'inverters'});
      }
      if(solar_block.length !== 0) {
        print_data.solarBlock = solar_block;
      }

    }

    if(this.additional_data.temporary_power) {
      const data = this.additional_data.temporary_power;
      print_data.equipmentBlock = [];
      const eq_count = {
        'temp power pole':0,
        'sub pole':0,
        'sub distro panel': 0,
        'transformer': 0,
        'generator': 0,
      };
      const eq = data.equipment;
      let eq_block = [];
      eq.forEach((piece)=> {

        let block = [];
        let eq_name = piece.eq_name.toLowerCase();
        if(! eq_count[eq_name]) {
          eq_count[eq_name] = 0;
        }
        eq_count[eq_name]++;
        //co_or_new: "New"
        if(piece['quantity']) {
          block.push({value: piece['quantity'], label: 'QTY'});
        }
        if(piece['amps']) {
          block.push({value: piece['amps'], label: 'AMPS'});
        }
        if(piece['kva']) {
          block.push({value: piece['kva'], label: 'KVA'});
        }
        if(piece['kw']) {
          block.push({value: piece['kw'], label: 'KW'});
        }
        if(piece['circuits']) {
          block.push({value: piece['circuits'], label: 'circuits'});
        }
        block = block.filter((row)=> Utils.valueIsSpecified(row.value));
        if(block.length !== 0) {
          eq_block.push({name: `${this.getEquipmentName(piece.eq_name)}#${eq_count[eq_name]}`, fields: block});
        }

      });
      print_data.equipmentBlock = this.chunkify(eq_block, 3);
      print_data.builderInfoBlock = [];
      print_data.builderInfoBlock.push({value: data['builder_name'], label: 'name'});
      print_data.builderInfoBlock.push({value: data['builder_company'], label: 'company'});
      print_data.builderInfoBlock.push({value: data['builder_phone'], label: 'Phone'});
      print_data.builderInfoBlock.push({value: data['builder_alt_phone_number'], label: 'alt phone'});
      print_data.builderInfoBlock.push({value: data['builder_email'], label: 'email'});
      print_data.builderInfoBlock.push({value: data['builder_address'], label: 'address'});
      print_data.builderInfoBlock = print_data.builderInfoBlock.filter((row)=> Utils.valueIsSpecified(row.value));

      print_data.miscInfoBlock = [];
      print_data.miscInfoBlock.push({value: data['jurisdiction_city'], label: 'jurisdiction city'});
      print_data.miscInfoBlock.push({value: data['county'], label: 'county'});
      print_data.miscInfoBlock.push({value: data['building_use'], label: 'building use'});
      print_data.miscInfoBlock.push({value: data['building_permit'], label: 'building permit'});
      print_data.miscInfoBlock.push({value: data['power_usage'], label: 'power usage'});
      print_data.miscInfoBlock = print_data.miscInfoBlock.filter((row)=> Utils.valueIsSpecified(row.value));

      print_data.additionalEquipmentBlock = [];
      print_data.additionalEquipmentBlock.push({value: data['bare_pole_quantity'], label: 'bare pole qty'});
      print_data.additionalEquipmentBlock.push({value: data['trailer_hookup_quantity'], label: 'trailer hookup qty'});
      print_data.additionalEquipmentBlock = print_data.additionalEquipmentBlock.filter((row)=> Utils.valueIsSpecified(row.value));

      print_data.moreEquipment= data['additional_equipment'];
    }
    console.log('will return created print', print_data);
    return print_data;


  }

  composeFurnance(efficiency,btus,action,location) {
    let fur = [];
    if (efficiency.afue) {
      fur.push({value: efficiency.afue, label: 'AFUE'});
    } else if (efficiency.hspf) {
      fur.push({value: efficiency.hspf, label: 'H SPF'});
    }

    fur.push({value: btus, label: 'BTU\'S'});

    return fur;
  }
  composeCooling(seer, tons, action,location) {
    let cool = [];
    if(Utils.valueIsSpecified(seer)) {
      cool.push({value: seer, label: 'SEER'});
    }

    if(Utils.valueIsSpecified(tons)) {
      cool.push({value: tons, label: 'TONS'});
    }



    return cool;
  }

  createMailLabel(){
    // console.log('this.data', this.data)
    const mailLabel = [this.data.Homeowner_Name];
    const newMailLabel = mailLabel.concat(this.data.Jobsite_Address.split(','));
    newMailLabel.push(this.data.Name);
    return newMailLabel;
  }
  getEquipmentName(name) {
    const l_name = name.toLowerCase();
    const eq_print_names={
      furnace: 'furnace',
      'gas package unit': 'gas package unit',
      'air handler':'air handler',
      'heat pump package unit': 'heat pump package unit',
      'condenser': 'ac',
      'indoor coil': 'indoor coil',
      'heat pump condenser': 'heat pump ac',
      'mini split':'mini split ductless',
      'ducts': 'ducts',
    };
    return eq_print_names[l_name] ? eq_print_names[l_name]:name;
  }
  chunkify = (arr, size) =>{
    if(!arr || !Array.isArray(arr)|| arr.length === 0) return [];
    return Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>(
      arr.slice(i * size, i * size + size)
    ));

  };

}
export {DataPreparation};

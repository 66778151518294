import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, InputAdornment} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ShowPassword = (props)=> {
  const style = {color: 'rgb(103 108 110)', width: '15px', height: '15px'};
  return (
    <InputAdornment position="end" sx={{
      marginLeft: '0',
    }}>
      <IconButton
        aria-label="toggle password visibility"
        onClick={props.handleShowPassword}
      >
        {props.show ? <Visibility style={style}/> : <VisibilityOff style={style}/>}
      </IconButton>
    </InputAdornment>
  );

};

ShowPassword.propTypes = {
  show: PropTypes.bool,
  handleShowPassword: PropTypes.func,
};

ShowPassword.defaultProps = {
  show: false,
  handleShowPassword: ()=> {},
};

export {ShowPassword};

import React from 'react';
import PropTypes from 'prop-types';
import {PaperWrapper} from '../../pages/companies/paperWrapper';
import clsx from 'clsx';
import {globalUseClasses} from '../../styles/globalClasses';
import {Box, Paper, Typography} from '@mui/material';

const AnalyticsHeader = (props) => {
  return (
    <Typography fontSize={16} fontWeight={700} alignSelf={'center'}>{props.title}</Typography>
  );
};


const AnalyticsBaseCard = (props) => {
  const classes = {...globalUseClasses()};
  return (
    <Box
      height={'100%'}>
      <Paper elevation={0} className={clsx(classes.info_paper)} pb={3}>
        <AnalyticsHeader title={props.title}></AnalyticsHeader>
        {props.children}
      </Paper>
    </Box>
  );
};

const AnalyticsPickerBaseCard = (props) => {
  return (
    <PaperWrapper>
      <AnalyticsHeader title={props.title}></AnalyticsHeader>
      {props.children}
    </PaperWrapper>
  );
};

AnalyticsHeader.propTypes = {
  title: PropTypes.string,
};

AnalyticsBaseCard.propTypes = {
  title: PropTypes.string,
};

AnalyticsPickerBaseCard.propTypes = {
  title: PropTypes.string,
};

export {AnalyticsHeader, AnalyticsBaseCard};

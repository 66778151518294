/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Box} from '@mui/system';
import ModalWrapper from '../../../pieces/modals/modalWrapper';
import PropTypes from 'prop-types';
import {Typography, Button} from '@mui/material';
import TypeSwitch from '../../../pieces/typeSwitch';
import AddButton from '../../../pieces/addButton';
import AttachmentRow from './newAttachmentRow';
import * as allConstants from '../../../constants/allConstants';
import {useFieldArray, useForm, FormProvider} from 'react-hook-form';
import base_url from '../../../../src/baseUrls';
import ApiUtils from '../../../utils/apiUtils';
import Utils from '../../../utils/utils';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Loader from '../../misc/loader';


function AddAttachmentModal(props) {
  const isTestingOnly = props.jobType === allConstants.JOB_TYPES.TESTING_ONLY.value;
  const canBeHERSJob = props.jobType === allConstants.JOB_TYPES.HVAC_RES.value;

  const validationSchema = Yup.object().shape({
    files: Yup.array().of(Yup.object().shape({
      fields: Yup.array().of(Yup.object().shape({
        [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: Yup.mixed().required('Please upload file or remove this row'),
      }))
    }))
  })
  const [fetching, setFetching] = useState(false);

  const formOptions = {
    mode: 'onChange',
      reValidateMode: 'onChange',
      criteriaMode:'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      files: [{
        name: 'file upload',
        fields: [{
          [allConstants.JOB_FIELDS.FILE.NAME.api_name]: null,
          [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: null,
        }],
      }],
    }
  };
  const blockPrefix = 'files[0].fields';

  const formMethods = useForm(formOptions);
  window.filesForm = formMethods;
  const [attachmentType, setAttachmentType] = useState(false);

  const inputDefaultProps = {
    viewMode: false,
    renderLabel: true,
  };

  const submitAttachments = async (data) => {
    let type;
    if(isTestingOnly) {
      type = allConstants.NOTE_TYPES.HERS.value;
    } else if(!canBeHERSJob){
      type = allConstants.NOTE_TYPES.PERMIT.value;
    }else {
      type = attachmentType ? allConstants.NOTE_TYPES.HERS.value : allConstants.NOTE_TYPES.PERMIT.value;
    }

    const filesArray = data.files[0].fields;
    if(!filesArray || filesArray.length === 0) return;

    setFetching(true);

    const filesToSubmit = filesArray.map((f)=> {
      const fileObj = {
        fileUploadId: f.file.id || f.file,
        type: type,
      };

      let fileName;
      if(f?.name && f.name.length !== 0) {
        fileName = f.name;
      } else if(f?.file && typeof f.file === 'object' &&  f?.file?.name){
        fileName = f.file.name;
      } else {
        fileName = f.fileName;
      }

      fileObj.fileName = fileName;

      return fileObj
    })

    const authToken = await ApiUtils.getAccessToken();
    const body = {
      jobId: props.jobId,
      attachments: filesToSubmit
    };

    const response = await fetch(`${base_url.api}jobs/add-attachments`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(
          body
        ),
      }).catch((error) => {
      setFetching(false);
    });

    const responseJson = await response.json();

    Utils.handleToastMessage(responseJson, 'Attachment added', 'Please try adding attachment again', 'add_attachments');

    setFetching(false);

    if (responseJson.status === 'success') {
      formMethods.reset();
      props.addAttachments(body);
      props.onClose();
    }
  };


  const changeAttachmentTypeToggle = (event) => {
    setAttachmentType(event.target.checked);
  };

  const {fields, append, remove} = useFieldArray({
    control: formMethods.control,
    name:blockPrefix
  });

  const addAttachment = () => {
    append({...formOptions.defaultValues.files[0].fields[0]});
  };

  useEffect(() => {
    formMethods.reset();
  }, [props.open]);

  const deleteAttachmentRow = (index) => {
    try {
      const files = [...formMethods.getValues()?.files[0].fields];
      console.log({...files});
      files.splice(index, 1);
      formMethods.setValue('files[0].fields', files);
      console.log('removed', files)
    } catch(e){

    }
  };

  return (
    <Box>
    {
      fetching ? <Loader/> :
      <ModalWrapper
        open={props.open}
        handleClose={props.onClose}
        width={'clamp(240px, 90vw, 640px)'}
        preventScroll={false}
        sx={{
          borderRadius: '10px',
          justifyContent: 'center'
        }}>
        <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitAttachments)} method="POST">

            <Box
              display={'flex'}
              flexDirection={'column'}
              textAlign={'center'}
              justifyContent={'center'}
              gap={3}
            >
              <Typography variant='h1'>Upload files</Typography>
              {!isTestingOnly &&canBeHERSJob && <TypeSwitch trueLabel={'HERS'} falseLabel={'PERMIT'} onToggle={changeAttachmentTypeToggle} toggle={attachmentType}/>}
              <Box textAlign={'left'}>
                <Box>
                  {
                    fields.map((item, idx) => {
                      return <AttachmentRow {...inputDefaultProps}
                                            key={idx.toString()+item?.id}
                                            index={idx}
                                            blockPrefix={blockPrefix}
                                            // formMehods={formMethods}
                                            removeAttachment={(idx)=> deleteAttachmentRow(idx)}/>;
                    })
                  }
                </Box>
                <AddButton action={addAttachment}/>
              </Box>
              <Button
                variant="contained"
                color={'primary'}
                type="submit"
                sx={{
                  width: 'fit-content',
                  alignSelf: 'center',
                  paddingX: 5,
                  paddingY: 1,
                }}>
                Submit
              </Button>
            </Box>


        </form>
        </FormProvider>
      </ModalWrapper>
}
    </Box>
  );
}

AddAttachmentModal.propTypes = {
  jobId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  addAttachments: PropTypes.func,
};

export default AddAttachmentModal;

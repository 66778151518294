import PropTypes from 'prop-types';
import {unwrapResult} from '@reduxjs/toolkit';
import {toast} from 'react-toastify'

const unwrapRequestResult = (props)=>{

  //console.log("inside unwrap request");
  //console.log(props);

  try{
    const promiseResult = unwrapResult(props.result);
   
    //console.log('promise  result');
    //console.log(promiseResult);

    if(props.showSuccess && promiseResult) {

      //console.log('success');

      toast.success(props.successMessage ? props.successMessage : 'Success', {
        toastId: `success_${props.id}`
      });
    } else if(!promiseResult && props.result.status ==='error') {

      //console.log('error');

      toast.error(props?.result?.message ? props?.result?.message : 'Error', {
        toastId: `error_${props.id}`
      });
    }
    return promiseResult;

  } catch (e) {
    console.log(`Error on ${props.id}:::: `);
    console.log('e', e);
    toast.error(props.errorMessage ? props.errorMessage : e.message, {
      toastId: `error_${props.id}`
    });
    return e;
  }
};
unwrapRequestResult.propTypes = {
  props: PropTypes.shape({
    showSuccess: PropTypes.bool,
    id: PropTypes.string.isRequired,
    successMessage: PropTypes.string,

  })
};

export {unwrapRequestResult};


import React from 'react';
import PropTypes from 'prop-types';
import ModalWrapper, {ModalContentWrapper} from './modalWrapper';
import { ThreeGMobiledataOutlined } from '@mui/icons-material';

const TagModal = (props)=> {
  return (
    <ModalWrapper modalName={'add-user'}
      handleClose={props.handleClose}
      open={props.open}
      preventScroll={props.preventScroll}
      width={{sx: '98%', sm: '600px'}}
      padding={6}
      customStyle={{paddingTop: 2}}
    >
      <ModalContentWrapper handleClose={props.handleClose} header={props.header}>
        {props.children}
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

TagModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  preventScroll: PropTypes.bool
};
TagModal.defaultProps = {
  open: false,
  header: '',
  preventScroll: false,
};
export default TagModal;

import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';

class ReroofDisplayUtils {
  constructor() {
    this.fields = allConstants.JOB_FIELDS.REROOF;
  }

  createBlockInfo = (details, fields, key, print)=> {
    if(!details?.[key]) return null;
    const block = fields.map((e) => {
      let value = details[key][e.api_name];
      if(e.api_name === allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name){
        value = Utils.createAddressString(details[key][e.api_name]);
      }
      return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e, print), value, print);
    });
    return block.filter((el)=> el);
  }

  getScopeOfWorkInfo = (details, print) => {
    const fields = [
      this.fields.HAS_HOA,
      this.fields.AIR_CONDITION_SPACE,
      this.fields.NUMBER_OF_STORIES,
      this.fields.INCLUDE_GARAGE,
      this.fields.GARAGE_TYPE,
    ];
    return this.createBlockInfo(details, fields, this.fields.REROOF_SCOPE.api_name, print);
  }
  getScopeDetails = (details, print) => {
    const fields = [
      this.fields.EXISTING_ROOF_TYPE,
      this.fields.NEW_ROOF_TYPE,
      allConstants.JOB_FIELDS.SHARED.ACTION,
      this.fields.ROOF_DECK,
      this.fields.SUBSTRATE_TYPE,
      this.fields.UNDERLAYMENT_MATERIAL,
      this.fields.TILE_ROOF_REMOVED,
      this.fields.SLOPE
    ];
    return this.createBlockInfo(details, fields, this.fields.REROOF_SCOPE.api_name, print);
  }
  getScopeText = (details, print)=> {
    return details?.[this.fields.REROOF_SCOPE.api_name]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
  }

  getMaterialInfo = (details, print) => {
    const fields = [
      this.fields.MANUFACTURER,
      this.fields.MODEL_STYLE,
      this.fields.COLOR,
      this.fields.COMP_YEAR,
      this.fields.ICC,
      this.fields.CRRC,
      this.fields.LA_RR,
      this.fields.UL,
      this.fields.OTHER,
    ];
    return this.createBlockInfo(details, fields, this.fields.ROOFING_MATERIALS.api_name, print);
  }
  getWasteInfo = (details, print) => {
    const fields = [
      this.fields.WASTE_TYPE,
      this.fields.WASTE_WEIGHT,
      this.fields.RECYCLING_COMPANY,
      allConstants.JOB_FIELDS.GENERAL.EMAIL,
      allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER,
      allConstants.JOB_FIELDS.GENERAL.ALT_PHONE_NUMBER,
      allConstants.JOB_FIELDS.GENERAL.ADDRESS,
    ];
    return this.createBlockInfo(details, fields, this.fields.WASTE_REMOVAL.api_name, print);
  }
  getBuildingsInfo = (details, print) => {
    const fields = [
      this.fields.TOTAL_SQUARES,
      this.fields.VALUE_REROOF,
      this.fields.NUMBER_OF_UNITS,
      this.fields.SAME_AS_JOB_ADDRESS,
      allConstants.JOB_FIELDS.GENERAL.ADDRESS
    ];
    return this.createBlockInfo(details, fields, this.fields.BUILDINGS.api_name, print);
  }
}

const reroofUtils = new ReroofDisplayUtils();
export {reroofUtils};

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CancelSelectedEquipment = (props)=> {
  if(!props.canModifyEquipment) return null;
  return (
    <Box display={'flex'} alignItems={'center'} sx={{height: '37px'}}>
      <>
        <Typography variant={'body1'} sx={{
          fontSize: {sx: '14px', md: '16px'}
        }}>Select one or more</Typography>
        {props.selected && <Button type={'text'}
          startIcon={<CloseIcon/>}
          sx={{
            marginLeft: '30px',
            startIcon: {
              marginRight: 0,
            }
          }}
          onClick={props.cancelAction}
          color={'secondary'}
        >Cancel selection</Button>}
      </>

    </Box>
  );
};

CancelSelectedEquipment.propTypes = {
  cancelAction: PropTypes.func,
  selected: PropTypes.bool,
  canModifyEquipment: PropTypes.bool,
};
CancelSelectedEquipment.defaultProps = {
  cancelAction: ()=> {console.log('[CancelSelectedEquipment]: cancelAction is not provided....');},
  selected: false,
  canModifyEquipment: true
};

export default CancelSelectedEquipment;

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {useCreateJobStyles} from '../../createJobStyles';
import * as allConstants from '../../../../../constants/allConstants';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {useFormContext} from 'react-hook-form';

const MechElectrical = (props)=> {
  const classes = useCreateJobStyles();
  const formController = useFormContext();
  const values = formController.getValues();
  const blockParent = `${props.parent}.${allConstants.JOB_FIELDS.MECHANICAL.EL_INFO.api_name}`;
  const MECH_FIELDS  = allConstants.JOB_FIELDS.MECHANICAL;
  const EL_FIELDS = allConstants.JOB_FIELDS.ELECTRICAL;
  const commonProps = {
    ...props,...{
      nested: true,
      parent: blockParent,
      fullWidth: true
    }
  };
  return (
    <Box className={classes.tab_container}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3} display={'flex'} flexDirection={'column'}>
          {[MECH_FIELDS.AC_AIR_HOOKUP, MECH_FIELDS.AC_CIR, MECH_FIELDS.DEDICATED_CIR].map((f, index)=>
            <CustomFormBlock key={index+f.api_name}
              {...commonProps}
              field={f}
              defaultValue={values?.[`${blockParent}`]?.[f.api_name]}
            />
          )}

        </Grid>
        <Grid item xs={12} sm={4} md={3} display={'flex'} flexDirection={'column'}>
          {[EL_FIELDS.LIGHTS, EL_FIELDS.SWITCHES, EL_FIELDS.OUTLETS].map((f, index)=>
            <CustomFormBlock key={index+f.api_name}
              {...commonProps}
              field={f}
              defaultValue={values?.[`${blockParent}`]?.[f.api_name]}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={3} display={'flex'} flexDirection={'column'}>
          {[EL_FIELDS.SERVICE_UPGRADE].map((f, index)=>
            <CustomFormBlock key={index+f.api_name}
              {...commonProps}
              field={f}
              defaultValue={values?.[`${blockParent}`]?.[f.api_name]}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

MechElectrical.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default MechElectrical;

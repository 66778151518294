import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';

const NoDataStringWrapper = (props)=> {
  return <Typography variant={'p'}
    sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 'inherit', textTransform: 'uppercase'}}>
    {props.text} </Typography>;
};

NoDataStringWrapper.propTypes = {
  text: PropTypes.string
};
NoDataStringWrapper.defaultProps = {
  text: ''
};
export {NoDataStringWrapper};

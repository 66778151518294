import React  from 'react';
import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import BlockedImage from '../../static/images/warningImage.png';
import Spacer from '../spacer';
import ModalWrapper from './modalWrapper';

export const GotItPopup = (props) => {
  return(
    <ModalWrapper modalName={'confirm-action'}
      handleClose={props.handleClose}
      open={true}
      preventScroll={true}
      width={{sx: '98%', md: '400px'}}
      padding={'16px 48px'}

    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
        <img src={BlockedImage} alt={''} width={50}/>
        <Spacer y={2} />
        {props.children}
        <Spacer y={2} />
        {props.handleClose && <Button variant={'contained'} color={'primary'} onClick={()=> {
          props.handleClose();
        }}>Got it</Button>}
      </Box>

    </ModalWrapper>
  );
};

GotItPopup.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.node
};

GotItPopup.defaultProps = {
  handleClose: null,
  children: null
};

import * as allConstants from '../constants/allConstants';
import Utils from './utils';

class FieldFormatter {
  constructor() {
    this._normalizeToLowercase = [


    ];
    this._normalizeToUppercase = [
      //products
      allConstants.PRODUCT_FIELDS.NAME.api_name,
      allConstants.PRODUCT_FIELDS.CODE.api_name,
      //company
      allConstants.COMPANY_FIELDS.NAME.api_name,
      //user fields
      allConstants.USER_FIELDS.FIRST_NAME.api_name,
      allConstants.USER_FIELDS.LAST_NAME.api_name,
      allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name,
      allConstants.JOB_FIELDS.NOTE.TEXT.api_name
    ];
    this._numberFields = [
      //job fields
      allConstants.JOB_FIELDS.GENERAL.JOB_VALUE.api_name,
    ];
    this._boolFields = [
      //job fields
      allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name,
      allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name,
      allConstants.JOB_FIELDS.GENERAL.ASBESTOS.api_name,
      allConstants.JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name,
    ];
  }
  getValueForDB = (apiName, value)=> {

    if(this._normalizeToLowercase.includes(apiName)) {
      return value?.toLowerCase();
    }
    if(this._normalizeToUppercase.includes(apiName)) {
      return value?.toUpperCase();
    }
    if(this._numberFields.includes(apiName)) {

      try{
        return  value.parseInt();
      } catch(e){}
      return value;
    }
    if(this._boolFields.includes(apiName)) {
      if(typeof value === 'boolean') return value;
      return Utils.textToBool(value);
    }
    return value;
  }
}

const fieldFormatter = new FieldFormatter();

export default fieldFormatter;

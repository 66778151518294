import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useForm, Controller} from 'react-hook-form';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {FormControlLabel} from '@mui/material';
import SimpleSwitch from '../simpleSwitch';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';


export default function CustomizedSwitchInput(props) {
  const {
    control
  } = useForm();

  const [checked, setChecked] = useState(props.defaultValue);

  const fieldIsDisabled = props.viewMode || props.disabled;

  useEffect(()=>{
    props.setValueToForm(`${props.field_name}`, checked, {shouldDirty: true, shouldValidate: true});
  },[checked]);

  return (
    <>
      <Controller name={props.field_name}
        type="checkbox"
        // defaultValue={false}
        control={control}
        render={({value: valueProp, onChangeEvent})=>(
          <FormControlLabel
            disabled={fieldIsDisabled}
            control={<SimpleSwitch onChange={(event, val)=>{
              setChecked(val);
            }}
            defaultChecked={checked}
            disabled={fieldIsDisabled}
            />}
            label={props.label}
            sx={{
              marginY: theme=>theme.spacing(1),
              '&.Mui-disabled': {
                '& .MuiSwitch-root': {
                  '& .MuiSwitch-track': {
                    backgroundColor: `${STYLE_CONSTANTS.COLORS.lightBlue} !important`,
                  }
                }
              }
            }}/>
        )}
      />
    </>
  );
}

CustomizedSwitchInput.propTypes = {
  ...formBlockTypes,
  ...{action: PropTypes.func,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool}
};

CustomizedSwitchInput.defaultProps = {
  ...formBlockDefaultProps,
  ...{
    action: ()=>{},
    disabled: false,
  }
};

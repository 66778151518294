import * as allConstants from '../../../../constants/allConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import {requiredRowFieldsValidator} from '../../../../utils/validators/subformValidator';
import Utils from '../../../../utils/utils';
const _ = require('lodash');


const mechanicalUtils = {
  generateDefaultFields: (equipmentMap)=> {
    const obj = {};
    for (const eq_type in equipmentMap) {
      const curr_fields = equipmentMap[eq_type].fields;
      obj[eq_type] = [{}];
      obj[eq_type][0].name = eq_type;
      obj[eq_type][0].fields = [];
      obj[eq_type][0].fields[0] = {};
      curr_fields.forEach((f)=> {
        obj[eq_type][0].fields[0][f.field.api_name] = f?.defaultValue || undefined;
      });

    }
    return obj;
  },
  /**
   * @param {string} sysName
   * @param {Array} options
   * @param {Object} savedValues
   * @param {Object} defaultValues.
   */
  updateSystemDefaultValues: (sysName, options, savedValues, defaultValues, parent)=> {
    let job = parent || allConstants.JOB_TYPES.HVAC_RES.api_name;
    const savedForm = savedValues?.[job]?.[sysName];
    const initialSelected = options.map((opt)=> null);
    if(savedForm) {
      const updated = _.cloneDeep(defaultValues);
      options.forEach((opt)=> {
        if(savedForm?.[opt.api_name]) {
          const saved= savedForm?.[opt.api_name][0];
          let mergedFields = [];
          if(saved){
            const fields = saved.fields;
            if(fields) {
              const def = defaultValues[opt.api_name][0].fields;
              def.forEach((row, index)=> {
                mergedFields.push({...row, ...fields[index]});
              });

              if(fields.length > 1) {
                const rest = [...fields];
                rest.shift();
                updated[opt.api_name][0].fields = [...mergedFields, ...rest];
              } else {
                updated[opt.api_name][0].fields = mergedFields;
              }
            }
          }
          if(updated?.[opt.api_name][0].fields) {

            const existingValues = updated?.[opt.api_name][0].fields[0];
            const definedValues = Object.values(existingValues).filter((v)=> Utils.valueIsSpecified(v)) ;
            if (definedValues.length > 0) {
              initialSelected[options.indexOf(opt)] = (opt.api_name);
            }
          }


          // updated[opt.api_name] = [...savedForm[opt.api_name]];
        }
      });
      return {updatedDefault: updated, initialSelected: initialSelected};
    }
    else {
      return {updatedDefault: defaultValues, initialSelected: initialSelected};
    }
  },
  getSystemInputDefaultProps: (formMethods)=> {
    return {
      viewMode: false,
      renderLabel: true,
      isEditable: true,
      labelSecondary: true,
    };
  },
  /**
   *
   * @param equipmentMap
   * @param validationSchema
   * @return {{mode: string, reValidateMode: string, criteriaMode: string, defaultValues: {}, resolver: function||undefined}}
   */
  getSystemFormOptions: (equipmentMap,  validationSchema)=> {
    // console.log('getSystemFormOptions', equipmentMap)
    const options = {
      mode: 'onChange',
      reValidateMode: 'all',
      criteriaMode:'all',
      defaultValues: mechanicalUtils.generateDefaultFields(equipmentMap)

    };
    if(validationSchema) {
      options.resolver = yupResolver(validationSchema);
    }
    return options;
  },

  validateIfAnyInRowIsNotEmpty:  (validateFields, rowFields)=> {
    return requiredRowFieldsValidator.validateIfAnyInRowIsNotEmpty(validateFields, rowFields);
  }
};

export default mechanicalUtils;

import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';

export  const AuthFormFooter = (props)=> {

  const navigate = useNavigate();

  const text = props.isSignUpPage ? 'Already have an account?' : 'Don\'t have an account?';
  const buttonText = props.isSignUpPage ? 'Login' : 'Signup here';
  const redirectPage = props.isSignUpPage ? allConstants.PATH.LOGIN : allConstants.PATH.SIGN_UP;
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      letterSpacing: '1.2px',
      '& .MuiTypography-root': {
        fontSize: '1rem'
      }

    }}>
      <Typography sx={{display: 'inline-block', color: STYLE_CONSTANTS.COLORS.black}}>{text}</Typography>
      <Button variant={'text'}
        color={'primary'}
        sx={{
          fontSize: '1rem',
          padding: '0 8px !important',
          height: '30px',
          letterSpacing: '1.2px',
          color: STYLE_CONSTANTS.COLORS.linkColor
        }}
        onClick={()=>navigate(redirectPage)}>{buttonText}</Button>
    </Box>
  );
};

AuthFormFooter.propTypes = {
  isSignUpPage: PropTypes.bool
};

AuthFormFooter.defaultProps = {
  isSignUpPage: false,
};

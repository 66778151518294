import {CONTRACTOR_ROLES} from '../constants/allConstants';
const GENERAL_ELEMENTS = {
  ipermitHomeButtons: 'ipermitHomeButtons',
  contractorUserHomeButtons: 'contractorUserHomeButtons',
  contractorAdminHomeButtons: 'contractorAdminHomeButtons',
};


const generalRules = Object.freeze({
  [GENERAL_ELEMENTS.ipermitHomeButtons]: {
    policyName: undefined,
    contractorAccess: false,
    hideWhenImpersonating: true
  },

  [GENERAL_ELEMENTS.contractorUserHomeButtons]: {
    policyName: 'contractor.view', //frontend only policy. Hide from ipermit
    contractorAccess: true,
    contractorPermissions: [CONTRACTOR_ROLES.CONTRACTOR]
  },

  [GENERAL_ELEMENTS.contractorAdminHomeButtons]: {
    policyName: 'contractor.view', //frontend only policy. Hide from ipermit
    contractorAccess: true,
    contractorPermissions: [CONTRACTOR_ROLES.COMPANY_ADMIN]
  },

});

export {generalRules, GENERAL_ELEMENTS};

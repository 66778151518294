import React, {useState} from 'react';
import {useJob} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {Box, Button, Table, TableBody, TableCell, TableRow, TableHead, Typography, Checkbox} from '@mui/material';

import IpermitIcon from '../../../static/images/ipermitIcon.svg';
import Utils from '../../../utils/utils';
import downloadFile from '../../../utils/downloadFileUtil';
import {NoDataStringWrapper} from '../../../pieces/noDataStringWrapper';
import {IpermitAddAttachment} from '../components/ipermitAddAttachments';
import PropTypes from 'prop-types';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {ConfirmDeleteFile} from './attachments/confirmDeleteFile';
import ApiUtils from '../../../utils/apiUtils';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {ATTACHMENT_TYPES} from '../../../constants/allConstants';
import {
  attachmentRules,
  ELEMENTS_ATTACHMENTS
} from '../../../permissions/ipermitAccessControl/attachmentsAccessControl';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {useGotIt} from '../../../hooks/useGotIt';


export const AttachmentsContent = (props) => {
  const Popup = useGotIt();
  const {job, setJob} = useJob();
  const {JOB_FIELDS} = allConstants;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showEdit, setShowEdit] = useState(false);

  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const user = useSelector(state=> state.user);


  const createTableRow = (attachment) => {
    const fileUploadedBy = attachment?.[JOB_FIELDS.ATTACHMENT.UPLOAD.api_name];
    const user = fileUploadedBy?.[JOB_FIELDS.ATTACHMENT.UPLOADED_BY.NAME.api_name] || '';
    const splitName = user.split(' ');
    const firstName = splitName[0];
    //set rest if user as lastName
    const lastName = splitName.slice(1).join(' ');
    const row = {
      origin: fileUploadedBy?.[JOB_FIELDS.ATTACHMENT.UPLOADED_BY.ADDED_FROM_USER.api_name] === JOB_FIELDS.ATTACHMENT.ADDED_FROM.IPERMIT.label,
      fileName: attachment?.[JOB_FIELDS.ATTACHMENT.FILE_NAME.api_name],
      createdTime: attachment?.[JOB_FIELDS.CREATE_TIME.api_name],
      addedByUser: fileUploadedBy?.[JOB_FIELDS.ATTACHMENT.UPLOADED_BY.NAME.api_name],
      userName: {
        firstName: firstName,
        lastName: lastName
      },
      fileId: attachment?.[JOB_FIELDS.ATTACHMENT.FILE_ID.api_name],
      id: attachment?.id
    };
    return row;
  };
  const currentTab = props?.tabs?.[props.activeTabIndex];

  const getDocsTable = ()=> {
    const attachments = [...job?.attachments];
    let currentAttachments = attachments.filter(attachment=>attachment?.[JOB_FIELDS.ATTACHMENT.TYPE.api_name] === currentTab?.type);

    if(currentTab?.type === allConstants.ATTACHMENT_TYPES.PERMIT.value) {
      const Cf1rAttachments = attachments.filter(attachment=>attachment?.[JOB_FIELDS.ATTACHMENT.TYPE.api_name] === allConstants.ATTACHMENT_TYPES.CF1R.value);
      currentAttachments = [...currentAttachments, ...Cf1rAttachments];
    }

    return currentAttachments.map(attachment=>createTableRow(attachment));
  };

  const docsTable = getDocsTable();

  const canDeleteAttachment = () => {
    const attachmentType = currentTab?.type;
    const mapDeletePermissionsToType = {
      [ATTACHMENT_TYPES.PERMIT.api_name]: ELEMENTS_ATTACHMENTS.deletePermitAttachment,
      [ATTACHMENT_TYPES.HERS.api_name]: ELEMENTS_ATTACHMENTS.deleteHersAttachment,
      [ATTACHMENT_TYPES.INSPECTION.api_name]: ELEMENTS_ATTACHMENTS.deleteInspectionAttachment,
    };
    return NewPermissionGate({
      user,
      elementPermissions: attachmentRules[mapDeletePermissionsToType[attachmentType]],
    });
  };

  const deleteOnClick = (attachment) => {
    if(!canDeleteAttachment(attachment)) {
      const attachmentType = getAttachmentTypeForModule()?.label;
      Popup.setShow(allConstants.ERROR_MSG.NO_PERMISSION('delete', attachmentType));
      return;
    }

    setCurrentAttachment(attachment);
    setShowConfirmDelete(true);
  };

  const cancelDelete = () => {
    setShowConfirmDelete(false);
    setCurrentAttachment(null);
  };

  const confirmDelete = async () => {
    if(!currentAttachment || !currentAttachment?.id) {
      toast('Error deleting attachment: missing attachment id', {type: 'error'});
      return;
    }
    setDeleteInProgress(true);
    const deleteResponse = await ApiUtils.makeApiCall('POST', 'jobs/delete-attachment',
      JSON.stringify({
        attachmentId: currentAttachment?.id,
        jobId: job?.id
      }));
    if(deleteResponse.status === 'success') {
      toast('Attachment deleted successfully', {type: 'success'});

      const updatedJob = {...job};
      updatedJob.attachments = updatedJob.attachments.filter(attachment=>attachment?.id !== currentAttachment?.id);
      setShowConfirmDelete(false);
      setCurrentAttachment(null);
      setJob(updatedJob);
      setDeleteInProgress(false);
    } else {
      toast(`Error deleting attachment ${deleteResponse?.errorMessage || ''}`, {type: 'error'});
      setDeleteInProgress(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const editOnClick = (attachment) => {
    setCurrentAttachment(attachment);
    setShowEdit(true);
  };

  // eslint-disable-next-line no-unused-vars
  const cancelEdit = () => {
    setShowEdit(false);
    setCurrentAttachment(null);
  };

  // eslint-disable-next-line no-unused-vars
  const confirmEdit = async (attachment) => {
    setUpdateInProgress(true);
    const existingAttachment = job?.attachments?.find(a=>a?.id === attachment?.id);
    const updatedAttachment = {
      [JOB_FIELDS.ATTACHMENT.CREATED_AT.api_name]: existingAttachment?.[JOB_FIELDS.ATTACHMENT.CREATED_AT.api_name],
      [JOB_FIELDS.ATTACHMENT.UPLOAD.api_name]: existingAttachment?.[JOB_FIELDS.ATTACHMENT.UPLOAD.api_name],
      [JOB_FIELDS.ATTACHMENT.FILE_NAME.api_name]: existingAttachment?.[JOB_FIELDS.ATTACHMENT.FILE_NAME.api_name],
      [JOB_FIELDS.ATTACHMENT.FILE_UPLOAD_ID.api_name]: attachment?.fileId,
      [JOB_FIELDS.ATTACHMENT.TYPE.api_name]: existingAttachment?.[JOB_FIELDS.ATTACHMENT.TYPE.api_name],

    };
    //delete existing attachment first
    const deleteResponse = await ApiUtils.makeApiCall('POST', 'jobs/delete-attachment',
      JSON.stringify({
        attachmentId: currentAttachment?.id,
        jobId: job?.id
      }));

    if(deleteResponse.status === 'success') {
      const updateResponse = await ApiUtils.makeApiCall('POST', 'jobs/add-attachments',
        JSON.stringify({
          jobId: job?.[allConstants.JOB_FIELDS.ID.api_name],
          attachments: [updatedAttachment]
        }));
      if(updateResponse.status === 'success') {
        toast('Attachment updated successfully', {type: 'success'});
        if(updateResponse?.data?.job) {
          setJob(updateResponse?.data?.job);
        }
        setShowEdit(false);
        setCurrentAttachment(null);

      } else {
        toast('Error updating attachment', {type: 'error'});
      }
    } else {
      toast('Error updating attachment', {type: 'error'});
    }
    setUpdateInProgress(false);
  };

  const isAddingAttachment = props.addAttachment;
  const getContent = (rows)=> {
    if(!rows || rows.length === 0) {
      return <TableRow>
        <TableCell sx={{width: '100%'}} colSpan={5}>
          <NoDataStringWrapper text={`No ${currentTab?.label} attachments Added`}/>
        </TableCell>

      </TableRow>;

    }

    rows.reverse();

    return (
      <>
        {rows.map((row) => (
          <TableRow key={row.name}
            sx={{
              '&:last-child td, &:last-child th': {border: 0},
              '& .MuiTableCell-root': {
                paddingY: '4px'
              }
            }}
          >
            <TableCell component="th" scope="row" sx={{
              width: '30px',
            }}>
              <Checkbox inputProps={{'aria-label': `documentRow ${row.id}`}}
                onChange={(e )=>props.handleAttachmentCheckboxChange(e?.target?.checked, row)}
              />

            </TableCell>
            <TableCell component="th" scope="row" sx={{
              width: '30px',
              padding:0,
              '& img': {
                width: '16px',
              }
            }}>
              {row.origin ?  <img src={IpermitIcon} alt={'ipermit document'}/> : <Box sx={{
                boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.03)',
                borderRadius: '50px',
                backgroundColor: '#EDF2FF',
                height: '32px',
                width: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Typography fontSize={'14px'}>
                  {Utils.getInitials(row.userName)}
                </Typography>
              </Box>}
            </TableCell>
            <TableCell component="th" scope="row" sx={{paddingLeft: '8px'}} >
              <Button type={'clear'} onClick={() => downloadFile({id: row.fileId})}>
                {row.fileName}
              </Button>
            </TableCell>

            <TableCell component="th" scope="row" >
              {Utils.formatIsoDateString(row.createdTime)}
            </TableCell>
            <TableCell component="th" scope="row" >
              {row.addedByUser}
            </TableCell>

            <TableCell component="th" scope="row" sx={{padding:0, width: '40px'}}>
              <Box display={'flex'}>
                {/*todo: use this when edit is ready */}
                {/*<Button*/}
                {/*  onClick={() => editOnClick(row)}*/}
                {/*  disabled={props.addAttachment}*/}
                {/*  sx={{*/}
                {/*    minWidth: 'fit-content',*/}
                {/*    minHeight: 'fit-content',*/}
                {/*    marginLeft: 'auto',*/}
                {/*  }}>*/}
                {/*  <img src={EditIcon} alt={'edit document'}/>*/}
                {/*</Button>*/}
                {/*<Spacer x={1}/>*/}
                <Button
                  onClick={()=>deleteOnClick(row)}
                  disabled={isAddingAttachment}
                  color={'error'}
                  sx={{
                    minWidth: 'fit-content',
                    minHeight: 'fit-content',
                    marginLeft: 'auto',
                  }}>
                  <DeleteForeverOutlinedIcon/>
                </Button>
              </Box>
            </TableCell>

          </TableRow>
        ))}
      </>

    );
  };

  const displayTableHeader = ()=> {

    return docsTable.length > 0;
  };

  const getAttachmentTypeForModule = ()=> {
    const module = props?.moduleName;
    const mapModuleToType = {
      [allConstants.IPERMIT_MODULES.INSPECTION]: allConstants.ATTACHMENT_TYPES.INSPECTION,
      [allConstants.IPERMIT_MODULES.PERMIT]: allConstants.ATTACHMENT_TYPES.PERMIT,
      [allConstants.IPERMIT_MODULES.HERS]: allConstants.ATTACHMENT_TYPES.HERS,
    };
    const type =  mapModuleToType?.[module] ? mapModuleToType?.[module] : allConstants.ATTACHMENT_TYPES.PERMIT;
    return type;
  };

  return (
    <>
      {showConfirmDelete && <ConfirmDeleteFile
        onCancel={cancelDelete}
        submitAction={confirmDelete}
        attachment={currentAttachment}
        deleteInProgress={deleteInProgress}
      />}
      {Popup.PopUp()}

      {/*{showEdit && <EditFile*/}
      {/*  onCancel={cancelEdit}*/}
      {/*  submitAction={confirmEdit}*/}
      {/*  attachment={currentAttachment}*/}
      {/*  updateInProgress={updateInProgress}*/}
      {/*/>}*/}

      {props.addAttachment && <IpermitAddAttachment onCancel={props.cancelAction} user={user}
        defaultType={getAttachmentTypeForModule()}/>}
      <Table size="small" sx={{minWidth: 650}} aria-label="attachment table">
        {displayTableHeader() && <TableHead sx={{
          '& .MuiTableCell-root': {
            color: STYLE_CONSTANTS.COLORS.fontPale
          }
        }}>
          <TableRow>
            <TableCell sx={{
              width: '30px',
              padding:0,
            }}/>
            <TableCell sx={{
              width: '30px',
              padding:0,
            }}/>
            <TableCell sx={{paddingLeft: '24px'}}>File</TableCell>
            <TableCell>Date </TableCell>
            <TableCell>Left by</TableCell>
            <TableCell sx={{padding:0, width: '50px'}}/>

          </TableRow>
        </TableHead>}
        <TableBody>
          {getContent(docsTable)}
        </TableBody>
      </Table>

    </>
  );
};

AttachmentsContent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired).isRequired,
  activeTabIndex: PropTypes.number,
  addAttachment: PropTypes.bool,
  cancelAction: PropTypes.func.isRequired,
  moduleName: PropTypes.string.isRequired,
  handleAttachmentCheckboxChange: PropTypes.func
};

AttachmentsContent.defaultProps = {
  activeTabIndex: 0,
  addAttachment: false,
  handleAttachmentCheckboxChange: ()=>{}
};

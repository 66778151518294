import React from 'react';
import PropTypes from 'prop-types';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import * as allConstants from '../../../../../constants/allConstants';
import {useFormContext} from 'react-hook-form';


const LinersPanel = (props)=> {
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const subParent = `${FIELDS.REPAIR.api_name}.${FIELDS.LINERS.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.PLUMBING.api_name];

  const getFieldValue = (fieldName)=> {
    const blockValues = values?.[FIELDS.REPAIR.api_name]?.[FIELDS.LINERS.api_name];
    return blockValues?.[fieldName];
  };
  return <>
    <CustomFormBlock{...props}
      field={FIELDS.LINER_QUANTITY}
      nested={true}
      parent={blockParent}
      defaultValue={getFieldValue(FIELDS.LINER_QUANTITY.api_name)}
      renderLabel={true}
    />
  </>;
};

LinersPanel.propTypes = {
  parent: PropTypes.string.isRequired,
};

export {LinersPanel};

import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import {ReactComponent as EquipmentIcon} from '../../../../static/images/equpment.svg';
import {ReactComponent as GeneralIcon} from '../../../../static/images/gene_info.svg';
import {ReactComponent as OtherIcon} from '../../../../static/images/other_info.svg';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: STYLE_CONSTANTS.COLORS.globalGreen,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: STYLE_CONSTANTS.COLORS.globalGreen,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
  backgroundColor: '#EBEDF3',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    border: `2px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
    backgroundColor: STYLE_CONSTANTS.COLORS.white,
  }),
  ...(ownerState.completed && {
    backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen
  }),
}));

function ColorlibStepIcon(props) {
  const {active, completed, className} = props;
  const iconColor = active ? STYLE_CONSTANTS.COLORS.globalBlue : completed ? STYLE_CONSTANTS.COLORS.white : STYLE_CONSTANTS.COLORS.fontPale;
  const icons = [GeneralIcon, EquipmentIcon, OtherIcon];
  const StepIcon = icons[props.icon-1];

  return (

    <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
      <StepIcon style={{width: '32px', fill: iconColor}}/>
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.number,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
};

const steps = ['General Info', 'Equipment', 'Other'];

const JobStepper =  (props) => {
  let showSteps = [...steps];
  if(props.shouldSkipEquipment) {
    showSteps.splice(1, 1);
  }
  return (
    <Stack sx={{width: {lg: '50%', md: '60%', xs: '100%'}}} spacing={4}>
      <Stepper alternativeLabel activeStep={props.currentActive} connector={<ColorlibConnector />} sx={{width: '100%'}}>
        {showSteps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}
              sx={{
                '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                  marginTop: 1
                }
              }}>{label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

JobStepper.propTypes = {
  currentActive: PropTypes.number.isRequired,
  shouldSkipEquipment: PropTypes.bool,

};

JobStepper.defaultProps = {
  shouldSkipEquipment: false,
};
export default JobStepper;

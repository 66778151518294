import React from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {Box, Typography} from '@mui/material';
import {PlumbingDetails} from '../details/plumbing/plumbingDetails';
import {ElectricalDetails} from '../details/electrical/electricalDetails';
import {HvacDetails} from '../details/hvac/hvacDetails';
import {useJobDetailViewStyles} from '../views/jobDetailViewStyles';
import {SolarDetails} from '../details/solar/solarDetails';
import {MiscDetails} from '../details/misc/miscDetails';
import {TempPowerDetails} from '../details/tempPower/tempPowerDetails';
import {ReroofDetails} from '../details/reroof/reroofDetails';


function JobDetails(job, preventScroll) {
  let details;
  let component;
  let equipment;
  const classes = useJobDetailViewStyles();
  const jobType = job[allConstants.JOB_FIELDS.JOB_TYPE.api_name];
  const JOB_TYPES = allConstants.JOB_TYPES;
  let jobJSON = {};
  try{
    jobJSON = JSON.parse(job[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
  } catch (e) {
    console.group('ERROR on jobJSON parsing');
    console.log('Tried to parse: ', job[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
    console.log(e);
    console.groupEnd('ERROR on jobJSON parsing');
  }
  // console.log('*******', jobJSON)

  switch (jobType) {
  case JOB_TYPES.PLUMBING.value: {
    details = jobJSON?.[JOB_TYPES.PLUMBING.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    equipment = jobJSON?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];

    component = PlumbingDetails;
    break;
  }
  case JOB_TYPES.ELECTRICAL.value: {
    details = jobJSON?.[JOB_TYPES.ELECTRICAL.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    equipment = jobJSON?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    component = ElectricalDetails;
    break;
  }
  case JOB_TYPES.HVAC_RES.value:{
    details = jobJSON?.[JOB_TYPES.HVAC_RES.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    equipment = jobJSON?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    component = HvacDetails;
    break;
  }
  case JOB_TYPES.TESTING_ONLY.value:{
    details = jobJSON?.[JOB_TYPES.TESTING_ONLY.api_name] || jobJSON?.[JOB_TYPES.HVAC_RES.api_name] || {};
    equipment = jobJSON?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    component = HvacDetails;
    break;
  }
  case JOB_TYPES.SOLAR.value: {
    details = jobJSON?.[JOB_TYPES.SOLAR.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    component = SolarDetails;
    break;
  }
  case JOB_TYPES.MISC.value: {
    details = jobJSON?.[JOB_TYPES.MISC.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    component = MiscDetails;
    break;
  }
  case JOB_TYPES.TEMPORARY_POWER.value: {
    details = jobJSON?.[JOB_TYPES.TEMPORARY_POWER.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    equipment = jobJSON?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    component = TempPowerDetails;
    break;
  }
  case JOB_TYPES.REROOF.value: {
    details = jobJSON?.[JOB_TYPES.REROOF.api_name] || {};
    if(job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name] === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
      details[allConstants.JOB_TYPES.COMMERCIAL.api_name] = jobJSON?.[JOB_TYPES.COMMERCIAL.api_name];
    }
    component = ReroofDetails;
    break;
  }
  default: break;
  }
  if(!component) return null;

  return (
    <Box className={preventScroll ? '' : classes.scrollable_tab_content}>
      {job.isMissingMap &&  <Typography variant={'body1'} sx={{
        fontSize: {sx: '14px', md: '16px'},
        color: 'red'
      }}>
        map is required for this job, please move to the map and add the setbacks</Typography>}
      {component({details: details, equipment: equipment})}
    </Box>
  );
}

const JobDetailsComponent = (props) => {
  return (
    JobDetails(props.job, props.preventScroll)
  );
};

export default JobDetails;
export {JobDetailsComponent};

import React from 'react';
import PropTypes from 'prop-types';
import {CustomInput} from './customInputComponent';
import NumberFormat from 'react-number-format';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import {Icon, InputAdornment} from '@mui/material';
import DollarSign from '../../static/images/dollarSign.svg';

const CurrencyInput = (props)=> {
  // console.log("erorrs", props.formState.errors)
  return(
    <NumberFormat key={props.defaultValue}
      size="small"
      label="price"
      color="secondary"
      variant="outlined"
      onValueChange={(v) => {
        props.setValueToForm(props.field_name, parseFloat(v.value), {shouldDirty: true,  trigger: props.field_name});
      }}
      allowEmptyFormatting={false}
      {...props.field}
      {...props.register(props.field_name, {required: props.field.required})}
      customInput={CustomInput}
      defaultValue={props.defaultValue}
      disabled={!props.isEditable || props.viewMode}
      thousandsGroupStyle="thousand"
      prefix="$"
      decimalSeparator="."
      displayType="input"
      type="text"
      thousandSeparator={true}
      allowNegative={true}
      error={props.isError}
      endAdornment={<InputAdornment position="end" sx={{right: '6px !important', transform: 'scale(0.8)', display:'flex'}}>
        {
          <Icon>
            <img src={DollarSign} height={20} width={20} alt={''}/>
          </Icon>
        }
      </InputAdornment>}
    />
  );
};

CurrencyInput.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired
  }
};
CurrencyInput.defaultProps = formBlockDefaultProps;
export default CurrencyInput;

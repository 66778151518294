import {makeStyles} from '@mui/styles';
import HexagonBg from '../../static/images/hexagone-lg.png';
import HomepageImage from '../../static/images/homepage-image.svg';
import myTheme from '../../styles/myStyles';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

const getHexagonStyles = (queries)=> {
  const small={
    hex_height: '250px',
    hex_width: '275px',
    hex_bg_size: '215px',
    hex_paddingTop: '10%',
    hex_font_size: '20px',
    hex_margin: '0 auto 20px auto',
    ribbon_height: '65px',
    ribbon_width: '275px',
    ribbon_bottom_pos: '55px',
    ribbon_left_pos: '0px',
    notch_max: '32px',
    notch_min: '26px'
  };
  const medium = {
    hex_height: '290px',
    hex_width: '250px',
    hex_bg_size: '250px',
    hex_paddingTop: '7%',
    hex_font_size: '30px',
    hex_margin: '8vh auto 10vh auto',
    ribbon_height: '75px',
    ribbon_width: '360px',
    ribbon_bottom_pos: '65px',
    ribbon_left_pos: 'unset',
    notch_max: '36px',
    notch_min: '33px'
  };
  if(queries.isXS) {
    return small;
  }
  return medium;
};

export const useHomePageStyles = props=> makeStyles(theme=> {
  const hexStyles = getHexagonStyles(props);
  theme = myTheme;
  return {
    welcome_box: {
      fontWeight: '500'
    },
    hexagon: {
      height: `${hexStyles.hex_height}`,
      width: `${hexStyles.hex_width}`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundImage: `url(${HexagonBg})`,
      backgroundSize: `${hexStyles.hex_bg_size}`,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#fff',
      fontWeight: 900,
      fontSize: `${hexStyles.hex_font_size}`,
      // paddingTop: `${hexStyles.hex_paddingTop}`,
      // transform: 'translateY(10px)'
      // margin: `${hexStyles.hex_margin}`,
    },
    hexagon_ribbon: {
      height: `${hexStyles.ribbon_height}`,
      width: `${hexStyles.ribbon_width}`,
      backgroundColor: '#fff',
      position: 'absolute',
      bottom: `${hexStyles.ribbon_bottom_pos}`,
      left: `${hexStyles.ribbon_left_pos}`,
      color: `${STYLE_CONSTANTS.COLORS.globalBlue}`,
      fontWeight: 900,
      fontSize: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&::after': {
        content: '""',
        width: '0',
        height: '0',
        position: 'absolute',
        right: '0',
        backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
        borderRight: `${hexStyles.notch_min} solid transparent`,
        borderTop: `${hexStyles.notch_max} solid white`,
        borderLeft: `${hexStyles.notch_min} solid white`,
        borderBottom: `${hexStyles.notch_max} solid white`,
      },  '&::before': {
        content: '""',
        width: '0',
        height: '0',
        position: 'absolute',
        left: '0',
        backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
        borderRight: `${hexStyles.notch_min} solid transparent`,
        borderTop: `${hexStyles.notch_max} solid white`,
        borderLeft: `${hexStyles.notch_min} solid white`,
        borderBottom: `${hexStyles.notch_max} solid white`,
        transform: 'rotate(180deg)'
      }
    },
    circle_container: {
      position: 'relative',
      width: '20em',
      height: '20em',
      padding: '2.8em',
      left: '0',
      top: '-20px',
      /*2.8em = 2em*1.4 (2em = half the width of a link with img, 1.4 = sqrt(2))*/
      borderRadius: '50%',
      margin: '0 auto 0 36px',
      [`${theme.breakpoints.only('xl')}`]: {
        margin: '0 auto 0 118px',
      },

      '&:before': {
        content:`url(${HomepageImage})`,
        position:'absolute',
        top: '-16%',
        left: '-33%',
        bottom: '0',
        transform: 'scale(0.8)',
        // backgroundColor: '#000000',
      }
    },
  };
});

import {ROLES} from '../constants/allConstants';
const CONTRACTOR_ELEMENTS = {
  viewPage: 'viewPage',
  editUser: 'editUser',
  addUserButton: 'addUserButton',
  changeCompanyAdmin: 'changeCompanyAdmin',
  manageUsers: 'manageUsers',
  deleteUser: 'deleteUser',
  resetPassword: 'resetPassword',
  modifyEmailPreferences: 'modifyEmailPreferences',

};

const contractorPolicies = {
  [CONTRACTOR_ELEMENTS.viewPage]: 'user.view',
  [CONTRACTOR_ELEMENTS.editUser]: 'user.editUsers',
  [CONTRACTOR_ELEMENTS.addUserButton]: 'user.invite',
  [CONTRACTOR_ELEMENTS.changeCompanyAdmin]: 'user.manageUsers',
  [CONTRACTOR_ELEMENTS.manageUsers]: 'user.manageUsers',
  [CONTRACTOR_ELEMENTS.resetPassword]: 'user.manageUsers',
  [CONTRACTOR_ELEMENTS.modifyEmailPreferences]: 'user.modifyEmailPreferences',
};

const contractorUserPageRules = Object.freeze({
  [CONTRACTOR_ELEMENTS.viewPage]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: [ROLES.COMPANY_ADMIN],
  },
  [CONTRACTOR_ELEMENTS.editUser]: {
    policyName: contractorPolicies[CONTRACTOR_ELEMENTS.editUser],
    contractorAccess: true,
    contractorPermissions: [ROLES.COMPANY_ADMIN],
  },

  [CONTRACTOR_ELEMENTS.resetPassword]: {
    policyName: contractorPolicies[CONTRACTOR_ELEMENTS.resetPassword],
    contractorAccess: false,
  },

  [CONTRACTOR_ELEMENTS.addUserButton]: {
    policyName: contractorPolicies[CONTRACTOR_ELEMENTS.addUserButton],
    contractorAccess: true,
    contractorPermissions: [ROLES.COMPANY_ADMIN],
    impersonateOnly: true,
  },
  [CONTRACTOR_ELEMENTS.changeCompanyAdmin]: {
    policyName: contractorPolicies[CONTRACTOR_ELEMENTS.changeCompanyAdmin],
    contractorAccess: true,
    contractorPermissions: [ROLES.COMPANY_ADMIN],
  },
  [CONTRACTOR_ELEMENTS.manageUsers]: {
    policyName: contractorPolicies[CONTRACTOR_ELEMENTS.manageUsers],
    contractorAccess: true,
    contractorPermissions: [ROLES.COMPANY_ADMIN],
  },
  [CONTRACTOR_ELEMENTS.modifyEmailPreferences]: {
    policyName: contractorPolicies[CONTRACTOR_ELEMENTS.modifyEmailPreferences],
    contractorAccess: true,
    contractorPermissions: [ROLES.COMPANY_ADMIN],
  }
  // [CONTRACTOR_ELEMENTS.deleteUser]: {
  //   [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.COMPANY_ADMIN]
  // }
});

export {contractorUserPageRules, CONTRACTOR_ELEMENTS};

import React from 'react';
import {Grid} from '@mui/material';
import InspectionCallLogs from '../../ipermitJobPages/recordViews/inspection/callLogsPanel';
import CallsIcon from '../../../../static/images/callLogIcon.svg';
import InspectionTestResults from '../../ipermitJobPages/recordViews/inspection/testResultsPanel';
import ResultsIcon from '../../../../static/images/testResults.svg';
import {DrawerWrapper} from '../../../../pieces/drawer';

const  Inspections = ()=> {

  const tabs =  {
    calls: {
      name: 'Call Logs',
      panel: <InspectionCallLogs isContractor={true}/>,
      icon: CallsIcon
    },
    tests:{
      name: 'Test results',
      panel: <InspectionTestResults isContractor={true}/>,
      icon: ResultsIcon
    }
  };

  return (
    <Grid container>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DrawerWrapper tabs={tabs}/>
      </Grid>
    </Grid>
  );
};

export default Inspections;

const TAG_ELEMENTS = {
  viewTags: 'viewTags',
  addTagButton: 'addTagButton',
  deleteTag: 'deleteTag',
  editTag: 'editTag'
};

const tagsPolicies = {
  [TAG_ELEMENTS.viewTags]: 'inspection.addTags',
  [TAG_ELEMENTS.addTagButton]: 'inspection.manageTags',
  [TAG_ELEMENTS.deleteTag]: 'inspection.manageTags',
  [TAG_ELEMENTS.editTag]: 'inspection.manageTags'
};


const tagsPageRules = Object.freeze({
  [TAG_ELEMENTS.viewTags]: {
    policyName: tagsPolicies[TAG_ELEMENTS.viewTags],
    contractorAccess: false,
  },

  [TAG_ELEMENTS.addTagButton]: {
    policyName: tagsPolicies[TAG_ELEMENTS.addTagButton],
    contractorAccess: false,
  },
  [TAG_ELEMENTS.editTag]: {
    policyName: tagsPolicies[TAG_ELEMENTS.editTag],
    contractorAccess: false,
  },
  [TAG_ELEMENTS.deleteTag]: {
    policyName: tagsPolicies[TAG_ELEMENTS.deleteTag],
    contractorAccess: false,
  },
});

export {tagsPageRules, TAG_ELEMENTS};

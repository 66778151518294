import React, {useEffect, useState} from 'react';
import * as allConstants from '../../constants/allConstants';
import Utils from '../../utils/utils';
import {urlSearchParams} from '../../baseUrls';
import {RecordBodyWrapper} from '../wrappers/recordBodyWrapper';
import {RecordWrapper} from '../wrappers/recordWrapper';
import {RecordHeader} from '../../pieces/recordHeader';
import {Box, Button, Typography} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {COLORS} from '../../styles/styleConstants';
import EditFormButton from '../../pieces/editFormButton';
import ApiUtils from '../../utils/apiUtils';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Spacer from '../../pieces/spacer';
import {useNavigate} from 'react-router-dom';
import {RaterInfoPanel} from './raterInfoPanel';
import {timelineTypes, useTimeline} from '../../hooks/useTimeline';
import {FloatingMenu} from '../../pieces/floatingMenu';
import {useGotIt} from '../../hooks/useGotIt';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {RATER_ELEMENTS, ratersPageRules} from '../../permissions/raterPageAccessControl';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';


export const RaterView = () => {
  const raterId = Utils.getUrlParameter(urlSearchParams.raterId);
  const gotIt = useGotIt();
  const user = useSelector((state=> state.user));

  const timeline = useTimeline({
    recordId: raterId,
    moduleName: timelineTypes.rater
  });

  const isAdding = !raterId;

  const [viewMode, setViewMode] = useState(!isAdding);
  const [raterData, setRaterData] = useState({});
  const {RATER_FIELDS} = allConstants;
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  useEffect(()=> {
    if(timeline.showTimeline) {
      setViewMode(true);
    }
  }, [timeline.showTimeline]);

  const tabNames = {
    raterInfo: 'Rater Info',
    raterCities: 'Cities',
  };

  const panelProps = {
    viewMode: viewMode,
    setViewMode: setViewMode,
    raterData: raterData,
    setRaterData: setRaterData,
    setSubmitting: setSubmitting,
    isAdding: isAdding,
  };

  const getTabs = ()=> {
    return {
      raterInfo: {
        name: tabNames.raterInfo,
        api_name: 'rater_info',
        order: 0,
        panel: <RaterInfoPanel {...panelProps}/>,
        fieldsToValidate: [],
      }
    };
  };

  const getCurrentTabs = ()=> {
    const tabs = getTabs();
    const currentTabs = {};
    for (const [key, value] of Object.entries(tabs)) {
      currentTabs[key] = value;
    }
    return currentTabs;
  };


  useEffect(async ()=> {
    if(!raterId) return;
    const data = await ApiUtils.makeApiCall('GET', `ipermit/raters/${raterId}`);
    setRaterData(data?.data?.rater);
  }, []);

  useEffect(async ()=> {
    if(viewMode && isAdding) {
      navigate(getBackPath());
    }
  }, [viewMode]);

  useEffect(()=> {
    if(isAdding && raterData?.id){
      navigate(`${allConstants.PATH.RATERS}${location.search}&${urlSearchParams.raterId}=${raterData.id}`);
    }
  }, [raterData]);

  const getValue = (field)=> {
    return raterData?.[field.api_name]||'';
  };

  /**
   * Switch to edit mode
   */
  const switchToEdit = ()=> {
    if(NewPermissionGate({
      elementPermissions: ratersPageRules[RATER_ELEMENTS.editRater],
      user: user,
    })){
      setViewMode(false);
    } else {
      gotIt.setShow(allConstants.ERROR_MSG.NO_PERMISSION('edit', 'raters'));
    }
  };

  const onCancel = async (e)=> {
    e.preventDefault();
    setViewMode(true);
  };

  const headerHeight = '66px';

  const getBackPath = ()=> {
    const items = Utils.getUrlParameter('items');
    const page = Utils.getUrlParameter('page');
    let path = `${allConstants.PATH.RATERS}?items=${items||100}&page=${page||1}`;
    return path;
  };

  useEffect(()=> {
    return ()=> {
      urlParams.delete(urlSearchParams.raterId);
      window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`);
    };
  }, []);

  const changeRaterStatus= async () => {
    if(!NewPermissionGate({
      elementPermissions: ratersPageRules[RATER_ELEMENTS.activate],
      user: user,
    })) {
      gotIt.setShow(allConstants.ERROR_MSG.NO_PERMISSION(isRaterDisabled() ? 'activate' : 'deactivate', 'raters'));
      return;
    }
    const successMessage = `${isRaterDisabled() ? 'Activated' : 'Deactivated' }: ${raterData?.[allConstants.RATER_FIELDS.NAME.api_name]}.`;
    const errorMessage = `Error on ${isRaterDisabled() ? 'activation' : 'deactivation'}.`;
    const endpoint = isRaterDisabled() ? 'ipermit/raters/activate' : 'ipermit/raters/deactivate';

    const result = await ApiUtils.makeApiCall('POST', endpoint, JSON.stringify({id: raterData?.id}));

    if(result.status === 'error') {
      toast.error(result?.message || errorMessage, {
        toastId: 'changeRaterStatusError',
      });
    } else {
      toast.success(successMessage, {
        toastId: 'changeRaterStatusSuccess',
      });
    }
    if(!result?.data?.rater) return;
    setRaterData(result.data.rater);

  };

  const isRaterDisabled= ()=> {
    return raterData?.[allConstants.RATER_FIELDS.STATUS.api_name] === allConstants.RATER_STATUSES.DISABLED.value;
  };

  const getDepartmentsString = ()=> {
    const departments = getValue(RATER_FIELDS.DEPARTMENTS);
    if(!departments) return '';
    return departments.map((department)=> department).join(', ');
  };

  const floatingMenuItems = [
    timeline.timelineButton(),
  ];


  return(
    <>
      {raterId && <FloatingMenu items={floatingMenuItems}/>}
      {gotIt.PopUp()}
      <RecordWrapper
        hideNavbar={false}
        headerHeight={headerHeight}
        headerPadding={'0px'}
        noDrawer={true}

        header={
          <Box display={'flex'} flexDirection={'row'} id={'headercontainer'} justifyContent={'center'} sx={{
            width: '100%',
            paddingTop: '16px',
            // backgroundColor: COLORS.lightBlue

          }}>
            <Box display={'flex'} flexDirection={'row'} width={'100%'} height={headerHeight}>
              <Box display={'flex'}
                width={'100%'}
                maxWidth={'1200px'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={0.5}

              >
                <RecordHeader
                  text={isAdding ? 'Add rater' :`${getValue(RATER_FIELDS.NAME)}`}
                  headerHeight={headerHeight}
                >
                  <StatusRenderer value={getValue(RATER_FIELDS.STATUS)}/>
                </RecordHeader>
                {getValue(RATER_FIELDS.EMAIL) &&
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <EmailOutlinedIcon sx={{color: COLORS.globalBlue, marginRight: '8px'}}/>
                  <Typography component={'span'} sx={{
                    fontStyle: 'italic'
                  }}>{getValue(RATER_FIELDS.EMAIL)}</Typography>
                  <Spacer x={2}/>
                  <Typography component={'span'} sx={{
                    fontWeight: 'bold',
                    color: COLORS.globalBlue
                  }}>Department:</Typography>
                  <Spacer x={1}/>
                  <Typography component={'span'}>
                    {getDepartmentsString()}
                  </Typography>
                </Box>}
              </Box>
              {!timeline.showTimeline && <Box display={'flex'} sx={{width:'350px', height: 'fit-content'}} alignItems={'center'} justifyContent={'flex-end'}>
                <Button key={raterData[allConstants.RATER_FIELDS.STATUS.api_name]}
                  variant={viewMode ? 'contained' : 'outlined'}
                  color={'primary'}
                  sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                    '&.MuiButton-root': {
                      textTransform: 'uppercase'
                    },
                    '&.MuiButton-containedPrimary': {
                      backgroundColor: STYLE_CONSTANTS.COLORS.globalRed,
                    },
                    '&:hover': {
                      color: STYLE_CONSTANTS.COLORS.white,
                    },
                  }}
                  onClick={changeRaterStatus}
                  disabled={!viewMode}
                >{isRaterDisabled() ? 'Activate' : 'Disable'}</Button>
                <EditFormButton onClick={viewMode ? switchToEdit : onCancel}
                  onCancel={onCancel}
                  edit={viewMode}

                />

              </Box>}
            </Box>
          </Box>
        }
      >
        <Spacer y={2}/>

        <RecordBodyWrapper
          tabs={getCurrentTabs()}
          headerHeight={headerHeight}
          goBack={{
            show: true,
            path: getBackPath()
          }}
          showLoader={submitting}
          preventSwitchTabsOnEdit={!viewMode}
          showTimeline={timeline.showTimeline}
          timeline={timeline.timeline()}
          // setSwitchTabsWarning={setSwitchTabsWarning}
        />

      </RecordWrapper>
      {/*</RecordController>*/}

    </>

  );
};

import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {BlockHeader} from '../../../../../pieces/displayJob/blockHeader';
import {reroofUtils} from './reroofDisplayUtils';
import {useJobDetailViewStyles} from '../../views/jobDetailViewStyles';
import {jobDisplayUtils} from '../jobDisplayUtils';
import {FormLabel, FormValue} from '../../../../../pieces/displayJob/formLine';
import * as allConstants from '../../../../../constants/allConstants';
import {CommercialSection} from '../commercialSection';

const ReroofDetails = (props) => {
  const {details} = props;
  const classes = useJobDetailViewStyles();

  const getScope = ()=> {
    const scope = reroofUtils.getScopeText(details);
    if(!scope) return null;
    return (
      <>
        <FormLabel label={allConstants.JOB_FIELDS.GENERAL.SCOPE.display_label} />
        <FormValue value={scope} />
      </>
    );
  };

  const scopeOfWork = jobDisplayUtils.getBlockContent(reroofUtils.getScopeOfWorkInfo(details), classes.details_block );
  const scopeDetails = jobDisplayUtils.getBlockContent(reroofUtils.getScopeDetails(details),classes.details_block );
  const materialInfo = jobDisplayUtils.getBlockContent(reroofUtils.getMaterialInfo(details), classes.details_block, 'Roofing Material');
  const wasteInfo = jobDisplayUtils.getBlockContent(reroofUtils.getWasteInfo(details), classes.details_block,'Waste Removal');
  const buildingInfo = jobDisplayUtils.getBlockContent(reroofUtils.getBuildingsInfo(details), classes.details_block,'Buildings');
  const scopeText = getScope();
  const commercial = <CommercialSection details={details}/>;

  return (
    <>
      <Grid container columnSpacing={4}>
        {commercial &&  <Grid item xs={12} >
          {commercial}
        </Grid>}
        {(scopeOfWork || scopeDetails) && <BlockHeader header={'Scope of Work'}/>}
        {scopeOfWork &&
        <Grid item xs={12} sm={6} >
          {scopeOfWork}
        </Grid>}
        {scopeDetails &&
        <Grid item xs={12} sm={6} >
          {scopeDetails}
        </Grid>}
        {scopeText && <Grid item  xs={12} sx={{paddingLeft: {xs: 0, sm: '16px', md: '24px'}}}>
          {scopeText}
        </Grid>}
        {materialInfo &&
        <Grid item xs={12} sm={6} >
          {materialInfo}
        </Grid>}
        {wasteInfo &&
        <Grid item xs={12} sm={6} >
          {wasteInfo}
        </Grid>}
        {buildingInfo &&
        <Grid item xs={12} sm={6} >
          {buildingInfo}
        </Grid>}

      </Grid>
    </>

  );
};

ReroofDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
};
export {ReroofDetails};

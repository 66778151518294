import * as Yup from 'yup';
import validator from '../../utils/validators/validator';
import {COMPANY_FIELDS, ERROR_MSG, PRODUCT_FIELDS, USER_FIELDS} from '../../constants/allConstants';
import {requiredRowFieldsValidator} from '../../utils/validators/subformValidator';
import Utils from '../../utils/utils';
import * as allConstants from '../../constants/allConstants';

class CompanyValidator {
  constructor(props) {
    this.props = props;
    this.init();

    const stateLicenseValidation = ()=> {
      if(this.props.isIpermitInvite) {
        return {
          [COMPANY_FIELDS.STATE_LICENSE.api_name]: Yup.object().shape({
            [COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable(),
          }),
        };
      }
      return {
        [COMPANY_FIELDS.STATE_LICENSE.api_name]: Yup.object().shape({
          [COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]:Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable()
            .test(
              {
                test: value => {
                  const validationParams = {
                    stateLicenseNumber: value,
                  };
                  if(this.props.id){
                    validationParams.id = this.props.id;
                  }
                  return Yup.string().checkIfCompanyExists(validationParams);
                },
                message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE('State License Number')
              }
            ),
          [COMPANY_FIELDS.STATE_LICENSE_EXPIRES_AT.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable(),
          [COMPANY_FIELDS.STATE_LICENSE_FILE.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable(),
        }),
      };
    };

    const workersCompValidation = ()=> {
      if(this.props.isIpermitInvite) {
        return {
          [COMPANY_FIELDS.WORKERS_COMP.api_name]: Yup.object().shape({
            [COMPANY_FIELDS.WORKERS_COMP_AGENT_PHONE.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE)
          })
        };
      }
      return {
        [COMPANY_FIELDS.WORKERS_COMP.api_name]: Yup.object().shape({
          [COMPANY_FIELDS.WORKERS_COMP_AGENT_PHONE.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE),
          [`${COMPANY_FIELDS.WORKERS_COMP_CERT_EXP_DATE.api_name}`]: Yup.string().ensure()
            .when(`${COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name}`, {
              is: (value) => {
                return value && value === allConstants.WORKERS_COMP_TYPES.REQUIRED.value;},
              then: Yup.string().required(ERROR_MSG.REQUIRED_FIELD),
            }),
          [`${COMPANY_FIELDS.WORKERS_COMP_CERT.api_name}`]: Yup.string().nullable().ensure()
            .when(`${COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name}`, {
              is: (value) => {
                return value && value === allConstants.WORKERS_COMP_TYPES.REQUIRED.value;
              },
              then: Yup.string().required(ERROR_MSG.REQUIRED_FIELD),
            }),
          [`${COMPANY_FIELDS.WORKERS_COMP_CERT_NUMBER.api_name}`]: Yup.string().ensure()
            .when(`${COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name}`, {
              is: (value) => {
                return value && value === allConstants.WORKERS_COMP_TYPES.REQUIRED.value;
              },
              then: Yup.string().required(ERROR_MSG.REQUIRED_FIELD),
            })
        }, [[`${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name}`]])
      };
    };

    this.fieldsValidationRules = {
      [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: {
        [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: Yup.string()
          .required('Method of payment can\'t be empty').nullable(),
      },
      [COMPANY_FIELDS.PHONE_NUMBER.api_name]: {
        [COMPANY_FIELDS.PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE),
      },
      [COMPANY_FIELDS.OWNER.api_name]: {
        [COMPANY_FIELDS.OWNER.api_name]: Yup.object().shape({
          [COMPANY_FIELDS.OWNER_PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE),
        })
      },

      [COMPANY_FIELDS.STATE_LICENSE.api_name]: stateLicenseValidation(),

      [COMPANY_FIELDS.DRIVER_LICENSE.api_name]: {
        [COMPANY_FIELDS.DRIVER_LICENSE.api_name]: Yup.object().shape({
          [COMPANY_FIELDS.DRIVER_LICENSE_EXPIRES_AT.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable(),
          [COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable()
        })

      },
      [COMPANY_FIELDS.WORKERS_COMP.api_name]:workersCompValidation(),




      [COMPANY_FIELDS.PRICE_LIST.api_name]: {
        [COMPANY_FIELDS.PRICE_LIST.api_name]: Yup.array().of((() => {
          const {shape, edges} = requiredRowFieldsValidator.validateIfAnyInRowIsNotEmpty(
            [PRODUCT_FIELDS.NAME.api_name, PRODUCT_FIELDS.PRICE.api_name, PRODUCT_FIELDS.CODE.api_name],
            [{field: PRODUCT_FIELDS.NAME}, {field: PRODUCT_FIELDS.PRICE}, {field: PRODUCT_FIELDS.CODE}]
          );
          return Yup.object().shape(shape, edges);
        })())
      },

      [COMPANY_FIELDS.ADMIN_INFO.api_name]: {
        [COMPANY_FIELDS.ADMIN_INFO.api_name]:
          Yup.object().shape({
            [COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name]: Yup.string()
              .required(ERROR_MSG.REQUIRED_FIELD).nullable(),
            [COMPANY_FIELDS.ADMIN_NAME_LAST.api_name]: Yup.string()
              .required(ERROR_MSG.REQUIRED_FIELD).nullable(),
            [COMPANY_FIELDS.ADMIN_PHONE.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE).nullable()
          })
      },
      [COMPANY_FIELDS.EMAIL.api_name]: {
        [COMPANY_FIELDS.EMAIL.api_name]: Yup.string().validateEmail(ERROR_MSG.EMAIL_INVALID).nullable()
          .test(
            {
              test: value => {
                const validationParams = {
                  email: value,
                };
                if(this.props.id){
                  validationParams.id = this.props.id;
                }
                return Yup.string().checkIfCompanyExists(validationParams);
              },
              message: allConstants.ERROR_MSG.COMPANY_ALREADY_EXISTS
            }
          )
      },
      [COMPANY_FIELDS.QB_EMAIL.api_name]: {
        [COMPANY_FIELDS.QB_EMAIL.api_name]: Yup.string().validateEmailIfNotEmpty(ERROR_MSG.EMAIL_INVALID).nullable()
      },

      [COMPANY_FIELDS.NAME.api_name]: {
        [COMPANY_FIELDS.NAME.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD).nullable()
      },
      [COMPANY_FIELDS.ALLOWED_PERMITS.api_name]: {
        [COMPANY_FIELDS.ALLOWED_PERMITS.api_name]: Yup.array().required(ERROR_MSG.REQUIRED_FIELD).min(1, ERROR_MSG.REQUIRED_FIELD)
      },
      [COMPANY_FIELDS.ALLOWED_TESTS.api_name]: {
        [COMPANY_FIELDS.ALLOWED_TESTS.api_name]: Yup.array().required(ERROR_MSG.REQUIRED_FIELD).min(1, ERROR_MSG.REQUIRED_FIELD)
      },
      [COMPANY_FIELDS.LOCATION.api_name]: {
        [COMPANY_FIELDS.LOCATION.api_name]: Yup.array().required(ERROR_MSG.REQUIRED_FIELD).min(1, ERROR_MSG.REQUIRED_FIELD)
      },
      [USER_FIELDS.PASSWORD.api_name]: {
        [USER_FIELDS.PASSWORD.api_name]:  Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters').nullable(),
      },
      [USER_FIELDS.CONFIRM_PASSWORD.api_name]: {
        [USER_FIELDS.CONFIRM_PASSWORD.api_name]:  Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref(USER_FIELDS.PASSWORD.api_name)], 'Passwords must match').nullable()
      },


    };
  }

  init = ()=> {
    Yup.addMethod(Yup.string, 'validatePhoneNumber', function (errorMessage) {
      return this.test('phone_number', errorMessage, function (value) {
        const {path, createError} = this;
        return (
          validator.isValidPhoneNumber(value) ||
          createError({path, message: errorMessage})
        );
      });
    });
    Yup.addMethod(Yup.string, 'validateEmail', function (errorMessage) {
      return this.test('email', errorMessage, function (value) {
        const {path, createError} = this;
        return (
          validator.isValidEmail(value) ||
          createError({path, message: errorMessage})
        );
      });
    });

    Yup.addMethod(Yup.string, 'validateEmailIfNotEmpty', function (errorMessage) {
      return this.test('email', errorMessage, function (value) {
        const {path, createError} = this;
        return (
          validator.isValidEmail(value, true) ||
          createError({path, message: errorMessage})
        );
      });
    });

    Yup.addMethod(Yup.string, 'checkIfCompanyExists', validator.checkIfCompanyExists);
  }

  getValidationSchema = (tabs)=> {
    const requiredFields = (Object.values(tabs)).flat();
    let validationObject = {};
    requiredFields.forEach((field)=> {
      if(Utils.objectHasProperty(this.fieldsValidationRules, field)){
        validationObject = {...validationObject, ...this.fieldsValidationRules[field]};
      }
    });
    return Yup.object().shape(validationObject);
  }

}

export default CompanyValidator;

const ANALYTICS_TARGETS = {
  PERMIT_SUBMITTED: {display: 'Permit Submitted', api_request: 'permitSubmitedTotal', response_key: 'submited_total'},
  PERMIT_COMPLETED: {display: 'Permit Completed', api_request: 'permitTotalByStatus', response_key: 'total_count'},
  PERMIT_PENDING: {display: 'Permit Pending', api_request: 'permitTotalByStatus', response_key: 'total_count'},
  PERMIT_NEED_ATTENTION: {display: 'Need Attention', api_request: 'permitCurrByStatus', response_key: 'curr_count'},
  PERMITS_COMPLETED_YEARLY: {display: 'Permit Completed Jobs', api_request: 'permitCompletedYearly', response_key: 'total_count'},
  PERMITS_COMPLETED_MONTHLY: {display: 'Permit Completed Jobs', api_request: 'permitCompletedMonthly', response_key: 'total_count'},
  PERMIT_AVG_COST: {display: 'Average Permit Cost', api_request: 'avgCost', response_key: 'avg_cost'},
  HERS_SCHEDULED: {display: 'HERS Test Scheduled', api_request: 'hersTotalTest', response_key:'test_total'},
  HERS_SCHEDULED_MONTHLY: {display: 'HERS Test Scheduled', api_request: 'hersScheduledMonthly', response_key:'test_total'},
  HERS_PASSED: {display: 'HERS Test Passed', api_request: 'hersTotalByStatus', response_key: 'completed_total'},
  HERS_FAILED: {display: 'HERS Test Failed', api_request: 'hersTotalByStatus', response_key: 'failed_total'},
  HERS_CURRENT_FAILED: {display: 'HERS Test Failed', api_request: 'hersCurrByStatus', response_key: 'curr_count'},
  HERS_TEST_RESULTS: {display: 'HERS Test Results', api_request: 'hersFailedVsCompleted', response_key: 'status_count'},
  HERS_FAILED_MONTHLY: {display: 'HERS Test Failed', api_request: 'hersByStatusMonthly', response_key: 'total_count'},
  HERS_COMPLETED_MONTHLY: {display: 'HERS Test Completed', api_request: 'hersByStatusMonthly', response_key: 'total_count'},
  PERMIT_AVG_CITY_COST: {display: 'Average Permit Cost/City', api_request: 'avgCityCost', response_key: 'cost_count'},
};

const ANALYTICS_ENTITIES = {
  PERMITS:  {name: 'permits', label: 'permit', route: '/'},
  HERS: {name: 'hers', label: 'HERS test', route: '/hers'},
  FINANCIAL: {name: 'financial', label: 'financial', route: '/finance'},
};

export {ANALYTICS_TARGETS, ANALYTICS_ENTITIES};

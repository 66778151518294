/* eslint-disable */
import jsPDF from 'jspdf'
import {autoTable} from 'jspdf-autotable';
import {JOB_FIELDS, JOB_TYPES} from '../../../src/constants/allConstants';
import {DataPreparation} from './dataPreparation';


const printJob = ({r_data, new_window, src='portal'})=> {
  console.log('print called', r_data.Ordered_By)
  let dataPrep;
  if(r_data) {
    // console.log('dataPrep....')
    dataPrep = new DataPreparation(r_data, src);
  }
  console.log('dataPrep>>>>>>>>>>>', dataPrep)
  const jobType = r_data[JOB_FIELDS.JOB_TYPE.api_name]?.toLowerCase() ||  r_data[JOB_FIELDS.JOB_TYPE.crm_name]?.toLowerCase();

  const printer = new PrintPDF( {job_type: jobType, print_data: dataPrep.createPrintData(), autotable: autoTable, mailLabel: false});
  // console.log('printer initialized')
  if(new_window){
    printer.setNewWindowOutput();
  }
  // console.log('printer new window set')
  printer.setFullData(r_data);
  // console.log('printer set full data')
  printer.setDoc(jsPDF);
  // console.log('setPrint')
  printer.print();
};
const printMail = ()=> {
  let dataPrep;
  if(recordData) {
    dataPrep = new DataPreparation(recordData);
  }

  const printer = new PrintPDF( dataPrep.createMailLabel(), true);
  printer.printMail();
};
function getImgFromUrl(logo_url, callback) {
  // console.log('callback', callback)
  var img = new Image();
  img.src = logo_url;
  img.onload = function () {
    callback(img);
  };
}
function valueIsSpecified(val) {
  if(val === '$0' || val === '$0.00') return false;
  if(typeof val === 'number') {
    //return val !== 0;
    return true;
  }
  if(typeof val === 'boolean') {
    return val;
  }
  if(Array.isArray(val)) {
    return val.length !== 0;
  }
  if (typeof val === 'string') {
    if (!val || val.length === 0) {
      return false;
    }
    if(val.split(' ').filter((n)=> n!== 'null').length === 0 ){
      return false;
    }
  }
  return val && val !== null && val.length !== 0 && val?.toLowerCase() !== 'false' && val?.toLowerCase() !== 'no' && val?.toLowerCase() !== 'undefined';
}
function generatePDF(img){

  var doc = new jsPDF();
  doc.addImage(img, 'jpeg', 0, 0, 100, 50);

  doc.output('dataurlnewwindow');
}
const printImage = (imgUrl)=> {
  //console.log('get image', imgUrl)
  getImgFromUrl(imgUrl, (img)=>generatePDF(img));

};
class PrintPDF {
  constructor({print_data, mailLabel, job_type}) {
    this.job_type = job_type;
    this.autotable = autoTable;
    this.p_data = print_data;
    this.page_count = 1;
    this.options = {};
    this.output = 'dataurl';
    if(mailLabel) {
      options.orientation='landscape';
      options.format=[90, 30];
    }
    //this.doc = new (this.options);

    this.pageMargins = {
      left: 10,
      top: 10,
      bottom: 10,
      right: 10,
    };
    this.blockMargin = 5;
    this.fontSize = {
      header: '16',
      normal: '10',
      large: '12',
      larger: '16',
      footer: '8'
    };
    this.curr_block_Y = this.pageMargins.top;
    this.currentX = this.pageMargins.left;
    this.yStart = {
      '1': 70,
    };
  }

  setNewWindowOutput() {
    this.output = 'dataurlnewwindow';
  }

  setDoc(pdf_plugin) {
    this.doc = new pdf_plugin(this.options);
    this.pageHeight = this.doc.internal.pageSize.height || this.doc.internal.pageSize.getHeight();
    this.pageWidth = this.doc.internal.pageSize.width || this.doc.internal.pageSize.getWidth();
  }
  setFullData(data) {
    this.rec_data=data;
  }

  setCurrentY(y) {
    this.curr_block_Y = y;
    this.checkAndAddNewPage();
  }

  getBlockCoords(x,y,label_width, line_length) {
    return {
      x,y,value_x: x+label_width,
      line: {x1: x, y1: y+1, x2: x+line_length, y2:y+1}
    };
  }

  printMail(){
    this.doc.setFont(undefined, 'bold');
    this.doc.setFontSize(this.fontSize.large);
    this.doc.text(this.p_data[0]?.toUpperCase(),this.pageWidth / 2,5,{align: 'center'});
    this.doc.text(this.p_data[1]?.toUpperCase(),this.pageWidth / 2, 10,{align: 'center'});
    this.doc.text(this.p_data[2]?.toUpperCase(),this.pageWidth / 2, 15,{align: 'center'});
    this.doc.text(`${this.p_data[3]}, ${this.p_data[4]}`?.toUpperCase(),this.pageWidth / 2, 20,{align: 'center'});
    this.doc.setFont(undefined, 'italic');
    this.doc.setFontSize(this.fontSize.footer);
    this.doc.text(`#${this.p_data.pop()}`?.toUpperCase(),this.pageWidth / 2, 27, {align: 'center'});

    this.doc.output('dataurlnewwindow');
  }
  printGeneralBlock() {
  console.group('generalBlock');
  console.log('Printing general block')
    if(!this.p_data.generalBlock) return [];
  const printedGeneral = this.printBlock(this.p_data.generalBlock, this.getBlockCoords(this.currentX, this.curr_block_Y, 45, 70));
  // this.setCurrentY(this.curr_block_Y+ this.getBlockPrintedLength(printedGeneral)*5 + 5);
  console.log("general block printed")
  console.groupEnd('generalBlock');
  return printedGeneral
}
  printTopSection() {
    console.group('topSection')
    console.log('>>>>Printing Top section')
    this.doc.setFont(undefined, 'normal');
    let head_x_start = 10;
    if (this.p_data.headerBlock.hers_required || this.p_data.headerBlock.euc_required) {
      this.doc.setFontSize(this.fontSize.large);
      //this.doc.text('TEST:', head_x_start, this.curr_block_Y)
      this.doc.setFont(undefined, 'bold');
      this.doc.text('TEST:', head_x_start, this.curr_block_Y);
      this.doc.setFont(undefined, 'normal');
      if(this.p_data.headerBlock.euc_required)  {
        this.doc.text('EUC', head_x_start + 30, this.curr_block_Y);
      }
      this.doc.text('HERS', head_x_start + 15, this.p_data.headerBlock.euc_required ? this.curr_block_Y : this.curr_block_Y);
    }
    this.doc.setFont(undefined, 'bold');
    this.doc.setFontSize(this.fontSize.large);
    this.doc.text(`${this.p_data.headerBlock.type.label.toUpperCase()}: `, this.currentX+50  , this.curr_block_Y);
    this.doc.setFont(undefined, 'normal');
    this.doc.text(`${this.p_data.headerBlock.type.value}`, this.currentX+50 + 15, this.curr_block_Y);
    this.doc.setFont(undefined, 'bold');
    this.doc.text(`${this.p_data.headerBlock.city.label}: `, 2 * this.pageWidth / 3 - 15 + head_x_start, this.curr_block_Y);
    this.doc.setFont(undefined, 'normal');
    let top_rect_height = 10;
    let rect_y_coord = this.curr_block_Y-5;
    //this.doc.rect(8, this.curr_block_Y-5, this.pageWidth-20, 13);
    if(this.p_data.headerBlock.city.value.length > 23) {
      this.doc.text(`${this.p_data.headerBlock.city.value.slice(0, 23)}`, 2 * this.pageWidth / 3 + head_x_start - 2, this.curr_block_Y);
      this.doc.text(`${this.p_data.headerBlock.city.value.slice(23)}`, 2 * this.pageWidth / 3 + head_x_start - 2, this.curr_block_Y+5);
    } else{
      this.doc.text(`${this.p_data.headerBlock.city.value}`, 2 * this.pageWidth / 3 + head_x_start - 2, this.curr_block_Y);
    }

    if (this.p_data.headerBlock.city.notes) {
      this.doc.setFontSize(this.fontSize.normal);
      this.doc.setFont(undefined, 'italic');
      // this.doc.text(`${this.p_data.headerBlock.city.notes}`, 2 * this.pageWidth / 3 + head_x_start-2, this.curr_block_Y + 3)
      this.doc.text(`${this.p_data.headerBlock.city.notes}`, this.currentX, this.curr_block_Y + 7);
      this.doc.setFontSize(this.fontSize.large);
      this.doc.setFont(undefined, 'normal');
      this.setCurrentY(this.curr_block_Y+5);
      top_rect_height = top_rect_height + 3;
    }
    this.doc.rect(8, rect_y_coord, this.pageWidth-20, top_rect_height);
    this.setCurrentY(this.curr_block_Y+13);
    console.log('Top section printed <<<<<')
    console.groupEnd('topSection')
  }

  printCustomerInfo(){
    console.group('customerInfo')
    console.log('>>>> Print Customer Info')
    const hasCustomerInfo = this.p_data.hasOwnProperty('customer') && this.p_data.customer;
    let rectHeight = 0;
    if(hasCustomerInfo) {
      this.doc.setFontSize(this.fontSize.normal);
      this.doc.setFont(undefined, 'bold');

      let block_Y =  this.curr_block_Y;
      if(this.p_data.customer.name) {
        const splitHomeOwnerName = this.doc.splitTextToSize(`${this.p_data.customer.name?.toUpperCase()}`, 50);
        this.doc.text(splitHomeOwnerName, 10,  this.curr_block_Y, {maxWidth: 60});
        block_Y = block_Y+splitHomeOwnerName.length*3 + 5;
        rectHeight = rectHeight + splitHomeOwnerName.length*3 + 5;
      }
      this.doc.setFont(undefined, 'normal');
      let address;
      if(this.p_data.customer.address && this.p_data.customer.address.length !== 0) {
        if(typeof this.p_data.customer.address === 'string') {
          address = this.p_data.customer.address;
        }
        const splitTextToSize = this.doc.splitTextToSize(`${address}`, 50);
        this.doc.text(splitTextToSize, 10, block_Y);
        block_Y = block_Y+splitTextToSize.length*3 + 5;
        rectHeight = rectHeight + splitTextToSize.length*3
        // if(address.length > 20) {
        //   rectHeight = rectHeight+ 3;
        //   block_Y = block_Y+5;
        // }
      }
      if(this.p_data.customer.phone) {
        this.doc.text(`${this.p_data.customer.phone}`, 10,  block_Y);
        block_Y = block_Y+5;
        rectHeight = rectHeight+3;
      }
      if(this.p_data.customer.altPhone) {
        this.doc.text(`alt: ${this.p_data.customer.altPhone}`, 10,  block_Y);
        block_Y = block_Y+5;
        rectHeight = rectHeight+6;
      }
      if(this.p_data.customer.email) {
        this.doc.text(`${this.p_data.customer.email}`, 10,  block_Y);
        rectHeight = rectHeight+3;
      }

      if(rectHeight !== 0) {
        rectHeight = rectHeight +2
        rectHeight = rectHeight + 10
        this.doc.rect(8, this.curr_block_Y-5, 60, rectHeight);
      }

      console.log('Customer Info printed <<<<<')
      console.groupEnd('customerInfo')
      return {hasCustomerInfo, rectHeight};
    }

    console.log('Customer Info printed <<<<<')
    console.groupEnd('customerInfo')
    return {hasCustomerInfo: false, rectHeight: 0};

  }

  printNotesBlock(){
    console.group('notes')
    console.log('>>>>>>Printing notes')
    if(this.p_data.init_note && this.p_data.init_note.length !== 0) {
      this.curr_block_Y = this.curr_block_Y+ 5;
      this.printSectionName('Note', {x: this.currentX, y: this.curr_block_Y});
      this.doc.setFont(undefined, 'bold');
      this.curr_block_Y = this.curr_block_Y+ 5;
      const note_line_count = this.printFullWidthMultiLine(this.p_data.init_note);

      this.setCurrentY(this.curr_block_Y + note_line_count * 5 +10+5);
    }
    console.log('Notes array', this.p_data.notes)

    if(this.p_data.notes.length !== 0) {
      this.doc.line(10, this.curr_block_Y, this.pageWidth-10, this.curr_block_Y);
      this.setCurrentY(this.curr_block_Y + 5);
      this.doc.setFontSize(this.fontSize.large);
      this.doc.setFont(undefined, 'bold');
      this.doc.text('NOTES:', 10, this.curr_block_Y);
      this.setCurrentY(this.curr_block_Y + 5);
      this.doc.setFontSize(this.fontSize.normal);
      this.doc.setFont(undefined, 'normal');
      this.printNotes(this.p_data.notes, this.getBlockCoords(10, this.curr_block_Y, 0, 50));
      this.doc.line(10, this.curr_block_Y, this.pageWidth-10, this.curr_block_Y);

      console.log('Notes printed<<<<')
      console.groupEnd('notes')
    }
  }
  printNewNotesBlock(data, header){
    console.group('notes')
    console.log('>>>>>>Printing notes ', header, data)

    if(data && data.length !== 0) {
      this.doc.line(10, this.curr_block_Y, this.pageWidth-10, this.curr_block_Y);
      this.setCurrentY(this.curr_block_Y + 5);
      this.doc.setFontSize(this.fontSize.large);
      this.doc.setFont(undefined, 'bold');
      this.doc.text(header.toUpperCase(), 10, this.curr_block_Y);
      this.setCurrentY(this.curr_block_Y + 5);
      this.doc.setFontSize(this.fontSize.normal);
      this.doc.setFont(undefined, 'normal');
      this.printNotes(data, this.getBlockCoords(10, this.curr_block_Y, 0, 50));
      this.doc.line(10, this.curr_block_Y, this.pageWidth-10, this.curr_block_Y);

      console.log('Notes printed<<<<' + header)
      console.groupEnd('notes')
    }
  }

  print() {
    console.log('print!!!!!!!!!!!! finally')
    console.log(JSON.stringify(this.p_data));
    //Print header
    this.printHeader();
    this.printFooter();
    this.printTopSection();
    const {hasCustomerInfo, rectHeight} = this.printCustomerInfo()
    let printedDatesLength = 0;
    let printedValuesLength = 0;
    // Print valuesBlock
    if(this.p_data.valueBlock) {
      console.group('valuesBlock');
      console.log('Printing Values')
      const printedValues = this.printBlock(this.p_data.valueBlock, this.getBlockCoords(hasCustomerInfo && rectHeight !== 0 ? (this.pageWidth / 3) : this.currentX, this.curr_block_Y, 35, 60));
      printedValuesLength = printedValues.length
      console.log("values block printed")
      console.groupEnd('valuesBlock');
    }
    // Print datesBlock
    if(this.p_data.datesBlock) {
      console.group('datesBlock');
      console.log('Printing Dates')
      const printedDates = this.printBlock(this.p_data.datesBlock, this.getBlockCoords(hasCustomerInfo && rectHeight !== 0 ? (this.pageWidth / 3) * 2 : printedValuesLength === 0 ? (this.pageWidth / 3) : this.pageWidth / 3, this.curr_block_Y, 25, 50));
      printedDatesLength = printedDates.length
      console.log("dates block printed")
      console.groupEnd('datesBlock');
    }

    if(printedDatesLength === 0 && printedValuesLength ===0) {
      this.currentX = this.pageWidth/3
      const printedGeneral = this.printGeneralBlock()
      const printedLength = this.getBlockPrintedLength(printedGeneral)
      this.currentX = this.pageMargins.left;
      this.setCurrentY(this.curr_block_Y+ Math.max(((printedLength+1) * 5 ), rectHeight) + 5)

    } else {
      let yIndent = Math.max(rectHeight+5, printedValuesLength*5);
      yIndent = Math.max(yIndent, printedDatesLength*5)
      // console.log('Y', yIndent, this.curr_block_Y,)
      this.setCurrentY(this.curr_block_Y+yIndent)
      const printedGeneral = this.printGeneralBlock()
      const printedLength = this.getBlockPrintedLength(printedGeneral)
      this.setCurrentY(this.curr_block_Y+(printedLength+1)*5+5)
    }
    if(this.p_data.com_table && this.p_data.com_table.length !== 0) {
      let currRow = [];
      const tableBody = [];
      this.p_data.com_table.forEach((row, index)=> {
        currRow = currRow.concat([row.name, row.count]);
        if(index !== 0 && index%2 !== 0 && index !==  this.p_data.com_table.length - 1) {
          tableBody.push([...currRow]);
          currRow = [];
        } else if(index ===  this.p_data.com_table.length - 1) {
          currRow.push(['','']);
          tableBody.push([...currRow]);
        }
      });
      const eqTableConfig = {
        theme: 'grid',
        styles: {textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center', lineColor: [0, 0, 0]},
        headStyles: {halign: 'center', fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.3},
        columnStyles: {
          0: {halign: 'left', cellWidth: 50},
          1: {halign: 'center', cellWidth: 20},
          2: {halign: 'left', cellWidth: 50},
          3: {halign: 'center', cellWidth: 20},
        },
        startY: this.curr_block_Y,
        startX: (this.pageWidth - 140)/2,
        head: [['Equipment', 'Qty', 'Equipment', 'Qty']],
        body: tableBody,
      };
      this.printTable(eqTableConfig);
      this.setCurrentY(this.curr_block_Y + ((tableBody.length+1)*8) + 5);
    }
    if(this.p_data?.commercialScope) {
      this.curr_block_Y = this.curr_block_Y+ 5;
      console.log('printing commercial')
      this.printSectionName('Commercial Scope', {x: this.currentX, y: this.curr_block_Y}, true);
      this.curr_block_Y = this.curr_block_Y+ 5;
      const commercialScopeLineCount = this.printFullWidthMultiLine(this.p_data.commercialScope);
      if(commercialScopeLineCount && commercialScopeLineCount !== 0) {
        this.setCurrentY(this.curr_block_Y + commercialScopeLineCount * 4 + 5);
      }

    }
    switch(this.job_type) {
    case 'plumbing':{
      if(this.p_data.waterSoftnerBlock && this.p_data.waterSoftnerBlock.length !== 0) {

        this.p_data.waterSoftnerBlock.forEach((row, index)=> {
          let currentYIndent = 0
          row.forEach((piece, idx)=> {
            const name = piece.name;
            if(piece.fields && piece.fields.length !== 0) {
              this.printSectionName(name, {x: this.currentX+ idx*this.pageWidth/2, y:this.curr_block_Y}, true);
              const printedBlock = this.printBlock(piece.fields,  this.getBlockCoords(this.currentX+ idx*this.pageWidth/2,this.curr_block_Y+5, 45, 80));
              currentYIndent = Math.max(currentYIndent, this.getBlockPrintedLength(printedBlock))
            }
          });
          this.setCurrentY(this.curr_block_Y + currentYIndent*5+5+5);
        });
      }
      if(this.p_data?.waterSoftenerScope && this.p_data.waterSoftenerScope.length !== 0) {
        this.curr_block_Y = this.curr_block_Y+ 5;
        this.printSectionName('Water softener scope', {x: this.currentX, y: this.curr_block_Y}, true);
        this.curr_block_Y = this.curr_block_Y+ 5;
        const wsScope = this.printFullWidthMultiLine(this.p_data.waterSoftenerScope);
        this.setCurrentY(this.curr_block_Y + wsScope * 5 +5);
      }
      let printedRepipe
      if(this.p_data.repipeBlock && this.p_data.repipeBlock.length !== 0) {
        printedRepipe = this.printBlock(this.p_data.repipeBlock, this.getBlockCoords(this.currentX, this.curr_block_Y, 50, 70));
        this.setCurrentY(this.curr_block_Y + this.getBlockPrintedLength(printedRepipe)*5 +7+5)
      }
      if(this.p_data.plumbingTable && this.p_data.plumbingTable.length !== 0) {
        this.printSectionName('Repipe fixtures', {x: this.currentX, y: this.curr_block_Y}, true);
        this.setCurrentY(this.curr_block_Y+3);
        let currRow = [];
        const tableBody = [];
        this.p_data.plumbingTable.forEach((row, index)=> {
          currRow = currRow.concat([row.name, row.count]);
          if(index !== 0 && (index+1)%5 === 0 && index !==  this.p_data.plumbingTable.length - 1) {
            tableBody.push([...currRow]);
            currRow = [];
          } else if(index ===  this.p_data.plumbingTable.length - 1) {
            tableBody.push([...currRow]);
          }

        });
        const plumTableConfig = {
          theme: 'grid',
          styles: {textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center', lineColor: [0, 0, 0]},
          columnStyles: {
            0: {halign: 'left', cellWidth: 27},
            1: {halign: 'center', cellWidth: 10},
            2: {halign: 'left', cellWidth: 27},
            3: {halign: 'center', cellWidth: 10},
            4: {halign: 'left', cellWidth: 27},
            5: {halign: 'center', cellWidth: 10},
            6: {halign: 'left', cellWidth: 27},
            7: {halign: 'center', cellWidth: 10},
            8: {halign: 'left', cellWidth: 27},
            9: {halign: 'center', cellWidth: 10},
          },
          startY: this.curr_block_Y,
          margin: {
            left: 10,
          },
          // head: [['Equipment', 'Qty', 'Equipment', 'Qty']],
          body: tableBody,
        };
        this.printTable(plumTableConfig);
        this.currentX = this.pageMargins.left;
        this.setCurrentY(this.curr_block_Y + tableBody.length*8 +this.blockMargin + 5);
      }
      if(this.p_data?.repipeScope && this.p_data.repipeScope.length !== 0) {
        this.printSectionName('Repipe scope', {x: this.currentX, y: this.curr_block_Y}, true);
        this.curr_block_Y = this.curr_block_Y+ 5;
        const repipeScopeLines = this.printFullWidthMultiLine(this.p_data.repipeScope)
        this.setCurrentY(this.curr_block_Y+ repipeScopeLines*5+7+5);
      }
      // this.doc.line(10, this.curr_block_Y, this.pageWidth-10, this.curr_block_Y)
      if(this.p_data.equipmentBlock && this.p_data.equipmentBlock.length !== 0) {
        this.printSectionName('repairs', {x: this.currentX, y: this.curr_block_Y},true);

        this.setCurrentY(this.curr_block_Y + 7);
        this.printEquipment()
      }

      let printedReplace = {};
      if(this.p_data.replaceBlock && this.p_data.replaceBlock.length !== 0) {
        this.printSectionName('replace',{x: this.currentX, y:this.curr_block_Y}, true)
        printedReplace= this.printBlock(this.p_data.replaceBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 70, 80));
      }

      if(this.p_data.sanitation && this.p_data.sanitation.length !== 0) {
        this.printSectionName('sanitation',{x: printedReplace ? this.pageWidth/2 : this.currentX, y:this.curr_block_Y}, true)
        const printedSanitation = this.printBlock(this.p_data.sanitation, this.getBlockCoords(printedReplace ? this.pageWidth/2 : this.currentX, this.curr_block_Y+5, 40, 100));
        this.setCurrentY(this.curr_block_Y + Math.max(this.getBlockPrintedLength(printedSanitation), this.getBlockPrintedLength(printedReplace))*5+7+5);
      } else if(Object.keys(printedReplace).length !== 0) {
        this.setCurrentY(this.curr_block_Y + this.getBlockPrintedLength(printedReplace)*5+7+5);
      }

      break;
    }
    case 'mechanical (hvac)':
    case 'testing only': {
      this.printEquipment();
      this.currentX = this.pageMargins.left;
      let info_block_line_count = 0;
      console.log('info line count', info_block_line_count, this.curr_block_Y)

      if(this.p_data.mechElectricalBlock&& this.p_data.mechElectricalBlock.length !== 0) {
        this.printSectionName('electrical', {x: this.currentX, y: this.curr_block_Y}, true);
        const printedMechEl = this.printBlock(this.p_data.mechElectricalBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 46, 50));
        this.currentX = this.currentX + this.pageWidth/3;
        info_block_line_count = Math.max(info_block_line_count, printedMechEl.length);
      }
      if(this.p_data.mechPlumbingBlock && this.p_data.mechPlumbingBlock.length !== 0) {
        this.printSectionName('plumbing', {x: this.currentX, y: this.curr_block_Y}, true);
        const printedMechPl = this.printBlock(this.p_data.mechPlumbingBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 42, 50));
        this.currentX = this.currentX + this.pageWidth/3;
        info_block_line_count = Math.max(info_block_line_count, printedMechPl.length);
      }
      let printedRadiant = [];
      let printedBlown = [];
      let prewY = this.curr_block_Y;

      if(this.p_data.mechRadiantBlock && this.p_data.mechRadiantBlock.length !== 0) {
        this.printSectionName('Radiant Barrier', {x: this.currentX, y: this.curr_block_Y}, true);
        printedRadiant = this.printBlock(this.p_data.mechRadiantBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 40, 50));

        this.setCurrentY(this.curr_block_Y + this.getBlockPrintedLength(printedRadiant)*5+10)
      }
      if(this.p_data.mechBlownInBlock && this.p_data.mechBlownInBlock.length !== 0) {
        this.printSectionName('blown in', {x: this.currentX, y: this.curr_block_Y}, true);
        printedBlown =  this.printBlock(this.p_data.mechBlownInBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 40, 50));
      }
      console.log('info_block_line_count', info_block_line_count)
      this.currentX = this.pageMargins.left
      let printedInsulation = printedRadiant.length + printedBlown.length
      info_block_line_count = Math.max(info_block_line_count, printedInsulation);
      let headerExtraSpace = (printedInsulation.length !==0 && printedRadiant.length !== 0) ? 5 : 0
      this.setCurrentY(prewY+ info_block_line_count*5+5+ headerExtraSpace);


      break;
    }
    case 'reroof': {
      let combinedGeneral = [];
      if(this.p_data.reroofGeneral && this.p_data.reroofGeneral.length !== 0) {
        combinedGeneral = [...combinedGeneral, ...this.p_data.reroofGeneral]
       // printed_8 = this.printBlock(this.p_data.reroofGeneral, this.getBlockCoords(this.currentX, this.curr_block_Y, 50, 70));
      }

      if(this.p_data.roofScopeBlock && this.p_data.roofScopeBlock.length !== 0) {
        combinedGeneral = [...combinedGeneral, ...this.p_data.roofScopeBlock]
      }
      let printedCombinedGeneral;
      let printedRoofMaterialBlock;
      if(combinedGeneral.length !== 0) {
        this.printSectionName('scope of work',
          {x: this.currentX, y: this.curr_block_Y}, true);
        printedCombinedGeneral = this.printBlock(combinedGeneral, this.getBlockCoords(this.currentX, this.curr_block_Y+7, 50, 70));
        this.currentX = this.pageWidth/2
      }

      if(this.p_data.roofMaterialBlock && this.p_data.roofMaterialBlock.length !== 0) {
        this.printSectionName('roofing material',
          {x: this.currentX, y: this.curr_block_Y}, true);
        printedRoofMaterialBlock = this.printBlock(this.p_data.roofMaterialBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+7, 50, 70));
      }
      this.setCurrentY(this.curr_block_Y + Math.max(this.getBlockPrintedLength(printedCombinedGeneral), this.getBlockPrintedLength(printedRoofMaterialBlock))*5+5+7)
      this.currentX = this.pageMargins.left;
      if(this.p_data.roofScopeText && this.p_data.roofScopeText.length !== 0) {
        this.printSectionName('Scope', {x:  this.currentX, y: this.curr_block_Y}, true);
        this.curr_block_Y = this.curr_block_Y+ 5;
        const printedTextLength = this.printFullWidthMultiLine(this.p_data.roofScopeText);
        console.log('printedTextLength', printedTextLength)
        this.setCurrentY(this.curr_block_Y + printedTextLength * 5+5)
      }

      this.currentX = this.pageMargins.left;
      let printedRoofBuildingInfoBlock;
      let printedWasteRemovalBlock;
      if(this.p_data.roofBuildingInfoBlock  && this.p_data.roofBuildingInfoBlock.length !== 0) {
        this.printSectionName('building',
          {x: this.currentX, y: this.curr_block_Y}, true);
        printedRoofBuildingInfoBlock = this.printBlock(this.p_data.roofBuildingInfoBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+7, 50, 70));
      }
      if(this.p_data?.wasteRemovalBlock && this.p_data.wasteRemovalBlock.length !== 0) {
        this.printSectionName('waste removal', {x:  printedRoofBuildingInfoBlock && this.getBlockPrintedLength(printedRoofBuildingInfoBlock).length !== 0 ? this.pageWidth/2: this.pageMargins.left, y: this.curr_block_Y}, true);
        printedWasteRemovalBlock =  this.printBlock(this.p_data.wasteRemovalBlock, this.getBlockCoords(this.getBlockPrintedLength(this.p_data.roofBuildingInfoBlock) !== 0 ? this.pageWidth/2: this.pageMargins.left, this.curr_block_Y+7, 45, 70));
      }
      this.setCurrentY(this.curr_block_Y+ Math.max(this.getBlockPrintedLength(printedWasteRemovalBlock), this.getBlockPrintedLength(printedRoofBuildingInfoBlock))*5 +5+7);
      break;
    }
    case 'electrical':{
      if(this.p_data.electricalEquipment && this.p_data.electricalEquipment.length !== 0) {
        for (let i = 0; i<this.p_data.electricalEquipment.length; i++) {
          let row = [...this.p_data.electricalEquipment[i]];
          let max_line
          row.forEach((block, index)=> {
            block.fields = block.fields.filter((f)=> f.label !==JOB_FIELDS.GENERAL.SCOPE.display_label)
            const header = block.name
            this.printSectionName(header, {x: 10 + (index)*70, y: this.curr_block_Y}, true);
            const printed = this.printBlock(block.fields, this.getBlockCoords(10 + (index)*70, this.curr_block_Y+5, 35, 50));
            if(index === 0 || index % 3 === 0) {
              max_line = this.getBlockPrintedLength(printed);
            } else {
              max_line = Math.max(max_line, this.getBlockPrintedLength(printed));
            }
          });
          this.setCurrentY(this.curr_block_Y + max_line*5+5+5);

        }
      }
      if(this.p_data.electricalScope && this.p_data.electricalScope.length !== 0) {
        this.printSectionName('SCOPES', this.getBlockCoords(this.currentX, this.curr_block_Y, 30, 30), true);
        this.setCurrentY(this.curr_block_Y + 5);
        const tableBody = [];
        this.p_data.electricalScope.forEach((row, index)=> {
          tableBody.push([row.name, row.value]);
        });
        let tableMeta;
        const eqTableConfig = {
          theme: 'grid',
          styles: {textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center', lineColor: [0, 0, 0]},
          headStyles: {halign: 'center', fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.3},
          columnStyles: {
            0: {halign: 'left', cellWidth: 40},
            1: {halign: 'left', cellWidth: this.pageWidth -40 -20},
          },
          startY: this.curr_block_Y,
          startX: this.currentX,
          body: tableBody,
          didParseCell: (data) => {
            if (!tableMeta) {
              tableMeta = data.table;
            }
          }
        };
        this.printTable(eqTableConfig);

        this.setCurrentY(tableMeta.finalY + 5);
      }
      break;
    }
    case 'solar': {
      if(this.p_data.solarBlock && this.p_data.solarBlock.length !== 0) {
        this.printSectionName('Solar P.V.', this.getBlockCoords(this.currentX, this.curr_block_Y, 30, 30), true);
        this.setCurrentY(this.curr_block_Y  + 5);
        this.printBlock(this.p_data.solarBlock, this.getBlockCoords(this.currentX, this.curr_block_Y, 40, 60));
        this.setCurrentY(this.curr_block_Y + this.getBlockPrintedLength(this.p_data.solarBlock)* 5 + 5);
      }
      break;
    }
    case 'temporary power': {
      this.printEquipment();
      let printedBuilderInfo;
      let printedMiscInfo;
      let printedAdditionalEquipment;
      let moreEquipmentLineCount;
      if(this.p_data.builderInfoBlock && this.p_data.builderInfoBlock.length !== 0) {
        this.printSectionName('building Info', {x: this.currentX, y: this.curr_block_Y}, true);
        printedBuilderInfo = this.printBlock(this.p_data.builderInfoBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 40), false, true);
        this.currentX = this.pageWidth/2
      }

      if(this.p_data.miscInfoBlock && this.p_data.miscInfoBlock.length !== 0) {
        this.printSectionName('misc Info', {x: this.currentX, y: this.curr_block_Y}, true);
        printedMiscInfo = this.printBlock(this.p_data.miscInfoBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 40, 70), false, true);
        this.currentX = this.pageMargins.left
      }
      let currentMax = Math.max(this.getBlockPrintedLength(printedBuilderInfo), this.getBlockPrintedLength(printedMiscInfo))
      this.setCurrentY(this.curr_block_Y + currentMax*5+5+10)

      if(this.p_data.additionalEquipmentBlock && this.p_data.additionalEquipmentBlock.length !== 0) {
        this.printSectionName('equipment', {x: this.currentX, y: this.curr_block_Y}, true);
        printedAdditionalEquipment = this.printBlock(this.p_data.additionalEquipmentBlock, this.getBlockCoords(this.currentX, this.curr_block_Y+5, 50, 70), false, true);
      }
      if(this.p_data.moreEquipment) {
        //this.curr_block_Y = this.curr_block_Y+ 5;
        this.currentX = this.pageWidth/3
        this.printSectionName('Additional Equipment', {x:  this.currentX, y: this.curr_block_Y}, true);
        this.curr_block_Y = this.curr_block_Y+ 5;
        moreEquipmentLineCount = this.printFullWidthMultiLine(this.p_data.moreEquipment, 2/3);
        this.currentX = this.pageMargins.left
      }
      this.setCurrentY(this.curr_block_Y + Math.max(moreEquipmentLineCount||0 * 3, this.getBlockPrintedLength(printedAdditionalEquipment) * 2) +5);
      break;
    }
    default: {
      break;
    }
    }

    if(this.p_data.general_scope) {
      this.curr_block_Y = this.curr_block_Y+ 5;
      this.printSectionName('Scope', {x: this.currentX, y: this.curr_block_Y}, true);
      this.curr_block_Y = this.curr_block_Y+ 5;
      const scopeLineCount = this.printFullWidthMultiLine(this.p_data.general_scope);
      this.setCurrentY(this.curr_block_Y + (scopeLineCount * 4) + 5);
    }
    this.printMoreOptions()
    this.printNotesBlock()
    if(this.p_data.permit_notes) {
      this.printNewNotesBlock(this.p_data.permit_notes, 'permit notes')
    }
    if(this.p_data.hers_notes) {
      this.printNewNotesBlock(this.p_data.hers_notes, 'hers notes')
    }
    this.checkAndAddNewPage(55);
    this.setCurrentY(this.curr_block_Y + 5);
    this.printPermitInfoTable();
    this.doc.output(this.output);

  }
  printPermitInfoTable() {
    if(this.job_type === JOB_TYPES.TESTING_ONLY.api_name) return;
    let tableMeta = null;
    const autotableConfig = {
      theme: 'grid',
      styles: {textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center', lineColor: [0, 0, 0]},
      headStyles: {halign: 'center', fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.3},
      columnStyles: {
        0: {halign: 'right', cellWidth: 30},
        1: {halign: 'center', cellWidth: 30},
        2: {halign: 'center', cellWidth: 40},
        3: {halign: 'center', cellWidth: 40},
        4: {halign: 'center', cellWidth: 40},
      },

      //startY: this.page_count === 1 ? this.pageHeight - 70 : this.curr_block_Y,
      startY:  this.curr_block_Y,
      startX: 10,
      head: [['', 'Check #', 'Doc #', 'Amount, $', 'Date']],
      body: this.p_data.permitTable,
      didParseCell: (data) => {
        data.cell.styles.valign = 'middle';
        if (data.column.index === 0 && data.row.index === 4 ||
                    data.column.index === 0 && data.row.index === 3 || data.column.index === 2 && data.row.index === 3) {
          data.cell.styles.valign = 'middle';
          data.cell.styles.halign = 'right';
        }
        if ((data.column.index === 4 && data.row.index === 3) || data.column.index === 4 && data.row.index === 4) {
          data.cell.colSpan = 2;
        }
        if (data.column.index === 0 && data.row.index === 4) {
          data.cell.colSpan = 3;
        }
      },
      didDrawPage: (d) => console.log('TABLE:::', d.cursor.y),
    };
    this.printTable(autotableConfig);
  }
  printFullWidthMultiLine(text, size = 1) {
    const splitTextToSize = this.doc.splitTextToSize(text.toUpperCase(), 180*size);
    const line_count = splitTextToSize.length;
    this.doc.text(splitTextToSize, this.currentX, this.curr_block_Y);
    // if(size === 1) {
    //   this.curr_block_Y = this.curr_block_Y + 5*line_count + 5;
    // }
    return line_count;
  }
  getMaxRowLines(arr) {
    if(arr.length === 0) return 0;
    let max = arr[0].length;
    arr.forEach((subarr)=> {
      if(subarr.length > max) {
        max = subarr.length;
      }
    });
    return max;
  }
  printTable(config){
    if(this.autotable) {
      this.doc[this.autotable(config)];
    } else {
      this.doc.autoTable(config);
    }
  }

  printHeader() {
    console.group('header')
    console.log('>>>>print header', this.p_data)
    const name_text = `${this.p_data.company?.toUpperCase()} - #${this.p_data.job_name}${this.p_data.duplicateOf ? this.p_data.duplicateOf : ''}`;
    this.doc.setFont(undefined, 'bold');
    this.doc.setFontSize(this.fontSize.header);
    if(name_text.length <=50) {
      this.doc.text(name_text, this.pageWidth / 2, 10, {align: 'center'});
      this.setCurrentY(15);
    } else {
      const text_splitted = name_text.split('(');
      this.doc.text(text_splitted[0], this.pageWidth / 2, this.curr_block_Y, {align: 'center'});
      this.setCurrentY(20);
      this.doc.text(`(${text_splitted[1]}`, this.pageWidth / 2, this.curr_block_Y, {align: 'center'});
      this.setCurrentY(25);
    }

    this.doc.setFont(undefined, 'italic');
    this.doc.setFontSize(this.fontSize.footer);
    this.doc.text(`(${this.p_data.headerBlock.created_by.label}: ${this.p_data.headerBlock.created_by.value.toUpperCase()})`, this.pageWidth / 2, this.curr_block_Y, {align: 'center'});
    this.setCurrentY(this.curr_block_Y+10);
    this.doc.setFontSize(this.fontSize.normal);
    console.log('Header printed<<<<')
    console.groupEnd('header')

  }
  printFooter() {
    console.log('>>>>Printing Footer')
    this.doc.setFont(undefined, 'italic');
    this.doc.setFontSize(this.fontSize.footer);
    this.doc.text(`Job# ${this.p_data.job_name}; Page ${this.page_count}`, this.pageWidth-10, this.pageHeight-10, {align: 'right'});
    this.doc.setFontSize(this.fontSize.normal);
    this.doc.setFont(undefined, 'normal');
    console.log('Footer printed<<<<')
  }
  printMoreOptions(){
    if(this.p_data.more_options && this.p_data.more_options.length !== 0) {
      this.curr_block_Y = this.curr_block_Y+ 5
      this.printSectionName("more options", {x: this.currentX, y: this.curr_block_Y}, true)
      this.curr_block_Y = this.curr_block_Y+ 5
      const options_line_count  = this.printFullWidthMultiLine(this.p_data.more_options)
      this.setCurrentY(this.curr_block_Y + options_line_count * 5 +5)
    }
  }
  printEquipment(){
    console.group('Equpment Block')
    console.log('>>>>print Equpment Block', this.p_data.equipmentBlock,this.curr_block_Y )
    if(this.p_data.equipmentBlock && this.p_data.equipmentBlock.length !== 0) {
      for (let i = 0; i<this.p_data.equipmentBlock.length; i++) {

        let row = [...this.p_data.equipmentBlock[i]];
        let printedLength = 0
        row.forEach((block, index)=> {
          const header = block.name;
          this.printSectionName(header, {x: 10 + (index)*70, y: this.curr_block_Y}, true);
          const printedBlock = this.printBlock(block.fields, this.getBlockCoords(10 + (index)*70, this.curr_block_Y+5, 33, 50));
          printedLength = Math.max(printedLength, this.getBlockPrintedLength(printedBlock)*5)
        });
        this.setCurrentY(this.curr_block_Y + printedLength+5+7);

      }
    }
    console.log('Equpment Block printed<<<<')
    console.groupEnd('Equpment Block')
  }
  checkAndAddNewPage(limit) {
    const extraLimit = limit || 0
    if (this.curr_block_Y >= this.pageHeight - this.pageMargins.top - this.pageMargins.bottom -extraLimit)

    {
      this.doc.addPage();
      this.printHeader();
      //this.curr_block_Y = this.curr_block_Y;
      this.page_count++;
      this.printFooter();
    }
  }
  printRecord(record, coords) {
    let line_height = 5;
    if(record.label) {
      this.doc.setFont(undefined, 'bold');
      this.doc.text(`${record.label?.toUpperCase()}: `,coords.x, coords.y);

      this.doc.setFont(undefined, 'normal');
    }
    this.doc.text(`${record.value?.toUpperCase()}`,coords.value_x,coords.y);
  }

  printSectionName(text, coords, bold= false){
    if(!text) return;
    this.doc.setFontSize(this.fontSize.large);
    if(bold) this.doc.setFont(undefined, 'bold');
    this.doc.text(`${text?.toUpperCase()}`, coords.x, coords.y);
    this.doc.setFontSize(this.fontSize.normal);
    this.doc.setFont(undefined, 'normal');
  }

  printBlock(block, coords, dividers=true, splitLong=true) {
    let y_indent = 0;
    block.forEach((record, index)=> {
      // console.log('recordLabel', record.label);
      // console.log('record', record, this.reformatValue(record.value));

      if(valueIsSpecified(this.reformatValue(record.value))) {
        let line_height = 5;
        let x_coord = coords.x;
        if(record.label) {
          x_coord = coords.value_x;
          if(record.label.toLowerCase() === 'scope' || record.label.toLowerCase() === 'address') {
            x_coord = x_coord-15;
          }
          this.doc.setFont(undefined, 'bold');
          this.doc.text(`${record.label?.toUpperCase()}: `,coords.x, coords.y+y_indent*line_height);

          this.doc.setFont(undefined, 'normal');
        }

        if(dividers && index !==  block.length - 1) {
          this.doc.line(coords.line.x1,coords.line.y1+y_indent*line_height, coords.line.x2,coords.line.y2+y_indent*line_height);
        }

        if(Array.isArray(record.value)) {
          record.value = record.value.join(', ').toUpperCase();
        }
        if(record.value || record.value === 0) {
          if(splitLong && record.value.length >=20 || (record?.label?.toLowerCase() === 'house rewire' && record.value.length >8)) {
            const splitTextToSize = this.doc.splitTextToSize(record.value.toUpperCase(), 61);
            const line_count = Math.ceil(record.value.length/60)+1;
            record.add_line_count = line_count;
            y_indent = y_indent+1;
            this.doc.text(splitTextToSize, coords.line.x1,coords.y+y_indent*line_height);
            y_indent = y_indent+line_count;
          } else{
            this.doc.text(`${this.reformatValue(record.value).toString()?.toUpperCase()}`,x_coord-5,coords.y+y_indent*line_height);
            y_indent = y_indent+1;
          }

        }
      }


    });
    return block;
  }
  chunkify(arr, size) {
    if(!arr || !Array.isArray(arr)|| arr.length === 0) return [];
    return Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>(
      arr.slice(i * size, i * size + size)
    ));
  }
  shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  printNotes(notes, coords) {
    this.currentX = 10;
    const pairs = this.chunkify(notes, 2);
    // const pairs = [[{"text":"Test note #2","left_by":"IAN JACOBY","src":"ipermit","added_time":"22-Sep-2021 11:13:38"},{"text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor i","left_by":"Dovrat Yaish","src":"ipermit","added_time":"22-Sep-2021 11:47:36"}],[{"text":"need more info","left_by":"Dovrat Yaish","src":"ipermit","added_time":"22-Sep-2021 10:47:47"},{"text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","left_by":"dovrat.classcalc5","src":"Contractor","Last_record":true,"added_time":"22-Sep-2021 11:42:57"}],[{"text":"checking status","left_by":"Dovrat Yaish","src":"ipermit","added_time":"22-Sep-2021 11:04:59"},{"text":"will get the permit soon","left_by":"Antonio Mayorga","src":"ipermit","added_time":"22-Sep-2021 11:04:11"}],[{"text":"Test note","left_by":"IAN JACOBY","src":"ipermit","added_time":"22-Sep-2021 11:13:01"},{"text":"Added info","left_by":"dovrat.classcalc5","src":"Contractor","Last_record":true,"added_time":"22-Sep-2021 10:49:50"}]]
    // console.log('PRINTING NOTE', JSON.stringify(pairs))
    pairs.forEach((row, i)=> {
      const note_1 = row[0];
      if(row.length === 2) {
        const note_2 = row[1];
        const note_2_isLong = note_2.text.length > 40;
        const note_1_isLong = note_1.text.length > 40;

        let twoColumns = !note_2_isLong && !note_1_isLong;
        this.doc.setFont(undefined, 'italic');
        this.doc.text(`${this.getNotePrefix(note_1)}${note_1.left_by} at ${note_1.added_time}`, this.currentX, this.curr_block_Y);
        if(twoColumns) {
          this.doc.text(`${this.getNotePrefix(note_2)}${note_2.left_by} at ${note_2.added_time}`, this.pageWidth/2, this.curr_block_Y);
          this.doc.text(`${note_1.text.replace(/\n/gm, ' ')?.toUpperCase()}`, 10, this.curr_block_Y+5);
          this.doc.text(`${note_2.text.replace(/\n/gm, ' ')?.toUpperCase()}`, this.pageWidth/2, this.curr_block_Y+5);
          this.setCurrentY(this.curr_block_Y + 15);
        } else {
          this.setCurrentY(this.curr_block_Y + 5);
          const line_count_1 = this.printFullWidthMultiLine(note_1.text.replace(/\n/gm, ' ').toUpperCase());
          this.setCurrentY(this.curr_block_Y + 5*line_count_1+3);
          this.doc.setFont(undefined, 'italic');
          this.doc.text(`${note_2.left_by} at ${note_2.added_time}`, 10, this.curr_block_Y);
          this.setCurrentY(this.curr_block_Y + 5);
          const line_count_2 = this.printFullWidthMultiLine(note_2.text.replace(/\n/gm, ' ').toUpperCase());
          this.setCurrentY(this.curr_block_Y +5*line_count_2+3);
        }
      } else {
        this.doc.setFont(undefined, 'italic');
        this.doc.text(`${this.getNotePrefix(note_1)}${note_1.left_by} at ${note_1.added_time}`, 10, this.curr_block_Y);
        this.setCurrentY(this.curr_block_Y + 5);
        const line_count_1 = this.printFullWidthMultiLine(note_1.text.replace(/\n/gm, ' ').toUpperCase());
        this.setCurrentY(this.curr_block_Y + 3*line_count_1+5);

      }
    });
  }

  getNotePrefix (note) {
    return note.src?.toLowerCase() === 'contractor' ? 'C: ' : '';
  }

  getMaxLength(a1, a2) {
    const printed_a1 = a1.filter((line)=> line.value ? !valueIsSpecified(line.value) : true);
    const printed_a2 =  a2.filter((line)=> line.value ? !valueIsSpecified(line.value): true);
    return Math.max(printed_a1.length,printed_a2.length);
  }
  getBlockPrintedLength(arr){
    if(!arr) return 0;
    if(!Array.isArray(arr)) return 1;
    if(arr.length === 0) return 0;
    let additional_count = 0;
    arr.forEach((line)=> {
      if(typeof line === 'object' && line?.add_line_count) {
        additional_count = Math.max(additional_count, line.add_line_count);
      }
    });

    return arr.filter((line)=> valueIsSpecified(line.value)).length + additional_count;
  }
  reformatValue (val){
    let transformed = val;
    if(typeof val === 'number' && val === 0) {
      return '0';
    }
    if (val === null || typeof val === 'string' && val.length === 0) {
      return val;
    }
    if (typeof val === 'boolean') {
      transformed = val ? 'yes' : 'no';
    }
    if (typeof val === 'number') {
      transformed = val.toString();
    }
    if (Array.isArray(val) && val.length !== 0) {
      transformed = val.join(', ');
    }
    return transformed;
  }
}

export {printJob, printMail, PrintPDF, DataPreparation, printImage}

import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {userSlice} from './redux/user/userSlice';
import {companySlice} from './redux/companies/companySlice';
import {subformSlice} from './redux/subforms/subformSlice';

// const devToolsConfiguration = {
//   actionSanitizer: (action) => {
//
//     switch (true) {
//
//       case action.type.includes(''):
//         return typeof action.payload !== 'undefined'
//           ? { ...action, payload: '<<LONG_BLOB>>' }
//           : { ...action, results: '<<LONG_BLOB>>' };
//
//       /* ... more entries */
//
//       default:
//         return action;
//     }
//   },
//
//   stateSanitizer: (state) =>
//     state.subforms
//       ? { ...state, subforms: '<<LONG_BLOB>>' }
//       : state,
//
// };

const reducer = combineReducers({
  user: userSlice.reducer,
  company: companySlice.reducer,
  subforms: subformSlice
});
const store = configureStore({
  reducer,
  //devTools: devToolsConfiguration
});
export default store;
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography, Box} from '@mui/material';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';


const Mark1= {
  path: 'M6.31855 14V2.92L4 3.46V1.5L7.40726 0H9V14H6.31855Z M13.8 14L15.6 15.8L9.5 21.8L15.6 27.8L13.8 29.6L7.8 23.5L1.8 29.6L0 27.8L6 21.8L0 15.8L1.8 14L7.8 20L13.8 14Z',
  fillColor: 'red',
  fillOpacity: 0.9,
  strokeWeight: 0,
  rotation: 0,
  scale: 1,
};

const Mark2= {
  path: 'M13.8 14L15.6 15.8L9.5 21.8L15.6 27.8L13.8 29.6L7.8 23.5L1.8 29.6L0 27.8L6 21.8L0 15.8L1.8 14L7.8 20L13.8 14Z M3 14V12.1713C3.8852 11.4766 4.74274 10.7884 5.57261 10.1067C6.41632 9.42509 7.16321 8.75 7.81328 8.08146C8.47718 7.41292 9.00277 6.76404 9.39004 6.13483C9.79115 5.49251 9.9917 4.86985 9.9917 4.26685C9.9917 3.70318 9.82573 3.20506 9.49378 2.77247C9.17566 2.33989 8.64315 2.12359 7.89626 2.12359C7.13555 2.12359 6.56155 2.35955 6.17427 2.83146C5.787 3.30337 5.59336 3.8736 5.59336 4.54213H3.02075C3.04841 3.53277 3.28354 2.69382 3.72614 2.02528C4.16874 1.34363 4.75657 0.838951 5.48963 0.511235C6.22268 0.170412 7.04564 0 7.95851 0C9.43845 0 10.5864 0.386704 11.4025 1.16011C12.2324 1.92041 12.6473 2.91011 12.6473 4.12921C12.6473 4.88951 12.4606 5.63015 12.0871 6.35112C11.7275 7.0721 11.2503 7.76685 10.6556 8.43539C10.0609 9.10393 9.4177 9.73315 8.72614 10.323C8.03458 10.8998 7.36376 11.4373 6.71369 11.9354H13V14H3Z',
  fillColor: 'red',
  fillOpacity: 0.9,
  strokeWeight: 0,
  rotation: 0,
  scale: 1,
};

export const Map = (props)=> {

  const [map, setMap] = useState();
  const [locationFound, setLocationFound] = useState(false);
  const [currentMarkers, setCurrentMarkers] = useState(Array(props.markersAmount || 1));
  const location = useRef();

  const getAdjustedPosition = (pos, index)=> {
    const quot = 360.0 / props.markersAmount;
    const newLat = pos.lat() + -.00005 * Math.cos(+quot * (index+1));
    const newLng = pos.lng() + -.00005 * Math.sin(+quot * (index+1));
    return new google.maps.LatLng(newLat, newLng);
  };
  const options = {
    ...{
      zoom: 20,
      streetViewControl: false,
      mapTypeId: 'hybrid',
      tilt: 0,
      rotateControl: false,
      fullscreenControl: false,
      clickableIcons: false, // disables infowindows
      preventGoogleFontsLoading: true,
      draggableCursor: 'default',
      styles: {stylers: [{visibility: 'off'}]}
    },
  };

  const onScriptLoad=()=> {
    // Prevent google overwrite Fonts
    // https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font/25902239#25902239
    const head = document.getElementsByTagName('head')[0];
    const insertBefore = head.insertBefore;
    head.insertBefore = (newElement, referenceElement) => {
      if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
        //console.info('Prevented Roboto from loading!');
        return;
      }
      insertBefore.call(head, newElement, referenceElement);
    };

    setMap(new window.google.maps.Map(
      document.getElementById(props.id),
      options,
    ));
  };

  const createMarker = (loc, index,)=> {
    const markerIcon = index === 0 ? Mark1 : Mark2;
    const marker = new window.google.maps.Marker({
      position: getAdjustedPosition(loc, index+1),
      map: map,
      icon: {...markerIcon, ...{anchor: new window.google.maps.Point(7, 10)}},
      draggable: true,
      cursor: 'grab',
    });
    const newCurrMarkers = [...currentMarkers];
    newCurrMarkers[index] = marker;
    setCurrentMarkers(newCurrMarkers);
    return marker;
  };

  const deleteSecondMarker = ()=> {
    if(currentMarkers.length === 2) {
      currentMarkers[1].setMap(null);
      const newArr = [...currentMarkers];
      newArr.pop();
      setCurrentMarkers(newArr);
    }
  };

  useEffect(()=> {
    if(!map) return;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({address: props.address}, (results) => {
      location.current = results?.[0].geometry?.location;
      if(!location.current) return;
      setLocationFound(true);
      new window.google.maps.Marker({
        position: results[0].geometry.location,
        map: map,
      });
      // console.log('MAP', map);
      // marker.setMap(map);
      map.setCenter(results[0].geometry.location);
      map.addListener('mouseover', () => {
        map.setOptions({draggableCursor: 'pointer'});
      });
      // adds a little bit of zoom to the map for better fit
      setTimeout(() => map.setZoom(20.1), 300);
      const m1 = createMarker(results[0].geometry.location, 0);
      const m2 = createMarker(results[0].geometry.location, 1);
      m1.setMap(map);
      m2.setMap(props.markersAmount === 2 ? map : null);
    });
  }, [map]);
  window.currM = currentMarkers;
  useEffect(()=> {
    if(location.current) {
      if(props.markersAmount === 1) {
        currentMarkers[1].setMap(null);
        deleteSecondMarker();
      } else if(props.markersAmount === 2){
        if(!currentMarkers[1]) {
          const m2 = createMarker(location.current, 1);
          m2.setMap(map);
        } else {
          currentMarkers[1].setMap(map);
        }

      }
    }
  }, [props.markersAmount]);

  useEffect(()=> {
    if (!window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}`;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      s.addEventListener('load', () => {
        onScriptLoad();
      });
    } else {
      onScriptLoad();
    }
  }, []);
  return(
    <Box sx={{position: 'relative'}}>
      {!locationFound && <Box sx={{position: 'absolute',
        top: '210px',
        opacity: '0.5',
        zIndex: 100,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <BlockOutlinedIcon sx={{fontSize: '50px', color: STYLE_CONSTANTS.COLORS.fontMain}}/>
        <Typography variant={'subtitle1'} fontSize={'24px'} fontWeight={900}>Address not found</Typography>
      </Box>}

      <div style={{width: 500, height: 500}} id={props.id} />
    </Box>
  );

};
Map.propTypes = {
  markersAmount: PropTypes.number,
  id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

Map.defaultProps = {
  markersAmount: 1,
};

import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import clsx from 'clsx';
import {useFormContext} from 'react-hook-form';

export const MiscInfo = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.TEMPORARY_POWER;
  const subParent = `${FIELDS.MISC_INFO.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.TEMPORARY_POWER.api_name]?.[FIELDS.MISC_INFO.api_name];

  const getFieldValue = (fieldName)=> {
    const blockValues = values?.[`${subParent}`];
    return blockValues?.[fieldName];
  };
  return (
    <Grid container xs={12} justifyContent={'center'} spacing={{md: 6, lg: 12}}>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <CustomFormBlock
          {...props}
          field={FIELDS.JURISDICTION_CITY}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.JURISDICTION_CITY.api_name)}
          renderLabel={true}
          fullWidth={true}

        />
        <CustomFormBlock
          {...props}
          field={FIELDS.COUNTY}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.COUNTY.api_name)}
          renderLabel={true}
          fullWidth={true}
        />
        <CustomFormBlock{...props}
          field={FIELDS.BUILDING_USE}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.BUILDING_USE.api_name)}
          renderLabel={true}
          fullWidth={true}
        />

      </Grid>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <CustomFormBlock{...props}
          field={FIELDS.BUILDING_PERMIT}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.BUILDING_PERMIT.api_name)}
          renderLabel={true}
          fullWidth={true}
        />
        <CustomFormBlock{...props}
          field={FIELDS.POWER_USAGE}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.POWER_USAGE.api_name)}
          renderLabel={true}
        />
      </Grid>
    </Grid>
  );
};
MiscInfo.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default MiscInfo;

import React, {useState, useCallback} from 'react';
import base_url, {urlSearchParams} from '../../../baseUrls';
import {ThemeProvider, Box, LinearProgress, CircularProgress, Typography} from '@mui/material';
import NavBar from '../../../pieces/navbar/navbar';
///import UserModal from '../../../pieces/modals/userModal';
import * as allConstants from '../../../constants/allConstants';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {contractorUserPageRules, CONTRACTOR_ELEMENTS} from '../../../permissions/contractorUserPageAccessControl';
import AllServiceTitanJobGrid from '../allServiceTitanJobGrid';
import myTheme from '../../../styles/myStyles';
import DisplayListsPageWrapper from '../../wrappers/displayListsPageWrapper';
//import SetCompanyAdmin from '../../users/setCompanyAdmin';
import ListPageHeader from '../../../pieces/listPageHeader';
import usePagination from '../../../hooks/usePagination';
import {useGridViewState} from '../../../hooks/useGridViewState';
import AccessDenied from '../../misc/accessDenied';
import {globalUseClasses} from '../../../styles/globalClasses';
import {useDispatch} from 'react-redux';
//import actions from '../../../redux/actions';
import {companyAndUserUtils} from '../../../utils/companyAndUserUtils';
import axios from 'axios';
import ApiUtils from '../../../utils/apiUtils';
import {useNavigate} from 'react-router-dom';
import {useGotIt} from '../../../hooks/useGotIt';

export const ServiceTitanJob  = (props)=> {

  const [isLoading, setIsLoading ] = useState(false);
  const viewController = useGridViewState();
  //const dispatch = useDispatch();
  const classes = globalUseClasses();
  const pagination = usePagination();
  const gotItPopup = useGotIt();
  //const company = useSelector(state => state.company);
  const navigate = useNavigate();
  if(!NewPermissionGate({
    user: viewController.user,
    elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.viewPage]
  })) {
    return <AccessDenied/>;
  }

  // const user = useSelector(state => state.user);
  //const handleAddUserOpen = useCallback(() =>  navigate(`${allConstants.PATH.USER}${location.search}`), []);

  const handleAddUserOpen = useCallback(() =>  {
    
    const url = `${allConstants.PATH.USER}${location.search}`;

    // console.log('url user setup =>');
    // console.log(url);
    // console.log('location');
    // console.log(location);
    
    navigate(`${allConstants.PATH.USER}${location.search}`);

  }, [])
  

  // const timeout = async (delay) =>{
  //   return new Promise( res => setTimeout(res, delay) );
  // }

  const getNewTenantJobs = async () => {

    setIsLoading(true);
    const url = `${base_url.api}companies/get-tenant-jobs-manually`;
    
    let requestParams = { tenantId: props.tabProps?.companyData.serviceTitan.tenantId,
                          id: 0 };

    const authToken = await ApiUtils.getAccessToken(); 

    let axiosConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      }
    };
    await axios.post(url, JSON.stringify(requestParams), axiosConfig);
    
    setIsLoading(false);
    gotItPopup.setShow(`Please wait for a minute or two and click on "Refresh Grid" button to see new jobs`);
  }
  
  const refreshGrid = () => {
    viewController.refreshGrid();
    //gotItPopup.setShow('Update Grid View!');
  }

  const showGridColumnChooser = () => {
    //console.log('show grid column chooser');;

    viewController.showGridColumnChooser();
  }

  const getAdditionalButtons = ()=> {
      return [
        {
          label: `Get New Jobs`,
          variant: 'outlined',
          color: 'primary',
          onClick: getNewTenantJobs,
        },
        {
          label: `Refresh Grid`,
          variant: 'outlined',
          color: 'primary',
          onClick: refreshGrid,
        }
        // {
        //   label: `Column Chooser`,
        //   variant: 'outlined',
        //   color: 'primary',
        //   onClick: showGridColumnChooser,
        // }
      ];
  };

  const headerComponent = <ListPageHeader header={'Service Titan Jobs'}
    addHandler={handleAddUserOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {false} 
    additionalButtons={getAdditionalButtons()}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  const getFetchParams = ()=> {
    const url = `${base_url.api}companies/get-tenant-jobs`;
    const respKey = 'stJobs';
    let params = {};
    params.tenantId = props.tabProps?.companyData.serviceTitan.tenantId;
    return {url: url, respKey: respKey, options: params};
  };

  const fetchParams = getFetchParams();

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          {gotItPopup.PopUp()}
          {isLoading && <Box display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress variant="indeterminate"  color="success" />
                <Typography color="red" variant="h6">
                    Please wait!
                </Typography>                
              </Box>}
          <Box> 
            <DisplayListsPageWrapper headerComponent={headerComponent}>
              <AllServiceTitanJobGrid viewController={viewController}
                  fetchParams={fetchParams}
                  pagination={pagination}/>
              {pagination.PageController}
            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};

// ServiceTitanNotEmp.propTypes = companyPropTypes;
// ServiceTitanNotEmp.defaultProps = companyDefaultProps;

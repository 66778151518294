import React from 'react';
import {Grid} from '@mui/material';
import Utils from '../../../../../utils/utils';
import * as allConstants from '../../../../../constants/allConstants';
import PropTypes from 'prop-types';
import {FormLabel,FormValue} from '../../../../../pieces/displayJob/formLine';
import {CommercialSection} from '../commercialSection';


const MiscDetails = (props)=> {
  const {details} = props;
  // console.log('misc DETAILS', props);
  const getMiscScope = ()=> {
    let scope = details?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
    let scopeContent;
    if(scope && Utils.valueIsSpecified(scope)) {
      scopeContent = <>
        <FormLabel label={allConstants.JOB_FIELDS.GENERAL.SCOPE.label}/>
        <FormValue value={scope} />
      </>;

    }
    return scopeContent;
  };

  const scopeContent = getMiscScope();
  const commercial = <CommercialSection details={details}/>;

  return(
    <>
      <Grid container>
        {scopeContent &&
        <Grid item xs={12} sx={{marginBottom: {xs: '8px', sm: '16px'}}}>
          {scopeContent}
        </Grid>
        }
        {commercial &&
        <Grid item xs={12}>
          {commercial}
        </Grid>
        }
      </Grid>
    </>
  );
};
MiscDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
};

export {MiscDetails};

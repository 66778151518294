import React from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {Grid, Paper, ThemeProvider, Box, Typography} from '@mui/material';
import myTheme from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import BlockedImage from '../../static/images/warningImage.png';

const NoAccess = () => {
  const classes = globalUseClasses();
  return (
    <>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          <Grid container xs={12}  sx={{backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain}}>
            <Grid item xs={12} sm={10} md={6} lg={4} sx={{
              margin: '200px auto'
            }}>
              <Paper sx={{
                width: '100%',
                //height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px',
                borderRadius: '10px',

              }}>
                <img src={BlockedImage} alt={''}/>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    margin: '16px auto'
                  }}>
                Your account is deactivated and you no longer have access to this portal</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default NoAccess;

import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';

class ElectricalDisplayUtils {
  constructor() {
    this.el_fields = allConstants.JOB_FIELDS.ELECTRICAL;
  }
  getEquipment(equipment) {
    const EQ = allConstants.EQUIPMENT;
    const SHARED = allConstants.JOB_FIELDS.SHARED;
    const eq_count = {
      [EQ.BR_CIR.api_name]: 0,
      [EQ.SUB_PANELS.api_name]: 0,
      [EQ.EV_CAR.api_name]: 0,
      [EQ.DISC_BOX.api_name]: 0,
      [EQ.MOTORS.api_name]:0,
    };

    const eq_ordered_fields = {
      [EQ.BR_CIR.api_name]: [
        SHARED.QUANTITY,
        this.el_fields.AMPS,
        this.el_fields.VOLTS,
        allConstants.JOB_FIELDS.GENERAL.SCOPE

      ],
      [EQ.SUB_PANELS.api_name]: [
        SHARED.QUANTITY,
        this.el_fields.AMPS,
        allConstants.JOB_FIELDS.GENERAL.SCOPE
      ],
      [EQ.EV_CAR.api_name]: [
        this.el_fields.AMPS,
        this.el_fields.VOLTS,
        allConstants.JOB_FIELDS.GENERAL.SCOPE
      ],
      [EQ.DISC_BOX.api_name]: [
        SHARED.QUANTITY,
        this.el_fields.AMPS,
        allConstants.JOB_FIELDS.GENERAL.SCOPE
      ],
      [EQ.MOTORS.api_name]: [
        this.el_fields.KW,
        allConstants.JOB_FIELDS.GENERAL.SCOPE
      ]
    };

    if(!equipment) return null;

    return jobDisplayUtils.getEquipment({
      equipment: equipment,
      fieldOrder: eq_ordered_fields,
      count: eq_count
    });
  }

  /**
   *
   * @param equipment
   * @param details
   * @param print
   * @return {{scopes: *[], content: *[]}|null}
   */
  getElectricalEquipment(equipment, details, print=false){
    const fields = [this.el_fields.HOUSE_REWIRE, this.el_fields.SERVICE_UPGRADE, this.el_fields.LIGHTS, this.el_fields.SWITCHES, this.el_fields.OUTLETS];
    const detailRows = jobDisplayUtils.getDetails({
      details:details,
      fields: fields,
      print: print,
    });
    let content = [];
    if(detailRows && detailRows.length !== 0) {
      content = [...[{name: '', fields: detailRows}], ...content];
    }
    const equipmentRows = this.getEquipment(equipment);
    if(equipmentRows && equipmentRows.length !== 0) {
      content = [...content, ...equipmentRows];
    }

    const scopes = [];
    if(content && content.length !== 0) {
      content.forEach((piece)=> {
        piece.fields.forEach((f) => {
          if (Utils.equalsIgnoreCase(f.label, allConstants.JOB_FIELDS.GENERAL.SCOPE.display_label)) {
            scopes.push({name: piece.name, value: f.value});
            delete piece.fields.scope;
          }
        });
      });
    }

    return {content, scopes};
    
  }
}

const electricalDisplayUtils= new ElectricalDisplayUtils();
export {electricalDisplayUtils};
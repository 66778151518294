const SERVICE_TITAN_JOB_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  TENANT_ID: {api_name: 'tenantId', required: false, label: 'Tenant ID', display_label: 'Tenant ID'},
  JOB_NUMBER: {api_name: 'jobNumber', required: false, label: 'Job Number', display_label: 'Job Number'},
  JOB_ID: {api_name: 'jobId', required: false, label: 'Job ID', display_label: 'SJob Id'},
  JOB_TYPE: {api_name: 'jobType', required: false, label: 'Job Type', display_label: 'Job Type'},
  IPERMIT_JOB_URL: {api_name: 'iPermitJobUrl', required: false, label: 'IPermit Job Url', display_label: 'Job Url'},
  IPERMIT_JOB_ID: {api_name: 'iPermitJobId', required: false, label: 'IPermit Job ID.', display_label: 'IPermit Job ID'},
  STATUS: {api_name: 'status', required: false, label: 'Status', display_label: 'Status'},
  IS_HERS_REQUIRED: {api_name: 'isHERS_Required', required: false, label: 'HERS Required', display_label: 'HERS Required'},
  HERS_STATUS: {api_name: 'herS_Status', required: false, label: 'HERS Status', display_label: 'HERS Status'},
  PERMIT_STATUS: {api_name: 'permitStatus', required: false, label: 'Permit Status', display_label: 'Permit Status'},
  IS_DRAFT: {api_name: 'isDraft', required: false, label: 'Draft?', display_label: 'Draft'},
  IS: {api_name: 'isCompleted', required: false, label: 'Completed', display_label: 'Completed'},
  NUM_ATTEMPT: {api_name: 'numAttempt', required: false, label: '# Attempt', display_label: '# Attempt'},
  EMP_EMAIL: {api_name: 'empEmail', required: false, label: 'Employee Email', display_label: 'Employee Email'},
  DATE_CREATED: {api_name:'dateCreated', required: false, label: 'Date Created', display_label: 'Date Created'},
  DATE_MODIFIED: {api_name:'dateModified', required: false, label: 'Date Modified', display_label: 'Date Modified'},
}

export {SERVICE_TITAN_JOB_FIELDS};

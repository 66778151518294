import React from 'react';
import * as allConstants from '../../../../../constants/allConstants';
import {Box} from '@mui/material';
import {fieldBank} from '../fieldBank';
import SystemFormWrapper from './systemFormWrapper';
import * as Yup from 'yup';
import mechanicalUtils from '../mechUtils';

const Ducts = (props)=> {
  // const [selected, setSelected] = useState();
  const MECH_FIELDS = allConstants.JOB_FIELDS.MECHANICAL;
  const EQUIPMENT = allConstants.EQUIPMENT;
  const system = allConstants.JOB_FIELDS.MECHANICAL.DUCTS.api_name;
  const options = allConstants.SYSTEM_CONTENT[system];

  const equipmentMap = {};
  options.forEach((duct)=> {
    equipmentMap[duct.api_name] = {
      name: duct.api_name,
      fields: [
        fieldBank[MECH_FIELDS.SUPPLY.api_name],
        fieldBank[MECH_FIELDS.RETURN.api_name],
        fieldBank[MECH_FIELDS.ENTIRELY_NEW_DUCT.api_name]
      ]
    };
  });
  // eslint-disable-next-line no-unused-vars
  const validationSchema =Yup.object().shape({
    [EQUIPMENT.R6.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.SUPPLY.api_name, MECH_FIELDS.RETURN.api_name, MECH_FIELDS.ENTIRELY_NEW_DUCT.api_name],
          equipmentMap[EQUIPMENT.R6.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),
    })),
    [EQUIPMENT.R8.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.SUPPLY.api_name, MECH_FIELDS.RETURN.api_name, MECH_FIELDS.ENTIRELY_NEW_DUCT.api_name],
          equipmentMap[EQUIPMENT.R8.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),
    })),
    [EQUIPMENT.R10.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.SUPPLY.api_name, MECH_FIELDS.RETURN.api_name, MECH_FIELDS.ENTIRELY_NEW_DUCT.api_name],
          equipmentMap[EQUIPMENT.R10.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),
    })),

  });
  return(
    <>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        {SystemFormWrapper({
          ...props,
          options: options,
          system: system,
          validationSchema: validationSchema,
          // setSelectedToParent: setSelected,
          equipmentMap: equipmentMap,
        })}
      </Box>
    </>
  );
};

// Ducts.propTypes = {
//   saveSelectedEquipment: PropTypes.func,
//   getSelectedEquipment: PropTypes.func,
// };
//
// Ducts.defaultProps = {
//   saveSelectedEquipment: ()=>{},
//   getSelectedEquipment: ()=>{return [];},
// };
export default Ducts;

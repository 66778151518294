import * as React from 'react';
import {PropTypes} from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

export default function RadioSwitch (props) {
  return (
    <FormControl sx={{
      '& .MuiFormGroup-root': {
        width: '100%',
        justifyContent: 'center',
      }
    }}>
      {props.showLabel && <FormLabel id={`radio-buttons-${props.label}-label`}>{props.label}</FormLabel>}
      <RadioGroup
        row={props.direction === 'row'}
        aria-labelledby={`radio-buttons-${props.label}-label`}
        name={`${props.label}`}
        defaultValue={props.defaultValue}
        onChange={(event) => {props.onChange(event.target.value);}}
      >
        {props.options.map((option, index) => {
          return <FormControlLabel key={`${index}`}
            value={option.value} control={<Radio sx={{
              color: STYLE_CONSTANTS.COLORS.globalGreen,
              '&.Mui-checked': {
                color: STYLE_CONSTANTS.COLORS.globalGreen
              }
            }}/>}
            label={option.label}
            sx={{
              '& .MuiTypography-root': {
                fontSize: {'xs': '14px', 'md': '18px', 'lg': '20px'},
                fontWeight: 400,
                textTransform: 'uppercase',
              }
            }}
          />;
        } )}
      </RadioGroup>
    </FormControl>
  );
}

RadioSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
};

RadioSwitch.defaultProps = {
  direction: 'row',
};

import {Box, Button, Typography} from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import {JobPaperWrapper} from './jobPaperWrapper';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import AttachmentRow from '../../jobDetails/newAttachmentRow';
import PropTypes from 'prop-types';
import {yupResolver} from '@hookform/resolvers/yup';
import * as allConstants from '../../../../constants/allConstants';
import * as Yup from 'yup';
import {useFieldArray} from 'react-hook-form';
import {useFormContext} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';

const AddAttachmentsJob = (props) => {

  const saveSubform = useMemo(()=> props.setEquipmentForm, []);

  const validationSchema = Yup.object().shape({
    files: Yup.array().of(Yup.object().shape({
      fields: Yup.array().of(Yup.object().shape({
        [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: Yup.mixed().required('Please upload file or remove this row'),
      }))
    }))
  });

  const formOptions = {
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      files: [{
        name: 'file upload',
        fields: [{
          [allConstants.JOB_FIELDS.FILE.NAME.api_name]: null,
          [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: null,
        }],
      }],
    }
  };

  const formMethods = useFormContext(formOptions);

  useEffect(()=> {
    saveSubform('attachments', formMethods);
  }, []);

  const blockPrefix = 'files';
  const {fields, append} = useFieldArray({
    control: formMethods.control,
    name: blockPrefix
  });

  const addAttachment = () => {
    append({...formOptions.defaultValues.files[0].fields[0]});
  };

  const deleteAttachmentRow = (index) => {
    try {
      const files = [...formMethods.getValues()?.files];
      files.splice(index, 1);
      formMethods.setValue('files', files);
    } catch(e){

    }
  };

  return (
    <>
      <Typography variant={'h5'} sx={{fontSize: '16px'}}>Attachments</Typography>
      <JobPaperWrapper styles={{padding: '24px !important'}}>
        <Box>
          {
            fields.map((item, idx) => {
              return <AttachmentRow {...props}
                key={item?.id+idx}
                index={idx}
                blockPrefix={blockPrefix}
                removeAttachment={(idx)=> deleteAttachmentRow(idx)}
              />;
            })
          }
        </Box>
        <Box sx={{display: 'flex'}}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<AddIcon/>}
            onClick={addAttachment}
            color='primary'
            sx={{
              ':hover': {backgroundColor: STYLE_CONSTANTS.COLORS.fontSecondary}
            }}>
            Add New File</Button>
        </Box>
      </JobPaperWrapper>
    </>
  );
};

export default AddAttachmentsJob;

AddAttachmentsJob.propTypes = {
  setEquipmentForm: PropTypes.func,
};

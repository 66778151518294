import {makeStyles} from '@mui/styles';

const useAnalyticsStyles = makeStyles(() => {
  return {
    full_height_box: {
      height: '100%'
    },
    selected: {
      borderBottom: '2px solid',
      marginBottom: 0,
      borderColor: 'inherit !important'
    },
    red: {
      color: 'red',
      borderBottom: '2px solid transparent'
    },
    blue: {
      color: 'blue',
      borderBottom: '2px solid transparent'
    },
    img: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '70%',
    }
  };
});

export {useAnalyticsStyles};

class AnalyticsPageUtils {
  /**
   * @name getRandomDummyData
   * @param {number} min
   * @param {number} max
   * @returns {number}
   */
  static getRandomDummyData =(min, max)=>{
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  /**
   * @name yearIsEqual
   * @param {Object} d1 - instance of Date class
   * @param {Object} d2 - instance of Date class
   * @returns {boolean}
   */
  static yearIsEqual= (d1, d2)=> {
    return d1.getFullYear().toString() === d2.getFullYear().toString();
  }
  /**
   * @name monthIsEqual
   * @param {Object} d1 - instance of Date class
   * @param {Object} d2 - instance of Date class
   * @returns {boolean}
   */
  static monthIsEqual= (d1, d2)=> {
    return d1.getMonth()=== d2.getMonth();
  }
  /**
   *
   */
  static prepareCostPerCityToDisplay = (raw_data)=> {
    const cities = [];
    const cost = [];
    // console.log('raw', raw_data);
    // console.log('raw_length', raw_data.length)
    const processed_data = raw_data.map((e)=> {return {...e, ...{avg_cost: parseFloat(e.avg_cost), cost_count: parseFloat(e.cost_count)}};});
    // const sorted = processed_data.sort((a, b) => (a.avg_cost < b.avg_cost) ? 1 : -1)
    let topTen;
    if(processed_data.length <= 10) {
      topTen = processed_data;
    } else {
      topTen = processed_data.slice(0,10);
    }
    //
    //  // topTen = sorted.slice(0,10);
    //  console.log('topTen', topTen)
    // console.log(topTen.length)
    for(let i in topTen) {
      cities.push(topTen[i].city);
      cost.push(Number(parseFloat(topTen[i].avg_cost).toFixed(2)));
    }
    return [cities, cost];
  };
  static combineParams = (params)=> {
    if(!params) return '';
    let res = [];
    for (const [key, value] of Object.entries(params)) {
      res.push(`${key}=${value}`);
    }
    // console.log(res.join('&'))
    return res.join('&');
  }

  static adjustTickCount = (maxValue) => {
    if(maxValue % 6 !== 0) {
      if(maxValue % 3 === 0) {
        return 4;
      } else if(maxValue % 5 === 0 ) {
        return 6;
      } else if(maxValue %2 === 0)
        return 3;
    }
    return 6;
  }
  /**
   * @name getYearMonth
   * @description: Jan would be 01, Feb - 02, Dec - 12
   * @returns Object {year: "", month:""}
   */
  static getYearMonth(date) {
    const today = date ? date : new Date();
    let month = (today.getMonth()+1).toString();
    // console.log('today', month, typeof month);
    if(month.length === 1) {
      month = `0${month}`;
    }
    return {'year': today.getFullYear().toString(), month};
  }
}


export default AnalyticsPageUtils;

import React, {useState, useRef, useEffect} from 'react';
import {Box, Paper} from '@mui/material';
import {AnalyticsHeader} from '../../../pieces/analytics/analyticsBaseCard';
import clsx from 'clsx';
import GettingDataProgress from '../../../pieces/gettingDataProgress';
import NoDataFound from '../../../pieces/noDataFound';
import {useAnalyticsStyles} from '../analyticsStyles';
import {awsUtils} from '../../../utils/awsUtils';
import {ANALYTICS_TARGETS} from '../../../constants/options/analyticsTargets';
import {globalUseClasses} from '../../../styles/globalClasses';
import PropTypes from 'prop-types';
import MultipleBarChart from '../../../pieces/analytics/multipleBarChart';
import MonthYearPicker from '../../../pieces/monthYearPicker';

const HersTestScheduled = (props)=> {
  const sessionDateKey = props.sessionKey + 'Date';
  const classes = {...globalUseClasses(), ...useAnalyticsStyles()};
  const [data, setData] = useState(sessionStorage.getItem(props.sessionKey) ? JSON.parse(sessionStorage.getItem(props.sessionKey)) : []);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);
  const [date, setDate] = useState(sessionStorage.getItem(sessionDateKey) ? new Date(sessionStorage.getItem(sessionDateKey)) : new Date());


  const months = useRef(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);

  const saveData = (fetchedData) => {
    if (fetchedData && Array.isArray(fetchedData)) {
      let formattedData;
      formattedData = Array(12).fill(0);
      fetchedData.forEach((month) => {

        formattedData[Number(month.status_month) - 1] = Number(month.scheduled_count);
      });
      setData(formattedData);
      sessionStorage.setItem(props.sessionKey, JSON.stringify(formattedData));
    } else {
      setData([]);
    }
  };

  const fetchData = (date)=> {

    let fetchYear = date.getFullYear();

    const fetchUrl = awsUtils.createUrl({
      type: ANALYTICS_TARGETS.HERS_SCHEDULED_MONTHLY.api_request,
      company: props.company,
      params: {year: fetchYear}
    });
    setFetching(true);

    fetch(`${fetchUrl}`)
      .then(response=> { return response.json();}).then(
        (result) => {
          saveData(result.queryResult);
          setFound(true);
          setFetching(false);
        },

        (error) => {
          console.log('error...', error);
          setFound(false);
          setFetching(false);
        }
      );
  };

  const saveYear = (newDate) => {
    setDate(newDate);
    fetchData(newDate);
    sessionStorage.setItem(sessionDateKey, newDate.toISOString());
  };

  useEffect(()=> {
    if (data.length === 0) {
      fetchData(date);
    }
  }, []);

  let content;
  if (fetching) {
    content = <GettingDataProgress/>;
  } else if (!found) {
    content = <NoDataFound/>;
  } else {
    content =
        <MultipleBarChart
          width={props.mediaQueries.isXL ? 810 : null}
          y={data} x={months.current}
          blue={true} dataKey={'scheduled'}/>;
  }

  return (
    <Paper elevation={0} className={clsx(classes.info_paper)} sx={{width: props.mediaQueries.isXL ? '800px' : '700px'}}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <AnalyticsHeader title={'HERS Test Scheduled'}/>
        <MonthYearPicker views={['year']} defaultDate={date}  handler={(value) => {saveYear(value);}}/>
      </Box>
      <Box display="flex" alignItems="center"
        flexDirection={'column'}
        rowGap={fetching ? 2 : 0}
        height={'100%'}>
        {content}
      </Box>
    </Paper>
  );
};

HersTestScheduled.propTypes = {
  company: PropTypes.func.isRequired,
  sessionKey: PropTypes.string,
  mediaQueries: PropTypes.shape({
    isXL: PropTypes.bool
  })
};

HersTestScheduled.defaultProps = {
  mediaQueries: {
    isXL: false
  }
};
export {HersTestScheduled};

import {INSPECTION_TYPES, INSPECTION_STATUSES} from '../../../../../constants/allConstants';

export const shouldShow = (transition, currentStatus, inspectionType) => {


  if(inspectionType === INSPECTION_TYPES.FINAL.value && transition.display === INSPECTION_STATUSES.SCHEDULED_ROUGH.display) return false;
  if(inspectionType === INSPECTION_TYPES.ROUGH.value && transition.display === INSPECTION_STATUSES.SCHEDULED.display) return false;

  if(currentStatus === transition.value) return true;

  const hiddenOnStatuses = {
    [INSPECTION_STATUSES.RESCHEDULED.value]: [
      INSPECTION_STATUSES.NEW.value,
      INSPECTION_STATUSES.HOLD.value,
      INSPECTION_STATUSES.PENDING.value,
      INSPECTION_STATUSES.COMPLETED.value,
      INSPECTION_STATUSES.CANCELLED.value,
      INSPECTION_STATUSES.MAILED_CERT_LETTER.value
    ],
  };

  if(hiddenOnStatuses?.[transition.value] && hiddenOnStatuses[transition.value].includes(currentStatus)) return false;
  return true;
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import {ERROR_CODES, errorHandler} from '../../../utils/errorHandler';
import Utils from '../../../utils/utils';
import {initialUserState} from '../userInitialStates';

export const validateUserInvite= createAsyncThunk(
  'users/validateUserInvite',
  async (props, thunkAPI) => {

    try {
      const response = await fetch(
        `${base_url.api}users/validate-user-invite`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          //K2CqDI6tR- - temp always valid code.
          body: JSON.stringify({
            inviteCode: props.inviteCode
            //inviteCode: 'K2CqDI6tR-'
          }),
        }
      );
      let data = await response.json();
      //console.log('Validate DATA', data);
      if (response.status === 200) {

        if(data.data.isValid) {
          return {...data.data};
        } else {
          return thunkAPI.rejectWithValue({
            errorCode: ERROR_CODES.INVALID_LINK
          });
        }

      } else {
        console.log('response status is not 200');
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const validateInviteReducer = {
  [validateUserInvite.pending]: (state, {payload}) => {
    state.isFetching = true;
  },

  [validateUserInvite.fulfilled]: (state, {payload}) => {
    state.isFetching = false;
    state.isSuccess = true;
    state.inviteLinkIsValid = payload.isValid;
  },

  [validateUserInvite.rejected]: (state, {payload}) => {
    const message = Utils.isDevEnv() ? `${payload.errorCode}::: ${payload?.message}` : errorHandler.getErrorMessage(payload.errorCode);
    state = {...initialUserState, ...{
      isFetching:false,
      isError:true,
      errorMessage: message

    }};
    return state;
  },
};
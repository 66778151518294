import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {Button} from '@mui/material';

const EditFormButton = (props)=> {
  const text = props.label ? props.label : props.edit ? 'Edit' : 'Cancel';
  const color = !props.isEditable ? STYLE_CONSTANTS.COLORS.lightGrayHover : props.edit ? STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.globalRed;
  const icon = props.edit ? <ModeEditOutlinedIcon style={{color: color}}/> : <CloseIcon style={{color: color}}/>;

  return(
    <Button onClick={props.onClick} variant={'text'} disableRipple disabled={!props.isEditable}
      style={{fontSize: '18px',
        color: color,
        height: '45px'

      }}
      startIcon={icon}>{text}</Button>

  );

};

EditFormButton.propTypes = {
  onClick: PropTypes.func,
  edit: PropTypes.bool,
  isEditable: PropTypes.bool,
  label: PropTypes.string,
};
EditFormButton.defaultProps={
  onClick: ()=> {console.log('No onclick event provided');},
  edit: false,
  isEditable: true,
  label: null,
};
export default EditFormButton;

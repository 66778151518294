import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';

class HvacDisplayUtils {
  constructor() {
    this.hvac_fields = allConstants.JOB_FIELDS.MECHANICAL;
    this.com_fields = allConstants.JOB_FIELDS.COMMERCIAL;
  }

  getEquipment = (equipment, print = false) => {
    const EQ = allConstants.EQUIPMENT;
    const SHARED = allConstants.JOB_FIELDS.SHARED;
    let content = [];
    if(!equipment) return content;
    const eq_count = {
      [EQ.FURNACE.api_name]: 0,
      [EQ.HEAT_PUMP_PU.api_name]: 0,
      [EQ.HEAT_PUMP_COND.api_name]: 0,
      [EQ.AIR_HANDLER.api_name]: 0,
      [EQ.GAS_PU.api_name]: 0,
      [EQ.COIL.api_name]: 0,
      [EQ.MINI_SPLIT_DUCTED.api_name]: 0,
      [EQ.MINI_SPLIT.api_name]: 0,
      [EQ.R6.api_name]: 0,
      [EQ.R8.api_name]: 0,
      [EQ.R10.api_name]: 0,
      [EQ.CONDENSER.api_name]: 0,
    };

    const eq_ordered_fields = {
      [EQ.FURNACE.api_name]: [
        this.hvac_fields.BTUS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.AFUE,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.NEED_GAS,
        this.hvac_fields.NEED_ELECTRICAL

      ],
      [EQ.HEAT_PUMP_PU.api_name]: [
        this.hvac_fields.TONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.SERIAL,
        this.hvac_fields.NEED_ELECTRICAL,
        this.hvac_fields.SEER,

      ],
      [EQ.HEAT_PUMP_COND.api_name]: [
        this.hvac_fields.TONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.SEER,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.NEED_ELECTRICAL,
      ],
      [EQ.AIR_HANDLER.api_name]: [
        this.hvac_fields.BTUS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.HSPF,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.NEED_ELECTRICAL,
      ],
      [EQ.GAS_PU.api_name]: [
        this.hvac_fields.TONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.SERIAL,
        this.hvac_fields.NEED_ELECTRICAL,
        this.hvac_fields.SEER,
      ],
      [EQ.COIL.api_name]: [
        this.hvac_fields.TONS,
        this.hvac_fields.SEER,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
      ],
      [EQ.MINI_SPLIT_DUCTED.api_name]: [
        this.hvac_fields.TONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.SEER,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.SERIAL,
        this.hvac_fields.NEED_ELECTRICAL,
        this.hvac_fields.AH_QTY,
        this.hvac_fields.DUCTS_QTY,
        this.hvac_fields.SUPPLY,
        this.hvac_fields.RETURN
      ],
      [EQ.MINI_SPLIT.api_name]: [
        this.hvac_fields.TONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.SEER,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.SERIAL,
        this.hvac_fields.NEED_ELECTRICAL,
        this.hvac_fields.AH_QTY,
      ],
      [EQ.CONDENSER.api_name]: [
        this.hvac_fields.TONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.hvac_fields.SEER,
        this.hvac_fields.MAKE,
        this.hvac_fields.MODEL,
        this.hvac_fields.NEED_ELECTRICAL,
      ],
      [EQ.R6.api_name]: [
        this.hvac_fields.SUPPLY,
        this.hvac_fields.RETURN,
        this.hvac_fields.ENTIRELY_NEW_DUCT,
      ],
      [EQ.R8.api_name]: [
        this.hvac_fields.SUPPLY,
        this.hvac_fields.RETURN,
        this.hvac_fields.ENTIRELY_NEW_DUCT,
      ],
      [EQ.R10.api_name]: [
        this.hvac_fields.SUPPLY,
        this.hvac_fields.RETURN,
        this.hvac_fields.ENTIRELY_NEW_DUCT,
      ],
    };

    if(!equipment) return null;

    return jobDisplayUtils.getEquipment({
      equipment: equipment,
      fieldOrder: eq_ordered_fields,
      count: eq_count,
      print: print
    });
  };

  getElectrical = (details, print=false) => {
    // console.log('GETTING EL', details);
    const electrical_fields = [
      this.hvac_fields.AC_AIR_HOOKUP,
      this.hvac_fields.AC_CIR,
      allConstants.JOB_FIELDS.ELECTRICAL.SERVICE_UPGRADE,
      this.hvac_fields.DEDICATED_CIR,
      allConstants.JOB_FIELDS.ELECTRICAL.LIGHTS,
      allConstants.JOB_FIELDS.ELECTRICAL.SWITCHES,
      allConstants.JOB_FIELDS.ELECTRICAL.OUTLETS
    ];
    if(!details?.[this.hvac_fields.EL_INFO.api_name]) return null;
    const electrical_block = electrical_fields.map((e) => {
      const value = details[this.hvac_fields.EL_INFO.api_name][e.api_name];
      return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e, print), value, print);
    });
    return electrical_block.filter((el)=> el);
  }
  getPlumbing = (details, print=false)=> {
    const pl_data = details?.[this.hvac_fields.PL_INFO.api_name];
    if(!pl_data) return undefined;
    const pl_fields = [
      this.hvac_fields.GAS_LINE,
      this.hvac_fields.NEED_WH
    ];
    let wh_fields = [];
    let wh_api;
    if(pl_data[this.hvac_fields.NEED_WH.api_name]) {
      pl_fields.push(this.hvac_fields.WH_TYPE);
      if(Utils.equalsIgnoreCase(pl_data[this.hvac_fields.WH_TYPE.api_name], 'traditional')) {
        wh_api = allConstants.EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name;
        wh_fields = [allConstants.JOB_FIELDS.PLUMBING.GALLONS,
          allConstants.JOB_FIELDS.SHARED.ACTION,
          allConstants.JOB_FIELDS.SHARED.LOCATION,
          allConstants.JOB_FIELDS.PLUMBING.FUEL_TYPE,
          allConstants.JOB_FIELDS.PLUMBING.ENERGY_FACTOR,
          allConstants.JOB_FIELDS.PLUMBING.INS_R_VALUE,
          allConstants.JOB_FIELDS.PLUMBING.BTU_INPUT,
          allConstants.JOB_FIELDS.MECHANICAL.GAS_LINE_FT
        ];
      }
      if(Utils.equalsIgnoreCase(pl_data[this.hvac_fields.WH_TYPE.api_name], 'tankless')){
        wh_api = allConstants.EQUIPMENT.WATER_HEATER_TANKLESS.api_name;
        wh_fields = [allConstants.JOB_FIELDS.SHARED.ACTION,
          allConstants.JOB_FIELDS.SHARED.LOCATION,
          this.hvac_fields.GAS_LINE_FT,
          allConstants.JOB_FIELDS.SHARED.GAS_TO_METER,
          allConstants.JOB_FIELDS.SHARED.IS_ELECTRICAL,
        ];
      }
    }
    const pl_block = pl_fields.map((e) => {
      const value = details[this.hvac_fields.PL_INFO.api_name][e.api_name];
      const key =jobDisplayUtils.getFormattedKey(e, print);
      return jobDisplayUtils.getRowOutput(key, value, print);
    });
    let wh_block = [];
    if(wh_api) {
      wh_block =  wh_fields.map((e) => {
        const value = details[this.hvac_fields.PL_INFO.api_name]?.[wh_api]?.[e.api_name];
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e, print), value, print);
      });
    }
    return [...pl_block,...wh_block].filter((el)=> el);
  }

  getInsulation = (details, type, print)=> {
    const ins_fields = [
      this.hvac_fields.VALUATION,
      allConstants.JOB_FIELDS.GENERAL.SQFT,
      this.hvac_fields.R_VALUE,
    ];
    const ins_block = ins_fields.map((e) => {
      const value = details?.[this.hvac_fields.INSULATION.api_name]?.[type]?.[e.api_name];
      const key =jobDisplayUtils.getFormattedKey(e, print);
      return jobDisplayUtils.getRowOutput(key, value, print);
    });
    return ins_block.filter((el)=> el);
  }

  getCommercial = (details)=> {
    if(!details || !details[allConstants.JOB_TYPES.COMMERCIAL.api_name]) return undefined;
    const com_fields = [
      this.com_fields.BUILDING_PERMIT_NUMBER,
      this.com_fields.WITNESS_TEST
    ];
    const com_block = com_fields.map((e) => {
      const value = details?.[allConstants.JOB_TYPES.COMMERCIAL.api_name]?.[e.api_name];
      if (Utils.valueIsSpecified(value)) {
        return {[e.label]: value};
      }
      return undefined;
    });
    return com_block.filter((el)=> el);
  }

}

const hvacDisplayUtils = new HvacDisplayUtils();
export {hvacDisplayUtils};

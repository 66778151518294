import {makeStyles} from '@mui/styles';
import {COLORS} from '../../styles/styleConstants';

const useLabelStyles = makeStyles(theme=> {
  return {
    secondary: {
      fontSize: '10px',
      fontWeight: '900',
      transform: 'translate(10px, -2px)',
      color: COLORS.globalBlue,
      zIndex: 1,
      // backgroundColor: 'white',
      padding: '0 7px 0 3px'
    },
    primary: {
      fontSize: '10px',
      fontWeight: '900',
      transform: 'translate(10px, -2px)',
      color: COLORS.globalBlue,
      zIndex: 1,
      // backgroundColor: 'white',
      padding: '0 7px 0 3px'
    }
  };
});

export {useLabelStyles};

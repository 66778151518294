import React from 'react';
import PropTypes from 'prop-types';
import MessageView from '../pages/jobs/jobDetails/views/messageView';
import * as allConstants from '../constants/allConstants';

export const JobDisplayNotes = (props) => {
  const getNotes = ()=> {
    let notes;

    if(props.jobNotes && props.jobNotes.length !== 0) {
      const notesCount = props.jobNotes.length;
      const jobNotes = props.jobNotes;
      notes = jobNotes.map((note, idx) => {
        return <MessageView key={note.id}
          firstName={note.author[allConstants.USER_FIELDS.FIRST_NAME.api_name]}
          isLastChild={idx+1=== notesCount}
          lastName={note.author[allConstants.USER_FIELDS.LAST_NAME.api_name]}
          date={note.createdAt}
          message={note.note}
          role={note.author.role}/>;
      });
    }

    if(!notes || notes.length === 0) return props?.defaultReturn || null;
    return notes;
  };

  const notes = getNotes();
  return (
    <>
      {notes && notes}
    </>
  );
};

JobDisplayNotes.propTypes = {
  jobNotes: PropTypes.shape([
    PropTypes.shape({
      author: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }).isRequired,
      createdAt: PropTypes.string.isRequired,
      note: PropTypes.string.isRequired,
    })
  ]),
  defaultReturn: PropTypes.node,
};

JobDisplayNotes.defaultProps = {
  jobNotes: [],
  defaultReturn: null,
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import {styled} from '@mui/material/styles';
import React from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  margin: '0',
  backgroundColor: STYLE_CONSTANTS.COLORS.white,
  borderRadius: theme.breakpoints.only('xs') ? '8px' : '10px',
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
  width: '100%',

  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{fontSize: '0.9rem'}} />}
    {...props}
  />
))(({theme}) => ({
  backgroundColor: STYLE_CONSTANTS.COLORS.white,
  borderRadius: theme.breakpoints.only('xs') ? '8px' : '10px',
  // borderBottom: '1px solid #DBDDE2',
  boxSizing: 'border-box',

  ' .MuiSvgIcon-root': {
    backgroundColor: '#EBEDF3',
    borderRadius: '50%',
    color: STYLE_CONSTANTS.COLORS.fontPale,
    transform: 'rotate(-90deg) scale(0.9)'
  },
  '&.Mui-expanded': {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    ' .MuiSvgIcon-root': {
      transform: 'rotate(180deg) scale(0.9)'
    }
  },
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    paddingRight: '16px',
    '&.Mui-expanded': {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

    }

  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: '20px 16px 16px 16px',

}));

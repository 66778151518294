import React from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import JobFormAccordionTab from '../../../../pieces/accordion/jobFormAccordionTab';
import PolesAndPanelsBlock from './polesAndPanelsBlock';
import BuilderInfo from './builderInfoFromBlock';
import MiscInfo from './miscFormBlock';
import Equipment from './equipmentBlock';


const TempPowerFormBlock = (props)=> {
  const classes = {...globalUseClasses()};
  const tabs = [
    {
      name: 'temp_power_equipment',
      header: 'Temporary Power',
      content:PolesAndPanelsBlock,
      alwaysOpen: true,
    },
    {
      name: 'equipment_info',
      header: 'Equipment',
      content: Equipment,
    },
    {
      name: 'builder_info',
      header: 'Builder Information',
      content: BuilderInfo,
    },
    {
      name: 'misc_info',
      header: 'Misc Information',
      content: MiscInfo,
    },
  ];

  return (
    <>
      {tabs.map((tab, idx)=> {
        return(
          <JobFormAccordionTab classes={classes}
            key={idx}
            header={tab.header}
            id={`${tab.name}-header`}
            aria_controls={`${tab.name}-content`}
            alwaysOpen={tab.alwaysOpen}
          >{tab.content({...props, ...{
              nested: true, parent: allConstants.JOB_TYPES.TEMPORARY_POWER.api_name},
            }
            )}
          </JobFormAccordionTab>
        );
      })}
    </>
  );
};

export default TempPowerFormBlock;

import React from 'react';
import PropTypes from 'prop-types';
import {globalUseClasses} from '../../styles/globalClasses';
import {Grid, Typography} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';


const BlockHeader = (props)=> {
  const classes = globalUseClasses();
  if(!props.header || props.header === '') return null;
  return(
    <>
      <Grid item xs={12} sx={{
        backgroundColor: STYLE_CONSTANTS.COLORS.chartBlue,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px'

      }}>
        <Typography variant={'body1'} className={classes.label_bold} sx={{
          fontSize: {xs: '14px', sm: '16px'},
          color: STYLE_CONSTANTS.COLORS.globalBlue,
        }}>{props.header}</Typography>
      </Grid>
    </>
  );
};

BlockHeader.propTypes = {
  header: PropTypes.string,
};

BlockHeader.defaultProps = {
  header: null
};

export {BlockHeader};

import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Button} from '@mui/material';
import {Box} from '@mui/system';
import Utils from '../../../../utils/utils';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {useMessageViewStyles} from './messageViewStyles';
import * as allConstants from '../../../../constants/allConstants';
import IpermitIcon from '../../../../static/images/ipermitIcon.svg';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function MessageView(props) {

  const classes = {...globalUseClasses(), ...useMessageViewStyles()};

  return (
    <Box display={'flex'} gap={2} p={1} sx={{
      paddingBottom: props.isLastChild ? '35px': 'inherit'
    }}>
      <Box minWidth={'38px'} textAlign={'center'} >

        <Box width={'fit-content'} justifyContent={'end'} className={classes.messageIcon}>
          {Object.values(allConstants.IPERMIT_ROLES).includes(props.role) || (props.role && props.role.toLowerCase() === 'ipermit') ?
            <img src={IpermitIcon} alt={'ipermit user'}/> :  <Typography className={[classes.text]} fontSize={'18px'}>
              {Utils.getInitials(props)}
            </Typography>}
        </Box>

      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Box display={'flex'} flexDirection={'column'} gap={0.5}>
          <Box display={'flex'} flexDirection={'row'} gap={1}>
            <Box
              display={'flex'}
              className={classes.messageBorder}>
              <Typography p={1}
                sx={{
                  textTransform: 'uppercase',
                  letterSpacing: '-0.02em',
                  lineHeight: '20px',
                  color: STYLE_CONSTANTS.COLORS.fontMain,
                  fontSize: '12px'
                }}>
                {props.message}
              </Typography>
            </Box>

          </Box>
          <Typography
            className={classes.text}
            sx={{
              fontSize: '12px',
              color: STYLE_CONSTANTS.COLORS.fontPale,
            }}>
            {props.firstName} {props.lastName} - {Utils.formatIsoDateString(props.date)}
          </Typography>

        </Box>
      </Box>
      {
        (props.onClick !== undefined) &&
            <Button
              onClick={() => {props.onClick(props.fileId);}}
              sx={{
                minWidth: 'fit-content',
                minHeight: 'fit-content',
                marginLeft: 'auto',
              }}>
              <FileDownloadIcon/>
            </Button>
      }
    </Box>
  );
}

MessageView.propTypes = {
  fileId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  date: PropTypes.string,
  message: PropTypes.string,
  role: PropTypes.string,
  isLastChild: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default MessageView;

import * as Yup from 'yup';

const tokenValidator = async (tokenPair)=> {
  const validationSchema = Yup.object().shape({
    accessToken: Yup.object().shape({
      token: Yup.string('[token]:: Incorrect format').required('Missing refresh token.'),
      expiresAtMs: Yup.string().required('Missing refresh token.').matches(/^[1-9]\d*$/, 'expiresAtMs is incorrect'),
    }),
    refreshToken: Yup.string()
      .required('Missing refresh token.')

  });

  try {
    const result = await validationSchema.validate(tokenPair);
    return result;
  } catch (error) {
    return error;
  }


};

export default tokenValidator;
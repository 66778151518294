import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import myTheme from '../../../styles/myStyles';

const useCreateJobStyles = makeStyles(theme=> {
  return {
    action_col:{
      width: '165px'
    },
    gas_col: {
      width: '155px'
    },
    electrical_col: {
      width: '100px'
    },
    eq_checkbox_input: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      '& .MuiCheckbox-root': {
        paddingBottom: '12px',
        '& .MuiTypography-root': {
          color: STYLE_CONSTANTS.COLORS.fontMain
        }
      }
    },
    job_form: {
      '& .MuiFormControl-root': {
        marginBottom: 0,
      },
      '& .MuiFormControlLabel-root': {
        color: '#000'
      },
      '& .MuiInputBase-input, & .MuiSelect-select': {
        height: '1em',
        minHeight: '1em',
        display: 'flex',
        alignItems: 'center',
        '&:focus': {
          marginBottom: '6px',
          height: '1em',
          minHeight: '1em',
          display: 'flex',
          alignItems: 'center',
        }
      }
    },
    general_col: {
      display: 'flex',
      flexDirection: 'column',

      boxSizing: 'content-box',
      maxWidth: '500px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px',
        boxSizing: 'border-box'
      },


    },
    block_header: {
      fontSize: '20px'
    },
    create_job_two_col_container: {
      [myTheme.breakpoints.up('md')]: {
        display: 'flex',
        gap: theme.spacing(3),
      },
      [myTheme.breakpoints.up('xl')]: {
        display: 'flex',
        gap: theme.spacing(4),
      }
    },
    tab_container: {
      paddingTop: '0px',
      padding: theme.spacing(2),
    },
    row_wrapper:{
      display: 'flex',
      justifyContent:'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '2.5em',
      '& .MuiInputBase-input': {
        padding: '4px'
      }
    },
    equipment:{
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      minHeight: '2.5em',
      height: 'auto',
      // marginTop: '16px',
      // marginBottom: '24px',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    equipment_wrapper: {
      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    water_heater_tankless: {
      maxWidth: {xs: '100%', md: '600px',}
    },
    // water_heater_traditional: {
    //   maxWidthwidth: {xs: '100%', md: '800px',  lg: '1200px'}
    // },
    small_input_box: {
      '& .MuiInputBase-root': {
        width: '100px',
        marginRight: '20px'
      }
    },
    normal_input_box: {
      '& .MuiInputBase-root': {
        width: 'initial',
        marginRight: '20px'
      }
    },
    large_input_box: {
      width: '150px'
    }

  };
});

export {useCreateJobStyles};

import React from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {Box, Grid, Typography} from '@mui/material';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {useCreateJobStyles} from '../createJobStyles';
import {CustomMediaQuery} from '../../../../hooks/customMediaQuery';
import Utils from '../../../../utils/utils';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {CustomInput} from '../../../../pieces/inputs/customInputComponent';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles(theme=> {
  return {
    fixture_col: {
      width: '130px'
    },
    table_header: {
      // height: '3.5em',
      background: STYLE_CONSTANTS.COLORS.backgroundMain,
      '& .MuiTypography-root': {
        fontSize: '12px'
      }

    },
    qty_col: {
      display: 'flex',
      justifyContent: 'center',
      width: '80px'
    }
  };
});

const RowElement = ({field, defaultValue, countEquipment})=> {
  const classes = {...useCreateJobStyles(), ...useStyles()};
  return(
    <Box display={'flex'}>
      <Box className={classes.row_wrapper}>
        <Box className={classes.fixture_col}>
          <Typography variant={'body1'} fontSize={'14'}>{field.label}</Typography>
        </Box>
        <Box className={classes.qty_col}>
          <CustomInput
            onChange={(e)=>countEquipment(e, field.api_name)}
            transform={(val)=> val.parseInt()}
            defaultValue={defaultValue}
            inputProps={{min: 0} }
            type="number"/>
        </Box>
      </Box>
    </Box>

  );
};

RowElement.propTypes = {
  field: PropTypes.shape({
    api_name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  countEquipment: PropTypes.func.isRequired,
  defaultValue: PropTypes.any
};
RowElement.defaultProps = {
  defaultValue: null,
};


export const Commercial = (props)=> {
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const FIELDS = allConstants.JOB_FIELDS.COMMERCIAL;
  const formController = useFormContext();
  const values = formController.getValues();
  const blockParent = props?.blockParent ? props.blockParent : `${allConstants.JOB_TYPES.COMMERCIAL.api_name}`;

  const fixtures = [
    FIELDS.EQUIPMENT.EXHAUST_FANS,
    FIELDS.EQUIPMENT.VENT_FANS,
    FIELDS.EQUIPMENT.REG_GRILLS,
    FIELDS.EQUIPMENT.SMOKE_DAMP,
    FIELDS.EQUIPMENT.FIRE_DAMP,
    FIELDS.EQUIPMENT.SMOKE_FIRE_DAMP,
    FIELDS.EQUIPMENT.DUCT_SMOKE_DET,
  ];
  const countEquipment = (e, name)=> {
    const formValues = formController.getValues();
    let newValue = {};

    const fixtures = values?.[allConstants.JOB_TYPES.COMMERCIAL.api_name];

    if(fixtures && Utils.objectHasProperty(fixtures, allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name)) {

      newValue = formValues?.[allConstants.JOB_TYPES.COMMERCIAL.api_name]?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    }
    newValue = {...newValue, ...{[name]: e.target.value}};
    formController.setValue(`${blockParent}.${[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]}`, newValue, {shouldDirty: true});
  };

  // console.log('equ count default', countEquipment(null, allConstants.JOB_FIELDS.PLUMBING.FIXTURES.LAVATORIES.api_name))

  const getCurrentValue = (fixture)=> {
    const currValues = formController.getValues();
    if(!Utils.objectHasProperty(currValues?.[allConstants.JOB_TYPES.COMMERCIAL.api_name], `${[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]}`)) {
      return null;
    }
    const fixtures = currValues?.[allConstants.JOB_TYPES.COMMERCIAL.api_name]?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];
    if(!Utils.objectHasProperty(fixtures, fixture)) return null;
    return fixtures[fixture];
  };

  const getColumns = ()=> {
    const queries = CustomMediaQuery();
    let count = 1;
    if(queries.isSM) count = 2;
    if(queries.isMD) count = 3;
    if(queries.isXL || queries.isLG) count = 3;
    return Utils.chunkify(fixtures, count);
  };

  // console.log('FIXTURES', fixtures)

  const renderColumns = getColumns();

  return (
    <Box className={classes.tab_container}>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4} lg={3} display={'flex'} flexDirection={'column'}>
          <Box maxWidth={'200px'}>
            <CustomFormBlock
              {...props}
              field={FIELDS.BUILDING_PERMIT_NUMBER}
              nested={true}
              parent={blockParent}
              defaultValue={values?.[`${blockParent}`]?.[FIELDS.BUILDING_PERMIT_NUMBER.api_name]}
              fullWidth/>
          </Box>
          <Box maxWidth={'350px'}>
            <CustomFormBlock
              {...props}
              inputType={inputTypes.multiSelect}
              field={FIELDS.WITNESS_TEST}
              options={Utils.getOptions(allConstants.WITNESS_TEST_OPTIONS)}
              nested={true}
              parent={blockParent}
              defaultValue={values?.[`${blockParent}`]?.[FIELDS.WITNESS_TEST.api_name]}
              fullWidth/>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} display={'flex'} flexDirection={{xs: 'column', sm: 'row'}}
          sx={{
            '& .MuiInputBase-root textarea': {
              width: '100%',
              maxWidth: '500px'
            }
          }}
        >
          <Box sx={{
            // width:{sx: '100%', sm: '150px'},
            marginRight: theme=>theme.spacing(2),
            paddingTop: '5px',
          }}>
            <Typography component={'p'} fontSize={{xs: '14px', md: '16px'}}>{allConstants.JOB_FIELDS.GENERAL.SCOPE.label}</Typography>
          </Box>
          <CustomFormBlock
            {...props}
            renderLabel={false}
            multiline={true}
            field={allConstants.JOB_FIELDS.GENERAL.SCOPE}
            nested={true}
            parent={blockParent}
            defaultValue={values?.[`${blockParent}`]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name]}
            fullWidth/>
        </Grid>
        <Grid item xs={12}>
          <Typography component={'p'} className={classes.block_header}>Equipment</Typography>
          <Box width={'100%'} maxWidth={'800px'}>
            <Box className={clsx(classes.row_wrapper, classes.table_header)}>
              {renderColumns[0].map((field, idx)=> {
                return (
                  <Box key={`${idx}-${field.api_name}`} display={'flex'}>
                    <Box className={clsx(classes.fixture_col)}>
                      <Typography variant={'body1'} fontSize={'12'}>Equipment</Typography>
                    </Box>
                    <Box className={classes.qty_col}>
                      <Typography variant={'body1'}>QTY</Typography>
                    </Box>
                  </Box>
                );})}
            </Box>

            {renderColumns.map((row, index)=> {
              return (
                <Box className={classes.row_wrapper} key={index+JSON.stringify(row)}>
                  {row.map((field, idx)=> {
                    return <RowElement
                      key={`${index}-${idx}-${field.api_name}`}
                      field={field}
                      defaultValue={getCurrentValue(field.api_name)}
                      countEquipment={countEquipment}
                    />;})}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>

    </Box>
  );
};

Commercial.propTypes = {
  parent: PropTypes.string.isRequired,
  blockParent: PropTypes.string,

};

Commercial.defaultProps = {
  blockParent: null,
};

export default Commercial;

import PropTypes from 'prop-types';

const formBlockTypes = {
  viewMode: PropTypes.bool,
  renderLabel: PropTypes.bool,
  nested: PropTypes.bool,
  parent: (props, propName, componentName)=> {
    if ((props['nested'] === true && (props[propName] === undefined || typeof (props[propName]) !== 'string'))) {
      return new Error('Parent is required');
    }},
  isEditable: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
  field: PropTypes.instanceOf(Object).isRequired,
  defaultValue: PropTypes.any,
  fullWidth: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  multiline: PropTypes.bool,
  preventCapitalize: PropTypes.bool,
  rowCount: PropTypes.number,
  transform: PropTypes.func,
  canClean: PropTypes.bool,
  hideHelper: PropTypes.bool,
  type: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  showAsStatic: PropTypes.bool,
};

const formBlockDefaultProps = {
  viewMode: false,
  renderLabel: true,
  nested: false,
  parent: undefined,
  inputType: 'text',
  defaultValue: undefined,
  fullWidth: false,
  preventCapitalize: false,
  multiline: false,
  shouldValidate: false,
  rowCount: 5,
  transform: null,
  canClean: false,
  hideHelper: false,
  type: 'text',
  hidePlaceholder: false,
  showAsStatic: false,
};

export {formBlockTypes, formBlockDefaultProps};

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import {Box, Grid} from '@mui/material';
import {useForm} from 'react-hook-form';
import CustomFormBlock  from '../../../../pieces/inputs/formBlock';
import {makeStyles} from '@mui/styles';
import actions from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(()=> {
  return {
    row_input: {
      width: '240px',
      marginRight: '20px',
      '& .MuiInputBase-root': {
        width: 'fit-content'
      }
    }
  };
});


export const SolarFormBlock = (props)=> {
  const dispatch = useDispatch();
  const parent = allConstants.JOB_TYPES.SOLAR.api_name;
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const SOLAR_FIELDS = allConstants.JOB_FIELDS.SOLAR;
  const formOptions = {
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const subforms = useSelector(state => state.subforms);
  //console.log('subforms', {...subforms});
  const savedForm = subforms?.[allConstants.JOB_TYPES.SOLAR.api_name]?.solar;
  if(savedForm) {
    formOptions.defaultValues = {...savedForm};
  }

  const formMethods = useForm(formOptions);


  useEffect(()=> {
    return ()=> {
      const currValues = formMethods.getValues();
      dispatch(actions.addSubform({jobType: allConstants.JOB_TYPES.SOLAR.api_name, subformName: 'solar',subform: currValues}));
    };

  },[]);

  window.solar_form = formMethods;

  return (
    <Box className={classes.tab_container}>
      <Grid container>
        <Grid item xs={12}>
          <Box display={'flex'} columnGap={4} flexDirection={{xs:'column', sm:'row'}} alignItems={{xs:'left', sm:'center'}}>
            <CustomFormBlock{...props}
              field={SOLAR_FIELDS.TOTAL_KW}
              nested={true}
              parent={parent}
              renderLabel={true}
              type={'number'}
            />
            <CustomFormBlock{...props}
              field={SOLAR_FIELDS.PANELS}
              nested={true}
              parent={parent}
              renderLabel={true}
              type={'number'}
            />
            <CustomFormBlock{...props}
              field={SOLAR_FIELDS.INVERTERS}
              nested={true}
              parent={parent}
              renderLabel={true}
              type={'number'}
            />
            <CustomFormBlock{...props}
              field={SOLAR_FIELDS.MODULES}
              nested={true}
              parent={parent}
              renderLabel={true}
              type={'number'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display={'flex'} flexDirection={{xs:'column',md:'row'}} >
          <Box>
            <CustomFormBlock{...props}
              field={allConstants.JOB_FIELDS.GENERAL.SCOPE}
              multiline={true}
              nested={true}
              parent={parent}
              renderLabel={true}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

SolarFormBlock.propTypes = {
  setEquipmentForm: PropTypes.func.isRequired,
  parent: PropTypes.string.isRequired,
  watch: PropTypes.func.isRequired,
};

export default SolarFormBlock;

import React from 'react';
import PropTypes from 'prop-types';
import {COLORS} from '../../styles/styleConstants';
import {Box, Typography, Grid, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalWrapper from './modalWrapper';
import FormSubmitButtons from '../formSubmitButtons';
import ApiUtils from '../../utils/apiUtils';
import {toast} from 'react-toastify';
import DeleteIcon from '../../static/images/delete-icon.png';

const ConfirmDelete = (props)=> {

  const handleSubmitResponse = (result)=> {
    if(result.status === 'success') {
      if(props?.handleCancel) {
        //close modal window
        props.handleCancel();
      }
      toast.success('Product successfully deleted. Please refresh page to see changes.', {
        toastId: 'success_delete_product',
        autoClose: 1000,
        onClose: () => {
          try{
            if(props.successAction) {
              props.successAction();
            }
          } catch (e){}

        }
      });
    } else{
      toast.error(`Error on product delete. ${result?.message ? `Message: ${result.message}.` : ''}`, {
        toastId: 'error_delete_product',
      });
    }
  };

  const deleteAction = async ()=> {
    if(!props?.idToDelete) return;
    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }
    const response = await fetch(
      `${props.deleteUrl}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify({
          id: props.idToDelete
        })
      }
    );
    let submitted = await response.json();
    handleSubmitResponse(submitted);
  };

  return (
    <ModalWrapper modalName={'delete-product'}
      handleClose={props.handleCancel}
      open={props.open}
      preventScroll={props.preventScroll}
      customStyle={{maxWidth: '500px'}}
      width={{sx: '98%', md: '60%'}}>
      <Grid container alignItems={'baseline'} position={'relative'}>
        <Box position={'absolute'} top={5} right={0}>
          <IconButton
            sx={{backgroundColor:COLORS.backgroundMain}}
            onClick={props.handleCancel}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Grid item xs={12} display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          textTransform={'uppercase'}
          fontSize={'16px'}
          mb={4}
        >
          <Box onDragStart={(e)=> e.preventDefault()}
            mb={2}

          >
            <img src={DeleteIcon} alt={'delete-icon.png'}/>
          </Box>
          <Typography variant={'body1'}
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: COLORS.globalRed,
              marginBottom: 2,
            }}
          >{`delete ${props.entityName}`}</Typography>
          <Typography variant={'body1'}
            sx={{lineHeight: '2em',  fontWeight: 600,}}>
              Are you sure you want to delete
            <Typography component={'span'}
              fontWeight={'inherit'}
              fontSize={'inherit'}
              color={COLORS.globalBlue}>
              {` ${props.recordName}`}
            </Typography>?
          </Typography>
          <Typography variant={'body1'}
            sx={{lineHeight: '2em',  fontWeight: 600,}}>
            {'This action can’t be undone.'}</Typography>

        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'center'}>
            <FormSubmitButtons
              submitText={`Yes, delete ${props.entityName}`}
              submitAction={deleteAction}
              cancelAction={props.handleCancel}
              applyDeleteStyles={true}
              revertColors={true}
              noOutlines={true}
              cancelText={`No, keep ${props.entityName}`}/>
          </Box>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

ConfirmDelete.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  successAction: PropTypes.func.isRequired,
  idToDelete: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  recordName: PropTypes.string.isRequired,
  preventScroll: PropTypes.bool,
  deleteUrl: PropTypes.string.isRequired
};
ConfirmDelete.defaultProps = {
  open: false,
  preventScroll: false,
};
export default ConfirmDelete;

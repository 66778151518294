import React, {useEffect} from 'react';
import {useFormContext, useFieldArray} from 'react-hook-form';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import * as allConstants from '../../../constants/allConstants';
import Grid from '@mui/material/Grid';
import {Box, Card, CardContent, List, Typography, ListItem} from '@mui/material';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import {AddRowButton, DeleteEquipmentRowButton,variants} from '../../../pieces/addDeleteEquipmentRowButtons';
import base_url from '../../../baseUrls';
import {companyPropTypes, companyDefaultProps} from './panelProps';
import {NotAllowedToEditSection} from './notAllowedToEditSection';

export const CompanyFinancial = (props)=> {
  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  const PRODUCT_FIELDS = allConstants.PRODUCT_FIELDS;
  const formMethods = useFormContext();

  const isIpermitInvite = props.tabProps.isIpermitInvite;

  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };

  const companyData = getCompanyData();


  const watchPaymentMethod = formMethods.watch(COMPANY_FIELDS.PAYMENT_METHOD.api_name);

  useEffect(()=> {
    if(watchPaymentMethod === allConstants.PAYMENT_METHODS.CREDIT_CARD.value) {
      const currValues = formMethods.getValues();
      const currPaymentFee = currValues[COMPANY_FIELDS.PAYMENT_FEE.api_name];
      formMethods.setValue(allConstants.COMPANY_FIELDS.PAYMENT_FEE.api_name, currPaymentFee || 3);
    }
  }, [watchPaymentMethod]);

  const getFileDefault = (field, parent)=> {
    const savedFile = sessionStorage.getItem(`${parent}.${field}`);
    const savedName = sessionStorage.getItem(`${parent}.${field}_name`);
    if(savedFile) {
      return {id: 'file ready to upload', name: savedName};
    } else {
      const formData =  getDefaultValue(parent);
      return {
        id: formData?.[field] || formData?.fileId,
        name: formData?.fileName
      };
    }
  };

  const getDefaultValue = (field, parent = null) => {
    if (parent) {
      return companyData?.[parent]?.[field];
    }
    return companyData?.[field];
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%',
  };

  const productsCollectionName = COMPANY_FIELDS.PRICE_LIST.api_name;

  const productRow = {
    [PRODUCT_FIELDS.NAME.api_name]: '',
    [PRODUCT_FIELDS.PRICE.api_name]:'',
    [PRODUCT_FIELDS.CODE.api_name]:'',
    [PRODUCT_FIELDS.ID.api_name]: null,
  };

  const {fields, append} = useFieldArray({
    control: formMethods.control,
    name: productsCollectionName,
  });

  const productSubformDefaultProps = (index) => ({
    defaultValue: '',
    isEditable: true,
    nested: true,
    fullWidth: true,
    parent: `${productsCollectionName}[${index}]`
  });


  const deletePriceListRow = (index) => {
    try {
      const priceList = [...formMethods.getValues()?.[allConstants.COMPANY_FIELDS.PRICE_LIST.api_name]];
      priceList.splice(index, 1);
      formMethods.setValue(allConstants.COMPANY_FIELDS.PRICE_LIST.api_name, priceList);
    } catch(e){

    }
  };

  return (
    <>
      <Grid container width={'100%'} spacing={3}>
        <NotAllowedToEditSection show={props.tabProps.notAllowedToEdit}/>
        <Grid item sm={12} md={6} flex={1}>
          <Card className={classes.card_block}>
            <CardContent className={classes.half_width}>
              <Box className={classes.two_col_container}
                sx={{
                  gridTemplateColumns: '3fr 1fr !important'
                }}
              >
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={{...COMPANY_FIELDS.PAYMENT_METHOD, ...{required: !isIpermitInvite}}}
                  options={Utils.getOptions(allConstants.PAYMENT_METHODS)}
                  inputType={inputTypes.singleSelect}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.PAYMENT_METHOD.api_name)}
                  // isEditable={true}

                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.PAYMENT_FEE}
                  inputType={inputTypes.number}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.PAYMENT_FEE.api_name)}
                  // isEditable={true}

                />
              </Box>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.CLASSIFICATION}
                defaultValue={getDefaultValue(COMPANY_FIELDS.CLASSIFICATION.api_name)}
                // isEditable={true}

              />
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                inputType={inputTypes.file}
                field={COMPANY_FIELDS.PAYMENT_AUTH_FORM_FILE}
                nested={true}
                parent={COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name}
                defaultValue={getFileDefault(COMPANY_FIELDS.PAYMENT_AUTH_FORM_FILE.api_name, COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name)}
                // isEditable={true}

              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} flex={1}>
          <Card className={classes.card_block} sx={{height: '100%'}}>
            <CardContent className={classes.half_width}>
              <Box className={classes.two_col_container}
                sx={{
                  gridTemplateColumns: '3fr 1fr !important'
                }}
              >
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.QB_EMAIL}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.QB_EMAIL.api_name)}
                  // isEditable={true}

                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.QB_ID}
                  inputType={inputTypes.number}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.QB_ID.api_name)}
                  // isEditable={true}

                />
              </Box>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.CREDIT_LIMIT}
                defaultValue={getDefaultValue(COMPANY_FIELDS.CREDIT_LIMIT.api_name)}
                // isEditable={true}

              />
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.ACCOUNTING_CONTACT}
                defaultValue={getDefaultValue(COMPANY_FIELDS.ACCOUNTING_CONTACT.api_name)}
                // isEditable={true}

              />
              <Box display={'flex'} justifyContent={'space-between'}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  renderLabel={false}
                  labelLeft={true}
                  field={COMPANY_FIELDS.CITY_INSPECTION}
                  inputType={inputTypes.checkbox}
                  disabled={!props.inputDefaultProps.isEditable}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.CITY_INSPECTION.api_name)}
                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  renderLabel={false}
                  labelLeft={true}
                  field={COMPANY_FIELDS.CHARGE_INITIAL_HERS}
                  inputType={inputTypes.checkbox}
                  disabled={!props.inputDefaultProps.isEditable}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.CHARGE_INITIAL_HERS.api_name)}
                />
              </Box>

            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} flex={1}>
          <Card className={classes.card_block}>
            <CardContent>
              <Box mb={2}>
                <Typography className={classes.eq_name}>Price list</Typography>
              </Box>
              <List>
                <ListItem sx={{
                  display: 'grid',
                  gridTemplateColumns: '2fr 1fr 1fr 0.5fr',
                  gap: '16px',
                  alignItems: 'flex-start',
                  textTransform: 'uppercase'
                }}>
                  <Typography>ipermit services</Typography>
                  <Typography>price ($)</Typography>
                  <Typography>product code</Typography>
                </ListItem>

              </List>
              <form>
                <List>
                  {fields.map((item, index) => (
                    <ListItem key={item.id} sx={{
                      display: 'grid',
                      gridTemplateColumns: '2fr 1fr 1fr 0.5fr',
                      gap: '16px',
                      alignItems: 'flex-start'
                    }}>

                      <CustomFormBlock
                        {...props.inputDefaultProps}
                        {...commonFieldProps}
                        {...productSubformDefaultProps(index)}
                        field={PRODUCT_FIELDS.NAME}
                        inputType={inputTypes.autoComplete}
                        autocompleteParams={{
                          url: `${base_url.api}products/autocomplete`,
                          getRequestParams: (value)=> {
                            if(value) {
                              return {
                                name: value
                              };
                            }
                          },
                          collectionName: productsCollectionName,
                          fill: [PRODUCT_FIELDS.PRICE, PRODUCT_FIELDS.CODE, PRODUCT_FIELDS.ID],
                          filter: (options)=> {
                            const existingRows = formMethods.getValues()?.[allConstants.COMPANY_FIELDS.PRICE_LIST.api_name];
                            if(!existingRows) return options;
                            const existingProducts = existingRows.map((row)=> row[PRODUCT_FIELDS.NAME.api_name]);
                            return options.filter((option)=> !existingProducts.includes(option[PRODUCT_FIELDS.NAME.api_name]));
                          }
                        }
                        }

                      />
                      <CustomFormBlock
                        {...props.inputDefaultProps}
                        {...commonFieldProps}
                        {...productSubformDefaultProps(index)}
                        field={PRODUCT_FIELDS.ID}
                        hide={true}

                      />

                      <CustomFormBlock
                        {...props.inputDefaultProps}
                        {...commonFieldProps}
                        {...productSubformDefaultProps(index)}
                        field={PRODUCT_FIELDS.PRICE}

                      />

                      <CustomFormBlock
                        {...props.inputDefaultProps}
                        {...commonFieldProps}
                        {...productSubformDefaultProps(index)}
                        field={PRODUCT_FIELDS.CODE}
                        isEditable={false}

                      />
                      {!props.inputDefaultProps.viewMode && <DeleteEquipmentRowButton eqName={'products'}
                        extraStyles={{alignSelf: 'baseline',
                          top: '8px',
                          position: 'relative',
                          padding: '0'
                        }}
                        deleteAction={() => deletePriceListRow(index)}
                      />}
                    </ListItem>
                  ))}
                </List>
                {!props.inputDefaultProps.viewMode && props.inputDefaultProps.isEditable &&
                <AddRowButton eqName={'products'} variant={variants.textWithIcon}
                  addAction={() => {
                    append({...productRow});
                  }}
                />}
              </form>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

CompanyFinancial.propTypes = companyPropTypes;
CompanyFinancial.defaultProps = companyDefaultProps;

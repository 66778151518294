import React from 'react';
import {Box} from '@mui/material';
import TabsBlock from '../../../../pieces/tabsBlock';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import {RepairDrainsPanel} from './panels/repairDrainsPanel';
import {CleanOutsPanel} from './panels/cleanOutsPanel';
import {RepairSewerPanel} from './panels/repairSewerPanel';
import {SpotRepairPanel} from './panels/spotRepairsPanel';
import {LinersPanel} from './panels/linersPanel';

export const Repair = (props)=> {
  const classes = useCreateJobStyles();
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;

  const panels = {
    [FIELDS.REPAIR_DRAIN_LINE.api_name]: RepairDrainsPanel,
    [FIELDS.REPAIR_SEWER.api_name]: RepairSewerPanel,
    [FIELDS.CLEAN_OUTS.api_name]: CleanOutsPanel,
    [FIELDS.LINERS.api_name]: LinersPanel,
    [FIELDS.SPOT_REPAIR.api_name]: SpotRepairPanel,
  };

  const fields = [
    FIELDS.REPAIR_DRAIN_LINE, FIELDS.REPAIR_SEWER, FIELDS.CLEAN_OUTS, FIELDS.LINERS, FIELDS.SPOT_REPAIR
  ];

  const content = fields.map((f)=> {

    return {name: f.api_name, label: f.label, panel: ()=> panels[f.api_name](props)};
  }
  );
  return (
    <Box className={classes.tab_container}>
      <Box display={'flex'}>
        <TabsBlock content={content}/>
      </Box>
    </Box>
  );
};

export default Repair;

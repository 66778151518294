import AnalyticsPageUtils from './analyticsPageUtils';
import base_url from '../baseUrls';

const awsUtils = {
  reformatCompanyName: (name)=> {
    if(!name) return '';
    return name.replace(/&/, '%26');
  },
  /**
   *
   * @param {string} type
   * @param {Object|undefined} params - additional query params
   * @param {string} company - Company name
   * @return {string} - api url request string
   */
  createUrl: ({type, params, company})=> {
    const other_params = params ? `&${AnalyticsPageUtils.combineParams(params)}` : '';
    const url = `${base_url.aws_api}/query?queryType=${type}&company=${awsUtils.reformatCompanyName(company)}${other_params}`;
    return url;
  }
};


export {awsUtils};

const TONS_OPTIONS = {
  BASE: {
    '5': {display: '5', value: '5', showOrder: 0, def: false},
    '4.5': {display: '4.5', value: '4.5', showOrder: 1, def: false},
    '4': {display: '4', value: '4', showOrder: 2, def: false},
    '3.5': {display: '3.5', value: '3.5', showOrder: 3, def: false},
    '3': {display: '3', value: '3', showOrder: 4, def: false},
    '2.5': {display: '2.5', value: '2.5', showOrder: 5, def: false},
    '2': {display: '2', value: '2', showOrder: 6, def: false},
    '1.5': {display: '1.5', value: '1.5', showOrder: 7, def: false},
    '1': {display: '1', value: '1', showOrder: 8, def: false},
  },
  ADDITIONAL: {
    '3/4': {display: '3/4', value: '3/4', showOrder: 9, def: false},
  }

};

export {TONS_OPTIONS};
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';


const PrevendAdminDeactivate = (props)=> {
  const onCancelEdit = (e)=> {
    e.preventDefault();
    props.closeModal();
  };


  return(
    <>
      <Grid container xs={12}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant={'body1'}
            fontSize={{xs: '18px', lg: '20px'}}
            mb={2}
          >
            You are going to deactivate admin

            <Typography component={'span'} fontSize={{xs: '18px', lg: '20px'}} color={STYLE_CONSTANTS.COLORS.globalBlue}>
              {` ${props?.selectedUser?.firstName} ${props?.selectedUser?.lastName}`}
            </Typography>
            .
          </Typography>
          <Typography variant={'body1'}
            fontSize={{xs: '18px', lg: '20px'}}
            mb={2}
          >
            {'Please assign another user as admin first.'}</Typography>
          <Typography>

          </Typography>
        </Grid>
        <Grid xs={12}>
          <FormSubmitButtons submitText={'Ok'} submitAction={onCancelEdit} hideCancel />
        </Grid>
      </Grid>
    </>
  );
};
PrevendAdminDeactivate.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired

};
export default PrevendAdminDeactivate;

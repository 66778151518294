import {ELEMENTS_PERMITS, permitRules} from './permitsAccessControl';
import {ELEMENTS_INSPECTIONS, inspectionRules} from './inspectionsAccessControl';
import {ELEMENTS_CF1R, cf1rRules} from './cf1rAccessControl';
import {ELEMENTS_NOTES, notesRules} from './notesAccessControl';
import {ELEMENTS_ATTACHMENTS, attachmentRules} from './attachmentsAccessControl';

const IPERMIT_JOB_ELEMENTS = {
  ...ELEMENTS_PERMITS,
  ...ELEMENTS_INSPECTIONS,
  ...ELEMENTS_CF1R,
  ...ELEMENTS_NOTES,
  ...ELEMENTS_ATTACHMENTS,

};


const jobRules = {
  ...permitRules,
  ...inspectionRules,
  ...cf1rRules,
  ...notesRules,
  ...attachmentRules,

};

const RATER_ELEMENTS = {
  viewRaters: 'viewRaters',
  manageRates: 'manageRates',
};

const raterPolicies = {
  viewRaters: 'viewRaters',
  manageRates: 'manageRates',
};

const raterRules = Object.freeze({
  [RATER_ELEMENTS.viewRaters]: {
    policyName: raterPolicies.viewRaters,
    contractorAccess: false,
  },
  [RATER_ELEMENTS.manageRates]: {
    policyName: raterPolicies.manageRates,
  },
});

export {jobRules, IPERMIT_JOB_ELEMENTS, raterRules, RATER_ELEMENTS};

import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {ANALYTICS_TARGETS} from '../../constants/options/analyticsTargets';
import PermitCost from '../../static/images/permit_cost.svg';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {awsUtils} from '../../utils/awsUtils';
import {AnalyticsCardWrapper} from '../analyticsCardWrapper';
import GettingDataProgress from '../gettingDataProgress';
import NoDataFound from '../noDataFound';
import {AnalyticsBaseCard} from './analyticsBaseCard';

function AnalyticsPermitCost(props) {

  const saveData = (fetchedData)=> {
    if(fetchedData && Array.isArray(fetchedData) && fetchedData[0]){
      const number = fetchedData[0]?.[props.target.response_key] || '0';
      const avgCost = Math.round(number * 100) / 100;
      setData(avgCost);
    } else {
      setData('0');
    }
  };

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);
  return (
    <Box sx={{width: STYLE_CONSTANTS.DEFAULT.ANALYTICS_CARD_HEIGHT}}>
      <AnalyticsBaseCard title={'Average Permit Cost'}>
        <AnalyticsCardWrapper
          target={ANALYTICS_TARGETS.PERMIT_AVG_COST}
          sessionKey='avgCost'
          returnFetchedData={saveData}
          setParentFetching={(status)=>setFetching(status)}
          setParentFound = {(status)=> setFound(status)}
          fetchUrl={awsUtils.createUrl({
            type: props.target.api_request,
            company: props.company,
            params: props.params,
          })}>
          {fetching && <GettingDataProgress/>}
          {!found && !fetching && <NoDataFound/>}
          {found && !fetching &&
        <>
          <Box display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignContent={'center'}
            width={'60%'} margin={'auto'} height={'100%'}
            sx={{
              transform: 'translateY(-10px)',
              '& img': {
                paddingTop: '20px',
                transform: 'scale(0.8)'
              }
            }}
          >
            <Typography sx={{
              backgroundColor: STYLE_CONSTANTS.COLORS.chartGreen,
              borderRadius: '12px',
              color: STYLE_CONSTANTS.COLORS.globalGreen,
              textAlign: 'center',
              fontSize: '2.5rem',
            }}>{data}</Typography>
            <img src={PermitCost} alt=''/>
          </Box>
        </>}
        </AnalyticsCardWrapper>
      </AnalyticsBaseCard>
    </Box>
  );
}

AnalyticsPermitCost.propTypes = {
  company: PropTypes.string,
  params: PropTypes.objectOf(Object),
  target: PropTypes.objectOf(PropTypes.shape({
    display: PropTypes.string,
    value: PropTypes.string,
    api_request: PropTypes.string,
  })).isRequired,
};

export default AnalyticsPermitCost;

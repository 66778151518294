const ELEMENTS_DOCUMENTS = {
  addInspectionDocument: 'addInspectionNote',
  addPermitDocument: 'addPermitNote',
  addHersDocument: 'addHersNote',
};

const documentsPolicies = {
  addPermitDocument: 'permitDocument.create',
  addInspectionDocument: 'inspectionDocument.create',
  addHersDocument: 'hersDocument.create',
};

const documentRules = Object.freeze({
  [ELEMENTS_DOCUMENTS.addInspectionDocument]: {
    policyName: documentsPolicies.addInspectionDocument,
  },
  [ELEMENTS_DOCUMENTS.addPermitDocument]: {
    policyName: documentsPolicies.addPermitDocument,
  },
  [ELEMENTS_DOCUMENTS.addHersDocument]: {
    policyName: documentsPolicies.addHersDocument,
  }

});

export {documentsPolicies, ELEMENTS_DOCUMENTS, documentRules};

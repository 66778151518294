import Utils from './utils';

class ErrorHandler{
  constructor() {
    this.errorMap = {
      INVALID_EMAIL: 'Please enter a valid email',
      LOGIN_FAILED: 'Email or password does not match.',
      DEFAULT_ERROR: 'Something went wrong.',
      INVALID_PARAMETER: 'Api call failed:: INVALID_PARAMETER',
      INVALID_LINK: 'Invite link is not valid',
      INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
      ERROR_ON_REFRESH_TOKEN: 'ERROR_ON_REFRESH_TOKEN',
      GET_INVALID_TOKEN_PAIR: 'GET_INVALID_TOKEN_PAIR',
      EXPIRED_PASSWORD_RECOVERY_TOKEN: 'Please send another forgot password email.',
      INTERNAL_SERVER_ERROR: 'Something went wrong, please try again.',
      EMAIL_ALREADY_IN_USE: 'A user with the same email address already exists.'
    };
  }

  /**
   *
   * @param {string} code
   * @return {string}
   */
  getErrorMessage(code, defaultError = this.errorMap.DEFAULT_ERROR) {
    if(Utils.objectHasProperty(this.errorMap, code)){
      return this.errorMap[code];
    }
    return defaultError;
  }
  getDefaultError(){
    return this.errorMap.DEFAULT_ERROR;
  }

  getErrorCodes() {
    const codes = {};
    Object.keys(this.errorMap).forEach((key)=> {
      codes[key]=key;
    });
    return codes;
  }

}


const errorHandler = new ErrorHandler();
const ERROR_CODES = errorHandler.getErrorCodes();
export {errorHandler, ERROR_CODES};

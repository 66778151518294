import {makeStyles} from '@mui/styles';

const useCompanyPageStyles = makeStyles(theme=> {
  return {
    welcome_box: {
      fontWeight: '600'
    },

    acc_paper: {
      marginTop: '20px',
    },

    acc_details: {
      borderRadius: '0 0 10px 10px',
      [theme.breakpoints.only('xs')]: {
        borderRadius: '8px'
      },
      //borderRadius: props.isXS ? '8px' : '0 0 10px 10px',
      // backgroundColor:'red',
    },

    padding_left: {
      paddingLeft: theme.spacing(2)
    },
    padding_right: {
      paddingRight:theme.spacing(2)
    }

  };
});

export {useCompanyPageStyles};
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import FilterIcon from '../static/images/filterIcon.svg';
import {
  Box, FormGroup, Grid, IconButton, FormControlLabel,
  Checkbox, Select, MenuItem, FormControl, TextField, Badge, Typography
} from '@mui/material';
import FormSubmitButtons from '../pieces/formSubmitButtons';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {inputTypes} from '../pieces/inputs/formInput';
import {useSearchParams} from 'react-router-dom';
import {COLORS} from '../styles/styleConstants';
import {useAllJobsViewStyles} from '../pages/jobs/allJobs/allJobsViewStyles';

const filterTypes = {
  text: 'pureText',
  number: 'number',
  date: 'date'
};

const createLabel = (textLabel, numberLabel)=> {
  const label = {};
  if(textLabel) {
    label[filterTypes.text] = textLabel;
  }
  if(numberLabel) {
    label[filterTypes.number] = numberLabel;
  }
  return label;
};

const queryTypes =  {
  contains: {value:'contains', label: createLabel('contains')},
  equals: {value:'equals',label: createLabel('is', '=')},
  inRange:  {value:'inrange',label: createLabel('In range')},
  empty: {value:'empty', label:createLabel('is empty', 'is empty')},
  notEmpty: {value:'notEmpty',label:createLabel('is not empty', 'is not empty')},
  lessThan: {value:'lessThan',label: createLabel(null, '<')},
  lessThanOrEquals: {value:'lessThanOrEquals',label: createLabel(null, '<=')},
  greaterThan: {value:'greaterThan', label:createLabel(null, '>')},
  greaterThanOrEquals: {value:'greaterThanOrEquals',label:createLabel(null, '>=')},
  between: {value:'between',label: createLabel(null, 'between')},
};

const queries = {
  [filterTypes.text]: [queryTypes.equals, queryTypes.contains, queryTypes.empty, queryTypes.notEmpty],
  [filterTypes.number]: [queryTypes.equals, queryTypes.lessThan, queryTypes.lessThanOrEquals,
    queryTypes.greaterThan, queryTypes.greaterThanOrEquals, queryTypes.empty, queryTypes.notEmpty],
  [filterTypes.date]:[]
};

const SelectDropdown = (props)=> {

  const options = queries[props.type].map((opt)=> {
    if(!opt.label[props.type]) return null;

    if(props.inputType && props.inputType === inputTypes.singleSelect && opt === queryTypes.contains) return  null;
    return (
      <MenuItem  key={`${opt.value}-${props.api_name}`} value={opt.value}>{opt.label[props.type]}</MenuItem>
    );
  });
  return(
    <FormControl sx={{m: 1, minWidth: 120, width: '120px'}}>
      <Select id={`${props.api_name}-select`}
        value={props.selected}
        onChange={(e)=> props.handleChange(props, e.target.value)}
      >

        {options}
      </Select>
    </FormControl>
  );
};

SelectDropdown.propTypes = {
  type: PropTypes.string.isRequired,
  api_name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])

};

SelectDropdown.defualtProps = {
  inputType: '',
  selected: null,
};

const useFilter = (props)=> {

  const getCollectionFilters = ()=> {
    return fields.map((f)=> {
      return f.fields.map((field)=> field.api_name);
    });
  };

  const getSearchParamsFromURL = (collectionFilters)=> {
    const existingFilters = {};
    const allFilters = [].concat.apply([], collectionFilters);
    const paramsIterator = searchParams.entries();
    let result = paramsIterator.next();
    while (!result.done) {
      const queryParam = result.value[0];
      const value = result.value[1];
      try {
        const parsedValue = JSON.parse(value);

        if (parsedValue && allFilters.includes(queryParam)) {
          existingFilters[queryParam] = parsedValue;
        }
      } catch (e){

      }
      result = paramsIterator.next();
    }
    return existingFilters;
  };

  const getInitialFilterSetting = ()=> {
    const collectionFilters = getCollectionFilters();
    const existingFilters = getSearchParamsFromURL(collectionFilters);

    const resultObject = {
      showFilterPanel: Object.keys(existingFilters).length !== 0,
      openFilters: new Set(Object.keys(existingFilters)),
      selectedFilters: existingFilters,
      applyFilters: existingFilters,
      collectionFilters: collectionFilters
    };

    //console.log('inside getInitialFilterSetting');
    //console.log(resultObject);
    
    return resultObject;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const {fields} = props;

  const initialFilters = getInitialFilterSetting();
  const [showFilterPanel, setShowFilterPanel]=useState(initialFilters.showFilterPanel);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters.selectedFilters);
  const [openFilters, setOpenFilters] = useState(initialFilters.openFilters);
  const [applyFilters, setApplyFilters] = useState(initialFilters.applyFilters);

  const avaiableSearchParams = useRef(initialFilters.collectionFilters);


  useEffect(()=> {
    if(props?.applyFilterHandler) {
      props.applyFilterHandler();
    }
    addSearchParams();
  }, [applyFilters]);


  const toggleShowFilter = ()=> {
    setShowFilterPanel((showFilterPanel)=> !showFilterPanel);
  };

  const clearFilters = ()=> {
    setSelectedFilters({});
    setApplyFilters({});
    setOpenFilters(new Set ([]));
  };

  const addSearchParams =()=> {
    const allFilters = [].concat.apply([], avaiableSearchParams.current);
    const appliedFilters = Object.keys(applyFilters);
    allFilters.forEach((field)=> {
      if(appliedFilters.includes(field)) {
        searchParams.append(field, JSON.stringify(applyFilters[field]));
      } else {
        searchParams.delete(field);
      }
    });
    setSearchParams(searchParams);
  };

  const selectFilter = (e) => {
    if(!e?.target) return;
    if(e.target.checked) {
      setOpenFilters((openFilters)=>  {
        const newSet = new Set(openFilters);
        newSet.add(e.target.name);
        return newSet;
      });
    }
    else {
      setOpenFilters((openFilters)=> {
        const newSet = new Set(openFilters);
        newSet.delete(e.target.name);
        return newSet;
      });

    }
  };

  const badgeIsInvisible = ()=> {
    if(showFilterPanel) return true;
    return Object.keys(applyFilters).length === 0;
  };

  const handleSelectChange = (field, value)=> {
    setSelectedFilters((selectedFilters)=> {
      const filter = {
        param: value
      };
      return {...selectedFilters,
        [field.api_name]: {...selectedFilters[field.api_name], ...filter}};
    });
  };

  const handleInputChange = (field, value)=> {
    setSelectedFilters((selectedFilters)=> {
      const isNumber = field.type && field.type === filterTypes.number;
      return {...selectedFilters,
        [field.api_name]: {...selectedFilters[field.api_name], ...{value: isNumber? Number(value) : value, type: field.type}}};
    });
  };

  const filterButton =
    <Badge color="secondary" variant="dot" invisible={badgeIsInvisible()}>
      <IconButton color="primary"
        aria-label="filter"
        component="label"
        sx={{
          padding: '6px',
          borderRadius: '4px',
          boxShadow: `${showFilterPanel ? 'inset 0 2px 6px rgb(49 81 162 / 35%)': 'none'}`,
          border: '1px solid',
          borderColor: `${showFilterPanel ? '#fff':STYLE_CONSTANTS.COLORS.globalBlue}`
        }}
        onClick={toggleShowFilter}
      >
        <img src={FilterIcon} alt={''} color={COLORS.globalBlue} width={15} height={15}/>
      </IconButton>
    </Badge>;

  const onSubmit = (e)=> {

    //console.log('onSubmit');

    e.preventDefault();
    const currentFilters = {};
    const checked = Array.from(openFilters);

    checked.forEach((field)=> {
      const filter = selectedFilters?.[field];
      if(filter) {
        const filterValue = filter?.value;
        if(!filter?.param) {
          filter.param = queryTypes.equals.value;
        }
        if(!filter.type) {
          filter.type = field.type || filterTypes.text;
        }
        if(filter.param === queryTypes.empty.value || filter.param === queryTypes.notEmpty.value) {
          delete filter.value;
        }
        currentFilters[field] = filter;
        if(filterValue  && filterValue.length !== 0) {
          currentFilters[field] = filter;
        }
      }

    });

    //console.log('current custom filters');
    //console.log(currentFilters);

    setApplyFilters(currentFilters);
  };

  const getInputField = (field)=> {
    const selectedFilter = selectedFilters[field.api_name];
    const param = selectedFilter?.param;
    if(param && (param === queryTypes.empty.value || param === queryTypes.notEmpty.value)) return null;

    if(!field?.inputType) {
      return (
        <FormControl>
          <TextField
            id={field.api_name}
            autoComplete={'off'}
            value={selectedFilters[field.api_name]?.value ||  ''}
            onChange={(e)=> handleInputChange(field, e.target.value)}
          />
        </FormControl>
      );
    }
    if(field.inputType === inputTypes.singleSelect) {
      return (
        <FormControl>
          <Select id={`${props.api_name}-select`}
            value={selectedFilters[field.api_name]?.value ||  ''}
            onChange={(e)=>  handleInputChange(field, e.target.value)}
          >

            {field.options.map((opt)=> {
              return (
                <MenuItem  key={`${opt.value}`} value={opt.value}>{opt.name}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  const createFieldFilters = (field, index)=> {
    const isChecked = openFilters.has(field.api_name);
    return(
      <Box key={field.api_name+index}
        display={'flex'}
        flexDirection={'column'}
        sx={{
          '& .MuiSvgIcon-root': {
            width: '20px'
          },
          '& .MuiInputBase-input:focus': {
            borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
          },
          '& .MuiInputBase-root': {
            fontSize: '14px',
            height: '24px'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              // borderColor: 'white',*/}
              //backgroundColor: 'red'*/}
            },
            '&:hover fieldset': {
              borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
            },
            '&.Mui-focused fieldset': {
              borderWidth: '1px',
            },
          },
          '& >.MuiFormControl-root': {
            marginLeft: '10px',
            marginRight: '15px'
          },
          '& .MuiCheckbox-root': {
            paddingTop: '2px',
            paddingBottom: '2px'
          }
        }}
      >
        <FormControlLabel
          control={
            <Checkbox name={field.api_name}
              onChange={selectFilter}
              checked={isChecked}/>
          }
          label={field.label}
        />
        {isChecked && <>
          <SelectDropdown type={field.type}
            api_name={field.api_name}
            inputType={field?.inputType}
            selected={selectedFilters[field.api_name]?.param || queries[field.type][0].value}
            handleChange={handleSelectChange}/>
          {getInputField(field)}
        </>}
      </Box>
    );
  };


  const filterPanel = <Grid container>
    {/*<FormProvider {...formMethods} >*/}
    <form onSubmit={onSubmit} >
      <FormGroup>
        <Box sx={{paddingLeft: '10px',}}>
          {fields.map((section, index)=> {
            const fields = section.fields;
            return (
              <Box key={`${section}-${index}`}>
                <Typography fontWeight={600} fontSize={'16px'} mt={2}>{section.category}</Typography>
                {
                  fields.map((field, i)=> {
                    return createFieldFilters(field, i);
                  })
                }
              </Box>
            );

          })}

        </Box>

      </FormGroup>

      {/*</FormProvider>*/}
      <Grid>
        <Box display={'flex'}
          justifyContent={'center'}
          sx={{
            position: 'fixed',
            bottom: 0,
            width: `${props.width ? props.width+'px' : 'unset' }`,
            backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
            height: STYLE_CONSTANTS.HEIGHT.PAGINATOR()
          }}>

          <FormSubmitButtons cancelAction={clearFilters} submitText={'Apply'} cancelText={'Clear'}/>
        </Box>
      </Grid>
    </form>
  </Grid>;


  return {
    controller: filterButton,
    filterPanel: filterPanel,
    show: showFilterPanel,
    filterTypes: filterTypes,
    applyFilters: applyFilters,
  };
};

const GridWithFilterWrapper = (props)=> {
  const classes = {...useAllJobsViewStyles()};
  return (
    <Grid container flexWrap={'nowrap'}>
      <Grid item xs={12} sx={{
        marginLeft: 'initial'
      }}>
        {props.show && <Grid item className={classes.search_container}>
          {props.filterPanel}
        </Grid>
        }
        <Grid item xs={12} sx={{
          marginLeft: props.show ? '230px' : 'initial'
        }}>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};

GridWithFilterWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  filterPanel: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired
};

export {useFilter, filterTypes, GridWithFilterWrapper};

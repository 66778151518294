export const validatePrices = (priceList)=>{
  if(!priceList || Object.keys(priceList).length === 0) return [];

  let invalidIndexes = [];
  priceList.forEach((row, index)=> {
    if(!row.price || (typeof row.price !== 'number' && isNaN(row.price)) ) {

      invalidIndexes.push(index);

    }
  });
  return  invalidIndexes;
};

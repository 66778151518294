import React from 'react';
import PropTypes from 'prop-types';
import {Text} from '../../../pieces/text';
import Grid from '@mui/material/Grid';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';

export const NotAllowedToEditSection = (props)=> {
  return (
    <Grid item xs={12} sx={{height: '24px'}}>
      {
        props.show && <Text text={'You are not allowed to edit this information'} color={STYLE_CONSTANTS.COLORS.globalRed}/>
      }
    </Grid>
  );

};

NotAllowedToEditSection.propTypes = {
  show: PropTypes.bool,
};

NotAllowedToEditSection.defaultProps = {
  show: false,
};


import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Paper, Box, Typography, Button} from '@mui/material';
import ipermitLogo from '../../../static/images/iPermitPro.png';
import successImage from '../../../static/images/check-circle.png';
import warningImage from '../../../static/images/warningImage.png';
import {globalUseClasses} from '../../../styles/globalClasses';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const JobSubmitted = (props)=> {
  const classes = {...globalUseClasses()};

  let doneButton = useRef(null);

  useEffect(() => {
    doneButton.current.focus();
  }, []);


  return(
    <Box display={'flex'} justifyContent={'center'} sx={{height: '75vh'}} alignItems={'center'}>
      <Paper sx={{
        width: {xs: '250px', sm: '500px'} ,
        padding: theme => theme.spacing(2),
      }}>
        <Box onDragStart={(e)=> e.preventDefault()} display={'flex'} justifyContent={'center'}>
          <img src={ipermitLogo} alt={'iPermit_pro_logo.png'} className={classes.logo}/>
        </Box>
        <Box onDragStart={(e)=> e.preventDefault()}
          display={'flex'} alignItems={'center'} flexDirection={'column'} mt={3} mb={3}

        >
          {!props.isError &&
            <>
              <Box textAlign={'center'}>
                <Typography variant={'subtitle1'}>Job
                  <Typography variant={'subtitle1'} component={'span'} sx={{fontWeight: '600', padding: '0 8px'}}>
                    {`#${props.jobNumber}`} </Typography>
                  {`has been ${props.isDraft ? 'submitted' : 'created'}.`}
                </Typography>
              </Box>
              <Typography variant={'subtitle1'}>We&apos;re on it!</Typography>
            </>

          }
          {props.isError &&
          <Box flexDirection={'row'} display={'flex'}>
            <Typography variant={'subtitle1'} sx={{fontWeight: '600', padding: '0 8px'}}>
              {`Error on ${props.isDraft ? 'submit' : 'create'} job.`}</Typography>
          </Box>
          }

        </Box>
        <Box onDragStart={(e)=> e.preventDefault()}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{
            '& img':{
              width: '70px'
            }
          }}
        >
          <img src={props.isError ? warningImage : successImage} alt={''}/>
          <Button
            type='submit'
            variant={'contained'}
            color={'primary'}
            sx={{
              width: '100px',
              marginTop: theme => theme.spacing(3),
              marginBottom: theme => theme.spacing(2)
            }}
            ref={doneButton}
            onClick={props.doneAction}
          >
            Done
          </Button>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant={'text'} onClick={props.newAction}>
            Create a new job
            <ArrowRightAltIcon/>
          </Button>
        </Box>
      </Paper>
    </Box>

  );
};

JobSubmitted.propTypes = {
  jobNumber: PropTypes.number.isRequired,
  doneAction: PropTypes.func.isRequired,
  newAction: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isDraft: PropTypes.bool
};

JobSubmitted.defaultProps = {
  isError: false,
  isDraft: false,
};
export default JobSubmitted;

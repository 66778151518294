import {blue, green} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from './styleConstants';

const theme = createTheme({

  palette: {
    secondary: {
      main: STYLE_CONSTANTS.COLORS.globalRed,
    },
    primary: {
      main: STYLE_CONSTANTS.COLORS.globalBlue,
      light: STYLE_CONSTANTS.COLORS.lightGray

    },
    info: {
      main: blue[900],
    },
    error: {
      main: STYLE_CONSTANTS.COLORS.globalRed,
    },
    success: {
      main: green[500],
    },
    link: {
      main: blue[600],
    },
    background: {
      default: STYLE_CONSTANTS.COLORS.backgroundMain,
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1537,
    },
  },
  props: {

    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'span',
        body2: 'span',
      },
    },
  },

  typography: {
    fontFamily: ['"DM Sans"', 'Open Sans'].join(','),
    color: STYLE_CONSTANTS.COLORS.fontMain,
    fontWeightRegular: 400,
    fontWeightBold: 600
  },
});

// eslint-disable-next-line no-unused-vars
const {breakpoints, typography: {pxToRem}} = theme;
const myTheme = {
  ...theme,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: 0,
        },
        input: {
          backgroundColor: '#fff',
          padding: '0px 12px',
          '&::placeholder': {
            color: 'gray'
          },
          //boxShadow: '0px 2px 8px rgba(202, 31, 65, 0.21)',
          //color: 'white', // if you also want to change the color of the input, this is the prop you'd use
        }
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          // marginBottom:'10px',
          // [breakpoints.down('lg')]: {
          //   marginBottom: '20px',
          // }
        }

      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
        },
        shrink: {
          transform: 'translate(0, 1.5px)',
        },
        asterisk: {
          color: 'red'
        }
      }

    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: STYLE_CONSTANTS.COLORS.fontMain,

        },
        body1: {
          fontSize: '14px',
          fontWeight: '500',
        },
        body2: {
          fontSize: '12px',
          fontWeight: '500',
        },
        h1: {
          fontSize: '2rem',
        },
        h2: {
          fontSize: '1.5rem',
        },
        h3: {
          fontSize: '1.75rem',
          fontWeight: '600',
          fontFamily: '"DM Sans", sans-serif',
          [breakpoints.down('md')]: {
            fontSize: '1.35rem'
          }
        },
        h4: {
          fontSize: '1.5rem',
          fontWeight: '600',
          [breakpoints.down('md')]: {
            fontSize: '1.15rem'
          }
        },
        h5: {
          fontSize: '1.15rem',
          fontWeight: '600',
          [breakpoints.down('md')]: {
            fontSize: '1.075rem'
          }
        },
        subtitle2: {
          fontSize: '1.125rem',
          [breakpoints.down('md')]: {
            fontSize: '1rem'
          },
          [breakpoints.down('sm')]: {
            fontSize: '1rem'
          }

        },
        subtitle1: {
          fontSize: '1.25rem',
          [breakpoints.down('md')]: {
            fontSize: '1.15rem'
          }
        },
      }

    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '4px 16px',
          [breakpoints.up('xl')]:{
            paddingX: '6px'
          },
          textTransform: 'none',
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.fontSecondary,
          }
        },
        outlined: {
          '&:hover': {
            color: STYLE_CONSTANTS.COLORS.white
          }
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.globalBlueHover,
          }
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.globalRedHover,
          }
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.globalBlueHover,
          }
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.globalRedHover,
          }
        },
        containedError: {
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.globalRedHover,
          }
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          }
        }
      },
      variants: [
        {
          props: {variant: 'violetContained', color: 'primary'},
          style: {
            backgroundColor: STYLE_CONSTANTS.COLORS.violet,
            textTransform: 'none',

          },
        },
        {
          props: {variant: 'violetContained', color: 'secondary'},
          style: {
            backgroundColor: STYLE_CONSTANTS.COLORS.lightViolet,
            textTransform: 'none',
            color: STYLE_CONSTANTS.COLORS.violet,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: STYLE_CONSTANTS.COLORS.lightVioletHover
            }
          },
        },
        {
          props: {variant: 'blueContained', color: 'secondary'},
          style: {
            backgroundColor: STYLE_CONSTANTS.COLORS.lightBlue,
            textTransform: 'none',
            color: STYLE_CONSTANTS.COLORS.globalBlue,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: STYLE_CONSTANTS.COLORS.lightBlueHover
            }
          },
        },
        {
          props: {variant: 'grayContained', color: 'primary'},
          style: {
            backgroundColor: STYLE_CONSTANTS.COLORS.lightGray,
            textTransform: 'none',
            color: STYLE_CONSTANTS.COLORS.fontMain,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: STYLE_CONSTANTS.COLORS.lightGrayHover
            }
          },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides:{
        root: {
          [breakpoints.up('sm')]: {
            minHeight: theme.spacing(6)
          }
        }
      }
    }
  },
};

const useHelperTextStyles = makeStyles(() => ({root: {
  color: 'red',
  margin: '0 !important',
  backgroundColor: 'inherit',
  display: 'flex',
  alignSelf: 'flex-end',
  minHeight: '16px',
  lineHeight: '10px',
  fontSize: '10px'
}}));

export {useHelperTextStyles};
export default myTheme;

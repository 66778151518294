import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {Box, Grid, TableContainer, Paper, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {Text} from '../text';
import Spacer from '../spacer';
import CustomizedSwitch from '../switch/switch';
import Utils from '../../utils/utils';
import ApiUtils from '../../utils/apiUtils';
import FormSubmitButtons from '../formSubmitButtons';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';


export const PermissinsSettingsPanel = (props) => {
  const [settings, setSettings] = useState(undefined);
  const [checked, setChecked] = useState(props.userData?.policies ?? []);

  useEffect(async()=> {
    const policiesResponse = await ApiUtils.makeApiCall('GET', 'ipermit/policies/list');
    const policies = policiesResponse.data;
    setSettings(policies?.categories ?? []);
  },[]);

  const isChecked = (name) => {
    return checked.includes(name);
  };

  const handleToggle = (name) => {

    const newChecked = [...checked];

    if (!isChecked(name)) {
      newChecked.push(name);
    } else {
      const currentIndex = checked.indexOf(name);
      newChecked.splice(currentIndex, 1);
    }
    settingsForm.setValue(name, !isChecked(name));
    setChecked(newChecked);
  };

  useEffect(()=> {
    setChecked(props.userData?.policies ?? []);
  }, [props.userData?.policies]);


  const settingsForm = useForm();
  if(Utils.isDevEnv()) {
    window.settingsForm = settingsForm;
  }

  useEffect(()=> {
    if(settingsForm) {
      settingsForm.reset(checked);
    }
  }, [checked]);

  const onSubmit = (data)=> {
    if(!props.submitAction) return;
    const formattedData = [];
    //iter over object keys and values
    Object.entries(data).forEach(([key, value])=> {
      formattedData.push(value);
    });

    props.submitAction(formattedData);
  };

  const onCancel = ()=> {
    settingsForm.reset(props.userData?.policies ?? []);
    props.cancelAction();
  };

  if(!settings) return null;

  return(
    <>
      <Spacer y={3} />
      <form onSubmit={settingsForm.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item>
            {settings.map((section, index)=> {
              return(
                <Box key={index+section.value} sx={{
                  textTransform: 'capitalize',
                }}>

                  <Text text={section.display} fontWeight={'bold'} size={'xLarge'}/>
                  <Spacer y={1} />
                  <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="settings table" size="small">
                      <TableBody>
                        {section.policies.map((entry, index)=> {
                          return(
                            <TableRow
                              key={entry.value+index}
                              sx={{'&:last-child td, &:last-child th': {border: 0},
                                backgroundColor: props.viewMode ? STYLE_CONSTANTS.COLORS.hoverColor: 'initial'
                              }}
                            >
                              <TableCell component="th" scope="row" sx={{width: {xs: 200, md: 300}}}>
                                {entry.display}
                              </TableCell>
                              <TableCell align="center" sx={{width: {xs: 100, md: 200}}}>
                                <CustomizedSwitch key={isChecked(entry.value)}
                                  disabled={props.viewMode}
                                  action={()=> {
                                    if(props.viewMode) return;
                                    handleToggle(entry.value);
                                  }}
                                  defaultChecked={isChecked(entry.value)}
                                />

                              </TableCell>
                              <TableCell align="left">
                                <Box sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  textTransform: 'none',
                                }}>
                                  {entry.shortDescription}
                                </Box>
                              </TableCell>


                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Spacer y={3} />
                </Box>
              );
            })}
          </Grid>
          {!props.viewMode && <Grid xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <FormSubmitButtons cancelAction={onCancel} submitText={'Update'} cancelText={'Cancel'}/>
            </Box>
          </Grid>}
        </Grid>
      </form>
    </>
  );
};

PermissinsSettingsPanel.propTypes = {
  submitAction: PropTypes.func,
  cancelAction: PropTypes.func,
  userData: PropTypes.instanceOf(Object),
  viewMode: PropTypes.bool
};

PermissinsSettingsPanel.defaultProps = {
  submitAction: (data)=> {console.log('submitAction not defined', data);},
  cancelAction: ()=> {console.log('cancelAction not defined');},
  userData: {},
  viewMode: true
};

const ADMIN_ELEMENTS = {
  adminPanel: 'adminPanel',
  manageUsers: 'manageUsers',
  manageCheers: 'manageCheers',
  controlPermissions: 'controlPermissions',
  forceSyncWithCRM: 'forceSyncWithCRM',
  inviteUser: 'inviteUser',
};

const ipermitAdminPolicies = {
  manageUsers: 'iPermitUser.manageUsers',
  setPolicy: 'iPermitUser.setPolicy',
  manageCheers: 'iPermitUser.manageCheersAccount',
  forceSyncWithCRM: 'iPermitUser.forceSyncWithCRM', //this policy is not implemented on backend - that is fine until we allow action for SYSTEM_ADMIN only
};

const ipermitAdminRules = Object.freeze({
  [ADMIN_ELEMENTS.adminPanel]: {
    // policyName: [ipermitAdminPolicies.manageUsers],
    contractorAccess: false,
  },
  [ADMIN_ELEMENTS.manageUsers]: {
    policyName: ipermitAdminPolicies.manageUsers,
    contractorAccess: false,
  },
  [ADMIN_ELEMENTS.controlPermissions]: {
    policyName: ipermitAdminPolicies.setPolicy,
    contractorAccess: false,
  },
  [ADMIN_ELEMENTS.forceSyncWithCRM]: {
    policyName: ipermitAdminPolicies.forceSyncWithCRM,
    contractorAccess: false,
  },
  [ADMIN_ELEMENTS.manageCheers]: {
    policyName: ipermitAdminPolicies.manageCheers,
    contractorAccess: false,
  },
  [ADMIN_ELEMENTS.inviteUser]: {
    policyName: ipermitAdminPolicies.manageUsers,
    contractorAccess: false,
  }
});

export {ipermitAdminRules, ADMIN_ELEMENTS};

import React from 'react';
import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
import * as allConstants from '../constants/allConstants';
import {useSelector} from 'react-redux';


const PrivateiPermitRoute = ({children}) => {
  const user = useSelector(state=> state.user);
  const {isAuthenticated, role, companyId, isFetching} = user;
  const isiPermit = Object.values(allConstants.IPERMIT_ROLES).includes(role);
  const isImpersonating = companyId;
  const {USER_FIELDS} = allConstants;

  if(isFetching) {
    return children;
  }

  if (!isFetching && !isAuthenticated) {
    // console.log('!isFetching && !isAuthenticated && !authPending',isFetching, isAuthenticated, authPending)
    // console.log("NAV To LOGIN!!!!")
    return <Navigate to={allConstants.PATH.LOGIN} replace={true} />;
  }

  //console.log('check isiPermit');

  if(!isiPermit) {
    return <Navigate to={allConstants.PATH.ACCESS_DENIED}/>;
  } else if(isImpersonating) {
    // console.log('loc', location);
  }

  if(user && user?.[USER_FIELDS.IS_CONNECT_TO_CHEERS_REQUESTED.api_name] && !user[USER_FIELDS.HAS_CONNECTED_TO_CHEERS.api_name]){

    return <Navigate to={allConstants.PATH.CHEERS_CONNECTION_REQUIRED}/>;
  }


  return children;
};

PrivateiPermitRoute.propTypes = {
  children:  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PrivateiPermitRoute.defaultProps = {
  children: null,
};

export default PrivateiPermitRoute;

import {makeStyles} from '@mui/styles';
import myTheme from '../../styles/myStyles';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

const useNavbarStyles = makeStyles(theme=> {
  theme=myTheme;
  return {
    menu_text: {
      color: STYLE_CONSTANTS.COLORS.globalRed,
      opacity: '1 !important',
    },
    link_wrapper: {
      color: STYLE_CONSTANTS.COLORS.fontPale,
      '& ul li': {
        color: STYLE_CONSTANTS.COLORS.fontPale,
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'inherit',
          color: `${STYLE_CONSTANTS.COLORS.globalBlue} !important`,

          '& span': {
            color: STYLE_CONSTANTS.COLORS.globalBlue
          },
          '& svg': {
            stroke: STYLE_CONSTANTS.COLORS.globalGreen,
          }
        }
      },
      '& a, & ul li a, button, ul li button': {
        margin: '0px',
        fontSize: '16px',
        [`${theme.breakpoints.only('xl')}`]: {
          fontSize: '18px',
        },
        color: STYLE_CONSTANTS.COLORS.fontPale,
        '&:hover': {
          backgroundColor: 'inherit',
          color: `${STYLE_CONSTANTS.COLORS.globalBlue} !important`,

          '& span': {
            color: STYLE_CONSTANTS.COLORS.globalBlue
          },
          '& svg': {
            stroke: STYLE_CONSTANTS.COLORS.globalGreen,
          }
        }
      }
    },


  };});

export {useNavbarStyles};


import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => {
  return {
    progressText: {
      color: STYLE_CONSTANTS.COLORS.fontPale
    },
    progressHolder: {
      width: '100%',
      height: '100%'
    }
  };
});



const GettingDataProgress = (props)=> {
  const classes = useStyles();
  return (
    <Box display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      className={classes.progressHolder}>
      <CircularProgress style={{color: `${props.color ? props.color : STYLE_CONSTANTS.COLORS.globalBlue}`}}/>
      {/*<Typography variant={'body1'} className={classes.progressText}>Getting Data...</Typography>*/}
    </Box>
  );
};

GettingDataProgress.propTypes = {
  color: PropTypes.string
};

GettingDataProgress.defaultProps = {
  color: null
};
export default GettingDataProgress;

import React, {useState} from 'react';
import {GotItPopup} from '../pieces/modals/gotItPopup';
import {Text} from '../pieces/text';

export const useGotIt = ()=> {
  const [show, setShow] = useState(null);

  const PopUp = ()=> {
    if(!show) return null;
    return <>
      <GotItPopup handleClose={async ()=> {
        setShow(null);
      }}>
        <Text text={show}/>
      </GotItPopup>
    </>;
  };

  return {show, setShow, PopUp: PopUp};
};

import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import ModalWrapper from '../../../../../pieces/modals/modalWrapper';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import {Text} from '../../../../../pieces/text';

export const TestResultSideEffectPopup = (props)=> {

  const noAction = ()=> {
    if(!props.dataToSubmit) return;
    try{
      props.submitAction(props.dataToSubmit);
    } catch(e) {
      console.error('Error on saving rough passes test result, user select "Yes"', e);
    }

  };

  const yesAction = ()=> {
    if(!props.dataToSubmit) return;

    try{
      const data = {...props.dataToSubmit,
        'setFinalPending': true,
      };
      props.submitAction(data);
    } catch(e) {
      console.error('Error on saving rough passes test result, user select "No"', e);
    }
  };

  return(
    <ModalWrapper modalName={'test-result'}
      open={true}
      preventScroll={true}
      width={{sx: '98%', md: '60%'}}
      maxWidth={'600px'}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Text text={'Rough inspection passed.'}/>
        <Text text={'Do you want to change inspection type to FINAL and set status PENDING?'}/>
        <FormSubmitButtons cancelAction={noAction} submitAction={yesAction} cancelText={'No'} submitText={'Yes'}/>
      </Box>
    </ModalWrapper>
  );
};

TestResultSideEffectPopup.propTypes = {
  dataToSubmit: PropTypes.instanceOf(Object),
  submitAction: PropTypes.func,
};

TestResultSideEffectPopup.defaultProps = {
  dataToSubmit: null,
  submitAction: ()=> {console.log('[TestResultSideEffectPopup]: submitAction not defined');},
};

import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import * as allConstants from '../../constants/allConstants';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import actions from '../../redux/actions';
import CustomFormBlock  from '../../pieces/inputs/formBlock';
import {Box} from '@mui/material';
import Utils from '../../utils/utils';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {inputTypes} from '../../pieces/inputs/formInput';

const ChangePasswordUserForm = (props)=> {
  const dispatch = useDispatch();
  const {USER_FIELDS} = allConstants;

  const validationSchema = Yup.object().shape({
    [USER_FIELDS.PASSWORD.api_name]: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    [USER_FIELDS.CONFIRM_PASSWORD.api_name]: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match')
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };
  const formMethods = useForm(formOptions);

  const onSubmit = async (data) => {
    //** For some reason here on edit fields are not set as dirty. Use touched instead.
    // const dirtyFields = formMethods.formState.dirtyFields;
    const touchedFields = formMethods.formState.touchedFields;
    const cleanData = Utils.dirtyValues(touchedFields, data);
    const editStatus = await dispatch(actions.changeUserPassword({userId: props.selectedUser.id, ...cleanData})); // ({company: cleanData}));
    console.log('editStatus', editStatus);
    if(editStatus) {
      unwrapRequestResult({
        showSuccess: true,
        result: editStatus,
        successMessage: 'Successfully changed password',
        id: 'edit_user_success',
      });
      props.closeModal();
    }
  };

  const onCancel = (e)=> {
    e.preventDefault();
    formMethods.reset();
    props.closeModal();
  };
  const inputDefaultProps = {
    viewMode: false,
    renderLabel: false,
  };

  return(
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} method={'POST'}>
          <CustomFormBlock
            {...inputDefaultProps}
            field={allConstants.USER_FIELDS.PASSWORD}
            fullWidth={true}
            isEditable={true}
            inputType={inputTypes.password}
            confirmIsRequired={true}
            twoColumns={true}
            hideHelper={true}
            preventCapitalize={true}
          />
          <Box display={'flex'} justifyContent={'center'}>
            <FormSubmitButtons cancelAction={onCancel} submitText="Update" cancelText="Cancel"/>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

ChangePasswordUserForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedUser: PropTypes.instanceOf(Object).isRequired,
};

export default ChangePasswordUserForm;

// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import * as allConstants from '../constants/allConstants';
import ApiUtils from '../utils/apiUtils';

export const AutocompleteFields = {
  [allConstants.COMPANY_FIELDS.LOCATION.api_name]: {
    url: 'get-markets',
    responseKey: 'markets',
  },
  cityOptions: {
    url: 'get-city-options',
  },
  jobStatuses: {
    url: 'get-all-job-statuses',
  },
  markets:{
    url: 'get-markets',
    responseKey: 'markets',
  },
  raterDepartments: {
    url: 'get-rater-departments',
    responseKey: 'raterDepartments',
  }
};

export const useAutocompleteOptions = (props) => {
  const field = props.field;
  const [options, setOptions] = useState(null);

  const fetchOptions = async () => {
    try{
      const response = await ApiUtils.makeApiCall('GET', `autocomplete/${AutocompleteFields[field].url}`, undefined);
      // console.log('response', response);
      if(response?.status === 'success') {

        return AutocompleteFields[field].responseKey ? response.data[AutocompleteFields[field].responseKey] : response.data;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }

  };

  useEffect(async () => {
    const options = await fetchOptions();
    setOptions(options);
  }, []);

  return {
    options: options
  };

};

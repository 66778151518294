import {Box, Grid, Paper} from '@mui/material';
import {makeStyles, styled} from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import GettingDataProgress from '../../pieces/gettingDataProgress';
import CustomIcon from '../../static/icons/Icon';
import {globalUseClasses} from '../../styles/globalClasses';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

const useBookmarkPaperStyles = props=> makeStyles(theme=> {
  return {
    homepage_paper_block: {
      borderRadius: '25px',
      height: '85px',
      minWidth: '100px',
      maxWidth: '435px',
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      boxShadow: `0px 3px 5px -1px ${STYLE_CONSTANTS.COLORS.boxShadow}, 0px 6px 10px 0px ${STYLE_CONSTANTS.COLORS.boxShadow}, 0px 1px 18px 0px ${STYLE_CONSTANTS.COLORS.boxShadow}`,
      color: `${STYLE_CONSTANTS.COLORS.globalBlue}`,
      fontWeight: 600,
      fontSize: `${props.isXS ? '12px' : props.isXS ? '14px' : '16px'}`,
      display: 'flex',
      flexDirection: 'row',

    },
    inverted: {
      flexDirection: 'row-reverse'
    }
  };
});

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const BookmarkPaper = (props)=> {
  const classes = {...useBookmarkPaperStyles({sizeQuery: props.sizeQuery, right: props.right})(), ...globalUseClasses()};
  const color = props.right ? STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.globalGreen;
  return(
    <Grid item style={{
      width: '95%',
    }}>
      <Box style={{position: 'relative',
        height: '85px',  minWidth: '100px',
        maxWidth: '470px', margin: 'auto',
      }}>
        <CustomIcon icon={'bookmark'} color={STYLE_CONSTANTS.COLORS.chartBlue}
          style={{
            fill:`${props.right ? '#608CF8' : '#C1D6AB'}`,
            position: 'absolute',
            top: '-9px',
            left: `${props.right ? 'unset' : '-65px'}`,
            right: `${props.right ? '-65px' : 'unset'}`,
            width: 'auto',
            zIndex: 5,
            transform: `scale(0.55) ${props.right ?'scale(-1)':'scaleY(-1)'}`,
          }}
          size={197} viewBox={'0 0 197 100'} />
        <CustomIcon icon={'bookmark'} color={color} className={classes.topCover}
          style={{
            fill: `${color}`,
            position: 'absolute',
            top: '-10px',
            left: `${props.right ? 'unset' : '-65px'}`,
            right: `${props.right ? '-65px' : 'unset'}`,
            width: 'auto',
            zIndex: 20,
            transform: `scale(0.55) ${props.right ? 'scaleX(-1)' : ''} `

          }}
          size={197} viewBox={'0 0 197 100'} />
        <CustomIcon icon={props.icon} color={STYLE_CONSTANTS.COLORS.white} size={36}
          style={{
            position: 'absolute',
            // top: '5%',
            left: `${props.right ? 'unset' : '5px'}`,
            right: `${props.right ? '0px' : 'unset'}`,
            top: '25px',
            zIndex: 25,
          }}
        />

        <Item className={clsx(classes.homepage_paper_block, props.right? '' : classes.inverted)} >
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
            style={{width: `${props.right ? '120px' : '100px'}`, fontSize: '30px', color: '#1B2559'}}>
            {props.isFetching ? <GettingDataProgress color={props.right ? null : STYLE_CONSTANTS.COLORS.globalGreen}/> : props.count}</Box>
          <Box display={'flex'}  alignItems={'center'}>
            {props.text}
          </Box>

        </Item>
      </Box>
    </Grid>
  );
};

BookmarkPaper.propTypes = {
  right: PropTypes.bool,
  text: PropTypes.string,
  sizeQuery: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.string.isRequired,
  count: PropTypes.string,
  isFetching: PropTypes.bool
};
BookmarkPaper.defaultProps = {
  isFetching: false,
  right: false,
  text: '',
  count: '',
};

export default BookmarkPaper;

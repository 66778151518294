import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Button} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useNavigate} from 'react-router-dom';

const GoBackFormButton = (props)=> {

  const navigate = useNavigate();
  let text = 'Back';
  if(props.view) {
    text = `${text} to ${props.view}`;
  }
  const color = STYLE_CONSTANTS.COLORS.globalBlue;
  const icon =  <KeyboardBackspaceIcon style={{color: color}}/>;

  const goBack = ()=> {
    if(!props.path) return;
    navigate(props.path);
  };

  return(
    <Button onClick={goBack} variant={'text'} disableRipple
      style={{fontSize: '18px',
        color: color,
        height: '45px'

      }}
      startIcon={icon}>{text}</Button>

  );

};

GoBackFormButton.propTypes = {
  view: PropTypes.string,
  path: PropTypes.string.isRequired,

};
GoBackFormButton.defaultProps={
  view: null
};
export default GoBackFormButton;

import * as Yup from 'yup';
import * as allConstants from '../../constants/allConstants';
import _ from 'lodash';

/**
 * @name ValidateRow
 */
class ValidateRow {

  #requiredIfAnyOtherIsSpecified = (validateField, dependsFrom)=> {
    const numberFieldsToCheck = dependsFrom.length-1;
    const dependsFromApiNames = dependsFrom.map((each)=> each.field.api_name).filter((name)=> name !==validateField);
    return  {[validateField]: Yup.string()
      .when(dependsFromApiNames, {
        is: (one, two, three, four, five, six, seven) => {
          return !!one || !!two ||
            (numberFieldsToCheck === 3 && !!three)  || (numberFieldsToCheck === 4 && !!four) || (numberFieldsToCheck === 5 && !!five) || (numberFieldsToCheck === 6 && !!six) || (numberFieldsToCheck === 7 && !!seven);
        },
        then: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).typeError(allConstants.ERROR_MSG.REQUIRED_FIELD),
        otherwise: Yup.string().optional()
      })
    };
  }

  /**
   *
   * @param {Array} validateFields - fields that are required if any of other fields in a row is not empty
   * @param {Array<{field: {api_name}}>} rowFields - all fields in a row
   * @return {{shape: {}, edges: FlatArray<*[], 1>[]}}
   */
  validateIfAnyInRowIsNotEmpty = (validateFields, rowFields)=> {
    let shape = {};
    const pairs = [];
    const f_arr = _.cloneDeep(validateFields);
    validateFields.forEach((field)=> {
      shape = {...shape, ...this.#requiredIfAnyOtherIsSpecified(field, rowFields)};
      const el = f_arr.pop();
      pairs.push(f_arr.map((rest)=> [el, rest]));
    });
    return {
      shape: shape,
      edges: pairs.flat().filter((a)=> a.length === 2)
    };
  }
}

const requiredRowFieldsValidator = new ValidateRow();

export {requiredRowFieldsValidator};

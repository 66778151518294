import React from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import ElectricalFormBlock from './electricalFormBlock';
import JobFormAccordionTab from '../../../../pieces/accordion/jobFormAccordionTab';


const Electrical = (props)=> {
  const classes = {...globalUseClasses()};
  const tabs = [
    {
      name: 'electrical',
      header: 'Electrical',
      content: ElectricalFormBlock
    },
  ];

  return (
    <>
      {tabs.map((tab, idx)=> {
        return(

          <JobFormAccordionTab classes={classes}
            key={idx}
            header={tab.header}
            id={`${tab.name}-header`}
            aria_controls={`${tab.name}-content`}
            alwaysOpen={true}
          >{tab.content({...props, ...{nested: true, parent: allConstants.JOB_TYPES.ELECTRICAL.api_name}})}</JobFormAccordionTab>
        );
      })}
    </>
  );
};

export default Electrical;

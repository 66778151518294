import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import {Box, TableCell, Typography, TableContainer, TableBody, TableRow, Table} from '@mui/material';

const StyledTableCell = withStyles(() => ({
  head: {
    color:'black',
    fontWeight: 'bold',
  },
  body: {
    fontSize: '14px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    '&:last-child': {
      paddingRight: '10px',
    },
  },
}))(TableCell);
const StyledTable = withStyles(() => ({
  root: {
    margin: '10px\' 0',
  },
}))(Table);

const getFixtureRow = (labels, values) => {
  const row = [];
  labels.forEach((l, index) => {
    row.push(<StyledTableCell align="left" >{l}</StyledTableCell>);
    row.push(<StyledTableCell align="right">{values[index]}</StyledTableCell>);
  });
  return row;
};

const FixtureTable = ({items, header})=> {
  const {labels, values} = items;
  if(!labels || labels.length === 0) return null;
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{border: '1px solid black'}}>
        <Typography variant="h6" sx={{fontSize: '14px', fontWeight: 600, paddingLeft: '16px'}}>
          {header}
        </Typography>
        <TableContainer>
          <StyledTable size="small" aria-label="plum fixtures">
            <TableBody>
              {labels.map((ch, index) => (
                <TableRow key={`${index.toString()}${ch}`} >
                  {getFixtureRow(ch, values[index])}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
    </>
  );
};

FixtureTable.propTypes = {
  items: PropTypes.shape({
    labels: PropTypes.instanceOf(Array).isRequired,
    values: PropTypes.instanceOf(Array).isRequired

  }).isRequired,
  header: PropTypes.string
};
FixtureTable.defaultProps= {
  header: '',
};

export {FixtureTable};

import {Box, Button, List, ListItem, Paper} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ANALYTICS_TARGETS} from '../../../constants/options/analyticsTargets';
import {AnalyticsHeader} from '../../../pieces/analytics/analyticsBaseCard';
import MultipleBarChart from '../../../pieces/analytics/multipleBarChart';
import GettingDataProgress from '../../../pieces/gettingDataProgress';
import NoDataFound from '../../../pieces/noDataFound';
import {globalUseClasses} from '../../../styles/globalClasses';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {awsUtils} from '../../../utils/awsUtils';
import {useAnalyticsStyles} from '../analyticsStyles';

const CompletedByMonthPermit = (props)=> {
  const classes = {...globalUseClasses(), ...useAnalyticsStyles()};
  const targets = {
    PREVIOUS: 'previous',
    CURRENT: 'current',
  };
  const sessionKey = {
    [targets.CURRENT]: 'permitCompletedByMonthCurr',
    [targets.PREVIOUS]: 'permitCompletedByMonthPrev'
  };
  const [selected, setSelected] = useState(targets.CURRENT);
  const [dataCurr, setDataCurr] = useState(sessionStorage.getItem(sessionKey[targets.CURRENT]) ? JSON.parse(sessionStorage.getItem(sessionKey[targets.CURRENT])) : []);
  const [dataPrev, setDataPrev] = useState(sessionStorage.getItem(sessionKey[targets.PREVIOUS]) ? JSON.parse(sessionStorage.getItem(sessionKey[targets.PREVIOUS])) : []);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);

  const today = useMemo(()=> Number(new Date().getFullYear()),[]);
  const months = useRef(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);

  const saveData = (fetchedData, target) => {
    if (fetchedData && Array.isArray(fetchedData)) {
      let formattedData;
      formattedData = Array(12).fill(0);
      fetchedData.forEach((month) => {

        formattedData[Number(month.status_month) - 1] = Number(month.completed_count);
      });
      setDataCurr(formattedData);
      sessionStorage.setItem(sessionKey[target], JSON.stringify(formattedData));
    } else {
      setDataCurr([]);
    }
  };

  const fetchData = (target)=> {

    const year = target === targets.CURRENT ? today : today-1;

    const fetchUrl = awsUtils.createUrl({
      type: ANALYTICS_TARGETS.PERMITS_COMPLETED_MONTHLY.api_request,
      // eslint-disable-next-line react/prop-types
      company: props.company,
      params: {year: year}
    });
    setFetching(true);

    fetch(`${fetchUrl}`)
      .then(response=> { return response.json();}).then(
        (result) => {
          saveData(result.queryResult, target);
          setFound(true);
          setFetching(false);
        },

        (error) => {
          console.log('error...', error);
          setFound(false);
          setFetching(false);
        }
      );
  };
  const toggleChart = (target) => {
    setSelected(target);
    if(sessionStorage.getItem(sessionKey[target])) {
      setDataToState(JSON.parse(sessionStorage.getItem(sessionKey[target])), target);
    } else {
      fetchData(target);
    }
  };

  const toDisplay = selected === targets.CURRENT ? dataCurr : dataPrev;

  const setDataToState = (data, target) => {
    if (target === targets.CURRENT) {
      setDataCurr(data);
    } else {
      setDataPrev(data);
    }
  };

  useEffect(()=> {
    if (dataCurr.length === 0) {
      fetchData(targets.CURRENT);
    }
    if (dataPrev.length === 0) {
      fetchData(targets.PREVIOUS);
    }
  }, [selected]);

  // console.log('PCM', data, selected)
  let content;
  if (fetching) {
    content = <GettingDataProgress/>;
  } else if (!found) {
    content = <NoDataFound/>;
  } else {
    content =
        <MultipleBarChart
          width={props.mediaQueries.isXL ? 810 : null}
          y={toDisplay} x={months.current}
          blue={selected === targets.CURRENT} dataKey={'completed'}/>;

  }

  return (
    <Paper elevation={0} className={clsx(classes.info_paper)} pb={3} sx={{width: props.mediaQueries.isXL ? '800px' : '700px'}}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <AnalyticsHeader title={'Permit Completed by Month'}/>
        <Box>
          <List component="div" disablePadding sx={{
            display: 'flex', flexDirection: 'row', padding: 0,
            '& .MuiButtonBase-root': {
              width:'max-content'
            }
          }}>
            <ListItem sx={{padding: '0px'}}>
              <Button disableRipple sx={{borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'}}
                className={clsx(selected === targets.CURRENT ? classes.selected : '')}
                onClick={() => toggleChart(targets.CURRENT)}>
                <Box mr={1} sx={{
                  borderRadius: '50%',
                  height: '15px',
                  width: '15px',
                  backgroundColor: `${STYLE_CONSTANTS.COLORS.globalBlue}`,
                }}/>
                Current Year
              </Button>
            </ListItem>
            <ListItem sx={{padding: '0px'}}>
              <Button disableRipple sx={{borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'}}
                className={clsx(selected === targets.PREVIOUS ? classes.selected : '')}
                onClick={() => toggleChart(targets.PREVIOUS)}>
                <Box mr={1} sx={{
                  borderRadius: '50%',
                  height: '15px',
                  width: '15px',
                  backgroundColor: `${STYLE_CONSTANTS.COLORS.globalGreen}`,
                }}/>
                Previous Year
              </Button>
            </ListItem>
          </List>
        </Box>

      </Box>
      {content}
    </Paper>
  );
};

CompletedByMonthPermit.propTypes = {
  company: PropTypes.string.isRequired,
  mediaQueries: PropTypes.shape({
    isXL: PropTypes.bool
  })
};

CompletedByMonthPermit.defaultProps = {
  mediaQueries: {
    isXL: false
  }
};

export {CompletedByMonthPermit};


import * as allConstants from '../../../constants/allConstants';
const jobShowFieldRules = {
  /**
   *
   * @param {string} jobType
   * @param {string} propertyType
   * @param {Array} allowedTests
   * @return {boolean}
   */
  showHERS: (jobType, propertyType, allowedTests)=> {
    if(!jobType || (jobType !== allConstants.JOB_TYPES.HVAC_RES.value && jobType !== allConstants.JOB_TYPES.TESTING_ONLY.value) || !allowedTests || allowedTests.length === 0) return false;
    const tests = allowedTests.map((t)=> t.value);
    if (tests.length === 0 || !tests.includes(allConstants.TEST_TYPES.HERS.value)) return false;
    return true;
    // return propertyType !== allConstants.PROPERTY_TYPES.COMMERCIAL.value;
  },
  showEUC: (jobType, propertyType, allowedTests)=> {
    if(!jobType || (jobType !== allConstants.JOB_TYPES.HVAC_RES.value && jobType !== allConstants.JOB_TYPES.TESTING_ONLY.value)|| !allowedTests || allowedTests.length === 0) return false;
    const tests = allowedTests.map((t)=> t.value);

    if(tests.length === 0 || !tests.includes(allConstants.TEST_TYPES.EUC.value)) return false;

    return true;
    //return propertyType !== allConstants.PROPERTY_TYPES.COMMERCIAL.value;

  },
  showAsbestos: (jobType)=> {
    return (jobType && (jobType === allConstants.JOB_TYPES.HVAC_RES.value || jobType === allConstants.JOB_TYPES.TESTING_ONLY.value));

  },
  showNewConstruction: (jobType)=> {
    return (jobType && (jobType === allConstants.JOB_TYPES.HVAC_RES.value || jobType === allConstants.JOB_TYPES.TESTING_ONLY.value));
  }
};

export {jobShowFieldRules};

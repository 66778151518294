import React from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import iPermitLogo from '../../static/images/iPermitPro.png';
import {useStylesLogin} from './loginStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import clsx from 'clsx';


const SingInPageWrapper = (props)=> {
  const classes = {...useStylesLogin(), ...globalUseClasses()};
  return(
    <>
      <Grid className={clsx(classes.auth_page_wrapper, classes.no_capitalize) }
        container
        justifyContent={{xs: 'center', md: 'flex-start'}}>
        {/*<NavBar/>*/}
        {/*<Grid item md={!mediaQueries.isXS && !mediaQueries.isSM ? 7 : 12} className={classes.bg_container}>*/}
        <Grid item xs={12} md={7} className={classes.bg_container}/>

        <Grid item xs={12} md={5} className={classes.login_container} >
          <Grid container style={{height: '100%'}}
            justifyContent={{xs: 'center', md: 'flex-start'}}
            alignContent={{xs: 'flex-start', md: 'center'}}

          >

            <Box className={classes.login_wrapper}
              sx={{
                '& .MuiTypography-h2': {alignSelf: 'center'},
                '& .MuiTextField-root': {
                  width: {xs: '80vw', sm: '30ch', lg:'40ch'}
                }
                //width:`${mediaQueries.isXS  ? '80vw' : mediaQueries.isLG ? '40ch' : '30ch'}`},
              }}
            >
              <Box>
                <img src={iPermitLogo} alt={'iPermit-logo'}/>
              </Box>
              <Box style={{minHeight: '350px', display: 'flex', justifyContent:'center', flexDirection:'column'}}>
                {props.children}
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};


export {SingInPageWrapper};


import {Grid, Box} from '@mui/material';
import React from 'react';
import ImportantDatesInfo from '../importantDatesInfo';
import * as allConstants from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';
import {FormLabel, FormValue} from '../../../../pieces/displayJob/formLine';
import {CustomMediaQuery} from '../../../../hooks/customMediaQuery';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';

function GeneralInformation(props) {
  // console.log('props', props);
  const getDates = () => {
    const dates = {
      startDate: {
        label: allConstants.JOB_FIELDS.GENERAL.START_DATE.display_label,
        date: props?.[allConstants.JOB_FIELDS.GENERAL.START_DATE.api_name],
      },
      neededByDate: {
        label: allConstants.JOB_FIELDS.GENERAL.NEED_BY_DATE.display_label,
        date: props?.[allConstants.JOB_FIELDS.GENERAL.NEED_BY_DATE.api_name],
      },
      inspectionRequestDate: {
        label: allConstants.JOB_FIELDS.GENERAL.INSP_REQUEST_DATE.display_label,
        date: props?.[allConstants.JOB_FIELDS.GENERAL.INSP_REQUEST_DATE.api_name],
      },
    };

    // Filter this dictionary for undefined values
    const datesArray = Object.entries(dates);
    const filteredDatesArray = datesArray.filter(([key, value]) => value.date !== undefined);
    const filteredDatesObject = Object.fromEntries(filteredDatesArray);

    return filteredDatesObject;
  };

  const getGeneralInfoFields = () => {
    const generalFields = allConstants.JOB_FIELDS.GENERAL;
    const queries = CustomMediaQuery();
    const labels = [];
    const values = [];

    const generalInfo = {
      propertyType: {
        label: generalFields.PROPERTY_TYPE.display_label,
        value: props?.[generalFields.PROPERTY_TYPE.api_name],
        shouldHideIfEmpty: true,
      },
      jobValue: {
        label: generalFields.JOB_VALUE.display_label,
        value: Utils.formatUsdCurrency(props?.[generalFields.JOB_VALUE.api_name]),
        shouldHideIfEmpty: true,
      },
      newConstruction: {
        label: generalFields.NEW_CONSTRUCTION.display_label,
        value: Utils.boolToText(props?.[generalFields.NEW_CONSTRUCTION.api_name]),
        shouldHideIfEmpty: true,
      },
      hersPo: {
        label: generalFields.HERS_PO_NUMBER.display_label,
        value: props?.[generalFields.HERS_PO_NUMBER.api_name],
        shouldHideIfEmpty: true,
      },
      customerNo: {
        label: generalFields.CUSTOMER_JOB_NUMBER.display_label,
        value: props?.[generalFields.CUSTOMER_JOB_NUMBER.api_name],
        shouldHideIfEmpty: true,
      },
      asbestos: {
        label: generalFields.ASBESTOS.display_label,
        value: Utils.boolToText(props?.[generalFields.ASBESTOS.api_name]),
        shouldHideIfEmpty: true,
      },
      eucRequired: {
        label: generalFields.EUC_TEST_REQUIRED.display_label,
        value: Utils.boolToText(props?.[generalFields.EUC_TEST_REQUIRED.api_name]),
        shouldHideIfEmpty: true,
      },
      postJobCard: {
        label: generalFields.POST_JOB_CARD.display_label,
        value: props?.[generalFields.POST_JOB_CARD.api_name] ? allConstants.POST_JOB_CARD_MAP_VAL_TO_DISPLAY?.[props[generalFields.POST_JOB_CARD.api_name]] : undefined,
        shouldHideIfEmpty: true,
      },
      sqft: {
        label: generalFields.SQFT.display_label,
        value: props?.[generalFields.SQFT.api_name],
        shouldHideIfEmpty: true,
      }
    };

    const jobType = props?.[allConstants.JOB_FIELDS.JOB_TYPE.api_name];
    if(jobType === allConstants.JOB_TYPES.TESTING_ONLY.value) {
      generalInfo.permitNumber = {
        label: 'Permit Number',
        value: props?.[allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name]?.[allConstants.PERMIT_MODULE_FIELDS.NUMBER.api_name],
        shouldHideIfEmpty: true,
      };
      generalInfo.permitDate = {
        label: 'Permit Date',
        value: Utils.formatIsoDateStringWithOptions(props?.[allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name]?.[allConstants.PERMIT_MODULE_FIELDS.DATE.api_name],
          {year: 'numeric', month: '2-digit', day: '2-digit'}),
        shouldHideIfEmpty: true,
      };
    }
    const hersSpecificFields = [
      generalFields.HERS_PO_NUMBER.display_label,
      generalFields.ASBESTOS.display_label,
      generalFields.EUC_TEST_REQUIRED.display_label,
      generalFields.NEW_CONSTRUCTION.display_label,
    ];

    Object.keys(generalInfo).forEach((key) => {

      // If a field is undefined then just put -
      if (generalInfo[key].value !== undefined) {
        // Don't show HVAC-specific fields if job is non-HVAC
        if (jobType !== allConstants.JOB_TYPES.TESTING_ONLY.value &&
        jobType !== allConstants.JOB_TYPES.HVAC_RES.value &&
        jobType !== allConstants.JOB_TYPES.COMMERCIAL.value) {

          const foundHersField = hersSpecificFields.find(field => field === generalInfo[key].label);

          if (foundHersField) {
            return;
          }
        }
        if(Utils.valueIsSpecified(generalInfo[key].value) || !generalInfo[key].shouldHideIfEmpty) {
          labels.push(generalInfo[key].label);
          values.push(generalInfo[key].value);
        }
      }
    });


    let count = 1;
    if(queries.isSM) count = 2;
    if(queries.isMD) count = 3;
    if(queries.isXL || queries.isLG) count = 4;

    const labelChunks = Utils.splitIntoColumns(labels, count);
    const valueChunks = Utils.splitIntoColumns(values, count);
    const dataRows = labelChunks.map((labelArray, index)=> {

      return labelArray.map((label, idx)=> {
        return {label: label, value:valueChunks[index][idx]};
      });
    });
    let currCount = 1;
    const colArray = [];
    while (currCount <= count) {
      const arr = [];
      for (let i = 0; i < dataRows.length; i++) {
        const row = dataRows[i];
        if(row[currCount-1]) {
          arr.push(row[currCount - 1]);
        }
      }
      if(arr.length !== 0) {
        colArray.push(arr);
      }
      currCount++;
    }
    return colArray;
  };

  const datesObject = getDates();
  const dataColumns = getGeneralInfoFields();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} pb={3} sx={{
        maxWidth: '800px',
        margin: '16px auto 0'
      }}>
        {
          (datesObject && Object.keys(datesObject).length > 0) &&
              <ImportantDatesInfo dates={datesObject}></ImportantDatesInfo>
        }
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'}>
        {
          dataColumns.map((col, idx) => {
            const count = dataColumns.length;
            let width;
            switch (count){
            case 4: width = 3; break;
            case 3: width = 4; break;
            case 2: width = 6; break;
            default: width = 12;
            }
            return <Grid key={idx.toString()} xs={width}
              sx={{
                padding: idx === 0 ? '0 20px 0 0' : '0 20px',
                borderRight: idx === count - 1 ? 'none' : `1px solid ${STYLE_CONSTANTS.COLORS.lightGrayHover}`,
              }}>
              {col.map((c, i)=> {
                return (
                  <Box key={`${i}+${idx}`} display={'flex'} marginY={2} sx={{maxWidth: '300px'}}>
                    <Box width={'50%'}>
                      <FormLabel label={c.label} />
                    </Box>
                    <Box>
                      <FormValue value={c.value} />
                    </Box>

                  </Box>
                );
              })

              }
            </Grid>;
          })}

      </Grid>
    </Grid>
  );
}

export default GeneralInformation;

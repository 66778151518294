import {Typography, Box,  Button} from '@mui/material';
import React, {useState} from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import PropTypes from 'prop-types';
import AttentionImage from '../../../src/static/images/Attention-cropped.svg';
import {awsUtils} from '../../utils/awsUtils';
import {AnalyticsCardWrapper} from '../analyticsCardWrapper';
import GettingDataProgress from '../gettingDataProgress';
import NoDataFound from '../noDataFound';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Link} from 'react-router-dom';


const CustomLink = (props)=> {

  return (
    <Button  onDragStart={(e)=> e.preventDefault() }
      endIcon={<ArrowForwardIcon/>}
      style={{color: STYLE_CONSTANTS.COLORS.globalBlue}} variant="text"
      component={Link} to={`${props.path}${props.params}`}>
      {props.text}
    </Button>
  );
};

CustomLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  params: PropTypes.string.isRequired
};


function AnalyticsNumberCard(props) {

  // const [count, setCount] =
  const [data, setData] = useState(sessionStorage.getItem(props.sessionKey) ? Number(JSON.parse(sessionStorage.getItem(props.sessionKey))) : 0);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);

  const saveData = (fetchedData)=> {
    if(fetchedData) {
      if (Array.isArray(fetchedData) && fetchedData[0]) {
        const count = fetchedData[0]?.[props.target.response_key] || '0';
        setData(count);
        sessionStorage.setItem(props.sessionKey, JSON.stringify(count));
      } else {
        sessionStorage.setItem(props.sessionKey, JSON.stringify(fetchedData));
      }
    } else {
      setData('0');
    }
  };
  const dataLength = data.toString().length;

  return (
    <AnalyticsCardWrapper
      target={props.target}
      sessionKey={props.sessionKey}
      returnFetchedData={saveData}
      setParentFetching={(status)=>setFetching(status)}
      setParentFound = {(status)=> setFound(status)}
      fetchUrl={awsUtils.createUrl({
        type: props.target.api_request,
        company: props.company,
        params: props.params,
      })}>

      <Box position={'relative'} textAlign={'center'} height={'100%'}>
        {fetching && <GettingDataProgress/>}
        {!found && !fetching && <NoDataFound/>}
        {found && !fetching &&
          <Box display={'flex'} flexDirection={'column'}
            alignItems={'center'}
            height={'inherit'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'}>
              <Typography sx={{
                position: 'absolute',
                top: 'calc(35%)',
                left: dataLength === 2 ? '50%' : '47%',
                transform: `translate(${dataLength === 2 ? '-50%': '-47%'}, -50%)`,
                fontWeight: 500,
                fontSize: '44px',
                color: `${STYLE_CONSTANTS.COLORS.globalGreen}`,
              }}>{data}</Typography>
              <img width={'140px'} src={AttentionImage} alt='circle_image'/>
              <Typography color={STYLE_CONSTANTS.COLORS.globalBlue} py={1}>{props.legend}</Typography>
            </Box>


            {data && data !== 0 && <Box display="flex" justifyContent="flex-end" pb={2} width={'100%'}>
              <CustomLink path={props.path} params={props.queryParams} text={'View jobs'}/>
            </Box>}
          </Box>
        }


      </Box>

    </AnalyticsCardWrapper>
  );
}

AnalyticsNumberCard.propTypes = {
  company: PropTypes.string,
  params: PropTypes.objectOf(Object),
  legend: PropTypes.string,
  sessionKey: PropTypes.string,
  target: PropTypes.objectOf(PropTypes.shape({
    display: PropTypes.string,
    value: PropTypes.string,
    api_request: PropTypes.string,
  })).isRequired,
  path: PropTypes.string.isRequired,
  queryParams: PropTypes.string.isRequired
};

export default AnalyticsNumberCard;

const ACCOUNTING_ELEMENTS = {
  viewProducts: 'viewProducts',
  addProductButton: 'addProductButton',
  deleteProduct: 'deleteProduct',
  editProduct: 'editProduct'
};

const accountingPolicies = {
  [ACCOUNTING_ELEMENTS.viewProducts]: 'product.view',
  [ACCOUNTING_ELEMENTS.addProductButton]: 'product.modify',
  [ACCOUNTING_ELEMENTS.deleteProduct]: 'product.modify',
  [ACCOUNTING_ELEMENTS.editProduct]: 'product.modify'
};


const productsPageRules = Object.freeze({
  [ACCOUNTING_ELEMENTS.viewProducts]: {
    policyName: accountingPolicies[ACCOUNTING_ELEMENTS.viewProducts],
    contractorAccess: false,
  },

  [ACCOUNTING_ELEMENTS.addProductButton]: {
    policyName: accountingPolicies[ACCOUNTING_ELEMENTS.addProductButton],
    contractorAccess: false,
  },
  [ACCOUNTING_ELEMENTS.editProduct]: {
    policyName: accountingPolicies[ACCOUNTING_ELEMENTS.addProductButton],
    contractorAccess: false,
  },
});

export {productsPageRules, ACCOUNTING_ELEMENTS};

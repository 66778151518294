import React, {useEffect, useState} from 'react';
import {MenuItem, Menu} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import Utils from '../utils/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {urlSearchParams} from '../baseUrls';

class MenuOption {
  constructor(value, title, show) {
    this.value = value;
    this.title = title;
    this.shouldShow = show ? show : () => true;
    return this;
  }
}

const useViewMenu = (props)=> {
  const [viewGrid, setViewGrid] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const open = Boolean(anchorEl);

  const GridEnum = props.items;

  const handleMenuItemClick = (event, index, option) => {
    const gridIndex = GridEnum.findIndex((grid) => {
      return grid.value === option.value;
    });
    setViewGrid(gridIndex);
    setAnchorEl(null);
  };

  const showMenuListOptions = () => {
    const grid = GridEnum.filter((item) => {
      return item.shouldShow();
    });

    const menuItems = grid.map((option, index) => (
      <MenuItem
        key={option.value+index}
        onClick={(event) => handleMenuItemClick(event, index, option)}
      >
        {option.title}
      </MenuItem>
    ));
    return menuItems;
  };

  const changeView = () => {
    const view = searchParams.get(urlSearchParams.view);
    const keys = Object.keys(GridEnum);

    keys.forEach((key, index) => {
      if (GridEnum[key].value === view) {
        setViewGrid(index);
      }
    });

    //if (job) { viewJobHandler(job); }
  };

  useEffect(() => {
    changeView();
  }, []);

  useEffect(()=> {
    let params = {view: GridEnum[viewGrid].value};
    setSearchParams(Utils.createQueryParam(location, params));
  }, [viewGrid]);

  return {
    controller: GridEnum.length !== 1 ?  <KeyboardArrowDownIcon/> : null,
    menuItems: GridEnum.length !== 1 ? <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={()=> setAnchorEl(null)}
    > {showMenuListOptions()}</Menu> : null,
    viewGrid: viewGrid,
    setAnchorEl: setAnchorEl,
    title: GridEnum[viewGrid].title,
  };

};

export default useViewMenu;
export {MenuOption};

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import base_url, {urlSearchParams} from '../../baseUrls';
import * as allConstants from '../../constants/allConstants';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {RATER_ELEMENTS, ratersPageRules} from '../../permissions/raterPageAccessControl';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import ApiUtils from '../../utils/apiUtils';
import {toast} from 'react-toastify';


const AllRatersGrid = (props) => {
  const viewController = props.viewController;
  const navigate = useNavigate();
  const [components] = useState({
    'statusRenderer': StatusRenderer,
  });

  const options = {
    markets: useAutocompleteOptions({field: 'markets'}).options,
    raterDepartments: useAutocompleteOptions({field: 'raterDepartments'}).options
  };

  if(!options.markets || !options.raterDepartments) return null;

  let columnDefs = [
    new ColumnDefinition({field: allConstants.RATER_FIELDS.ID, hide: true}),
    new ColumnDefinition({field: allConstants.RATER_FIELDS.NAME,
      lockPinned: true,
      pinned: 'left',
      checkboxSelection: true,
      width: 350,
      wrapText: true
    }),
    new ColumnDefinition({field: allConstants.RATER_FIELDS.DEPARTMENTS,
      width: 200,
      filter:'agSetColumnFilter',
      filterValues: options.raterDepartments ? options.raterDepartments.map((s)=> s.value) :[],
    }),
    new ColumnDefinition({field: allConstants.RATER_FIELDS.STATUS, cellRenderer: 'statusRenderer',
      filter:'agSetColumnFilter',
      filterValues: Object.values(allConstants.RATER_STATUSES).map((s)=> s.value),
      width: 200}),

    new ColumnDefinition({field: allConstants.RATER_FIELDS.MARKETS,
      width: 200,
      filter:'agSetColumnFilter',
      filterValues: options.markets ? options.markets.map((s)=> s.value) :[],
    }),
    new ColumnDefinition({field: allConstants.RATER_FIELDS.EMAIL}),
    new ColumnDefinition({field: allConstants.RATER_FIELDS.PHONE_NUMBER, width: 250}),
  ];


  const editRaterHandler = (raterData)=> {
    navigate(`${allConstants.PATH.RATER}${location.search}&${urlSearchParams.raterId}=${raterData.id}`);
  };

  const isRaterDisabled= (raterData)=> {
    return raterData?.[allConstants.RATER_FIELDS.STATUS.api_name] === allConstants.RATER_STATUSES.DISABLED.value;
  };


  const changeRaterStatus= async (raterData) => {
    const isDisabled = isRaterDisabled(raterData);
    const successMessage = `${isDisabled ? 'Activated' : 'Disabled' }: ${raterData?.[allConstants.RATER_FIELDS.NAME.api_name]}`;
    const errorMessage = `Error on ${isDisabled ? 'activation' : 'deactivation'}.`;
    const endpoint = isDisabled ? 'ipermit/raters/activate' : 'ipermit/raters/deactivate';

    const result = await ApiUtils.makeApiCall('POST', endpoint, JSON.stringify({id: raterData?.id}));
    if(result.status === 'error') {
      toast.error(result?.message || errorMessage, {
        toastId: 'changeRaterStatusError',
      });
    } else {
      viewController.refreshOnSuccess(result);
      toast.success(successMessage, {
        toastId: 'changeRaterStatusSuccess',
      });
    }
  };

  const getContextMenuItems = (params)=> {
    if(!params.node) return [];

    const standardActions = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const actions = {
      edit: {
        name: 'View/Edit',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          editRaterHandler(params?.node?.data);
        },
      },
      activate: {
        name: 'Activate Rater',
        disabled: viewController.selectedMultipleRows(),
        action: async ()=> {
          await changeRaterStatus(params?.node?.data);
        },
        cssClasses: ['blueFont', 'bold'],
      },
      deactivate: {
        name: 'Disable Rater',
        disabled: viewController.selectedMultipleRows(),
        action: async ()=> {
          await changeRaterStatus(params?.node?.data);
        },
        cssClasses: ['redFont', 'bold'],
      },
    };

    let allowedActions = [actions.edit];

    allowedActions = [...allowedActions, ...standardActions];

    if(NewPermissionGate({
      elementPermissions: ratersPageRules[RATER_ELEMENTS.activate],
      user: viewController.user,
    })) {
      allowedActions.push('separator');
      if(isRaterDisabled(params?.node?.data)) {
        allowedActions.push(actions.activate);
      } else {
        allowedActions.push(actions.deactivate);
      }
    }

    return allowedActions;
  };

  const ls_name = 'raters_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      fetchParams={{url: `${base_url.api}ipermit/raters`, respKey:'raters'}}
      companyId={viewController.companyId}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
      refreshTimestamp={viewController.refreshTimestamp}

    />
  );
};

AllRatersGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,

};
export default AllRatersGrid;

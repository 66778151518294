import React from 'react';
import PropTypes from 'prop-types';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import Utils from '../../../../../utils/utils';
import * as allConstants from '../../../../../constants/allConstants';
import {useFormContext} from 'react-hook-form';

const RepairDrainsPanel = (props)=> {
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const subParent = `${allConstants.JOB_FIELDS.PLUMBING.REPAIR.api_name}.${allConstants.JOB_FIELDS.PLUMBING.DRAINS.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.PLUMBING.api_name];
  const getFieldValue = (fieldName)=> {
    const blockValues = values?.[FIELDS.REPAIR.api_name]?.[FIELDS.DRAINS.api_name];
    return blockValues?.[fieldName];
  };


  return   <>
    <CustomFormBlock{...props}
      field={FIELDS.DRAIN_LENGTH}
      nested={true}
      parent={blockParent}
      defaultValue={getFieldValue(FIELDS.DRAIN_LENGTH.api_name)}
      renderLabel={true}
    />
    <CustomFormBlock{...props}
      field={FIELDS.DRAIN_SIZE}
      nested={true}
      parent={blockParent}
      defaultValue={getFieldValue(FIELDS.DRAIN_SIZE.api_name)}
      renderLabel={true}
    />
    <CustomFormBlock{...props}
      inputType={inputTypes.multiSelect}
      field={FIELDS.DRAIN_FIXTURES}
      nested={true}
      parent={blockParent}
      options={Utils.getOptions(allConstants.REPAIR_FIXTURES_OPTIONS)}
      defaultValue={getFieldValue(FIELDS.DRAIN_FIXTURES.api_name)}
      renderLabel={true}
    />
  </>;
};

RepairDrainsPanel.propTypes = {
  parent: PropTypes.string.isRequired,
};

export {RepairDrainsPanel};

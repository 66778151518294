const IPERMIT_ROLES = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  IPERMIT_USER: 'IPERMIT_USER',
  IPERMIT_ADMIN: 'IPERMIT_ADMIN',
};

const CONTRACTOR_ROLES = {
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  CONTRACTOR: 'CONTRACTOR',
};
const ROLES = {

  ...IPERMIT_ROLES, ...CONTRACTOR_ROLES
};


export {IPERMIT_ROLES, CONTRACTOR_ROLES};
export default ROLES;

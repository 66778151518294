import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {Accordion, AccordionDetails, AccordionSummary} from './accordionTab';
import Spacer from '../spacer';


const JobFormAccordionTab = (props)=> {

  // console.log('JobFormAccordionTa');
  // console.log(props);
 
  if(!props.show) return null;
  return (
    <>

      <Accordion defaultExpanded={props.alwaysOpen}>
        <AccordionSummary

          aria-controls={props.aria_controls}
          id={props.id}

        >
          <Typography variant={'subtitle1'}
            style={{
              margin: 0,
              color: STYLE_CONSTANTS.COLORS.globalBlue, fontSize: '20px'
            }}>
            {props.header}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
      <Spacer y={2}/>
    </>
  );

};

JobFormAccordionTab.propTypes = {
  aria_controls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  alwaysOpen: PropTypes.bool,
  classes: PropTypes.shape({
    // acc_details: PropTypes.any
  }).isRequired,
  show: PropTypes.bool
};

JobFormAccordionTab.defaultProps = {
  alwaysOpen: false,
  show: true,
};

export default JobFormAccordionTab;

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import clsx from 'clsx';
import {useFormContext} from 'react-hook-form';

export const MaterialInfo = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.REROOF;
  const subParent = `${FIELDS.ROOFING_MATERIALS.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.REROOF.api_name]?.[FIELDS.ROOFING_MATERIALS.api_name];

  const getFieldValue = (fieldName)=> {
    return  values?.[fieldName];
  };
  const commonProps = {
    nested: true,
    parent: blockParent,
    fullWidth: true,
    renderLabel: true,
  };
  return (
    <Grid container justifyContent={'center'} spacing={{md: 6, lg: 12}}>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <CustomFormBlock
          {...props} {...commonProps}
          field={FIELDS.MANUFACTURER}
          defaultValue={getFieldValue(FIELDS.MANUFACTURER.api_name)}
        />
        <CustomFormBlock
          {...props} {...commonProps}
          field={FIELDS.MODEL_STYLE}
          defaultValue={getFieldValue(FIELDS.MODEL_STYLE.api_name)}
        />
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            field={FIELDS.COLOR}
            defaultValue={getFieldValue(FIELDS.COLOR.api_name)}
          />
          <CustomFormBlock
            {...props} {...commonProps}
            field={FIELDS.COMP_YEAR}
            defaultValue={getFieldValue(FIELDS.COMP_YEAR.api_name)}
          />
        </Box>

      </Grid>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.number}
            field={FIELDS.ICC}
            defaultValue={getFieldValue(FIELDS.ICC.api_name)}
          />
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.number}
            field={FIELDS.CRRC}
            defaultValue={getFieldValue(FIELDS.CRRC.api_name)}
          />
        </Box>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.number}
            field={FIELDS.LA_RR}
            defaultValue={getFieldValue(FIELDS.LA_RR.api_name)}
          />
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.number}
            field={FIELDS.UL}
            defaultValue={getFieldValue(FIELDS.UL.api_name)}
          />
        </Box>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.number}
            field={FIELDS.OTHER}
            defaultValue={getFieldValue(FIELDS.OTHER.api_name)}
            fullWidth={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
MaterialInfo.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default MaterialInfo;

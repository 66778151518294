import {makeStyles} from '@mui/styles';
import blueWaveBgImage from '../../static/images/blue-wave-bg.png';
import loginBgImage1200 from '../../static/images/login_bg_1200.png';
import loginBgImage900 from '../../static/images/login_bg_900.png';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

const useStylesLogin = makeStyles(theme => {

  return {
    auth_page_wrapper: {
      position: 'relative',
      height: '100vh',
      borderBottom: 'none',
      zIndex: 0,
      '& .MuiFormHelperText-root': {
        marginLeft: 0,
      },
      // backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
      // [theme.breakpoints.between('xs', 'sm')]: {
      // borderBottom:  `15vh solid ${STYLE_CONSTANTS.COLORS.backgroundMain}`,
      // backgroundColor: `${STYLE_CONSTANTS.COLORS.backgroundMain}`
      // ,
      // borderBottom: `${props.isXS || props.isSM ? `15vh solid ${STYLE_CONSTANTS.COLORS.backgroundMain}`: 'none'}`,
      //backgroundColor: `${props.isSM || props.isXS ? STYLE_CONSTANTS.COLORS.backgroundMain : ''}`,

      // },
      [theme.breakpoints.up('sm')]: {
        '&:before' : {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: '-10',
          background: `url(${blueWaveBgImage})`,
          backgroundPositionY: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
        },
        // '&:before': props.isXS || props.isSM ? {} : {
        //   content: '""',
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   width: '100%',
        //   height: '100%',
        //   zIndex: '-10',
        //   background: `url(${blueWaveBgImage})`,
        //   backgroundRepeat: 'no-repeat',
        //   backgroundSize: '100%',
        //backgroundPositionY: `${props.isMD ? 'bottom' : 'unset'}`,

      },
      '& .MuiOutlinedInput-input': {
        backgroundColor: '#FFF',
      },
      '& .MuiInputAdornment-root': {
        backgroundColor: 'inherit',
        padding: '18px 0',
      }

    },
    bg_container: {
      position: 'relative',
      width: '100%',
      borderBottom: '15vh solid #edf2fd00',
      backgroundImage: 'none',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        backgroundSize: '80vh',
        backgroundImage: `url(${loginBgImage1200})`,
        display: 'initial',
      },


      // backgroundImage: `${props.isMD || props.isLG || props.isXL?  `url(${loginBgImage1200})` : 'none'}`,

      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 'bottom',
      //display: `${props.isXS || props.isSM ? 'none' : 'initial'}`,
      height: '100%',

    },
    form_header: {
      // marginBottom: myTheme.spacing(props.isXS ? 4 : 2),
      //       fontSize: `${props.isXS ? '28px' : '36px'}`
      marginBottom: theme.spacing(4),
      fontSize: '28px',
      [theme.breakpoints.up('xs')]: {
        marginBottom: theme.spacing(2),
        fontSize: '36px'
      }
    },
    login_container: {
      paddingTop: 0,
      // backgroundImage: `${props.isSM?  `url(${loginBgImage900})` : 'none'}`,
      // backgroundColor: `${props.isXS || props.isSM ? STYLE_CONSTANTS.COLORS.background : 'unset'}`,
      [theme.breakpoints.only('sm')]: {
        backgroundImage: `url(${loginBgImage900})`,

      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '5vh',
        backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain
      },
      backgroundSize: '50vh',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 'bottom',
      // paddingTop: `${props.isXS || props.isSM ? '5vh' : '0'}`,
    },
    login_wrapper: {
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: '240px',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.only('xs')]: {
          width: '150px',
          marginBottom: theme.spacing(6)
        }
        //width: `${props.isXS ? '150px' : '240px'}`,
        //marginBottom: myTheme.spacing(props.isXS ? 6 : 4),
      }
    },

    button_simple: {
      textTransform: 'none',
      fontSize: '1em',
      color: STYLE_CONSTANTS.COLORS.globalBlue,
      '&:hover': {
        backgroundColor: 'initial'
      },
      '&:active': {
        backgroundColor: 'initial'
      }
    },
    adornedEnd:{
      paddingRight: 0,
    },
    go_back_link:{
      color: STYLE_CONSTANTS.COLORS.fontMain,
      fontSize: '16px'
    }
  };});

export {useStylesLogin};


import React, {useMemo, useRef, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Box, Grid} from '@mui/material';
import FormSubmitButtons from '../pieces/formSubmitButtons';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as allConstants from '../constants/allConstants';
import Utils from '../utils/utils';
import ModalWrapper, {ModalContentWrapper} from '../pieces/modals/modalWrapper';
import CustomFormBlock from '../pieces/inputs/formBlock';
import {Text} from '../pieces/text';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import Spacer from '../pieces/spacer';

export const useConfirmationForStatusChange = (module)=> {
  const currentModule = useMemo(()=>module, [module]);
  const [show, setShow] = useState(false);
  const [noteRequired, setNoteRequired] = useState(false);
  const [noteText, setNoteText] = useState(null);
  const [pendingStatus, setPendingStatus] = useState(null);
  const submitAction = useRef(null);

  const validationSchema = Yup.object().shape({
    [allConstants.JOB_FIELDS.NOTE.TEXT.api_name]: Yup.string().required('Please leave a note')
  });

  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const confirmForm = useForm(formOptions);
  if(Utils.isDevEnv()) {
    window.confirmForm = confirmForm;
  }

  const askConfirmation = ({action, noteRequired, pendingStatus, noteText})=> {
    submitAction.current = action;
    setPendingStatus(pendingStatus);
    setNoteText(noteText);
    if(noteRequired) setNoteRequired(true);
    setShow(true);
  };

  const onSubmit = async (data)=> {
    if(!submitAction.current) return;
    if(noteRequired) {
      //submit with note
    }
    await submitAction.current();
    setShow(false);
  };

  const onCancel = ()=> {
    setShow(false);
  };

  const Confirmation =  ()=><>
    <ModalWrapper modalName={'confirmWithNote'}

      handleClose={onCancel}
      open={show}
      preventScroll={true}
      width={{sx: '98%', sm: '600px'}}
      padding={6}
      customStyle={{paddingTop: 2}}
    >
      <ModalContentWrapper handleClose={onCancel} header={noteRequired ? 'Please leave a note' : `Changing ${currentModule} status to ${pendingStatus}`}>
        <FormProvider {...confirmForm}>
          <form onSubmit={confirmForm.handleSubmit(onSubmit)} method={'POST'}>
            {noteRequired && <Grid container xs={12}>
              <Text text={'Please leave a note'} color={STYLE_CONSTANTS.COLORS.globalRed} size={'xLarge'}/>
              <CustomFormBlock
                field={allConstants.JOB_FIELDS.NOTE.TEXT}
                renderLabel={false}
                multiline={true}
                rowCount={5}
                required={true}
                isEditable={true}
              />
            </Grid>}
            {noteText && <Grid container xs={12}>
              <Text text={'warning:'} uppercase={true} color={STYLE_CONSTANTS.COLORS.globalBlue} weight={'bold'} size={'xLarge'}/>
              <Spacer x={1}/>
              <Text text={noteText} size={'xLarge'}/>
            </Grid>}

            {!noteRequired && <Grid container xs={12}>
              <Spacer y={2}/>
              <Text text={'Are you sure you want to change status?'} size={'xLarge'}/>
            </Grid>}
            <Grid xs={12}>
              <Spacer y={2}/>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancel}
                  submitText={noteRequired ? 'Submit' : 'Yes, change status'}
                  cancelText={noteRequired ? 'Cancel': 'No, go back'}
                  submitAction={noteRequired ? null : onSubmit}
                />
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </ModalContentWrapper>
    </ModalWrapper>

  </>;

  return {
    ConfirmPopup: Confirmation,
    askConfirmation: askConfirmation
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Typography,Paper} from '@mui/material';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {useCreateJobStyles} from '../createJobStyles';

const JobPaperWrapper = (props)=> {
  const classes = {...globalUseClasses(), ...useCreateJobStyles()};

  return(
    <Box style={{maxWidth: '1300px', margin: 'auto',
      marginBottom: '20px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%'}}>
      {props.showHeader &&
      <Box
      ><Typography variant={'h5'} className={classes.block_header}>{props.header}</Typography>
      </Box>

      }
      <Paper elevation={0} className={clsx(classes.create_job_paper, classes.paper_style)}

        sx={{...props.styles}}>
        {props.children}
      </Paper>
    </Box>
  );
};
JobPaperWrapper.propTypes = {
  header: PropTypes.string,
  showHeader: PropTypes.bool,
  styles: PropTypes.instanceOf(Object)
};
JobPaperWrapper.defaultProps = {
  header: '',
  showHeader: false,
  styles: {},
};
export {JobPaperWrapper};

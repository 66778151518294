import React from 'react';
import PropTypes from 'prop-types';
import {Box, List, ListItem} from '@mui/material';
//import {Text} from '../text';
import TagImage from '../../static/images/tag-green.png';

export const LineBreakCellRenderer = ({value})=>{

  if (!value) return <></>;

  //if (value == undefined) return <></>;

  //if (value.tagNames.length <= 0 )return <></
  //console.log('value=>>>');
  //console.log(value);
  //console.log('showTag=>', showTag);

  return (
    <>
    <List>
      {value.tagNames.map ((item, idx) => (
        <ListItem key={item + idx} sx={{padding: '0'}}>
          {value.showTag && <img src={TagImage}  width="20px" height="16px"  />}
          &nbsp;&nbsp;{item}
        </ListItem>
      ))}
    </List>
    </>
  )
};

LineBreakCellRenderer.propTypes = {
  params: PropTypes.instanceOf(Array)
};

LineBreakCellRenderer.defaultProps = {
  value: []
};

export default LineBreakCellRenderer;

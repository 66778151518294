import {CONTRACTOR_ROLES} from '../constants/allConstants';
const CONTRACTOR_JOB_ELEMENTS = {
  createJob: 'createJob',
  addNote: 'addNote',
  addAttachment: 'addAttachment',
  editMap: 'editMap',
  contractorJobView: 'contractorJobView',
};

const contractorJobRules = Object.freeze({
  [CONTRACTOR_JOB_ELEMENTS.createJob]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: Object.values(CONTRACTOR_ROLES),
  },
  [CONTRACTOR_JOB_ELEMENTS.addNote]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: Object.values(CONTRACTOR_ROLES),
  },
  [CONTRACTOR_JOB_ELEMENTS.addAttachment]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: Object.values(CONTRACTOR_ROLES),
  },
  [CONTRACTOR_JOB_ELEMENTS.editMap]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: Object.values(CONTRACTOR_ROLES),
  },
  [CONTRACTOR_JOB_ELEMENTS.contractorJobView]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: Object.values(CONTRACTOR_ROLES),
  }

});

export {contractorJobRules, CONTRACTOR_JOB_ELEMENTS};

import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import * as allConstants from '../constants/allConstants';
import PropTypes from 'prop-types';
import Utils from '../utils/utils';
import {urlSearchParams} from '../baseUrls';
import LOCAL_STORAGE_KEYS from '../utils/localStorageKeys';

const AnonymousRoute = ({children}) => {
  const userReducer = useSelector(state => state.user);
  if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSG === 'mock'){
    return children;
  }

  const isAuthenticated = userReducer.isAuthenticated;

  // if (loading) {
  //   return <p>Checking authenticaton..</p>;
  // }

  if (isAuthenticated) {

    if(localStorage.getItem(LOCAL_STORAGE_KEYS.redirectUrl)) {
      const url = localStorage.getItem(LOCAL_STORAGE_KEYS.redirectUrl);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.redirectUrl);
      window.location.replace(url);
      return;
    }

    const redirectUrl = Utils.getUrlParameter(urlSearchParams.redirect);
    return <Navigate to={redirectUrl ? redirectUrl : allConstants.PATH.HOME} replace={true}/>;
  }

  return children;
};
AnonymousRoute.propTypes = {
  children:  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

AnonymousRoute.defaultProps = {
  children: null,
};

export default AnonymousRoute;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import {Box, Button, Table, TableBody, TableCell, TableRow} from '@mui/material';
import Utils from '../../utils/utils';


export const CityDocumentsByJobType = (props) => {
  const [documents, setDocuments] = useState(props.documents);

  useEffect(()=> {
    setDocuments(props.documents);
  }, [props.documents]);

  const docsTable = (()=> {
    const jobTypes = Object.values(allConstants.JOB_TYPES).map((type)=> type.value);
    const table = new Map();
    table.set(allConstants.ALL_JOBS_TYPE.value, []);
    jobTypes.forEach((jobType)=> {

      table.set(jobType,  []);
    });
    documents.forEach((doc)=> {
      let docJobTypes = [...doc?.[allConstants.JOB_FIELDS.JOB_TYPE.api_name]];
      if(docJobTypes.includes(allConstants.ALL_JOBS_TYPE.value)) {
        table.set(allConstants.ALL_JOBS_TYPE.value, table.get(allConstants.ALL_JOBS_TYPE.value).concat([doc]));
      } else {
        docJobTypes.forEach((jobType)=> {
          table.set(jobType, table.get(jobType).concat([doc]));
        });
      }
    });

    const tableRows = [];
    for (let [key, value] of  table.entries()) {
      if (value && value.length > 0) {
        tableRows.push({name: key, docs: value});
      }
    }
    return tableRows;
  })();

  return (
    <>
      {docsTable && docsTable.length > 0 && <Table sx={{minWidth: 650}} aria-label="city documents table">
        <TableBody>
          {docsTable.map((row) => (
            <TableRow key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {border: 0},
                '& .MuiTableCell-root': {
                  paddingY: '4px'
                }
              }}
            >
              <TableCell component="th" scope="row" sx={{width: '200px', textTransform: 'none !important', fontWeight: '600'}}>
                {row.name}
              </TableCell>
              <TableCell>
                <Box display={'grid'}
                  sx={{
                    gridTemplateColumns: {xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'},
                  }}>
                  {row.docs.map((doc, index)=> {
                    return (
                      <Button key={index + doc.fileId} className={'city-doc'}
                        disableRipple sx={{padding: '0px', justifyContent: 'left'}}
                        value={doc.fileId}
                      >{Utils.trimString(doc.name, 30)}</Button>
                    );
                  } )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
    </>

  );

};

CityDocumentsByJobType.propTypes = {
  documents: PropTypes.instanceOf(Array)
};

CityDocumentsByJobType.defaultProps = {
  documents: []
};

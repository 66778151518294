import React from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import JobFormAccordionTab from '../../../../pieces/accordion/jobFormAccordionTab';
import Repipe from './repipeFormBlock';
import Repair from './repairFormBlock';
import Sanitation from './sanitationFormBlock';
import WaterHeater from './waterHeaterFormBlock';
import WaterSoftener from './waterSoftnerFromBlock';
import Replace from './replaceFormBlock';


const Plumbing = (props)=> {
  const classes = {...globalUseClasses()};
  const tabs = [
    {
      name: 'water_heaters',
      header: 'Water Heater',
      content:WaterHeater
    },
    {
      name: 'repipe',
      header: 'Repipe',
      content: Repipe
    },
    {
      name: 'repair',
      header: 'Repair',
      content: Repair
    },
    {
      name: 'replace',
      header: 'Replace',
      content: Replace
    },
    {
      name: 'water_softner',
      header: 'Water Softner',
      content:WaterSoftener
    },
    {
      name: 'sanitation',
      header: 'Sanitation',
      content: Sanitation
    },
  ];

  return (
    <>
      {tabs.map((tab, idx)=> {
        return(
          <JobFormAccordionTab classes={classes}
            key={idx}
            header={tab.header}
            id={`${tab.name}-header`}
            aria_controls={`${tab.name}-content`}
          >{tab.content({...props, ...{nested: true, parent: allConstants.JOB_TYPES.PLUMBING.api_name}})}</JobFormAccordionTab>
        );
      })}
    </>
  );
};

export default Plumbing;

export const initialUserState = {
  id:'',
  userName: {firstName:'', lastName:''},
  role: '',
  email: '',
  status:'',
  companyId: null,

};
export const initialServerState = {
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage:null,
};

export const cleanedServerState = {
  ...initialServerState, ...{isFetching: false}
};
import {Box, List, ListItem, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {ANALYTICS_TARGETS} from '../../../constants/options/analyticsTargets';
import {AnalyticsBaseCard, AnalyticsHeader} from '../../../pieces/analytics/analyticsBaseCard';
import DotLegend from '../../../pieces/analytics/dotLegend';
import TwoValuesBarChart from '../../../pieces/analytics/twoValuesBarChart';
import GettingDataProgress from '../../../pieces/gettingDataProgress';
import MonthYearPicker from '../../../pieces/monthYearPicker';
import NoDataFound from '../../../pieces/noDataFound';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {awsUtils} from '../../../utils/awsUtils';
import {useAnalyticsStyles} from '../analyticsStyles';

const HersResults = (props)=> {
  const sessionDateKey = props.sessionKey + 'Date';
  const classes = useAnalyticsStyles();
  const [data, setData] = useState(sessionStorage.getItem(props.sessionKey) ? JSON.parse(sessionStorage.getItem(props.sessionKey)) : []);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);
  const [date, setDate] = useState(sessionStorage.getItem(sessionDateKey) ? new Date(sessionStorage.getItem(sessionDateKey)) : new Date());

  const names = {
    PASSED: 'passed',
    FAILED: 'failed'
  };

  const saveData = (fetchedData)=> {
    if(fetchedData && Array.isArray(fetchedData)){
      const statusCount = [];

      if (fetchedData[0]?.hers_status === 'completed') {
        statusCount.push(fetchedData[0]?.status_count ?? 0);
        statusCount.push(fetchedData[1]?.status_count ?? 0);
      } else {
        statusCount.push(fetchedData[1]?.status_count ?? 0);
        statusCount.push(fetchedData[0]?.status_count ?? 0);
      }

      setData(statusCount);
      sessionStorage.setItem(props.sessionKey, JSON.stringify(statusCount));
    } else {
      setData([0, 0]);
    }
  };

  const displayDate = (date) => {
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', {month: 'long'});
    const displayDate = `${month} - ${year}`;
    return displayDate;
  };

  const saveDate = (value) => {
    setDate(value);
    sessionStorage.setItem(sessionDateKey, value.toISOString());
    fetchData(value);
  };

  const fetchData = (date)=> {
    const fetchMonth = date.getMonth();
    const fetchYear = date.getFullYear();

    const fetchUrl = awsUtils.createUrl({
      type: ANALYTICS_TARGETS.HERS_TEST_RESULTS.api_request,
      company: props.company,
      params: {month: fetchMonth, year: fetchYear}
    });
    setFetching(true);

    fetch(`${fetchUrl}`)
      .then(response=> { return response.json();}).then(
        (result) => {
          saveData(result.queryResult);
          setFound(true);
          setFetching(false);
        },
        (error) => {
          console.log('error...', error);
          setFound(false);
          setFetching(false);
        }
      );
  };

  useEffect(()=> {
    if (data.length === 0) {
      fetchData(date);
    }
  }, []);

  // useEffect(()=> {
  // fetchData();
  // }, [date]);

  let content;
  if(fetching) {
    content = <GettingDataProgress/>;
  } else if(!found) {
    content = <NoDataFound/>;
  } else {
    content =
    <>
      <TwoValuesBarChart value={data}/>
      <List>
        <ListItem
          className={classes.item}
          disableGutters
          key={names.PASSED}>
          <DotLegend color={STYLE_CONSTANTS.COLORS.globalBlue} text={`${data[0]} HERS Tests ${names.PASSED}`}/>
        </ListItem>
        <ListItem
          disableGutters
          key={names.FAILED}>
          <DotLegend color={STYLE_CONSTANTS.COLORS.globalGreen} text={`${data[1]} HERS Tests ${names.FAILED}`}/>
        </ListItem>
        <ListItem
          disableGutters
          key={'Date'}>
          <Typography variant={'h4'}>{displayDate(date)}</Typography>
        </ListItem>
      </List>
    </>;
  }

  return(
    <Box sx={{width: props.mediaQueries.isXL ? '565px' : '465px'}}>
      <AnalyticsBaseCard title={
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <AnalyticsHeader title={'HERS Test Results'}/>
          <MonthYearPicker views={['year', 'month']} defaultDate={date}  handler={(value) => { saveDate(value); }}/>
        </Box>
      }>
        <Box display={'flex'} flexDirection={'row'} height={'100%'} justifyContent={!found ? 'center' :'flex-start'}>
          {content}
        </Box>
      </AnalyticsBaseCard>
    </Box>

  );
};

HersResults.propTypes = {
  company: PropTypes.string.isRequired,
  sessionKey: PropTypes.string.isRequired,
  mediaQueries: PropTypes.shape({
    isXL: PropTypes.bool
  })
};

HersResults.defaultProps = {
  mediaQueries: {
    isXL: false
  }
};
export {HersResults};


const EQUIPMENT = {

  // FURNACE: {api_name: 'furnace', display_name: ''},
  // GAS_PU: 'gas package unit',
  // AIR_HANDLER: 'air handler',
  // HEAT_PUMP_PU: 'heat pump package unit',
  // CONDENSER: 'condenser',
  // COIL: 'coil',
  // HEAT_PUMP_COND: 'heat pump condenser',
  // MINI_SPLIT: 'mini split',
  // MINI_SPLIT_DUCTED: 'mini split ducted',
  // DUCTS: 'ducts',
  TEMP_POWER_POLES: {api_name: 'temp_power_poles', display_name: 'Temp Power Poles'},
  // // TEMP_POWER_POLES_CIRCUITS: 'temp power poles circuits',
  SUB_POLES: {api_name: 'sub_poles', display_name: 'Sub Poles'},
  // // SUB_POLES_CIRCUITS: 'sub poles circuits',
  SUB_DISTRO_PANELS: {api_name: 'sub_distro_panels', display_name: 'Sub/Distro Panels'},
  TRANSFORMERS: {api_name: 'transformers', display_name: 'Transformers'},
  GENERATORS: {api_name: 'generators', display_name: 'Generators'},
  WATER_HEATER_TANKLESS: {api_name: 'water_heater_tankless', display_name: 'water heater tankless'},
  WATER_HEATER_TRADITIONAL: {api_name: 'water_heater_traditional', display_name: 'water heater traditional'},
  EXPANSION_TANKS: {api_name: 'expansion_tanks', display_name: 'expansion tanks'},
  WATER_SOFTNER: {api_name: 'water_softner', display_name: 'water softner'},
  MOTORS: {api_name: 'motors', display_name: 'motors'},
  BR_CIR: {api_name: 'branch_circuits', display_name: 'branch circuits'},
  EV_CAR: {api_name: 'ev_car', display_name: 'ev car'},
  SUB_PANELS: {api_name: 'sub_panels', display_name: 'sub panels'},
  DISC_BOX: {api_name: 'disconnected_boxes', display_name: 'disconnected boxes'},
  FURNACE: {api_name: 'furnace', display_name: 'Furnace'},
  GAS_PU: {api_name: 'gas_package_unit', display_name: 'Gas Package Unit'},
  AIR_HANDLER: {api_name: 'air_handler', display_name: 'Air Handler'},
  HEAT_PUMP_PU: {api_name: 'heat_pump_package_unit', display_name: 'Heat Pump Package Unit'},
  CONDENSER: {api_name: 'condenser', display_name: 'Condenser'},
  COIL: {api_name: 'coil', display_name: 'Indoor Coil'},
  HEAT_PUMP_COND: {api_name: 'heat_pump_condenser', display_name: 'Heat Pump Condenser'},
  MINI_SPLIT: {api_name: 'mini_split', display_name: 'Mini Split (Ductless)'},
  MINI_SPLIT_DUCTED: {api_name: 'mini_split_ducted', display_name: 'Mini Split (Ducted)'},
  R6: {api_name: 'duct_r6', display_name: 'R6 Ducts'},
  R8: {api_name: 'duct_r8', display_name: 'R8 Ducts'},
  R10: {api_name: 'duct_r10', display_name: 'R10 Ducts'},
  DUCTS: {api_name: 'ducts', display_name: 'Ducting'},
  EXHAUST_FANS: {api_name: 'exhaust_fans', required: false, label: 'Exhaust Fans', display_label: 'Exhaust Fans'},
  VENT_FANS: {api_name: 'vent_fans', required: false, label: 'Vent Fans', display_label: 'Vent Fans'},
  REG_GRILLS: {api_name: 'registers_grills', required: false, label: 'Registers/Grills', display_label: 'Registers/Grills'},
  SMOKE_DAMP: {api_name: 'smoke_dampers', required: false, label: 'Smoke Dampers', display_label: 'Smoke Dampers'},
  FIRE_DAMP: {api_name: 'fire_dampers', required: false, label: 'Fire Dampers', display_label: 'Fire Dampers'},
  SMOKE_FIRE_DAMP: {api_name: 'smoke_fire_dampers', required: false, label: 'Smoke/Fire Dampers', display_label: 'Smoke/Fire Dampers'},
  DUCT_SMOKE_DET: {api_name: 'duct_smoke_detectors', required: false, label:'Duct Smoke Detectors', display_label: 'Duct Smoke Detectors'},

};

const EQ_NAME_TO_API_MAPPING = {};
const EQ_API_TO_NAME_MAPPING = {};
// eslint-disable-next-line no-unused-vars
for (const [key, value] of Object.entries(EQUIPMENT)) {
  EQ_NAME_TO_API_MAPPING[value.display_name] = value.api_name;
  EQ_API_TO_NAME_MAPPING[value.api_name]=value.display_name || value.display_label;
}

const WH_OPTIONS = {
  [EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name]: {
    display: 'Traditional',
    value: 'Traditional',
    showOrder: 0,
    def: false
  },
  [EQUIPMENT.WATER_HEATER_TANKLESS.api_name]: {
    display: 'Tankless',
    value: 'Tankless',
    showOrder: 1,
    def: false
  },
};
const EQUIPMENT_REQUIRES_MAP = [
  EQUIPMENT.CONDENSER.api_name,
  EQUIPMENT.HEAT_PUMP_COND.api_name,
  EQUIPMENT.MINI_SPLIT_DUCTED.api_name,
  EQUIPMENT.MINI_SPLIT.api_name,
  EQUIPMENT.GAS_PU.api_name,
  EQUIPMENT.HEAT_PUMP_PU.api_name
];

export {EQUIPMENT, EQ_API_TO_NAME_MAPPING, EQ_NAME_TO_API_MAPPING, WH_OPTIONS, EQUIPMENT_REQUIRES_MAP};

import React from 'react';
import PropTypes from 'prop-types';
import {COLORS} from '../../styles/styleConstants';
import {Box, Typography, Grid, IconButton} from '@mui/material';
import ipermitLogo from '../../static/images/iPermitPro.png';
import {globalUseClasses} from '../../styles/globalClasses';
import CloseIcon from '@mui/icons-material/Close';
import {Hidden} from '@mui/material';
import ModalWrapper from './modalWrapper';

const headerText = {
  width: '70vw',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const UserModal = (props)=> {
  const classes = {...globalUseClasses()};
  return (
    <ModalWrapper modalName={'userModal'}
      handleClose={props.handleClose}
      open={props.open}
      preventScroll={props.preventScroll}
      width={{sx: '98%', md: '60%'}}
      maxWidth={props.maxWidth}
    >
      <Grid container spacing={4} alignItems={'baseline'} position={'relative'}>
        <Box position={'absolute'} top={10} right={-20}>
          <IconButton
            sx={{backgroundColor:COLORS.backgroundMain}}
            onClick={props.handleClose}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Grid item display={'flex'} alignItems={'flex-end'} justifyContent={'space-between'} xs={12}>
          <Box display={'flex'} alignItems={'flex-end'}>
            <Box pr={4} onDragStart={(e)=> e.preventDefault()}>
              <img src={ipermitLogo} alt={'iPermit_pro_logo.png'} className={classes.logo}/>
            </Box>
            <Hidden smDown>
              <Typography variant={'h5'}>{props.header}</Typography>
            </Hidden>
            {/*{((!queries.isXS && !queries.isSM) &&*/}

          </Box>

        </Grid>
        <Hidden smUp>
          <Grid item xs={12}>
            <Typography variant={'h5'} sx={{...headerText}}>{props.header}</Typography>
          </Grid>
        </Hidden>
        {/*{((queries.isXS || queries.isSM) && */}
        {/*  <Grid item xs={12}>*/}
        {/*  <Typography variant={'h5'} sx={{...headerText}}>{props.header}</Typography>*/}
        {/*</Grid>)}*/}
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

UserModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  preventScroll: PropTypes.bool,
  maxWidth: PropTypes.string
};
UserModal.defaultProps = {
  open: false,
  header: '',
  preventScroll: false,
  maxWidth: null
};
export default UserModal;

import React, {useRef, useState, useEffect} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Box, Grid} from '@mui/material';
import FormSubmitButtons from '../pieces/formSubmitButtons';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as allConstants from '../constants/allConstants';
import Utils from '../utils/utils';
import ModalWrapper, {ModalContentWrapper} from '../pieces/modals/modalWrapper';
import axios from 'axios';
import ApiUtils from '../utils/apiUtils';
import base_url from '../baseUrls';
//import * as STYLE_CONSTANTS from '../styles/styleConstants';
import Spacer from '../pieces/spacer';
import { DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';

//import {inputTypes} from '../pieces/inputs/formInput';
//import CustomFormBlock from '../pieces/inputs/formBlock';

export const useServiceTitanAlias = (props)=> {

  const headerText = 'Select ' + props?.title;
  const fields = { text: 'name', value: 'value' };

  const [show, setShow] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const curRowData = useRef(null);
  //const targetFieldNames = useRef(null);

  // const formInputDefaultProps = {
  //   viewMode: false,
  //   renderLabel: true,
  // };
  const validationSchema = Yup.object().shape({
   // [allConstants.JOB_FIELDS.NOTE.TEXT.api_name]: Yup.string().required('Please leave a note')
  });

  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const mainForm = useForm(formOptions);

  if(Utils.isDevEnv()) {
    window.mainForm = mainForm;
  }

  // Thanh 02/02/24
  const onSubmit = async (data)=> {

    //console.log('Current row data =>');
    //console.log(curRowData.current);

    //console.log('selection');
    //console.log(selectedUser);

    const url = `${base_url.api}${props.apiUrl}`;
    const authToken =  await ApiUtils.getAccessToken();
    const selectedNodes = curRowData.current.api.getSelectedNodes();

    //console.log('selected nodes');
    //console.log(selectedNodes)

    selectedNodes.map( (node) => {
      const body = { 
        companyId: props.companyId, 
        'email': node.data[allConstants.USER_FIELDS.EMAIL.api_name], 
        [allConstants.USER_FIELDS.ST_CREATE_JOB_EMAIL.api_name]: selectedUser.value
      };
      //console.log('body to sent to api');
      //console.log(body);

      axios.post(url, body, {headers: {Authorization: authToken,}}).then (() => {
        //node.setDataValue(allConstants.USER_FIELDS.ST_CREATE_JOB_EMAIL.api_name, selectedUser.value);
        node.setDataValue(allConstants.USER_FIELDS.ST_CREATE_JOB_USER.api_name, selectedUser.name);
      });
     // { 'companyId': companyId, 'email': params.node.data[allConstants.USER_FIELDS.EMAIL.api_name]});
    });
        
    setShow(false);
  };

  const onCancel = ()=> {
    setShow(false);
  };

  const getSelectedRows = (api) => {
    const selectedNodes = api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);

    //console.log('selected data', selectedData);

    return selectedData;
  };

  const showDialog = (userList, params)=> {

    // console.log('inside showDialaog');
    // console.log('userList => ', userList);
    // console.log('selected row => ', params);

    const selectedRows = getSelectedRows(params.api);

    // console.log('selected rows');
    // console.log(selectedRows);

    curRowData.current = params;
    setSelectedUser( { name: params.node.data.name, value: params.node.data.value});

    setListData(userList);   
    setShow(true);
  };

  const handleChangeUser = (e) => {

    // console.log('inside handle change user');
    // console.log(e);

    if (e && e.itemData) {
      setSelectedUser(e.itemData); // Store full selected item data

    }
  }
  const mainDialog = () =><>
      <style>
        {`
          #userTtemplate {
              margin: 0 auto;
              width: 300px !important;
              padding-top: 4px;
          }
          #userTemplate .name {
              padding: 5px 2px;
              opacity: .87;
              line-height: 1.0em;
          }
          #users_popup .name {
              display: block !important;
              opacity: .87;
              font-size: 14px;
              margin-top: 2px;
              padding: 2px 0 0;
          }
          .custom-dropdown .e-input {
            border-bottom: 2px solid blue !important; /* Changes the bottom border to blue */
          }
        `}
      </style>
      <ModalWrapper modalName={'lookupItem'}
        handleClose={onCancel}
        open={show}
        preventScroll={true}
        width={{sx: '98%', sm: '600px'}}
        padding={6}
        customStyle={{paddingTop: 2}}
      >
        <ModalContentWrapper handleClose={onCancel} header={headerText} >
          <FormProvider {...mainForm}>
            <form onSubmit={mainForm.handleSubmit(onSubmit)} method={'POST'}>
              <div id='userTemplate'>
                {listData &&<DropDownListComponent id="users" dataSource={listData} 
                   fields={fields} cssClass="custom-dropdown"
                    placeholder="Select an alias" popupHeight="270px"
                    //itemTemplate={itemTemplate} 
                    // headerTemplate={headerTemplate}
                    //value={selectedValue}
                    //valueTemplate={valueTemplate} 
                    change={handleChangeUser}
                />}
              </div> 
              <Grid>
                <Spacer y={2}/>
                <Box display={'flex'} justifyContent={'center'}>
                  <FormSubmitButtons cancelAction={onCancel}
                    submitText={'Select'} cancelText='Cancel'
                  />
                </Box>
              </Grid>
            </form>
          </FormProvider>
        </ModalContentWrapper>
      </ModalWrapper>
  </>;
  
  return {
    mainDialog: mainDialog,
    showDialog: showDialog,
  }
};



  // const headerTemplate = () => {
  //   return null;
  // }; 
  // const itemTemplate = (data) => {
  //   return (<div className="name"> {data.name} - {data.value}</div>);
            
  // };
  // const valueTemplate = (data) => {

  //     console.log('data value');
  //     console.log(data);
      
  //     if (!data) return null;

  //     return (<div className="valueTemplate">
  //             <div className="name"> {data.name} </div>
  //             </div>);
  // };
  // const handleChangeUser = (e) => {
  //   console.log('inside handle change user');
  //   console.log(e);

  //   if (e && e.itemData) {
  //     setSelectedUser(e.itemData); // Store full selected item data

  //   }
  // };


      //let fields = fieldNames.split(',');
    //let defValue = null;

    // for (var i=0; i < fields.length; i++) {
    //   if (i==0) {
    //     defValue = params.node.data[fields[i]]??"";
    //     //console.log('def value');
    //     // console.log(defValue);
    //   }
    //   else {
    //     defValue = defValue[fields[i]]??"";
    //     //console.log('new default');
    //     //console.log(defValue);
        
    //   }
    // }
    //submitAction.current = action;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useJob} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {Box} from '@mui/material';
import Utils from '../../../utils/utils';
import {JobDisplayNotes} from '../../../pieces/jobDisplayNotes';
import {scrollStyles} from '../allJobs/allJobsViewStyles';
import {useForm, useWatch} from 'react-hook-form';
import Loader from '../../misc/loader';
import {toast} from 'react-toastify';
import ApiUtils from '../../../utils/apiUtils';
import {AddNoteForm} from '../../../pieces/noteElements/addNoteForm';
import {NoDataStringWrapper} from '../../../pieces/noDataStringWrapper';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {jobRules, IPERMIT_JOB_ELEMENTS} from '../../../permissions/ipermitAccessControl';
import {useSelector} from 'react-redux';
import {NotAllowedTo} from './notAllowedToAdd';


export const ExternalNotesContent = (props) => {

  const {job, setJob} = useJob();

  const [submitting, setSubmitting] = useState(false);
  const user = useSelector(state => state.user);

  const {JOB_FIELDS} = allConstants;
  const notes = job?.notes || [];

  const tabs = props.tabs;

  const currentNotes = notes.filter(note=>note?.[JOB_FIELDS.NOTE.TYPE.api_name] === tabs[props.activeTabIndex].type);

  const formOptions = {
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues: {
      [allConstants.JOB_FIELDS.NOTE.TYPE.api_name]: props?.defaultNoteType?.value,
    },
  };

  const formMethods = useForm(formOptions);

  const watchNote = useWatch({
    control: formMethods.control,
    name: allConstants.JOB_FIELDS.NOTE.TEXT.api_name,
  });

  if(Utils.isDevEnv()) {
    window.noteForm = formMethods;
  }

  const handleSubmitResponse = (result, noteType)=> {
    const toastOptions = {
      toastId: 'add_note_response',
      autoClose: 1000,
    };

    if(result.status === 'success') {
      const updatedNotes = result?.data;

      setJob({...job, ...updatedNotes});
      formMethods.reset({[allConstants.JOB_FIELDS.NOTE.TYPE.api_name]: noteType,
        [allConstants.JOB_FIELDS.NOTE.TEXT.api_name]: ''});
      toast.success(`External ${noteType || ''} note added`, toastOptions);
    } else{
      toast.error(`Error on adding note: ${result?.message}`, toastOptions);
    }
    Utils.sleep(1000).then(()=> {
      setSubmitting(false);
    });
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    const dataToSubmit = {
      jobId: job?.[JOB_FIELDS.ID.api_name],
      note: data[allConstants.JOB_FIELDS.NOTE.TEXT.api_name],
      type: data[allConstants.JOB_FIELDS.NOTE.TYPE.api_name]
    };
    const result = await ApiUtils.makeApiCall('POST', 'jobs/add-note', JSON.stringify(dataToSubmit));
    handleSubmitResponse(result, data[allConstants.JOB_FIELDS.NOTE.TYPE.api_name]);
  };

  const noteTypeProps = (() => {
    const properties = {
      elementPermissions: jobRules[IPERMIT_JOB_ELEMENTS.addPermitNote],
      noteType: allConstants.NOTE_TYPES.PERMIT
    };
    const noteType = props.tabs?.[props.activeTabIndex]?.type;
    if(!noteType) return properties;
    switch (noteType) {
    case allConstants.NOTE_TYPES.HERS.api_name: {
      properties.elementPermissions = jobRules[IPERMIT_JOB_ELEMENTS.addHersNote];
      properties.noteType = allConstants.NOTE_TYPES.HERS;
      break;
    }
    case allConstants.NOTE_TYPES.INSPECTION.api_name: {
      properties.elementPermissions = jobRules[IPERMIT_JOB_ELEMENTS.addInspectionNote];
      properties.noteType = allConstants.NOTE_TYPES.INSPECTION;
      break;
    }
    case allConstants.NOTE_TYPES.SCHEDULING.api_name: {
      properties.elementPermissions = jobRules[IPERMIT_JOB_ELEMENTS.addSchedulingNote];
      properties.noteType = allConstants.NOTE_TYPES.SCHEDULING;
      break;
    }
    default:{
      break;
    }
    }
    return properties;
  })();

  return (
    <>
      <Box key = {props.defaultNoteType} sx={{
        position: 'relative',
        // border: `1px solid ${STYLE_CONSTANTS.COLORS.lightGray}`,
        paddingTop: '0px',
        padding: submitting ? '0' : {xs: '4px', md: '8px', xl: '16px'},
        maxHeight: '400px',
        overflowY: 'scroll',
        ...scrollStyles,
      }}>
        <Box sx={{
          position: 'absolute',
          top:0,
          left:0,
          right:0,
          bottom:0,
          display: submitting? 'initial' : 'none',
          zIndex: 100,
          padding: 0,
          backgroundColor: STYLE_CONSTANTS.COLORS.white
        }}>
          <Loader useOwnWrapper/>
        </Box>
        {currentNotes.length === 0 && <NoDataStringWrapper text={'No notes added'}/>}
        <JobDisplayNotes jobNotes={currentNotes} defaultReturn={null}/>
        {NewPermissionGate({
          user: user,
          elementPermissions: noteTypeProps.elementPermissions,
        }) ? <AddNoteForm formMethods={formMethods} color={STYLE_CONSTANTS.COLORS.globalBlue}
            onSubmitAction={onSubmit}
            selectNoteType={true}
            ipermitViewNote={'External'}
            defaultNoteType={props.defaultNoteType}
            submitDisabled={!watchNote || watchNote.length === 0}
            jobType={job?.[JOB_FIELDS.JOB_TYPE.api_name]}
            user={user}
          /> : <NotAllowedTo action={'add'} module={`new ${noteTypeProps.noteType.display?.toLowerCase()} notes`}/>}
      </Box>
    </>
  );
};

ExternalNotesContent.propTypes = {
  defaultNoteType: PropTypes.shape({
    display: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,

  }).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
  })).isRequired,
  activeTabIndex: PropTypes.number.isRequired,
};

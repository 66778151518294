export const CALL_RESULTS_HOME_OWNER = {
  AVAILABILITIES_RECEIVED: {display: 'Availabilities Received', value: 'Availabilities received', showOrder: 0, def: false},
  NOT_INTERESTED: {display: 'Not interested', value: 'Not interested', showOrder: 1, def: false},
  NO_RESPONSE: {display: 'No response/busy', value: 'No response/Busy', showOrder: 2, def: false},
  REQUESTED_MORE_INFO: {display: 'Requested More Info', value: 'Requested more info', showOrder: 3, def: false},
  REQUESTED_CALL_BACK: {display: 'Requested Call Back', value: 'Requested call back', showOrder: 4, def: false},
  INVALID_NUMBER: {display: 'Invalid number', value: 'Invalid number', showOrder: 5, def: false},
};

export const CALL_RESULTS_CITY = {
  NO_RESPONSE: {display: 'No response/busy', value: 'No response/Busy', showOrder: 0, def: false},
  REQUESTED_MORE_INFO: {display: 'Requested More Info', value: 'Requested more info', showOrder: 1, def: false},
  SCHEDULED_ROUGH: {display: 'Scheduled Rough', value: 'Scheduled rough', showOrder: 2, def: false},
  SCHEDULED_FINAL: {display: 'Scheduled Final', value: 'Scheduled final', showOrder: 3, def: false},
};

export const CALL_TO_OPTIONS = {
  HOME_OWNER: {display: 'Homeowner', value: 'Homeowner', showOrder: 0, def: false},
  CITY: {display: 'City', value: 'City', showOrder: 1, def: false},
};

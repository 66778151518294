import * as allConstants from '../constants/allConstants';

const companyAndUserUtils = {
  isHERSCompany: (company)=> {
    return !company?.allowedTests || !Array.isArray(company.allowedTests) || company.allowedTests.includes(allConstants.TEST_TYPES.HERS.value);
  },
  isEUCCompany: (company)=> {
    return !company?.allowedTests || !Array.isArray(company.allowedTests) || company.allowedTests.includes(allConstants.TEST_TYPES.EUC.value);
  },
  isTestingOnlyCompany: (company) => {
    return company?.allowedTests && Array.isArray(company.allowedTests) && company.allowedPermits.length === 1 && company.allowedPermits[0] === allConstants.JOB_TYPES.TESTING_ONLY.value;
  },
  canAddNewEntityToCompany: (company)=> {
    return company?.[allConstants.COMPANY_FIELDS.STATUS.api_name] === allConstants.COMPANY_STATUSES.ACTIVE.value && (!company || !company?.[allConstants.COMPANY_FIELDS.IS_BLOCKED.api_name]);
  }
};

export {companyAndUserUtils};

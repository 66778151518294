import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import ErrorIcon from '../static/images/alert-circle.svg';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import Utils from '../utils/utils';

const AntTabs = styled(Tabs)({
  minHeight: '36px',
  //borderBottom: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
  },
});

const AntTab = styled((props) => {
  return <Tab disableRipple
    fullWidth
    {...props}
    sx={{
      borderColor: props.errors ? 'red !important': 'initial',
      '&.Mui-selected': {
        color: props.disabled? 'rgba(0, 0, 0, 0.38)' : STYLE_CONSTANTS.COLORS.white,
        backgroundColor: props.disabled? 'initial' : STYLE_CONSTANTS.COLORS.globalGreen,
        border: props.disabled? '1px solid #B5B5C3' : 'initial',
        '&:hover': {
          backgroundColor: props.disabled? 'initial' : STYLE_CONSTANTS.COLORS.globalGreen,
        }
      },

    }}
    icon={props.errors ? <img src={ErrorIcon} alt=""/>: null} iconPosition={'end'}
  />;})(({theme}) => ({
  textTransform: 'inherit',
  minWidth: 0,
  height: '36px !important',
  minHeight: '36px !important',
  color: STYLE_CONSTANTS.COLORS.fontMain,
  fontSize: '14px',
  border: '1px solid #B5B5C3',
  '&:not(:last-child)': {
    borderRight: 'none'
  },
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },

  //marginRight: theme.spacing(1),

  '&:hover': {
    opacity: 1,
    backgroundColor: STYLE_CONSTANTS.COLORS.chartBlue,
  },

  '&.MuiTab-labelIcon': {
    minHeight: 'inherit'
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{pt: '8px', columnGap: {xs: '10px', md: '20px'}, display: 'flex'
      }}>
        {children}
      </Box>
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function TabsBlock(props) {
  const getActiveTabFromURL = ()=> {
    const paramTab = searchParams.get('tab') !== null ? searchParams.get('tab') : undefined;
    if(!paramTab) return 0;
    const tabs = props.content.map((t)=> t.api_name);
    if(!tabs.includes(paramTab)) return 0;
    return tabs.indexOf(paramTab);

  };

  const getNotBlockedTabs = ()=> {
    const disabledNames = {};
    props.content.forEach((tab, index)=> disabledNames[index] = tab.isDisabled);
    return props.content.map((tab)=> !tab.isDisabled);
  };

  const getActiveTab = ()=> {
    const initialActive = getActiveTabFromURL();
    const notBlocked = getNotBlockedTabs();

    if(notBlocked[initialActive]) return initialActive;
    if(notBlocked.filter((v)=> v).length === 0) return initialActive;

    let currentActive;
    for (let i = 0; i<notBlocked.length - 1; i++) {
      if(!currentActive && notBlocked[i]) {
        currentActive = i;
      }
    }
    return currentActive;
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(getActiveTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const tabs = props.content.map((t)=> t.api_name);
    setSearchParams(Utils.createQueryParam(location, {tab: tabs[newValue]}));
  };

  const tabs = [];
  const panels = [];

  props.content.forEach((tab, index)=> {
    tabs.push(<AntTab label={tab.label} key={index} errors={tab.errors} disabled={tab.isDisabled}/>);
    panels.push(tab.isDisabled? null : <TabPanel value={value} key={index} index={index}>{tab.panel(props)}</TabPanel>);
  });

  return (
    <Box sx={{width: props.fullWidth ? '100%' : 'unset'}}>
      <Box>

        <AntTabs value={value} onChange={handleChange} aria-label="ant example" variant='fullWidth'>
          {tabs}
        </AntTabs>

      </Box>
      {panels}
    </Box>
  );
}

TabsBlock.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    panel: PropTypes.func.isRequired,
  })).isRequired,
  fullWidth: PropTypes.bool,
};

TabsBlock.defaultProps = {
  fullWidth: false,
};

export default TabsBlock;

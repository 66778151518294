export const INSPECTION_FIELDS = {
  INSPECTION: {api_name: 'cityInspection', required: false, label: 'city inspection', display_label: 'city inspection'},
  CONFIRMATION_NUMBER: {api_name: 'confirmationNumber', required: false, label: 'Confirmation Number', display_label: 'Confirmation#'},
  INSPECTION_RESULTS: {api_name: 'tests', required: false, label: '', display_label: ''},
  RESULT: {api_name: 'status', required: false, label: 'results', display_label: 'results'},
  DATE: {api_name: 'date', required: false, label: 'Date', display_label: 'Date'},
  TYPE: {api_name: 'type', required: false, label: 'Inspection Type', display_label: 'Inspection Type'},
  LOGGED_BY: {api_name: 'user', required: false, label: 'User', display_label: 'user'},
  NOTE: {api_name: 'note', required: false, label: 'note', display_label: 'note'},
  HO_AVAILABILITY: {api_name: 'homeownerAvailability', required: false, label: 'Homeowner Notes', display_label: 'Homeowner Notes'},
  CITY_AVAILABILITY: {api_name: 'cityAvailability', required: false, label: 'City Notes', display_label: 'City Notes'},
  LADDER: {api_name: 'needsLadder', required: false, label: 'Ladder', display_label: 'Ladder'},
  REQUIREMENTS: {api_name: 'requirement', required: false, label: 'Requirements', display_label: 'Requirements'},
  INSPECTION_STATUS:{api_name: 'status', required: false, label: 'Mailing Status', display_label: 'Mailing Status'},
  INSPECTION_STATUS_CHANGE_DATE: {api_name: 'inspectionStatusChangeDate', required: false, label: 'Change Date', display_label: 'Change Date'},
  //INSPECTION_SCHEDULED_DATE: {api_name: 'inspectionScheduledDate', required: false, label: 'Inspection Scheduled Date', display_label: 'Scheduled Date'},
  SCHED_ASSIGNEE: {api_name: 'schedAssignee', required: false, label: 'Sched Assignee', display_label: 'Sched Assignee'},
  TAGS: {api_name: 'tags', required: false, label: 'Tag', display_label: 'Tag'},
  TAGS_REMINDER_DATE: {api_name: 'tags.reminderDate', required: false, label: 'Reminder Date', display_label: 'Reminder Date'},
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import localStorageKeys from '../../../utils/localStorageKeys';

export const selfRegisterCompany = createAsyncThunk(
  'companies/register',
  async ( props, thunkAPI) => {
    try {
      //console.log('Edit company payload', props);

      const response = await fetch(
        `${base_url.api}companies/register`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(props),
        }
      );
      let data = await response.json();
      if (response.status === 201) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const selfRegisterUserCompanyReducer = {
  [selfRegisterCompany.fulfilled]: (state, {payload}) => {
    localStorage.setItem(localStorageKeys.tokens, JSON.stringify(payload.tokenPair));
    const newState = {...state, ...payload.user};
    return newState;
  },
};

export const selfRegisterCompanyReducer = {
  [selfRegisterCompany.pending]: (state)=>{
    state.isFetching = true;
  },
  [selfRegisterCompany.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...payload.company};
    return newState;
  },
  [selfRegisterCompany.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import * as allConstants from '../../../../../constants/allConstants';
import {useCreateJobStyles} from '../../createJobStyles';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import Utils from '../../../../../utils/utils';
import {AddRowButton, DeleteEquipmentRowButton} from '../../../../../pieces/addDeleteEquipmentRowButtons';
import {FormProvider, useFieldArray} from 'react-hook-form';
import ErrorIcon from '../../../../../static/images/alert-circle.svg';


const SystemEquipment = (props)=> {
  const {eqName, formMethods, addFields, inputDefaultProps, fieldSet, errors, canModifyEquipment} = props;
  const classes = {...useCreateJobStyles()};
  const name_prefix = `${eqName}[0].fields`;
  const {fields, append, remove} = useFieldArray({
    control: formMethods.control,
    name:name_prefix
  });
  const values = formMethods.getValues();

  const getFieldValue = (fieldName, index)=> {
    const blockValues = values?.[`${eqName}`]?.[0]?.fields[index];
    return blockValues?.[fieldName];
  };

  useEffect(()=> {
    if(errors && errors.length !== 0) {
      try{
        const fields = errors[0]?.fields;
        if (fields && fields.length > 0) {
          const errorObj = Object.values(fields[0])[0];
          if(errorObj && errorObj.ref) {
            const el =document.getElementById(`${errorObj.ref?.name}-input`);
            el.scrollIntoView({behavior: 'smooth'});

          }
        }
      } catch (e){}
    }
  }, [errors]);

  return (
    <>
      {!props.hideEqName && <Typography variant={'subtitle1'} sx={{
        fontWeight: '600',
        '& span': {
          marginLeft: '4px'
        }
      }}>{allConstants.EQ_API_TO_NAME_MAPPING[eqName]}
        {errors && errors.length !== 0 && <span><img src={ErrorIcon} alt=""/></span>}
      </Typography>}

      <FormProvider {...formMethods}>
        <form>

          <Box className={classes.equipment_wrapper} sx={{
            ...props.sx,
            ...{maxWidth: {xs: '100%'}, paddingRight: '40px !important'}, minWidth:{xs: '100%', sm: '580px'}}}>
            {props.children}
            {fields.map((item, k) => {
              const additionalProps = {
                nested: true,
                parent: `${name_prefix}[${k}]`
              };
              return (
                <Box width={'100%'} className={classes.equipment} key={`${name_prefix}[${k}]`}>

                  {fieldSet.map((f, index)=> {
                    const required = f.field.required || f?.requiredFor && f.requiredFor.includes(eqName);
                    return (
                      <Box key={index+f.field.api_name} className={f.wrapperClass} xs={{width: '165px'}} mr={2}>
                        <CustomFormBlock {...inputDefaultProps} {...additionalProps} {...f.uniqueProps}
                          inputType={f.inputType}
                          options={f.options ? Utils.getOptions(f.options, f.filterOptions) : undefined}
                          field={{...f.field, ...{required: required}}}
                          defaultValue={getFieldValue(f.field.api_name, k)}
                          fullWidth
                          required={required}/>
                      </Box>);
                  })}
                  {canModifyEquipment && <DeleteEquipmentRowButton eqName={eqName}
                    deleteAction={() => remove(k)}
                    index={k}
                  />}
                </Box>
              );
            })}
            {canModifyEquipment && <AddRowButton eqName={eqName}
              addAction={() => {append({...addFields});}}
            />}
          </Box>
        </form>
      </FormProvider>
    </>

  );

};

SystemEquipment.propTypes = {
  formMethods: PropTypes.shape({
    control: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired
  }).isRequired,
  addFields: PropTypes.func.isRequired,
  inputDefaultProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
  eqName: PropTypes.string.isRequired,
  fieldSet: PropTypes.instanceOf(Array).isRequired,
  errors: PropTypes.instanceOf(Array),
  canModifyEquipment: PropTypes.bool,
  hideEqName: PropTypes.bool,
  sx: PropTypes.instanceOf(Object)

};
SystemEquipment.defaultProps = {
  errors: [],
  canModifyEquipment: true,
  hideEqName: false,
  sx:{}
};
export {SystemEquipment};

export const PERMIT_MODULE_FIELDS = {
  ASSIGNED_CITY: {api_name: 'assignedCity', required: false, label: 'City', display_label: 'City'},
  ZONE: {api_name: 'zone', required: false, label: 'zone', display_label: 'zone'},
  MECH01: {api_name: 'mech01', required: false, label: 'mech01', display_label: 'mech01'},
  PERMIT_INFO: {api_name: 'permitInfo', required: false, label: 'Permit', display_label: 'Permit'},
  MISC_INFO: {api_name: 'miscInfo', required: false, label: 'Misc', display_label: 'Misc'},
  LICENSE_INFO: {api_name: 'licenseInfo', required: false, label: 'License', display_label: 'License'},
  NUMBER: {api_name: 'number', required: false, label: 'Document Number', display_label: 'Document Number'},
  DATE: {api_name: 'date', required: false, label: 'Date', display_label: 'Date'},
  AMOUNT: {api_name: 'amount', required: false, label: 'Amount', display_label: 'Amount'},
  CHECK_NUMBER: {api_name: 'checkNumber', required: false, label: 'Check Number', display_label: 'Check Number'},
  INSPECTION: {api_name: 'inspection', crm_name: 'Inspection', required: false, label: 'Inspection', display_label: 'Inspection'},
  CHECK_TOTAL: {api_name: 'checkTotal', crm_name: 'Check_total', required: false, label: 'Check Total', display_label: 'Check Total'},
  CARD_POSTED: {api_name: 'cardPosted', crm_name: 'Post_Jobs_Card', required: false, label: 'Card Posted', display_label: 'Card Posted'},
  CF1R_IS_PENDING: {api_name: 'isCf1rPending', crm_name: 'CF1R_is_pending', required: false, label: 'CF1R is Pending', display_label: 'CF1R is Pending'},
};

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {Box, Grid, Typography} from '@mui/material';
import CustomizedSwitch from '../../../../pieces/switch/switch';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {useCreateJobStyles} from '../createJobStyles';
import {makeStyles} from '@mui/styles';
import {useFormContext, useWatch} from 'react-hook-form';
import {inputTypes} from '../../../../pieces/inputs/formInput';

const useStyles = makeStyles(theme=> {
  return {
    fixture_col: {
      width: '130px'
    },
    table_header: {
      // height: '3.5em',
      background: STYLE_CONSTANTS.COLORS.backgroundMain,
      '& .MuiTypography-root': {
        fontSize: '12px'
      }

    },
    qty_col: {
      display: 'flex',
      justifyContent: 'center',
      width: '80px'
    }
  };
});


export const Replace = (props)=> {
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const formController = useFormContext();
  const values = formController.getValues();
  const blockParent = `${props.parent}.${allConstants.JOB_FIELDS.PLUMBING.REPLACE.api_name}`;
  const [replaceWater, setRelaceWater] = useState(values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPLACE.api_name]?.[FIELDS.REPLACE_WATER_MAIN.api_name] || false);
  const [replaceSewer, setReplaceSewer] = useState(values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPLACE.api_name]?.[FIELDS.REPLACE_SEWER_LINE.api_name] || false);

  const publicPropertyIsSelected = useWatch({
    name: `${blockParent}.${FIELDS.SEWER_LINE_PUBLIC_PROPERTY.api_name}`,
    control: formController.control
  });

  const waterAction = (val)=> {
    setRelaceWater(val);
    formController.setValue(`${[blockParent]}.${[FIELDS.REPLACE_WATER_MAIN.api_name]}`, val, {shouldDirty: true});
    if(!val) {
      formController.setValue(`${[blockParent]}.${[FIELDS.WATER_MAIN_DIAMETER.api_name]}`, null, {shouldDirty: true});
    }
  };

  const sewerAction = (val)=> {
    setReplaceSewer(val);
    formController.setValue(`${[blockParent]}.${[FIELDS.REPLACE_SEWER_LINE.api_name]}`, val, {shouldDirty: true});
    if(!val) {
      formController.setValue(`${[blockParent]}.${[FIELDS.SEWER_LENGTH.api_name]}`, null, {shouldDirty: true});
      formController.setValue(`${[blockParent]}.${[FIELDS.SEWER_LINE_DIAMETER.api_name]}`, null, {shouldDirty: true});
      formController.setValue(`${[blockParent]}.${[FIELDS.TRENCHLESS_PIPE_BURSTING.api_name]}`, false, {shouldDirty: true});
      formController.setValue(`${[blockParent]}.${[FIELDS.SEWER_LINE_PUBLIC_PROPERTY.api_name]}`, false, {shouldDirty: true});
      formController.setValue(`${[blockParent]}.${[FIELDS.SEWER_LINE_PRIVATE_PROPERTY.api_name]}`, false, {shouldDirty: true});
    }
  };
  const getValues = (field)=> {
    return values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPLACE.api_name]?.[field];
  };

  return (
    <Box className={classes.tab_container}>
      <Grid container columnGap={3} display={'flex'} flexDirection={'row'} alignItems={'flex-start'} >
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <CustomizedSwitch label={FIELDS.REPLACE_WATER_MAIN.label}
            action={waterAction}
            defaultChecked={replaceWater}
          />
        </Grid>
        {replaceWater &&  <Grid item>
          <Box className={classes.action_col}>
            <CustomFormBlock
              {...props}
              inputType={inputTypes.number}
              field={FIELDS.WATER_MAIN_DIAMETER}
              nested={true}
              parent={blockParent}
              defaultValue={getValues(FIELDS.WATER_MAIN_DIAMETER.api_name)}
              fullWidth/>
          </Box>

        </Grid>}
      </Grid>
      <Grid container columnGap={3} display={'flex'} flexDirection={'row'} alignItems={'flex-start'} >
        <Grid item  xs={12} sm={4} md={3} lg={2}>
          <Box xs={12}  display={'flex'} flexDirection={'row'}>
            <CustomizedSwitch label={FIELDS.REPLACE_SEWER_LINE.label}
              action={sewerAction}
              defaultChecked={replaceSewer}
            />
          </Box>
        </Grid>
        {replaceSewer &&
              <>
                <Grid item mr={6}>
                  <Box width={165}>
                    <CustomFormBlock {...props}
                      inputType={inputTypes.number}
                      field={FIELDS.SEWER_LENGTH}
                      nested={true}
                      parent={blockParent}
                      defaultValue={values?.[`${blockParent}`]?.[FIELDS.SEWER_LENGTH.api_name]}
                      fullWidth/>
                  </Box>
                  <Box width={165}>
                    <CustomFormBlock {...props}
                      inputType={inputTypes.number}
                      field={FIELDS.SEWER_LINE_DIAMETER}
                      nested={true}
                      parent={blockParent}
                      defaultValue={values?.[`${blockParent}`]?.[FIELDS.SEWER_LINE_DIAMETER.api_name]}
                      fullWidth/>
                  </Box>


                </Grid>

                <Grid item>
                  <Box width={250}>
                    <CustomFormBlock
                      {...props}
                      inputType={inputTypes.checkbox}
                      labelLeft={true}
                      rowReverse={false}
                      renderLabel={false}
                      labelWidth={200}
                      field={FIELDS.TRENCHLESS_PIPE_BURSTING}
                      nested={true}
                      parent={blockParent}
                      defaultValue={getValues(FIELDS.TRENCHLESS_PIPE_BURSTING.api_name)}
                      fullWidth/>
                  </Box>

                  <Box width={250}>
                    <CustomFormBlock
                      {...props}
                      inputType={inputTypes.checkbox}
                      labelLeft={true}
                      rowReverse={false}
                      renderLabel={false}
                      labelWidth={200}
                      field={FIELDS.SEWER_LINE_PRIVATE_PROPERTY}
                      nested={true}
                      parent={blockParent}
                      defaultValue={getValues(FIELDS.SEWER_LINE_PRIVATE_PROPERTY.api_name)}
                      fullWidth/>
                  </Box>

                  <Box width={250}>
                    <CustomFormBlock
                      {...props}
                      inputType={inputTypes.checkbox}
                      labelLeft={true}
                      rowReverse={false}
                      renderLabel={false}
                      labelWidth={200}
                      field={FIELDS.SEWER_LINE_PUBLIC_PROPERTY}
                      nested={true}
                      parent={blockParent}
                      defaultValue={getValues(FIELDS.SEWER_LINE_PUBLIC_PROPERTY.api_name)}
                      fullWidth/>
                    {publicPropertyIsSelected &&
                    <Box mb={2}>
                      <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>
                        Encroachment Permit Will Be Needed
                      </Typography>
                    </Box>
                    }
                  </Box>
                </Grid>
              </>}

      </Grid>

    </Box>
  );
};

Replace.propTypes = {
  parent: PropTypes.string.isRequired,
  field: PropTypes.shape({
    api_name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  defaultValue: PropTypes

};

export default Replace;

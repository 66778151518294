import React from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';
import {Grid, Typography} from '@mui/material';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import {BlockHeader} from '../../../../../pieces/displayJob/blockHeader';
import {EquipmentBlock} from '../../../../../pieces/displayJob/equipmentBlock';
import {hvacDisplayUtils} from './hvacDisplayUtils';
import {useJobDetailViewStyles} from '../../views/jobDetailViewStyles';
import {CommercialSection} from '../commercialSection';

const HvacDetails = (props) => {
  const {details, equipment} = props;
  const MECH_FIELDS = allConstants.JOB_FIELDS.MECHANICAL;
  const classes = {...globalUseClasses(), ...useJobDetailViewStyles()};

  const displayEquipment = () => {
    let all_equipment;
    let content;
    if(equipment){
      all_equipment = [...equipment];
      content = hvacDisplayUtils.getEquipment(all_equipment);
    }

    const otherEquipment = details?.[MECH_FIELDS.OTHER_EQ_OPTIONS.api_name];
    const isOtherEquipment = otherEquipment && Utils.valueIsSpecified(otherEquipment);
    if((!content || content.length === 0) && !isOtherEquipment) return null;
    return(
      <>
        {BlockHeader({header: 'equipment'})}
        {content && content.length !== 0 && EquipmentBlock({content: content})}
        {isOtherEquipment && <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.eq_name}>{MECH_FIELDS.OTHER_EQ_OPTIONS.display_label}</Typography>
          <Typography variant={'body1'}>{otherEquipment}</Typography>
        </Grid>}
      </>
    );
  };
  const displayElectrical = ()=> {
    const elBlock = hvacDisplayUtils.getElectrical(details);

    if(!elBlock)return null;
    const elContent = jobDisplayUtils.getBlockComponentToDisplay(elBlock);

    if(elContent.length === 0) return null;

    return (
      <Grid container className={classes.details_block}>
        {BlockHeader({header: 'electrical information'})}
        <Grid item xs={12}>
          {elContent}
        </Grid>
      </Grid>
    );
  };

  const displayPlumbing = ()=> {
    const plBlock = hvacDisplayUtils.getPlumbing(details);
    if(!plBlock) return null;
    const plContent = jobDisplayUtils.getBlockComponentToDisplay(plBlock);

    if(plContent.length === 0) return null;

    return (
      <Grid container className={classes.details_block} xs={12}>
        {BlockHeader({header: 'Plumbing information'})}
        <Grid item xs={12}>
          {plContent}
        </Grid>
      </Grid>
    );
  };

  const displayInsulation = ()=> {
    const insRadiant = hvacDisplayUtils.getInsulation(details, MECH_FIELDS.RADIANT_BARRIER.api_name);
    const insBlownin = hvacDisplayUtils.getInsulation(details, MECH_FIELDS.BLOWN_IN.api_name);
    if(!insBlownin && !insRadiant) return null;
    const radiantContent = jobDisplayUtils.getBlockComponentToDisplay(insRadiant);
    const blowninContent = jobDisplayUtils.getBlockComponentToDisplay(insBlownin);

    if(radiantContent.length === 0 && blowninContent.length === 0) return null;
    return <>
      {radiantContent.length !==0 &&
      <Grid item xs={12} sm={6} >
        <Grid container className={classes.details_block}>
          {BlockHeader({header: MECH_FIELDS.RADIANT_BARRIER.label})}
          <Grid item xs={12}>
            {radiantContent}
          </Grid>
        </Grid>
      </Grid>}
      {blowninContent.length !==0 &&  <Grid item xs={12} sm={6} >
        <Grid container className={classes.details_block}>
          {BlockHeader({header: MECH_FIELDS.BLOWN_IN.label})}
          <Grid item xs={12}>
            {blowninContent}
          </Grid>
        </Grid>
      </Grid>}
    </>;
  };

  const plumbing = displayPlumbing();
  const electrical = displayElectrical();
  const equipmentBlock = displayEquipment();
  const insulation = displayInsulation();
  const commercial = <CommercialSection details={details}/>;


  return (
    <>
      <Grid container xs={12}>
        {equipmentBlock &&
        <Grid container columns={{xs: 4, sm: 8, md: 12}} >
          {equipmentBlock}
        </Grid>
        }
        {electrical &&
        <Grid item xs={12} sm={6} >
          {electrical}
        </Grid>}
        {plumbing &&
        <Grid item xs={12} sm={6} >
          {plumbing}
        </Grid>}
        {insulation && insulation}
        {commercial && commercial}
      </Grid>
    </>

  );
};

HvacDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
  equipment: PropTypes.instanceOf(Array).isRequired,
};
export {HvacDetails};

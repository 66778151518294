import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm, FormProvider} from 'react-hook-form';
import ApiUtils from '../../../utils/apiUtils';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {COMPANY_FIELDS, ERROR_MSG} from '../../../constants/allConstants';
import validator from '../../../utils/validators/validator';
import {Box, CardContent, Grid, Card, Typography} from '@mui/material';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import FormSubmitButtons from '../../../pieces/formSubmitButtons';
import {globalUseClasses} from '../../../styles/globalClasses';
import actions from '../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import Spacer from '../../../pieces/spacer';
import {JobActionButtonsContained, JobActionButtonsOutlined} from '../../../pieces/jobActionButtons';
import {useAutocompleteOptions} from '../../../hooks/useAutompleteOptions';
import {toast} from 'react-toastify';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {ADMIN_ELEMENTS, ipermitAdminRules} from '../../../permissions/ipermitAdminAccessControl';

export const UserInfoPanel = (props) => {
  const {USER_FIELDS} = allConstants;
  const [userData, setUserData] = useState(props?.userData || {});
  const classes = {...globalUseClasses()};
  const dispatch = useDispatch();
  const currentUser = useSelector(state=> state?.user);

  const options = {
    markets: useAutocompleteOptions({field: USER_FIELDS.LOCATION.api_name}).options,
  };

  const getDefaultValue = (field)=> {
    return userData?.[field.api_name]||'';
  };

  const getFormDefaultValues = ()=> {
    return {
      [USER_FIELDS.FIRST_NAME.api_name]: getDefaultValue(USER_FIELDS.FIRST_NAME),
      [USER_FIELDS.LAST_NAME.api_name]: getDefaultValue(USER_FIELDS.LAST_NAME),
      [USER_FIELDS.EMAIL.api_name]: getDefaultValue(USER_FIELDS.EMAIL),
      [USER_FIELDS.PHONE_NUMBER.api_name]: getDefaultValue(USER_FIELDS.PHONE_NUMBER),
      [USER_FIELDS.TITLE.api_name]: getDefaultValue(USER_FIELDS.TITLE),
      [USER_FIELDS.ROLE.api_name]: getDefaultValue(USER_FIELDS.ROLE),
      [USER_FIELDS.LOCATION.api_name]: getDefaultValue(USER_FIELDS.LOCATION),
    };
  };

  Yup.addMethod(Yup.string, 'checkIfEmailExists', validator.checkIfEmailExists);
  Yup.addMethod(Yup.string, 'validatePhoneNumber', function (errorMessage) {
    return this.test('phone_number', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidPhoneNumber(value) ||
        createError({path, message: errorMessage})
      );
    });
  });
  Yup.addMethod(Yup.string, 'validateEmail', function (errorMessage) {
    return this.test('email', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidEmail(value) ||
        createError({path, message: errorMessage})
      );
    });
  });

  const getValidationSchema = ()=> {
    const validationOptions = {
      [USER_FIELDS.FIRST_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [USER_FIELDS.LAST_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [USER_FIELDS.EMAIL.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD)
        .validateEmail(allConstants.ERROR_MSG.EMAIL_INVALID)
        .test(
          {
            test: value => {
              return Yup.string().checkIfEmailExists(value, userData?.[USER_FIELDS.EMAIL.api_name]);
            },
            message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(COMPANY_FIELDS.EMAIL.label)
          }
        ),

      [USER_FIELDS.PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE).nullable(),
    };
    if(props.isInvite) {
      validationOptions[USER_FIELDS.ROLE.api_name] = Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD);
    }
    return Yup.object().shape(validationOptions);
  };

  const formOptions = {
    mode: 'onBlur',
    criteriaMode:'all',
    reValidateMode: 'onChange',
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(getValidationSchema()),
  };

  const formMethods = useForm(formOptions);

  useEffect(()=> {
    setUserData(props.userData);
  }, [props.userData]);

  useEffect(()=> {
    formMethods.reset(getFormDefaultValues());
  }, [userData]);

  if(ApiUtils.isDevelopment()) {
    window.userForm = formMethods;
  }

  const submitInvite = async (data) => {
    props.setSubmitting(true);
    const newData = {...data};
    const inviteResult = await dispatch(actions.inviteUser(newData));
    let successMessage = 'User ' + data[USER_FIELDS.FIRST_NAME.api_name] + ' ' + data[USER_FIELDS.LAST_NAME.api_name] + ' successfully invited.';
    if(inviteResult) {
      if(inviteResult.status === 'error' || inviteResult?.meta?.requestStatus === 'rejected'){
        toast(inviteResult.message, {type: 'error'});
      } else {
        toast(successMessage, {type: 'success'});
      }

      if(inviteResult?.payload?.user) {
        props.setUserData(inviteResult?.payload?.user);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }
  };

  const onSubmit = async (data)=> {
    if(props.isInvite) {
      submitInvite(data);
    } else {
      props.setSubmitting(true);

      try{
        let successMessage = 'User ' + data[USER_FIELDS.FIRST_NAME.api_name] + ' ' + data[USER_FIELDS.LAST_NAME.api_name] + ' successfully edited.';
        const editResponse = await ApiUtils.makeApiCall('POST', `users/update-user-data/${userData.id}`, JSON.stringify(data));

        if(editResponse.status === 'error'){
          toast(editResponse.message, {type: 'error'});
        } else {
          toast(successMessage, {type: 'success'});
        }

        if(editResponse?.data?.user) {
          setUserData(editResponse?.data?.user);
        }

      } catch (e) {
        console.error(e);
        Utils.errorToastMessage(`Error editing user. ${e?.message}`);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }


  };

  const onCancel = ()=> {
    props.setViewMode(true);
  };

  const inputDefaultProps = {
    viewMode: props.viewMode,
    renderLabel: true,
    isEditable: true,
  };

  const isCheersConnected = getDefaultValue(USER_FIELDS.HAS_CONNECTED_TO_CHEERS);
  const cheersRequested = getDefaultValue(USER_FIELDS.IS_CONNECT_TO_CHEERS_REQUESTED);

  const requireCheersConnection = async (connect)=> {
    props.setSubmitting(true);
    try {
      const data = {
        [USER_FIELDS.CONNECT_TO_CHEERS.api_name]: connect,
      };
      let successMessage = `CHEERS connection ${connect ? 'requested' : 'terminated'} for user ` + getDefaultValue(USER_FIELDS.FIRST_NAME) + ' ' + getDefaultValue(USER_FIELDS.LAST_NAME);
      const editResponse = await ApiUtils.makeApiCall('POST', `users/update-user-data/${userData.id}`, JSON.stringify(data));
      if(editResponse.status === 'error'){
        toast(editResponse.message, {type: 'error'});
      } else {
        toast(successMessage, {type: 'success'});
      }

      if(editResponse?.data?.user) {
        setUserData(editResponse?.data?.user);
      }
    } catch (e) {
      console.error(e);
      Utils.errorToastMessage(`Error editing user. ${e?.message}`);
    }
    props.setSubmitting(false);
  };

  const disconnectCheers = async ()=> {
    props.setSubmitting(true);
    try {
      const result = await ApiUtils.makeApiCall('POST', 'users/disconnect-cheers-account', JSON.stringify({
        userId: userData.id
      }));
      if(result.status === 'error'){
        toast(result.message, {type: 'error'});
      } else {
        toast('Cheers account disconnected', {type: 'success'});
      }
      if(result?.data?.user) {
        setUserData({...userData, ...{[USER_FIELDS.CHEERS_CONNECTION_REQUIRED.api_name]: false,
          [USER_FIELDS.HAS_CONNECTED_TO_CHEERS.api_name]: false}});
      }
    } catch (e) {
      console.error(e);
      Utils.errorToastMessage(`Error editing user. ${e?.message}`);
    }
    props.setSubmitting(false);
  };

  const canControlCheersConnection = NewPermissionGate({
    user: currentUser,
    elementPermissions: ipermitAdminRules[ADMIN_ELEMENTS.manageCheers],
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} method={'POST'}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.card_block} sx={{height: '100%'}}>
                <CardContent>


                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.FIRST_NAME}
                      fullWidth
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.LAST_NAME}
                      fullWidth
                    />
                  </Box>
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      inputType={inputTypes.singleSelect}
                      options={Utils.getOptions(allConstants.IPERMIT_DISPLAY_ROLES)}
                      field={{api_name: USER_FIELDS.ROLE.api_name, required: true, label: USER_FIELDS.ROLE.label}}
                      defaultValue={getDefaultValue(USER_FIELDS.ROLE)}
                      fullWidth
                      isEditable={true}
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.LOCATION}
                      inputType={inputTypes.singleSelect}
                      options={Utils.getOptions(options.markets)}
                      defaultValue={getDefaultValue(USER_FIELDS.LOCATION)}
                      allowNone={false}
                    />
                  </Box>
                  <CustomFormBlock
                    {...inputDefaultProps}
                    field={{...USER_FIELDS.EMAIL, ...{required: true}}}
                    isEditable={props.isInvite}
                    fullWidth
                    preventCapitalize={true}
                  />
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      inputType={inputTypes.phone}
                      field={USER_FIELDS.PHONE_NUMBER}
                      defaultValue={Utils.reformatPhoneNumber(getDefaultValue(USER_FIELDS.PHONE_NUMBER))}
                      fullWidth
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.TITLE}
                      fullWidth
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} key={isCheersConnected + cheersRequested}>
              <Card className={classes.card_block}>
                <CardContent>
                  <Typography className={classes.card_section_header} >Cheers account
                    {cheersRequested && <Typography component={'span'}
                      sx={{
                        marginLeft: '10px',
                        color: 'white',
                        padding: '2px 5px',
                        backgroundColor: isCheersConnected ? 'success.main' : 'error.main',
                        fontSize: '12px',
                        textTransform: 'none',
                      }}
                    >{isCheersConnected ? 'Connected' : 'Not connected'}</Typography>}

                  </Typography>
                  {cheersRequested && !isCheersConnected && <Typography sx={{
                    color: STYLE_CONSTANTS.COLORS.globalRed,
                  }}>Cheers connection is required</Typography>}
                  <Box display={'flex'} flexDirection={'row'}>
                    <>
                      {/*<Spacer y={2}/>*/}
                      {cheersRequested && !isCheersConnected && <JobActionButtonsOutlined
                        text={     'Revoke cheers connection'}
                        width={'100%'}
                        disabled={!props.viewMode || !canControlCheersConnection}
                        mainColor={STYLE_CONSTANTS.COLORS.globalBlue}
                        action={()=>requireCheersConnection(false)}/>
                      }
                      {!cheersRequested && !isCheersConnected && <JobActionButtonsContained
                        text={'Require cheers connection'}
                        width={'100%'}
                        disabled={!props.viewMode || !canControlCheersConnection}
                        mainColor={STYLE_CONSTANTS.COLORS.globalBlue}
                        action={()=>requireCheersConnection(true)}/>}
                      <Spacer x={2}/>
                    </>
                    {isCheersConnected && <JobActionButtonsContained onClick={()=>{}}
                      width={'100%'}
                      text={'Disconnect Cheers'}
                      mainColor={STYLE_CONSTANTS.COLORS.globalRed}
                      disabled={!props.viewMode}
                      action={disconnectCheers}
                    />}
                  </Box>





                </CardContent>
              </Card>
            </Grid>
            {!props.viewMode && <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancel} submitText={props.isInvite ? 'Invite' : 'Update'} cancelText={'Cancel'}/>
              </Box>
            </Grid>}
          </Grid>

        </form>
      </FormProvider>
    </>
  );
};

UserInfoPanel.propTypes = {
  userData: PropTypes.instanceOf(Object),
  setUserData: PropTypes.func,
  viewMode: PropTypes.bool,
  setViewMode: PropTypes.func,
  setSubmitting: PropTypes.func,
  isInvite: PropTypes.bool,

};

UserInfoPanel.defaultProps = {
  userData: {},
  viewMode: true,
  setViewMode: ()=> {},
  setSubmitting: ()=> {},
  isInvite: false,
  setUserData: ()=> {},

};

import React from 'react';
import PropTypes from 'prop-types';
import {Text} from '../../../pieces/text';
import * as allConstants from '../../../constants/allConstants';

export const NotAllowedTo = (props) => {

  let message = props.action ? allConstants.ERROR_MSG.NO_PERMISSION(props.action, props.module ? props.module : 'this record')
    : 'You do not have permission to perform this action';

  return(
    <Text text={message}/>
  );
};


NotAllowedTo.propTypes = {
  action: PropTypes.string,
  module: PropTypes.string,
};

NotAllowedTo.defaultProps = {
  action: null,
  module: null,
};

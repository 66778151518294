import JOB_FIELDS from './formFields/jobFields';
import {EQUIPMENT} from './equipment';

export const SYSTEM_CONTENT = {
  [JOB_FIELDS.MECHANICAL.COOLING_SYSTEM.api_name]: [EQUIPMENT.CONDENSER,
    EQUIPMENT.HEAT_PUMP_COND, EQUIPMENT.MINI_SPLIT,  EQUIPMENT.MINI_SPLIT_DUCTED, EQUIPMENT.COIL],
  [JOB_FIELDS.MECHANICAL.HPU_SYSTEM.api_name]: [EQUIPMENT.FURNACE, EQUIPMENT.AIR_HANDLER,  EQUIPMENT.HEAT_PUMP_PU, EQUIPMENT.GAS_PU],
  [JOB_FIELDS.MECHANICAL.DUCTS.api_name]: [EQUIPMENT.R6, EQUIPMENT.R8, EQUIPMENT.R10],
  [JOB_FIELDS.ELECTRICAL_SYSTEM.api_name]: [EQUIPMENT.BR_CIR, EQUIPMENT.SUB_PANELS, EQUIPMENT.EV_CAR, EQUIPMENT.DISC_BOX, EQUIPMENT.MOTORS ],
  [JOB_FIELDS.TEMP_POWER_SYSTEM.api_name]: [EQUIPMENT.TEMP_POWER_POLES, EQUIPMENT.SUB_POLES, EQUIPMENT.SUB_DISTRO_PANELS, EQUIPMENT.TRANSFORMERS, EQUIPMENT.GENERATORS],
  [JOB_FIELDS.WATER_HEATER_SYSTEM.api_name]: [EQUIPMENT.WATER_HEATER_TRADITIONAL, EQUIPMENT.WATER_HEATER_TANKLESS, EQUIPMENT.EXPANSION_TANKS]

};

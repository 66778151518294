import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Box, Typography} from '@mui/material';
import Spacer from './spacer';

export const Text = (props)=> {
  const sizes = {
    small:{xs: '10px', md: '12px', xl: '14px'},
    normal:{xs: '11px', md: '13px', xl: '15px'},
    large:{xs: '12px', md: '14px', xl: '16px'},
    xLarge:{xs: '13px', md: '16px', xl: '18px'},
  };

  const weights = {
    light: '400',
    normal: '500',
    bold: '600'
  };

  const styles = {
    fontSize: props.sizePx ? `${props.sizePx}px`:props.size ? sizes?.[props.size]|| sizes.normal : sizes.normal,
    color: props.pale ? STYLE_CONSTANTS.COLORS.fontPale : props.color? props.color : STYLE_CONSTANTS.COLORS.fontMain,
    fontWeight: props.fontWeight ? props.fontWeight : props.weight ? weights?.[props.weight] || weights.normal : weights.normal,
    textTransform: props.uppercase ? 'uppercase' : 'inherit',
    fontStyle: props.italic ? 'italic' : 'inherit',
  };
  if(!props.text) return null;

  return(
    <Typography variant='p' sx={styles}>
      {props.text}
    </Typography>
  );
};

Text.propTypes = {
  size: PropTypes.string,
  //size: PropTypes.instanceOf(Object),
  pale: PropTypes.bool,
  weight: PropTypes.instanceOf(Object),
  text: PropTypes.string,
  color: PropTypes.string,
  uppercase: PropTypes.bool,
  sizePx: PropTypes.number,
  fontWeight: PropTypes.number,
  italic: PropTypes.bool,
};

Text.defaultProps = {
  size: null,
  pale: false,
  weight: null,
  text: null,
  color: null,
  uppercase: false,
  sizePx: null,
  fontWeight: null,
  italic: false,
};

export const TextWithIconWrapper = (props)=> {
  return (
    <Box display={'flex'} alignItems={'center'}
      sx={{
        '& svg': {
          color: props.iconColor || STYLE_CONSTANTS.COLORS.fontMain
        },
        textTransform: 'uppercase'

      }}>
      {props.icon && props.icon}
      {!props.spacing && props.spacing !== 'none' && <Spacer x={1}/>}
      {props.children}
    </Box>
  );
};

TextWithIconWrapper.propTypes = {
  icon: PropTypes.node,
  iconColor: PropTypes.string,
  spacing: PropTypes.string,
};

TextWithIconWrapper.defaultProps = {
  icon: null,
  iconColor: null,
  spacing: null,
};

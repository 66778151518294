import React, {useEffect, useState} from 'react';
import {ThemeProvider, Box, Grid} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import * as allConstants from '../../constants/allConstants';
import AnalyticsMenuBar from './analyticsMenuBar';
import Utils from '../../utils/utils';
import PermitAnalytics from './permitAnalytics';
import HersAnalytics from './hersAnalytics';
import AnalyticSideBarItems from './analyticSideBarItems';
import FinanceAnalytics from './financeAnalytics';
import {useSearchParams} from 'react-router-dom';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';
import {globalUseClasses} from '../../styles/globalClasses';
import {useSelector} from 'react-redux';
import myTheme from '../../styles/myStyles';

const AnalyticsView = () => {
  const queries =CustomMediaQuery();
  const classes = {...globalUseClasses()};

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const company = useSelector(state=> state.company);

  const [analyticsView, setAnalyticsView] = useState('permits');

  const changeView = (name) => {
    setAnalyticsView(name);
    console.log(name);
  };

  useEffect(()=> {
    sessionStorage.clear();
  }, []);

  useEffect(()=> {
    let params = {view: analyticsView};

    setSearchParams(Utils.createQueryParam(location, params));
  }, [analyticsView]);

  const {allowedTests} = company;
  const showHERS = allowedTests && Array.isArray(allowedTests) && allowedTests.includes(allConstants.TEST_TYPES.HERS.value);

  const showAnalyticsView = (view) => {
    switch (view) {
    case AnalyticSideBarItems[1].name:
      return <HersAnalytics company={{[allConstants.COMPANY_FIELDS.NAME.api_name]: company?.[allConstants.COMPANY_FIELDS.NAME.api_name]}}/>;
    case  AnalyticSideBarItems[2].name:
      return <FinanceAnalytics showHers={showHERS} company={{[allConstants.COMPANY_FIELDS.NAME.api_name]: company?.[allConstants.COMPANY_FIELDS.NAME.api_name]}}/>;
    default:
      return <PermitAnalytics company={{[allConstants.COMPANY_FIELDS.NAME.api_name]: company?.[allConstants.COMPANY_FIELDS.NAME.api_name]}}/>;
    }
  };

  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box display={'flex'} className={classes.content_container}>
          <Box display={'flex'}
            flexDirection={'column'}
            minWidth={'250px'}
            sx={{
              position: 'fixed',
              top: 0,
              backgroundColor: STYLE_CONSTANTS.COLORS.white,
              paddingTop: STYLE_CONSTANTS.HEIGHT.NAVBAR(queries.isXS),
              height: '100%'
            }}
          >

            <AnalyticsMenuBar selected={analyticsView}
              showHers={showHERS}
              changeView={changeView}/>
          </Box>


          <Box paddingLeft={'250px'}
            paddingTop={`${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: queries.isXS, number: true})-10}px`}>

            <Grid container
              gap={2}
              sx={{
                backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
                padding: '20px',
                paddingTop: '0px'
              }}>
              {showAnalyticsView(analyticsView)}
            </Grid>

          </Box>


        </Box>

      </ThemeProvider>
    </>
  );
};


export default AnalyticsView;

import React from 'react';
import NoDataImage from '../static/images/noDataFound.png';
import {Box} from '@mui/material';


const NoDataFound = ()=> {

  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'center'} height={'100%'}>
      <img src={NoDataImage} height={'130px'} alt={''}/>
    </Box>

  );
};
export default NoDataFound;

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import * as allConstants from '../../../constants/allConstants';
import CustomIcon from '../../../static/icons/Icon';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';

const BackTo = (props)=> {
  const iconProps = {
    icon: `${'arrow_left'}`,
    color: `${STYLE_CONSTANTS.COLORS.globalBlue}`,
    fill:'none',
    style: {
      marginRight: '8px'
    }

  };

  return (
    <>
      <CustomIcon {...iconProps}/>
      <Link to={props.path} className={props.cl_name}>{props.text} </Link>
    </>

  );
};

BackTo.propTypes = {
  text: PropTypes.string,
  cl_name: PropTypes.string,
  path: PropTypes.string
};

BackTo.defaultProps = {
  path: allConstants.PATH.HOME,
  text: 'Go back',
  cl_name: '',
};

export default BackTo;
import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';

class PlumbingDisplayUtils {
  constructor() {
    this.pl_fields = allConstants.JOB_FIELDS.PLUMBING;
    this.repairsMap = {
      [this.pl_fields.DRAINS.api_name]: this.pl_fields.DRAINS,
      [this.pl_fields.REPAIR_SEWER.api_name]: this.pl_fields.REPAIR_SEWER,
      [this.pl_fields.CLEAN_OUTS.api_name]: this.pl_fields.CLEAN_OUTS,
      [this.pl_fields.LINERS.api_name]: this.pl_fields.LINERS,
      [this.pl_fields.SPOT_REPAIR.api_name]: this.pl_fields.SPOT_REPAIR
    };
  }

  getRepiars = (details, print)=> {
    let repairs = details?.[this.pl_fields.REPAIR.api_name];
    if(!repairs) return null;


    const ordered_fields = new Map([
      [
        this.pl_fields.DRAINS.api_name,
        [ this.pl_fields.DRAIN_LENGTH,
          this.pl_fields.DRAIN_SIZE,
          this.pl_fields.DRAIN_FIXTURES
        ],
      ],
      [
        this.pl_fields.REPAIR_SEWER.api_name,
        [ this.pl_fields.SEWER_LENGTH,
          this.pl_fields.SEWER_SIZE,
        ],
      ],
      [
        this.pl_fields.CLEAN_OUTS.api_name,
        [ this.pl_fields.CLEANOUT_LOCATIONS],
      ],
      [
        this.pl_fields.LINERS.api_name,
        [ this.pl_fields.LINER_QUANTITY],
      ],
      [
        this.pl_fields.SPOT_REPAIR.api_name,
        [ this.pl_fields.LENGTH_SPOT_REPAIRS,
          this.pl_fields.SIZE_SPOT_REPAIRS,
          this.pl_fields.SPOT_LOCATION
        ],
      ]
    ]);
    const content = [];

    ordered_fields.forEach((value, key)=> {
      if(repairs?.[key]) {
        const filtered = value.filter(f=> repairs[key]?.[f.api_name] && Utils.valueIsSpecified(repairs[key][f.api_name]));
        if(filtered && filtered.length !== 0) {
          content.push({
            name: jobDisplayUtils.getFormattedKey(this.repairsMap[key], print),
            fields: filtered.map((f)=> {
              return {label: jobDisplayUtils.getFormattedKey(f, print), value: repairs[key][f.api_name]};
            })
          });
        }
      }
    });
    return content;
  }
  getEquipmentData = (details, equipment, print)=> {
    let all_equipment = [];
    let ws_scope;
    if(Utils.objectHasProperty(details, allConstants.EQUIPMENT.WATER_SOFTNER.api_name)) {
      ws_scope = details?.[allConstants.EQUIPMENT.WATER_SOFTNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
      all_equipment.push(
        {...details[allConstants.EQUIPMENT.WATER_SOFTNER.api_name], ...{eq_name: allConstants.EQUIPMENT.WATER_SOFTNER.api_name}}
      );
    }

    if(equipment && Array.isArray(equipment)) {
      all_equipment = [...all_equipment, ...equipment];
    }

    if(all_equipment.length === 0 && !ws_scope) return {content: null, ws_scope: null};

    return {content: plumbingUtils.getEquipment(all_equipment, print), ws_scope: ws_scope};
  };
  getReplaces = (details, print)=> {
    const replaceFields = [
      this.pl_fields.REPLACE_WATER_MAIN,
      this.pl_fields.WATER_MAIN_DIAMETER,
      this.pl_fields.REPLACE_SEWER_LINE,
      this.pl_fields.SEWER_LENGTH,
      this.pl_fields.SEWER_LINE_DIAMETER,
      this.pl_fields.TRENCHLESS_PIPE_BURSTING,
      this.pl_fields.SEWER_LINE_PRIVATE_PROPERTY,
      this.pl_fields.SEWER_LINE_PUBLIC_PROPERTY
    ];
    const replace_block = replaceFields.map((e) => {
      const value = details?.[this.pl_fields.REPLACE.api_name]?.[e.api_name];
      if (Utils.valueIsSpecified(value)) {
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e, print), value, print);
      }
      return undefined;
    });
    return replace_block.filter((el)=> el);

  }

  getRepipes = (details, print) => {
    const repipe_fields = [
      this.pl_fields.REPIPE_FRESH_WATER,
      this.pl_fields.REPIPE_DRAINS,
    ];
    const repipe_block = repipe_fields.map((e) => {
      const value = details?.[this.pl_fields.REPIPE.api_name]?.[e.api_name];
      if (Utils.valueIsSpecified(value)) {
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e, print), value, print);
      }
      return undefined;
    });
    return repipe_block.filter((el)=> el);
  }
  getEquipment = (equipment, print) => {
    const EQ = allConstants.EQUIPMENT;
    const SHARED = allConstants.JOB_FIELDS.SHARED;
    const eq_count = {
      [EQ.WATER_HEATER_TRADITIONAL.api_name]: 0,
      [EQ.WATER_HEATER_TANKLESS.api_name]: 0,
      [EQ.EXPANSION_TANKS.api_name]: 0,
    };

    const eq_ordered_fields = {
      [EQ.WATER_HEATER_TRADITIONAL.api_name]: [
        SHARED.QUANTITY,
        this.pl_fields.GALLONS,
        SHARED.ACTION,
        SHARED.LOCATION,
        this.pl_fields.FUEL_TYPE,
        this.pl_fields.ENERGY_FACTOR,
        this.pl_fields.INS_R_VALUE,
        this.pl_fields.BTU_INPUT

      ],
      [EQ.WATER_HEATER_TANKLESS.api_name]: [
        SHARED.ACTION,
        SHARED.LOCATION,
        SHARED.GAS_TO_METER,
        allConstants.JOB_FIELDS.MECHANICAL.GAS_LINE_FT,
        SHARED.IS_ELECTRICAL
      ],
      [EQ.EXPANSION_TANKS.api_name]: [
        SHARED.QUANTITY,
        this.pl_fields.GALLONS
      ],
      [EQ.WATER_SOFTNER.api_name]: [
        allConstants.JOB_FIELDS.GENERAL.CO_OR_NEW,
        this.pl_fields.ELECTRICAL_PERMIT,
        SHARED.LOCATION
      ]
    };

    if(!equipment) return null;

    return jobDisplayUtils.getEquipment({
      equipment: equipment,
      fieldOrder: eq_ordered_fields,
      count: eq_count,
      print: print
    });
  };
  getSanitation = (details, print=false) => {
    const fields = [this.pl_fields.SANITATION_LOCATION, this.pl_fields.JURISDICTION];
    const sanitation= details?.[this.pl_fields.SANITATION.api_name];
    // console.log('sanitation', sanitation);

    if(!sanitation) return null;
    const block = fields.map((e) => {
      let value=sanitation[e.api_name];
      if (Utils.valueIsSpecified(value)) {
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e,print), value, print);
      }
      return undefined;
    });
    // console.log('builder_block', block);
    return block.filter((el)=> el);
  }
}

const plumbingUtils = new PlumbingDisplayUtils();
export {plumbingUtils};


import React, {useState, useCallback} from 'react';
import base_url, {urlSearchParams} from '../../../baseUrls';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../../pieces/navbar/navbar';
import UserModal from '../../../pieces/modals/userModal';
import * as allConstants from '../../../constants/allConstants';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {contractorUserPageRules, CONTRACTOR_ELEMENTS} from '../../../permissions/contractorUserPageAccessControl';
import AllUsersGrid from '../../users/allUsersGrid';
import myTheme from '../../../styles/myStyles';
import DisplayListsPageWrapper from '../../wrappers/displayListsPageWrapper';
import SetCompanyAdmin from '../../users/setCompanyAdmin';
import ListPageHeader from '../../../pieces/listPageHeader';
import usePagination from '../../../hooks/usePagination';
import {useGridViewState} from '../../../hooks/useGridViewState';
import AccessDenied from '../../misc/accessDenied';
import {globalUseClasses} from '../../../styles/globalClasses';
import {useSelector, useDispatch} from 'react-redux';
import actions from '../../../redux/actions';
//import {companyAndUserUtils} from '../../../utils/companyAndUserUtils';
import ChangePasswordUserForm from '../../users/changeUserPassword';
import PrevendAdminDeactivate from '../../users/preventAdminDeactivation';
import {unwrapRequestResult} from '../../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';
import Utils from '../../../utils/utils';

export const ServiceTitanEmp  = (props)=> {
  const viewController = useGridViewState();
  const dispatch = useDispatch();
  const classes = globalUseClasses();
  const pagination = usePagination();
  //const company = useSelector(state => state.company);
  const navigate = useNavigate();
  

  if(!NewPermissionGate({
    user: viewController.user,
    elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.viewPage]
  })) {
    return <AccessDenied/>;
  }

  const handleNewLookupItem = (params) => {

    getSchedAssignees().then (x => {
      assigneeData = x;
      itemSelection.showDialog(assigneeData, params, 'cityInspection,schedAssignee');
    }); 
  }

  // const user = useSelector(state => state.user);
  //const handleAddUserOpen = useCallback(() =>  navigate(`${allConstants.PATH.USER}${location.search}`), []);

  const handleAddUserOpen = useCallback(() =>  {
    
    const url = `${allConstants.PATH.USER}${location.search}`;

    //console.log('url user setup =>');
    //console.log(url);
    //console.log('location');
    //console.log(location);
    
    navigate(`${allConstants.PATH.USER}${location.search}`);

  }, [])
  
  const [showChangeUserPasswordPopup, setChangeUserPasswordPopup] = useState(false);
  const handleChangeUserPasswordClose = () => setChangeUserPasswordPopup(false);

  const [showSetAdminPopup, setShowSetAdminPopup] = useState(false);
  const handleSetAdminClose = ()=> setShowSetAdminPopup(false);

  const [showSetAdminDeactivateWarning, setShowSetAdminDeactivateWarning] = useState(false);
  const handleShowAdminDeactivateWarning = (open)=> setShowSetAdminDeactivateWarning(open);

  const [selectedUser, setSelectedUser] = useState(undefined);
  const {USER_FIELDS} = allConstants;


  const resetPasswordUserHandler = (data)=> {
    setSelectedUser(data);
    setChangeUserPasswordPopup(true);
  };

  const changeAdminHandler = (data)=> {
    setSelectedUser(data);
    setShowSetAdminPopup(true);
  };
  const changeAdminCancel = ()=> {
    setShowSetAdminPopup(false);
  };

  const deactivateUserHandler = async (data)=> {

    if(data[USER_FIELDS.ROLE.api_name] === allConstants.CONTRACTOR_ROLES.COMPANY_ADMIN) {
      setSelectedUser(data);
      handleShowAdminDeactivateWarning(true);
    } else {
      const deactivateStatus = await dispatch(actions.deactivateUser({userId: data?.[USER_FIELDS.ID.api_name]}));
      unwrapRequestResult({
        result: deactivateStatus,
        successMessage: `Deactivated: ${data?.[USER_FIELDS.FIRST_NAME.api_name]} ${data?.[USER_FIELDS.LAST_NAME.api_name]}.`,
        errorMessage: 'Error on deactivation',
        showSuccess: true,
      } );
      viewController.refreshOnSuccess(deactivateStatus);
    }

  };


  const inviteUser = async (data) => {

    
    //console.log('inside service titan invite User');
    //console.log(data);

    const list = data.firstName?.split(" ",2);
    const firstName = list.length > 0 ? list[0].trim() : "";
    const lastName = list.length > 1 ? list[1].trim() : "";
    const companyId = Utils.getUrlParameter('companyId') ?? '';

    //console.log('location=>');
    //console.log(location.search);

    let url = `${allConstants.PATH.USER}?companyId=${companyId}&email=${data?.email}&firstName=${firstName}&lastName=${lastName}`;
    url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanEmp`;
   
    navigate(url);
  }

  const editUser = async (permitUserId) => {

    const companyId = Utils.getUrlParameter('companyId') ?? '';
  
    let url = `${allConstants.PATH.USER}?companyId=${companyId}&userId=${permitUserId}`;
    url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanEmp`;
    navigate(url);

  }
  const headerComponent = <ListPageHeader header={'Service Titan Employees'}
    addHandler={handleAddUserOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {false} 
  >
    {pagination.ItemsController}
  </ListPageHeader>;

const getFetchParams = ()=> {
  const url = `${base_url.api}users/get-contractor-users-service-titan`;
  const respKey = 'users';
  return {url: url, respKey: respKey};
};

const fetchParams = getFetchParams();

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.pageInsideTab}>
            {selectedUser && showChangeUserPasswordPopup && <UserModal
              maxWidth={'600px'}
              open={showChangeUserPasswordPopup}
              preventScroll={false}
              handleClose={handleChangeUserPasswordClose}
              header={`${selectedUser[USER_FIELDS.EMAIL.api_name]}`}>
              <ChangePasswordUserForm selectedUser={selectedUser} closeModal={handleChangeUserPasswordClose}/>
            </UserModal>}
            {selectedUser && showSetAdminPopup && <UserModal
              open={showSetAdminPopup}
              handleClose={handleSetAdminClose}
              header={selectedUser?.[allConstants.USER_FIELDS.COMPANY_NAME.api_name]}

            >
              <SetCompanyAdmin selectedUser={selectedUser} closeModal={handleSetAdminClose}/>
            </UserModal>}
            {selectedUser && showSetAdminDeactivateWarning && <UserModal
              open={showSetAdminDeactivateWarning}
              handleClose={()=>handleShowAdminDeactivateWarning(false)}
              header={selectedUser?.[allConstants.USER_FIELDS.COMPANY_NAME.api_name]}

            >
              <PrevendAdminDeactivate selectedUser={selectedUser} closeModal={handleShowAdminDeactivateWarning}/>
            </UserModal>
            }

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllUsersGrid
                isTabOffset={true}
                resetPasswordUserHandler={resetPasswordUserHandler}
                changeAdminHandler={changeAdminHandler}
                changeAdminCancel={changeAdminCancel}
                viewController={viewController}
                deactivateUserHandler={deactivateUserHandler}
                fetchParams={fetchParams} 
                overrideCompanyId={Utils.getUrlParameter('companyId') ?? ''}
                overrideEditUser={editUser}
                overrideInviteUser={inviteUser}
                pagination={pagination}
                refreshTimestamp={viewController.refreshTimestamp}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
}
import {CustomMediaQuery} from '../../../../hooks/customMediaQuery';
import {FixtureTable} from '../fixtureTable';
import {jobDisplayUtils} from './jobDisplayUtils';

const DisplayFixtureTable = ({fixtureData, target, header})=> {
  const queries = CustomMediaQuery();
  if(!fixtureData) return null;
  const items = jobDisplayUtils.getDisplayFixtures(fixtureData,
    target, false,
    queries.isXS ? 1 : queries.isSM? 2 : queries.isMD ? 3 :4);
  if(!items) return null;
  return FixtureTable({items: items, header: header ? header : 'Equipment'});
};


export {DisplayFixtureTable};

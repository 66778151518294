import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Modal, Typography, IconButton} from '@mui/material';
import {COLORS} from '../../styles/styleConstants';
import CloseIcon from '@mui/icons-material/Close';
import Spacer from '../spacer';
import {Text} from '../text';
import Loader from '../../pages/misc/loader';


export const ModalLoader = ()=> {
  return (
    <Box position={'absolute'} sx={{
      width: '100%',
      marginTop: '-50px',
      height: '100%',
      zIndex: 10000,
      backgroundColor: '#ffffff69',
    }}>
      <Loader useOwnWrapper={true} timeout={1}/>
    </Box>
  );
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  maxWidth: '1400px'
};
/**
 *
 * @param preventScroll
 */
const bodyScroll = (preventScroll)=> {return {
  overflow: preventScroll ? 'hidden': 'scroll',
  maxHeight: '95vh',
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none'
  }
};};


export const ModalContentWrapper = (props)=> {
  return (
    <Grid container alignItems={'baseline'} position={'relative'}>
      <Box position={'absolute'} top={5} right={-25}>
        <IconButton
          sx={{backgroundColor:COLORS.backgroundMain}}
          onClick={props.handleClose}>
          <CloseIcon/>
        </IconButton>
      </Box>
      <Grid item display={'flex'} alignItems={props.toggler ? 'center' : 'flex-end'}
        justifyContent={'flex-start'} xs={12}
        paddingBottom={props.note ? 1 : 3}

      >
        <Box display={'flex'} alignItems={'flex-end'}
          // width={200}
          minWidth={200}
          borderBottom={props.header ? `2px solid ${COLORS.globalBlue}` : 'none'}
        >
          <Typography variant={'h4'} sx={{lineHeight: '2.5em'}}>{props.header}</Typography>

        </Box>
        {props.toggler && <>
          <Spacer x={4} />

          {props.toggler}
          <Spacer x={3} />
        </>
        }


      </Grid>
      {props.note &&<Grid item paddingBottom={3}>
        <Text text={props.note?.text} color={props.note?.color || 'inherit'}/>
      </Grid>}
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
};

ModalContentWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  toggler: PropTypes.node,
  note: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string
  }),
};
ModalContentWrapper.defaultProps = {
  header: '',
  toggler: null,
  note: null
};

const ModalWrapper = (props)=> {
  return (
    <Modal
      open={props.open}
      onClose={(e, reason)=> {
        if(reason === 'backdropClick') return;
        props.handleClose();
      }}
      aria-labelledby={`${props.modalName}-modal-title`}
      aria-describedby={`${props.modalName}-modal-description`}
    >
      {/*<Box sx={{...style, ...{width: props.width}, ...((queries.isXS || queries.isSM) && bodyScroll)}}>*/}
      <Box sx={{...style, ...{display: 'flex', flexDirection: 'column',
        width: props.width, paddingBottom: 0, padding: props.padding}, ...props.customStyle, ...bodyScroll(props.preventScroll)}}>
        {props.children}

      </Box>
    </Modal>
  );
};

ModalWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  width: PropTypes.instanceOf(Object),
  padding: PropTypes.any,
  customStyle: PropTypes.instanceOf(Object),
  preventScroll: PropTypes.bool,

};
ModalWrapper.defaultProps = {
  width:  {xs: '98%'},
  padding: {xs: 3, md:3, lg: 4},
  customStyle: {},
  preventScroll: true,

};
export default ModalWrapper;

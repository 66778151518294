export const RATER_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'name', required: true, label: 'Name', display_label: 'Name'},
  STATUS: {api_name: 'status', required: false, label: 'status', display_label: 'status'},
  PHONE_NUMBER: {api_name: 'phoneNumber', required: false, label: 'Phone Number', display_label: 'Phone Number'},
  ALT_PHONE_NUMBER: {api_name: 'altPhoneNumber', required: false, label: 'Alt Phone Number', display_label: 'Alt Phone Number'},
  EMAIL: {api_name: 'email', required: true, label: 'Calendar Email', display_label: 'Calendar Email'},
  ALT_EMAIL: {api_name: 'altEmail', required: false, label: 'Alt Email', display_label: 'Alt Email'},
  DEPARTMENTS: {api_name: 'departments', required: true, label: 'Department', display_label: 'Department'},
  MARKETS: {api_name: 'markets', required: true, label: 'Market', display_label: 'Market'},
  CITIES: {api_name: 'cities', required: false, label: 'Cities', display_label: 'Cities'},
  ONYX_ENVOY_EMAIL: {api_name: 'onyxEnvoyEmail', required: true, label: 'Onyx Email', display_label: 'Onyx Email'},
  CAN_DO_EUC: {api_name: 'canDoEUC', required: false, label: 'Can Do EUC', display_label: 'Can Do EUC'},
};

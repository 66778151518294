import React from 'react';
import {Box} from '@mui/system';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import * as allConstants from '../../../constants/allConstants';
import {useFormContext} from 'react-hook-form';

function AttachmentRow(props) {

  const additionalProps = {
    nested: true,
    parent: `${props.blockPrefix}[${props.index}]`,
    directUpload: false,
  };
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const getFileValue = (fieldName, index)=> {
    const v = values?.['files'];
    if(!v || !Array.isArray(v) || !v[0]) return null;
    //* hacky and ugly way to handle attachments from both create job and edit job
    const blockValues = v[0]?.fields?.[index] || v?.[index];
    if(!blockValues) return null;
    if(blockValues?.file && typeof blockValues?.file === 'string') {
      return {
        id: blockValues.file,
        name:blockValues?.fileName || '',
      };
    }
    return blockValues.file;
  };

  const getFileName = (fieldName, index)=> {
    const existingName = values?.['files']?.[index]?.[allConstants.JOB_FIELDS.FILE.NAME.api_name];
    if(existingName) return existingName;
    return undefined;
  };


  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      gap={2}
      width={'100%'}
    >

      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
        }}>
        <Box width={'100%'}>
          <Box
            display={'flex'}
            gap={3}>
            <CustomFormBlock
              {...props} {...additionalProps}
              field={allConstants.JOB_FIELDS.FILE.NAME}
              defaultValue={getFileName(allConstants.JOB_FIELDS.FILE.UPLOAD.api_name, props.index)?.name}
              isEditable={true}
              renderLabel={true}
              fullWidth
            />
            <CustomFormBlock
              {...props} {...additionalProps}
              inputType={inputTypes.file}
              field={allConstants.JOB_FIELDS.FILE.UPLOAD}
              placeholder={allConstants.JOB_FIELDS.FILE.UPLOAD.display_label}
              defaultValue={getFileValue(allConstants.JOB_FIELDS.FILE.UPLOAD.api_name, props.index)}
              isEditable={true}
              renderLabel={true}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ' .MuiButtonBase-root': {
          marginTop: '-7px'
        }
      }}>
        <IconButton
          color='secondary'
          disableRipple={false}
          onClick={()=>props.removeAttachment(props.index)}>
          <DeleteForeverOutlinedIcon/>
        </IconButton>
      </Box>

    </Box>
  );
}

AttachmentRow.propTypes = {
  removeAttachment: PropTypes.func,
  blockPrefix: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default AttachmentRow;

import {useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {AddRowButton, DeleteEquipmentRowButton, variants} from '../pieces/addDeleteEquipmentRowButtons';
import React, {useMemo, useState} from 'react';

export const useSubform = ({collectionName, validationSchema, subformRow, currentValues, addRowText})=> {

  const formMethods = useForm({
    resolver: validationSchema ? yupResolver(validationSchema): undefined,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues: {[collectionName]: currentValues},
  });

  const {fields, append, replace} = useFieldArray({
    control: formMethods.control,
    name: collectionName,
  });

  const fieldsToRender = useMemo(()=> {
    return fields;

  }, [fields]);
  const [deletedRows, setDeletedRows] = useState([]);



  const commonFieldProps = (index) => ({
    fullWidth: true,
    maxWidth: '100%',
    isEditable: true,
    renderLabel: true,
    nested: true,
    parent: `${collectionName}[${index}]`
  });

  const getValue = (fieldName, index)=> {
    const values = formMethods.getValues();
    return values?.[collectionName]?.[index]?.[fieldName];
  };

  const addRowAction = () => {
    append({...subformRow});
  };

  const AddRow = <AddRowButton eqName={collectionName} variant={variants.textWithIcon}
    addAction={addRowAction} text={addRowText}/>;


  const deleteRow = (index) => {
    try {
      const results = [...formMethods.getValues()?.[collectionName]];
      const deletedRowId = results.splice(index, 1);
      setDeletedRows([...deletedRows, ...[deletedRowId[0]]]);
      formMethods.setValue(collectionName,results);
    } catch(e){

    }
  };

  const getDeleteRowButton = (index, action=null)=> <DeleteEquipmentRowButton eqName={collectionName}
    extraStyles={{alignSelf: 'baseline',
      top: '8px',
      position: 'relative',
      padding: '0'
    }}
    deleteAction={() => {
      deleteRow(index);
      if(action){
        action();
      }
    }}/>;

  return useMemo(()=> {
    return {
      getValue: getValue,
      commonFieldProps: commonFieldProps,
      fields: fieldsToRender,
      formMethods: formMethods,
      AddRowButton: AddRow,
      addRowAction: addRowAction,
      getDeleteRowButton:getDeleteRowButton,
      replace: replace,
      deletedRows: deletedRows,
    };
  }, [fieldsToRender, currentValues]);

};

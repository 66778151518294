import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Box, Typography} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import clsx from 'clsx';
import {useFormContext} from 'react-hook-form';

export const BuildingInfo = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.REROOF;
  const subParent = `${FIELDS.BUILDINGS.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.REROOF.api_name]?.[FIELDS.BUILDINGS.api_name];

  const getFieldValue = (fieldName)=> {
    return values?.[fieldName];
  };

  const commonProps = {
    nested: true,
    parent: blockParent,
    fullWidth: true,
    renderLabel: true,
  };
  return (
    <Grid container justifyContent={'center'} spacing={{md: 6, lg: 12}}>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <CustomFormBlock
          {...props} {...commonProps}
          inputType={inputTypes.number}
          field={FIELDS.TOTAL_SQUARES}
          defaultValue={getFieldValue(FIELDS.TOTAL_SQUARES.api_name)}
        />
        <CustomFormBlock
          {...props} {...commonProps}
          inputType={inputTypes.number}
          field={FIELDS.VALUE_REROOF}
          defaultValue={getFieldValue(FIELDS.VALUE_REROOF.api_name)}
        />
        <CustomFormBlock
          {...props} {...commonProps}
          inputType={inputTypes.number}
          field={FIELDS.NUMBER_OF_UNITS}
          defaultValue={getFieldValue(FIELDS.NUMBER_OF_UNITS.api_name)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)} sx={{
        '& .MuiFormControlLabel-root': {
          margin: '0',
        }}}>

        <Box className={classes.two_col_container}
          sx={{
            gridTemplateColumns: '1fr 2fr !important',
            '& .MuiFormControlLabel-root': {
              alignItems: 'center',
              '& .MuiCheckbox-root': {
                paddingTop: '0',
                paddingBottom: '0',
              }
            }
          }}
        >
          <Typography variant={'body1'} fontWeight={'600'} fontSize={'16px'}>Address</Typography>
          <CustomFormBlock{...props} {...commonProps}
            inputType={inputTypes.checkbox}
            renderLabel={false}
            labelLeft={true}
            field={FIELDS.SAME_AS_JOB_ADDRESS}
            defaultValue={getFieldValue(FIELDS.SAME_AS_JOB_ADDRESS.api_name)}
          />
        </Box>

        <CustomFormBlock
          {...props} {...commonProps}
          inputType={inputTypes.address}
          field={allConstants.JOB_FIELDS.GENERAL.ADDRESS}
          defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name]}
          renderLabel={false}
        />
      </Grid>
    </Grid>
  );
};
BuildingInfo.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default BuildingInfo;

import React from 'react';
import PropTypes from 'prop-types';
import {globalUseClasses} from '../../styles/globalClasses';
import {Divider, Grid, Box, Typography} from '@mui/material';
import {FormLine} from './formLine';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme=> {
  return {
    eq_wrapper: {
      '&:last-child hr': {
        display: 'none',
      }
    }
  };
});

const EquipmentBlock = (props)=> {
  const {content} = props;
  const classes = {...globalUseClasses(), ...useStyles()};
  if(!content || content.length === 0) return null;
  return(
    <>
      {content.map((c, idx)=> {
        return (
          <Grid key={idx+c.name}item xs={4} sm={4} md={3} sx={{
            paddingRight: theme => theme.spacing(6),
            paddingBottom: theme => theme.spacing(4),
            minWidth: '300px',
            boxSizing: 'border-box',
          }}>
            <Typography key={c.name+idx}variant={'body1'} className={classes.eq_name}>{c.name}</Typography>

            {c.fields.filter(r=>!props.filter || !props.filter.has(r.label)).map((row, index)=> {
              return (<Box className={classes.eq_wrapper} key={`${row?.value}-${index}`}>
                <FormLine  label={row?.label} value={row?.value} longLabel/>
                <Divider/>
              </Box>
              );
              // }

            }

            )}

          </Grid>
        );
      })}
    </>);
};
EquipmentBlock.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    eq_name: PropTypes.string.isRequired,
    value: PropTypes.any,
  })).isRequired,
  filter: PropTypes.any,
};
EquipmentBlock.defaultProps = {
  filter:  new Set([])
};

export {EquipmentBlock};

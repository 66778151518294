import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, IconButton, styled, Table, TableBody, TableCell, TableHead, TableRow, Box} from '@mui/material';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '../static/images/editIcon.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: STYLE_CONSTANTS.COLORS.chartBlue,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ExpandableTableRow = ({children, expandComponent, ...otherProps}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <StyledTableRow {...otherProps}>

        <TableCell>
          <Box sx={{width:' 24px'}}>
            {otherProps.rowExpandable && <IconButton onClick={() => setIsExpanded(!isExpanded)}
              disableRipple={true}
              sx={{
                paddingY: '0px',
              }}

            >
              {otherProps.expandableRowIcon ? <otherProps.expandableRowIcon/> : null}
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>}
          </Box>
        </TableCell>
        {children}
      </StyledTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell/>
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

ExpandableTableRow.propTypes = {
  children: PropTypes.node,
  expandComponent: PropTypes.node,
  expandableRowIcon: PropTypes.node,
};

ExpandableTableRow.defaultProps = {
  expandableRowIcon: null,
  children: null,
  expandComponent: null,
};


export const CustomTable = (props) => {

  const getColumnWidth = (index)=> {
    if(!props.columnWidths) {
      return 'auto';
    }
    const width = props.columnWidths[index];
    return width ? `${width}px` : 'auto';
  };

  const getHeader = (expandable)=> {
    const header = props.tableHeader.map((item, index) => {
      return <TableCell key={item}
        sx={{color: STYLE_CONSTANTS.COLORS.globalBlue,
          fontWeight: 'bold',
          width: getColumnWidth(index),
        }}>
        {item}
      </TableCell>;
    });
    if(props.isEditable) {
      header.push(<TableCell key={'edit'} sx={{color: STYLE_CONSTANTS.COLORS.globalBlue,
        fontWeight: 'bold',
        width: 'auto',
      }}/>);
    }
    if(expandable) {
      header.unshift(<TableCell key={'expand'} sx={{color: STYLE_CONSTANTS.COLORS.globalBlue,
        fontWeight: 'bold',
        width: '24px',
      }}/>);
    }
    return header;
  };

  const rowData = (item, index, expandable)=> {
    return (
      <>
        {item.map((rowItem, idx) => {
          if(typeof rowItem === 'object') return null;
          if(idx === 1 && !expandable) {
            return <TableCell key={rowItem+idx} component="th" scope="row">
              {rowItem}
            </TableCell>;
          }
          return (
            <TableCell key={rowItem+idx}>
              {rowItem}
            </TableCell>
          );
        })}
        {props.isEditable &&
        <TableCell key={'edit'+index}>
          {props.showEditButton &&
          <Button
            onClick={()=>props.editRowAction(index)}
            startIcon={<img src={EditIcon} alt={''}/>}
            sx={{
              textTransform: 'uppercase',
              paddingX: theme=> theme.spacing(2),
            }}
          />}
          {props.showDeleteBtn &&
          <IconButton aria-label={`delete-${props.tableName}-row-${index}`}
            color={'error'}
            onClick={()=>props.deleteRowAction(index)}
            disableRipple
            sx={{'&.MuiIconButton-root': {padding: 0}}}
          >
            <DeleteForeverOutlinedIcon/>
          </IconButton>}
        </TableCell>}
      </>
    );
  };

  const Body = props.tableData.map((item, index) => {
    return ( props.expandable ?
      <ExpandableTableRow key={`${item[0]}-${index}`}
        expandableRowIcon={props.expandableRowIcon}
        rowExpandable={props.isRowExpandable(item)}
        expandComponent={ props.isRowExpandable(item) ?
          <TableCell colSpan={1+ props.tableHeader.length}>{props.getExpandComponent(item)}

          </TableCell> : <TableCell padding="checkbox" sx={{width: '24px'}}/>}

      >
        {rowData(item, index, true)}
      </ExpandableTableRow> :
      <StyledTableRow key={`${item[0]}-${index}`}>
        {rowData(item, index)}
      </StyledTableRow>
    );
  });

  return (
    <Table aria-label={props.tableName}  size="small" sx={{
      textTransform: 'uppercase'
    }}>
      <TableHead>
        <TableRow sx={{
          '& .MuiTableCell-root': {
            color: STYLE_CONSTANTS.COLORS.globalBlue,
            fontWeight: 'bold',
          }
        }}>
          {getHeader(props.expandable)}
        </TableRow>
      </TableHead>
      <TableBody>
        {Body}
      </TableBody>
    </Table>
  );
};

CustomTable.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableHeader: PropTypes.instanceOf(Array).isRequired,
  tableData: PropTypes.instanceOf(Array).isRequired,
  isEditable: PropTypes.bool,
  editRowAction: PropTypes.func,
  deleteRowAction: PropTypes.func,
  columnWidths: PropTypes.any,
  expandable: PropTypes.bool,
  getExpandComponent: PropTypes.func,
  expandableRowIcon: PropTypes.any,
  isRowExpandable: PropTypes.func,
  showEditBtn: PropTypes.bool,
  showDeleteBtn: PropTypes.bool
};

CustomTable.defaultProps = {
  isEditable: false,
  editRowAction: ()=>{},
  deleteRowAction: ()=>{},
  columnWidths: null,
  expandable: false,
  getExpandComponent: ()=>{return null;},
  expandableRowIcon: null,
  isRowExpandable: ()=>{return false;},
  showEditBtn: true,
  showDeleteBtn: true
};

import React from 'react';
import PropTypes from 'prop-types';
import {Navigate, useLocation} from 'react-router-dom';
import * as allConstants from '../constants/allConstants';
import {useSelector, useDispatch} from 'react-redux';
import ApiUtils from '../utils/apiUtils';
import Utils from '../utils/utils';
import actions from '../redux/actions';
import * as companySlice from '../redux/companies/companySlice';
import LOCAL_STORAGE_KEYS from '../utils/localStorageKeys';

const PrivateRoute =  ({children}) => {
  const user = useSelector(state=> state.user);
  const company = useSelector(state=> state.company);
  const dispatch = useDispatch();
  const location = useLocation();
  if(ApiUtils.isMockMode()){
    return children;
  }

  if(Utils.equalsIgnoreCase(user.status, allConstants.USER_STATUSES.BLOCKED.value)) {
    return <Navigate to={allConstants.PATH.COMPANY_BLOCKED}/>;
  }

  if(user.status === allConstants.USER_STATUSES.PENDING.value || company?.status === allConstants.COMPANY_STATUSES.PENDING.value) {
    if(location.pathname !== allConstants.PATH.COMPANY_PENDING) {
      return <Navigate to={allConstants.PATH.COMPANY_PENDING}/>;
    }

  }
  if(Utils.equalsIgnoreCase(user.status, allConstants.USER_STATUSES.NOT_ACTIVE.value) || Utils.equalsIgnoreCase(user.status, allConstants.USER_STATUSES.DISABLED.value)) {
    dispatch(actions.signOut());
    dispatch(companySlice.resetCompanyState());
    return <Navigate to={allConstants.PATH.NO_ACCESS}/>;
  }
  const isAuthenticated = user.isAuthenticated;

  if (user.isFetching) {
    return null;

  }

  if (!isAuthenticated && !user.isFetching) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.redirectUrl, window.location.href);
    return <Navigate to={`${allConstants.PATH.LOGIN}`} replace={true} />;
  }
  return children;
};

PrivateRoute.propTypes = {
  children:  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PrivateRoute.defaultProps = {
  children: null,
};

export default PrivateRoute;

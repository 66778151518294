import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import {cleanedServerState, initialUserState} from '../userInitialStates';
import {initialCompanyState} from '../../companies/initialStates';

export const impersonate = createAsyncThunk(
  'companies/impersonate',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}companies/impersonate-as-company-admin`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify({companyId:props}),
        }
      );
      let data = await response.json();
      // console.log('impersonate response',response, response.status === 200);
      if (response.status === 200) {
        return data.data;
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const impersonateCompanyReducer = {
  [impersonate.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...payload.company};
    return newState;
  },
};
export const impersonateReducer = {
  [impersonate.pending]: (state)=>{
    state.isFetching = true;
  },
  [impersonate.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...cleanedServerState, ...{companyId: payload.company.id}};
    return newState;
  },
  [impersonate.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};
export const depersonate = createAsyncThunk(
  'companies/depersonate',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}companies/leave-impersonate-as-company-admin`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      let data = await response.json();
      // console.log('getCurrentUserData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const depersonateCompanyReducer = {
  [depersonate.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...initialCompanyState};
    return newState;
  },
};
export const depersonateReducer = {
  [depersonate.pending]: (state)=>{
    state.isFetching = true;
  },
  [depersonate.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...cleanedServerState, ...{companyId: initialUserState.companyId}};
    console.log();
    return newState;
  },
  [depersonate.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

const EQ_LOCATIONS = {
  WATER_HEATER: {
    CHANGEOUT: {display: 'Garage', value: 'Garage', showOrder: 0, def: false},
    NEW: {display: ' Outside closet', value: 'Outside closet', showOrder: 1, def: false},
    RELOCATE: {display: 'Closet', value: 'Closet', showOrder: 2, def: false},
    BASEMENT: {display: 'Basement', value: 'Basement', showOrder: 3, def: false},
    OTHER: {display: 'Other', value: 'Other', showOrder: 4, def: false},
  },
  MECH_LOCATIONS: {
    BACK_YARD: {display: 'Back Yard', value: 'Back Yard', showOrder: 0, def: false},
    LEFT_YARD: {display: 'Left Side Yard', value: 'Left Side Yard', showOrder: 1, def: false},
    RIGHT_YARD: {display: 'Right Side Yard', value: 'Right Side Yard', showOrder: 2, def: false},
    ROOF: {display: 'Roof', value: 'Roof', showOrder: 3, def: false},
    FRONT_YARD: {display: 'Front Yard', value: 'Front Yard', showOrder: 4, def: false},
    OTHER: {display: 'Other', value: 'Other', showOrder: 5, def: false},
  },
  FURNANCE_LOCAIONS: {
    ATTIC: {display: 'Attic', value: 'Attic', showOrder: 0, def: false},
    CLOSET: {display: 'Closet', value: 'Closet', showOrder: 1, def: false},
    GARAGE: {display: 'Garage', value: 'Garage', showOrder: 2, def: false},
    BASEMENT: {display: 'Basement', value: 'Basement', showOrder: 3, def: false},
    UNDER_HOUSE: {display: 'Under House', value: 'Under House', showOrder: 4, def: false},
    OUTSIDE: {display: 'Outside', value: 'Outside', showOrder: 5, def: false},
    OUTSIDE_CLOSET: {display: 'Outside closet (Need Plan)', value: 'Outside Closet', showOrder: 6, def: false},
    OTHER: {display: 'Other', value: 'Other', showOrder: 7, def: false},
  }

};

const COMPANY_LOCATIONS = {
  SOUTH: {display: 'South', value: 'South', showOrder: 0, def: false},
  NORTH: {display: 'North', value: 'North', showOrder: 1, def: false},
  CENTRAL: {display: 'Central', value: 'Central', showOrder: 2, def: false},
  NEVADA: {display: 'Nevada', value: 'Nevada', showOrder: 3, def: false},
  ARIZONA: {display: 'Arizona', value: 'Arizona', showOrder: 4, def: false},
};

export {EQ_LOCATIONS, COMPANY_LOCATIONS};

import React from 'react';
import PropTypes from 'prop-types';
// import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {Typography, Box} from '@mui/material';
import Spacer from './spacer';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

export const NoRecords = (props) => {

  const getSpacer = (size=1) => {
    if(props.rowDirection) {
      return <Spacer x={size} />;
    }
    return <Spacer y={size} />;
  };

  return (
    <Box display={'flex'} flexDirection={props.rowDirection ? 'row' : 'column'} alignItems={'center'} justifyContent={'center'}>
      {getSpacer(2)}
      {/*<DoDisturbIcon fontSize={'medium'} style={{color: STYLE_CONSTANTS.COLORS.globalBlue50}}/>*/}
      {/*{getSpacer(3)}*/}
      <Typography sx={{
        color: STYLE_CONSTANTS.COLORS.globalBlue50,
        fontSize: props.fontSize || {xs: '18px', xl: '24px'},
        fontWeight: '600',
        letterSpacing: '1.2px'
      }}>{`No ${props.recordName}`}</Typography>
    </Box>
  );
};

NoRecords.propTypes = {
  recordName: PropTypes.string,
  rowDirection: PropTypes.bool,
  fontSize: PropTypes.any
};

NoRecords.defaultProps = {
  recordName: '',
  rowDirection: false,
  fontSize: null

};

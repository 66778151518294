import {useState, useEffect, useCallback} from 'react';
import axios from 'axios';

export function useFetch(query, page) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);

  console.log('useFetch', query, page);

  const sendQueryPage = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      const res = await axios.get(
        `https://openlibrary.org/search.json?q=${query}&page=${page}`
      );
      await setList((prev) => [
        ...new Set([...prev, ...res.data.docs.map((d) => {return {name: d.title, value: d.title};})])
      ]);
      setLoading(false);
    } catch (err) {
      setError(err);
    }

  }, [page]);

  useEffect(() => {
    sendQueryPage(query);
  }, [page]);

  const sendQueryValue = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      const res = await axios.get(
        `https://openlibrary.org/search.json?q=${query}&page=${page}`
      );
      await setList((prev) => [
        ...new Set([...res.data.docs.map((d) => {return {name: d.title, value: d.title};})])
      ]);
      setLoading(false);
    } catch (err) {
      setError(err);
    }

  }, [query]);

  useEffect(() => {
    sendQueryValue(query);
  }, [query]);


  return {loading, error, list};
}

export default useFetch;

import React from 'react';
import {hvacDisplayUtils} from './hvac/hvacDisplayUtils';
import {Box, Grid} from '@mui/material';
import {BlockHeader} from '../../../../pieces/displayJob/blockHeader';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {useJobDetailViewStyles} from '../views/jobDetailViewStyles';
import {DisplayFixtureTable} from './fixtureTable';
import {jobDisplayUtils} from './jobDisplayUtils';
import * as allConstants from '../../../../constants/allConstants';
import {JOB_FIELDS, JOB_TYPES} from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';
import {FormLabel, FormValue} from '../../../../pieces/displayJob/formLine';
import PropTypes from 'prop-types';

const CommercialSection = ({details})=> {
  const classes = {...globalUseClasses(), ...useJobDetailViewStyles()};
  const comBlock = hvacDisplayUtils.getCommercial(details);
  if(!comBlock) return null;
  const comContent = jobDisplayUtils.getBlockComponentToDisplay(comBlock);

  let commercialScope = null;
  if(details?.[JOB_TYPES.COMMERCIAL.api_name]?.[JOB_FIELDS.GENERAL.SCOPE.api_name] &&
    Utils.valueIsSpecified(details?.[JOB_TYPES.COMMERCIAL.api_name]?.[JOB_FIELDS.GENERAL.SCOPE.api_name])) {
    commercialScope = details?.[JOB_TYPES.COMMERCIAL.api_name]?.[JOB_FIELDS.GENERAL.SCOPE.api_name];
  }
  return (
    <>
      <Grid container xs={12}  sx={{marginBottom: '16px!important'}}>
        <Grid item sx={12} md={4}>
          <Box className={classes.details_block}>
            {BlockHeader({header: 'Commercial information'})}
            {comContent && comContent}
          </Box>

        </Grid>
        {commercialScope && <Grid item xs={12}  md={8}  mb={4} px={4}>
          {/*<Box className={classes.details_block}>*/}
          <FormLabel label={`Commercial ${allConstants.JOB_FIELDS.GENERAL.SCOPE.display_label}`}/>
          <FormValue value={commercialScope} />
          {/*</Box>*/}
        </Grid>
        }


        {/*<Grid container >*/}
        <Grid item xs={12} pr={2} mb={2}>
          <DisplayFixtureTable
            header={'Commercial Equipment'}
            target={allConstants.JOB_FIELDS.COMMERCIAL.EQUIPMENT}
            fixtureData={details?.[allConstants.JOB_TYPES.COMMERCIAL.api_name]?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]}/>
        </Grid>
      </Grid>


      {/*</Grid>*/}
    </>
  );
};
CommercialSection.propTypes = {
  details: PropTypes.instanceOf(Object),
};

CommercialSection.defaultProps = {
  details: {}
};
export {CommercialSection};

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import actions from '../../../../redux/actions';
import * as allConstants from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';
import TempPowerEquipment from './tempPowerSystem';

export const PolesAndPanelsBlock = (props)=> {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const parsedDraft = useRef(null);
  const canModifyEquipment = useRef(Utils.canModifyEquipment(props.draftData));

  useEffect(()=> {
    let draftData;
    try {
      parsedDraft.current = JSON.parse(props.draftData?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
      draftData = parsedDraft.current?.equipment;

      if(draftData) {

        const subforms = {
          [allConstants.JOB_FIELDS.TEMP_POWER_SYSTEM.api_name]: Utils.createSystemDataForRedux(draftData, allConstants.JOB_FIELDS.TEMP_POWER_SYSTEM.api_name)
        };
        dispatch(actions.addBulkSubforms({
          jobType: allConstants.JOB_TYPES.TEMPORARY_POWER.api_name,
          subforms
        }));
      }

    } catch (e){

    }
    setIsFirstLoad(false);

  },[]);




  if(isFirstLoad) {
    //don't render subforms before we set draft data, is exists to store
    return null;
  }


  return (
    <TempPowerEquipment {...props} canModifyEquipment={canModifyEquipment.current}/>
  );
};

PolesAndPanelsBlock.propTypes = {
  setEquipmentForm: PropTypes.func.isRequired,
  draftData: PropTypes.string,
};

PolesAndPanelsBlock.defaultProps = {
  draftData: null
};

export default PolesAndPanelsBlock;

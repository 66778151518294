import React from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../constants/allConstants';
import {Box} from '@mui/material';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import LadderIcon from '../static/images/ladder.png';
import {Text} from './text';
import Spacer from './spacer';
import {useAutocompleteOptions} from '../hooks/useAutompleteOptions';

export const InspectionRequirementsForContractor = (props)=> {
  const {job} = props;

  const options = {
    cityOptions: useAutocompleteOptions({field: 'cityOptions'}).options,
  };

  const inspectionData = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name];

  const needLadder = inspectionData?.[allConstants.INSPECTION_FIELDS.LADDER.api_name];
  const requirements = inspectionData?.[allConstants.INSPECTION_FIELDS.REQUIREMENTS.api_name] ?
    inspectionData?.[allConstants.INSPECTION_FIELDS.REQUIREMENTS.api_name] : options.cityOptions?.cityInspectionRequirements?.STANDARD?.display;

  const getInspectionRequirementsDisplayValue = (value)=>{
    const map = {};
    if(!options?.cityOptions?.cityInspectionRequirements) return value;
    Object.values(options?.cityOptions?.cityInspectionRequirements).forEach((requirement)=>{
      map[requirement.value] = requirement.display;
    });
    return map?.[value] ? map?.[value] : value;
  };

  return (
    <Box display={'flex'}>
      <Box sx={{
        border: `1px solid ${STYLE_CONSTANTS.COLORS.teal}`,
        borderRadius: '15px',
        width: 'fit-content',
        padding: '4px 8px',
        display: 'flex',
        backgroundColor: STYLE_CONSTANTS.COLORS.teal,
        alignItems: 'center',
        '& img': {
          width: '26px',
        }
      }}>
        <Text text={inspectionData?.[allConstants.INSPECTION_FIELDS.TYPE.api_name] ?
          inspectionData?.[allConstants.INSPECTION_FIELDS.TYPE.api_name] : allConstants.INSPECTION_TYPES.FINAL.value
        }/>
      </Box>
      {(needLadder || requirements) &&
        <>
          <Spacer x={2}/>
          <Box sx={{
            border: `1px solid ${STYLE_CONSTANTS.COLORS.globalBlue}`,
            borderRadius: '15px',
            width: 'fit-content',
            padding: '4px 8px',
            display: 'flex',
            alignItems: 'center',
            '& img': {
              width: '26px',
            }
          }}>
            {needLadder && <>
              <img src={LadderIcon} alt={'Need ladder'}/>
              <Text text={'Ladder needed'} weight={'bold'} size={'small'}/>
            </>}
            {needLadder && requirements && <>
              <Spacer x={1}/>
              <Text text={'/'}/>
              <Spacer x={1}/>
            </>}
            {requirements && <Text text={getInspectionRequirementsDisplayValue(requirements)} weight={'bold'} size={'small'}/>}


          </Box>

        </>

      }

    </Box>
  );
};

InspectionRequirementsForContractor.propTypes = {
  job: PropTypes.instanceOf(Object).isRequired
};

import React from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {Grid, Paper, ThemeProvider, Box, Typography} from '@mui/material';
import myTheme from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import AllGoodPendingImage from '../../static/images/all-good-pending.svg';
import NavBar from '../../pieces/navbar/navbar';

const CompanyUnderReview = () => {
  const classes = globalUseClasses();
  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          <Grid container xs={12} sx={{backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain, height: '100%'}}>
            <Grid item xs={12} sm={10} md={6} lg={4} sx={{
              margin: 'auto'
            }}>
              <Paper sx={{
                width: '100%',
                //height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px',
                borderRadius: '10px',

              }}>
                <img src={AllGoodPendingImage} alt={''} width={86}/>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    margin: '16px auto'
                  }}>
                  You have registered your company and iPermit team is reviewing your request. We’ll notify you via email once your company is activated. For more questions call:

                </Typography>
                <Box sx={{
                  backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
                  paddingX: '40px',
                  borderRadius: '4px'
                }}>
                  <Typography variant={'body1'}
                    align={'center'} style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      color: STYLE_CONSTANTS.COLORS.globalBlue,
                      margin: '16px auto',

                    }}>
                    855-737-6484
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default CompanyUnderReview;

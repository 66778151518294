import React from 'react';
import PropTypes from 'prop-types';
import {Box, List, ListItem} from '@mui/material';
import {useJob} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import Utils from '../../../utils/utils';
import {Text, TextWithIconWrapper} from '../../../pieces/text';
import {ReactComponent as UserIconSvg} from '../../../static/images/user.svg';
import {ReactComponent as PhoneIconSvg} from '../../../static/images/phoneSimple.svg';
import {ReactComponent as EnvelopeIconSvg} from '../../../static/images/envelopeIcon.svg';
import {ReactComponent as LocationPinIconSvg} from '../../../static/images/locationPin.svg';
import Spacer from '../../../pieces/spacer';


export const SameAddressJobsBlock =(props)=> {
  const {job} = useJob();
  const {GENERAL} = allConstants.JOB_FIELDS;

  const getValueFromJob = (fieldName, parent)=> {
    if(parent) {
      return job?.[parent]?.[fieldName];
    }
    return job?.[fieldName];
  };

  const sameAddressJobs = getValueFromJob(GENERAL.SAME_ADDRESS_JOBS.api_name);

  // const styles = {textTransform: 'uppercase'};
  // if(props?.row) {
  //   styles.display = 'grid';
  //   styles.gridTemplateColumns = '1fr 0.7fr 2fr';
  // }

  // const displayStyles = {
  //   display: props.row ? 'flex' : 'initial',
  //   alignItems: 'center'
  // };

  const handleClick = (jobId) => {

    event.preventDefault(); // Prevents the default link behavior
    const url = `${location.origin}/module/${jobId}${location.search}`;
    window.open(url, '_blank');
  };

  const containerStyle = {
    display: 'flex',
    gap: '6px', // Space between columns
  };
  
  const columnStyle = {
    flex: 1,
  };
  
  const itemStyle = {
    backgroundColor: '#f0f0f0',
    padding: '1px',
    border: '1px solid #ddd',
    marginBottom: '5px', // Space between items
  };

  function splitArrayIntoColumns(arr, numColumns) {
    const result = Array.from({ length: numColumns }, () => []);
    arr.forEach((item, index) => {
      result[index % numColumns].push(item);
    });
    return result;
  }

  const columns = sameAddressJobs.length > 4 ? 2 : 1;
  const columnItems = splitArrayIntoColumns(sameAddressJobs, columns);

  return (
    <Box>
      <div style={containerStyle}>
        {columnItems.map((column, colIndex) => (
          <div key={colIndex} style={columnStyle}>
            {column.map((item, itemIndex) => (
              <div key={itemIndex} style={itemStyle}>
                <a href="#" onClick={ () => handleClick(item.jobId) }>{item.jobNumber}</a>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Box>
  );
};

// SameAddressBlock.propTypes = {
//   row: PropTypes.bool
// };

// HomeownerInfoBlock.defaultProps = {
//   row: false
// };

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import * as allConstants from '../../../constants/allConstants';
import SelectJobType from './pieces/selectJobType';
import Utils from '../../../utils/utils';
import {Box, Typography, Grid} from '@mui/material';
import {JobPaperWrapper} from './pieces/jobPaperWrapper';
import {globalUseClasses} from '../../../styles/globalClasses';
import {useCreateJobStyles} from './createJobStyles';
import {jobShowFieldRules} from './fieldRules';
import {useFormContext} from 'react-hook-form';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';


const GeneralStep = (props)=> {
  const options = Utils.getOptions(props.allowedPermits);
  const classes = {...globalUseClasses(), ...useCreateJobStyles()};

  const showHERS = jobShowFieldRules.showHERS(props.jobType, props.propertyType, props.allowedTests);
  const showEUC = jobShowFieldRules.showEUC(props.jobType, props.propertyType, props.allowedTests);
  const showAsbestos = jobShowFieldRules.showAsbestos(props.jobType);
  const showNewConstr = jobShowFieldRules.showNewConstruction(props.jobType);

  const isTestingOnly = props.jobType && props.jobType === allConstants.JOB_TYPES.TESTING_ONLY.value;
  const isBL = props.jobType && props.jobType === allConstants.JOB_TYPES.BUSINESS_LIC.value;

  const formController = useFormContext();

  const values = formController.getValues();

  // Thanh 12/02/23
  // console.log('form control values');
  //console.log(values);
  //console.log('props');
  //onsole.log(props);

  const isDraft = props?.draftData?.[allConstants.JOB_FIELDS.IS_DRAFT.api_name];

  const getHERSValue = ()=> {
    const touchedFields = formController.formState.touchedFields;
    if(Utils.objectHasProperty(touchedFields, allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name)) {
      const formValues = formController.getValues();
      const hersIsRequired = formValues[allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name];
      return hersIsRequired ? allConstants.ANSWER_OPTIONS.YES.value : allConstants.ANSWER_OPTIONS.NO.value;
    }
    if(props.jobType === allConstants.JOB_TYPES.TESTING_ONLY.value) return allConstants.ANSWER_OPTIONS.YES.value;
    if(isDraft) {
      return props?.draftData?.[allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name] ? allConstants.ANSWER_OPTIONS.YES.value : allConstants.ANSWER_OPTIONS.NO.value;
    }
    if(props.jobType === allConstants.JOB_TYPES.HVAC_RES.value) {
      if(!showHERS) return allConstants.ANSWER_OPTIONS.NO.value;
      if(props.isDuplicate) return allConstants.ANSWER_OPTIONS.NO.value;
      if(props.propertyType === allConstants.PROPERTY_TYPES.MOBILE_HOME.value) return allConstants.ANSWER_OPTIONS.NO.value;
      return allConstants.ANSWER_OPTIONS.YES.value;
    }
    return allConstants.ANSWER_OPTIONS.NO.value;
  };

  const getEUCValue = ()=> {
    const touchedFields = formController.formState.touchedFields;
    if(Utils.objectHasProperty(touchedFields, allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name)) {
      const formValues = formController.getValues();
      const eucIsRequired = formValues[allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name];
      return eucIsRequired ? allConstants.ANSWER_OPTIONS.YES.value : allConstants.ANSWER_OPTIONS.NO.value;
    } else if(isDraft) {
      return props?.draftData?.[allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name] ? allConstants.ANSWER_OPTIONS.YES.value : allConstants.ANSWER_OPTIONS.NO.value;
    }
    return allConstants.ANSWER_OPTIONS.NO.value;
  };

  return (
    <>
      {isDraft && <Box mt={2}>
        <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalRed}}>*Submitted on Service Titan - Job Type is not editable</Typography>
      </Box>}

      <Box ref={props.refs[allConstants.JOB_FIELDS.JOB_TYPE.api_name]}
        display={'flex'} justifyContent={'center'} sx={{
          '& .MuiFormControl-root': {
            width: 'unset'
          },
          '& .MuiFormHelperText-root': {
            alignSelf: 'center',
            fontSize: '14px'
          }
        }}>

        <CustomFormBlock
          {...props}
          maxWidth={'100vw'}
          inputType={inputTypes.radio}
          field={allConstants.JOB_FIELDS.JOB_TYPE}
          nested={false}
          fullWidth={true}
          defaultValue={props.jobType}
          renderLabel={false}
          disabled={isDraft}
          items={<SelectJobType allowedJobs={options}
            selected={props.jobType}
            isDraft={isDraft}
            onMouseDown={true}/>}
        >
        </CustomFormBlock>
      </Box>
      {!isBL && <Box>
        <JobPaperWrapper showHeader header={'General Information'}>
          <Grid container justifyContent={'center'} spacing={{md: 6, lg: 12}}>
            <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
              <Box ref={props.refs[allConstants.JOB_FIELDS.GENERAL.HO_NAME.api_name]}>
                <CustomFormBlock
                  {...props}
                  labelSecondary={true}
                  field={allConstants.JOB_FIELDS.GENERAL.HO_NAME}
                  fullWidth={true}
                  nested={true}
                  parent={allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name}
                  defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.HO_NAME.api_name]}
                />
              </Box>
              <Box ref={props.refs[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name]}>
                <CustomFormBlock
                  {...props}
                  inputType={inputTypes.phone}
                  labelSecondary={true}
                  field={allConstants.JOB_FIELDS.GENERAL.HO_PHONE}
                  fullWidth={true}
                  nested={true}
                  parent={allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name}
                  defaultValue={Utils.reformatPhoneNumber(values?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name])}
                />
              </Box>
              <CustomFormBlock
                {...props}
                inputType={inputTypes.phone}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.ALT_HO_PHONE}
                fullWidth={true}
                nested={true}
                parent={allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name}
                defaultValue={Utils.reformatPhoneNumber(values?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.ALT_HO_PHONE.api_name])}
              />
              <CustomFormBlock
                {...props}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.HO_EMAIL}
                fullWidth={true}
                nested={true}
                parent={allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name]}
              />
              <Box className={classes.two_col_container}>
                <Box ref={props.refs[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name]}>
                  <CustomFormBlock
                    {...props}
                    inputType={inputTypes.singleSelect}
                    labelSecondary={true}
                    field={allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE}
                    options={Utils.getOptions(allConstants.PROPERTY_TYPES)}
                    defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name]}
                    fullWidth={true}
                  />
                </Box>
                <Box ref={props.refs[allConstants.JOB_FIELDS.GENERAL.JOB_VALUE.api_name]}>
                  <CustomFormBlock
                    {...props}
                    inputType={inputTypes.currency}
                    labelSecondary={true}
                    field={{...allConstants.JOB_FIELDS.GENERAL.JOB_VALUE, ...{required: !isTestingOnly}}}
                    fullWidth={true}
                    defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.JOB_VALUE.api_name]}
                    //transform={(val)=> val.parseInt()}
                  />
                </Box>
              </Box>
              {isTestingOnly &&  <Box className={classes.two_col_container}>
                <Box>
                  <CustomFormBlock
                    {...props}
                    labelSecondary={true}
                    nested={true}
                    parent={allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name}
                    field={{...allConstants.PERMIT_MODULE_FIELDS.NUMBER, ...{label: 'Permit Number'}}}
                    fullWidth={true}
                    defaultValue={values?.[allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name]?.[allConstants.PERMIT_MODULE_FIELDS.NUMBER.api_name]}
                  />
                </Box>
                <Box>
                  <CustomFormBlock
                    {...props}
                    labelSecondary={true}
                    inputType={inputTypes.date}
                    nested={true}
                    parent={allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name}
                    field={{...allConstants.PERMIT_MODULE_FIELDS.DATE, ...{label: 'Permit Date'}}}
                    fullWidth={true}
                    defaultValue={values?.[allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name]?.[allConstants.JOB_FIELDS.PERMIT_DATE.api_name]?.toString()}
                  />
                </Box>
              </Box>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
              <Box ref={props.refs[allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS.api_name]}>
                <CustomFormBlock
                  {...props}
                  inputType={inputTypes.address}
                  field={allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS}
                  fullWidth
                  //methods={formMethods}
                  defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS.api_name]}
                  // setValueToForm={formMethods.setValueToForm}
                  renderLabel={false}
                />
              </Box>
              <Box>
                <CustomFormBlock
                  {...props}
                  labelSecondary={true}
                  inputType={inputTypes.number}
                  field={allConstants.JOB_FIELDS.GENERAL.SQFT}
                  fullWidth={true}
                  defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.SQFT.api_name]}
                />
              </Box>

              {(showHERS || showEUC) && <Box className={classes.create_job_two_col_container}>
                {showHERS && !isTestingOnly && <CustomFormBlock
                  {...props}
                  inputType={inputTypes.singleSelect}
                  labelSecondary={true}
                  field={allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED}
                  options={Utils.getOptions(allConstants.ANSWER_OPTIONS)}
                  defaultValue={getHERSValue()}
                  fullWidth={true}
                  //transform={Utils.textToBool}
                />}

                {showEUC && <CustomFormBlock
                  {...props}
                  inputType={inputTypes.singleSelect}
                  labelSecondary={true}
                  field={allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED}
                  options={Utils.getOptions(allConstants.ANSWER_OPTIONS)}
                  defaultValue={getEUCValue()}
                  fullWidth={true}
                  // transform={Utils.textToBool}
                />}
              </Box>}
              <Box className={classes.two_col_container}>
                <CustomFormBlock
                  {...props}
                  labelSecondary={true}
                  field={allConstants.JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER}
                  fullWidth={true}
                  defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER.api_name]}
                />
                {showHERS && <CustomFormBlock
                  {...props}
                  labelSecondary={true}
                  field={allConstants.JOB_FIELDS.GENERAL.HERS_PO_NUMBER}
                  fullWidth={true}
                  defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.HERS_PO_NUMBER.api_name]}
                />}

              </Box>

            </Grid>
          </Grid>
        </JobPaperWrapper>
        <JobPaperWrapper showHeader header={'More Info'}>
          <Grid container justifyContent={'center'} spacing={{md: 6, lg: 12}}>
            <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
              <CustomFormBlock
                {...props}
                inputType={inputTypes.date}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.START_DATE}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.START_DATE.api_name]}
                fullWidth={true}
              />
              <CustomFormBlock
                {...props}
                inputType={inputTypes.date}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.NEED_BY_DATE}
                fullWidth={true}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.NEED_BY_DATE.api_name]}
              />
              {showAsbestos && <CustomFormBlock
                {...props}
                inputType={inputTypes.singleSelect}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.ASBESTOS}
                options={Utils.getOptions(allConstants.ANSWER_OPTIONS)}
                defaultValue={values ? Utils.boolToText(values?.[allConstants.JOB_FIELDS.GENERAL.ASBESTOS.api_name]): allConstants.ANSWER_OPTIONS.NO.value}
                fullWidth={true}
                //transform={Utils.textToBool}
              />}
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
              <CustomFormBlock
                {...props}
                inputType={inputTypes.singleSelect}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.POST_JOB_CARD}
                options={Utils.getOptions(allConstants.POST_JOB_CARD)}
                fullWidth={true}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.POST_JOB_CARD.api_name]}
              />
              <CustomFormBlock
                {...props}
                inputType={inputTypes.date}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.INSP_REQUEST_DATE}
                fullWidth={true}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.INSP_REQUEST_DATE.api_name]}
              />
              {showNewConstr && <CustomFormBlock
                {...props}
                inputType={inputTypes.singleSelect}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.NEW_CONSTRUCTION}
                options={Utils.getOptions(allConstants.ANSWER_OPTIONS)}
                fullWidth={true}
                defaultValue={values ? Utils.boolToText(values?.[allConstants.JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name]): allConstants.ANSWER_OPTIONS.NO.value}
                //transform={Utils.textToBool}
              />}
            </Grid>
          </Grid>
        </JobPaperWrapper>
      </Box>}
      {isBL &&
      <Box>
        <JobPaperWrapper showHeader header={'General Information'}>
          <Grid container justifyContent={'center'} spacing={{md: 6, lg: 12}}>
            <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
              <Box ref={props.refs[allConstants.JOB_FIELDS.GENERAL.JOB_VALUE.api_name]}>
                <CustomFormBlock
                  {...props}
                  inputType={inputTypes.currency}
                  labelSecondary={true}
                  field={{...allConstants.JOB_FIELDS.GENERAL.JOB_VALUE, ...{required: !isBL && !isTestingOnly}}}
                  fullWidth={true}
                  defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.JOB_VALUE.api_name]}
                  //transform={(val)=> val.parseInt()}
                />
              </Box>
              <CustomFormBlock
                {...props}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL}
                fullWidth={true}
                nested={false}
                defaultValue={values?.[allConstants.JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name]}
              />
              <CustomFormBlock
                {...props}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER}
                fullWidth={true}
                //transform={(val)=> val.parseInt()}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER.api_name]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
              <CustomFormBlock
                {...props}
                inputType={inputTypes.date}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.START_DATE}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.START_DATE.api_name]}
                fullWidth={true}
              />
              <CustomFormBlock
                {...props}
                inputType={inputTypes.date}
                labelSecondary={true}
                field={allConstants.JOB_FIELDS.GENERAL.NEED_BY_DATE}
                fullWidth={true}
                defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.NEED_BY_DATE.api_name]}
              />
            </Grid>
          </Grid>
        </JobPaperWrapper>
      </Box>}
    </>
  );

};

GeneralStep.propTypes = {
  allowedPermits: PropTypes.instanceOf(Array).isRequired,
  allowedTests: PropTypes.instanceOf(Array),
  //jobType: PropTypes.string.isRequired,
  //propertyType: PropTypes.string.isRequired,
  refs: PropTypes.instanceOf(Object).isRequired,
  draftData: PropTypes.string,
  isDuplicate: PropTypes.bool,
};
GeneralStep.defaultProps = {
  allowedTests: [],
  draftData: null,
  isDuplicate: false,
};


export {GeneralStep};

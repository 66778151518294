import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {ThemeProvider, Paper, Grid, Button, Typography} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import myTheme from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import * as allConstants from '../../constants/allConstants';
import BlockedImage from '../../static/images/warningImage.png';

const SignupNotCompleted = (props) => {
  const classes = globalUseClasses();
  const navigate = useNavigate();
  const canCompleteSignUp = props.role === allConstants.ROLES.IPERMIT_ADMIN_IMPERSONATE || props.role === allConstants.ROLES.COMPANY_ADMIN;
  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Grid container xs={12} className={classes.page} sx={{backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain}}>
          <Grid item xs={12} sm={10} md={6} lg={4} sx={{
            margin: '200px auto'
          }}>
            <Paper sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px',
              borderRadius: '10px',

            }}>
              <img src={BlockedImage} alt={''}/>
              {canCompleteSignUp && <>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    margin: '16px auto'
                  }}>
                  {'You can\'t create job until signup process is completed.'}
                </Typography>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '16px'
                  }}>
                  {'Please fill company mandatory fields.'} </Typography>
                <Button variant={'text'} onClick={()=> {navigate(allConstants.PATH.COMPANY);}}>
                Go to company details page
                  <ArrowRightAltIcon/>
                </Button>
              </>
              }
              {!canCompleteSignUp && <Typography variant={'body1'}
                align={'center'} style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  margin: '16px auto'
                }}>
                {'You can\'t create job until your company admin completes the registration process.'} </Typography>}
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

SignupNotCompleted.propTypes = {
  role: PropTypes.string.isRequired,
};


export default SignupNotCompleted;

import * as allConstants from '../constants/allConstants';
import * as STYLE_CONSTANTS from './styleConstants';

export const getStatusColor = (status)=> {
  let color;

  switch (status) {
  case allConstants.PERMIT_STATUSES.COMPLETED.value:
  case allConstants.MAILING_STATUSES.MAILED.value:
  case allConstants.MAILING_STATUSES.EMAILED.value:
  case allConstants.MAILING_STATUSES.MAILED_TO_CONTRACTOR.value:
  case allConstants.HERS_STATUSES.SCHEDULED.value:
  case allConstants.RESULT_OPTIONS.PASSED.value: {
    color = '#17AE38';
    break;
  }
  case allConstants.PERMIT_STATUSES.NEED_ATTENTION.value:
  case allConstants.PERMIT_STATUSES.CANCELLED.value:
  case allConstants.HERS_STATUSES.FAILED.value:
  case allConstants.HERS_STATUSES.HOLD_OFF:
  case allConstants.PERMIT_STATUSES.HOLD.value:
  case allConstants.RESULT_OPTIONS.FAILED.value: {
    color = 'red';
    break;
  }
  case allConstants.PERMIT_STATUSES.NEW.value: {
    color =  '#EC8E00';
    break;
  }
  default: {
    color = STYLE_CONSTANTS.COLORS.fontMain;
    break;
  }
  }
  return color;
};

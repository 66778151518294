const REPAIR_FIXTURES_OPTIONS = {
  LAVATORIES: {display: 'Lavatories', value: 'Lavatories', showOrder: 0, def: false},
  TUB: {display: 'Tub', value: 'tub', showOrder: 1, def: false},
  TUB_SHOWER: {display: 'Tub/Shower', value: 'Tub/Shower', showOrder: 2, def: false},
  SHOWER: {display: 'Shower', value: 'Shower', showOrder: 3, def: false},
  TOILET: {display: 'Toilet', value: 'Toilet', showOrder: 4, def: false},
  LAUNDRY_SINK: {display: 'Laundry Sink', value: 'Laundry Sink', showOrder: 5, def: false},
  KITCHEN_SINK: {display: 'Kitchen Sink', value: 'Kitchen Sink', showOrder: 6, def: false},
  WASHERS: {display: 'Washers', value: 'Washers', showOrder: 7, def: false},
  ICE_MAKERS: {display: 'Ice Makers', value: 'Ice Makers', showOrder: 8, def: false},
  GARBAGE_DISPOSER: {display: 'Garbage Disposer', value: 'Garbage Disposer', showOrder: 9, def: false},
  WATER_HEATER: {display: 'Water Heater', value: 'Water Heater', showOrder: 10, def: false},
  HOSE_BIBBS: {display: 'Hose Bibbs', value: 'Hose Bibbs', showOrder: 11, def: false},
  OTHER: {display: 'Other', value: 'Other', showOrder: 12, def: false},
};

export {REPAIR_FIXTURES_OPTIONS};
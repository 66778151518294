import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import {FormProvider} from 'react-hook-form';
import Utils from '../../utils/utils';
import {ListItem, Box} from '@mui/material';
import {TableFormWrapper} from '../wrappers/tableFormWrapper';
import {useSubform} from '../../hooks/useSubform';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {inputTypes} from '../../pieces/inputs/formInput';
import {SCHEDULE_INSPECTION_OPTIONS} from '../../constants/allConstants';
import {NoDataStringWrapper} from '../../pieces/noDataStringWrapper';

const _ = require('lodash');

export const HowToScheduleInspectionFrom = (props) => {
  // eslint-disable-next-line react/prop-types
  const {cityData} = props;
  const {CITY_FIELDS} = allConstants;
  const [currentValues, setCurrentValues] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const ways = cityData?.[CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name];


  const collectionName = CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name;

  const collectionRow = {
    [CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name]: '',
    [CITY_FIELDS.NOTE.api_name]: '',
  };

  const getFormInitialValues = () => {
    const initialValues= [];

    //compose ways to schedule

    if(!ways) {return initialValues;}
    //iter object entries
    for (const [key, value] of Object.entries(ways)) {
      initialValues.push({
        [CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name]: key,
        [CITY_FIELDS.NOTE.api_name]: value,
      });
    }
    return initialValues;

  };

  const {getValue, commonFieldProps, fields, formMethods,
    AddRowButton, getDeleteRowButton} = useSubform({
    collectionName: collectionName,
    subformRow: collectionRow,
    addRowText: 'Add',
    currentValues: getFormInitialValues(),
  });

  props.saveSubform && props.saveSubform(formMethods);

  if(Utils.isDevEnv()) {
    window.cityForm = formMethods;
  }


  const gridTemplateColumns = '200px 1fr 40px';
  const gap = '16px';

  const columns = [
    {name: 'Schedule inspection by'},
    {name: 'Note'}
  ];


  const watchFields = formMethods.watch();

  useEffect(()=> {
    const currTypes = watchFields?.[collectionName].map((item)=> item?.[CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name]);
    if(!_.isEqual(currTypes, currentValues)) {
      setCurrentValues(currTypes);
    }
  });

  const getOptions = (index)=> {
    const options = {...SCHEDULE_INSPECTION_OPTIONS};

    //options object entries iteration
    Object.entries(options).forEach(([key, value])=> {
      if(currentValues.includes(value.value)) {
        delete options[key];
      }
    });
    return Utils.getOptions(options);
  };

  const deleteRow = (fieldToDelete) => {
    const rows = [...rowsToDelete];
    rows.push(fieldToDelete);
    setRowsToDelete(rows);
  };

  const noData = () => {
    return !ways || ways.length === 0;
  };

  return (
    <Box>
      <TableFormWrapper gridTemplateColumns={gridTemplateColumns} columns={columns} gap={gap}>
        <>
          {!props.editable &&
            noData() && <NoDataStringWrapper  text={'Nothing added yet'} />
          }
          <FormProvider {...formMethods}>
            <form>
              {fields.map((field, index) => {
                const optionValue = getValue(CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name, index);
                return (<ListItem key={field.id+index} sx={{
                  display: 'grid',
                  gridTemplateColumns: gridTemplateColumns,
                  gap: gap,
                  alignItems: 'flex-start',
                  '& .MuiInputBase-inputMultiline': {
                    width: '100% !important',
                  }
                }}>
                  <CustomFormBlock {...commonFieldProps(index)}
                    field={CITY_FIELDS.SCHEDULE_INSPECTION_VIA}
                    inputType={inputTypes.singleSelect}
                    options={getOptions()}
                    defaultValue={optionValue}
                    viewMode={!props.editable}

                  />
                  <CustomFormBlock {...commonFieldProps(index)}
                    field={CITY_FIELDS.NOTE}
                    viewMode={!props.editable}
                  />
                  {props.editable && getDeleteRowButton(index, ()=>deleteRow(optionValue))}
                </ListItem>
                );})}
              {currentValues.length < 4 && props.editable && AddRowButton}
            </form>
          </FormProvider>
        </>
      </TableFormWrapper>
    </Box>
  );
};

HowToScheduleInspectionFrom.propTypes = {
  editable: PropTypes.bool,
  saveSubform: PropTypes.func,
};

HowToScheduleInspectionFrom.defaultProps = {
  editable: false,
  saveSubform: ()=>{},
};

import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../baseUrls';
//import * as allConstants from '../../constants/allConstants';
import {TAG_FIELDS} from '../../constants/formFields/tagFields';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {TAG_ELEMENTS, tagsPageRules} from '../../permissions/tagAccessControl';
import Utils from '../../utils/utils';

const AllTagsGrid = (props) => {
  const viewController = props.viewController;
  const [components] = useState({
    'statusRenderer': StatusRenderer,
  });

  const tagColumns = {
    id: new ColumnDefinition({field: TAG_FIELDS.ID, hide: true}),
    tagName: new ColumnDefinition({field: TAG_FIELDS.TAG_NAME,
      lockPinned: true,
      pinned: 'left',
      checkboxSelection: true,
      width: 350,
      wrapText: true
    }),
    createdBy: new ColumnDefinition({
      field: TAG_FIELDS.CREATED_BY,
    }),
    createdAt: new ColumnDefinition({
      field: TAG_FIELDS.CREATED_AT,
      valueGetter: (({data}) => {
        
        if (data) {
          return Utils.formatIsoDateString(data?.[TAG_FIELDS.CREATED_AT.api_name], {date: true});
        }
        return null;
      })
    })

  }

  const getColumns = () => {
    return [
      tagColumns.id,
      tagColumns.tagName,
      tagColumns.createdBy,
      tagColumns.createdAt,
    ];
  }

  let columnDefs = useMemo(()=> {
    return getColumns();
  }, []);


  const editTagHandler = (tagData)=> {
    props.editTagHandler(tagData);
  };

  const deleteTagHandler = (tagData)=> {
    props.deleteTagHandler(tagData);
  };

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data, user?.id);
    if(!params.node) return [];

    const standardActions = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const actions = {
      edit: {
        name: 'Edit',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          editTagHandler(params?.node?.data);
        },
      },
      delete: {
        name: 'Delete',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          deleteTagHandler(params?.node?.data);
        },
      },
    };

    let allowedActions = [];
    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: tagsPageRules[TAG_ELEMENTS.editTag],
    })) {
      allowedActions.push(actions.edit);
      allowedActions.push(actions.delete);
    }

    allowedActions = [...allowedActions, ...standardActions];

    return allowedActions;
  };

  const ls_name = 'tags_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      fetchParams={{url: `${base_url.api}tags/`, respKey:'tags'}}
      //companyId={viewController.companyId}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

AllTagsGrid.propTypes = {
  editTagHandler: PropTypes.func.isRequired,
  deleteTagtHandler: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,

};
export default AllTagsGrid;

const POST_JOB_CARD = {
  SITE: {display: 'Post at site ($)', value: 'Site', showOrder: 0, def: false},
  OFFICE: {display: 'Post at contractor’s office ($$$) ', value: 'Contractor\'s Office', showOrder: 1, def: false},
  SHIP: {display: 'Regular mail', value: 'Ship', showOrder: 2, def: false},
};

const POST_JOB_CARD_MAP_VAL_TO_DISPLAY = (()=>{
  const obj = {};
  Object.values(POST_JOB_CARD).forEach((v)=> {
    obj[v.value] =v.display;
  });
  return obj;
})();

const PROPERTY_TYPES = {
  SINGLE_FAMILY: {display: 'Single Family', value: 'Single Family', showOrder: 0, def: true},
  MOBILE_HOME: {display: 'Mobile Home', value: 'Mobile Home', showOrder: 1, def: false},
  DUPLEX: {display: 'Duplex', value: 'Duplex', showOrder: 2, def: false},
  APARTMENT: {display: 'Apartment', value: 'Apartment', showOrder: 3, def: false},
  CONDO: {display: 'Condominium', value: 'Condominium', showOrder: 4, def: false},
  COMMERCIAL: {display: 'Commercial', value: 'Commercial', showOrder: 5, def: false},
};

export {PROPERTY_TYPES, POST_JOB_CARD, POST_JOB_CARD_MAP_VAL_TO_DISPLAY};

import React, {useState, useCallback, useEffect} from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {createStyles, makeStyles} from '@mui/styles';
import {Select, MenuItem, Chip, Box} from '@mui/material';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import {CustomInput} from './customInputComponent';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import PropTypes from 'prop-types';
import CustomSelect from './select';
import Utils from '../../utils/utils';
const _ = require('lodash');

const useStyles = makeStyles((theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: 2,
      backgroundColor: STYLE_CONSTANTS.COLORS.lightGray,
      height: 24,
      borderRadius: 4,
      '& .MuiChip-deleteIcon': {
        fontSize: 14,
      }
    },
    noLabel: {
      marginTop: theme.spacing(3)
    }
  })
);


const MultipleSelect = (props) => {
  // console.log('MS', props);
  const classes = useStyles();
  const [selected, setSelected] = useState( props.defaultValue || []);
  const inputRef = React.useRef();

  useEffect(()=> {
    setSelected(props.defaultValue || []);
  }, [props.viewMode]);

  const {setValueToForm, field_name} = props;

  const handleChange = useCallback((selectedValue) => {
    setSelected((previousState) => {
      const newState = _.cloneDeep(previousState);
      newState.push(selectedValue);
      setValueToForm(field_name, newState, {shouldDirty: true, shouldValidate: true});
      return newState;
    });
  }, []);

  const fieldIsDisabled = props.viewMode || props.disabled;

  const handleDelete = (e, value) => {
    e.preventDefault();
    if(fieldIsDisabled) return;
    setValueToForm(field_name, _.without(selected, value), {shouldDirty: true});
    setSelected((current) => _.without(current, value));
  };

  const optionIsSelected = (option) => {
    const value = option.value;
    const name = option.name;
    return selected.includes(value) || selected.includes(name) ||
      Utils.arrayIncludesIgnoreCase(selected, name) ||
      Utils.arrayIncludesIgnoreCase(selected, value);
  };

  const options = [];
  const filteredOptions = props.options.filter((opt)=>!optionIsSelected(opt));
  filteredOptions.forEach((e, idx)=> {
    options.push(<MenuItem value={e.value} key={`${e.value}${idx}`} onClick={()=>handleChange(e.value)}>{e.name}</MenuItem>);
  });



  return (
    <Box sx={{
      '& .MuiSelect-multiple': {
        width: props.width || '300px',
      },
    }}>


      <Select
        labelId={`${props.field_name}-select-label`}
        id={`${props.field_name}-select`}
        multiple
        value={selected}
        onChange={(e)=> {e.preventDefault(); e.stopPropagation();}}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          sx: {maxHeight: '300px'},
          variant: 'menu',
          // getContentAnchorEl: () => null,

        }}
        // onOpen={(e) => console.log('select opened', e)}
        input={<CustomInput id={`${props.field_name}-input` }
          name={props.field_name}  ref={inputRef}
          error={props.hasError}
          disabled={fieldIsDisabled}
          fullWidth={props.fullWidth}/>}
        renderValue={() => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={props.getDisplayValue ? props.getDisplayValue(value) : value}
                clickable = {!fieldIsDisabled}
                deleteIcon={
                  fieldIsDisabled ? <></> : <ClearSharpIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                className={classes.chip}
                onDelete={(e) => handleDelete(e, value)}
                // onClick={(e) => console.log('clicked chip', e.target)}
                onMouseDown={e => {
                  e.stopPropagation();
                }}

              />
            ))}
          </div>
        )}
      >
        {[...options]}

      </Select>

    </Box>
  );
};
MultipleSelect.displayName = 'MultipleSelect';
MultipleSelect.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired,
    getDisplayValue: PropTypes.func,
  }
};
CustomSelect.defaultProps = {
  ...formBlockDefaultProps,
  ...{
    getDisplayValue: (value) => value
  }
};

export default MultipleSelect;

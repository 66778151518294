import {Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import CustomIcon from '../../static/icons/Icon';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';


const StyledLink = styled.span`
   display:flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   z-index:1;
   position:absolute;
   border-radius: 10px;
   background-color: ${STYLE_CONSTANTS.COLORS.white};
   height: 120px;
   filter: drop-shadow(6px 0px 4px #daddea);
   &.disabled {
      filter: none !important;
      background-color: ${STYLE_CONSTANTS.COLORS.lightGray};
       &:before, &:after{
        background-color: ${STYLE_CONSTANTS.COLORS.lightGray};
     }
      & p {
        color: ${STYLE_CONSTANTS.COLORS.lightGrayHover};
       }
       & svg {
         stroke:${STYLE_CONSTANTS.COLORS.fontPale};
       }
     &:hover {
        cursor: initial;
        filter: none !important;
        background-color: initial;
         &:before, &:after {
          background-color: ${STYLE_CONSTANTS.COLORS.lightGray} !important;
       }
       & p {
        color: ${STYLE_CONSTANTS.COLORS.lightGrayHover} !important
       }
       & svg {
         stroke: ${STYLE_CONSTANTS.COLORS.fontPale} !important
       }
      
     }
   }
   &:hover {
     cursor: pointer;
      filter: drop-shadow(4px 0px 2px ${STYLE_CONSTANTS.COLORS.boxShadow});
      background-color: ${STYLE_CONSTANTS.COLORS.globalBlue};
     
       & p {
        color: ${STYLE_CONSTANTS.COLORS.white};
       }
       & svg {
         stroke: ${STYLE_CONSTANTS.COLORS.white};
       }
       &:before, &:after {
          background-color: ${STYLE_CONSTANTS.COLORS.globalBlue}
       }
  
   }
   & p {
     color: ${STYLE_CONSTANTS.COLORS.globalBlue};
     font-weight: 600;
   }
   
   &.small {
      width: 80px;
   }
   
    &.large {
      width: 90px;
   }
   
   
   
   &:before, &:after {
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:100%;
      bottom:0;
      z-index:-1;
      border-radius:10px;
      background-color: #FFFFFF;
      &:hover {
 
      background-color: ${STYLE_CONSTANTS.COLORS.globalBlue}
  
   }
     
   }
   
   &:before {
   
      transform:skew(-10deg);
      left:10px;
   }

   &:after {
      transform:skew(10deg);
      right:10px;
      left:auto;
   }
   svg {
    margin-bottom: 8px;
   }



   // 5-Button Layout

   &.tr-btn-1-of-5 {
      transform: rotate( -50deg ) translate(14.5em) rotate( 70deg);
      & p {
         transform: rotate(-20deg) translate(6px);
      }
      & svg {
         transform: rotate(-20deg) translate(-6px);
      }
   }

   &.tr-btn-2-of-5 {
      transform: rotate( -20deg ) translate(16em) rotate( 75deg);
      & p {
         transform: rotate(-55deg) translateX(23px) translateY(4px);
      }
      & svg {
         transform: rotate(-55deg) translateX(-13px) translateY(-6px);
      }
   }
   
   &.tr-btn-3-of-5 {
      transform: rotate( 8deg ) translate(18em) rotate( 80deg);
      & p {
         transform: rotate(-86deg) translateX(23px) translateY(20px);
      }
      & svg {
         transform: rotate(-86deg) translateX(-15px) translateY(-7px);
      }
   }
   
   &.tr-btn-4-of-5 {
      transform: rotate( 34deg ) translate(19em) rotate( 86deg);
      & p {
         transform: rotate(240deg) translateX(36%) translateY(25px);
      }
      & svg{
         transform: rotate(240deg) translateY(-74%) translateX(-50%)
      }
   }
   
   &.tr-btn-5-of-5 {
      flex-direction: column-reverse;
      transform: rotate( 59deg ) translate(19em) rotate(96deg);
      & p {
         transform: rotate(-155deg) translate(-0px);
      }
      & svg {
         transform: rotate(-155deg) translateY(-14px);
      }
   }



   // 4-Button Layout

   &.tr-btn-1-of-4 {
      transform: rotate( -34deg ) translate(16em) rotate( 72deg);
      & p {
         transform: rotate(320deg) translate(14px);
      }
      & svg {
         transform: rotate(321deg) translate(-6px);
      }
   }

   &.tr-btn-2-of-4 {
      transform: rotate( -3deg ) translate(18em) rotate( 80deg);
      & p {
         transform: rotate(283deg) translateX(23px) translateY(15px);
      }
      & svg {
         transform: rotate(282deg) translateX(-13px) translateY(-6px);
      }
   }
   
   &.tr-btn-3-of-4 {
      transform: rotate( 25deg ) translate(19em) rotate( 85deg);
      & p {
         transform: rotate(250deg) translateX(18px) translateY(27px);
      }
      & svg {
         transform: rotate(250deg) translateX(-12px) translateY(-14px);
      }
   }
   
   &.tr-btn-4-of-4 {
      
      transform: rotate( 52deg ) translate(19.5em) rotate( 91deg);
      & p {
         transform: rotate(215deg) translateX(20%) translateY(31px);
      }
      & svg{
         transform: rotate(216deg) translateY(-74%) translateX(-24%);
      }
   }




   // 3-Button Layout
   &.tr-btn-1-of-3 {
      transform: rotate( -20deg ) translate(16em) rotate( 75deg);
      & p {
         transform: rotate(-55deg) translateX(23px) translateY(4px);
      }
      & svg {
         transform: rotate(-55deg) translateX(-13px) translateY(-6px);
      }
   }
   
   &.tr-btn-2-of-3 {
      transform: rotate( 8deg ) translate(18em) rotate( 80deg);
      & p {
         transform: rotate(-86deg) translateX(23px) translateY(20px);
      }
      & svg {
         transform: rotate(-86deg) translateX(-15px) translateY(-7px);
      }
   }
   
   &.tr-btn-3-of-3 {
      flex-direction: column-reverse;
      transform: rotate( 34deg ) translate(19em) rotate( 86deg);
      & p {
         transform: rotate(240deg) translateX(-20%) translateY(10px)
      }
      & svg{
         transform: rotate(240deg) translateY(-10%) translateX(35%)
      }
   }



   // 2-Button Layout

   &.tr-btn-1-of-2 {
      transform: rotate( -3deg ) translate(18em) rotate( 80deg);
      & p {
         transform: rotate(283deg) translateX(23px) translateY(15px);
      }
      & svg {
         transform: rotate(282deg) translateX(-13px) translateY(-6px);
      }
   }
   
   &.tr-btn-2-of-2 {
      transform: rotate( 25deg ) translate(19em) rotate( 85deg);
      & p {
         transform: rotate(250deg) translateX(18px) translateY(27px);
      }
      & svg {
         transform: rotate(250deg) translateX(-12px) translateY(-14px);
      }
   }



   // 1-Button Layout

   &.tr-btn-1-of-1 {
      transform: rotate( 8deg ) translate(18em) rotate( 80deg);
      & p {
         transform: rotate(-86deg) translateX(23px) translateY(20px);
      }
      & svg {
         transform: rotate(-86deg) translateX(-15px) translateY(-7px);
      }
   }

`;

const TrapezoidButton = (props)=> {
  const navigate = useNavigate();

  const redirect = (path)=> {
    navigate(path);
  };

  

  return <StyledLink className={clsx(`tr-btn-${props.currentButtonNumber}-of-${props.numberedButtonLayout}`, props.type, props.disabled ? 'disabled' : '')}
    onClick={()=> {if(!props.disabled) redirect(props.path);}}
  >
    <CustomIcon icon={props.icon} size={32} color={STYLE_CONSTANTS.COLORS.globalGreen} fill={'none'} />
    <Typography>{props.text}</Typography></StyledLink>;
};

TrapezoidButton.propTypes = {
  currentButtonNumber: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  numberedButtonLayout: PropTypes.number.isRequired,
};
TrapezoidButton.defaultProps = {
  disabled: false,
};
export {TrapezoidButton};


import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Grid} from '@mui/material';
import {useCreateJobStyles} from '../../createJobStyles';
import * as allConstants from '../../../../../constants/allConstants';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {useFormContext} from 'react-hook-form';


const MechOther = (props)=> {
  const classes = useCreateJobStyles();
  const control = useFormContext();
  const values = control.getValues();
  const blockParent = `${props.parent}`;
  return (
    <Box className={classes.tab_container}>
      <Grid container>
        <Grid item xs={12} display={'flex'} flexDirection={'column'}>
          <Typography variant={'body1'}>
            If you are installing a less common system that is not in the above options, please leave a detailed description of the type of system being installed:
          </Typography>

        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} sx = {{
          '& .MuiFormControl-root': {
            maxWidth: '700px'
          },
          '& .MuiInputBase-inputMultiline': {
            width: '100% !important',
          }
        }}>
          <CustomFormBlock
            {...props}
            renderLabel={false}
            multiline={true}
            field={allConstants.JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS}
            nested={true}
            parent={blockParent}
            defaultValue={values?.[`${blockParent}`]?.[allConstants.JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name]}
            fullWidth/>
        </Grid>



      </Grid>
    </Box>

  );
};

MechOther.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default MechOther;

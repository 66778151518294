import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';


const AnalyticsCardWrapper = (props)=> {
  const {fetchUrl} = props;
  const savedData = sessionStorage.getItem(props.sessionKey);
  let parsedData = null;
  if(savedData && savedData !== 'undefined') {
    try{
      parsedData = JSON.parse(savedData);
    } catch(e){
      console.log('Error on parsing', savedData);
      console.log(e.message);
    }
  }
  const data = useState(parsedData);


  const getAnalyticsData = ()=> {
    props.setParentFetching(true);

    fetch(`${props.fetchUrl}`)
      .then(response=> { return response.json();}).then(
        (result) => {
          props.returnFetchedData(result.queryResult);
          props.setParentFound(true);
          props.setParentFetching(false);
          sessionStorage.setItem(props.sessionKey, JSON.stringify(result.queryResult));
        },

        (error) => {
          console.log('error...', error);
          props.setParentFound(false);
          props.setParentFetching(false);
        }
      );
  };

  useEffect(()=> {
    if (!data[0]) {
      getAnalyticsData();
    } else {
      props.returnFetchedData(data[0]);
    }
  }, [fetchUrl]);
  return <>{props.children}</>;
};

AnalyticsCardWrapper.propTypes = {
  setParentFetching: PropTypes.func.isRequired,
  setParentFound: PropTypes.func.isRequired,
  returnFetchedData: PropTypes.func.isRequired,
  fetchUrl: PropTypes.string.isRequired,
  sessionKey: PropTypes.string.isRequired,
};

export {AnalyticsCardWrapper};

import React, {useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from '@mui/material';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import PropTypes from 'prop-types';
import CustomSelect from './select';
import * as STYLES_CONSTANTS from '../../styles/styleConstants';


const CustomCheckBox = (props)=> {

  const [checked, setChecked] = React.useState(typeof props.defaultValue === 'string' ? props.defaultValue.length > 0 : props.defaultValue);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //const setValueToForm = useMemo(()=> props.setValueToForm, [props.setValueToForm]);

  useEffect(()=> {
    props.setValueToForm(props.field_name, checked, {shouldDirty: true});
  }, [checked]);

  useEffect(()=> {
    setChecked(typeof props.defaultValue === 'string' ? props.defaultValue.length > 0 : props.defaultValue);
  }, [props.viewMode]);

  return (
    <FormControlLabel
      value={props.field.label}
      control={<Checkbox/>}
      checked={checked}
      disabled={props.viewMode || props.disabled}
      // inputProps={{'aria-label': 'controlled'}}
      label={props.field.label}
      sx={{
        flexDirection: props.labelLeft? (props.rowReverse ?  'row-reverse': 'row'): 'column-reverse',
        justifyContent: props.labelLeft? 'flex-end': 'initial',
        margin: 'auto',
        position: 'relative',
        textTransform: 'capitalize',
        left: props.labelLeft? '-9px': 'initial',
        '&.Mui-disabled': {
          '& .MuiTypography-root': {
            color: STYLES_CONSTANTS.COLORS.globalBlue,
          }
        },

        '& .MuiTypography-root': {
          display: props.hideLabel ? 'none': 'initial',
          color: STYLES_CONSTANTS.COLORS.globalBlue,
          width: props.labelWidth? `${props.labelWidth}px` : 'initial'
        },
      }}
      labelPlacement="top"
      onChange={handleChange}
    />
  );
};
CustomCheckBox.displayName = 'CustomCheckBox';
CustomCheckBox.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired,
    rowReverse: PropTypes.bool
  }
};
CustomSelect.defaultProps = {
  ...formBlockDefaultProps,
  ...{
    rowReverse: true
  }
};

export default CustomCheckBox;

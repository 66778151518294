import React, {useState, useCallback} from 'react';
import base_url, {urlSearchParams} from '../../../baseUrls';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../../pieces/navbar/navbar';
import UserModal from '../../../pieces/modals/userModal';
import * as allConstants from '../../../constants/allConstants';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {contractorUserPageRules, CONTRACTOR_ELEMENTS} from '../../../permissions/contractorUserPageAccessControl';
import AllUsersGrid from '../../users/allUsersGrid';
import myTheme from '../../../styles/myStyles';
import DisplayListsPageWrapper from '../../wrappers/displayListsPageWrapper';
import SetCompanyAdmin from '../../users/setCompanyAdmin';
import ListPageHeader from '../../../pieces/listPageHeader';
import usePagination from '../../../hooks/usePagination';
import {useGridViewState} from '../../../hooks/useGridViewState';
import AccessDenied from '../../misc/accessDenied';
import {globalUseClasses} from '../../../styles/globalClasses';
import {useSelector, useDispatch} from 'react-redux';
import actions from '../../../redux/actions';
//import {companyAndUserUtils} from '../../../utils/companyAndUserUtils';
import ChangePasswordUserForm from '../../users/changeUserPassword';
import PrevendAdminDeactivate from '../../users/preventAdminDeactivation';
import {unwrapRequestResult} from '../../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';
import Utils from '../../../utils/utils';

export const ServiceTitanNotEmp  = (props)=> {
  const viewController = useGridViewState();
  const dispatch = useDispatch();
  const classes = globalUseClasses();
  const pagination = usePagination();
  //const company = useSelector(state => state.company);
  const navigate = useNavigate();
  if(!NewPermissionGate({
    user: viewController.user,
    elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.viewPage]
  })) {
    return <AccessDenied/>;
  }

  // const user = useSelector(state => state.user);
  //const handleAddUserOpen = useCallback(() =>  navigate(`${allConstants.PATH.USER}${location.search}`), []);

  const handleAddUserOpen = useCallback(() =>  {
    
    const url = `${allConstants.PATH.USER}${location.search}`;

    console.log('url user setup =>');
    console.log(url);
    console.log('location');
    console.log(location);
    
    navigate(`${allConstants.PATH.USER}${location.search}`);

  }, [])
  
  const [showChangeUserPasswordPopup, setChangeUserPasswordPopup] = useState(false);
  const handleChangeUserPasswordClose = () => setChangeUserPasswordPopup(false);

  const [showSetAdminPopup, setShowSetAdminPopup] = useState(false);
  const handleSetAdminClose = ()=> setShowSetAdminPopup(false);

  const [showSetAdminDeactivateWarning, setShowSetAdminDeactivateWarning] = useState(false);
  const handleShowAdminDeactivateWarning = (open)=> setShowSetAdminDeactivateWarning(open);

  const [selectedUser, setSelectedUser] = useState(undefined);
  const {USER_FIELDS} = allConstants;


  const resetPasswordUserHandler = (data)=> {
    setSelectedUser(data);
    setChangeUserPasswordPopup(true);
  };

  const changeAdminHandler = (data)=> {
    setSelectedUser(data);
    setShowSetAdminPopup(true);
  };
  const changeAdminCancel = ()=> {
    setShowSetAdminPopup(false);
  };

  const deactivateUserHandler = async (data)=> {

    if(data[USER_FIELDS.ROLE.api_name] === allConstants.CONTRACTOR_ROLES.COMPANY_ADMIN) {
      setSelectedUser(data);
      handleShowAdminDeactivateWarning(true);
    } else {
      const deactivateStatus = await dispatch(actions.deactivateUser({userId: data?.[USER_FIELDS.ID.api_name]}));
      unwrapRequestResult({
        result: deactivateStatus,
        successMessage: `Deactivated: ${data?.[USER_FIELDS.FIRST_NAME.api_name]} ${data?.[USER_FIELDS.LAST_NAME.api_name]}.`,
        errorMessage: 'Error on deactivation',
        showSuccess: true,
      } );
      viewController.refreshOnSuccess(deactivateStatus);
    }

  };

  const editUser = async (permitUserId) => {

    console.log('inside service titan not emp eidt user method');

    const companyId = Utils.getUrlParameter('companyId') ?? '';
    
    let url = `${allConstants.PATH.USER}?companyId=${companyId}&userId=${permitUserId}`;
    url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanNotEmp`;
    navigate(url);

  }
  const headerComponent = <ListPageHeader header={'Not Service Titan Employees'}
    addHandler={handleAddUserOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {false} 
  >
    {pagination.ItemsController}
  </ListPageHeader>;

const getFetchParams = ()=> {
  const url = `${base_url.api}users/get-contractor-users-not-service-titan`;
  const respKey = 'users';
  return {url: url, respKey: respKey};
};

const fetchParams = getFetchParams();

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box>

            {selectedUser && showChangeUserPasswordPopup && <UserModal
              maxWidth={'600px'}
              open={showChangeUserPasswordPopup}
              preventScroll={false}
              handleClose={handleChangeUserPasswordClose}
              header={`${selectedUser[USER_FIELDS.EMAIL.api_name]}`}>
              <ChangePasswordUserForm selectedUser={selectedUser} closeModal={handleChangeUserPasswordClose}/>
            </UserModal>}
            {selectedUser && showSetAdminPopup && <UserModal
              open={showSetAdminPopup}
              handleClose={handleSetAdminClose}
              header={selectedUser?.[allConstants.USER_FIELDS.COMPANY_NAME.api_name]}

            >
              <SetCompanyAdmin selectedUser={selectedUser} closeModal={handleSetAdminClose}/>
            </UserModal>}
            {selectedUser && showSetAdminDeactivateWarning && <UserModal
              open={showSetAdminDeactivateWarning}
              handleClose={()=>handleShowAdminDeactivateWarning(false)}
              header={selectedUser?.[allConstants.USER_FIELDS.COMPANY_NAME.api_name]}

            >
              <PrevendAdminDeactivate selectedUser={selectedUser} closeModal={handleShowAdminDeactivateWarning}/>
            </UserModal>
            }

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllUsersGrid
                resetPasswordUserHandler={resetPasswordUserHandler}
                changeAdminHandler={changeAdminHandler}
                changeAdminCancel={changeAdminCancel}
                viewController={viewController}
                deactivateUserHandler={deactivateUserHandler}
                fetchParams={fetchParams} 
                overrideCompanyId={Utils.getUrlParameter('companyId') ?? ''}
                overrideEditUser={editUser}
                pagination={pagination}
                refreshTimestamp={viewController.refreshTimestamp}
                isTabOffset={props?.isTabOffset}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );

  // //console.log('Servic Titan Info');
  // //console.log(props);

  // const navigate = useNavigate();

  // const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  // const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  // const formMethods = useFormContext();

  // const getCompanyData = ()=> {
  //   const initialData = props.tabProps?.companyData || {};
  //   return {...initialData, ...formMethods.getValues()};
  // };
  
  // const companyData = getCompanyData();

  // const getDefaultValue = (field, parent = null) => {
  //   if (parent) {
  //     return companyData?.[parent]?.[field];
  //   }
  //   return companyData?.[field];
  // };

  // const commonFieldProps = {
  //   fullWidth: true,
  //   maxWidth: '100%',
  //   nested: true,
  //   parent: COMPANY_FIELDS.SERVICE_TITAN.api_name
  // };

  // const empParent = COMPANY_FIELDS.SERVICE_TITAN.api_name;
  // const empCollectionName = COMPANY_FIELDS.TENANT_EMP_LIST.api_name;

  // // const {fields} = useFieldArray({
  // //   control: formMethods.control,
  // //   name: `${empParent}.${empCollectionName}`
  // // });

  // // const empSubformDefaultProps = (index) => ({
  // //   defaultValue: '',
  // //   isEditable: false,
  // //   nested: true,
  // //   fullWidth: true,
  // //   parent: `${empParent}.${empCollectionName}[${index}]`
  // // });


  // const editUser = async (permitUserId) => {
  //   const companyId = Utils.getUrlParameter('companyId') ?? '';
    
  //   let url = `${allConstants.PATH.USER}?companyId=${companyId}&userId=${permitUserId}`;
  //   url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanEmp`;
  //   navigate(url);

  // }
  // const createUser = async (email, name) => {
  //   const list = name?.split(" ",2);
  //   const firstName = list.length > 0 ? list[0].trim() : "";
  //   const lastName = list.length > 1 ? list[1].trim() : "";
  //   const companyId = Utils.getUrlParameter('companyId') ?? '';

  //   //console.log('location=>');
  //   //console.log(location.search);

  //   let url = `${allConstants.PATH.USER}?companyId=${companyId}&email=${email}&firstName=${firstName}&lastName=${lastName}`;
  //   url = `${url}&view=${allConstants.PATH.COMPANY}&tabName=serviceTitanEmp`;
   
  //   //console.log('firstName => ' + firstName);
  //   //console.log('lastName => ' + lastName);
  //   //console.log('url => ' + url);

  //   navigate(url);
  // }

  // const getEmployeeContent = (rows)=> {

  //   if(!rows || rows.length === 0) {
  //     return <TableRow>
  //       <TableCell sx={{width: '100%'}} colSpan={6}>
  //         <NoDataStringWrapper text={`No Records`}/>
  //       </TableCell>
  //     </TableRow>;
  //   }

  //   return (
  //     <>
  //       {rows.map((row) => (       
  //         <TableRow key={row.email} sx={{'&:last-child td, &:last-child th': {border: 0}, '& .MuiTableCell-root': {paddingY: '4px'}}}>
  //           <TableCell component="th" scope="row" sx={{paddingLeft: '8px'}} >
  //             {row.name}              
  //           </TableCell>
  //           <TableCell component="th" scope="row" >
  //             {row.email}
  //           </TableCell>
  //           <TableCell coponent="th" scope="row">
  //             <Checkbox  checked={row.permitUser}/>
  //           </TableCell>
  //           <TableCell coponent="th" scope="row">
  //             <Checkbox  checked={row.isBlocked}/>
  //           </TableCell>
  //           <TableCell>
  //             {row.status}
  //           </TableCell>
  //           <TableCell>
  //             {row.permitUser ?
  //               <Button variant="contained" onClick={() => editUser(row.permitUserId)}>
  //                 EDIT
  //               </Button>
  //               :
  //               <Button variant="contained" onClick={() => createUser(row.email, row.name)}>
  //                 INVITE
  //               </Button>
  //             }
  //           </TableCell>
  //         </TableRow>
  //         )
  //       )}
  //     </>
  //   );
  // };
  // // Thanh 01/04/24
  // //console.log('employee data');
  // //console.log(companyData);

  // return (
  //   <>
  //     <Grid container width={'100%'} spacing={3}>
  //       <NotAllowedToEditSection show={props.tabProps.notAllowedToEdit}/>
  //       <Grid item sm={12} md={12} flex={1}>
  //         <Box mt={1}>
  //           <Typography className={classes.eq_name}>NOT SERVICE TITAN EMPLOYEES</Typography>
  //         </Box>
  //         <Table size="small" sx={{minWidth: 650}} aria-label="not employees table">
  //           <TableHead sx={{'& .MuiTableCell-root': { color: STYLE_CONSTANTS.COLORS.fontPale}}}>
  //             <TableRow>
  //               {/* <TableCell sx={{width: '30px',padding:0,}}/> */}
  //               {/* <TableCell sx={{paddingLeft: '24px'}}>File</TableCell> */}
  //               <TableCell>Name</TableCell>
  //               <TableCell>Email</TableCell>
  //               <TableCell>User?</TableCell>
  //               <TableCell>Blocked?</TableCell>
  //               <TableCell>Status</TableCell>
  //               <TableCell>Action</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {getEmployeeContent(companyData.serviceTitan?.notEmployees)} 
  //           </TableBody>
  //         </Table>
  //       </Grid>
  //     </Grid>
  //   </>
  // );
};

// ServiceTitanNotEmp.propTypes = companyPropTypes;
// ServiceTitanNotEmp.defaultProps = companyDefaultProps;

import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

const AnalyticsMenuBarStyles = makeStyles(theme => ({
  root: {
    justifyContent : 'left',
    textTransform: 'capitalize',
    paddingTop: '0',
    // backgroundColor: 'blue',
    // // Match [md, ∞)
    // //       [900px, ∞)
    // [theme.breakpoints.down('md')]: {
    //     backgroundColor: 'red',
    // },
  },
  drawer: {
    // paddingTop : "20px",
    width: '290px',
    border: 'none',
    flexShrink: 0,
    display: 'flex',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    textTransform: 'capitalize',
    //color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '0',
    '&:hover': {
      background: STYLE_CONSTANTS.COLORS.backgroundMain,
    },
    position: 'relative',
    height: '55px'             //height of nav_icon + paddingTop+paddingBottom
  },
  btnRoot : {
    paddingLeft : '25px',
    justifyContent : 'left !important'
  },
  subMenu : {
    paddingLeft : '50px !important',
  },

  nav_icon: {
    position: 'absolute',
    height: '35px',
  },
  nav_text: {
    position: 'absolute',
    bottom:'8px',
    left: '40px',
    lineHeight:'1',
    color: STYLE_CONSTANTS.COLORS.fontDarkBlue,
  },
  not_active: {
    color: STYLE_CONSTANTS.COLORS.fontPale,
  }
}));

export default AnalyticsMenuBarStyles;

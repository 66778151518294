import React from 'react';
import {withStyles} from '@mui/styles';
import {Grid, Table, TableCell, TableRow, TableContainer, TableBody} from '@mui/material';
import * as allConstants from '../../../../../constants/allConstants';
import {BlockHeader} from '../../../../../pieces/displayJob/blockHeader';
import {EquipmentBlock} from '../../../../../pieces/displayJob/equipmentBlock';
import * as STYLE_CONSTANTS from '../../../../../styles/styleConstants';
import PropTypes from 'prop-types';
import {electricalDisplayUtils} from './electricalDisplayUtils';
import {CommercialSection} from '../commercialSection';

const StyledTable = withStyles(() => ({
  root: {
    // width: 300,
  },

}))(Table);
const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: STYLE_CONSTANTS.COLORS.lightGray,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
  root: {
    '&:nth-of-type(odd)': {
      width: '190px',
      fontWeight: 'bold',
    },
  },
}))(TableCell);

const ElectricalDetails = (props)=> {
  const {details, equipment} = props;

  const displayEquipment = () => {
    const {content, scopes} = electricalDisplayUtils.getElectricalEquipment(equipment, details);
    if(!content || content.length === 0) return null;

    return(
      <>
        {/*{BlockHeader({header: 'equipment'})}*/}
        {EquipmentBlock({content: content,
          filter: new Set([allConstants.JOB_FIELDS.GENERAL.SCOPE.label])}
        )}
        {scopes.length !== 0 && (
          <>
            <BlockHeader header={'scope'}/>
            <TableContainer>
              <StyledTable aria-label="equipment scopes" size="small">
                <TableBody>
                  {scopes.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <TableCell>{row.value}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </>
        )}
      </>
    );
  };

  const equipmentBlock = displayEquipment();
  const commercial = <CommercialSection details={details}/>;

  return(
    <>
      <Grid container>
        {equipmentBlock &&
        <Grid container columns={{xs: 4, sm: 8, md: 12}}  mb={2}>
          {equipmentBlock}
        </Grid>
        }
      </Grid>
      <Grid container xs={12}>
        {commercial &&  commercial}
      </Grid>
    </>
  );
};
ElectricalDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
  equipment: PropTypes.instanceOf(Array),
};

ElectricalDetails.defaultProps = {
  equipment: [],
};
export {ElectricalDetails};

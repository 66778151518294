import * as allConstants from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';
import {FormLine} from '../../../../pieces/displayJob/formLine';
import React from 'react';
import {Divider, Grid} from '@mui/material';
import {BlockHeader} from '../../../../pieces/displayJob/blockHeader';

class JobDisplayUtils {
  getBlockComponentToDisplay = (block, longlabel=true)=> {
    const content = [];
    if(!block || !Array.isArray(block) || block.length === 0)return content;

    block.forEach((b, idx) => {
      for (const [key, value] of Object.entries(b)) {
        if (Utils.valueIsSpecified(value)) {
          content.push(<>
            <FormLine label={key} value={Utils.reformatValue(value)} longLabel={longlabel} key={key} />
            {idx < block.length - 1 && <Divider/>}
          </>);
        }
      }
    });
    return content;
  }
  getRowOutput = (label, value, print) => {
    if (Utils.valueIsSpecified(value)) {
      if(print) {
        return {label:label, value: value};
      }
      return {[label]: value};
    }
    return undefined;
  }
  getFormattedKey = (field, print) => {
    return print ? (field?.print_label || field.display_label) : field.label;
  }
  /**@
   *
   * @param equipment
   * @param fieldOrder
   * @param eq_count
   * @return {*[]}
   */
  getEquipment = ({equipment, fieldOrder, count, print}) => {
    let content = [];
    equipment.forEach((piece) => {
      const curr_fields = fieldOrder[piece.eq_name];
      if(!curr_fields) return content;
      let name = `${allConstants.EQ_API_TO_NAME_MAPPING[piece.eq_name]}`;
      if(count && Utils.objectHasProperty(count, piece.eq_name)){
        name = `${name}#${++count[piece.eq_name]}`;
      }
      const fields = {
        name: name,
        fields: curr_fields.filter(f => Utils.valueIsSpecified(piece?.[f.api_name])).map((f) => {
          const label = print? (f?.print_label || f.display_label) :  f.display_label;
          return {label: label, value: piece?.[f.api_name]};
        })
      };
      if(fields.fields && fields.fields.length!==0) content.push(fields);
    });

    return content;
  };
  getDetails = ({details, fields, print})=> {
    let detailRows;
    if(!details || !fields) return null;
    detailRows = fields.filter(f => Utils.valueIsSpecified(details[f.api_name])).map((f)=> {
      return {label: print ? f.print_label || f.display_label : f.display_label, value: details[f.api_name]};
    });
    return detailRows;
  };
  /**
   *
   * @param details
   * @param fixtures
   * @param chunks
   * @return {{values: *[], labels: *[]}|null}
   */
  getDisplayFixtures = (details, fixtures, print, chunks=4) => {
    // const fixtures = allConstants.JOB_FIELDS.PLUMBING.FIXTURES;
    if(!details || !fixtures) return null;
    const labels = [];
    const values = [];
    const normalizedFixtures = Utils.normalizeObjectKeys(fixtures);
    const normalizedDetails = Utils.normalizeObjectKeys(details);
    Object.values(normalizedFixtures).forEach((field) => {
      if (Utils.valueIsSpecified(normalizedDetails?.[field.api_name])) {
        labels.push(field.display_label);
        values.push(normalizedDetails?.[field.api_name]);
      }
    });
    if (labels.length === 0) return null;
    if(print) return labels.map((l, idx)=> {return{name: l, count: values[idx]};});
    const l_chunks = Utils.chunkify(labels, chunks);
    const v_chunks = Utils.chunkify(values, chunks);
    const last_idx = l_chunks.length - 1;
    const last_l_chunk = l_chunks[last_idx];
    const last_v_chunk = v_chunks[last_idx];
    while (last_l_chunk.length !== 4) {
      last_l_chunk.push('');
      last_v_chunk.push('');
    }
    return {labels: l_chunks, values: v_chunks};
  }
  /**
   *
   * @param contentBlock
   * @param gridClass
   * @param header
   * @return {JSX.Element|null}
   */
  getBlockContent = (contentBlock, gridClass, header='')=> {
    const content = jobDisplayUtils.getBlockComponentToDisplay(contentBlock);
    if(content.length === 0) return null;
    return (
      <Grid container className={gridClass}>
        {BlockHeader({header: header})}
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    );
  }
}

const jobDisplayUtils = new JobDisplayUtils();
export {jobDisplayUtils};

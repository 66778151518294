// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import * as allConstants from '../constants/allConstants';
import axios from 'axios';
import ApiUtils from '../utils/apiUtils';

export const useGetFilterList = (props) => {
 // const field = props.field;
  const [options, setOptions] = useState(null);

  const fetchOptions = async () => {
    try{


      //console.log('inside fetch options');

      const authToken =  await ApiUtils.getAccessToken();

      //console.log('after authtoken');

      const results = await axios.get(props.url, {params: {}, headers: {Authorization: authToken}});

      //console.log('get tags from server');
      //console.log(results.data);

      return results.data.data; 
 
    } catch (e) {
      console.error(e);
      return null;
    }

  };

  useEffect(async () => {
    const options = await fetchOptions();
    setOptions(options);
  }, []);

  return {
    options: options
  };

};

import {CONTRACTOR_ROLES, IPERMIT_ROLES} from '../constants/roles';

export const isImpersonating = (role, companyId)=> {
  if(!role) return false;
  return Object.values(IPERMIT_ROLES).includes(role) && !!companyId;
};

export const isIpermitUser = (role)=> {
  return Object.values(IPERMIT_ROLES).includes(role);
};

export  const isContractor = (role)=> {
  return Object.values(CONTRACTOR_ROLES).includes(role);
};

import React from 'react';
import {makeStyles} from '@mui/styles';
import {Typography, Box, Switch} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

const useStyles = (props)=>makeStyles((theme) => {
  const styles = {
    generalHeight: props.small ? '2.5rem' : '4rem',
    knobHeight: props.small ? '1.5rem' : '3rem',
  };
  return {
    label: {
      position: 'absolute',
      top: '50%',
      pointerEvents: 'none',
    },
    labelFalse: {
      left: '28%',
      transform: 'translate(-50%,-50%)',
    },
    labelTrue: {
      right: '25%',
      transform: 'translate(50%,-50%)',
    },
    root: {
      width: '100%',
      height: styles.generalHeight,
      padding: 2,
      margin: theme.spacing(1),
    },
    switchBase: {
      left: 0,
      top: '50%',
      transform: 'translate(8px,-50%)',
      width: '50%',
      padding: 0,
      border: 'none',
      borderRadius: '30px',
      '&$checked': {
        transform: 'translate(calc(100% - 8px), -50%)',
        // color: `${STYLE_CONSTANTS.COLORS.white} !important`,
        '& .selected' : {
          color: `${STYLE_CONSTANTS.COLORS.globalRed} !important`
        },
        '& + $track': {
          backgroundColor: STYLE_CONSTANTS.COLORS.white,
          opacity: 1
        }
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff'
      }
    },
    thumb: {
      width: '100%',
      height: styles.knobHeight,
      marginTop: '1px',
      borderRadius: '30px',
      boxShadow: '0px 9px 8px rgba(49, 82, 163, 0.24)',
      backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
      border: `1px solid ${STYLE_CONSTANTS.COLORS.globalBlue}`,
    },
    track: {
      borderRadius: 30,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: STYLE_CONSTANTS.COLORS.white,
      opacity: 1,
    },
    checked: {},
    focusVisible: {}
  };});

const TypeSwitch = (props) =>{
  const classes = useStyles({small: props.small})();

  const labelFalseClassNames = clsx(
    classes.label,
    classes.labelFalse,
  );

  const labelTrueClassNames = clsx(classes.label, classes.labelTrue);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Box
        sx={{
          width: props.width ? props.width : {
            xs: '80%',
            sm: '60%',
          },
          position: 'relative'
        }}
      >
        <Switch
          onChange={props.onToggle}
          defaultChecked={props.toggle}
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
          {...props}
        />

        <Typography className={labelFalseClassNames} sx={{
          color: `${props.toggle ? STYLE_CONSTANTS.COLORS.fontMain : STYLE_CONSTANTS.COLORS.white} !important`,
        }}>{props.falseLabel}</Typography>
        <Typography className={labelTrueClassNames} sx={{
          color: `${props.toggle ? STYLE_CONSTANTS.COLORS.white : STYLE_CONSTANTS.COLORS.fontMain} !important`,
        }}>{props.trueLabel}</Typography>
      </Box>
    </Box>
  );
};

TypeSwitch.propTypes = {
  trueLabel: PropTypes.string.isRequired,
  falseLabel: PropTypes.string.isRequired,
  toggle: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  width: PropTypes.any,
  small: PropTypes.bool,
};

TypeSwitch.defaultProps = {
  width: null,
  small: false,
};

export default TypeSwitch;

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import clsx from 'clsx';
import {useFormContext} from 'react-hook-form';

export const BuilderInfo = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.TEMPORARY_POWER;
  const subParent = `${FIELDS.BUILDER_INFO.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const control = useFormContext();
  const values = control.getValues()[allConstants.JOB_TYPES.TEMPORARY_POWER.api_name]?.[allConstants.JOB_FIELDS.TEMPORARY_POWER.BUILDER_INFO.api_name];

  const getFieldValue = (fieldName)=> {
    return values?.[fieldName];
  };
  return (
    <Grid container justifyContent={'center'} spacing={{md: 6, lg: 12}}>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props}
            field={allConstants.JOB_FIELDS.GENERAL.FIRST_NAME}
            nested={true}
            parent={blockParent}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.api_name)}
            renderLabel={true}

          />
          <CustomFormBlock
            {...props}
            field={allConstants.JOB_FIELDS.GENERAL.LAST_NAME}
            nested={true}
            parent={blockParent}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.LAST_NAME.api_name)}
            renderLabel={true}
          />
        </Box>
        <CustomFormBlock{...props}
          field={FIELDS.BUILDER_COMPANY}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.BUILDER_COMPANY.api_name)}
          renderLabel={true}
          fullWidth={true}
        />
        <CustomFormBlock{...props}
          field={allConstants.USER_FIELDS.EMAIL}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(allConstants.USER_FIELDS.EMAIL.api_name)}
          renderLabel={true}
          fullWidth={true}
        />
        <Box className={classes.two_col_container}>
          <CustomFormBlock{...props}
            field={allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER}
            nested={true}
            parent={blockParent}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER.api_name)}
            renderLabel={true}
            inputType={inputTypes.phone}
          />
          <CustomFormBlock{...props}
            field={allConstants.JOB_FIELDS.GENERAL.ALT_PHONE_NUMBER}
            nested={true}
            parent={blockParent}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.ALT_PHONE_NUMBER.api_name)}
            renderLabel={true}
            inputType={inputTypes.phone}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
        <CustomFormBlock{...props}
          field={allConstants.JOB_FIELDS.GENERAL.ADDRESS}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name)}
          renderLabel={false}
          inputType={inputTypes.address}
        />
      </Grid>
    </Grid>
  );
};
BuilderInfo.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default BuilderInfo;

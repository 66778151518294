import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import LOCAL_STORAGE_KEYS from '../../../utils/localStorageKeys';

export const signUpUser = createAsyncThunk(
  'users/signupUser',
  async ({password, inviteCode}, thunkAPI) => {
    try {
      const response = await fetch(
        `${base_url.api}users/sign-up`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password,
            inviteCode,
          }),
        }
      );


      let data = await response.json();
      if (response.status === 201) {
        //console.log('TOKEN PAIR::::', data.data.tokenPair);

        localStorage.setItem(LOCAL_STORAGE_KEYS.tokens, JSON.stringify(data.data.tokenPair));
        return {...data.data};
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const signUpReducer = {
  [signUpUser.fulfilled]: (state, {payload}) => {
    state.isFetching = false;
    state.isSuccess = true;
    state.id = payload.user.id;
    state.email =  payload.user.email;
    state.role =  payload.user.role;
    state.userName = {lastName:  payload.user.lastName, firstName:  payload.user.firstName};
    state.status =  payload.user.status;
    state.isAuthenticated = true;
    if(payload.user.companyId) {
      state.companyId = payload.user.companyId;
    }
    state.policies = payload.user.policies??[];

  },
  [signUpUser.pending]: (state) => {
    state.isFetching = true;
  },
  [signUpUser.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Box} from '@mui/material';
import {Text} from './text';
import Spacer from './spacer';

export const CustomTag = (props)=> {
  return(
    <Box sx={{
      height: '20px',
      position: 'relative',
      backgroundColor: props.color,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '5px',
      '&:before': {
        content: '""',
        position: 'absolute',
        right: '-10px',
        bottom: '0',
        width: '0',
        height: '0',
        borderLeft: `10px solid ${props.color}`,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent'},
    }}>

      <Text text={props.text} color={STYLE_CONSTANTS.COLORS.white} size={'small'}/>
      <Spacer x={0.5}/>
    </Box>
  );
};

CustomTag.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

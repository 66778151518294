import {Box, List, ListItem, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

export const TableFormWrapper = (props)=> {

  return(
    <Box sx={{
      '& .MuiList-root':  {
        paddingY: theme => theme.spacing(0.2),
      },
      '& .MuiListItem-root':  {
        paddingY: theme => theme.spacing(0.2),
        paddingX: '0px'
      },
      '& .MuiFormControl-root': {
        '& .MuiFormHelperText-root': {
          display: `${props.hideHelperText ? 'none !important' : 'initial'}`,
        }
      }
    }}>
      {props.showHeader && <List>
        <ListItem sx={{
          display: 'grid',
          gridTemplateColumns: props.gridTemplateColumns,
          gap: props.gap,
          alignItems: 'flex-start',
          textTransform: 'uppercase',
          '& .MuiTypography-root': {
            color: STYLE_CONSTANTS.COLORS.grayBase,
            fontSize: {xs: '12px', md: '12px', xl: '16px'},
            fontWeight: {xs: '600', md: '600', xl: '400'},
            '& span': {
              color: STYLE_CONSTANTS.COLORS.globalRed
            }
          },


        }}>
          {props.columns.map((col, index)=>{
            return  <Typography key ={col.name+index}>{col.name}<span>{col.required ? '*' :''}</span></Typography>;
          })}
        </ListItem>

      </List>}
      {props.children}
    </Box>

  );

};

TableFormWrapper.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    required:PropTypes.bool,
  })).isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
  gap: PropTypes.string,
  showHeader: PropTypes.bool,
  hideHelperText: PropTypes.bool,
};

TableFormWrapper.defaultProps = {
  gap: '16px',
  showHeader: true,
  hideHelperText: false,
};

const IPERMIT_DISPLAY_ROLES = {
  IPERMIT_USER: {display: 'IPERMIT USER', value: 'IPERMIT_USER', showOrder: 0, def: false},
  IPERMIT_ADMIN: {display: 'IPERMIT ADMIN', value: 'IPERMIT_ADMIN', showOrder: 1, def: false},
};

const CONTRACTOR_DISPLAY_ROLES = {
  COMPANY_ADMIN: {display: 'COMPANY ADMIN', value: 'COMPANY_ADMIN', showOrder: 0, def: false},
  CONTRACTOR: {display: 'CONTRACTOR', value: 'CONTRACTOR', showOrder: 1, def: false},
};
  
export {IPERMIT_DISPLAY_ROLES, CONTRACTOR_DISPLAY_ROLES};
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {FormControl, Box} from '@mui/material';
import {CustomInput, FloatingLabel} from './customInputComponent';
import {ShowPassword} from '../../pages/auth/pieces/showPasswordIcon';
import {USER_FIELDS} from '../../constants/allConstants';
import {globalUseClasses} from '../../styles/globalClasses';
import Utils from '../../utils/utils';
import myTheme, {useHelperTextStyles} from '../../styles/myStyles';
import CustomIcon from '../../static/icons/Icon';
import PropTypes from 'prop-types';
import {spacing} from '@mui/system';
import {FormHelperText} from '@mui/material';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';

const HelperBlock = (props)=> {
  let isError = true;
  if(!props.currValue || props.currValue.length === 0) {
    return null;
  }

  if(!Utils.objectHasProperty(props.formState.errors, 'password')) {
    isError = false;
  }
  else if(props.formState && Object.keys(props.formState.errors).length !== 0) {
    if(props.formState.errors.password) {

      const errorTypes = props.formState.errors.password.types;
      isError = Utils.objectHasProperty(errorTypes, props.validation);
    }
  }  else {
    isError = false;
  }
  const iconProps = {
    icon: `${isError ? 'cross_small' : 'checkmark'}`,
    size:10,
    color: `${isError ? myTheme.palette.error.main : myTheme.palette.success.main}`,
    fill:'none',
    style: {
      strokeWidth:`${isError ? 4 : 6}`,
      marginRight: '8px'
    }
  };

  return (
    <Box display={'flex'} alignItems={'center'} sx={{
      paddingLeft: '4px',
      position: 'relative',
      bottom: '2px',
      fontSize: {xs: '10px', md: '12px'},
      height: spacing(2)
    }}>
      <CustomIcon {...iconProps} />
      <span>{props.text}</span>
    </Box>
  );
};

HelperBlock.propTypes = {
  currValue: PropTypes.string,
  formState: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string,
  validation: PropTypes.string.isRequired,
};
HelperBlock.defaultProps = {
  currValue: undefined,
  text: '',
};

const PasswordBlock = (props)=> {
  const classes = {...globalUseClasses()};
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFocused, setFocused] = useState(false);
  const formController = useFormContext();
  const helperTextStyles = useHelperTextStyles();


  const CONFIRM_PASSWORD = USER_FIELDS.CONFIRM_PASSWORD;
  const commonProps = {
    placeholder:'',
    autoComplete: 'new-password',
  };
  const handleShowPassword = ()=> {
    setShowPassword(!showPassword);
  };

  return (
    <Box className={props.twoColumns ? classes.two_col_container : ''} mb={2} sx={{
      columnGap: '42px !important',
      '& .MuiInputBase-input': {
        textTransform: 'none !important',
      }

    }}>
      <FormControl variant="standard" fullWidth={true}
        sx={{
          position: 'relative',
        }}
      >
        <FloatingLabel apiName={props.field.api_name} required={props.field.required} label={props.field.label}/>
        <CustomInput id={props.field.api_name}
          {...commonProps}
          onFocus={()=> {setFocused(true);}}
          type={showPassword ? 'text' : 'password'}
          endAdornment={ <ShowPassword show={showPassword} handleShowPassword={handleShowPassword}/>}
          {...formController.register(props.field.api_name)}
          error={props.isError}

        />
        {passwordFocused && <>
          <HelperBlock text={'6-16 characters'} formState={formController.formState} validation={'min'} currValue={formController.watch('password')}/>

        </>}
      </FormControl>
      {props.confirmIsRequired &&
        <FormControl>
          <FloatingLabel apiName={CONFIRM_PASSWORD.api_name} required={true} label={CONFIRM_PASSWORD.label}/>
          <CustomInput id={CONFIRM_PASSWORD.api_name}
            {...commonProps}
            type={showPassword ? 'text' : 'password'}
            endAdornment={ <ShowPassword show={showPassword} handleShowPassword={handleShowPassword}/>}
            {...formController.register(CONFIRM_PASSWORD.api_name)}
            error={props.isError}
          />
          {props.isError && <FormHelperText classes={{
            root:helperTextStyles.root
          }}>{props.isError?.message}</FormHelperText>}
        </FormControl>
      }

    </Box>
  );
};

PasswordBlock.propTypes = {
  ...formBlockTypes,
  ...{
    confirmIsRequired: PropTypes.bool
  }
};
PasswordBlock.defaultProps = {
  ...formBlockDefaultProps,
  ...{confirmIsRequired: false}
};

export default PasswordBlock;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../baseUrls';
import * as allConstants from '../../constants/allConstants';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {DetailCellRenderer} from './cityExpandableSection';
import {MaximizableCellRenderer} from '../../pieces/grids/MaximizableCellRenderer';
import ApiUtils from '../../utils/apiUtils';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import Utils from '../../utils/utils';
import {CITY_ELEMENTS, cityRules} from '../../permissions/cityAccessControl';

const AllCitiesGrid = (props) => {
  const viewController = props.viewController;
  const [components] = useState({
    'statusRenderer': StatusRenderer,
    'MaximizableCellRenderer': MaximizableCellRenderer,
  });
  const cityOptions =  useAutocompleteOptions({field: 'cityOptions'}).options;

  const filterEmptyOption = (options) => {
    if(!options) return null;
    return options.filter((option) => option.value !== '');
  };


  if(!cityOptions) return null;


  const handleDisplayCityDetails = (params) => {
    props.viewCityHandler(params?.node?.data.id);
  };

  const userCanForceAddToCRM = false;
  //Todo: refactor after force add to CRM added on backend on backend.

  // const userCanForceAddToCRM = PermissionGate({
  //   modulePermissions: citiesPageRules.forceAddToCRM,
  //   scope: allConstants.SCOPES.canView,
  //   role: viewController.userRole});

  let columnDefs = [
    new ColumnDefinition({field: allConstants.CITY_FIELDS.ID, hide: true}),
    new ColumnDefinition({
      field: {api_name: 'zohoId', label: ''},
      headerName: 'Not synced to CRM',
      cellStyle: {color: 'red'},
      valueGetter: (({data}) => {
        const cityZohoId = data.zohoId;
        if(!cityZohoId) return 'City';
      }),
      hide: (()=>{
        if(ApiUtils.isDevelopment()) return false;
        return !userCanForceAddToCRM;
      })(),
    }),
    new ColumnDefinition({field: allConstants.CITY_FIELDS.NAME,
      cellRenderer: 'MaximizableCellRenderer',
      cellRendererParams: (params) => {
        return {
          handleExpand: handleDisplayCityDetails,
        };
      },
      lockPinned: true,
      pinned: 'left',
      checkboxSelection: true,
      width: 250,
      wrapText: true
    }),
    new ColumnDefinition({field: allConstants.CITY_FIELDS.HOURS,
    }),
    new ColumnDefinition({field: allConstants.CITY_FIELDS.CLOSURE_CODE,
      filter:'agSetColumnFilter',
      filterValues: cityOptions?.closureCodes ? filterEmptyOption(cityOptions.closureCodes).map((s)=> s.value) : []
    }),
    new ColumnDefinition({field: allConstants.CITY_FIELDS.COUNTY,
    }),
    new ColumnDefinition({field: allConstants.CITY_FIELDS.SET_BACKS,
      valueGetter: ({data})=> {
        const ft = data?.[allConstants.CITY_FIELDS.FT.api_name];
        const backs = data?.[allConstants.CITY_FIELDS.SET_BACKS.api_name];
        let str = '';
        if(ft) str += `${ft} ft, `;
        if(backs) str += `${backs}`;
        return str;
      },
      filter: 'agSetColumnFilter',
      filterValues: cityOptions?.setBacks ? filterEmptyOption(cityOptions.setBacks).map((s)=> s.value) : []
    }),
    new ColumnDefinition({
      field: allConstants.CITY_FIELDS.ON_ROOF,
      filter:'agSetColumnFilter',
      filterValues: cityOptions?.onRoof ? filterEmptyOption(cityOptions.onRoof).map((s)=> s.value) : []
    }),

    new ColumnDefinition({
      field: allConstants.CITY_FIELDS.CITY_TYPE,
      filter:'agSetColumnFilter',
      filterValues: cityOptions.types ? filterEmptyOption(cityOptions.types).map((s)=> s.value) : []
    })
  ];


  const deleteCityHandler = (cityData)=> {
    props.deleteCityHandler(cityData);
  };

  const forceAddToCRMHandler = async (cityData)=> {
    if(!cityData) return;

    const result = await ApiUtils.makeApiCall('POST', 'cities/force-sync', JSON.stringify({id: cityData.id}));
    Utils.handleToastMessage(result, 'City synced to CRM', 'Error syncing city to CRM', 'city-sync');
  };

  const getContextMenuItems = (params)=> {
    if(!params.node) return [];

    const standardActions = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const actions = {
      edit: {
        name: 'View/Edit',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          props.viewCityHandler(params?.node?.data?.id);
        },
      },
      delete: {
        name: 'Delete',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          deleteCityHandler(params?.node?.data);
        },
      },
      forceAddToCRM: {
        name: 'Force Sync to CRM',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          forceAddToCRMHandler(params?.node?.data);
        },
      }
    };

    let allowedActions = [];
    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: cityRules[CITY_ELEMENTS.editCity],
    })) {
      allowedActions.push(actions.edit);
    }

    allowedActions = [...allowedActions, ...standardActions];

    //Todo: refactor after deleting city policy added on backend.

    // if(PermissionGate({
    //   modulePermissions: citiesPageRules.deleteCity, scope: allConstants.SCOPES.canView,
    //   role: viewController.userRole})) {
    //   allowedActions.push('separator');
    //   allowedActions.push(actions.delete);
    // }

    if(userCanForceAddToCRM || ApiUtils.isDevelopment()){
      allowedActions.push(
        actions.forceAddToCRM
      );
    }

    return allowedActions;
  };

  const ls_name = 'cities_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      masterDetail={true}
      detailsCellRenderer={DetailCellRenderer}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      fetchParams={{url: `${base_url.api}cities/`, respKey:'cities'}}
      companyId={viewController.companyId}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

AllCitiesGrid.propTypes = {
  deleteCityHandler: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,
  viewCityHandler: PropTypes.func.isRequired,

};
export default AllCitiesGrid;

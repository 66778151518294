
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {FormProvider} from 'react-hook-form';
import * as Yup from 'yup';
import * as allConstants from '../../constants/allConstants';
import {useSubform} from '../../hooks/useSubform';
import {Box, List, ListItem} from '@mui/material';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {inputTypes} from '../../pieces/inputs/formInput';
import Utils from '../../utils/utils';
import {ERROR_MSG, JOB_TYPES} from '../../constants/allConstants';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import ApiUtils from '../../utils/apiUtils';
import {toast} from 'react-toastify';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Loader from '../misc/loader';


export const CityDocumentsForm = (props) => {

  const [submitting, setSubmitting] = useState(false);

  const collectionName = useMemo(()=> allConstants.CITY_FIELDS.DOCUMENTS.api_name, []);

  const validationSchema = useMemo(()=>Yup.object().shape({
    [collectionName]: Yup.array().of(Yup.object().shape({
      // file: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
      [allConstants.JOB_FIELDS.JOB_TYPE.api_name]: Yup.array().required(ERROR_MSG.REQUIRED_FIELD).min(1, ERROR_MSG.REQUIRED_FIELD).nullable(),
      [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: Yup.mixed().required('Please upload file or remove this row'),
      [allConstants.JOB_FIELDS.FILE.NAME.api_name]: Yup.string().required('Required'),
    }))
  }), []);

  const collectionRow = useMemo(()=>{return {
    file: {
      fileId: null,
      name: null,
    },
    [allConstants.JOB_FIELDS.JOB_TYPE.api_name]:null,
  };}, []);

  const currentValues = useMemo(()=>{return props.documents.map((item, index) => {
    return {
      id: item.id,
      [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: {id: item.fileId, name: item?.fileName ? item.fileName : item.fileId},
      fileName: item?.fileName ? item.fileName : item.fileId,
      [allConstants.JOB_FIELDS.FILE.NAME.api_name]: item.name,
      [allConstants.JOB_FIELDS.JOB_TYPE.api_name]: item.jobType,
    };
  });}, [props.documents]);


  const {commonFieldProps, fields, formMethods, getValue, getDeleteRowButton,
    AddRowButton, deletedRows} = useSubform({
    collectionName: collectionName,
    validationSchema: validationSchema,
    subformRow: collectionRow,
    currentValues: currentValues,
    addRowText: 'Add Document'
  });

  if(process.env.NODE_ENV === 'development'){
    window.cityDocs = formMethods;
  }

  const createJobTypeOptions = () => {
    const options = {
      all: allConstants.ALL_JOBS_TYPE,
    };
    Object.keys({...allConstants.JOB_TYPES}).forEach((key, index) => {
      options[key] = {
        ...allConstants.JOB_TYPES[key],
        showOrder: index + 1,
      };
    });

    return options;

  };

  const values = formMethods.getValues();

  const getFileValue = (fieldName, index)=> {
    const v = values?.[collectionName];
    if(!v || !Array.isArray(v) || !v[0]) return null;
    const blockValues = v?.[index];
    if(!blockValues) return null;
    if(blockValues?.file && typeof blockValues?.file === 'string') {
      return {
        id: blockValues.file,
        name:blockValues?.fileName || '',
      };
    }
    return blockValues.file;
  };

  const onSubmit = async (data) => {

    const toastOptions = {
      toastId: 'cityEditDocuments',
      autoClose: 1000,
    };
    if(!props.cityId) {
      toast.error('City ID is not provided', toastOptions);
      return;
    }

    setSubmitting(true);

    try{
      const filesArray = data[collectionName];
      // setFetching(true);

      const filesToSubmit = filesArray.map((f)=> {
        const fileObj = {
          fileUploadId: f?.file?.id || f?.file,
          name: f?.name,
          jobType: f?.jobType,
        };
        if(f.id){
          fileObj.id = f.id;
        }

        let fileName = f.fileName;

        fileObj.fileName = fileName;

        return fileObj;
      });
      const payload = {
        id: props.cityId,
        documents: filesToSubmit,
      };
      const filteredDeletedRows = deletedRows.filter((item)=> item.id);
      if(filteredDeletedRows.length > 0) {
        payload.deleteDocuments = filteredDeletedRows.map((item)=> item.id);
      }
      const response = await ApiUtils.makeApiCall('POST', 'cities/edit-documents', JSON.stringify(payload));

      if(response && response.status === 'success') {
        props.onSuccess(response.data.documents);
        if(response?.data?.city) {
          props.setCityData(response.data.city);
        }

        toast.success('City documents updated', toastOptions);
      } else {
        toast.error( `Error during editing  city documents: ${response?.message}`, toastOptions);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      console.error('Error during editing city documents' + JSON.stringify(e));
      toast.error(`Error during editing city documents: ${e?.message}`, toastOptions);
    }

  };

  const onCancel = () => {
    formMethods.reset();
    props.onCancel();

  };

  const gridTemplateColumns =  '1fr 1fr 2fr 50px';
  const gap = '16px';

  return(
    <>
      <Box sx={{
        position: 'absolute',
        top:0,
        left:0,
        right:0,
        bottom:0,
        display: submitting? 'initial' : 'none',
        zIndex: 100,
        padding: 0,
        backgroundColor: STYLE_CONSTANTS.COLORS.white
      }}>
        <Loader useOwnWrapper/>
      </Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
          <List>
            {fields.map((item, index) => {
              return <ListItem key={item.id + index} sx={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                gap: gap,
                alignItems: 'flex-start',
                '& .MuiInputBase-inputMultiline': {
                  width: '100% !important',
                },
                '& .MuiSelect-multiple': {
                  maxWidth: 'unset !important',
                  width: '100% !important',
                }
              }}>
                <CustomFormBlock {...commonFieldProps(index)}
                  viewMode={!props.editMode}
                  field={{...allConstants.JOB_FIELDS.FILE.NAME, ...{required: true}}}
                  defaultValue={getValue(allConstants.JOB_FIELDS.FILE.NAME.api_name, index)}
                />
                <CustomFormBlock {...commonFieldProps(index)}
                  viewMode={!props.editMode}
                  field={{...allConstants.JOB_FIELDS.FILE.UPLOAD, ...{required: true}}}
                  inputType={inputTypes.file}

                  defaultValue={getFileValue(allConstants.JOB_FIELDS.FILE.UPLOAD.api_name, index)}
                />
                <CustomFormBlock {...commonFieldProps(index)}
                  viewMode={!props.editMode}
                  inputType={inputTypes.multiSelect}
                  field={allConstants.JOB_FIELDS.JOB_TYPE}
                  options={Utils.getOptions(createJobTypeOptions(), (opt)=> opt.value !== JOB_TYPES.COMMERCIAL.value)}
                  defaultValue={getValue(allConstants.JOB_FIELDS.JOB_TYPE.api_name, index)}

                />
                {props.editMode && getDeleteRowButton(index)}
              </ListItem>;
            })}

          </List>
          {props.editMode &&  AddRowButton}
          {props.editMode && <FormSubmitButtons cancelAction={onCancel} submitText={'Submit'}/>}
        </form>
      </FormProvider>
    </>

  );

};

CityDocumentsForm.propTypes = {
  documents: PropTypes.instanceOf(Array),
  editMode: PropTypes.bool,
  cityId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  setCityData: PropTypes.func,
};

CityDocumentsForm.defaultProps = {
  documents: [],
  editMode: false,
  onSuccess: ()=>{},
  onCancel: ()=>{},
  setCityData: ()=>{},

};

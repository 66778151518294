import React, {useEffect, useMemo} from 'react';
import base_url from '../baseUrls';
import ApiUtils from './apiUtils';
import axios from 'axios';
import html2canvas from 'html2canvas';
import Utils from './utils';
import * as allConstants from '../constants/allConstants';

const MapController = ()=> {
  const textRef = useMemo(()=> React.createRef(),[]);
  const mapRef = useMemo(()=> React.createRef(),[]);

  const getDistString = (distance, markIndex) => {
    const SHARED = allConstants.JOB_FIELDS.SHARED;
    const MECH_FIELDS  = allConstants.JOB_FIELDS.MECHANICAL;
    const back = `Back: ${distance?.[MECH_FIELDS.BACK.api_name]?.[SHARED.FT.api_name] || '0'}'${distance?.[MECH_FIELDS.BACK.api_name]?.[SHARED.INCH.api_name] || '0'}"`;
    const front = `Front: ${distance?.[MECH_FIELDS.FRONT.api_name]?.[SHARED.FT.api_name] || '0'}'${distance?.[MECH_FIELDS.FRONT.api_name]?.[SHARED.INCH.api_name] || '0'}"`;
    const left = `Left: ${distance?.[MECH_FIELDS.LEFT.api_name]?.[SHARED.FT.api_name] || '0'}'${distance?.[MECH_FIELDS.LEFT.api_name]?.[SHARED.INCH.api_name] || '0'}"`;
    const right = `Right: ${distance?.[MECH_FIELDS.RIGHT.api_name]?.[SHARED.FT.api_name] || '0'}'${distance?.[MECH_FIELDS.RIGHT.api_name]?.[SHARED.INCH.api_name] || '0'}"`;
    return `#${markIndex}  ${front}, ${back}, ${left}, ${right}`;
  };
  
  useEffect(()=> {
    return ()=> {
      textRef.current = null;
      mapRef.current = null;
    };
  }, []);

  const displayText = () => {
    if(textRef?.current) {
      textRef.current.style.visibility = 'initial';
    }
    if(mapRef?.current) {
      textRef.current.style.visibility = 'initial';
    }
    return true;
  };
  const hideMapIcons = () => {
    const terrain_icons = document.getElementsByClassName('gm-style-mtc');
    const st_view = document.getElementsByClassName('gm-svpc');
    const zoom_icons = document.getElementsByClassName('gmnoprint');
    for (const icon of terrain_icons) {
      icon.style.display = 'none';
      icon.style.opacity = 0;
    }
    for (const icon of st_view) {
      icon.style.display = 'none';
    }
    for (const icon of zoom_icons) {
      icon.style.display = 'none';
    }
  };
  const preloadFile = async (fileData)=> {
    let url = `${base_url.api}files/upload`;
    const authToken = await ApiUtils.getAccessToken();
    const data = await axios.post(url, fileData, {
      headers: {
        Authorization: authToken,
      }});
    return data;
  };
  /**
   *
   * @param {function} setFetching
   * @param {boolean} submit
   * @return {Promise<*|null>}
   */
  const saveMapImage = async (setFetching,submit=true) => {
    console.log('mapRef', mapRef);
    if(!mapRef.current) {
      if(setFetching) {
        setFetching();
      }
      return null;
    }
    let imageDataStream = '';
    const html2canvasOptions = {
      useCORS: true,
      scale: 2,
    };
    displayText();
    hideMapIcons();
    console.log('Will prepare canvas', mapRef);
    const canvas = await html2canvas(mapRef.current, html2canvasOptions);
    if(setFetching) {
      setFetching();
    }
    let img = canvas.toDataURL('image/jpeg', 1.0);
    img = img.replace('data:image/jpeg;base64,', '');
    // set dataurl image
    imageDataStream = `data:image/jpeg;base64,${img}`;

    // convert dataurl to blob so it can be used by filereader
    const blob = Utils.dataURItoBlob(imageDataStream);
    if (!submit) {
      //saveData(blob, `map${Utils.formatIsoDateString(new Date())}.jpeg`);
    } else {
      const fileToUpload = new File([blob], `map${Utils.formatIsoDateString(new Date())}.jpeg`, {
        type: 'image/jpeg',
      });
      const fileData = new FormData();

      fileData.append('file', fileToUpload);
      const preloadResult = await preloadFile(fileData);
      const fileId = await preloadResult.data?.data?.id;
      return fileId;
    }
  };

  return {
    refs: {textRef, mapRef},
    saveMapImage: saveMapImage,
    getDistString: getDistString,
    preloadFile: preloadFile,
  };
};

export {MapController};

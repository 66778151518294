import React from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import JobFormAccordionTab from '../../../../pieces/accordion/jobFormAccordionTab';
import ScopeInfo from './scopeBlock';
import MaterialInfo from './materialBlock';
import WasteInfo from './wasteBlock';
import BuildingInfo from './buildingsBlock';


const Reroof = (props)=> {
  const classes = {...globalUseClasses()};
  const tabs = [
    {
      name: 'scope',
      header: 'Scope of work',
      content:ScopeInfo,
      alwaysOpen: true,
    },
    {
      name: 'roofing_material',
      header: 'Roofing Material',
      content: MaterialInfo,
    },
    {
      name: 'waste_removal',
      header: 'Waste Removal',
      content: WasteInfo,
    },
    {
      name: 'buildings',
      header: 'Buildings',
      content: BuildingInfo,
    },
  ];

  return (
    <>
      {tabs.map((tab, idx)=> {
        return(
          <JobFormAccordionTab classes={classes}
            key={idx}
            header={tab.header}
            id={`${tab.name}-header`}
            aria_controls={`${tab.name}-content`}
            alwaysOpen={tab.alwaysOpen}
          >{tab.content({...props, ...{nested: true, parent: allConstants.JOB_TYPES.REROOF.api_name}})}</JobFormAccordionTab>
        );
      })}
    </>
  );
};

export default Reroof;

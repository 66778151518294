import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Typography} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import * as ROOF_OPTIONS from '../../../../constants/options/roofOptions';
import clsx from 'clsx';
import Utils from '../../../../utils/utils';
import {useFormContext} from 'react-hook-form';

export const WasteInfo = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.REROOF;
  const subParent = `${FIELDS.WASTE_REMOVAL.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.REROOF.api_name]?.[FIELDS.WASTE_REMOVAL.api_name];

  const getFieldValue = (fieldName)=> {
    return values?.[fieldName];
  };

  const commonProps = {
    nested: true,
    parent: blockParent,
    fullWidth: true,
    renderLabel: true,
  };
  return (
    <>
      <Grid container  justifyContent={'center'} spacing={{md: 6, lg: 12}}>
        <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)}>
          <Typography variant={'body1'} fontWeight={'600'} fontSize={'16px'} mb={1}>Waste Information</Typography>
          <Box className={classes.two_col_container}>
            <CustomFormBlock
              {...props} {...commonProps}
              inputType={inputTypes.singleSelect}
              options={Utils.getOptions(ROOF_OPTIONS.WASTE_TYPE)}
              field={FIELDS.WASTE_TYPE}
              defaultValue={getFieldValue(FIELDS.WASTE_TYPE.api_name)}
            />
            <CustomFormBlock
              {...props} {...commonProps}
              inputType={inputTypes.number}
              field={FIELDS.WASTE_WEIGHT}
              defaultValue={getFieldValue(FIELDS.WASTE_WEIGHT.api_name)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={clsx(classes.general_col)} mt={1}/>
        <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)} sx={{paddingTop: '0 !important'}}>
          <Box sx={{height: '30px'}}>
            <Typography variant={'body1'} fontWeight={'600'} fontSize={'16px'} mb={1}>Recycling</Typography>
          </Box>

          <CustomFormBlock
            {...props} {...commonProps}
            field={FIELDS.RECYCLING_COMPANY}
            defaultValue={getFieldValue(FIELDS.RECYCLING_COMPANY.api_name)}
          />
          <Box className={classes.two_col_container}>

            <CustomFormBlock
              {...props} {...commonProps}
              inputType={inputTypes.phone}
              field={allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER}
              defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER.api_name)}
            />
            <CustomFormBlock
              {...props} {...commonProps}
              inputType={inputTypes.phone}
              field={allConstants.JOB_FIELDS.GENERAL.ALT_PHONE_NUMBER}
              defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.ALT_PHONE_NUMBER.api_name)}
            />
          </Box>
          <CustomFormBlock
            {...props} {...commonProps}
            field={allConstants.JOB_FIELDS.GENERAL.EMAIL}
            defaultValue={getFieldValue(allConstants.JOB_FIELDS.GENERAL.EMAIL.api_name)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={clsx(classes.general_col)} sx={{paddingTop: '0 !important'}}>
          <Box sx={{height: '30px'}}/>
          <CustomFormBlock
            {...props} {...commonProps}
            inputType={inputTypes.address}
            field={allConstants.JOB_FIELDS.GENERAL.ADDRESS}
            defaultValue={values?.[allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name]}
            renderLabel={false}
          />
        </Grid>
      </Grid>
    </>
  );
};
WasteInfo.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default WasteInfo;

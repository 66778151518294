import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import {initialServerState} from '../initialStates';
import ApiUtils from '../../../utils/apiUtils';

export const acceptCompanyRegistration = createAsyncThunk(
  'companies/accept-registration',
  async ( props, thunkAPI) => {
    try {
      //console.log('Edit company payload', props);
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }
      const response = await fetch(
        `${base_url.api}companies/accept-registration`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(props),
        }
      );
      let data = await response.json();
      //console.log('getCurrentCompanyData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const acceptCompanyRegistrationReducer = {
  [acceptCompanyRegistration.pending]: (state)=>{
    state.isFetching = true;
  },
  [acceptCompanyRegistration.fulfilled]: (state)=>{
    const newState = {...state, ...initialServerState};
    return newState;
  },
  [acceptCompanyRegistration.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

export const rejectCompanyRegistration = createAsyncThunk(
  'companies/reject-registration',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}companies/reject-registration`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(props),
        }
      );
      let data = await response.json();
      //console.log('getCurrentCompanyData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const rejectCompanyRegistrationReducer = {
  [rejectCompanyRegistration.pending]: (state)=>{
    state.isFetching = true;
  },
  [rejectCompanyRegistration.fulfilled]: (state)=>{
    const newState = {...state, ...initialServerState};
    return newState;
  },
  [rejectCompanyRegistration.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

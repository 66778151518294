import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {IconButton, Box} from '@mui/material';
import {Button} from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

const useDatePickerStyles = makeStyles({
  date_picker_box: {
    borderRadius: '10px',
    position: 'absolute',
    zIndex: 1000,
    top: '40px',
    right: '85%'
  },
  date_picker: {
    borderRadius: 10,
  }
});

export default function StaticDatePickerDemo(props) {
  const classes = useDatePickerStyles();
  const [value, setValue] = useState(new Date());
  const [open, setOpen] = useState(false);

  const openHandler = ()=> {
    if(open) {
      submitHandler();
    } else {
      setOpen(true);
    }
  };

  const cancelHandler = ()=> {
    setOpen(false);
  };

  const submitHandler = ()=> {
    props.handler(value);
    setOpen(false);
  };


  return (
    <>
      <Box position={'relative'}>
        <IconButton color="primary" sx={{paddingRight: 0}}
          aria-label="upload picture"
          onClick={openHandler}
          component="span">
          <CalendarTodayOutlinedIcon />
        </IconButton>
        {open && <Box className={classes.date_picker_box}>
          <LocalizationProvider dateAdapter={AdapterDateFns} >
            <StaticDatePicker InputProps={{classes: {root: classes.date_picker}}} classes={{root: classes.date_picker}}
              displayStaticWrapperAs="desktop"
              openTo={'day'}
              showDaysOutsideCurrentMonth
              value={value}
              orientation={'portrait'}
              onChange={(newValue) => {
                setValue(newValue);
              }}

              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Box position={'absolute'} bottom={20} left={0} right={0} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button onClick={cancelHandler}
              sx={{color: STYLE_CONSTANTS.COLORS.globalRed, cursor: 'pointer'}}>Cancel</Button>
            <Button onClick={submitHandler}
              sx={{color: STYLE_CONSTANTS.COLORS.globalBlue, cursor: 'pointer', fontWeight:600}}>Ok</Button>

          </Box>

        </Box>}
      </Box>
    </>

  );
}
StaticDatePickerDemo.propTypes = {
  handler: PropTypes.func.isRequired,
};

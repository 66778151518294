import {COMPANY_FIELDS} from '../../constants/formFields/companyFields';

export const initialServerState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage:'',
};
export const initialCompanyState = {
  id:null,
  [COMPANY_FIELDS.NAME.api_name]: null,
  [COMPANY_FIELDS.EMAIL.api_name]: null,
  [COMPANY_FIELDS.ADMIN_INFO.api_name]: {
    [COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name]: null,
    [COMPANY_FIELDS.ADMIN_NAME_LAST.api_name]: null
  },
  [COMPANY_FIELDS.ADDRESS.api_name]: {
    line1: '',
    line2: '',
    city: '',
    state:'',
    zipcode: ''

  },
};

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {useCreateJobStyles} from '../../createJobStyles';
import Utils from '../../../../../utils/utils';
import * as allConstants from '../../../../../constants/allConstants';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {MapEditor} from '../../../../../pieces/map/mapEditor';
import {DistanceEditor} from '../../../../../pieces/map/distanceEditor';

const MapPanel = (props)=> {

  const classes = {...useCreateJobStyles()};
  const control = useFormContext();
  const values = control.getValues();
  const MECH_FIELDS  = allConstants.JOB_FIELDS.MECHANICAL;
  const defaultValues = values?.[allConstants.JOB_TYPES.HVAC_RES.api_name]?.[MECH_FIELDS.MARKS.api_name];
  const blockParent = `${props.parent}.${allConstants.JOB_FIELDS.MECHANICAL.MARKS.api_name}`;
  const [marksCount, setMarksCount] = useState(defaultValues && defaultValues !==0 ? defaultValues.length : 1);
  const [distStrings, setDistString] = useState([]);

  const watchMapDistances = useWatch(`${props.parent}.${allConstants.JOB_FIELDS.MECHANICAL.MARKS.api_name}`);

  useEffect(()=>{
    let src = props[allConstants.JOB_FIELDS.JOB_TYPE.api_name] === allConstants.JOB_TYPES.HVAC_RES.value ? allConstants.JOB_TYPES.HVAC_RES.api_name : allConstants.JOB_TYPES.TESTING_ONLY.api_name;
    const dist = values[src]?.[MECH_FIELDS.MARKS.api_name];
    if(!dist) return null;
    const newArr = [];
    dist.forEach((mark, index)=> {
      newArr.push(props.mapController.getDistString(mark, index+1));
    });
    setDistString(newArr);
  }, [watchMapDistances, marksCount]);

  const {remove} = useFieldArray({
    control: control.control,
    name: `${props.parent}.${allConstants.JOB_FIELDS.MECHANICAL.MARKS.api_name}`
  });


  const toggleMarkCount = ()=> {
    if(marksCount === 1) {
      setMarksCount(2);
    } else {
      setMarksCount(1);
      remove(1);
    }
  };

  return (
    <Box className={classes.tab_container}>
      <Grid container flexDirection={'row-reverse'}>
        <Grid item xs={12} sm={12} md={5} lg={4}  display={'flex'}
          flexDirection={'column'} justifyContent={'left'} flexWrap={'wrap'}>
          <DistanceEditor marksCount={marksCount}
            blockParent={blockParent}
            toggleMarkCount={toggleMarkCount}
            defaultValues={defaultValues}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8}>

          <MapEditor mapRef={props.mapController.refs.mapRef}
            textRef={props.mapController.refs.textRef}
            distStrings={distStrings}
            marksCount={marksCount}
            address={Utils.createAddressString(values[allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS.api_name])}
            name={values[allConstants.JOB_FIELDS.GENERAL.HO_NAME.api_name]}/>

        </Grid>
      </Grid>
    </Box>
  );
};

MapPanel.propTypes = {
  parent: PropTypes.string.isRequired,
  mapController: PropTypes.shape({
    getDistString: PropTypes.func.isRequired,
    refs: PropTypes.instanceOf(Object).isRequired,

  })

};

export default MapPanel;

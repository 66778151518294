import {
  JOB_FIELDS,
  USER_FIELDS,
  COMPANY_FIELDS,
  JOB_TYPES,
  PROPERTY_TYPES, PERMIT_MODULE_FIELDS, IPERMIT_MODULES
} from '../../../constants/allConstants';
import {filterTypes} from '../../../hooks/useFilter';
import {isContractor, isImpersonating} from '../../../permissions/utils';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';

class JobFilters {
  constructor() {

    this.fields = [
      {
        category: 'General',
        fields: [
          this.createFilterOption(JOB_FIELDS.JOB_NUMBER, {type: filterTypes.number}),
          this.createFilterOption({...JOB_FIELDS.JOB_TYPE, ...{inputType: inputTypes.singleSelect, options: Utils.getOptions(JOB_TYPES, (opt)=> opt.value !== JOB_TYPES.COMMERCIAL.value)}}),
          this.createFilterOption({...JOB_FIELDS.GENERAL.PROPERTY_TYPE, ...{inputType: inputTypes.singleSelect,
            options: Utils.getOptions(PROPERTY_TYPES)}}),
          this.createFilterOption({...JOB_FIELDS.GENERAL.ADDRESS, ...{label: 'Jobsite Address'}})
        ],
      },
      {
        category: 'Home owner',
        fields:[
          this.createFilterOption({...JOB_FIELDS.GENERAL.HO_NAME, ...{api_name: `${JOB_FIELDS.GENERAL.HOMEOWNER.api_name}.firstName`}}),
          this.createFilterOption({...JOB_FIELDS.GENERAL.HO_PHONE,
            ...{api_name: `${JOB_FIELDS.GENERAL.HOMEOWNER.api_name}.${JOB_FIELDS.GENERAL.HO_PHONE.api_name}`}}),
          this.createFilterOption({...JOB_FIELDS.GENERAL.ALT_HO_PHONE,
            ...{api_name: `${JOB_FIELDS.GENERAL.HOMEOWNER.api_name}.${JOB_FIELDS.GENERAL.ALT_HO_PHONE.api_name}`}}),
          this.createFilterOption({...JOB_FIELDS.GENERAL.HO_EMAIL,
            ...{api_name: `${JOB_FIELDS.GENERAL.HOMEOWNER.api_name}.${JOB_FIELDS.GENERAL.HO_EMAIL.api_name}`, label: 'Homeowner\'s Email'}}),
        ]
      },

      {category: 'Permit info',
        fields: [
          this.createFilterOption({...PERMIT_MODULE_FIELDS.ASSIGNED_CITY, ...{label: 'Assigned City', api_name: `${PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name}.name`}}),
          this.createFilterOption({...PERMIT_MODULE_FIELDS.NUMBER, api_name: `${PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name}.${PERMIT_MODULE_FIELDS.NUMBER.api_name}`,
            label: 'Permit Number'
          }),
          this.createFilterOption({...PERMIT_MODULE_FIELDS.AMOUNT, api_name: `${PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name}.${PERMIT_MODULE_FIELDS.AMOUNT.api_name}`, label: 'Permit Amount'},
            {type: filterTypes.number}),
          this.createFilterOption({...PERMIT_MODULE_FIELDS.NUMBER, api_name: `${PERMIT_MODULE_FIELDS.LICENSE_INFO.api_name}.${PERMIT_MODULE_FIELDS.NUMBER.api_name}`, label: 'License Number'}),
          this.createFilterOption({...PERMIT_MODULE_FIELDS.AMOUNT, api_name: `${PERMIT_MODULE_FIELDS.LICENSE_INFO.api_name}.${PERMIT_MODULE_FIELDS.AMOUNT.api_name}`, label: 'License Amount'},
            {type: filterTypes.number}),
          this.createFilterOption({...PERMIT_MODULE_FIELDS.NUMBER, api_name: `${PERMIT_MODULE_FIELDS.MISC_INFO.api_name}.${PERMIT_MODULE_FIELDS.NUMBER.api_name}`, label: 'Misc Number'}),
          this.createFilterOption({...PERMIT_MODULE_FIELDS.AMOUNT, api_name: `${PERMIT_MODULE_FIELDS.MISC_INFO.api_name}.${PERMIT_MODULE_FIELDS.AMOUNT.api_name}`, label: 'Misc Amount'},
            {type: filterTypes.number}),
          this.createFilterOption(PERMIT_MODULE_FIELDS.CHECK_TOTAL, {type: filterTypes.number}),
        ]
      },
      {
        category: 'Contractor',
        fields: [
          this.createFilterOption({...JOB_FIELDS.ORDERED_BY, ...{api_name: 'orderedByUser.fullName'}}),
          this.createFilterOption({...USER_FIELDS.EMAIL,
            ...{api_name: 'orderedByUser.email', label: 'Contractor\'s Email'}}),
          this.createFilterOption({...COMPANY_FIELDS.NAME, label: 'Company Name', api_name: `company.${COMPANY_FIELDS.NAME.api_name}`},
            {hide: true}),
          this.createFilterOption({...COMPANY_FIELDS.STATE_LICENSE_NUMBER, api_name: 'company.stateLicenseNumber'},
            {hide: true})
        ]
      },
      {category: 'Other',
        fields: [
          this.createFilterOption(JOB_FIELDS.GENERAL.HERS_PO_NUMBER),
          this.createFilterOption(JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER)
        ]
      }
    ];

  }

  createFilterOption = (field, params={}) => {
    const {type, hide} = params;
    return {...field, ...{type: type || filterTypes.text, hideFromContractor: hide}};
  }

  getFilters = (user, params)=>{
    const role = user?.[USER_FIELDS.ROLE.api_name];
    if(isContractor(role) || isImpersonating(role, user?.[USER_FIELDS.COMPANY_ID.api_name])) {
      return this.getContractorFilters();
    }
    return this.getIpermitFilters(params?.module);
  }

  getContractorFilters = ()=> {
    const contractorFields = this.fields.map((category)=> {
      category.fields = [...category.fields].filter((field)=> !field.hideFromContractor);
      return category;
    });
    return contractorFields;

  }

  getIpermitFilters = (module)=> {
    if(!module) {
      return this.fields;
    }
    switch (module) {
    case IPERMIT_MODULES.INSPECTION: {
      const inspectionFields = this.fields.filter((category)=> {
        return category.category !== 'Permit info' && category.category !== 'Other';
      });
      return inspectionFields;
    }
    default: {
      return this.fields;
    }
    }

  }

}

export const jobFilters = new JobFilters();

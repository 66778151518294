import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import LadderIcon from '../../static/images/ladder.png';


// Thanh 02/04/24
const RequirementFilter = forwardRef (({ column, filterChangedCallback }, ref) => {
  const [checkedValues, setCheckedValues] = useState([]);
  const [needsLadder, setNeedsLadder] = useState(false);

  useEffect(() => {
    // Initialize checkedValues with all possible values
    const allValues = column.colDef.filterParams.values || [];
    setCheckedValues(allValues);
  }, [column]);

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return (needsLadder || checkedValues.length < column.colDef.filterParams.values.length);
    },

    getModel() {

      if (!needsLadder && checkedValues.length == column.colDef.filterParams.values.length) {
        return null;
      }
      if (needsLadder) {
        return {
          "cityInspection.requirement": { "values": checkedValues, "filterType": "set" },
          "cityInspection.needsLadder": { "filter": needsLadder , "filterType": "boolean", "type": "boolean" }
        };
      } else {
        return {
          "cityInspection.requirement": { "values": checkedValues, "filterType": "set" },
        };
      }
    },

    setModel(model) {
      if (model) {
        setCheckedValues(model["cityInspection.requirement"].values || []);
        setNeedsLadder(model["cityInspection.needsLadder"] || false);
        
      } else {
        setCheckedValues(column.colDef.filterParams.values || []);
        setNeedsLadder(false);
      }
    }
  }));

  useEffect(() => {

    filterChangedCallback();

  }, [checkedValues, needsLadder]);

  const onCheckboxChange = (event, value) => {
    if (event.target.checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    }
  };
  
  const onCheckboxLadderChange = (event) => {
    setNeedsLadder(event.target.checked);
  }
  // const returnToCaller = () => {

  //   const values = {"cityInspection.requirement":{"values": checkedValues,"filterType":"set"}};

  //   console.log('values to return');
  //   console.log(values);

  //   filterChangedCallback(values); 
  //   //props.closeRequirementFilter(); 
  // }

  const reset = () => {
    setCheckedValues(column.colDef.filterParams.values || []);
    setNeedsLadder(false);
    filterChangedCallback(null);
  }

  return (
    <>
    <br />
    <div>
      &nbsp;&nbsp;<input type="checkbox" checked={needsLadder} onChange={(e) => onCheckboxLadderChange(e)} />
       <img src={LadderIcon} alt={'need ladder'}/>
    </div>
    <br />
    <div>
      {column.colDef.filterParams.values.map((value) => (
        <div key={value}>
          &nbsp;&nbsp;<input type="checkbox" value={value} checked={checkedValues.includes(value)}
             onChange={(e) => onCheckboxChange(e, value)}
          />
          <label>&nbsp;{value}</label>
        </div>
      ))}
    </div>
    <br />
    <div>
      &nbsp;&nbsp;
      {/* <button onClick={() => returnToCaller()}>Filter</button>
      &nbsp;&nbsp;&nbsp;&nbsp; */}
      <button onClick={() => reset() }>Reset</button>
    </div>
    <br />
    </>
  );
});

export default RequirementFilter;

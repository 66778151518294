import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from '@mui/material/GlobalStyles';
import {SCROLLBAR_STYLES} from '../../styles/scrollBar';
import NavBar from '../../pieces/navbar/navbar';
import {Grid} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {drawerWidth} from './recordBodyWrapper';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';


export const RecordWrapper = (props)=> {
  const topHeight = props?.headerHeight || '100px';

  const mainHeight = props.hideNavbar ? `calc(100vh - ${topHeight})` : `calc(100vh - ${topHeight} - ${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false})})`;
  const query = CustomMediaQuery();
  return (
    <>
      <GlobalStyles
        styles={SCROLLBAR_STYLES}
      />
      {!props.hideNavbar && <NavBar/>}
      <Grid container xs={12} sm={12} md={12} lg={12}
        sx={{textTransform: 'uppercase',
          backgroundColor: '#fff',
          marginLeft: `${drawerWidth(query)}px`,
          width: `calc(100% - ${drawerWidth(query)}px)`
          // marginTop: props.hideNavbar ? 'initial' : STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false}),
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{
          height: topHeight,
          position: 'sticky',
          zIndex:999,
          backgroundColor: 'inherit',
          top: props.hideNavbar ? '0' : `${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false, number: true})}px`,
          left: props.hideNavbar ? '0': `${drawerWidth(query)}px`,

          maxWidth: props.hideNavbar ? '100%' : `calc(100% - ${drawerWidth(query)}px)`,
          padding: props.headerPadding ?? '16px 8px',
        }}>
          {props.header}
        </Grid>
        <Grid item xs={12} position={'relative'}
          sx={{
            height: mainHeight,
            width: `calc(100% - ${drawerWidth(query)}px)`

          }}
        >
          {props.children}
        </Grid>

      </Grid>
    </>
  );

};

RecordWrapper.propTypes = {
  hideNavbar: PropTypes.bool,
  headerHeight: PropTypes.string,
  header: PropTypes.node,
  headerPadding: PropTypes.string,
};

RecordWrapper.defaultProps = {
  hideNavbar: false,
  headerHeight: null,
  header: null,
  headerPadding: null,
};

import React from 'react';
import {PermissinsSettingsPanel} from '../../../pieces/userSettings/permissionSettings';
import ApiUtils from '../../../utils/apiUtils';
import * as allConstants from '../../../constants/allConstants';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';


export const IpermitPermissionsPanel = (props) => {
  const editUserPermissions = async (data)=> {
    props.setSubmitting(true);
    const updatedPermissions = await ApiUtils.makeApiCall('POST', 'ipermit/users/set-policies', JSON.stringify({
      userId: props.userData?.[allConstants.USER_FIELDS.ID.api_name],
      policies: data,
    }));
    if(updatedPermissions.status === 'success') {
      toast.success('User permissions successfully updated', {
        toastId: 'success_update_permissions'
      });
    } else {
      const errorMessage = updatedPermissions.message ?? 'Failed to update user permissions';
      toast.error(errorMessage, {
        toastId: 'error_update_permissions'
      });
    }
    const updatedPolicies = updatedPermissions?.data?.policies ?? [];
    if(updatedPolicies) {
      props.setUserData({
        ...props.userData,
        policies: updatedPolicies,
      });
    }
    props.setViewMode(true);
    props.setSubmitting(false);
  };

  const cancelAction = ()=> {
    props.setViewMode(true);
  };
  return (
    <PermissinsSettingsPanel viewMode={props.viewMode}
      userData={props.userData}
      submitAction={editUserPermissions}
      cancelAction={cancelAction}
    />
  );
};

IpermitPermissionsPanel.propTypes = {
  viewMode: PropTypes.bool,
  userData: PropTypes.instanceOf(Object),
  setViewMode: PropTypes.func,
  setUserData: PropTypes.func,
  setSubmitting: PropTypes.func,
};

IpermitPermissionsPanel.defaultProps = {
  viewMode: true,
  userData: {},
  setViewMode: ()=> {},
  setUserData: ()=> {},
  setSubmitting: ()=> {},

};


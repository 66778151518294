import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {INSPECTION_STATUSES} from '../constants/options/statuses';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

export const TransitionRow = (props)=> {
  const {transitions, currentInspectionType} = props;


  const isActive = (button)=> {
    if(props.currentStatus === INSPECTION_STATUSES.SCHEDULED.value && button.value === INSPECTION_STATUSES.SCHEDULED.value) {
      return button.display.toLowerCase().indexOf(currentInspectionType?.toLowerCase()) !== -1;
    }
    return button.value === props.currentStatus;
  };
  return(
    <Box display={'flex'}
      flexDirection={'row'}
      rowGap={2}
      flexWrap={'wrap'}
      sx={{
        columnGap: {xs: '8px', md: '16px', xl:'24px'}
      }}
    >
      {transitions.map((t, index)=> {
        return (
          <TransitionButton key={index+t.status.value}
            status={t.status}
            action={t.action}
            isActive={isActive(t.status)}
            hide={t.hide}
          />
        );
      })}
    </Box>
  );
};



export const TransitionButton = (props)=>{

  const backgroundColor = props.isActive ? STYLE_CONSTANTS.COLORS.activeTransitionButton : STYLE_CONSTANTS.COLORS.white;
  const borderColor = props.isActive ? STYLE_CONSTANTS.COLORS.activeTransitionButton : props.hide ? STYLE_CONSTANTS.COLORS.lightGrayHover : STYLE_CONSTANTS.COLORS.grayBase;
  const color = props.isActive ? STYLE_CONSTANTS.COLORS.white : props.hide ? STYLE_CONSTANTS.COLORS.lightGrayHover : STYLE_CONSTANTS.COLORS.fontMain;

  const {value, display} = props.status;

  const onClick = ()=> {
    if(!props.action) return;
    props.action(value);
  };

  return (
    <Button onClick={onClick}
      disabled={props.hide || props.isActive}
      sx={{
        backgroundColor: backgroundColor,
        border: `2px solid ${borderColor}`,
        color: color,
        minWidth: '80px',
        fontWeight:600,
        fontSize: {sm: '14px', xl:'16px'},
        paddingX: theme=>theme.spacing(2),
        borderRadius: '20px',
        '&.Mui-disabled': {
          color: color,
        }
      }}


    >
      {display}
    </Button>
  );
};

const TransitionPropTypes = {
  isActive: PropTypes.bool,
  action: PropTypes.func,
  status: PropTypes.shape({
    display: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,

  }).isRequired,
  hide: PropTypes.bool

};

TransitionRow.propTypes = {
  transitions: PropTypes.arrayOf(PropTypes.shape(TransitionPropTypes)),
  currentInspectionType: PropTypes.string.isRequired,
  currentStatus: PropTypes.string.isRequired,
};

TransitionButton.propTypes = TransitionPropTypes;

TransitionButton.defaultProps = {
  isActive: false,
  action: null,
  hide: false
};

import React from 'react';
import {makeStyles} from '@mui/styles';
import {FormProvider} from 'react-hook-form';
import {Box, Button, Icon, Tooltip, Typography} from '@mui/material';
import * as allConstants from '../../constants/allConstants';
import CustomFormBlock from '../inputs/formBlock';
import SendIcon from '@mui/icons-material/Send';
import ModalWrapper from '../modals/modalWrapper';
import FormSubmitButtons from '../formSubmitButtons';
import {inputTypes} from '../inputs/formInput';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import NoteAndNotify from '../../static/images/noteAndNotify.svg';
import Utils from '../../utils/utils';
import PropTypes from 'prop-types';
import {NewPermissionGate} from '../../permissions/permissionGate';

const useStyles = makeStyles((theme) => ({
  note_input: {
    '& .MuiInputBase-inputMultiline': {
      width: '100% !important',
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
      '& textarea': {
        margin: 0,
      }
    }
  },
}));

export const AddNoteForm = (props)=> {
  const classes = {...useStyles()};
  const [showNotify, setShowNotify] = React.useState(false);

  const onSubmit = (data) => {
    props.onSubmitAction(data);
  };

  const addAndNotify = () => {
    // const data = props.formMethods.getValues();
    // console.log(data);
    //props.onSubmitAction(data, true);
  };

  const getNoteField = ()=> {
    const field = {...allConstants.JOB_FIELDS.NOTE.TEXT, required: false};
    return props.ipermitViewNote ? {...field, label: `${props.ipermitViewNote} Note`.toUpperCase()} : field;
  };

  const labelStyles = ()=> {
    return props.ipermitViewNote ? {
      '& .MuiFormLabel-root': {
        transform: 'translate(10px, -8px)',
        backgroundColor: STYLE_CONSTANTS.COLORS.white,
      },
    } : {};
  };

  const getNoteTypes = ()=> {
    const types = allConstants.NOTE_TYPES;
    const allowedTypes = {};
    //iterate over object entries
    Object.entries(types).forEach(([key, value])=> {

      if(NewPermissionGate({
        user: props.user,
        elementPermissions: value.permission,
      })) {
        allowedTypes[key] = value;
      }
    });
    return Utils.getOptions(allowedTypes);
  };

  return (
    <>

      <FormProvider {...props.formMethods}>
        <form onSubmit={props.formMethods.handleSubmit(onSubmit)} method={'POST'}>
          {showNotify && <ModalWrapper modalName={'note-notify'}
            open={true}
            preventScroll={true}
            width={{sx: '98%', md: '60%'}}
            maxWidth={'600px'}
          >
            <Box display={'flex'} flexDirection={'column'} sx={{'& .MuiSelect-multiple': {width: '100% !important'}}}>
              <Typography variant={'h6'}>Notify users</Typography>
              <CustomFormBlock
                viewMode={false}
                isEditable={true}
                field={allConstants.JOB_FIELDS.NOTE.NOTIFY_EMAIL}
                renderLabel={false}
                fullWidth={true}
                inputType={inputTypes.multiSelectWithSearch}
              />
              <FormSubmitButtons cancelAction={addAndNotify}
                cancelText={'Add note'}
                submitAction={addAndNotify}
                submitText={'Add note and notify'}/>
            </Box>
          </ModalWrapper>}
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            sx={{
              paddingBottom: 0,
              '& .scrollable_tab_content': {
                height: '40vh'
              },
              '& .MuiInputBase-root .MuiInputBase-input:focus': {
                borderColor: props.color ? props.color : 'initial',
              },

            }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
              sx={{
                // width: 'calc(100% - 60px)',
                // paddingBottom: '24px',
                backgroundColor: '#fff',
                '& .MuiButton-root': {
                  backgroundColor: props.color ? props.color : 'initial',
                }
              }}
              className={classes.note_input}
            >
              <Box sx={{
                ...labelStyles(),
                width: '100%',
              }}>
                <CustomFormBlock
                  viewMode={false}
                  multiline
                  fullWidth
                  rowCount={1}
                  isEditable={true}
                  field={getNoteField()}
                  renderLabel={!!props?.ipermitViewNote}
                  hideHelper={true}
                  maxWidth={'100%'}
                  placeholder={'Enter your message here'}
                />
              </Box>
              {props.selectNoteType && <Box sx={{
                '& .MuiFormControl-root': {
                  top: '10px'
                }
              }}>

                <CustomFormBlock
                  viewMode={false}
                  renderLabel={true}
                  inputType={inputTypes.singleSelect}
                  options={getNoteTypes()}
                  defaultValue={props.defaultNoteType?.value}
                  fullWidth
                  isEditable={true}
                  field={allConstants.JOB_FIELDS.NOTE.TYPE}
                  maxWidth={'150px'}
                />
              </Box>}

              <Button
                variant='contained'
                disabled={props.submitDisabled}
                type={'submit'}
              >
                <SendIcon/>
              </Button>
              {props.submitWithNotify &&
              <Tooltip title="Add and Notify">
                <Button
                  variant='contained'
                  disabled={props.submitDisabled}
                  type={'button'}
                  onClick={()=> {setShowNotify(true);}}
                  sx={{
                    '& .MuiButton-startIcon': {
                      marginRight: 0,
                      marginLeft: 0,
                    },
                    display:'none'
                  }}
                  startIcon={
                    <Icon sx={{
                      width: '32px',
                      height: '24px',
                      transform: 'scale(1.2)',
                      color: 'inherit'
                    }}>
                      <img src={NoteAndNotify} alt={''}/>
                    </Icon>
                  }
                >

                </Button>
              </Tooltip>
              }
            </Box>

          </Box>
        </form>
      </FormProvider>
    </>

  );
};

AddNoteForm.propTypes = {
  onSubmitAction: PropTypes.func.isRequired,
  formMethods: PropTypes.instanceOf(Object).isRequired,
  submitDisabled: PropTypes.bool,
  submitWithNotify: PropTypes.bool,
  ipermitViewNote: PropTypes.string,
  selectNoteType: PropTypes.bool,
  defaultNoteType: PropTypes.instanceOf(Object),
  color: PropTypes.string,
  jobType: PropTypes.string.isRequired,
  user: PropTypes.shape({
    policies: PropTypes.instanceOf(Array),
  })


};

AddNoteForm.defaultProps = {
  submitDisabled: false,
  submitWithNotify: false,
  ipermitViewNote: '',
  selectNoteType: false,
  defaultNoteType: {},
  color: null,
  user: {
    policies: [],
  }

};

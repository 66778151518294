import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import {Grid} from '@mui/material';
import CustomizedSwitch from '../../../../../pieces/switch/switch';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {useFormContext} from 'react-hook-form';

const MechInsulation = (props)=> {
  const MECH_FIELDS = allConstants.JOB_FIELDS.MECHANICAL;
  const formController = useFormContext();
  const values = formController.getValues();
  // const draftHelper = useDraft({
  //   control: useFormContext(),
  //   parent: props.parent,
  //   target: MECH_FIELDS.INSULATION.api_name,
  //   draft: props.draftData
  // });

  // const values = draftHelper.getDraftValues();
  const blockParent = `${props.parent}.${MECH_FIELDS.INSULATION.api_name}`;

  const isSelected = (target)=> {
    const savedData = values?.[props.parent]?.[MECH_FIELDS.INSULATION.api_name]?.[target];
    if(!savedData) return false;
    const filteredValues = Object.values(savedData).filter((v)=> v!== null && v.length !== 0);
    return Object.keys(savedData).length !== 0 && filteredValues.length !== 0;
  };

  const [radiant, setRadiant] = useState(isSelected(MECH_FIELDS.RADIANT_BARRIER.api_name));
  const [blownin, setBlownin] = useState(isSelected(MECH_FIELDS.BLOWN_IN.api_name));

  const radiantAction = (val)=> {
    setRadiant(val);
    formController.setValue(`${[blockParent]}.${[MECH_FIELDS.RADIANT_BARRIER.api_name]}`, val, {shouldDirty: true});
  };

  // useEffect(()=> {
  //   if(draftHelper.isFirstLoad) return;
  //   setRadiant(isSelected(MECH_FIELDS.RADIANT_BARRIER.api_name));
  //   setBlownin(isSelected(MECH_FIELDS.BLOWN_IN.api_name));
  // }, [draftHelper.isFirstLoad]);


  const getCommonProps = (selected, field) => {
    const fParent = `${blockParent}[${selected}]`;
    return {
      ...props,...{
        nested: true,
        parent: fParent,
        fullWidth: true,
        required: field.required,
        defaultValue: values?.[props.parent]?.[MECH_FIELDS.INSULATION.api_name]?.[selected]?.[field.api_name]},
      field: field
    };
  };

  const blowninAction = (val)=> {
    setBlownin(val);
    formController.setValue(`${[blockParent]}.${[MECH_FIELDS.BLOWN_IN.api_name]}`, val, {shouldDirty: true});
  };
  const blowninFields = [MECH_FIELDS.VALUATION, {...allConstants.JOB_FIELDS.GENERAL.SQFT, required: true}, MECH_FIELDS.R_VALUE];
  const radiantFields = [MECH_FIELDS.VALUATION, {...allConstants.JOB_FIELDS.GENERAL.SQFT, required: true}, {...MECH_FIELDS.R_VALUE, required: false}];

  // if(draftHelper.isFirstLoad) return null;
  return (
    <Grid container columnSpacing={2} p={3}>
      <Grid item xs={12} sm={5} md={3}>
        <CustomizedSwitch label={MECH_FIELDS.RADIANT_BARRIER.label} style={{
          '& .MuiFormControlLabel-root': {
            marginLeft: '0'
          }
        }}
        action={radiantAction}
        defaultChecked={radiant}
        />
        {radiant && radiantFields.map((f, index)=>
          <CustomFormBlock key={index+f.api_name}
            {...getCommonProps(MECH_FIELDS.RADIANT_BARRIER.api_name, f)}
          />
        )}
      </Grid>
      <Grid item xs={0} sm={2} md={3} lg={1}>
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <CustomizedSwitch label={MECH_FIELDS.BLOWN_IN.label} style={{marginLeft: '11px'}}
          action={blowninAction}
          defaultChecked={blownin}
          //checked={values?.[blockParent]?.[FIELDS.REPIPE_DRAINS.api_name]}
        />
        {blownin && blowninFields.map((f, index)=>
          <CustomFormBlock key={index+f.api_name}
            {...getCommonProps(MECH_FIELDS.BLOWN_IN.api_name, f)}
          />
        )}

      </Grid>
    </Grid>
  );
};
MechInsulation.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default MechInsulation;

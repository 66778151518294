import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {urlSearchParams} from '../../../../../baseUrls';
import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {Box, ThemeProvider} from '@mui/material';
import {useGridViewState} from '../../../../../hooks/useGridViewState';
import NavBar from '../../../../../pieces/navbar/navbar';
import clsx from 'clsx';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import myTheme from '../../../../../styles/myStyles';
import AllCF1RView from './allCF1RView';
import AllPermitJobsView from './permitJobs';
import AllJobsView from '../../../../jobs/allJobs/allJobsView';
import InspectionScheduleView from '../../../../calendars/inspectionScheduleView';

export const PermitListViews = (props) => {

  //console.log('inside permit list view');

  const [subModule, setSubModule] = React.useState(Utils.getUrlParameter(urlSearchParams.submodule));
  const location= useLocation();
  const viewController = useGridViewState();
  const classes = {...globalUseClasses()};

  useEffect(() => {
    setSubModule(Utils.getUrlParameter(urlSearchParams.submodule));
  }, [location]);

  const getPage = () => {

    if(subModule === allConstants.IPERMIT_MODULES.CF1R) {

      return <AllCF1RView/>;
    }
    else if (subModule === allConstants.IPERMIT_MODULES.CONTRACTOR_JOBS) {
      return <AllJobsView inspectionViewOnly = {true}/>
    }
    else if (subModule === allConstants.IPERMIT_MODULES.SCHED_CALENDAR) {
      return <InspectionScheduleView />
    }
    else  {
      return <AllPermitJobsView/>;
    }
  };

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={clsx(classes.page)}>
            {getPage()}
          </Box>
        </ThemeProvider>



      </>)
  );


};

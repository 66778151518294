import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import LOCAL_STORAGE_KEYS from '../../../utils/localStorageKeys';
import Utils from '../../../utils/utils';

export const signInUser = createAsyncThunk('users/login',
  async ({email, password}, thunkAPI) => {
    try {
      const response = await fetch(
        `${base_url.api}users/login`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.tokens, JSON.stringify(data.data.tokenPair));
        return {...data.data};
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const signInReducer = {
  [signInUser.fulfilled]: (state, {payload}) => {
    // console.log('payload', payload);
    state.isFetching = false;
    state.isSuccess = true;
    state.id = payload.user.id;
    state.email =  payload.user.email;
    state.role =  payload.user.role;
    state.userName = {lastName:  payload.user.lastName, firstName:  payload.user.firstName};
    state.status =  payload.user.status;
    state.isAuthenticated = true;
    state.policies = payload.user.policies??[];

    if(Utils.objectHasProperty(payload, 'company')){
      state.companyId = payload.company.id;
    }
    // console.log('state', state)

  },
  [signInUser.pending]: (state) => {
    state.isFetching = true;
  },
  [signInUser.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

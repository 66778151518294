import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import * as allConstants from '../../constants/allConstants';
import {Grid, Typography} from '@mui/material';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import actions from '../../redux/actions';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Utils from '../../utils/utils';
import {USER_STATUSES} from '../../constants/allConstants';

export const canBeAdmin = (userStatus, userRole)=> {
  return Utils.equalsIgnoreCase(userStatus, USER_STATUSES.ACTIVE.value) &&
    !Utils.equalsIgnoreCase(userRole, allConstants.ROLES.COMPANY_ADMIN);
};

const SetCompanyAdmin= (props)=> {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const newAdminName = `${props.selectedUser.firstName} ${props.selectedUser.lastName}`;
  const onSubmit = async (data) => {
    const updateStatus = await dispatch(actions.updateAdmin({
      newAdminUserId: props.selectedUser[allConstants.USER_FIELDS.ID.api_name],
      [allConstants.USER_FIELDS.COMPANY_ID.api_name]: props.selectedUser[allConstants.USER_FIELDS.COMPANY_ID.api_name]
    }));

    unwrapRequestResult({
      showSuccess: true,
      result: updateStatus,
      successMessage: `Now ${newAdminName} is the admin for ${props.selectedUser[allConstants.USER_FIELDS.COMPANY_NAME.api_name]}` ,
      id: 'update_admin',
    });

    if(user.role === allConstants.ROLES.COMPANY_ADMIN) {
      dispatch(actions.getCurrentUserData());
      navigate(allConstants.PATH.HOME);
    }
    props.closeModal();

  };
  const onCancelEdit = (e)=> {
    e.preventDefault();
    props.closeModal();
  };

  let warningNote;
  if(user.role === allConstants.ROLES.COMPANY_ADMIN) {
    warningNote = 'NOTE: company is allowed to have only one admin. Your role would be changed to "User". You will no longer get access to this tab.';
  } else {
    warningNote = 'NOTE: company is allowed to have only one admin. Current admin would be changed to "User".';
  }

  return(
    <>
      <Grid container xs={12}>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant={'body1'}
            fontSize={{xs: '18px', lg: '20px'}}
            mb={2}
          >
            You are going to change company admin to
            <Typography component={'span'} fontSize={{xs: '18px', lg: '20px'}} color={STYLE_CONSTANTS.COLORS.globalBlue}>
              {` ${props?.selectedUser?.firstName} ${props?.selectedUser?.lastName}`}
            </Typography>
            .
          </Typography>
          <Typography>
            {warningNote}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <FormSubmitButtons cancelAction={onCancelEdit}
            cancelText={'Cancel'}
            submitText={'Set New Admin'}
            submitAction={onSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};
SetCompanyAdmin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired
};
export default SetCompanyAdmin;

import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {ANALYTICS_ENTITIES, ANALYTICS_TARGETS} from '../../constants/options/analyticsTargets';
import EntityMiniCard from '../../pieces/analytics/entityMiniCard';
import {AnalyticsBaseCard} from '../../pieces/analytics/analyticsBaseCard';
import AnalyticsNumberCard from '../../pieces/analytics/analyticsNumberCard';
import {CompletedPermits} from './cards/completedPermits';
import * as allConstants from '../../constants/allConstants';
import {CompletedByMonthPermit} from './cards/completedByMonthPermit';
import {AnalyticsRowWrapper} from '../../pieces/analyticsRowWrapper';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';


function PermitAnalytics(props) {
  const company = props.company;
  const mediaQueries = CustomMediaQuery();
  const commonProps = {
    company: company?.[allConstants.COMPANY_FIELDS.NAME.api_name],
    entity: ANALYTICS_ENTITIES.PERMITS,
    mediaQueries: mediaQueries
  };

  return (
    <>
      <AnalyticsRowWrapper>
        <CompletedPermits {...commonProps}/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <EntityMiniCard {...commonProps}
          target={ANALYTICS_TARGETS.PERMIT_SUBMITTED}
          sessionKey={'permitSubmitedTotal'}/>
        <EntityMiniCard {...commonProps}
          params={{status: allConstants.PERMIT_STATUSES.COMPLETED.value.toLowerCase()}}
          target={ANALYTICS_TARGETS.PERMIT_COMPLETED}
          sessionKey={'permitCompletedTotal'}/>
        <EntityMiniCard {...commonProps}
          params={{status: allConstants.PERMIT_STATUSES.PENDING.value.toLowerCase()}}
          target={ANALYTICS_TARGETS.PERMIT_PENDING}
          sessionKey={'permitCurrPending'}/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <CompletedByMonthPermit {...commonProps} sessionKey={'completed'}/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <Box sx={{width: STYLE_CONSTANTS.DEFAULT.ANALYTICS_CARD_HEIGHT}}>
          <AnalyticsBaseCard title={'Need Attention'}>
            <AnalyticsNumberCard
              {...commonProps} target={ANALYTICS_TARGETS.PERMIT_NEED_ATTENTION}
              legend={'Current Jobs'} sessionKey={'currPermitsNeedsAttention'}
              path={allConstants.PATH.JOBS}
              queryParams={`?${allConstants.JOB_FIELDS.PERMIT_STATUS.api_name}=${allConstants.PERMIT_STATUSES.NEED_ATTENTION.value}`}
              params={{status: allConstants.PERMIT_STATUSES.NEED_ATTENTION.value.toLowerCase()}}/>
          </AnalyticsBaseCard>
        </Box>
      </AnalyticsRowWrapper>
    </>
  );
}

PermitAnalytics.propTypes = {
  company: PropTypes.shape({
    [allConstants.COMPANY_FIELDS.NAME.api_name]: PropTypes.string.isRequired
  }).isRequired
};

export default PermitAnalytics;

import React from 'react';
import Utils from '../../utils/utils';
import {urlSearchParams} from '../../baseUrls';
import {CompanyView} from './companyView';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as allConstants from '../../constants/allConstants';


export const RegisterCompany = ()=> {
  const user = useSelector(state=> state.user);
  const navigate = useNavigate();
  const stateLicenseNumber = Utils.getUrlParameter(urlSearchParams.stateLicenseNumber);


  if(!user.isAuthenticated && !stateLicenseNumber && !user.isFetching){
    navigate(allConstants.PATH.LOGIN);
  }

  return <CompanyView isRegistering={true} stateLicenseNumber={stateLicenseNumber}/>;
};


import {Box} from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import base_url from '../../../../../baseUrls';
import * as allConstants from '../../../../../constants/allConstants';
import {INSPECTION_FIELDS, INSPECTION_TYPES} from '../../../../../constants/allConstants';
import {useJob} from '../../../../../contextProviders/jobContext';
import {useConfirmationForStatusChange} from '../../../../../hooks/useConfirmationForStatusChange';
import {Card} from '../../../../../pieces/card';
import {FormLabel, FormValue} from '../../../../../pieces/displayJob/formLine';
import {DrawerWrapper} from '../../../../../pieces/drawer';
import Spacer from '../../../../../pieces/spacer';
import {TransitionRow} from '../../../../../pieces/transitionButton';
import CallsIcon from '../../../../../static/images/callLogIcon.svg';
import TagIcon from '../../../../../static/images/tag-green.png';
import CityIcon from '../../../../../static/images/cityIcon.svg';
import ResultsIcon from '../../../../../static/images/testResults.svg';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import * as STYLE_CONSTANTS from '../../../../../styles/styleConstants';
import ApiUtils from '../../../../../utils/apiUtils';
import Utils from '../../../../../utils/utils';
import Loader from '../../../../misc/loader';
import {IpermitJobWrapper} from '../../../components/ipermitJobWrapper';
import InspectionCityInfo from './cityPanel';
import {shouldShow} from './inspectionTransitionsShowRules';
import {ScheduleInspectionModal} from './scheduleInspectionModal';
import {useGotIt} from '../../../../../hooks/useGotIt';
import {useSelector} from 'react-redux';
import {NewPermissionGate} from '../../../../../permissions/permissionGate';
import {
  ELEMENTS_INSPECTIONS,
  inspectionRules
} from '../../../../../permissions/ipermitAccessControl/inspectionsAccessControl';

const InspectionCallLogs = React.lazy(() => import('./callLogsPanel.js'));
const InspectionTags = React.lazy(() => import('./tagsPanel.js'));

const InspectionTestResults = React.lazy(() => import('./testResultsPanel'));


const InspectionRecord = ()=>{
  const classes = {...globalUseClasses()};
  const [fetching, setFetching] = useState(false);
  const {job, setJob, ...jobContext} = useJob();
  const route = useParams();
  const {INSPECTION_STATUSES, JOB_FIELDS, PERMIT_MODULE_FIELDS, CALL_LOGS_FIELDS, SCHEDULING_FIELDS} = allConstants;
  const [showSchedulingModal, setShowSchedulingModal] = useState(false);
  const [schedulingInspection, setSchedulingInspection] = useState({});
  const confirmation = useConfirmationForStatusChange('Inspection');
  const gotIt = useGotIt();
  const user = useSelector(state => state.user);


  const userAllowedToEditInspection = useMemo(()=> {
    return NewPermissionGate({
      user: user,
      elementPermissions: inspectionRules[ELEMENTS_INSPECTIONS.manageInspection],
    });
  }, []);

  const userAllowedToEditInsp = () => {
    return NewPermissionGate({
      user: user,
      elementPermissions: inspectionRules[ELEMENTS_INSPECTIONS.manageInspection],
    });
  }

  const resolveNotAllowedToEditInspection = ()=> [
    gotIt.setShow(allConstants.ERROR_MSG.NO_PERMISSION('edit', 'inspection')),
  ];

  const simpleTransitionAction = async (value, autocloseTask=false)=> {

    // Thanh 01/24/24
    //console.log('simpleTransitionAction');
    //console.log(userAllowedToEditInspection);

    // if(!userAllowedToEditInspection) {
    //   resolveNotAllowedToEditInspection();
    //   return;
    // }

    if (!userAllowedToEditInsp()) {
      resolveNotAllowedToEditInspection();
      return;
    }

    if(!value) return;
    const dataToSubmit = {
      status: value,
      id: job?.[JOB_FIELDS.ID.api_name],
      cancelTask: autocloseTask,
    };
    const result = await ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/change-inspection-status', JSON.stringify(dataToSubmit));

    return handleSubmitResponse(result, `Inspection status changed to ${value}`);
  };

  const startScheduling = (type, )=> {

    // Thanh 01/24/24
    //console.log('startScheduling');
    //console.log(userAllowedToEditInspection);

    if (!userAllowedToEditInsp()) {
      resolveNotAllowedToEditInspection();
      return;
    }

    // if(!userAllowedToEditInspection) {
    //   resolveNotAllowedToEditInspection();
    //   return;
    // }


    setSchedulingInspection({
      type: type,
      status: INSPECTION_STATUSES.SCHEDULED.value
    });
    setShowSchedulingModal(true);
  };



  const handleSubmitResponse = (result, message)=> {
    const toastOptions = {
      toastId: 'inspection_response',
      autoClose: 1000,
    };

    if(result.status === 'success') {
      const updatedInspection = result?.data?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name] || {};
      const updatedJob = {...job, [allConstants.INSPECTION_FIELDS.INSPECTION.api_name]: {...updatedInspection}};
      setSchedulingInspection({});
      setJob(updatedJob);
      setShowSchedulingModal(false);
      setFetching(false);
      toast.success(message ? message : 'Inspection updated', toastOptions);
      return true;
    } else{
      toast.error(`Inspection update error: ${result?.message}`, toastOptions);
    }
  };

  const handleSchedulingAction = async(formData)=> {
    setFetching(true);
    const dataToSubmit = {...formData, ...{
      id: job?.[JOB_FIELDS.ID.api_name],
    }};

    console.log('inside handle scheduling action');
    console.log(dataToSubmit);

    const result = await ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/schedule-inspection', JSON.stringify(dataToSubmit));
    const inspectionType = dataToSubmit?.type;
    handleSubmitResponse(result, `${inspectionType} inspection scheduled`);
  };

  const startRescheduling = ()=> {

    // Thanh 01/24/24
    //console.log('startRescheduling');
    //console.log(userAllowedToEditInspection);

    if (!userAllowedToEditInsp()) {
      resolveNotAllowedToEditInspection();
      return;
    }

    // if(!userAllowedToEditInspection) {
    //   resolveNotAllowedToEditInspection();
    //   return;
    // }
    setSchedulingInspection({
      type: job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.TYPE.api_name] || INSPECTION_TYPES.FINAL.value,
      status: INSPECTION_STATUSES.SCHEDULED.value,
      isRescheduling: true
    });
    setShowSchedulingModal(true);
  };

  const handleCloseModal = ()=> {
    setFetching(false);
    setShowSchedulingModal(false);
  };

  const tabs =  {
    calls: {
      name: 'Call Logs',
      panel:   <Suspense fallback={<div>Loading...</div>}>
        <InspectionCallLogs/></Suspense>,
      icon: CallsIcon
    },
    tests:{
      name: 'Test results',
      panel: useMemo(()=> {return(
        <Suspense fallback={<div>Loading...</div>}>
          <InspectionTestResults/>
        </Suspense>);}, [job]),
      icon: ResultsIcon
    },
    city: {
      name: 'City Info',
      panel: useMemo(()=> {return <InspectionCityInfo/>;}, [job]),
      icon: CityIcon
    },
    tags: {
      name: 'Tags',
      panel:   <Suspense fallback={<div>Loading...</div>}>
        <InspectionTags/></Suspense>,
      icon: TagIcon
    },
  };

  const submitConfirmedStatusChange = async (status)=> {
    const cancelSideEffect = status === INSPECTION_STATUSES.PENDING.value ||
      status === INSPECTION_STATUSES.NEW.value;
    await simpleTransitionAction(status, cancelSideEffect);
  };

  const transitionWithWarning = async (status, noteRequired=false)=> {

    // Thanh 01/24/24
    //console.log('simpleTransitionAction');
    //console.log(userAllowedToEditInspection);

    if (!userAllowedToEditInsp()) {
      resolveNotAllowedToEditInspection();
      return;
    }
  
    // if(!userAllowedToEditInspection) {
    //   resolveNotAllowedToEditInspection();
    //   return;
    // }

    const inspectionScheduledWithRater = jobContext.inspectionWithOwnRater();
    const inspectionScheduledWithCity = jobContext.inspectionScheduled() && !inspectionScheduledWithRater;
    if(!inspectionScheduledWithCity && !inspectionScheduledWithRater) {
      //inspection is not scheduled
      if(status === INSPECTION_STATUSES.CANCELLED.value || status === INSPECTION_STATUSES.HOLD.value) {
        confirmation.askConfirmation({
          action: ()=>submitConfirmedStatusChange(status),
          pendingStatus: status,
          noteRequired: noteRequired
        });
      } else {
        await simpleTransitionAction(status);
      }
      return;
    }

    //inspection is scheduled with city or with own rater
    let noteText = null;
    if(inspectionScheduledWithCity) {
      if(status === INSPECTION_STATUSES.NEW.value ||
        status === INSPECTION_STATUSES.PENDING.value) {
        const result = await simpleTransitionAction(status);
        if(result) {
          gotIt.setShow('This inspection has been scheduled with the city. If needed, call city to cancel it and notify home owner.');
        }
        return;
      } else {
        noteText = 'This inspection has been scheduled with the city. If needed, call city to cancel it and notify home owner.';
      }
    } else if(inspectionScheduledWithRater) {
      noteText = 'This will also remove the inspection from the rater\'s calendar.';
    }

    confirmation.askConfirmation({
      action: ()=>submitConfirmedStatusChange(status),
      pendingStatus: status,
      noteText: noteText,
      noteRequired: noteRequired
    });
  };

  const transitions = useMemo(()=>[
    {status: INSPECTION_STATUSES.NEW, action: ()=>transitionWithWarning(INSPECTION_STATUSES.NEW.value, false)},
    {status: INSPECTION_STATUSES.PENDING, action: ()=>transitionWithWarning(INSPECTION_STATUSES.PENDING.value,false)},
    {status: INSPECTION_STATUSES.SCHEDULED, action: ()=>startScheduling(allConstants.INSPECTION_TYPES.FINAL.value)},
    {status: INSPECTION_STATUSES.SCHEDULED_ROUGH, action: ()=>startScheduling(allConstants.INSPECTION_TYPES.ROUGH.value)},
    {status: INSPECTION_STATUSES.RESCHEDULED, action: ()=>startRescheduling()},
    {status: INSPECTION_STATUSES.MAILED_CERT_LETTER, action: simpleTransitionAction},
    {status: INSPECTION_STATUSES.HOLD, action: ()=>transitionWithWarning(INSPECTION_STATUSES.HOLD.value, false)},
    {status: INSPECTION_STATUSES.CANCELLED, action: ()=>transitionWithWarning(INSPECTION_STATUSES.CANCELLED.value,false)},
    {status: INSPECTION_STATUSES.COMPLETED, action: simpleTransitionAction}
  ], [job]);


  const getTransitions = ()=> {
    return transitions.map((transition)=> {
      let hide;
      const currStatus = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]?.[allConstants.INSPECTION_FIELDS.INSPECTION_STATUS.api_name];
      const inspType = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]?.[allConstants.INSPECTION_FIELDS.TYPE.api_name];
      if(!currStatus || !inspType) {
        hide = true;
      } else {
        hide = !shouldShow(transition.status, currStatus, inspType);
      }
      return {...transition, ...{hide: hide}};
    });
  };


  useEffect(() => {
    const fetch = async () => {
      let url = `${base_url.api}jobs/${route.id}`;

      const authToken = await ApiUtils.getAccessToken();
      const requestParams = {populate: ['inspection'], getSameAddressJobs: true};
      const jobResponse = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return jobResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {
        //console.log('got job', data.data.data.job);
        setJob(data.data.data.job);
        // setNotes(data.data.data.job.notes);
        setFetching(false)
        ;
      })
      .catch((error) => {
        // props.parentFetching(false);
        setFetching(false);
        console.log('ERR:::', error);
      });
  }, []);

  if(fetching && !showSchedulingModal) return <Loader/>;
  if(!job) return null;

  const getRow = (field, options)=> {
    const {label} = field;
    if(options?.value) {
      return {label: label, value: options.value};
    }

    const value = options?.parent ? job?.[options.parent]?.[field.api_name] : job?.[field.api_name];

    const displayValue = options.overwriteValue ? options.overwriteValue : typeof value === 'undefined' ? '-' : Utils.reformatValue(value);
    return{
      label: label, value: displayValue};
  };

  const generalFields = (()=> {
    const NaValue = 'N/A';
    const currentCalls = job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[CALL_LOGS_FIELDS.CALL_LOG.api_name] || [];
    const homeOwnerCalls = currentCalls.filter((call)=>call?.[CALL_LOGS_FIELDS.TYPE.api_name] === allConstants.CALL_TO_OPTIONS.HOME_OWNER.value);
    const lastCallDate = Utils.formatIsoDateString(homeOwnerCalls.length ? homeOwnerCalls[homeOwnerCalls.length - 1]?.[CALL_LOGS_FIELDS.DATE.api_name] : null, {
      defaultReturn: '-'
    });
    const col1 = [
      getRow(JOB_FIELDS.GENERAL.ASBESTOS, {value: jobContext.isHVACJob() ? undefined : NaValue}),
      getRow(JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED, {value: jobContext.isPermitOnly() ? NaValue : undefined}),
      getRow(JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED, {value: jobContext.isPermitOnly() ? NaValue : undefined}),
      {label: 'Contractor\'s email', value: job[JOB_FIELDS.ORDERED_BY.api_name]?.[allConstants.USER_FIELDS.EMAIL.api_name], longLabel: true},
      getRow({...allConstants.PERMIT_MODULE_FIELDS.NUMBER, label: 'Permit Number'}, {parent:
      allConstants.PERMIT_MODULE_FIELDS.PERMIT_INFO.api_name}),
    ];

    const col2 = [
      getRow(PERMIT_MODULE_FIELDS.ZONE, {value: jobContext.isPermitOnly() ? NaValue : undefined}),
      getRow(PERMIT_MODULE_FIELDS.MECH01, {value: jobContext.isPermitOnly() ? NaValue : undefined}),
      getRow(CALL_LOGS_FIELDS.LAST_CALL_DATE, {value: lastCallDate}),
      getRow(CALL_LOGS_FIELDS.HOMEOWNER_FAILED_CALL_COUNTER, {overwriteValue: homeOwnerCalls.length ? homeOwnerCalls.length : 0}),

    ];
    return [col1, col2];

  })();

  return (
    <>
      <Box className={clsx(classes.page)}>
        {showSchedulingModal &&  <ScheduleInspectionModal
          handleClose={handleCloseModal}
          action={handleSchedulingAction}
          inspection={schedulingInspection}
          homeOwnerInfo={job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]}
          jobId={job.id}
          cityInspectionData={job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]}
          isFetching={fetching}
        />}
        <IpermitJobWrapper module={allConstants.IPERMIT_MODULES.INSPECTION}
          backPath={`${allConstants.PATH.MODULE}${location.search}`}
          generalInfo={generalFields}
        >
          <>
            {confirmation.ConfirmPopup()}
            {gotIt.PopUp()}
            <Card>
              <TransitionRow transitions={getTransitions()}
                currentInspectionType={job?.[INSPECTION_FIELDS.INSPECTION.api_name][INSPECTION_FIELDS.TYPE.api_name]}
                currentStatus={job?.[INSPECTION_FIELDS.INSPECTION.api_name][INSPECTION_FIELDS.INSPECTION_STATUS.api_name]}/>
            </Card>
            <Spacer y={2}/>
            {job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[SCHEDULING_FIELDS.START_DATE.api_name] && <>
              <Box sx={{
                textTransform: 'uppercase',
                border: `2px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
                borderRadius: '10px',
                padding: theme=>theme.spacing(1),
                display:'flex',
                justifyContent: 'center'

              }}
              >
                <Box display={'flex'} flexDirection={'row'} sx={{
                  '& .MuiTypography-root':{
                    color: STYLE_CONSTANTS.COLORS.globalBlue,
                  }
                }}>
                  <FormLabel label={'scheduled'}/>
                  <Spacer x={1}/>
                  <FormValue value={Utils.getFormattedScheduledDate({
                    startDate: job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[SCHEDULING_FIELDS.START_DATE.api_name],
                    startTime: job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[SCHEDULING_FIELDS.START_TIME.api_name],
                    endTime:  job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[SCHEDULING_FIELDS.END_TIME.api_name],
                    duration: 1,
                    timezone: job?.[JOB_FIELDS.TIMEZONE.api_name]
                  })}/>
                </Box>
              </Box>
              <Spacer y={2}/>
            </>}
            <DrawerWrapper tabs={tabs}/>
          </>
        </IpermitJobWrapper >

      </Box>
    </>
  );
};

export default InspectionRecord;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import {globalUseClasses} from '../../../../styles/globalClasses';
import JobFormAccordionTab from '../../../../pieces/accordion/jobFormAccordionTab';
import MechOther from './panels/mechOther';
import MechPlumbing from './panels/mechPlumbing';
import MechElectrical from './panels/mechElecrtical';
import MechInsulation from './panels/mechInsulation';
import Commercial from './commercialSection';
import Utils from '../../../../utils/utils';
import MapPanel from './panels/mapPanel';

const OtherMechanical = (props)=> {
  // console.log('OTHERRR,', props);
  const classes = {...globalUseClasses()};
  const isTestingOnly = props.jobType === allConstants.JOB_TYPES.TESTING_ONLY.value;
  const [showMap, setShowMap] = useState(false);
  const mapTab = [
    {
      name: 'map',
      header: 'Map',
      content:MapPanel,
      alwaysOpen: true,
    },
    {
      name: 'other',
      header: 'Other Equipment Options',
      content:MechOther
    },
  ];

  let tabs = [...mapTab, ...[

    {
      name: 'mech_electrical',
      header: 'Electrical Information',
      content: MechElectrical
    },
    {
      name: 'mech_plumbing',
      header: 'Plumbing Information',
      content: MechPlumbing
    },
    {
      name: 'insulation',
      header: 'Insulation',
      content: MechInsulation
    },
  ]];

  if(props.propertyType === allConstants.PROPERTY_TYPES.COMMERCIAL.value) {
    tabs.push(
      {
        name: 'commercial',
        header: 'Commercial Information',
        content: Commercial
      }
    );
  }

  useEffect(()=> {
    if(props.savedForms) {
      const usedEquipment = Utils.getEquipmentThatRequiresMap(props.savedForms);
      if(usedEquipment && usedEquipment.length !== 0) {
        setShowMap(true);
      }
    }
  },[]);
  if(isTestingOnly) {
    return (
      <>
        {mapTab.map((tab, idx)=> {
          return(
            <JobFormAccordionTab classes={classes}
              show={tab.name !== 'map' || showMap}
              key={idx+tab.name}
              header={tab.header}
              id={`${tab.name}-header`}
              aria_controls={`${tab.name}-content`}
              alwaysOpen={tab.alwaysOpen}
            >{tab.content({...props, ...{nested: true, parent: allConstants.JOB_TYPES.TESTING_ONLY.api_name}})}</JobFormAccordionTab>
          );
        })}
      </>
    );
  }

  return (
    <>
      {tabs.map((tab, idx)=> {
        return(
          <JobFormAccordionTab classes={classes}
            show={tab.name !== 'map' || showMap}
            key={idx+tab.name}
            header={tab.header}
            id={`${tab.name}-header`}
            aria_controls={`${tab.name}-content`}
            alwaysOpen={tab.alwaysOpen}
          >{tab.content({...props, ...{nested: true, parent: allConstants.JOB_TYPES.HVAC_RES.api_name}})}</JobFormAccordionTab>
        );
      })}
    </>
  );
};
OtherMechanical.propTypes = {
  propertyType: PropTypes.string.isRequired,
  savedForms: PropTypes.instanceOf(Object),
  jobType: PropTypes.string.isRequired,
};

OtherMechanical.defaultProps = {
  savedForms: undefined,
};


export default OtherMechanical;

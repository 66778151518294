import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm, FormProvider} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Box, Typography, TextField, Button} from '@mui/material';
import myTheme, {useHelperTextStyles} from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import {useStylesLogin} from './loginStyles';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Utils from '../../utils/utils';
import * as allConstants from '../../constants/allConstants';
import actions from '../../redux/actions';
import {SingInPageWrapper} from './singInPageWrapper';
import CustomIcon from '../../static/icons/Icon';
import BackTo from './pieces/backTo';
import {unwrapResult} from '@reduxjs/toolkit';
import * as companySlice from '../../redux/companies/companySlice';
import {ShowPassword} from './pieces/showPasswordIcon';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import base_url, {urlSearchParams} from '../../baseUrls';
import {AuthFormFooter} from './pieces/authFormFooter';
import ApiUtils from '../../utils/apiUtils';
import axios from 'axios';

const HelperBlock = (props)=> {
  let isError = true;
  if(!props.currValue || props.currValue.length === 0) {
    return null;
  }

  if(!Utils.objectHasProperty(props.formState.errors, 'password')) {
    isError = false;
  }
  else if(props.formState && Object.keys(props.formState.errors).length !== 0) {
    if(props.formState.errors.password) {

      const errorTypes = props.formState.errors.password.types;
      isError = Utils.objectHasProperty(errorTypes, props.validation);
    }
  }  else {
    isError = false;
  }
  const iconProps = {
    icon: `${isError ? 'cross_small' : 'checkmark'}`,
    size:`${isError ? 8 : 10}`,
    color: `${isError ? myTheme.palette.error.main : myTheme.palette.success.main}`,
    fill:'none',
    style: {
      strokeWidth:`${isError ? 4 : 6}`,
      marginRight: '8px'
    }
  };

  return (
    <Box display={'flex'} alignItems={'center'} mr={2}>
      <CustomIcon {...iconProps} />
      <span>{props.text}</span>
    </Box>
  );
};

HelperBlock.propTypes = {
  currValue: PropTypes.string,
  formState: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string,
  validation: PropTypes.string.isRequired,
};
HelperBlock.defaultProps = {
  currValue: undefined,
  text: '',
};

const SignupPage = () => {
  const signUpState = {
    resetPassword: 'resetPassword',
    userInvitation: 'userInvitation',
    selfRegister: 'selfRegister',
  };

  const [linkisValid, setLinkisValid] = useState(true);
  const signUpMode = React.useRef(signUpState.selfRegister);
  const helperTextStyles = useHelperTextStyles({bg_color: 'red'});
  const extractUserData = ()=> {
    try{
      const data = Utils.getUrlParameter('success');
      const decompressed = JSON.parse(Utils.decodeFromBase64(data));

      if (decompressed.redirectReason === 'passwordRecovery') {
        // console.log('Password Recovery');
        const recoveryToken = decompressed.data.token;
        // console.log('recoveryToken', recoveryToken);
        signUpMode.current = signUpState.resetPassword;
        return {recoveryToken: recoveryToken};
      } else {
        console.log('Sign In User');
        const email = decompressed.data.email;
        const inviteCode = decompressed.data.inviteCode;
        //console.log('decompressed', decompressed);
        signUpMode.current = signUpState.userInvitation;
        return {inviteCode: inviteCode, email, userId: decompressed.data.userId};
      }
    } catch (e){}

    return {inviteCode: ''};
  };


  const userData = extractUserData();
  const signUpWithLink = userData.inviteCode || userData.recoveryToken;
  const dispatch = useDispatch();
  const classes = {...useStylesLogin(), ...globalUseClasses()};
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFocused, setFocused] = useState(false);

  const setPasswordValidation = {password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),

  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
  };

  const selfRegisterValidation = {
    [allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.REQUIRED_FIELD)
  };

  const validationSchema = Yup.object().shape(signUpWithLink? setPasswordValidation : selfRegisterValidation);
  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const methods = useForm(formOptions);

  const {isSuccess, isError, inviteLinkIsValid} = useSelector(
    state => state.user
  );
  const user = useSelector(state => state.user);

  const signUpInvited = async (data)=> {
    data.inviteCode = userData.inviteCode;
    const signUpStatus = await dispatch(actions.signUpUser(data));
    try{
      const singupPromiseResult = unwrapResult(signUpStatus);
      if(Utils.objectHasProperty(singupPromiseResult, 'company') && singupPromiseResult.company) {
        console.log('Signed up...', singupPromiseResult.company);
        navigate(`${allConstants.PATH.COMPANY}?${urlSearchParams.completeOnboarding}=true&${urlSearchParams.companyId}=${singupPromiseResult?.company?.id}`);
        dispatch(companySlice.updateCompanyState(singupPromiseResult.company));
      }
    } catch (e) {
      console.log('error on signup');
    }
  };

  const resetPassword = async (data) => {
    data.recoveryToken = userData.recoveryToken;
    const resetPasswordResult = await dispatch(actions.createPassword(data));
    try {
      unwrapRequestResult({
        result: resetPasswordResult,
        id: 'reset password'
      });
      const status = resetPasswordResult.payload.status;
      if (status === 'success') {
        navigate(allConstants.PATH.HOME);
      }
    } catch (e) {
      console.log('error on signup', e);
    }
  };

  const isCompanyExists = async (formData)=> {
    if(!formData?.[allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]) return;
    const authToken = await ApiUtils.getAccessToken();
    const requestParams = {
      stateLicenseNumber: formData?.[allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]
    };
    const url = `${base_url.api}companies/exists`;
    const response = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
    return response?.data?.data ? response.data.data.exists : true;

  };


  const onSubmit =  async (data) => {

    if (signUpMode.current === signUpState.resetPassword) {
      await resetPassword(data);
    } else if (signUpMode.current === signUpState.userInvitation) {
      await signUpInvited(data);
    } else {
      const isNewCompany = !await isCompanyExists(data);
      const stateNumber = data?.[allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name];
      if(isNewCompany) {
        navigate(`${allConstants.PATH.REGISTER_COMPANY}?${urlSearchParams.stateLicenseNumber}=${stateNumber}`);
      } else {
        navigate(allConstants.PATH.COMPANY_EXISTS);
      }
    }
  };

  //reset form values
  useEffect(()=> {
    methods.reset();
  }, [methods]);

  //validate invite link
  useEffect(() => {
    if(!signUpWithLink) return;
    const validateInviteLink = async ()=>{
      const validateResponse = await dispatch(actions.validateUserInvite({inviteCode:userData.inviteCode}));
      const unwrapped = unwrapRequestResult({
        result: validateResponse,
        id: 'validateInvite'
      });
      console.log('unwrapped', unwrapped);
      if(unwrapped.errorCode === 'INVALID_LINK'){
        setLinkisValid(false);
      }
    };
    if (signUpMode.current === signUpState.userInvitation) {
      validateInviteLink();
    }
  }, [dispatch, userData.inviteCode]);


  useEffect(() => {
    if (isSuccess && user.isAuthenticated) {
      const role = user.role;

      if(role && role === allConstants.ROLES.COMPANY_ADMIN) {
        navigate(`${allConstants.PATH.COMPANY}?${urlSearchParams.companyId}=${user?.[allConstants.USER_FIELDS.COMPANY_ID.api_name]}`, {replace: true});
      } else {
        navigate(allConstants.PATH.HOME, {replace: true});
      }
    }
    if (isError) {
      dispatch(actions.clearState());
    }
  }, [user]);


  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  if(!linkisValid) {
    return (
      <SingInPageWrapper>
        <p>Link is not valid (was already used). Please contact iPermit...</p>
      </SingInPageWrapper>
    );
  }

  const passwordBlock = <>

    <Box display={'flex'} flexDirection={'column'} className={classes.form_group}>
      <span className={classes.input_label}>Enter password</span>
      <TextField variant="outlined"
        onFocus={()=> {setFocused(true);}}
        size="small"
        type={showPassword ? 'text' : 'password'}
        name={'password'}
        autoComplete={'off'}
        error={Utils.formstateContainErrorFor(methods.formState, 'password')}
        {...methods.register('password', {
          required: true,
        })}

        InputProps={{ // <-- Toggler to show/hide password
          classes: {
            adornedEnd: classes.adornedEnd
          },
          endAdornment:
                       <ShowPassword show={showPassword} handleShowPassword={handleShowPassword}/>
        }}
      />

    </Box>
    <Box display={'flex'} flexDirection={'column'} mb={2}>
      <span className={classes.input_label}>Re-enter password</span>
      <TextField variant="outlined" mb={0}
        onFocus={()=> {setFocused(false);}}
        size="small"
        type={showPassword ? 'text' : 'password'}
        name={'confirmPassword'}
        autoComplete={'off'}
        error={Utils.formstateContainErrorFor(methods.formState, 'confirmPassword')}
        FormHelperTextProps={{
          classes:{
            root:helperTextStyles.root

          }
        }}
        helperText={Utils.formstateContainErrorFor(methods.formState, 'confirmPassword') ? methods.formState.errors.confirmPassword.message :' '}
        {...methods.register('confirmPassword', {
          required: true,

        })}

        InputProps={{ // <-- togger to show password.
          classes: {
            adornedEnd: classes.adornedEnd
          },
          endAdornment: (
            <ShowPassword show={showPassword} handleShowPassword={handleShowPassword}/>
          )
        }}
      />
    </Box>
    <Box display={'flex'} height={25}>
      {passwordFocused && <>
        <HelperBlock text={'6-16 characters'} formState={methods.formState} validation={'min'} currValue={methods.watch('password')}/>

      </>}

    </Box>
  </>;

  const getFormFields = ()=> {
    if(signUpWithLink) {
      return (
        <>
          {passwordBlock}
          <Box mt={1}>
            <Button variant="contained"
              color={'primary'}
              fullWidth
              type="submit"
              disabled={!inviteLinkIsValid && signUpMode.current !== signUpState.resetPassword}

            >
              Set password
            </Button>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box display={'flex'} flexDirection={'column'}  alignItems={'center'} mb={6} sx={{
            letterSpacing: '1.2px',
          }}

          >
            <p>Please enter your company license number</p>
            <TextField variant="outlined"
              onFocus={()=> {setFocused(true);}}
              size="small"
              type={'text'}
              name={allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name}
              autoComplete={'off'}
              sx={{width: '100% !important'}}
              inputProps={{style: {textAlign: 'center', padding: '6px'}}}
              error={Utils.formstateContainErrorFor(methods.formState, allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name)}
              {...methods.register(allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name, {
                required: true,
              })}
              helperText={Utils.formstateContainErrorFor(methods.formState, allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name)? methods.formState.errors[allConstants.COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name].message :' '}
            />

          </Box>
          <Box mt={1} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
            <Button variant="contained"
              color={'primary'}
              type='submit'
              fullWidth

            >
              Sign Up
            </Button>
            {/*<AuthFormFooter isSignUpPage={true}/>*/}
          </Box>
        </>
      );
    }
  };

  return (
    <SingInPageWrapper>
      <>

        <Typography variant={'h2'} className={classes.form_header}>
          {signUpMode.current === signUpState.userInvitation || signUpMode.current === signUpState.selfRegister? 'Sign Up' : 'Set Password'}
        </Typography>
        <Box
        ><FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} onError={()=> console.log(methods.formState.errors)}
              method="POST"
            >
              {getFormFields()}
            </form>

          </FormProvider>
        </Box>

      </>
      <Box sx={{marginTop: '24px'}}>
        <AuthFormFooter isSignUpPage={true}/>
      </Box>

      {signUpMode.current === signUpState.resetPassword && <Box display={'flex'} justifyContent={'center'} mt={6}>
        <BackTo path={allConstants.PATH.LOGIN} text={'Back to login'} cl_name={classes.go_back_link}/>
      </Box>}

    </SingInPageWrapper>


  );
};
export default SignupPage;

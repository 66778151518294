const HyperlinkCellRenderer = (params) => {
  
  const { value, link } = params;

  return (
  <a href={link} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  );
};
export default HyperlinkCellRenderer;
import React, {useState, useMemo} from 'react';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import * as allConstants from '../../constants/allConstants';
import {roleTranslator} from '../../utils/translator';
import {useDispatch} from 'react-redux';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import base_url, {urlSearchParams} from '../../baseUrls';
import PropTypes from 'prop-types';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import actions from '../../redux/actions';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import {USER_FIELDS} from '../../constants/allConstants';
import Utils from '../../utils/utils';
import {useNavigate} from 'react-router-dom';

function AdminPageGrid(props) {
  const viewController = props.viewController;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ls_name = 'admin_panel_grid';
  const [components] = useState({
    'statusRenderer': StatusRenderer,
  });

  const editUserHandler = (userData) => {
    navigate(`${allConstants.PATH.ADMIN_PAGE_USER}${location.search}&${urlSearchParams.userId}=${userData.id}`);
  };

  const deactivateUser = async (userId, userData) => {
    const deactivateStatus = await dispatch(actions.deactivateUser({userId: userId}));
    unwrapRequestResult({
      result: deactivateStatus,
      successMessage: `Deactivated: ${userData?.[allConstants.USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[allConstants.USER_FIELDS.LAST_NAME.api_name]}.`,
      errorMessage: 'Error on deactivation',
      showSuccess: true,
    });
    viewController.refreshOnSuccess(deactivateStatus);
  };


  const cheersHandler = (userData)=> {
    props.cheersHandler(userData);
  };

  const activateUser = async (userId, userData) => {
    const activateStatus = await dispatch(actions.activateUser({userId: userId}));
    unwrapRequestResult({
      result: activateStatus,
      successMessage: `Activated: ${userData?.[allConstants.USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[allConstants.USER_FIELDS.LAST_NAME.api_name]}.`,
      errorMessage: 'Error on activation',
      showSuccess: true,
    });
    viewController.refreshOnSuccess(activateStatus);
  };

  const reinvite = async (userId, userData) => {
    const reinviteStatus = await dispatch(actions.reinviteUser({userId: userId}));
    unwrapRequestResult({
      result: reinviteStatus,
      successMessage: `New invite link sent to user ${userData?.[USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[USER_FIELDS.LAST_NAME.api_name]}.`,
      //errorMessage: 'Error on re-invite',
      showSuccess: true,
    } );
  };

  const userConnectedToCheers = (userData)=> {
    return userData?.[allConstants.USER_FIELDS.IS_CONNECT_TO_CHEERS_REQUESTED.api_name] &&
      userData?.[allConstants.USER_FIELDS.HAS_CONNECTED_TO_CHEERS.api_name];
  };

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data.id, user.id)
    if(!params.node) return [];
    const userStatus = params?.node?.data[allConstants.USER_FIELDS.STATUS.api_name];
    const userSignedUp = Utils.notEqualsIgnoreCase(userStatus, allConstants.USER_STATUSES.INVITED.value);
    let clickOnSelf = params?.node?.data && params?.node?.data.id === viewController.user.id;

    const actions = {
      editUser: {
        name: 'Edit',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> {
          editUserHandler(params?.node?.data);
        },
      },
      reInvite: {
        name: 'Re-send Invite',
        disabled: viewController.selectedMultipleRows() || userSignedUp,
        action: ()=> {
          reinvite(params?.node?.data[USER_FIELDS.ID.api_name], params?.node?.data);
        },
        cssClasses: ['blueFont', 'bold'],
      },
      disableUser: {
        name: 'Disable User',
        disabled: clickOnSelf || viewController.selectedMultipleRows() ,
        action: ()=> {
          deactivateUser(params?.node?.data[allConstants.USER_FIELDS.ID.api_name], params?.node?.data);
        }
      },
      activateUser: {
        name: 'Activate User',
        disabled: clickOnSelf || viewController.selectedMultipleRows(),
        action: ()=> {
          activateUser(params?.node?.data[allConstants.USER_FIELDS.ID.api_name], params?.node?.data);
        }
      },
      connectToCheers: {
        name: 'Connect to Cheers',
        disabled: viewController.selectedMultipleRows() || userConnectedToCheers(params?.node?.data),
        action: ()=> {
          cheersHandler(params?.node?.data);
        }
      },

      disconnectFromCheers: {
        name: 'Disconnect from Cheers',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> {props.disconnectCheersHandler(params?.node?.data);}
      }
    };

    const userActions = [
      actions.editUser
    ];
    if(userStatus === allConstants.USER_STATUSES.DISABLED.value.toUpperCase()) {
      userActions.push(actions.activateUser);
    } else {

      if(userConnectedToCheers(params?.node?.data)){
        userActions.push(actions.disconnectFromCheers);
      }
      userActions.push(actions.disableUser);
    }
    if(!userSignedUp) {
      userActions.push(actions.reInvite);
    }

    const standard = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];
    const separator=[
      'separator'
    ];

    const dangerous = [];

    return [...userActions, ...separator, ...standard, ...dangerous];
  };

  const columnDefs = useMemo(()=> {
    return [
      new ColumnDefinition({field: allConstants.USER_FIELDS.ID, hide: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.NAME,
        wrapText: true,
        lockPinned: true,
        pinned: 'left',
        checkboxSelection: true,
        width: 300,
        valueGetter: ({data})=> {return `${data.firstName} ${data.lastName}`;}
      }),
      new ColumnDefinition({field: allConstants.USER_FIELDS.EMAIL}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.ROLE,
        valueGetter: (({data})=>  roleTranslator.translate(data.role)), minWidth: 150}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.PHONE_NUMBER, minWidth: 150}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.STATUS, cellRenderer: 'statusRenderer', width: 130,
        filter:'agSetColumnFilter',
        filterValues: Object.values(allConstants.USER_STATUSES).map((s)=> s.value.toUpperCase())
      })
    ];
  },[]);

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      //queries={queries}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      fetchParams={{url:`${base_url.api}users/get-ipermit-users`, respKey:'users'}}

      // modifyDataElement={modifyElement}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
      refreshTimestamp={viewController.refreshTimestamp}
    />
  );
}

AdminPageGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,
  cheersHandler: PropTypes.func.isRequired,
  disconnectCheersHandler: PropTypes.func.isRequired,
};

export default AdminPageGrid;

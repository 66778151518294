import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Box} from '@mui/material';
import Spacer from './spacer';

export const RecordHeader = (props)=>{
  return (
    <Box display={'flex'} >
      <Typography variant='h1' sx={{fontSize: {xs: '18px', md: '20px'}, fontWeight: 'bold'}}>{props.text}</Typography>
      <Spacer x={2} />
      {props.children && props.children}
    </Box>
  );
};

RecordHeader.propTypes = {
  text: PropTypes.string
};

RecordHeader.defaultProps = {
  text: '',
};

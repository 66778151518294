import React from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {plumbingUtils} from './plumbingDisplayUtils';
import {Grid, Typography} from '@mui/material';
import {FormValue, FormLabel} from '../../../../../pieces/displayJob/formLine';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import {BlockHeader} from '../../../../../pieces/displayJob/blockHeader';
import {EquipmentBlock} from '../../../../../pieces/displayJob/equipmentBlock';
import {jobDisplayUtils} from '../jobDisplayUtils';
import {useJobDetailViewStyles} from '../../views/jobDetailViewStyles';
import {CommercialSection} from '../commercialSection';
import {DisplayFixtureTable} from '../fixtureTable';


const PlumbingDetails = (props) => {
  const {details, equipment} = props;
  // console.log('PL DETAILS', props);
  const PL_FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const classes = {...globalUseClasses(), ...useJobDetailViewStyles()};

  const displayRepipe = ()=> {
    const scope = details?.[PL_FIELDS.REPIPE.api_name]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
    let scopeContent;
    if(scope && Utils.valueIsSpecified(scope)) {
      scopeContent = <>
        <FormLabel label={allConstants.JOB_FIELDS.GENERAL.SCOPE.display_label}/>
        <FormValue value={scope} />
      </>;

    }
    const repipeBlock = plumbingUtils.getRepipes(details);
    if(repipeBlock.length === 0) return scopeContent;
    const repipeContent = jobDisplayUtils.getBlockComponentToDisplay(repipeBlock);

    if(repipeContent.length === 0 && !scopeContent) return null;

    return (
      <Grid container >
        {BlockHeader({header: 'repipe'})}
        <Grid item xs={12} sm={6}>

          {repipeContent}

        </Grid>
        <Grid item xs={12}  mb={2} pr={2}>
          <DisplayFixtureTable
            header={'Fixtures'}
            target={PL_FIELDS.FIXTURES}
            fixtureData={details?.[PL_FIELDS.REPIPE.api_name]?.[PL_FIELDS.REPIPE_FIXTURES.api_name]}/>
        </Grid>
        <Grid item  xs={12} sm={6}>
          {scopeContent}
        </Grid>

      </Grid>
    );

  };

  const displayReplaces = ()=> {
    const replaceBlock = plumbingUtils.getReplaces(details);
    if (replaceBlock.length === 0) return null;
    const replaceContent = jobDisplayUtils.getBlockComponentToDisplay(replaceBlock);


    return (
      <Grid container xs={12} className={classes.details_block} sx={{marginBottom: '16px!important'}}>
        {BlockHeader({header: 'Replace'})}
        {replaceContent && <Grid item xs={12}>
          {replaceContent}
        </Grid>}
      </Grid>
    );
  };


  const displayEquipment = () => {
    let {content, ws_scope} = plumbingUtils.getEquipmentData(details, equipment);

    if(!content || content.length === 0) return null;
    return(
      <>
        {BlockHeader({header: 'equipment'})}
        {EquipmentBlock({content: content})}
        {ws_scope && <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.label_bold}>water softener scope</Typography>
          <Typography variant={'body1'}>{ws_scope}</Typography>
        </Grid>}
      </>
    );

  };


  const displayRepairs = ()=> {
    const content = plumbingUtils.getRepiars(details);
    // console.log('Repairs', {...content});
    if(!content || content.length === 0) return null;
    return(
      <>
        {BlockHeader({header: 'repairs'})}
        {EquipmentBlock({content: content})}
      </>
    );
  };

  const repairs = displayRepairs();
  const repipe = displayRepipe();
  const replace = displayReplaces();
  const equipmentBlock = displayEquipment();
  const sanitation = jobDisplayUtils.getBlockContent(plumbingUtils.getSanitation(details), classes.details_block,'sanitation');
  const commercial = <CommercialSection details={details}/>;

  return (
    <>
      <Grid container>
        {commercial &&
        <Grid item xs={12} >
          {commercial}
        </Grid>
        }
        {equipmentBlock &&
        <Grid container columns={{xs: 4, sm: 8, md: 12}} >
          {equipmentBlock}
        </Grid>
        }
        {repipe &&
        <Grid item xs={12} >
          {repipe}
        </Grid>
        }
        {repairs &&
        <Grid container columns={{xs: 4, sm: 8, md: 12}} >
          {repairs}
        </Grid>
        }
        {replace && <Grid item xs={12} md={6} >
          {replace}
        </Grid>}
        {sanitation && <Grid item xs={12} md={6}>
          {sanitation}
        </Grid>}

      </Grid>
    </>

  );
};

PlumbingDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
  equipment: PropTypes.instanceOf(Array).isRequired,
};
export {PlumbingDetails};


const RATER_ELEMENTS = {
  viewPage: 'viewPage',
  addRaterButton: 'addRaterButton',
  deleteRater: 'deleteRater',
  editRater: 'editRater',
  activate: 'activate',
};

const raterPolicies = {
  manage: 'rater.manage',
};


const ratersPageRules = Object.freeze({
  [RATER_ELEMENTS.viewPage]: {
    policyName: undefined,
    contractorAccess: false,
  },

  [RATER_ELEMENTS.activate]: {
    policyName: raterPolicies.manage,
    contractorAccess: false,
  },

  [RATER_ELEMENTS.addRaterButton]: {
    policyName: raterPolicies.manage,
    contractorAccess: false,
  },
  [RATER_ELEMENTS.editRater]: {
    policyName: raterPolicies.manage,
    contractorAccess: false,
  },
  [RATER_ELEMENTS.deleteRater]: {
    policyName: raterPolicies.manage,
    contractorAccess: false,
  }
});

export {ratersPageRules, RATER_ELEMENTS};

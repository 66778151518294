import {ROLES} from '../constants/allConstants';

const COMPANY_ELEMENTS = {
  companyInfo: 'companyInfoView',
  financeInfoView: 'financeInfoView',
  financeInfoEdit: 'financeInfoEdit',
  editPaymentMethod: 'editPaymentMethod',
  companySettings: 'companySettings',
  companySettingsEdit: 'companySettingsEdit',
  ownerInfo: 'ownerInfo',
  workersInfo: 'workersInfo',
  serviceTitanInfo: 'serviceTitanInfo',      // Thanh 01/04/24
  serviceTitanEmp: 'serviceTitanEmp',
  serviceTitanNotEmp: 'serviceTitanNotEmp',
  serviceTitanJob: 'serviceTitanJob',
  inviteButton: 'inviteButton',
  editButton: 'editButton',
  inviteCompany: 'inviteCompany',
  editStatus: 'editStatus',
  impersonate: 'impersonate',
  depersonateButton: 'depersonateButton',
  verify: 'verify',
  allCompaniesNavLink: 'allCompaniesLink',
  companyNavLink: 'companyNavLink',
};

const companyPolicies = {
  modify: 'company.modify',
  verify: 'company.verify',
  manageState: 'company.manageState',
  impersonate: 'company.impersonate',
  viewFinance: 'company.viewFinance',
  editFinance: 'company.editFinance',
};

const contractorCompanyRules = Object.freeze({
  [COMPANY_ELEMENTS.companyInfo]: [ROLES.COMPANY_ADMIN, ROLES.CONTRACTOR],
  [COMPANY_ELEMENTS.ownerInfo]:[ROLES.COMPANY_ADMIN],
  [COMPANY_ELEMENTS.workersInfo]: [ROLES.COMPANY_ADMIN, ROLES.CONTRACTOR],
  [COMPANY_ELEMENTS.serviceTitanInfo]: [ROLES.COMPANY_ADMIN],    // Thanh 01/04/24
  [COMPANY_ELEMENTS.serviceTitanEmp]: [ROLES.COMPANY_ADMIN],    // Thanh 01/04/24
  [COMPANY_ELEMENTS.serviceTitanNotEmp]: [ROLES.COMPANY_ADMIN],    // Thanh 01/04/24
  [COMPANY_ELEMENTS.serviceTitanJob]: [ROLES.COMPANY_ADMIN],    // Thanh 01/04/24
  [COMPANY_ELEMENTS.editButton]: [ROLES.COMPANY_ADMIN],
  [COMPANY_ELEMENTS.companyNavLink]: [ROLES.COMPANY_ADMIN, ROLES.CONTRACTOR],
  [COMPANY_ELEMENTS.editPaymentMethod]: [ROLES.COMPANY_ADMIN],
});

const companyRules = Object.freeze({
  [COMPANY_ELEMENTS.companyInfo]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.companyInfo],
  },
  [COMPANY_ELEMENTS.ownerInfo]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.ownerInfo],
  },
  [COMPANY_ELEMENTS.workersInfo]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.workersInfo],
  },
  // Thanh 01/04/24
  [COMPANY_ELEMENTS.serviceTitanInfo]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.serviceTitanInfo],
  },
  // Thanh 01/04/24
  [COMPANY_ELEMENTS.serviceTitanEmp]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.serviceTitanEmp],
  },
  // Thanh 01/04/24
  [COMPANY_ELEMENTS.serviceTitanNotEmp]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.serviceTitanNotEmp],
  },
  [COMPANY_ELEMENTS.serviceTitanJob]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.serviceTitanJob],
  },
  [COMPANY_ELEMENTS.editButton]: {
    policyName: companyPolicies.modify,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.editButton],
  },
  [COMPANY_ELEMENTS.inviteCompany]: {
    policyName: companyPolicies.modify,
    contractorAccess: false,
  },
  [COMPANY_ELEMENTS.financeInfoView]: {
    policyName: companyPolicies.viewFinance,
    contractorAccess: false,
  },
  [COMPANY_ELEMENTS.financeInfoEdit]: {
    policyName: companyPolicies.editFinance,
    contractorAccess: false,
  },
  [COMPANY_ELEMENTS.companySettings]: {
    policyName: undefined,
    contractorAccess: false,
    hideWhenImpersonating: true,
  },
  [COMPANY_ELEMENTS.companySettingsEdit]: {
    policyName: companyPolicies.modify,
    contractorAccess: false,
  },
  [COMPANY_ELEMENTS.inviteButton]: {
    policyName: companyPolicies.modify,
    contractorAccess: false,
  },
  [COMPANY_ELEMENTS.editStatus]: {
    policyName: companyPolicies.manageState,
    contractorAccess: false,
  },

  [COMPANY_ELEMENTS.impersonate]: {
    policyName: companyPolicies.impersonate,
    contractorAccess: false,
    hideWhenImpersonating: true,
  },

  [COMPANY_ELEMENTS.depersonateButton]: {
    policyName: companyPolicies.impersonate,
  },

  [COMPANY_ELEMENTS.verify]: {
    policyName: companyPolicies.verify,
    contractorAccess: false,
  },
  [COMPANY_ELEMENTS.allCompaniesNavLink]: {
    policyName: undefined,
    contractorAccess: false,
    hideWhenImpersonating: true,
  },
  [COMPANY_ELEMENTS.companyNavLink]: {
    policyName: undefined,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.companyNavLink],
  },
  [COMPANY_ELEMENTS.editPaymentMethod]: {
    policyName: companyPolicies.editFinance,
    contractorAccess: true,
    contractorPermissions: contractorCompanyRules[COMPANY_ELEMENTS.editPaymentMethod],
  }


});

export {companyRules, COMPANY_ELEMENTS};

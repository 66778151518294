const PATH = {
  HOME: '/',
  LOGIN: '/login',
  ANALYTICS: '/analytics',
  COMPANY: '/company',
  ALL_COMPANIES: '/all_companies',
  CREATE_JOB: '/create_job',
  JOBS: '/jobs',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  USER: '/user',
  SIGN_UP: '/signup',
  RESET_PASSWORD: '/resetPassword',
  ACCESS_DENIED: '/access_denied',
  NO_ACCESS: '/no_access',
  SERVER_REDIRECT: '/serverRedirect',
  SUB_ROUTE_EDIT: 'edit',
  COMPANY_BLOCKED: '/access_blocked',
  ADMIN_PAGE: '/admin',
  ADMIN_PAGE_USER: '/admin/user',
  ANALYTICS_PERMIT: 'permits',
  ANALYTICS_HERS: 'hers',
  ANALYTICS_FINANCE: 'finance',
  REGISTER_COMPANY: '/register_company',
  PRODUCTS: '/products',
  TAGS: '/tags',
  COMPANY_EXISTS: '/registration_reject',
  COMPANY_PENDING: '/company_under_review',
  MODULE: '/module',
  MODULE_RECORD: '/module/:id',
  CITIES: '/cities',
  CITY: '/cities/:id',
  ADD_CITY: '/add_city',
  CHEERS_CONNECTION_REQUIRED: '/connect-to-cheers',
  RATERS: '/raters',
  RATER: '/raters/user',
  INSPECTION_SCHEDULE: '/inspection-schedule',
};

export default PATH;

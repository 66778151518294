const ELEMENTS_NOTES = {
  createInternalNote: 'createInternalNote',
  addInspectionNote: 'addInspectionNote',
  addPermitNote: 'addPermitNote',
  addHersNote: 'addHersNote',
  addSchedulingNote: 'addSchedulingNote',
  addNotes: 'addNotes',
};

const notesPolicies = {
  addPermitNote: 'permitNote.create',
  addInspectionNote: 'inspectionNote.create',
  addHersNote: 'hersNote.create',
  addInternalNote: 'internalNotes.create',
  addSchedulingNote: 'schedulingNote.create',
};

const notesRules = Object.freeze({
  [ELEMENTS_NOTES.createInternalNote]: {
    policyName: notesPolicies.addInternalNote,
  },
  [ELEMENTS_NOTES.addInspectionNote]: {
    policyName: notesPolicies.addInspectionNote,
  },
  [ELEMENTS_NOTES.addPermitNote]: {
    policyName: notesPolicies.addPermitNote,
  },
  [ELEMENTS_NOTES.addHersNote]: {
    policyName: notesPolicies.addHersNote,
  },
  [ELEMENTS_NOTES.addSchedulingNote]: {
    policyName: notesPolicies.addSchedulingNote,
  },
  [ELEMENTS_NOTES.addNotes]: {
    policyName: [],
  }
});

export {notesRules, ELEMENTS_NOTES, notesPolicies};

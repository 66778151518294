import * as STYLE_CONSTANTS from './styleConstants';

export const SCROLLBAR_STYLES = {
  '*::-webkit-scrollbar': {
    width: '0.2em',
    height:'0.5em'
  },
  '*::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
    borderRadius: '4px'
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useJob} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import {Box, IconButton} from '@mui/material';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {JobTypeAndNumber, StatusCard} from './headerComponents';
import {Text, TextWithIconWrapper} from '../../../pieces/text';
import {ButtonSplit} from '../../../pieces/printButton';
import {useNavigate} from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Spacer from '../../../pieces/spacer';
import {CustomTag} from '../../../pieces/tag';
import {ReactComponent as UserIconSvg} from '../../../static/images/user.svg';
import {ReactComponent as LocationPinIconSvg} from '../../../static/images/locationPin.svg';
import {ReactComponent as CalendarIconSvg} from '../../../static/images/calendar.svg';
import Utils from '../../../utils/utils';
import {printJobDetails} from '../../../utils/print';
import {canDoInspection, orderInspection} from '../helpers/requestCityInspection';
import {NewPermissionGate} from '../../../permissions/permissionGate';
import {jobRules, IPERMIT_JOB_ELEMENTS} from '../../../permissions/ipermitAccessControl/index';
import {urlSearchParams} from '../../../baseUrls';
import {companyRules} from '../../../permissions/companyAccessControl';
import actions from '../../../redux/actions';
import {jobApiTypes} from '../helpers/jobGridOptions';
import LOCAL_STORAGE_KEYS from '../../../utils/localStorageKeys';


export const IpermitRecordHeader = (props)=> {
  const {job, jobHasInspection, setJob} = useJob();
  const {JOB_FIELDS, INSPECTION_FIELDS} = allConstants;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const currentModule = Utils.getUrlParameter(urlSearchParams.moduleName);

  const goBack = ()=> {

    // Thanh 01/01/24
    window.close();
    
    //navigate(props.backPath);
  };

  const getLink = (module, submodule)=> {
    const link = `/${urlSearchParams.module}/${job[JOB_FIELDS.ID.api_name]}?${urlSearchParams.moduleName}=${module}`;
    if(submodule) {
      return `${link}&${urlSearchParams.submodule}=${submodule}`;
    }
    return link;
  };

  const getStatues = ()=> {
    const permitStatus = {name: 'Permit',
      isActive: currentModule === allConstants.IPERMIT_MODULES.PERMIT,
      statuses: [
        {status: job[JOB_FIELDS.PERMIT_STATUS.api_name],
          date: job?.[JOB_FIELDS.PERMIT_STATUS_CHANGE_DATE.api_name]}
      ],
      url: getLink(allConstants.IPERMIT_MODULES.PERMIT, allConstants.IPERMIT_MODULES.ALL_JOBS)
    };

    const inspectionStatus = {name: 'Inspection', subName: job[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.TYPE.api_name] || null,
      isActive: props.module === allConstants.IPERMIT_MODULES.INSPECTION,
      statuses: [
        {status: job[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.INSPECTION_STATUS.api_name],
          date: job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.INSPECTION_STATUS_CHANGE_DATE.api_name]}
      ],
      url: getLink(allConstants.IPERMIT_MODULES.INSPECTION)
    };
    const hersStatus = {name: 'HERS',
      isActive: props.module === allConstants.IPERMIT_MODULES.HERS,
      statuses: [
        {status: job[JOB_FIELDS.HERS_STATUS.api_name],
          date: job?.[JOB_FIELDS.HERS_STATUS_CHANGE_DATE.api_name]}
      ],
      // url: getLink(allConstants.IPERMIT_MODULES.HERS)
    };
    const mailingStatus = {name: 'Mailing',
      isActive: props.module === allConstants.IPERMIT_MODULES.MAILING,
      statuses: [
        {status: job[JOB_FIELDS.MAILING_STATUS.api_name],
          date: job?.[JOB_FIELDS.MAILING_STATUS_CHANGE_DATE.api_name]}
      ],
      // url: getLink(allConstants.IPERMIT_MODULES.MAILING)
    };

    const statuses = [permitStatus];

    if(job?.[JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name]){
      statuses.push(hersStatus);
    }

    if(jobHasInspection()) {
      statuses.push(inspectionStatus);
    }

    statuses.push(mailingStatus);
    return statuses;
  };

  const statuses = getStatues();


  const getActions = ()=> {
    const buttonActions = [{name: 'print order', action: ()=>printJobDetails({...job})}];

    if(NewPermissionGate({
      user: user,
      elementPermissions: jobRules[IPERMIT_JOB_ELEMENTS.requestInspection],
    })) {
      buttonActions.push({name: 'order inspection',
        action: ()=>orderInspection(job?.[allConstants.JOB_FIELDS.ID.api_name], (inspectionData)=> {
          setJob({
            ...job, ...{
              [allConstants.INSPECTION_FIELDS.INSPECTION.api_name]: inspectionData
            }
          });
        }),
        disabled: jobHasInspection(job) || !canDoInspection(job)
      });
    }
    if(NewPermissionGate({
      user: user,
      elementPermissions: companyRules.impersonate,
    })){
      buttonActions.push({name: 'impersonate',
        action: async ()=> {
          const companyId = job?.company.id;
          if(companyId) {
            const result = await dispatch(actions.impersonate(companyId));
            if(result?.payload?.company?.[allConstants.COMPANY_FIELDS.ID.api_name]){
              const currentPath = window.location.pathname;
              const currentSearch = window.location.search;
              console.log('currentPath', window.location);
              localStorage.setItem(LOCAL_STORAGE_KEYS.depersonateGoBackUrl, currentPath+currentSearch);
              navigate(`${allConstants.PATH.JOBS}?${urlSearchParams.view}=${jobApiTypes.all}&${urlSearchParams.jobId}=${job[JOB_FIELDS.ID.api_name]}`);
            }
          }
        }});
    }


    return buttonActions;
  };

  return(
    <Box sx={{

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around'
    }}>
      <Box sx={{
        paddingX: {xs: '8px', xl: '12px'},
        paddingY: '2px',
        display: 'grid',
        gridTemplateColumns: '200px 1fr 200px!important',
        backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
        '.MuiTypography-root': {
          color: STYLE_CONSTANTS.COLORS.white
        }
      }}>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton onClick={goBack} sx={{
            height: '40px',
            paddingX: '0px',
            justifyContent: 'flex-start',
            color: STYLE_CONSTANTS.COLORS.white,
            '&:hover': {
              transform: 'scale(1.1)'
            }
          }}>
            <KeyboardBackspaceIcon/>

          </IconButton>
          <Spacer x={2}/>

          {job?.[allConstants.JOB_FIELDS.SERVICE_TITAN_ID.api_name] && <CustomTag text={'Service Titan'} color={STYLE_CONSTANTS.TAG_COLORS.orange}/>}
        </Box>

        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <JobTypeAndNumber job={job} ipermitView={true}/>
        </Box>

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '& .MuiButton-root': {
            backgroundColor: STYLE_CONSTANTS.COLORS.white,
            color: STYLE_CONSTANTS.COLORS.globalBlue,
          }
        }}>
          <ButtonSplit options={getActions()}/>
        </Box>
      </Box>
      <Box sx={{
        padding: {xs: '8px', xl: '12px'},
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        columnGap: '16px',
      }}>
        <Box>
          <Text text={job.company.name} weight={{xs: 600}} fontWeight={'bold'}/>
          {/*assigned city*/}
          <Box display={'flex'} gap={1}>
            <TextWithIconWrapper icon={<LocationPinIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>

              <Text text={`${
                (()=>{
                  const city = job?.[allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name]?.[allConstants.CITY_FIELDS.NAME.api_name];
                  if(!city) return 'No city assigned';
                  return `${city}, ${job?.address?.state}`;
                })()
              }`}/>
            </TextWithIconWrapper>
          </Box>


        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Box sx={{
            width: 'fit-content',
          }} >
            <Box>
              <TextWithIconWrapper icon={<CalendarIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                <Text text={Utils.formatIsoTimeStringWithOptions(job?.[JOB_FIELDS.CREATE_TIME.api_name], {
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit'

                })} size={'small'}/>
              </TextWithIconWrapper>
              <Spacer y={0.5}/>
              <TextWithIconWrapper icon={<UserIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                <Text text={`${
                  (()=>{
                    const orderedByUser = job?.[JOB_FIELDS.ORDERED_BY.api_name];
                    if(!orderedByUser) return '-';
                    return `${orderedByUser.firstName} ${orderedByUser.lastName}`;
                  })()
                }`} size={'small'}/>
              </TextWithIconWrapper>

            </Box>

          </Box>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} columnGap={2} justifyContent={'center'}>
        {statuses.map((module, index)=> {
          return (
            <StatusCard key={index+module.name} header={module.name}
              statuses={module.statuses}
              active={module.isActive}
              subName={module?.subName}
              url={module?.url}
            />
          );
        })}
      </Box>
    </Box>

  );
};

IpermitRecordHeader.propTypes = {
  module: PropTypes.string.isRequired,
  // backPath: PropTypes.string.isRequired
};

import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';

const useMessageViewStyles = makeStyles(theme=> {
  return {
    text: {
      textTransform: 'capitalize',
      letterSpacing: '-0.02em',
      lineHeight: '20px',
    },
    messageBorder: {
      boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.03)',
      borderRadius: '6px 6px 6px 0px',
      border: `1px solid ${STYLE_CONSTANTS.COLORS.lightGray}`,
    },
    messageIcon: {
      boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.03)',
      borderRadius: '50px',
      backgroundColor: '#EDF2FF',
      height: '38px',
      width: '38px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

export {useMessageViewStyles};


import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {urlSearchParams} from '../../baseUrls';
import CustomIcon from '../../static/icons/Icon';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Utils from '../../utils/utils';

const NavbarLink = (props)=> {
  const currLocation = useLocation().pathname;
  const module = Utils.getUrlParameter(urlSearchParams.moduleName);

  const isActive = props.isSublink ? props.isActive : (currLocation.indexOf(props.path) !== -1 && currLocation !== '/') || (module && props.path.indexOf(module) !== -1);

  return (
    <Button  onDragStart={(e)=> e.preventDefault() }
      disableRipple={true}
      style={{color: isActive ?  STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.fontPale}} variant="text"
      startIcon={ props.icon ?

        <CustomIcon
          icon={props.icon}
          color={isActive ? STYLE_CONSTANTS.COLORS.globalGreen : STYLE_CONSTANTS.COLORS.fontPale}
          fill={'none'}
          alt={props.alt}
        /> : null

      }
      sx={{
        paddingLeft: '0px',
        paddingRight: '0px',
      }}
      component={Link} to={`${props.path}${props.params}`}>
      {props.text}
    </Button>
  );
};

NavbarLink.propTypes = {
  alt: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  params: PropTypes.string,
  isSublink: PropTypes.bool,
  isActive: PropTypes.bool,
};

NavbarLink.defaultProps = {
  params: '',
  isSublink: false,
  isActive: false,
  icon: null,
};

export {NavbarLink};

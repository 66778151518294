import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {Box} from '@mui/material';
import Utils from '../../utils/utils';
import {JobDisplayNotes} from '../../pieces/jobDisplayNotes';
import {scrollStyles} from '../jobs/allJobs/allJobsViewStyles';
import {useForm, useWatch} from 'react-hook-form';
import Loader from '../misc/loader';
import {toast} from 'react-toastify';
import ApiUtils from '../../utils/apiUtils';
import {AddNoteForm} from '../../pieces/noteElements/addNoteForm';
import {NoDataStringWrapper} from '../../pieces/noDataStringWrapper';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {CITY_ELEMENTS, cityRules} from '../../permissions/cityAccessControl';

export const CityNotesContent = (props) => {
  const {notes, role, companyId} = props;

  const [submitting, setSubmitting] = useState(false);

  const checkAndReturnNoContent = ()=> {
    if(!notes || notes.length === 0) {
      return <NoDataStringWrapper text={'No notes added'}/>;
    }
  };

  const formOptions = {
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const formMethods = useForm(formOptions);

  const watchNote = useWatch({
    control: formMethods.control,
    name: allConstants.JOB_FIELDS.NOTE.TEXT.api_name,
  });

  if(Utils.isDevEnv()) {
    window.noteForm = formMethods;
  }

  const handleSubmitResponse = (result)=> {
    const toastOptions = {
      toastId: 'add_note_response',
      autoClose: 1000,
    };

    if(result.status === 'success') {
      const updatedNotes = result?.data?.city?.notes;
      formMethods.reset();
      toast.success('City note added', toastOptions);
      props.successCallback(updatedNotes);
    } else{
      toast.error(`Error on adding note: ${result?.message}`, toastOptions);
    }
    Utils.sleep(1000).then(()=> {
      setSubmitting(false);
    });
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    const dataToSubmit = {
      cityId: props.cityId,
      note: data[allConstants.JOB_FIELDS.NOTE.TEXT.api_name],
    };
    const result = await ApiUtils.makeApiCall('POST', 'cities/add-note', JSON.stringify(dataToSubmit));
    handleSubmitResponse(result);
  };


  return (
    <>
      <Box sx={{
        position: 'relative',
        border: `1px solid ${STYLE_CONSTANTS.COLORS.lightGray}`,
        borderRadius: '20px',
        padding: submitting ? '0' : {xs: '4px', md: '8px', xl: '16px'},
        maxHeight: '400px',
        overflowY: 'scroll',
        ...scrollStyles,
      }}>
        {checkAndReturnNoContent()}
        <Box sx={{
          position: 'absolute',
          top:0,
          left:0,
          right:0,
          bottom:0,
          display: submitting? 'initial' : 'none',
          zIndex: 100,
          padding: 0,
          backgroundColor: STYLE_CONSTANTS.COLORS.white
        }}>
          <Loader useOwnWrapper/>
        </Box>
        <JobDisplayNotes jobNotes={notes} defaultReturn={null}/>
        <AddNoteForm formMethods={formMethods} color={STYLE_CONSTANTS.COLORS.globalBlue}
          onSubmitAction={onSubmit}
          userAllowedToAddNote={NewPermissionGate({
            role: role,
            companyId: companyId,
            elementPermissions: cityRules[CITY_ELEMENTS.createCity]
          })}
          submitDisabled={!watchNote || watchNote.length === 0}/>
      </Box>
    </>
  );
};

CityNotesContent.propTypes = {
  notes: PropTypes.instanceOf(Array),
  cityId: PropTypes.string.isRequired,
  successCallback: PropTypes.func,
  role: PropTypes.string,
  companyId: PropTypes.string,
};

CityNotesContent.defaultProps = {
  notes: [],
  successCallback: ()=> {},
  role: undefined,
  companyId: undefined,
};

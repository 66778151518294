import {Box, Typography} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import HalfPieChart from './halfPieChart';

const AnalyticsHalfPieCard = (props) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-around'} height={'inherit'}>
      <HalfPieChart value_1={Number(props.value_1)} value_2={Number(props.value_2)}/>
      <Box display={'flex'} flexDirection={'row'} gap={4}>
        <Box>
          <Typography color={STYLE_CONSTANTS.COLORS.fontDarkBlue} fontSize={36} fontWeight={500} textAlign={'center'}>{props.value_1}</Typography>
          <Box display={'flex'} gap={1} alignItems={'center'} justifyContent={'center'}>
            <Box sx={{
              borderRadius: '50%',
              height: '15px',
              width: '15px',
              backgroundColor: `${STYLE_CONSTANTS.COLORS.globalBlue}`,
            }}/>
            <Typography>{props.text_1}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography color={STYLE_CONSTANTS.COLORS.fontDarkBlue} fontSize={36} fontWeight={500} textAlign={'center'}>{props.value_2}</Typography>
          <Box display={'flex'} gap={1} alignItems={'center'} justifyContent={'center'}>
            <Box sx={{
              borderRadius: '50%',
              height: '15px',
              width: '15px',
              backgroundColor: `${STYLE_CONSTANTS.COLORS.globalGreen}`,
            }}/>
            <Typography>{props.text_2}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AnalyticsHalfPieCard.propTypes = {
  value_1: PropTypes.string,
  value_2: PropTypes.string,
  text_1: PropTypes.string,
  text_2: PropTypes.string,
};
AnalyticsHalfPieCard.defaultProps = {
  value_1: '0',
  value_2: '0',
  text_1: '',
  text_2: '',
};

export default AnalyticsHalfPieCard;

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import {AlpineContainer} from './gridContainer';
require('./grid-styles.scss');


const MyGrid = (props) => {

  const defaultColDef = useMemo( ()=> ({
    flex: 1,
    filter: true,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    suppressMenu: true
  }), []);

  const onColumnMoved =(params)=> {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem(props.ls_name, columnState);

  };
  const onGridReady = (params)=> {
    props.registerGrid(params);
    let columnState = JSON.parse(localStorage.getItem(props.ls_name));

    if (columnState) {
      params.columnApi.applyColumnState({state:columnState, applyOrder: true});
    }
  };

  const updateColumnOrder = ()=> {
    if(!props.ls_name) return props.columnDefs;
    const columnSavedState = JSON.parse(localStorage.getItem(props.ls_name));
    try{
      if(columnSavedState) {
        let newColumnDef = new Array(props.columnDefs.length);
        props.columnDefs.forEach((el, i)=> {
          //console.log('el:::', el);
          const index = columnSavedState.findIndex((e)=> e.colId === el.field);
          newColumnDef[index] = el;
        });
        return newColumnDef;
      } else {
        return props.columnDefs;
      }
    } catch(e) {
      return props.columnDefs;
    }
  };


  const getGridDefaultOptions =()=> {
    return {
      reactUi: 'true',
      className: 'ag-theme-alpine',
      animateRows: 'true',
      onColumnMoved: onColumnMoved,
      defaultColDef: defaultColDef,
      enableCellTextSelection: true,
      rowSelection: 'multiple',
      suppressHorizontalScroll: false,
    };
  };

  return (
    <AlpineContainer>

      <AgGridReact
        {...getGridDefaultOptions()}
        getContextMenuItems={props.contextMenu}
        columnDefs={updateColumnOrder()}
        rowData={props.rowData}
        frameworkComponents={props.components}
        onGridReady={onGridReady}
        // onDragStopped={(p)=> {
        //   const colIds = p.columnApi.getAllDisplayedColumns().map(col => col.colId)
        //   console.log(colIds)
        // }}

        // onSortChanged={onSortChange}
        // onFilterChanged={onFilterChanged}
        //bodyScroll = {log}

        // onComponentStateChanged={()=> {console.log('onComponentStateChanged')}}
      />
    </AlpineContainer>
  );
};

MyGrid.propTypes = {
  ls_name: PropTypes.string.isRequired,
  columnDefs: PropTypes.instanceOf(Object).isRequired,
  contextMenu: PropTypes.instanceOf(Array).isRequired,
  rowData: PropTypes.instanceOf(Array).isRequired,
  components: PropTypes.instanceOf(Array),
  registerGrid: PropTypes.func.isRequired,
};

MyGrid.defaultProps = {
  components: [],
};
export default MyGrid;
import React, {useEffect, useState} from 'react';
import * as allConstants from '../../constants/allConstants';
import Utils from '../../utils/utils';
import {urlSearchParams} from '../../baseUrls';
import {RecordController} from '../../hooks/recordController';
import {RecordBodyWrapper} from '../wrappers/recordBodyWrapper';
import {RecordWrapper} from '../wrappers/recordWrapper';
import {RecordHeader} from '../../pieces/recordHeader';
import {Box, Button, Typography} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {COLORS} from '../../styles/styleConstants';
import EditFormButton from '../../pieces/editFormButton';
import {UserInfoPanel} from './panels/userInfoIpermit';
import ApiUtils from '../../utils/apiUtils';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Spacer from '../../pieces/spacer';
import {roleTranslator} from '../../utils/translator';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Text} from '../../pieces/text';
import {useGotIt} from '../../hooks/useGotIt';
import {IpermitPermissionsPanel} from './panels/permissionsIpermit';
import actions from '../../redux/actions';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {ADMIN_ELEMENTS, ipermitAdminRules} from '../../permissions/ipermitAdminAccessControl';
import {NewPermissionGate} from '../../permissions/permissionGate';


export const IpermitUserView = () => {
  const userId = Utils.getUrlParameter(urlSearchParams.userId);
  const dispatch = useDispatch();

  const isInvite = !userId;

  const [viewMode, setViewMode] = useState(!isInvite);
  const [userData, setUserData] = useState({});
  const {USER_FIELDS} = allConstants;
  const [submitting, setSubmitting] = useState(false);
  const gotIt = useGotIt();
  const navigate = useNavigate();
  const currentUser = useSelector(state=> state.user);


  useEffect(async ()=> {
    if(!userId) return;
    const usedData = await ApiUtils.makeApiCall('GET', `users/${userId}`);
    setUserData(usedData?.data.user);
  }, []);

  useEffect(async ()=> {
    if(viewMode && userId) {
      const usedData = await ApiUtils.makeApiCall('GET', `users/${userId}`);
      setUserData(usedData?.data.user);
      return;
    }
    if(viewMode && isInvite) {
      setViewMode(true);
      navigate(getBackPath());
    }
  }, [viewMode]);

  useEffect(()=> {
    if(isInvite && userData?.id){
      navigate(`${allConstants.PATH.ADMIN_PAGE_USER}${location.search}&${urlSearchParams.userId}=${userData.id}`);
    }
  }, [userData]);

  const getValue = (field)=> {
    return userData?.[field.api_name]||'';
  };

  const switchToEdit = ()=> {

    const location = Utils.getUrlParameter('open');
    const tabs = getTabs();

    const tab = Object.values(tabs).find((tab)=> tab.api_name === location);
    if(canPerformAction(tab.permissions)) {
      setViewMode(false);
    } else {
      gotIt.setShow(allConstants.ERROR_MSG.NO_PERMISSION('edit', tab?.name || 'user'));
    }
  };

  const onCancel = async (e)=> {
    e.preventDefault();
    setViewMode(true);
    setViewMode(true);
  };

  const tabNames = {
    userInfo: 'User Info',
    userPermissions: 'Permissions',
  };

  const panelProps = {
    viewMode: viewMode,
    setViewMode: setViewMode,
    userData: userData,
    setUserData: setUserData,
    setSubmitting: setSubmitting,
    isInvite: isInvite,
  };

  const getTabs = ()=> {
    return {
      userInfo: {
        name: tabNames.userInfo,
        api_name: 'user_info',
        order: 0,
        panel: <UserInfoPanel {...panelProps}/>,
        fieldsToValidate: [],
        permissions: ipermitAdminRules[ADMIN_ELEMENTS.manageUsers]
      },
      userNotifications: {
        name: tabNames.userPermissions,
        api_name: 'user_permissions',
        order: 1,
        panel: <IpermitPermissionsPanel {...panelProps}/>,
        fieldsToValidate: [],
        permissions: ipermitAdminRules[ADMIN_ELEMENTS.controlPermissions]
      }
    };
  };

  const getCurrentTabs = ()=> {
    const tabs = getTabs();
    const currentTabs = {};
    for (const [key, value] of Object.entries(tabs)) {
      currentTabs[key] = value;
    }
    return currentTabs;
  };

  const headerHeight = '66px';

  const getBackPath = ()=> {
    const items = Utils.getUrlParameter('items');
    const page = Utils.getUrlParameter('page');
    let path = `${allConstants.PATH.ADMIN_PAGE}?items=${items||100}&page=${page||1}`;
    return path;
  };

  const deactivateUser = async () => {
    let successMessage = '';
    let errorMessage = '';
    const isDisabled = isUserDisabled();
    if(currentUser.id === userData.id) {
      gotIt.setShow(allConstants.ERROR_MSG.CAN_NOT_DO_FOR_YOURSELF(isDisabled ? 'activate' : 'deactivate'));
      return;
    }

    if(isDisabled) {
      successMessage = `Activated: ${userData?.[allConstants.USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[allConstants.USER_FIELDS.LAST_NAME.api_name]}.`;
      errorMessage = 'Error on activation';
    } else {
      successMessage = `Deactivated: ${userData?.[allConstants.USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[allConstants.USER_FIELDS.LAST_NAME.api_name]}.`;
      errorMessage = 'Error on deactivation';
    }
    const result = isUserDisabled() ? await dispatch(actions.activateUser({userId: userData[USER_FIELDS.ID.api_name]}))
      : await dispatch(actions.deactivateUser({userId: userData[USER_FIELDS.ID.api_name]}));
    unwrapRequestResult({
      result: result,
      successMessage: successMessage,
      errorMessage: errorMessage,
      showSuccess: true,
    });
    if(!result?.payload?.user) return;
    setUserData({...userData, ...result?.payload?.user});

  };

  const isUserDisabled= ()=> {
    return userData?.[allConstants.USER_FIELDS.STATUS.api_name] === allConstants.USER_STATUSES.DISABLED.value;
  };

  const getWarningMessage = ()=> {
    if(isInvite) {
      return (
        <>
          <Text text={'Please invite user first'} sizePx={14}/>
        </>
      );
    }
    return (
      <>
        <Text text={'You have unsaved changes'} sizePx={14}/>
        <Text text={'Please save or cancel editing first'} sizePx={14}/>
      </>
    );
  };

  const canPerformAction = (action)=> {
    return NewPermissionGate({
      user: currentUser,
      elementPermissions: action
    });
  };

  return(
    <>
      {gotIt.PopUp()}

      <RecordController
        recordId={userId || null}
        module={'users'}
        responseTarget={'user'}
        saveRecord={(data)=>setUserData(data)}
        viewMode={viewMode}
      >
      </RecordController>
      <RecordWrapper
        hideNavbar={false}
        headerHeight={headerHeight}
        headerPadding={'0px'}

        header={
          <Box display={'flex'} flexDirection={'row'} id={'headercontainer'} justifyContent={'center'} sx={{
            width: '100%',
            paddingTop: '16px',
            backgroundColor: COLORS.lightGreen

          }}>
            <Box display={'flex'} flexDirection={'row'} width={'100%'} height={headerHeight}>
              <Box display={'flex'}
                width={'calc(100% - 110px)'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={0.5}

              >
                <RecordHeader
                  text={isInvite ? 'Invite user' :`${getValue(USER_FIELDS.FIRST_NAME)} ${getValue(USER_FIELDS.LAST_NAME)}`}
                  headerHeight={headerHeight}
                >
                  <StatusRenderer value={getValue(USER_FIELDS.STATUS)}/>
                </RecordHeader>
                {getValue(USER_FIELDS.EMAIL) &&
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <EmailOutlinedIcon sx={{color: COLORS.globalBlue, marginRight: '8px'}}/>
                  <Typography component={'span'} sx={{
                    fontStyle: 'italic'
                  }}>{getValue(USER_FIELDS.EMAIL)}</Typography>
                  <Spacer x={2}/>
                  <Typography component={'span'} sx={{
                    fontWeight: 'bold',
                    color: COLORS.globalBlue
                  }}>Role:</Typography>
                  <Spacer x={1}/>
                  <Typography component={'span'}>
                    {roleTranslator.translate(getValue(USER_FIELDS.ROLE))}
                  </Typography>
                </Box>}
              </Box>
              <Box display={'flex'} sx={{width:'350px', height: 'fit-content'}} alignItems={'center'} justifyContent={'flex-end'}>
                {canPerformAction(ipermitAdminRules[ADMIN_ELEMENTS.manageUsers]) && <Button variant={viewMode ? 'contained' : 'outlined'}
                  color={'primary'}
                  sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                    '&.MuiButton-root': {
                      textTransform: 'uppercase'
                    },
                    '&.MuiButton-containedPrimary': {
                      backgroundColor: STYLE_CONSTANTS.COLORS.globalRed,
                    },
                    '&:hover': {
                      color: STYLE_CONSTANTS.COLORS.white,
                    },
                  }}
                  onClick={deactivateUser}
                  disabled={!viewMode}
                >{isUserDisabled() ? 'Activate' : 'Disable'}</Button>}
                <EditFormButton onClick={viewMode ? switchToEdit : onCancel}
                  onCancel={onCancel}
                  edit={viewMode}

                />

              </Box>
            </Box>
          </Box>
        }
      >
        <Spacer y={2}/>
        <RecordBodyWrapper
          tabs={getCurrentTabs()}
          headerHeight={headerHeight}
          goBack={{
            show: true,
            path: getBackPath()
          }}
          showLoader={submitting}
          preventSwitchTabsOnEdit={!viewMode}
          setSwitchTabsWarning={()=>gotIt.setShow(getWarningMessage())}
        />

      </RecordWrapper>

    </>

  );
};

import React, {useEffect, useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {Box, List, ListItem, Card, Button} from '@mui/material';
import {useEditRecord} from '../../../../../hooks/useEditRecord';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import {FormProvider} from 'react-hook-form';
import {useSelector} from 'react-redux';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import * as Yup from 'yup';
import {useJob} from '../../../../../contextProviders/jobContext';
import ApiUtils from '../../../../../utils/apiUtils';
import {toast} from 'react-toastify';
import {useSubform} from '../../../../../hooks/useSubform';
import {InspectionRequirementsForContractor} from '../../../../../pieces/inspectionRequirementsForContractor';
import Spacer from '../../../../../pieces/spacer';
import {WrappedHomeOwnerInfoRows} from '../../../components/homeownerInfoBlock';
import {CustomTable} from '../../../../../pieces/table';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import {TestResultSideEffectPopup} from './testResultSideEffectPopup';
import {NoDataStringWrapper} from '../../../../../pieces/noDataStringWrapper';
import {ReactComponent as AttachmentIconSvg} from '../../../../../static/images/attachmentIcon.svg';
import downloadFile from '../../../../../utils/downloadFileUtil';
import {NewPermissionGate} from '../../../../../permissions/permissionGate';
import {
  ELEMENTS_INSPECTIONS,
  inspectionRules
} from '../../../../../permissions/ipermitAccessControl/inspectionsAccessControl';
import {useGotIt} from '../../../../../hooks/useGotIt';


const InspectionTestResults = (props)=> {
  const classes = {...globalUseClasses()};
  const user = useSelector(state=>state.user);
  const gotItPopup = useGotIt();
  const {job, setJob, ...jobContext} = useJob();
  const {INSPECTION_FIELDS, JOB_FIELDS} = allConstants;
  const [currentResults, setCurrentResults] = useState(job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.INSPECTION_RESULTS.api_name] ||[]);
  const [showSideEffectPopup, setShowSideEffectPopup] = useState(false);
  const currDataToSubmit = useRef();


  useEffect(()=> {
    setCurrentResults(job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.INSPECTION_RESULTS.api_name]);
  }, [job]);

  const collectionName = useMemo(()=> INSPECTION_FIELDS.INSPECTION_RESULTS.api_name, []);

  const validationSchema = useMemo(()=>Yup.object().shape({
    [collectionName]: Yup.array().of(Yup.object().shape({
      [INSPECTION_FIELDS.RESULT.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
      [INSPECTION_FIELDS.TYPE.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
    }))
  }), []);

  const collectionRow = useMemo(()=>{return {
    [INSPECTION_FIELDS.DATE.api_name]: '',
    [INSPECTION_FIELDS.RESULT.api_name]:null,
    [INSPECTION_FIELDS.TYPE.api_name]:null,
    [INSPECTION_FIELDS.LOGGED_BY.api_name]: null,
    [INSPECTION_FIELDS.NOTE.api_name]:''
  };}, []);

  const {replace, commonFieldProps, fields, formMethods} = useSubform({
    collectionName: collectionName,
    validationSchema: validationSchema,
    subformRow: collectionRow,
    currentValues: [collectionRow],
    addRowText: 'Add test'
  });

  const  {edit, EditButton, cancelEdit} = useEditRecord({
    editText: 'add test result',
  });


  const userAllowedToEditInspection = useMemo(()=> {
    return NewPermissionGate({
      user: user,
      elementPermissions: inspectionRules[ELEMENTS_INSPECTIONS.manageInspection],
    });
  }, [user]);

  useEffect(()=> {
    if(edit && !userAllowedToEditInspection) {
      cancelEdit();
      gotItPopup.setShow(allConstants.ERROR_MSG.NO_PERMISSION('add inspection results'));
      return;
    }

    replace([collectionRow]);
  },[edit]);

  if(Utils.isDevEnv()) {
    window.testResults = formMethods;
  }

  const handleSubmitResponse = (result, message)=> {
    const toastOptions = {
      toastId: 'inspection_response',
      autoClose: 1000,
    };

    if(result.status === 'success') {
      try {
        const updatedJob = {...job,
          ...{[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]: result.data[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]}};
        const updatedTests = result?.data?.[INSPECTION_FIELDS.INSPECTION_RESULTS.api_name] || currentResults;
        updatedJob[allConstants.INSPECTION_FIELDS.INSPECTION.api_name][INSPECTION_FIELDS.INSPECTION_RESULTS.api_name] = updatedTests;
        setJob(updatedJob);
      } catch (e) {}


      cancelEdit();
      toast.success(message ? message : 'Inspection updated', toastOptions);
    } else{
      toast.error(`Inspection update error: ${result?.message}`, toastOptions);
    }
    setShowSideEffectPopup(false);
    currDataToSubmit.current = null;
  };

  const saveTestResults = async (data)=> {
    const dataToSubmit = {
      id: job?.[JOB_FIELDS.ID.api_name],
      ...data
    };
    const result = await ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/add-inspection-tests', JSON.stringify(dataToSubmit));

    handleSubmitResponse(result, 'Test results are successfully updated');
  };

  const onSubmit = async (data)=> {
    if(props.isContractor) return;
    const jobId = job?.[JOB_FIELDS.ID.api_name];
    const tests = data?.[INSPECTION_FIELDS.INSPECTION_RESULTS.api_name];

    const dataToSubmit = {
      id: jobId,
      tests: tests,
    };

    const addedTest = tests[tests.length - 1];

    if(addedTest && addedTest[INSPECTION_FIELDS.RESULT.api_name] === allConstants.RESULT_OPTIONS.PASSED.value
      && addedTest[INSPECTION_FIELDS.TYPE.api_name] === allConstants.INSPECTION_TYPES.ROUGH.value) {
      currDataToSubmit.current = dataToSubmit;
      setShowSideEffectPopup(true);
    } else {
      await saveTestResults(dataToSubmit);
    }
  };

  const gridTemplateColumns = props.isContractor ? '150px 1fr 1fr 1fr 3fr' : '150px 1fr 3fr';
  const gap = '16px';

  const getUserOrRaterName = (rowData)=> {
    const rater = rowData?.rater;
    if(rater && rater.name) {
      return rater.name;
    }
    else {
      const user = rowData[INSPECTION_FIELDS.LOGGED_BY.api_name];
      if(!user) return '';

      return `${user?.[allConstants.USER_FIELDS.FIRST_NAME.api_name]||''} ${user[allConstants.USER_FIELDS.LAST_NAME.api_name]}`;
    }
  };

  const getInspectionDate = (rowData)=> {
    const testDate = rowData?.performedOn;
    if(testDate) {
      return Utils.formatIsoDateString(testDate);
    }
    return Utils.formatIsoDateString(rowData[INSPECTION_FIELDS.DATE.api_name]);
  };

  const testHasAttachments = (rowData)=> {
    return rowData.some(item => typeof item === 'object' && item?.files);
  };


  const getRowAttachments = (rowData)=> {
    const rowDataFiles = rowData.find(item => typeof item === 'object' && item?.files)?.files;
    const attachments = [];
    if(rowDataFiles && rowDataFiles?.length > 0) {
      rowDataFiles.forEach(item => {
        attachments.push(item);
      });
    }
    const displayAttachments = attachments.map((attachment, index)=> {
      return (
        <Button key={`${attachment.fileId}-${index}`}
          type={'clear'}
          onClick={() => downloadFile({id: attachment.fileId})}>
          {attachment.fileName}
        </Button>
      );
    });
    if(displayAttachments.length === 0) return null;
    return (
      <Box display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        sx={{
          '& .MuiButton-root': {
            paddingLeft: '0px',
            paddingY: '0px',
          }
        }}>
        {displayAttachments}
      </Box>
    );
  };


  const createTestResultsTableData = ()=> {
    const pastResults = [...currentResults].reverse();
    const header = [
      INSPECTION_FIELDS.DATE.label,
      'Type',
      INSPECTION_FIELDS.RESULT.label,
      `${INSPECTION_FIELDS.LOGGED_BY.label}/RATER`,
      INSPECTION_FIELDS.NOTE.label
    ];
    const data = pastResults.map((row)=> {
      const rowData = [
        getInspectionDate(row),
        row[INSPECTION_FIELDS.TYPE.api_name],
        row[INSPECTION_FIELDS.RESULT.api_name],
        getUserOrRaterName(row),
        row[INSPECTION_FIELDS.NOTE.api_name],
      ];
      if(row?.file) {
        rowData.push({
          files: [{
            fileName: row.file.name,
            fileId: row.file.id,
          }],
        });
      }

      return rowData;
    });
    const columnWidths = [190, 100, 100, 230, null];
    return {
      tableHeader: header,
      tableData: data,
      columnWidths: columnWidths
    };
  };

  const deleteResultRow = (index)=> {
    console.log('will delete row', index);
  };

  const editResultRowAction = (index)=> {
    console.log('will edit row', index);
  };

  return(
    <>
      {gotItPopup.PopUp()}
      {showSideEffectPopup && <TestResultSideEffectPopup submitAction={saveTestResults} dataToSubmit={currDataToSubmit.current}/>}
      {props.isContractor && <>
        <InspectionRequirementsForContractor job={job} />
        <Spacer y={1} />
      </>
      }
      {!props.isContractor && !jobContext.inspectionWithOwnRater() && <Box position={'absolute'} right={0} zIndex={100}>
        {EditButton}
      </Box>}
      {!props.isContractor && <>
        <WrappedHomeOwnerInfoRows/>

        <Spacer y={2} />
      </>}
      {edit &&   <Card className={classes.card_block}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
            <List>
              {fields.map((item, index) => (
                <ListItem key={item.id+index} sx={{
                  display: 'grid',
                  gridTemplateColumns: gridTemplateColumns,
                  gap: gap,
                  alignItems: 'flex-start',
                  '& .MuiInputBase-inputMultiline': {
                    width: '100% !important',
                  }
                }}>
                  <CustomFormBlock {...commonFieldProps(index)}
                    showAsStatic={props.isContractor}
                    viewMode={!edit}
                    inputType={inputTypes.singleSelect}
                    options={Utils.getOptions(allConstants.INSPECTION_TYPES)}
                    isEditable={false}
                    defaultValue={job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]?.[allConstants.INSPECTION_FIELDS.TYPE.api_name]}
                    field={INSPECTION_FIELDS.TYPE}
                  />
                  <CustomFormBlock {...commonFieldProps(index)}
                    showAsStatic={props.isContractor}
                    viewMode={!edit}
                    inputType={inputTypes.singleSelect}
                    options={Utils.getOptions(allConstants.RESULT_OPTIONS)}
                    field={INSPECTION_FIELDS.RESULT}
                  />

                  <CustomFormBlock {...commonFieldProps(index)}
                    showAsStatic={props.isContractor}
                    multiline={true}
                    rowCount={1}
                    field={INSPECTION_FIELDS.NOTE}
                  />
                </ListItem>
              ))}
            </List>
            {edit && !props.isContractor && <FormSubmitButtons cancelAction={cancelEdit} submitText={'Update'}/>}
          </form>
        </FormProvider>
      </Card>
      }
      <Spacer y={2}/>
      {currentResults && currentResults.length !==0 &&
      <CustomTable tableName={'test results'}
        {...createTestResultsTableData()}
        isEditable={user.role === allConstants.ROLES.SYSTEM_ADMIN}
        deleteRowAction={deleteResultRow}
        editRowAction={editResultRowAction}
        expandable={true}
        expandableRowIcon={AttachmentIconSvg}
        isRowExpandable={(rowData)=>{
          return testHasAttachments(rowData);
        }}
        getExpandComponent={(rowData)=>{
          return getRowAttachments(rowData);
        }}
      />}
      {(!currentResults || currentResults.length ===0) && !edit &&
        <>

          <NoDataStringWrapper text={'No Test Results Added'}/>
        </>

      }

    </>
  );
};

InspectionTestResults.propTypes = {
  isContractor: PropTypes.bool,
};

InspectionTestResults.defaultProps = {
  isContractor: false,
};

export default InspectionTestResults;


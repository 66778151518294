import * as React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel} from '@mui/material';
import SimpleSwitch from '../simpleSwitch';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

export default function CustomizedSwitch(props) {
  const onChange=(e, flag)=> {
    props.action(flag);
  };
  return (
    <>
      <FormControlLabel control={<SimpleSwitch onChange={onChange} defaultChecked={props.defaultChecked}/>}
        label={props.label}
        disabled={props.disabled}
        sx={{'&.Mui-disabled': {
          '& .MuiSwitch-root': {
            '& .MuiSwitch-track': {
              backgroundColor: `${STYLE_CONSTANTS.COLORS.lightBlue} !important`,
            }
          }
        }}}

      />
    </>
  );
}

CustomizedSwitch.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomizedSwitch.defaultProps = {
  action: ()=>{},
  defaultChecked: false,
  disabled: false,
};

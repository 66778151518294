import {createAction, createReducer} from '@reduxjs/toolkit';

const addSubform = createAction('addSubform', function prepare(props) {
  return {
    payload: {
      jobType: props.jobType,
      subformName: props.subformName,
      subform: props.subform,
    },
  };
});

const addBulkSubforms = createAction('addBulkSubforms', function prepare(props) {
  return {
    payload: {
      jobType: props.jobType,
      subforms: props.subforms
    },
  };
});

const resetSubform = createAction('resetSubform', function prepare(props){
  return {
    payload: {
      jobType: props.jobType,
      subformName: props.subformName,
    },
  };
});
const resetAllSubforms = createAction('resetAllSubform');

const initialState = {};
export const subformSlice = createReducer(initialState, (builder) => {
  builder.addCase(addSubform, (state, action) => {

    const {jobType, subformName, subform} = action.payload;
    if(!state[jobType]) {
      state[jobType] = {};
    }
    if(subformName && subform) {
      state[jobType][subformName] = subform;
    }

    return state;
  });

  builder.addCase(addBulkSubforms, (state, action)=> {
    const {jobType, subforms} = action.payload;
    if(!state[jobType]) {
      state[jobType] = {};
    }
    state[jobType] = {...state[jobType], ...subforms};
    return state;

  });

  builder.addCase(resetSubform, (state, action) => {
    const {jobType, subformName} = action.payload;
    delete state[jobType][subformName];
    return state;
  });
  builder.addCase(resetAllSubforms, (state, action) => {
    return {};
  });

});

export const subformActions = {
  addSubform: addSubform,
  resetSubform: resetSubform,
  resetAllSubforms: resetAllSubforms,
  addBulkSubforms: addBulkSubforms,
};


import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';

const useJobDetailViewStyles = makeStyles(theme=> {
  return {
    details_block: {
      maxWidth: '400px',
      // maxWidth: {xs: '100%', sm: '320px', md: '400px'},
      marginBottom: '32px',
      // marginBottom: {xs: '8px', sm: '16px'},
    },
    tabs_style: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    tab_item_style: {
      border: 'solid',
      borderWidth: '1px 0 1px 1px',
      borderColor: STYLE_CONSTANTS.COLORS.lightGrayHover,
      '&.Mui-selected': {
        backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
        borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
        color: STYLE_CONSTANTS.COLORS.white,
      },
      '&:last-child': {
        borderWidth: '1px 1px 1px 1px',
      }
    },
    scrollable_tab_content: {
      width: '100%',
      height: 'calc(48vh - 16px)',
      paddingTop: '16px',
      overflow: 'hidden',
      overflowY: 'scroll',
      boxSizing: 'content-box'
    }
  };
});

export {useJobDetailViewStyles};

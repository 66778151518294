import React from 'react';
import {Link} from 'react-router-dom';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import NavBar from '../../pieces/navbar/navbar';
import {Grid, Paper, ThemeProvider, Box, Typography} from '@mui/material';
import myTheme from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import BlockedImage from '../../static/images/warningImage.png';

const AccessDenied= () => {
  const classes = globalUseClasses();
  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          <Grid container xs={12}  sx={{backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain}}>
            <Grid item xs={12} sm={10} md={6} lg={4} sx={{
              margin: '200px auto'
            }}>
              <Paper sx={{
                width: '100%',
                //height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px',
                borderRadius: '10px',

              }}>
                <img src={BlockedImage} alt={''}/>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    margin: '16px auto'
                  }}>
                Access Denied</Typography>
                <Box sx={{
                  backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
                  padding: '16px 40px',
                  borderRadius: '4px',
                  fontSize: '18px',
                  fontWeight: 600,
                  '& a': {
                    color: `${STYLE_CONSTANTS.COLORS.globalBlue} !important` ,
                    textDecoration: 'none'
                  }

                }}>
                  <Link to='/'>Return to home page</Link>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};
export default AccessDenied;

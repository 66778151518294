import {Grid, Box} from '@mui/material';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import MyGrid from '../../../../pieces/grids/gridWrapper';
import * as allConstants from '../../../../constants/allConstants';
import ColumnDefinition from '../../../../pieces/grids/columnGenerator';
import Utils from '../../../../utils/utils';
import {useGridViewState} from '../../../../hooks/useGridViewState';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import base_url from '../../../../baseUrls';
import ApiUtils from '../../../../utils/apiUtils';
import axios from 'axios';
import {Spinner} from '../../../misc/loader';
import {NoDataStringWrapper} from '../../../../pieces/noDataStringWrapper';
import moment from 'moment';

function CallLogs(props) {
  const gridRef = useRef();
  const viewController = useGridViewState();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let url = `${base_url.api}calls/get-by-job/${props[allConstants.JOB_FIELDS.ID.api_name]}`;
      const authToken = await ApiUtils.getAccessToken();
      const callsResponse = await axios.get(url, {
        headers: {
          method: 'POST',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken
        },
      });

      return callsResponse;
    };
    //  props.parentFetching(true);
    fetchData()
      .then((data) => {
        setData(data?.data?.data?.calls);
        setFetching(false)
        ;
      })
      .catch((error) => {
        // props.parentFetching(false);
        setFetching(false);
        console.log(error);
      });
  }, []);

  let columnDefs = useMemo(()=>{
    return [
      new ColumnDefinition({
        field: allConstants.CALL_LOGS_FIELDS.DATE_AND_TIME,
        filter: 'agDateColumnFilter',
        comparator: function(filterLocalDateAtMidnight, cellValue) {
          //using moment js
          var dateAsString = moment(cellValue).format('DD/MM/YYYY');
          var dateParts = dateAsString.split('/');
          var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        width: 250,
        valueGetter: ({data})=> {return Utils.formatIsoDateString(data[allConstants.CALL_LOGS_FIELDS.DATE_AND_TIME.api_name]);},
      }),
      new ColumnDefinition({
        field: allConstants.CALL_LOGS_FIELDS.CALL_RESULT,
      }),
      new ColumnDefinition({
        field: allConstants.CALL_LOGS_FIELDS.DESCRIPTION,
        wrapText: true,
        minWidth: 200,
        width: 500,
      }),
      new ColumnDefinition({
        field: allConstants.CALL_LOGS_FIELDS.CREATED_BY,
        // TODO: Once backend is ready, test fetching first name and last name and displaying the name properly
        valueGetter: ({data})=> {return `${data[allConstants.CALL_LOGS_FIELDS.CREATED_BY.api_name][allConstants.USER_FIELDS.FIRST_NAME.api_name]} ${data[allConstants.CALL_LOGS_FIELDS.CREATED_BY.api_name][allConstants.USER_FIELDS.LAST_NAME.api_name] || ''}`;}
      }),
    ];
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{
        '& .ag-header-row': {
          backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain
        }
      }}>
        {fetching && <Box sx={{transform: 'translateY(165px)'}}>
          <Spinner/>
        </Box>
        }

        {!fetching && data && data.length !==0 && <MyGrid
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          registerGrid={viewController.setGridToParent}
        ></MyGrid>}
        {!fetching && data && data.length === 0 &&
          <Box sx={{height: 'calc(48vh - 16px)'}}>
            <NoDataStringWrapper text={'No calls logged'}/>
          </Box>
        }
      </Grid>
    </Grid>
  );
}

export default CallLogs;

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Grid} from '@mui/material';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import ModalWrapper, {ModalContentWrapper} from '../../../../../pieces/modals/modalWrapper';
import Utils from '../../../../../utils/utils';
import Spacer from '../../../../../pieces/spacer';
import {useForm, FormProvider} from 'react-hook-form';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import * as allConstants from '../../../../../constants/allConstants';
import ApiUtils from '../../../../../utils/apiUtils';
import {Text} from '../../../../../pieces/text';
import {useJob} from '../../../../../contextProviders/jobContext';
import downloadFile from '../../../../../utils/downloadFileUtil';


export const AddCf1rPopupWindow = (props)=> {

  const {job, setJob} = useJob();
  const cf1rDocForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all'
  });
  window.cf1rDocForm = cf1rDocForm;

  const submitAction = async (data)=> {
    try{
      const dataToSubmit = {
        jobId: props.jobId,
        fileUploadId: data.file
      };

      if(props.existingCf1rDocument) {
        console.log('existing cf1r doc', props.existingCf1rDocument);
        //do this delete temporary untill we sync files with zoho
        await ApiUtils.makeApiCall('POST', 'jobs/delete-attachment',
          JSON.stringify({
            attachmentId:props.existingCf1rDocument?.id,
            jobId: props.jobId
          }));

      }

      const result = await ApiUtils.makeApiCall('POST', 'ipermit/jobs/cf1r/add-attachment', JSON.stringify(dataToSubmit));
      Utils.handleToastMessage(result, 'CF1R Document Uploaded Successfully', 'Error Uploading CF1R Document');
      if(result.status === 'success'){
        const updatedJob = result.data.job;
        if(updatedJob) {
          setJob({...job, ...updatedJob});
        }
        props.close();
      }
    } catch (e) {
      console.log('error', e);
      Utils.handleToastMessage(e, 'CF1R Document Uploaded Successfully', 'Error Uploading CF1R Document');
    }

  };

  const downloadCf1r = async ()=> {
    downloadFile({id: props.existingCf1rDocument?.fileId});
  };


  return(
    <ModalWrapper
      open={true}
      modalName={'upload-cf1r'}
      preventScroll={true}
      width={{sx: '98%', sm: '600px'}}
      padding={6}
      customStyle={{paddingTop: 2}}
    >
      <ModalContentWrapper handleClose={props.close} header={props.existingCf1rDocument ? 'Replace CF1R document':'Upload CF1R Document'}>

        <Grid container alignItems={'baseline'} position={'relative'}>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} textAlign={'center'}>
              {props.existingCf1rDocument &&
              <>
                <Box display={'flex'} alignItems={'center'}>
                  <Text text={'View existing document:'} sizePx={16}/>
                  <Spacer x={1}/>
                  <Button variant={'text'} color={'primary'} onClick={downloadCf1r}>CF1R</Button>
                </Box>
                <Spacer y={2}/>
              </>
              }
              <FormProvider {...cf1rDocForm}>
                <form onSubmit={cf1rDocForm.handleSubmit(submitAction)} method={'POST'}>
                  <CustomFormBlock
                    fullWidth={true}
                    maxWidth={'100%'}
                    isEditable={true}
                    renderLabel={true}
                    inputType={inputTypes.file}
                    field={allConstants.JOB_FIELDS.FILE.UPLOAD}
                  />
                  <FormSubmitButtons
                    submitText={'Upload'}
                    cancelAction={props.close}
                    noOutlines={true}/>

                </form>

              </FormProvider>

              <Spacer y={3}/>

            </Box>
          </Grid>
        </Grid>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

AddCf1rPopupWindow.propTypes = {
  close: PropTypes.func.isRequired,
  jobId: PropTypes.string.isRequired,
  existingCf1rDocument: PropTypes.oneOf([null, PropTypes.instanceOf(Object)]).isRequired
};


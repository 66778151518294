import {Typography} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../utils/utils';
import {DatesWrapper} from './datesWrapper';
import {COLORS} from '../../../styles/styleConstants';

const ImportantDatesInfo = (props) => {

  const formatNumericDateString = (date) => {
    return Utils.formatIsoDateStringWithOptions(date, {year: 'numeric', month: '2-digit', day: '2-digit'});
  };

  const getValue = (dateObject)=> {
    const val = formatNumericDateString(dateObject.date);
    if(!dateObject.valueFormatter) return val;
    return dateObject.valueFormatter(val);
  };

  let dateText;

  dateText = Object.keys(props.dates).map((key, index) => {
    const dateObject = props.dates[key];
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        key={index}
      >
        <Typography fontWeight={'bold'}>{`${dateObject.label}:`}</Typography>
        <Typography>{getValue(dateObject)}</Typography>
      </Box>
    );
  });

  return (
    <DatesWrapper color={COLORS.lightGreen}>
      {dateText}
    </DatesWrapper>
  );
};

ImportantDatesInfo.propTypes = {
  dates: PropTypes.objectOf(PropTypes.string),
};

export default ImportantDatesInfo;

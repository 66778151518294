import React, {useState} from 'react';
import {Paper, Box, Typography} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import PermitCompleted from '../../static/images/permit_completed.svg';
import PermitSubmitted from '../../static/images/permit_submitted.svg';
import PermitPending from '../../static/images/permit_pending.svg';
import {ANALYTICS_TARGETS} from '../../constants/options/analyticsTargets';
import PropTypes from 'prop-types';
import {AnalyticsCardWrapper} from '../analyticsCardWrapper';
import {awsUtils} from '../../utils/awsUtils';
import GettingDataProgress from '../gettingDataProgress';
import NoDataFound from '../noDataFound';
import clsx from 'clsx';
import {globalUseClasses} from '../../styles/globalClasses';


const EntityMiniCard = (props) => {
  const classes = {...globalUseClasses()};
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);

  const saveData = (fetchedData)=> {
    if(fetchedData && Array.isArray(fetchedData) && fetchedData[0]){
      setData(fetchedData[0]?.[props.target.response_key] || '0');
    } else {
      setData('0');
    }
  };

  const iconMatch = {
    [ANALYTICS_TARGETS.PERMIT_SUBMITTED.display]: PermitSubmitted,
    [ANALYTICS_TARGETS.PERMIT_COMPLETED.display]: PermitCompleted,
    [ANALYTICS_TARGETS.PERMIT_PENDING.display]:   PermitPending,
    [ANALYTICS_TARGETS.HERS_SCHEDULED.display]: PermitPending,
    [ANALYTICS_TARGETS.HERS_PASSED.display]:    PermitCompleted,
    [ANALYTICS_TARGETS.HERS_FAILED.display]:   PermitSubmitted,
  };

  return (
    <AnalyticsCardWrapper
      target={props.target}
      sessionKey={props.sessionKey}
      returnFetchedData={saveData}
      setParentFetching={(status)=>setFetching(status)}
      setParentFound = {(status)=> setFound(status)}
      fetchUrl={awsUtils.createUrl({
        type: props.target.api_request,
        company: props.company,
        params: props.params
      })}
    >

      <Box
        height={'auto'} >
        <Paper elevation={0} className={clsx(classes.info_paper)}
          sx={{
            width: '180px',
            padding: '14px !important',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box display="flex" justifyContent="center" pt={2}>
            {(found && !fetching) && <img src={iconMatch[props.target.display]} alt={''} width={'90px'}/>}
          </Box>
          <Box display="flex" alignItems="center" pb={1}
            flexDirection={'column'}
            justifyContent={'flex-end'}
            rowGap={fetching ? 2 : 0}
            height={'100%'}>
            {fetching && <GettingDataProgress/>}
            {!found && !fetching && <NoDataFound/>}
            {found && !fetching && <Typography color={STYLE_CONSTANTS.COLORS.fontDarkBlue} fontSize={36} fontWeight={700}>{data}</Typography>}
            <Typography color={STYLE_CONSTANTS.COLORS.globalBlue} fontSize={14} variant={'body1'}>{props.target.display}</Typography>
          </Box>
        </Paper>
      </Box>


    </AnalyticsCardWrapper>
  );
};

EntityMiniCard.propTypes = {
  target: PropTypes.objectOf(PropTypes.shape({
    display: PropTypes.string,
    value: PropTypes.string,
    response_key: PropTypes.string,
  })).isRequired,
  company: PropTypes.string,
  sessionKey: PropTypes.string,
  params: PropTypes.objectOf(Object)

};
EntityMiniCard.defaultProps = {
  params: {}
};

export default EntityMiniCard;

import React from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import {Grid} from '@mui/material';
import {FormValue, FormLabel} from '../../../../../pieces/displayJob/formLine';
import {EquipmentBlock} from '../../../../../pieces/displayJob/equipmentBlock';
import {tempPowerUtils} from './tempPowerDisplayUtils';
import {useJobDetailViewStyles} from '../../views/jobDetailViewStyles';
import {jobDisplayUtils} from '../jobDisplayUtils';
import {CommercialSection} from '../commercialSection';

const TempPowerDetails = (props) => {
  const {details, equipment} = props;
  const TEMP_POWER_FIELDS = allConstants.JOB_FIELDS.TEMPORARY_POWER;
  const classes = useJobDetailViewStyles();

  const displayEquipment = () => {
    if(!equipment || !Array.isArray(equipment)) return null;
    let all_equipment = [...equipment];

    const content = tempPowerUtils.getEquipment(all_equipment);
    if(content.length === 0) return null;
    return(
      <>
        {EquipmentBlock({content: content})}
      </>
    );
  };

  const displayMoreEquipment = ()=> {
    const moreEquipment = tempPowerUtils.getAdditionalEquipment(details);
    const additional = details?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]?.[TEMP_POWER_FIELDS.ADDITIONAL_EQUIPMENT.api_name];
    if((!moreEquipment || (moreEquipment && moreEquipment.length === 0)) && (!additional||(additional && additional.length === 0))) return null;
    return (
      <Grid container xs={12}>
        {moreEquipment && <Grid item xs={12} sm={6}>
          {jobDisplayUtils.getBlockContent(moreEquipment, classes.details_block, allConstants.JOB_FIELDS.SHARED.EQUIPMENT.display_label)}
        </Grid>}
        {additional && <Grid item xs={12}>
          <FormLabel label={TEMP_POWER_FIELDS.ADDITIONAL_EQUIPMENT.display_label}/>
          <FormValue value={additional}/>
        </Grid>}
      </Grid>
    );
  };
  const equipmentBlock = displayEquipment();
  const builderInfo = jobDisplayUtils.getBlockContent(tempPowerUtils.getBuilderInfo(details), classes.details_block,'Builder information');
  const miscInfo = jobDisplayUtils.getBlockContent(tempPowerUtils.getMiscInfo(details), classes.details_block,'Misc information');
  const moreEquipment = displayMoreEquipment();
  const commercial = <CommercialSection details={details}/>;


  return (
    <>
      <Grid container>
        {equipmentBlock &&
        <Grid container columns={{xs: 4, sm: 8, md: 10}} >
          {equipmentBlock}
        </Grid>
        }
        {builderInfo &&
        <Grid item xs={12} sm={6} >
          {builderInfo}
        </Grid>}
        {miscInfo &&
        <Grid item xs={12} sm={6} >
          {miscInfo}
        </Grid>}
        {moreEquipment && moreEquipment}
        {commercial &&
        <Grid item xs={12} >
          {commercial}
        </Grid>
        }

      </Grid>
    </>

  );
};

TempPowerDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
  equipment: PropTypes.instanceOf(Array).isRequired,
};
export {TempPowerDetails};

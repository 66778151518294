import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Logo from '../../static/images/iPermitPro.png';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

const useStyles = makeStyles(theme => ({
  spinner: {
    animation: '$load3 1.4s infinite linear'
  },
  '@keyframes load3': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
}));

const Spinner = ()=> {
  const classes = useStyles();
  return(
    <>
      <Box className={classes.spinner} sx={{
        height: '240px',
        width: '240px',
        position: 'absolute',
        left: 'calc(50% - 120px)',
        top: 'calc(50% - 120px)',
        textIndent: '-9999em',
        borderRadius: '50%',
        background: 'linear-gradient(to right, #84C341 0%, rgba(255,169,110, 0) 52%)',
        transform: 'translateZ(0)',
        '&::before': {
          width: '50%',
          height: '50%',
          background: 'rgba(255,169,110, 0)',
          borderRadius: '100% 0 0 0',
          position: 'absolute',
          top: 0,
          left: 0,
          content: '""',
        },

        '&::after': {
          background: '#fff',
          width: '96%',
          height: '96%',
          borderRadius: '50%',
          content: '""',
          margin: 'auto',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }

      }}/>

      <Box sx={{
        background: `url(${Logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        // backgroundSize:  '50px',
        width: '200px',
        backgroundSize: '160px',
        height: '150px',
        position: 'absolute',
        left: 'calc(50% - 100px)',
        top: 'calc(50% - 75px)',
      }}/>
    </>
  );
};

const Loader = (props)=> {
  const [isLoadingVisibleInternal, setIsLoadingVisibleInternal] = useState(false);

  useEffect(() => {
    const viewTimeout = setTimeout(() => { setIsLoadingVisibleInternal(true); }, props?.timeout || 1000);

    return () => {
      clearTimeout(viewTimeout);
    };
  }, []);

  if (!isLoadingVisibleInternal) {
    return null;
  }

  if(props.useOwnWrapper) {
    return (
      <>
        {isLoadingVisibleInternal && <>
          <Spinner/>
        </>}
      </>
    );
  }


  return (
    // <Box sx={{height: '80vh', border: '1px solid red', width: '80%'}}>
    <Box sx={{
      // background: 'none repeat scroll 0 0 #fff',
      height: '100%',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      zIndex: 9999,
      background: STYLE_CONSTANTS.COLORS.white,
    }}>
      {isLoadingVisibleInternal && <>
        <Spinner/>
      </>}

      {/*</Box>*/}
    </Box>

  );
};

Loader.propTypes = {
  timeout: PropTypes.number,
  useOwnWrapper: PropTypes.bool,
};

Loader.defaultProps = {
  timeout: undefined,
  useOwnWrapper: false,
};
export default Loader;
export {Spinner};


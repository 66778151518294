import React, {useEffect, useState} from 'react';
import NavBar from '../../../pieces/navbar/navbar';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import * as allConstants from '../../../constants/allConstants';
import {globalUseClasses} from '../../../styles/globalClasses';
import {Box} from '@mui/material';
import CreateJobForm from './createJobForm';
import {useSelector} from 'react-redux';
import BlockedPage from '../../misc/blockedCompany';
import SignupNotCompleted from '../../misc/signupNotCompleted';
import AccessDenied from '../../misc/accessDenied';
import clsx from 'clsx';
import Utils from '../../../utils/utils';
import {urlSearchParams} from '../../../baseUrls';
import base_url from '../../../baseUrls';
import ApiUtils from '../../../utils/apiUtils';
import axios from 'axios';

const CreateJobView = () => {
  const classes = {...globalUseClasses()};
  const [draftData, setDraftData]=useState(null);
  const [originalJob, setOriginalJob]=useState(null);
  const company = useSelector(state=> state.company);
  const user = useSelector(state=> state.user);
  const STATUS = allConstants.COMPANY_STATUSES;
  const companyStatus = company[allConstants.COMPANY_FIELDS.STATUS.api_name];
  const companyIsBlocked = company?.[allConstants.COMPANY_FIELDS.IS_BLOCKED.api_name];

  const fetchJob = async (jobId) => {
    let url = `${base_url.api}/jobs/${jobId}`;
    const authToken = await ApiUtils.getAccessToken();
    const jobResponse = await axios.get(url, {headers: {Authorization: authToken}});
    return jobResponse?.data?.data?.job;
  };

  useEffect(()=> {
    const draftId = Utils.getUrlParameter(urlSearchParams.draftId);

    const originalId = Utils.getUrlParameter(urlSearchParams.duplicateOfId);
    if(draftId) {
      try {
        fetchJob(draftId)
          .then((job) => {
            setDraftData(job || null);
          });

      } catch (e){}
    } else if(originalId) {
      try {
        fetchJob(originalId)
          .then((job) => {
            setOriginalJob({
              [allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]: job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name],
              [allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name]: job?.[allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name],
              [allConstants.JOB_FIELDS.ID.api_name]: originalId
            });
          });

      } catch (e){}
    }

  }, []);

  if(companyStatus === STATUS.BLOCKED.value || companyIsBlocked) {
    return <BlockedPage/>;
  }

  if(Utils.equalsIgnoreCase(companyStatus, STATUS.NOT_SIGNED.value)) {
    return <SignupNotCompleted role={user.role}/>;
  }

  if(Utils.equalsIgnoreCase(companyStatus, STATUS.NOT_ACTIVE.value) ||
    !Utils.equalsIgnoreCase(user[allConstants.USER_FIELDS.STATUS.api_name], allConstants.USER_STATUSES.ACTIVE.value)) {
    return <AccessDenied/>;
  }

  if(Utils.getUrlParameter(urlSearchParams.draftId) && !draftData) return null;
  if(Utils.getUrlParameter(urlSearchParams.duplicateOfId) && !originalJob) return null;

  return (
    <>
      <NavBar/>
      <Box className={clsx(classes.content_container, classes.page)} style={{
        padding: '20px',
        backgroundColor: STYLE_CONSTANTS.COLORS.white,
        borderTop: '2px solid #EBEDF3'
      }}>
        <CreateJobForm duplicateOf={originalJob}
          draftData={draftData} />
      </Box>
    </>
  );
};
export default CreateJobView;

import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import * as allConstants from '../../constants/allConstants';
import AnalyticsPermitCost from '../../pieces/analytics/analyticsPermitCost';
import {ANALYTICS_ENTITIES, ANALYTICS_TARGETS} from '../../constants/options/analyticsTargets';
import HersTestFailed from './cards/hersTestFailed';
import HersTestCompleted from './cards/hersTestCompleted';
import AveragePermitCost from './cards/averagePermitCost';
import {AnalyticsRowWrapper} from '../../pieces/analyticsRowWrapper';

function FinanceAnalytics(props) {

  const user = useSelector((state=> state.user));
  const company = props.company;

  const commonProps = {
    company: company?.[allConstants.COMPANY_FIELDS.NAME.api_name],
    user: user,
    entity: ANALYTICS_ENTITIES.FINANCIAL
  };

  return (
    <>
      <AnalyticsRowWrapper>
        <AveragePermitCost {...commonProps} sessionKey='cityCostAvg'/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <AnalyticsPermitCost {...commonProps} target={ANALYTICS_TARGETS.PERMIT_AVG_COST}/>
      </AnalyticsRowWrapper>
      {props.showHers && <AnalyticsRowWrapper>
        <HersTestFailed {...commonProps} sessionKey='hersFailedMonthly'/>
        <HersTestCompleted {...commonProps} sessionKey='hersCompletedMonthly'/>
      </AnalyticsRowWrapper>}

    </>
  );
}

FinanceAnalytics.propTypes = {
  showHers: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    [allConstants.COMPANY_FIELDS.NAME.api_name]: PropTypes.string.isRequired
  }).isRequired
};

export default FinanceAnalytics;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Cell} from 'recharts';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

class HalfPieChart extends Component {
  constructor(props) {
    super(props);
    this.colors = [STYLE_CONSTANTS.COLORS.globalBlue, STYLE_CONSTANTS.COLORS.globalGreen];
  }

  render() {
    // console.log(`[HalfPieChart]:::`,  this.props)
    const data = [
      {name: 'Group A', value: Number(this.props.value_1)},
      {name: 'Group B', value: Number(this.props.value_2)},
    ];
    // console.log('[HalfPieChart]::: render', data)
    return (
      <PieChart width={220} height={110}>
        <Pie
          data={data}
          // cx={420}
          cy={110}
          startAngle={180}
          endAngle={0}
          innerRadius={70}
          outerRadius={110}
          // fill="#8884d8"
          paddingAngle={4}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={this.colors[index % this.colors.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
HalfPieChart.propTypes = {
  value_1: PropTypes.number.isRequired,
  value_2: PropTypes.number.isRequired,
};
export default HalfPieChart;

import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {AnalyticsBaseCard} from '../../../pieces/analytics/analyticsBaseCard';
import {AnalyticsCardWrapper} from '../../../pieces/analyticsCardWrapper';
import {awsUtils} from '../../../utils/awsUtils';
import AnalyticsHalfPieCard from '../../../pieces/analytics/analyticsHalfPieCard';
import {ANALYTICS_TARGETS} from '../../../constants/options/analyticsTargets';
import GettingDataProgress from '../../../pieces/gettingDataProgress';
import NoDataFound from '../../../pieces/noDataFound';
import {Box} from '@mui/material';

const CompletedPermits = (props)=> {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);
  const currYear = useMemo(()=>new Date().getFullYear(),[]);
  const prevYear = useMemo(()=>(Number(currYear) - 1).toString(),[]);

  const saveData = (fetchedData)=> {
    if(fetchedData && Array.isArray(fetchedData)){
      setData(fetchedData);
    } else {
      setData([]);
    }
  };

  let content;
  if(fetching) {
    content = <GettingDataProgress/>;
  } else if(!found) {
    content = <NoDataFound/>;
  } else {
    content = <>
      <AnalyticsHalfPieCard value_1={data[0] ? data[0]?.total_count || '0' : '0'}
        value_2={data[1] ? data[1]?.total_count || '0' : '0' }
        text_1={'Completed current year'}
        text_2={'Completed previous year'}
      />
    </>;
  }

  return(
    <Box sx={{width: props.mediaQueries.isXL ? '565px' : '465px'}}>
      <AnalyticsBaseCard title={ANALYTICS_TARGETS.PERMITS_COMPLETED_YEARLY.display}>
        <AnalyticsCardWrapper
          target={ANALYTICS_TARGETS.PERMITS_COMPLETED_MONTHLY}
          sessionKey='completedPermits'
          setParentFetching={(status)=>setFetching(status)}
          setParentFound = {(status)=> setFound(status)}
          returnFetchedData={saveData}
          fetchUrl={awsUtils.createUrl({type: ANALYTICS_TARGETS.PERMITS_COMPLETED_YEARLY.api_request,
            params:{year1: currYear, year2: prevYear}, company: props.company})}>
          {content}
        </AnalyticsCardWrapper>
      </AnalyticsBaseCard>
    </Box>

  );
};

CompletedPermits.propTypes = {
  company: PropTypes.string.isRequired,
  mediaQueries: PropTypes.shape({
    isXL: PropTypes.bool
  })
};

CompletedPermits.defaultProps = {
  mediaQueries: {
    isXL: false
  }
};

export {CompletedPermits};

const ELEMENTS_ATTACHMENTS = {
  addPermitAttachment: 'addPermitAttachment',
  deletePermitAttachment: 'deletePermitAttachment',
  addInspectionAttachment: 'addInspectionAttachment',
  deleteInspectionAttachment: 'deleteInspectionAttachment',
  addHersAttachment: 'addHersAttachment',
  deleteHersAttachment: 'deleteHersAttachment',
  addAttachments: 'addAttachments',
};

const attachmentPolicies = {
  addPermitAttachment: 'permitDocument.create',
  deletePermitAttachment: 'permitDocument.delete',
  addInspectionAttachment: 'inspectionDocument.create',
  deleteInspectionAttachment: 'inspectionDocument.delete',
  addHersAttachment: 'hersDocument.create',
  deleteHersAttachment: 'hersDocument.delete',

};

const attachmentRules = Object.freeze({
  [ELEMENTS_ATTACHMENTS.addPermitAttachment]: {
    policyName: attachmentPolicies.addPermitAttachment,
  },
  [ELEMENTS_ATTACHMENTS.deletePermitAttachment]: {
    policyName: attachmentPolicies.deletePermitAttachment,
  },
  [ELEMENTS_ATTACHMENTS.addInspectionAttachment]: {
    policyName: attachmentPolicies.addInspectionAttachment,
  },
  [ELEMENTS_ATTACHMENTS.deleteInspectionAttachment]: {
    policyName: attachmentPolicies.deleteInspectionAttachment,
  },
  [ELEMENTS_ATTACHMENTS.addHersAttachment]: {
    policyName: attachmentPolicies.addHersAttachment,
  },
  [ELEMENTS_ATTACHMENTS.deleteHersAttachment]: {
    policyName: attachmentPolicies.deleteHersAttachment,
  },
  [ELEMENTS_ATTACHMENTS.addAttachments]: {
    policyName: undefined,
  },
});

export {attachmentRules, ELEMENTS_ATTACHMENTS, attachmentPolicies};

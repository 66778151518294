import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {ANALYTICS_ENTITIES, ANALYTICS_TARGETS} from '../../constants/options/analyticsTargets';
import EntityMiniCard from '../../pieces/analytics/entityMiniCard';
import AnalyticsNumberCard from '../../pieces/analytics/analyticsNumberCard';
import * as allConstants from '../../constants/allConstants';
import {AnalyticsRowWrapper} from '../../pieces/analyticsRowWrapper';
import {HersResults} from './cards/hersResults';
import {HersTestScheduled} from './cards/hersTestScheduled';
import {AnalyticsBaseCard} from '../../pieces/analytics/analyticsBaseCard';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';


function HersAnalytics(props) {
  const company = props.company;
  const mediaQueries = CustomMediaQuery();

  const commonProps = {
    company: company?.[allConstants.COMPANY_FIELDS.NAME.api_name],
    entity: ANALYTICS_ENTITIES.PERMITS,
    mediaQueries: mediaQueries
  };

  return (
    <>
      <AnalyticsRowWrapper>
        <HersResults {...commonProps} sessionKey={'hersFailedVsPassed'}/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <EntityMiniCard {...commonProps} target={ANALYTICS_TARGETS.HERS_SCHEDULED} sessionKey={'hersTestTotal'}/>
        <EntityMiniCard {...commonProps} target={ANALYTICS_TARGETS.HERS_PASSED} params={{status: allConstants.HERS_STATUSES.COMPLETED.value.toLowerCase()}} sessionKey={'hersComplatedTotal'}/>
        <EntityMiniCard {...commonProps} target={ANALYTICS_TARGETS.HERS_FAILED} params={{status: allConstants.HERS_STATUSES.FAILED.value.toLowerCase()}} sessionKey={'hersFailedTotal'}/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <HersTestScheduled {...commonProps} sessionKey={'hersScheduledMonthly'}/>
      </AnalyticsRowWrapper>
      <AnalyticsRowWrapper>
        <Box sx={{width: STYLE_CONSTANTS.DEFAULT.ANALYTICS_CARD_HEIGHT}}>
          <AnalyticsBaseCard title={'HERS Test Failed'}>
            <AnalyticsNumberCard {...commonProps} sessionKey={'currFailedHERS'}
              target={ANALYTICS_TARGETS.HERS_CURRENT_FAILED}
              path={allConstants.PATH.JOBS}
              queryParams={`?${allConstants.JOB_FIELDS.HERS_STATUS.api_name}=${allConstants.HERS_STATUSES.NEED_ATTENTION.value}`}
              legend={'Current Test Failed Jobs'} params={{status: 'failed'}}/>
          </AnalyticsBaseCard>
        </Box>
      </AnalyticsRowWrapper>
    </>
  );
}
HersAnalytics.propTypes = {
  showHers: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    [allConstants.COMPANY_FIELDS.NAME.api_name]: PropTypes.string.isRequired
  }).isRequired
};

export default HersAnalytics;

import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {useJob} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import Utils from '../../../utils/utils';
import {Text, TextWithIconWrapper} from '../../../pieces/text';
import {ReactComponent as UserIconSvg} from '../../../static/images/user.svg';
import {ReactComponent as PhoneIconSvg} from '../../../static/images/phoneSimple.svg';
import {ReactComponent as EnvelopeIconSvg} from '../../../static/images/envelopeIcon.svg';
import {ReactComponent as LocationPinIconSvg} from '../../../static/images/locationPin.svg';
import Spacer from '../../../pieces/spacer';


export const HomeownerInfoBlock =(props)=> {
  const {job} = useJob();
  const {GENERAL} = allConstants.JOB_FIELDS;
  const PARENT = GENERAL.HOMEOWNER.api_name;

  const name = (()=> {
    const homeOwner = job[PARENT];
    let n = homeOwner?.[allConstants.USER_FIELDS.FIRST_NAME.api_name] || '';
    if(homeOwner?.[allConstants.USER_FIELDS.LAST_NAME.api_name]) {
      n = `${name} ${homeOwner?.[allConstants.USER_FIELDS.LAST_NAME.api_name]}`;
    }
    return n;
  })();

  const getValueFromJob = (fieldName, parent)=> {
    if(parent) {
      return job?.[parent]?.[fieldName];
    }
    return job?.[fieldName];
  };


  const homeOwnerFields = props?.row ? [
    {value: name, icon:<UserIconSvg/>},
    {value: Utils.formatPhoneNumber(getValueFromJob(GENERAL.PHONE_NUMBER.api_name, PARENT)), icon:<PhoneIconSvg/>},
    {value: Utils.getAddressString(getValueFromJob(GENERAL.ADDRESS.api_name)), icon:<LocationPinIconSvg/>},
    {value: getValueFromJob(GENERAL.HO_EMAIL.api_name, PARENT), icon:<EnvelopeIconSvg/>},
    {value: getValueFromJob(GENERAL.ALT_HO_PHONE.api_name, PARENT) ? `ALT: ${Utils.formatPhoneNumber(getValueFromJob(GENERAL.ALT_HO_PHONE.api_name, PARENT))}` : null, icon:<PhoneIconSvg/>},


  ]:[
    {value: name, icon:<UserIconSvg/>},
    {value: Utils.getAddressString(getValueFromJob(GENERAL.ADDRESS.api_name)), icon:<LocationPinIconSvg/>},
    {value: Utils.formatPhoneNumber(getValueFromJob(GENERAL.PHONE_NUMBER.api_name, PARENT)), icon:<PhoneIconSvg/>},
    {value: getValueFromJob(GENERAL.ALT_HO_PHONE.api_name, PARENT) ? `ALT: ${Utils.formatPhoneNumber(getValueFromJob(GENERAL.ALT_HO_PHONE.api_name, PARENT))}` : null, icon:<PhoneIconSvg/>},
    {value: getValueFromJob(GENERAL.HO_EMAIL.api_name, PARENT), icon:<EnvelopeIconSvg/>}
  ];

  const styles = {textTransform: 'uppercase'};
  if(props?.row) {
    styles.display = 'grid';
    styles.gridTemplateColumns = '1fr 0.7fr 2fr';
  }

  const displayStyles = {
    display: props.row ? 'flex' : 'initial',
    alignItems: 'center'
  };


  return (
    <Box sx={styles}>
      {homeOwnerFields.map((f, index)=> {
        if(!Utils.valueIsSpecified(f.value)) return null;
        return (
          <Box  key={f.value + index} sx={displayStyles}>
            <TextWithIconWrapper icon={f.icon} key={f.value + index} iconColor={props.row ? STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.fontSecondary}>
              <Text text={f.value}/>
            </TextWithIconWrapper>
            {!props.row && <Spacer y={1}/>}
          </Box>

        );
      })}
    </Box>
  );
};

HomeownerInfoBlock.propTypes = {
  row: PropTypes.bool
};

HomeownerInfoBlock.defaultProps = {
  row: false
};
export const WrappedHomeOwnerInfoRows = ()=> {
  return (
    <Box sx={{
      marginRight: '150px',
      border: `1px solid ${STYLE_CONSTANTS.COLORS.globalBlue}`,
      borderRadius: '5px',
      padding: '8px'
    }}>
      <HomeownerInfoBlock row={true}/>
    </Box>
  );
};

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const CustomMediaQuery = ()=> {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMD = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLG = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    isXS, isSM,  isMD, isLG, isXL
  };
};

export {CustomMediaQuery};
import React from 'react';
import PropTypes from 'prop-types';
import {SystemEquipment} from './mechanical/panels/systemEquipment';


const EquipmentPanelWrapper=(props)=> {

  const {formMethods, addFields, inputDefaultProps, canModifyEquipment, inputs, eqName} = props;
  return (
    <SystemEquipment addFields={addFields}
      formMethods={formMethods}
      eqName={eqName}
      sx={{maxWidth: {xs: '100%', md: '1200px', lg: '1400px'}}}
      inputs={inputs}
      inputDefaultProps={inputDefaultProps}
      fieldSet={props.equipmentMap[eqName]['fields']}
      canModifyEquipment={canModifyEquipment}
      hideEqName={true}
    >{props.children}</SystemEquipment>
  );
};

EquipmentPanelWrapper.propTypes = {
  formMethods: PropTypes.shape({
    control: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired
  }).isRequired,
  addFields: PropTypes.func.isRequired,
  inputDefaultProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
  canModifyEquipment: PropTypes.bool,
  equipmentMap: PropTypes.instanceOf(Array).isRequired,
  eqName: PropTypes.string.isRequired,
  inputs: PropTypes.instanceOf(Array).isRequired
};

export default EquipmentPanelWrapper;

const CITY_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'name', crm_name: 'Name', required: true, label: 'city name', display_label: 'city name'},
  CLOSURE_CODE: {api_name: 'closureCode', crm_name: 'Closure_Code', label: 'Closure Code', display_label: 'Closure Code'},
  HOURS: {api_name: 'hours', crm_name: 'Hours', label: 'Hours', display_label: 'Hours'},
  ADDRESS: {api_name: 'address', required: false, label: 'Address', display_label: 'Address'},
  LINE_1: {api_name: 'line1', required: true, label: 'Address', display_label: 'Address'},
  LINE_2: {api_name: 'line2', required: false, label: 'Suite/apt', display_label: 'Suite/apt'},
  ADDRESS_CITY: {api_name: 'city', required: true, label: 'City', display_label: 'City'},
  STATE: {api_name: 'state', required: true, label: 'State', display_label: 'State'},
  ZIPCODE: {api_name: 'zipCode', required: true, label: 'Postal Code', display_label: 'Postal Code'},
  PHONE_NUMBER: {api_name: 'phoneNumber', required: false, label: 'Phone Number', display_label: 'Phone Number'},
  ALT_PHONE_NUMBER: {api_name: 'alternativePhoneNumber', required: false, label: 'Alt Phone No.', display_label: 'Alt Phone No.'},
  CITY_TYPE: {api_name: 'cityType', required: false, label: 'City Type', display_label: 'City Type'},
  COUNTY: {api_name: 'county', required: false, label: 'County', display_label: 'County'},
  FT: {api_name: 'ft', required: false, label: 'FT', display_label: 'FT'},
  SET_BACKS: {api_name: 'setBacks', required: false, label: 'Set Backs', display_label: 'Set Backs'},
  ON_ROOF: {api_name: 'onRoof', required: false, label: 'On Roof', display_label: 'On Roof'},
  LOCATION: {api_name: 'location', required: true, label: 'Location', display_label: 'Location'},
  DOCUMENTS: {api_name: 'documents', required: false, label: 'Documents', display_label: 'Documents'},
  SETTING_INSPECTION_RULES: {api_name: 'settingInspectionRules', required: false, label: 'How to set an inspection in this city', display_label: 'How to set an inspection in this city'},
  SCHEDULE_INSPECTION_VIA: {api_name: 'waysToScheduleInspection', required: false, label: 'Schedule Via', display_label: 'Schedule By'},
  NOTE: {api_name: 'note', required: false, label: 'Note', display_label: 'Note'},
  LADDER: {api_name: 'needsLadder', required: false, label: '', display_label: ''},

};

export {CITY_FIELDS};

import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import {useParams} from 'react-router-dom';
import base_url from '../../../../../baseUrls';
import * as allConstants from '../../../../../constants/allConstants';
import {
  JOB_FIELDS,
} from '../../../../../constants/allConstants';
import {useJob} from '../../../../../contextProviders/jobContext';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import ApiUtils from '../../../../../utils/apiUtils';
import Utils from '../../../../../utils/utils';
import Loader from '../../../../misc/loader';
import {IpermitJobWrapper, showChildrenPosition} from '../../../components/ipermitJobWrapper';

const AllJobsRecord = ()=>{
  const classes = {...globalUseClasses()};
  const [fetching, setFetching] = useState(false);
  const {job, setJob, ...jobContext} = useJob();
  const route = useParams();


  useEffect(() => {
    const fetch = async () => {
      let url = `${base_url.api}jobs/${route.id}`;
      const authToken = await ApiUtils.getAccessToken();
      const requestParams = {populate: ['inspection'], getSameAddressJobs: true};
      const jobResponse = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return jobResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {
        console.log('got job', data.data.data.job);
        setJob(data.data.data.job);
        // setNotes(data.data.data.job.notes);
        setFetching(false)
        ;
      })
      .catch((error) => {
        // props.parentFetching(false);
        setFetching(false);
        console.log('ERR:::', error);
      });
  }, []);



  const getRow = (field, options)=> {
    const {label} = field;
    if(options?.value) {
      return {label:label, value: options.value};
    }
    const value = options?.parent ? job?.[options.parent]?.[field.api_name] : job?.[field.api_name];
    const displayValue = typeof value === 'undefined' ? '-' : Utils.reformatValue(value);
    return{
      label: label, value: displayValue};
  };


  const generalFields = (()=> {
    const NaValue = 'N/A';
    const col1 = [
      getRow(JOB_FIELDS.GENERAL.PROPERTY_TYPE),
      getRow(JOB_FIELDS.GENERAL.SQFT),
      getRow(JOB_FIELDS.GENERAL.JOB_VALUE, {value: job?.[JOB_FIELDS.GENERAL.JOB_VALUE.api_name] ? `$${job?.[JOB_FIELDS.GENERAL.JOB_VALUE.api_name]}` : '-'}),
      {label: 'Contractor\'s email', value: job[JOB_FIELDS.ORDERED_BY.api_name]?.[allConstants.USER_FIELDS.EMAIL.api_name], longLabel: true}
    ];

    const col2 = [
      getRow(JOB_FIELDS.GENERAL.ASBESTOS, {value: jobContext.isHVACJob() ? undefined : NaValue}),
      getRow(JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED, {value: jobContext.isPermitOnly() ? NaValue : undefined}),
      getRow(JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED, {value: jobContext.isPermitOnly() ? NaValue : undefined}),

    ];
    return [col1, col2];

  })();


  if(fetching) return <Loader timeout={10}/>;
  if(!job) return null;

  return (
    <>
      <Box className={clsx(classes.page)}>
        <IpermitJobWrapper module={allConstants.IPERMIT_MODULES.ALL_JOBS}
          backPath={`${allConstants.PATH.MODULE}${location.search}`}
          generalInfo={generalFields}
          showChildrenPosition={showChildrenPosition.afterGeneral}
        />

      </Box>
    </>
  );
};

export default AllJobsRecord;

import * as allConstants from '../../constants/allConstants';

const AnalyticSideBarItems = [
  {
    'name': 'permits',
    'label': 'permit analytics',
    'route': allConstants.PATH.ANALYTICS_PERMIT
  },
  {
    'name': 'hers',
    'label': 'HERS analytics',
    'route': allConstants.PATH.ANALYTICS_HERS
  },
  {
    'name': 'finance',
    'label': 'finance & analytics',
    'route': allConstants.PATH.ANALYTICS_FINANCE
  },
];

export default AnalyticSideBarItems;
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {COLORS} from '../styles/styleConstants';

const FormSubmitButtons = (props)=> {
  const getDeleteStyles = ()=> {
    if(!props.applyDeleteStyles) return {};
    return {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      borderRadius: '15px',
      padding: '3px 12px',
      '&.red': {
        backgroundColor: COLORS.globalRedPale,
        color: COLORS.globalRed
      },
      '&.blue': {
        backgroundColor: COLORS.globalBluePale,
        color: COLORS.globalBlue
      },
      '&.gray': {

        '&:hover': {
          backgroundColor: COLORS.globalBluePale,
          color: COLORS.fontMain
        }
      },
      boxShadow: 'none'
    };
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'center'}>
        {!props.hideCancel && <Box m={2} pt={3}  sx={{
        // marginTop: {xs: '0', sm: 'initial'},
          paddingTop: {xs: '0', sm: 'initial'}
        }}>
          <Button variant={props.noOutlines ? 'contained' : 'outlined'}
            sx={{minWidth: '80px', ...getDeleteStyles()}}
            className={props.applyDeleteStyles ? 'blue' :''}
            color={props.revertColors ? 'primary' : 'secondary'}
            onClick={(e)=>props.cancelAction(e)}
            disabled={props.disableCancel}

          >{props.cancelText}</Button>
        </Box>}
        <Box m={2} pt={3} sx={{
        // marginTop: {xs: '0', sm: 'initial'},
          paddingTop: {xs: '0', sm: 'initial'}
        }}>
          <Button variant="contained" sx={{minWidth: '80px', ...getDeleteStyles()}}
            className={'gray'}
            color={props.revertColors ? 'secondary': 'primary'}
            disabled={props.disableSubmit }
            type={props.submitAction ? 'button' : 'submit'}
            onClick={props.submitAction ? props.submitAction : null}
            startIcon={props?.submitIcon ? props.submitIcon : null}

          >{props.submitText}</Button>
        </Box>

      </Box>
      {props.extraButton &&  <Box m={2} pt={3} sx={{
        // marginTop: {xs: '0', sm: 'initial'},
        paddingTop: {xs: '0', sm: 'initial'}
      }}>
        <Button variant="contained" sx={{
          minWidth: '100%',
          ...getDeleteStyles(),
          color: '#000'
        }}
        className={'blue'}
        color={'secondary'}
        type={props.extraButton.type}
        onClick={props.extraButton.action ? props.extraButton.action : null}

        >{props.extraButton.text}</Button>
      </Box>}
    </>
  );
};

FormSubmitButtons.propTypes = {
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  cancelAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func,
  submitIcon: PropTypes.element,
  disableSubmit: PropTypes.bool,
  applyDeleteStyles: PropTypes.bool,
  revertColors: PropTypes.bool,
  noOutlines: PropTypes.bool,
  extraButton: PropTypes.shape({
    type: PropTypes.string,
    action: PropTypes.func,
    text: PropTypes.string
  }),
  hideCancel: PropTypes.bool,
  disableCancel: PropTypes.bool
};
FormSubmitButtons.defaultProps = {
  cancelText: 'Cancel',
  submitText: 'Submit',
  submitAction: null,
  submitIcon: null,
  disableSubmit: false,
  applyDeleteStyles: false,
  revertColors: false,
  noOutlines: false,
  extraButton: undefined,
  hideCancel: false,
  disableCancel: false
};

export default FormSubmitButtons;

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import actions from '../../../../redux/actions';
import * as allConstants from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';

import WaterHeaterEquipment from './waterHeaterSystem';

export const WaterHeater = (props)=> {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const parsedDraft = useRef(null);


  useEffect(()=> {
    let draftData;
    try {
      parsedDraft.current = JSON.parse(props.draftData?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
      draftData = parsedDraft.current?.equipment;

      if(draftData) {

        const subforms = {
          [allConstants.JOB_FIELDS.WATER_HEATER_SYSTEM.api_name]: Utils.createSystemDataForRedux(draftData, allConstants.JOB_FIELDS.WATER_HEATER_SYSTEM.api_name)
        };
        dispatch(actions.addBulkSubforms({
          jobType: allConstants.JOB_TYPES.PLUMBING.api_name,
          subforms
        }));
      }

    } catch (e){

    }
    setIsFirstLoad(false);

  },[]);
  const canModifyEquipment = useRef(Utils.canModifyEquipment(props.draftData));


  if(isFirstLoad) {
    //don't render subforms before we set draft data, is exists to store
    return null;
  }


  return (

    <WaterHeaterEquipment {...props} canModifyEquipment={canModifyEquipment.current}/>

  );
};

WaterHeater.propTypes = {
  setEquipmentForm: PropTypes.func.isRequired,
  draftData: PropTypes.string,
};

WaterHeater.defaultProps = {
  draftData: null,
};

export default WaterHeater;

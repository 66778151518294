import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../baseUrls';
import * as allConstants from '../../constants/allConstants';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {ACCOUNTING_ELEMENTS, productsPageRules} from '../../permissions/accountingAccessControl';


const AllProductsGrid = (props) => {
  const viewController = props.viewController;
  const [components] = useState({
    'statusRenderer': StatusRenderer,
  });

  let columnDefs = useMemo(()=>{
    return [
      new ColumnDefinition({field: allConstants.PRODUCT_FIELDS.ID, hide: true}),
      new ColumnDefinition({field: allConstants.PRODUCT_FIELDS.NAME,
        lockPinned: true,
        pinned: 'left',
        checkboxSelection: true,
        width: 350,
        wrapText: true
      }),
      new ColumnDefinition({field: allConstants.PRODUCT_FIELDS.CATEGORY,
        width: 200,
        filter:'agSetColumnFilter',
        filterValues: Object.values(allConstants.PRODUCT_CATEGORIES_OPTIONS).map((s)=> s.value)
      }),
      new ColumnDefinition({field: allConstants.PRODUCT_FIELDS.PRICE,
        filter: 'agNumberColumnFilter',
        valueGetter: ({data})=> {return data.price ? `$${data.price}` :'';},
        width: 150}),
      new ColumnDefinition({field: allConstants.PRODUCT_FIELDS.CODE, width: 150}),
      new ColumnDefinition({field: allConstants.PRODUCT_FIELDS.DESCRIPTION, wrapText: true}),
    ];
  }, []);


  const editProductHandler = (productData)=> {
    props.editProductHandler(productData);
  };

  const deleteProductHandler = (productData)=> {
    props.deleteProductHandler(productData);
  };

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data, user?.id);
    if(!params.node) return [];

    const standardActions = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const actions = {
      edit: {
        name: 'Edit',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          editProductHandler(params?.node?.data);
        },
      },
      delete: {
        name: 'Delete',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          deleteProductHandler(params?.node?.data);
        },
      },
    };

    let allowedActions = [];
    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: productsPageRules[ACCOUNTING_ELEMENTS.editProduct],
    })) {
      allowedActions.push(actions.edit);
    }

    allowedActions = [...allowedActions, ...standardActions];

    return allowedActions;
  };

  const ls_name = 'products_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      fetchParams={{url: `${base_url.api}products/`, respKey:'products'}}
      companyId={viewController.companyId}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

AllProductsGrid.propTypes = {
  editProductHandler: PropTypes.func.isRequired,
  deleteProductHandler: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,

};
export default AllProductsGrid;

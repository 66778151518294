import React from 'react';
import PropTypes from 'prop-types';
import {Paper} from '@mui/material';
import clsx from 'clsx';
import {globalUseClasses} from '../styles/globalClasses';
import Spacer from './spacer';

export const Card = (props)=> {
  const classes = {...globalUseClasses()};
  const baseStyles = {
    textTransform: 'uppercase',
    padding: props.padding || {xs: '8px', md: '16px', xl: '24px'},
    boxShadow: props.boxShadow ? '0px 0px 12px rgba(0, 0, 0, 0.08)' : 'none',
  };
  const styles = props?.styles ? {...baseStyles, ...props.styles} : baseStyles;
  return(
    <>
      {/*{props.showHeader && <Typography variant={'h5'} className={classes.block_header}>{props.header}</Typography>}*/}
      <Paper elevation={0} className={clsx(classes.paper_style)}
        sx={styles}>
        {props.header && <>
          {props.header}
          <Spacer y={1}/>
        </>}
        {props.children}
      </Paper>
    </>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.instanceOf(Object),
  header: PropTypes.node,
  boxShadow: PropTypes.bool,
  padding: PropTypes.instanceOf(Object),
};

Card.defaultProps = {
  children: null,
  header: null,
  styles: {},
  boxShadow: false,
  padding: null,
};

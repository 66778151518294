import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {BarChart, Bar, Cell, LabelList, YAxis} from 'recharts';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';


class TwoValuesBarChart extends Component {
  constructor(props) {
    super(props);
    this.colors = [STYLE_CONSTANTS.COLORS.globalBlue, STYLE_CONSTANTS.COLORS.globalGreen];
  }
  render() {
    const data = [
      {name: 'Passed', value: this.props.value[0]},
      {name: 'Failed', value: this.props.value[1]},
    ];

    return (
      <BarChart width={220}
        height={260}
        data={data}
        margin={{
          top: 30,
          right: 20,
        }}
      >
        <YAxis type="number" domain={[0, this.props.value[1]+10]} hide={true}/>
        <Bar dataKey="value">
          {
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={this.colors[index]} strokeWidth={0}>
                {/*<LabelList dataKey="value" position={'top'} fill={this.colors[index]}/>*/}
              </Cell>
            ))
          }
          <LabelList dataKey="value" position={'top'} fontSize={20}/>
        </Bar>

      </BarChart>
    );
  }
}
TwoValuesBarChart.propTypes = {
  value: PropTypes.instanceOf(Array),
};
export default TwoValuesBarChart;

import React from 'react';
import PropTypes from 'prop-types';
import {Box, useTheme} from '@mui/material';

const Spacer = ({x, y, basis, ...restProps}) => {
  const theme = useTheme();
  return (
    <Box
      data-testid="Spacer"
      width={x ? theme.spacing(x) : '100%'}
      height={y ? theme.spacing(y) : '100%'}
      flexBasis={basis ? theme.spacing(basis) : undefined}
      flexGrow={0}
      flexShrink={0}
      {...restProps}
    />
  );
};

Spacer.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  basis: PropTypes.number
};

Spacer.defaultProps = {
  x: null,
  y: null,
  basis: null
};

export default Spacer;

import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import base_url, {urlSearchParams} from '../../baseUrls';
import Utils from '../../utils/utils';
import ApiUtils from '../../utils/apiUtils';
import {NewPermissionGate} from '../../permissions/permissionGate';
import * as allConstants from '../../constants/allConstants';
import {useDispatch} from 'react-redux';
import actions from '../../redux/actions';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import {companyRules, COMPANY_ELEMENTS} from '../../permissions/companyAccessControl';
import {SERVICE_TITAN_JOB_FIELDS} from '../../constants/allConstants';
//import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import {useNavigate} from 'react-router-dom';
import {useGotIt} from '../../hooks/useGotIt';


const AllServiceTitanJobsGrid = (props) => {

  //const gridApi = useRef(null);
  const viewController = props.viewController;
  const dispatch = useDispatch();
  const gotItPopup = useGotIt();
  const [components] = useState({
    'statusRenderer': StatusRenderer,
  });
  const navigate = useNavigate();
  const jobColumns = {
    id:  new ColumnDefinition({field: SERVICE_TITAN_JOB_FIELDS.ID, hide: true}),
    dateCreated: new ColumnDefinition({
      field: SERVICE_TITAN_JOB_FIELDS.DATE_CREATED,
      sortable: false,
      parent: SERVICE_TITAN_JOB_FIELDS.api_name,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        return Utils.formatIsoDateString(data?.[SERVICE_TITAN_JOB_FIELDS.DATE_CREATED.api_name],{date: true});
      }),
    }),
    empEmail: new ColumnDefinition({field: SERVICE_TITAN_JOB_FIELDS.EMP_EMAIL, filter: false }),
    jobNumber: new ColumnDefinition({field: SERVICE_TITAN_JOB_FIELDS.JOB_NUMBER, filter: false}),
    status: new ColumnDefinition({
      field: SERVICE_TITAN_JOB_FIELDS.STATUS,
      sortable: false,
      headerName: 'Status',
      cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      filterValues: Object.values(allConstants.SERVICE_TITAN_STATUSES).map((s) => s.value),
    }),
    jobType: new ColumnDefinition({field: SERVICE_TITAN_JOB_FIELDS.JOB_TYPE, filter: false}),
    permitStatus: new ColumnDefinition({field: SERVICE_TITAN_JOB_FIELDS.PERMIT_STATUS, filter: false}),
    permitJobUrl: new ColumnDefinition({field: SERVICE_TITAN_JOB_FIELDS.IPERMIT_JOB_URL, filter: false}),

  };

  const getColumns = ()=> {
    return [
      jobColumns.id,
      jobColumns.dateCreated,
      jobColumns.empEmail,
      jobColumns.jobNumber,
      jobColumns.jobType,
      jobColumns.status,
      //jobColumns.permitJobUrl,
    ];
  };

  let columnDefs = useMemo(()=> {
    return getColumns();
  }, []);
  
  
  // const handleDisplayCompanyDetails = (params) => {
  //   navigate(`${allConstants.PATH.COMPANY}?${urlSearchParams.companyId}=${params?.node?.data.id}`);
  // };

  const reCreateServiceTitanJob = async (param) => {

    try {
      const payload = {
        tenantId: param?.node?.data[allConstants.SERVICE_TITAN_JOB_FIELDS.TENANT_ID.api_name],
        id: param?.node?.data[allConstants.SERVICE_TITAN_JOB_FIELDS.ID.api_name],
      };
      param?.node?.setDataValue(allConstants.SERVICE_TITAN_JOB_FIELDS.JOB_NUMBER.api_name, 'Re-creating');
      
      ApiUtils.makeApiCall('POST', '/companies/get-tenant-jobs-manually', JSON.stringify(payload)).
       then( (x) => {
        gotItPopup.setShow(`Wait for a minute or two and click on "Refresh Grid" button to see new jobs`);
       });
    }
    catch (e) {
      console.log('error on deleting service titan job.', e);
     }


  }

  const deleteServiceTitanJob = async (param)  =>{

    //console.log('Grid parameters');
    //console.log(param);
    try {
      const payload = {
        id: param?.node?.data[allConstants.SERVICE_TITAN_JOB_FIELDS.ID.api_name],
      };
      ApiUtils.makeApiCall('POST', 'companies/delete-service-titan-job', JSON.stringify(payload)).
       then( (x) => {

        param?.node?.setDataValue(allConstants.SERVICE_TITAN_JOB_FIELDS.JOB_NUMBER.api_name, 'Deleted');
       });
    }
    catch (e) {
      console.log('error on deleting service titan job.', e);
    }
  }

  const isFailedServiceTitanJob = (data) => {
    return data.iPermitJobUrl === '';
  }


  const isDeletedServiceTitanJob = (data) => {
    return  data.status === 'Deleted';
  }

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data.id, viewController.userRole);
    if(!params.node) return [];
  
    const actions = [];

    actions.push({
      name: 'Delete Service Titan Job',
      disabled: viewController.selectedMultipleRows() || !isFailedServiceTitanJob(params?.node?.data),
      action: () => deleteServiceTitanJob(params)
    });
    actions.push('separator');

    actions.push({
      name: 'Re-create Service Titan Job',
      disabled: !isDeletedServiceTitanJob(params?.node?.data) && (viewController.selectedMultipleRows() || !isFailedServiceTitanJob(params?.node?.data)),
      action: () => reCreateServiceTitanJob(params)
    });

    const standard = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const separator=[
      'separator'
    ];

    return [...actions, ...separator, ...standard];

  };

  return (
    <>
    {gotItPopup.PopUp()}
    <MyPaginatedGrid columnDefs={columnDefs}
      components={components}
      ls_name={'srv_titan_jobs'}
      // onCellDoubleClicked={(params)=> handleDisplayCompanyDetails(params)}
      registerGrid={viewController.setGridToParent}
      contextMenu={getContextMenuItems}
      fetchParams={props.fetchParams}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
      refreshTimestamp={viewController.refreshTimestamp}
      isTabOffset={true}
    />
    </>
  );
};

AllServiceTitanJobsGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,
  editFinancial: PropTypes.func.isRequired
};
export default AllServiceTitanJobsGrid;

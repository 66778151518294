import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {CustomInput} from './customInputComponent';
import {inputTypes} from './formInput';
import {useFormContext} from 'react-hook-form';
import {List, ListItemText, Paper, ListItem, ListItemButton, Box, InputAdornment, Icon} from '@mui/material';
import ApiUtils from '../../utils/apiUtils';
import axios from 'axios';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useDebounce from '../../hooks/useDebounce';
import SearchIcon from '../../static/images/searchIcon.svg';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
// import useKeyPress from '../../hooks/useKeyPress';


const CustomAutocomplete = (props)=> {
  const staticProps = useMemo(()=> props, []);
  const suggestionRef = React.useRef();

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currInputValue, setCurrentInputValue] = useState();
  const [fetching, setFetching] = useState(false);
  // const [cursor, setCursor] = useState(0);
  // const downPress = useKeyPress('ArrowDown');
  // const upPress = useKeyPress('ArrowUp');
  // const enterPress = useKeyPress('Enter');
  const fieldFullName = props?.parent ? `${props.parent}[${props.field.api_name}]` : props.field.api_name;

  const isDisabled = !props.isEditable || props.viewMode;

  const formMethods = useFormContext();

  const debouncedValue = useDebounce(currInputValue, 500);

  //Enable this if want key navigation support. Issue: scroll is not applied when use arrow keys to navigate
  // useEffect(() => {
  //   if (suggestions.length && downPress) {
  //     setCursor(prevState =>
  //       prevState < suggestions.length - 1 ? prevState + 1 : prevState
  //     );
  //   }
  // }, [downPress]);
  // useEffect(() => {
  //   if (suggestions.length && upPress) {
  //     setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
  //   }
  // }, [upPress]);
  // useEffect(() => {
  //   if (suggestions.length && enterPress) {
  //     handleSelect(suggestions[cursor])
  //   }
  // }, [cursor, enterPress]);

  useEffect(async () => {
    if (showSuggestions) {
      setFetching(true);
      const options = await getData(debouncedValue);
      setFetching(false);
      setSuggestions(options);
    }
  },
  [debouncedValue, showSuggestions] // Only call effect if debounced search term changes
  );

  const getData = async(inputValue)=> {
    try{
      let url = props.autocompleteParams.url;
      const requestParams = props.autocompleteParams.getRequestParams(inputValue);
      const authToken = await ApiUtils.getAccessToken();
      const data = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return data?.data?.data || [];
    } catch(e) {
      return [];
    }
  };

  const onInputChange = async (e)=> {
    setCurrentInputValue(e.target.value);
  };

  const handleSelect = (selected)=> {
    // setSelected(selected);
    setShowSuggestions(false);
    formMethods.setValue(fieldFullName, selected?.[props.field.api_name], {
      shouldValidate: true,
    });
    if(props.autocompleteParams?.fill) {
      props.autocompleteParams?.fill.forEach((rowElement)=> {
        const rowElementFullName = props?.parent ? `${props.parent}[${rowElement.api_name}]` : rowElement.api_name;
        formMethods.resetField(rowElementFullName,
          {keepDirty: true,
            keepTouched: true,
            defaultValue: selected?.[rowElement.api_name],

          });
      });
    }
  };

  const renderSuggestions = () => {
    // if(!suggestions || suggestions.length === 0) {
    //   return null;
    // }
    let renderList = [...suggestions];

    try{
      if(props.autocompleteParams.filter) {
        renderList = props.autocompleteParams.filter(renderList);
        console.log('renderList', renderList);
      }
    } catch(e) {}


    return (
      <Box sx={{minHeight: '40px'}}>
        {
          renderList.map((suggestion, idx) => {
            const value = suggestion[props.field.api_name];
            return (
              <ListItem key={value + idx}
                // selected={idx === cursor}
                sx={{padding: '0'}}>
                <ListItemButton  onClick={()=>handleSelect(suggestion)}>
                  <ListItemText primary={value} />
                </ListItemButton>
              </ListItem>

            );
          })
        }
        {
          renderList.length === 0 && <ListItem>
            <ListItemText primary={fetching ? 'Getting data...' : 'No results found' } sx={{
              '& .MuiTypography-root': {
                color: fetching ?  STYLE_CONSTANTS.COLORS.fontPale: STYLE_CONSTANTS.COLORS.globalRed
              }
            }}/>
          </ListItem>
        }
      </Box>
    );
  };

  useOnClickOutside(suggestionRef, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    setShowSuggestions(false);
    setSuggestions([]);
  });

  return(
    <>
      <CustomInput id={fieldFullName}
        name={fieldFullName}
        inputProps={{defaultValue: staticProps.defaultValue ? staticProps.defaultValue: null, title: staticProps.inputType === inputTypes.number ? 'Number' : null, min: '0', autoComplete: 'off'}}
        // autoComplete={'new-password'}
        autoComplete={'off'}
        readOnly={isDisabled}
        disabled={isDisabled}
        {...formMethods.register(staticProps.field_name, {required: staticProps.field.required})}

        error={props.isError}
        {...formMethods.register(fieldFullName, {required: props.field.required})}
        onChange={onInputChange}
        onClick={
          async (e)=> {
            if(isDisabled || showSuggestions) return;

            setShowSuggestions(true);
            const data = await getData(e.target.value);
            setSuggestions(data);
          }}

        type={'text'}
        endAdornment={<InputAdornment position="end" sx={{right: '6px !important', transform: 'scale(0.8)', display:'flex'}}>
          {
            <Icon sx={{
              '& img': {
                filter: 'invert(81%) sepia(4%) saturate(598%) hue-rotate(202deg) brightness(92%) contrast(84%)',
              }
            }}>
              <img src={SearchIcon} height={20} width={20} alt={''}/>
            </Icon>
          }
        </InputAdornment>}
      />
      {showSuggestions && <Paper style={{
        zIndex: 10,
        position: 'static',
        left: 0,
        right: 0,
        maxHeight: '200px',
        overflowY: 'scroll'
      }} ref={suggestionRef}>
        <List>{renderSuggestions()}</List>
      </Paper>
      }
    </>
  );
};

CustomAutocomplete.propTypes = {
  autocompleteParams: PropTypes.shape({
    url: PropTypes.string.isRequired,
    getRequestParams: PropTypes.instanceOf(Object).isRequired,
    fill: PropTypes.arrayOf(PropTypes.shape({
      api_name: PropTypes.string.isRequired
    })),
    filter: PropTypes.func
  }),
  parent: PropTypes.string,
  field: PropTypes.shape({
    api_name: PropTypes.string.isRequired,
    required: PropTypes.bool,
  }).isRequired,
  viewMode: PropTypes.bool,
  isEditable: PropTypes.bool,
  isError: PropTypes.bool
};

CustomAutocomplete.defaultProps = {
  isEditable: false,
  isError: false,
  autocompleteParams: {
    filter: null,
  }

};

export default CustomAutocomplete;

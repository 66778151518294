import {ROLES} from '../constants/allConstants';
import ApiUtils from '../utils/apiUtils';
import {isContractor} from './utils';

const ContractorPermissionGate = ({user, rolesWithAccess})=> {
  if(!user || !rolesWithAccess || rolesWithAccess.length === 0) return false;
  const role = user?.role ?? undefined;
  return rolesWithAccess.includes(role);
};


export function NewPermissionGate({user, elementPermissions, children, companyStatus}) {

  const returnFalse = ()=> {
    if(children) return null;
    return false;
  };

  const returnTrue = ()=> {
    if(children) return children;
    return true;
  };

  const hasPermission = (userPolicies, elementPolicy)=> {
    //elementPolicy is array of policies
    if(!elementPolicy) {
      //access is not restricted
      return true;
    }
    if(Array.isArray(elementPolicy)) {
      return elementPolicy.some(policy=> userPolicies.includes(policy));
    } else {
      return userPolicies.includes(elementPolicy);
    }
  };

  if (ApiUtils.isMockMode()) {
    return returnTrue();
  }

  if(!user)  return returnFalse();

  const role = user?.role ?? undefined;
  const companyId = user?.companyId ?? undefined;

  if (!role) return returnFalse();

  if(isContractor(user.role) && elementPermissions?.contractorAccess) {
    //handle contractor permissions
    return ContractorPermissionGate({
      user,
      rolesWithAccess: elementPermissions?.contractorPermissions
    }) ? returnTrue() : returnFalse();

  } else if(isContractor(user.role) && !elementPermissions?.contractorAccess) {
    return returnFalse();
  }

  if(role === ROLES.SYSTEM_ADMIN) {
    return returnTrue();
  }

  const userPolicies = user?.policies ?? [];
  const elementPolicy = elementPermissions?.policyName ?? undefined;

  if(!elementPolicy) {
    //access is not restricted
    return returnTrue();
  }

  //check access for actions allowed by ipermit policies on impersonate mode only
  if(elementPermissions.impersonateOnly && companyId && userPolicies.includes(elementPolicy)) {
    return returnTrue();
  } else if(elementPermissions.impersonateOnly && (!companyId || !userPolicies.includes(elementPolicy))) {
    return returnFalse();
  }

  if(elementPermissions.hideWhenImpersonating && companyId) {
    //return false when user is impersonating
    return returnFalse();
  }

  if(hasPermission(userPolicies, elementPolicy)) {
    return returnTrue();
  } else {
    return returnFalse();
  }
}

import React, {useEffect, useState} from 'react';
import * as allConstants from '../../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../../styles/styleConstants';
import {useJob} from '../../../../../contextProviders/jobContext';
import ApiUtils from '../../../../../utils/apiUtils';
import {NoRecords} from '../../../../../pieces/noRecords';
import Loader from '../../../../misc/loader';
import {Box, TableCell, TableRow, Table, TableHead, TableBody} from '@mui/material';
import {Text} from '../../../../../pieces/text';

const InspectionCityInfo = () => {
  const {job} = useJob();
  const cityId = job?.[allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name]?.[allConstants.CITY_FIELDS.ID.api_name];
  const [city, setCity] = useState(null);

  useEffect(async ()=> {
    if(cityId) {
      const cityResponse = await ApiUtils.makeApiCall('GET', `cities/${cityId}`);
      if(cityResponse?.data && cityResponse.status === 'success') {
        setCity(cityResponse.data.city);
      }
    }
  },[]);

  if(!cityId) {
    return <NoRecords recordName={'City Assigned'}/>;
  } else if(!city) {
    return <Box position={'relative'} sx={{
      width: '100%',
      marginTop: '64px'
    }}>
      <Loader useOwnWrapper={true}/>
    </Box>;
  }

  const schedulingOptions = (() => {
    if(!city) return null;
    let ways = city?.[allConstants.CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name];
    if(!ways) return null;
    const options = [];
    //iter object entries
    for (const [key, value] of Object.entries(ways)) {
      if(key !== '_id') {
        options.push({col1: key, col2: value});
      }
    }
    return options;

  })();

  const getHeader = () => {
    const name =  city?.[allConstants.CITY_FIELDS.NAME.api_name];
    const hours = city?.[allConstants.CITY_FIELDS.HOURS.api_name];

    return (
      <Box display={'flex'} flexDirection={'row'}>
        {name && <Text text={name} sizePx={18} fontWeight={'bold'}/>}
        {hours && <Text text={` (${hours})`} sizePx={18} italic={true}/>}
      </Box>
    );
  };

  return(
    <>
      {getHeader()}
      {schedulingOptions && <Table size={'small'} sx={{
        '& .MuiTableCell-root': {
          textTransform: 'uppercase',
          fontSize: {xs: '12px', md: '12px', xl: '16px'},
        },
        '& .MuiTableHead-root': {
          '& .MuiTableCell-root': {
            color: STYLE_CONSTANTS.COLORS.globalBlue,
            fontWeight: '600',
          }
        },
        '& .MuiTableCell-root:nth-child(1)': {
          width: '200px',
          fontWeight: '600',

        },
        '& .MuiTableBody-root .MuiTableCell-root:nth-child(1)': {
          borderRight: schedulingOptions.length === 0 ? 'none' : `2px solid ${STYLE_CONSTANTS.COLORS.lightGrayHover}`,
        },
        '& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)': {
          backgroundColor: schedulingOptions.length === 0 ? 'initial' : STYLE_CONSTANTS.COLORS.chartBlue,

        }

      }}>
        <TableHead>
          <TableRow>
            <TableCell >{allConstants.CITY_FIELDS.SCHEDULE_INSPECTION_VIA.display_label}</TableCell>
            <TableCell>{allConstants.CITY_FIELDS.NOTE.api_name}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedulingOptions.map((option, index)=>{
            return <TableRow key={option.col1+index}>
              <TableCell >{option.col1}</TableCell>
              <TableCell>{option.col2}</TableCell>
            </TableRow>;
          })}
          {schedulingOptions.length === 0 && <TableRow >
            <TableCell colSpan={2}><NoRecords recordName={'Scheduling Options'} rowDirection={true}
              fontSize={{xs: '14px', xl: '16px'}}/></TableCell>
          </TableRow>
          }
        </TableBody>

      </Table>}


    </>

  );
};

export default InspectionCityInfo;

const SEER_OPTIONS = {
  '14': {display: '14', value: '14', showOrder: 0, def: false},
  '14.5': {display: '14.5', value: '14.5', showOrder: 1, def: false},
  '15': {display: '15', value: '15', showOrder: 2, def: false},
  '15.5': {display: '15.5', value: '15.5', showOrder: 3, def: false},
  '16': {display: '16', value: '16', showOrder: 4, def: false},
  '16.5': {display: '16.5', value: '16.5', showOrder: 5, def: false},
  '17': {display: '17', value: '17', showOrder: 6, def: false},
  '17.5': {display: '17.5', value: '17.5', showOrder: 7, def: false},
  '18': {display: '18', value: '18', showOrder: 8, def: false},
  '18.5': {display: '18.5', value: '18.5', showOrder: 9, def: false},
  '19': {display: '19', value: '19', showOrder: 10, def: false},
  '20': {display: '20', value: '20', showOrder: 11, def: false},
  '21': {display: '21', value: '21', showOrder: 12, def: false},
  '22': {display: '22', value: '22', showOrder: 13, def: false},
  '23': {display: '23', value: '23', showOrder: 14, def: false},
  '24': {display: '24', value: '24', showOrder: 15, def: false},
  '25': {display: '25', value: '25', showOrder: 16, def: false}
};

export {SEER_OPTIONS};
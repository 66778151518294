import React, {useState} from 'react';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Box} from '@mui/material';
import {Text} from '../pieces/text';
import FormSubmitButtons from '../pieces/formSubmitButtons';
import ModalWrapper from '../pieces/modals/modalWrapper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Spacer from '../pieces/spacer';
import {JobActionButtonsOutlined} from '../pieces/jobActionButtons';

export const SMS_TEMPLATE_NAMES = {
  ASK_HOMEOWNER_AVAILABILITY: 'ASK_HOMEOWNER_AVAILABILITY',
};

//Don't remove empty lines in templatesgit , change/add line breaks. They are used to format the text in the SMS
const smsFooter = `
      Thank you,  
      
      iPermit - Final Inspection Department
`;

//This is iPermit, the company that pulled your permit for your installation at (ADDRESS) performed by (CONTRACTOR). Now that all work has been completed, we need to schedule your final inspection with the city or county. Please reply with a few dates that work for your schedule. We will reply once we get confirmation that your inspection was scheduled.

const smsTemplates = {
  [SMS_TEMPLATE_NAMES.ASK_HOMEOWNER_AVAILABILITY]: ({address, jobNumber, company})=> {
    return `
  
    Hello,  This is iPermit, the company that pulled your permit for your installation at ${address} performed by ${company}. Now that all work has been completed, we need to schedule your final inspection with the city or county. Please reply with a few dates that work for your schedule. We will reply once we get confirmation that your inspection was scheduled.
      
    ${smsFooter}
    `;
  }
};

export const useGetSms = (props)=> {
  const dynamicData = props?.data || {};


  const getTemplate = ()=> {
    return smsTemplates[props?.templateName] ? smsTemplates[props?.templateName](dynamicData) : '';
  };

  const [showPopup, setShowPopup] = useState(false);
  const [textCopied, setTextCopied] = useState(false);

  const copyToClipboard = async (text)=> {
    await navigator.clipboard.writeText(text);
    setTextCopied(true);
  };

  return({
    ButtonGetSms: <JobActionButtonsOutlined action={()=> setShowPopup(true)} text={'Get sms'}/>,
    Popup: showPopup && <ModalWrapper modalName={'smsPopup'}
      open={true}
      preventScroll={true}
      width={{sx: '98%', md: '400px'}}
      maxWidth={'600px'}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Text text={getTemplate()}/>
        <Spacer y={2}/>
        <Box display={'flex'} justifyContent={'center'}>
          <Text
            text={textCopied ? 'Text copied to clipboard.' : ''}
            color={STYLE_CONSTANTS.COLORS.globalGreen}
            sizePx={16}
            fontWeight={600}
          />
        </Box>

        <FormSubmitButtons cancelAction={()=> setShowPopup(false)}
          submitAction={()=>copyToClipboard(getTemplate())}
          cancelText={'Close'}
          submitText={'Copy text'}
          submitIcon={<ContentCopyIcon/>}
        />
      </Box>

    </ModalWrapper>
  }

  );
};

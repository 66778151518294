import React, {useState, useRef, useEffect} from 'react';
import {Box, Paper} from '@mui/material';
import {AnalyticsHeader} from '../../../pieces/analytics/analyticsBaseCard';
import clsx from 'clsx';
import GettingDataProgress from '../../../pieces/gettingDataProgress';
import NoDataFound from '../../../pieces/noDataFound';
import {useAnalyticsStyles} from '../analyticsStyles';
import {awsUtils} from '../../../utils/awsUtils';
import {ANALYTICS_TARGETS} from '../../../constants/options/analyticsTargets';
import {globalUseClasses} from '../../../styles/globalClasses';
import PropTypes from 'prop-types';
import MonthYearPicker from '../../../pieces/monthYearPicker';
import AreaChart from '../../../pieces/analytics/areaChart';
import {CustomMediaQuery} from '../../../hooks/customMediaQuery';

const HersTestCompleted = (props)=> {
  const mediaQueries = CustomMediaQuery();
  const sessionDateKey = props.sessionKey + 'Date';
  const classes = {...globalUseClasses(), ...useAnalyticsStyles()};
  const [data, setData] = useState(sessionStorage.getItem(props.sessionKey) ? JSON.parse(sessionStorage.getItem(props.sessionKey)) : []);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);
  const [date, setDate] = useState(sessionStorage.getItem(sessionDateKey) ? new Date(sessionStorage.getItem(sessionDateKey)) : new Date());

  const months = useRef(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);

  const saveData = (fetchedData) => {
    if (fetchedData && Array.isArray(fetchedData)) {
      let formattedData;
      formattedData = Array(12).fill(0);
      fetchedData.forEach((month) => {
        formattedData[Number(month.status_month) - 1] = Number(month.total_count);
      });
      setData(formattedData);
      sessionStorage.setItem(props.sessionKey, JSON.stringify(formattedData));
    } else {
      setData([]);
    }
  };

  const fetchData = (fetchDate)=> {

    const fetchUrl = awsUtils.createUrl({
      type: ANALYTICS_TARGETS.HERS_COMPLETED_MONTHLY.api_request,
      company: props.company,
      params: {year: fetchDate.getFullYear(), status: 'completed'}
    });
    setFetching(true);

    fetch(`${fetchUrl}`)
      .then(response=> { return response.json();}).then(
        (result) => {
          saveData(result.queryResult);
          setFound(true);
          setFetching(false);
        },

        (error) => {
          console.log('error...', error);
          setFound(false);
          setFetching(false);
        }
      );
  };

  const saveYear = (newDate) => {
    setDate(newDate);
    sessionStorage.setItem(sessionDateKey, newDate.toISOString());
    fetchData(newDate);
  };

  useEffect(()=> {
    if (data.length === 0) {
      fetchData(date);
    }
  }, []);

  let content;
  if (fetching) {
    content = <GettingDataProgress/>;
  } else if (!found) {
    content = <NoDataFound/>;
  } else {
    content =
        <AreaChart x={months.current} y={data} dataKey={'completed'} blue={true} width={mediaQueries.isXL ? 570 : null}></AreaChart>;
  }

  return (
    <Paper elevation={0} className={clsx(classes.info_paper)} pb={3} sx={{width: mediaQueries.isXL ? '600px': '530px'}}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <AnalyticsHeader title={'HERS Test Completed'}/>
        <MonthYearPicker views={['year']} defaultDate={date} handler={(value) => {saveYear(value);}}/>
      </Box>
      {content}
    </Paper>
  );
};

HersTestCompleted.propTypes = {
  company: PropTypes.func.isRequired,
  sessionKey: PropTypes.string.isRequired,
};


export default HersTestCompleted;

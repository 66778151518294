import React from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Button, Box} from '@mui/material';
import Spacer from './spacer';

export const FloatingMenu = (props) => {

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({behavior: 'smooth', block: 'end'});
  };

  return (
    <Box sx={{
      width: '50px',
      // height: '100px',
      backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
      position: 'fixed',
      top: '60%',
      right: '0',
      zIndex: '1000',
    }}>
      {props.items && props.items.map((item)=> {
        return (
          <>
            <Spacer y={1}/>
            <Button onClick={()=>{
              if(item.ref) {
                scrollToRef(item.ref);
              } else if(item.onClick) {
                item.onClick();
              }

            }} key={item.name}
            sx ={{
              padding: '0',
              width: '100%',
              minWidth:'unset',
              '& svg': {
                width: '24px',
                color: STYLE_CONSTANTS.COLORS.white,
              }
            }}>
              {item.icon}
            </Button>
          </>

        );

      })}
      <Spacer y={1}/>
    </Box>
  );
};

FloatingMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    ref: PropTypes.any.isRequired,
    scroll
  })),
};

FloatingMenu.defaultProps = {
  items: null,
};

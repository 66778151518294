import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {useCreateJobStyles} from '../../createJobStyles';
import Utils from '../../../../../utils/utils';
import * as allConstants from '../../../../../constants/allConstants';
import {useFormContext, useWatch} from 'react-hook-form';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import CustomizedSwitch from '../../../../../pieces/switch/switch';
import clsx from 'clsx';

const MechPlumbing = (props)=> {
  const classes = useCreateJobStyles();
  const formController = useFormContext();
  const values = formController.getValues();
  const MECH_FIELDS  = allConstants.JOB_FIELDS.MECHANICAL;
  const EQUIPMENT = allConstants.EQUIPMENT;
  // const formController = useFormContext();
  // const draftHelper = useDraft({
  //   control: useFormContext(),
  //   parent: props.parent,
  //   target: MECH_FIELDS.PL_INFO.api_name,
  //   draft: props.draftData
  // });

  // const values = draftHelper.getDraftValues();

  // Thanh 12/02/23
  //console.log('props')
  //console.log(props);
  //console.log('values');
  //console.log(values);

  const blockParent = `${props.parent}.${allConstants.JOB_FIELDS.MECHANICAL.PL_INFO.api_name}`;
  const tanklessBlockParent = `${blockParent}.${EQUIPMENT.WATER_HEATER_TANKLESS.api_name}`;
  const traditionalBlockParent = `${blockParent}.${EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name}`;


  const needWh = useWatch({
    name: `${blockParent}.${MECH_FIELDS.NEED_WH.api_name}`,
    control: formController.control
  });

  const selectedType = useWatch({
    name: `${blockParent}.${MECH_FIELDS.WH_TYPE.api_name}`,
    control: formController.control
  });

  useEffect(()=> {
    if(Utils.equalsIgnoreCase(selectedType, 'tankless')) {
      formController.unregister(traditionalBlockParent);
    } else {
      formController.unregister(tanklessBlockParent);
    }
    // props.setWhType(selectedType);

  }, [selectedType]);

  // useEffect(()=> {
  //   if(!draftHelper.isFirstLoad) {
  //     console.log('setting wh ')
  //     const plInfo = values?.[allConstants.JOB_TYPES.HVAC_RES.api_name]?.[MECH_FIELDS.PL_INFO.api_name];
  //     const whType = plInfo?.[MECH_FIELDS.WH_TYPE.api_name];
  //     if(whType) {
  //       formController.setValue(`${blockParent}.${MECH_FIELDS.NEED_WH.api_name}`, true);
  //       formController.setValue(`${blockParent}.${MECH_FIELDS.WH_TYPE.api_name}`, whType);
  //     }
  //   }
  //
  
  // }, [draftHelper.isFirstLoad]);

  useEffect(()=> {
    if(!needWh) {
      formController.unregister(traditionalBlockParent);
      formController.unregister(tanklessBlockParent);
      formController.unregister(`${blockParent}.${MECH_FIELDS.WH_TYPE.api_name}`);
    }
  }, [needWh]);


  const commonProps = {
    ...props,...{
      nested: true,
      parent: blockParent,
      fullWidth: true
    }
  };

  const fields = {
    [EQUIPMENT.WATER_HEATER_TANKLESS.api_name]: [
      {
        field: {...allConstants.JOB_FIELDS.SHARED.ACTION, ...{label: 'Tankless Action'}},
        additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.CO_OR_NEW)},
        wrapperProps: {className: classes.action_col, mr: 2}
      },
      {
        field: allConstants.JOB_FIELDS.SHARED.LOCATION,
        additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.EQ_LOCATIONS.WATER_HEATER)},
        wrapperProps: {className: classes.action_col, mr: 2}
      },
      {
        field: MECH_FIELDS.GAS_LINE_FT,
        additionalProps: {inputType: inputTypes.number},
        wrapperProps: {className: classes.small_input_box}
      },
      {
        field: allConstants.JOB_FIELDS.SHARED.GAS_TO_METER,
        additionalProps: {inputType: inputTypes.checkbox, renderLabel: false},
        wrapperProps: {className: clsx(classes.gas_col, classes.eq_checkbox_input)},
      },
      {
        field: allConstants.JOB_FIELDS.SHARED.IS_ELECTRICAL,
        additionalProps: {inputType: inputTypes.checkbox, renderLabel: false},
        wrapperProps: {className: clsx(classes.electrical_col, classes.eq_checkbox_input)},
      },
    ],
    [EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name]: [
      {
        field: allConstants.JOB_FIELDS.PLUMBING.GALLONS,
        additionalProps: {inputType: inputTypes.number},
        wrapperProps: {className: classes.small_input_box, mr: 2}
      },
      // {
      //   field: {...allConstants.JOB_FIELDS.SHARED_REQ.ACTION, ...{label: 'Traditional Action'}},
      //   additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.CO_OR_NEW)},
      //   wrapperProps: {className: classes.action_col, mr: 2}
      // },
      // {
      //   field: allConstants.JOB_FIELDS.SHARED_REQ.LOCATION,
      //   additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.EQ_LOCATIONS.WATER_HEATER)},
      //   wrapperProps: {className: classes.action_col, mr: 2}
      // },
      {
        field: {...allConstants.JOB_FIELDS.SHARED.ACTION, ...{required: true}, ...{label: 'Traditional Action'}},
        additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.CO_OR_NEW)},
        wrapperProps: {className: classes.action_col, mr: 2}
      },
      {
        field: {...allConstants.JOB_FIELDS.SHARED.LOCATION, ...{required: true}},
        additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.EQ_LOCATIONS.WATER_HEATER)},
        wrapperProps: {className: classes.action_col, mr: 2}
      },
      {
        field: allConstants.JOB_FIELDS.PLUMBING.FUEL_TYPE,
        additionalProps: {inputType: inputTypes.singleSelect, options: Utils.getOptions(allConstants.FUEL_TYPES)},
        wrapperProps: {className: classes.action_col, mr: 2}
      },
      {
        field: allConstants.JOB_FIELDS.PLUMBING.ENERGY_FACTOR,
        additionalProps: {},
        wrapperProps: {className: classes.small_input_box}
      },
      {
        field: allConstants.JOB_FIELDS.PLUMBING.INS_R_VALUE,
        additionalProps: {},
        wrapperProps: {className: classes.small_input_box, sx:{width: '170px',
          '& .MuiInputBase-root': {
            width: 'fit-content'
          }
        }}
      },
      {
        field: allConstants.JOB_FIELDS.PLUMBING.BTU_INPUT,
        additionalProps: {},
        wrapperProps: {className: classes.small_input_box, sx:{width: '170px'}}
      },
      {
        field: MECH_FIELDS.GAS_LINE_FT,
        additionalProps: {inputType: inputTypes.number},
        wrapperProps: {className: classes.small_input_box}
      },
    ]
  };

  const getFieldValue = (field, whType)=> {
    if(whType) {
      return values?.[allConstants.JOB_TYPES.HVAC_RES.api_name]?.[MECH_FIELDS.PL_INFO.api_name]?.[whType]?.[field];
    }
    return values?.[allConstants.JOB_TYPES.HVAC_RES.api_name]?.[MECH_FIELDS.PL_INFO.api_name]?.[field];
  };
  //
  // if(draftHelper.isFirstLoad) return null;


  return (
    <Box className={classes.tab_container}>
      <Grid container>
        <Grid item xs={12}  display={'flex'} flexDirection={'row'} justifyContent={'left'} flexWrap={'wrap'}>
          <Box sx={{width: '200px',
            '& .MuiFormControlLabel-root': {
              marginLeft: 0,
              marginTop: '0 !important',
              height: '86px'
            }
          }}>
            <CustomFormBlock{...props}
              inputType={inputTypes.checkbox}
              renderLabel={false}
              labelLeft={true}
              field={MECH_FIELDS.GAS_LINE}
              nested={true}
              parent={blockParent}
              defaultValue={getFieldValue(MECH_FIELDS.GAS_LINE.api_name)}
            />
          </Box>

          <Box sx={{width: '200px',
            '& .MuiFormControlLabel-root': {
              marginLeft: '0',
              marginTop: '0 !important',
              height: '86px'
            }
          }}>
            <CustomizedSwitch label={MECH_FIELDS.NEED_WH.label}
              action={(val)=> formController.setValue(`${[blockParent]}.${[MECH_FIELDS.NEED_WH.api_name]}`, val, {shouldDirty: true})}
              defaultChecked={getFieldValue(MECH_FIELDS.NEED_WH.api_name)}
            /></Box>

          {needWh &&
        <Box sx={{width: '200px'}}  >
          <CustomFormBlock {...props}
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(allConstants.WH_OPTIONS, null)}
            field={MECH_FIELDS.WH_TYPE}
            nested={true}
            parent={blockParent}
            defaultValue={getFieldValue(MECH_FIELDS.WH_TYPE.api_name)}
            fullWidth/>
        </Box>}
        </Grid>
       
        {needWh && Utils.equalsIgnoreCase(selectedType, 'tankless') &&
        <Grid item xs={12} display={'flex'} flexWrap={'wrap'}>
          {fields[EQUIPMENT.WATER_HEATER_TANKLESS.api_name].map((field, index)=>{
            return <Box {...field.wrapperProps} key={field.field.api_name+index}>
              <CustomFormBlock {...commonProps}  {...field.additionalProps} parent={tanklessBlockParent}
                field={field.field}
                defaultValue={getFieldValue(field.field.api_name, EQUIPMENT.WATER_HEATER_TANKLESS.api_name)}
              />
            </Box>;
          })}
        </Grid>
        }
        {needWh && Utils.equalsIgnoreCase(selectedType, 'traditional') &&
        <>
          <Grid item xs={12} display={'flex'} flexWrap={'wrap'}>
            {fields[EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name].map((field, index)=>{
              return <Box {...field.wrapperProps} key={field.field.api_name+index}>
                <CustomFormBlock {...props} {...commonProps}  {...field.additionalProps} parent={traditionalBlockParent}
                  field={field.field}
                  defaultValue={getFieldValue(field.field.api_name, EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name)}
                />
              </Box>;
            })}
          </Grid>
        </>
        }
      </Grid>
    </Box>
  );
};

MechPlumbing.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default MechPlumbing;

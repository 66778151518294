export const CITY_TYPES = {
  ONLINE: {display: 'Online', value: 'Online', showOrder: 0, def: false},
  OTC: {display: 'OTC', value: 'OTC', showOrder: 1, def: false},
  OTC_APPOINTMENT: {display: 'OTC Appointment', value: 'OTC Appointment', showOrder: 2, def: false},
  BOTH: {display: 'Both', value: 'Both', showOrder: 3, def: false},
  SUBMIT_ONLINE_OTC_PICKUP: {display: 'Submit Online/Pickup OTC', value: 'Submit online pick up OTC', showOrder: 4, def: false},
};

export const CLOSURE_CODES = {
  F0: {display: 'F0', value: 'F0', showOrder: 0, def: false},
  F1: {display: 'F1', value: 'F1', showOrder: 1, def: false},
  F2: {display: 'F2', value: 'F2', showOrder: 2, def: false},
  F3: {display: 'F3', value: 'F3', showOrder: 3, def: false},
};
export const ONROOF = {
  ALLOWED: {display: 'Allowed', value: 'Allowed', showOrder: 0, def: false},
  NOT_ALLOWED: {display: 'Not Allowed', value: 'Not Allowed', showOrder: 1, def: false},
  DEPENDS: {display: 'Depends', value: 'Depends', showOrder: 2, def: false},
};

export const SCHEDULE_INSPECTION_OPTIONS = {
  CITY_PORTAL: {display: 'City Portal', value: 'cityPortal', showOrder: 0, def: false},
  EMAIL: {display: 'Email', value: 'email', showOrder: 1, def: false},
  PHONE: {display: 'Phone', value: 'phone', showOrder: 2, def: false},
  OTC: {display: 'OTC (over the counter)', value: 'otc', showOrder: 3, def: false},
};



import React from 'react';
import MenuItem from '@mui/material/MenuItem';

export const LinkWrapper=(props)=>{
  return (
    <MenuItem sx={{
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: 'initial'
      }
    }}>
      {props.children}
    </MenuItem>
  );
};

import React from 'react';
import {Box} from '@mui/material';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

const AnalyticsRowWrapper = (props)=> {
  return(
    <Box
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: STYLE_CONSTANTS.DEFAULT.ANALYTICS_CARD_HEIGHT
      }}>{props.children}</Box>
  );
};

export {AnalyticsRowWrapper};

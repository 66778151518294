import React  from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import {useDispatch} from 'react-redux';
import * as allConstants from '../../constants/allConstants';
import * as Yup from 'yup';
import {ERROR_MSG} from '../../constants/allConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import actions from '../../redux/actions';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {inputTypes} from '../../pieces/inputs/formInput';

export const CheersConnection = (props)=> {

  const {USER_FIELDS} = allConstants;

  const validationSchema = Yup.object().shape({
    [USER_FIELDS.CHEERS_USERNAME.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD),
    [USER_FIELDS.CHEERS_PASSWORD.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD)
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  });

  const dispatch = useDispatch();


  const handleSubmit = async (data) => {
    const result = await dispatch(actions.connectUserToCheers(data));
    if(result) {
      unwrapRequestResult({
        showSuccess: true,
        result: result,
        successMessage: 'You have successfully connected your ipermit account to cheers',
        id: 'connectUserToCheers',
        errorMessage: 'Failed connecting your ipermit account to cheers'
      });
    }
  };

  const inputDefaultProps = {
    viewMode: false,
    renderLabel: true,
    isEditable: true,
    preventCapitalize: true,
    fullWidth: true,
  };

  return(
    <>
      <Box display={'flex'} justifyContent={'center'}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)} method={'POST'}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <CustomFormBlock
                  {...inputDefaultProps}
                  field={USER_FIELDS.CHEERS_USERNAME}
                />

              </Grid>
              <Grid item xs={12} md={6}>
                <CustomFormBlock
                  {...inputDefaultProps}
                  inputType={inputTypes.password}
                  field={USER_FIELDS.CHEERS_PASSWORD}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons submitText={'Connect to Cheers'} hideCancel={true}/>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

CheersConnection.propTypes = {
  closeModal: PropTypes.func,


};

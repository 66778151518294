import React  from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../pieces/modals/modalWrapper';
import {Box, Grid, IconButton, LinearProgress, Typography} from '@mui/material';
import {Text} from '../../../../pieces/text';
import {COLORS} from '../../../../styles/styleConstants';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '../../../../static/images/delete-icon.png';
import FormSubmitButtons from '../../../../pieces/formSubmitButtons';

export const ConfirmDeleteFile = (props)=> {

  return (
    <ModalWrapper handleClose={props.onCancel}
      open={true}
      modalName={'delete-attachment'}
      preventScroll={true}
      width={{sx: '98%', sm: '600px'}}
      padding={6}
      customStyle={{paddingTop: 2}}
    >
      <Grid container alignItems={'baseline'} position={'relative'}>
        <Box position={'absolute'} top={5} right={0}>
          <IconButton
            sx={{backgroundColor:COLORS.backgroundMain}}
            onClick={props.onCancel}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Grid item xs={12} display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          textTransform={'uppercase'}
          fontSize={'16px'}
          mb={4}
        >
          <Box onDragStart={(e)=> e.preventDefault()}
            mb={2}

          >
            <img src={DeleteIcon} alt={'delete-icon.png'}/>
          </Box>
          <Typography variant={'body1'}
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: COLORS.globalRed,
              marginBottom: 2,
            }}
          >{`${props.attachment.fileName}`}</Typography>
          {props.deleteInProgress &&
          <Box sx={{
            minWidth: '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Box width={'100%'}>
              <LinearProgress/>
            </Box>

            <Text text={'Deleting file...'} sizePx={16}/>
          </Box>}
          {!props.deleteInProgress && <>
            <Typography variant={'body1'}
              sx={{lineHeight: '2em',  fontWeight: 600,}}>
            Are you sure you want to delete this file?
            </Typography>
            <Typography variant={'body1'}
              sx={{lineHeight: '2em',  fontWeight: 600,}}>
              {'This action can’t be undone.'}</Typography>
          </>}

        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'center'}>
            <FormSubmitButtons
              submitText={'Yes, delete file'}
              submitAction={props.submitAction}
              cancelAction={props.onCancel}
              applyDeleteStyles={true}
              revertColors={true}
              noOutlines={true}
              cancelText={'No, keep file'}/>
          </Box>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

ConfirmDeleteFile.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fileId: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func,
  submitAction: PropTypes.func,
  deleteInProgress: PropTypes.bool,
};

ConfirmDeleteFile.defaultProps = {
  onCancel: ()=> {console.log('[ConfirmDeleteFile]: onCancel not defined');},
  submitAction: ()=> {console.log('[ConfirmDeleteFile]: submitAction not defined');},
  deleteInProgress: false,
};

import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as allConstants from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';
import actions from '../../../../redux/actions';
import mechanicalUtils from '../mechanical/mechUtils';
import {useForm} from 'react-hook-form';
import {Box, Grid, Typography} from '@mui/material';
import TabsBlock from '../../../../pieces/tabsBlock';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import clsx from 'clsx';
import {useCreateJobStyles} from '../createJobStyles';
import EquipmentPanelWrapper from '../equipmentPanelWrapper';


const ElectricalEquipment = (props)=> {
  const dispatch = useDispatch();
  const classes = {...useCreateJobStyles()};
  const subforms = useSelector(state => state.subforms);
  const EL_FIELDS = allConstants.JOB_FIELDS.ELECTRICAL;
  const JOB_TYPES = allConstants.JOB_TYPES;
  const SHARED_FIELDS = allConstants.JOB_FIELDS.SHARED;

  useEffect(()=> {
    saveSubform(allConstants.JOB_TYPES.ELECTRICAL.api_name, formMethods);
  }, []);



  useEffect(()=> {
    return ()=> {
      const currValues = formMethods.getValues();
      dispatch(actions.addSubform({jobType: allConstants.JOB_TYPES.ELECTRICAL.api_name, subformName: 'el', subform: currValues}));
    };

  },[]);

  const canModifyEquipment = useRef(Utils.canModifyEquipment(props.draftData));

  const getFields = (fieldArr, extraClasses=clsx(classes.small_input_box))=> {
    return (
      fieldArr.map((f)=> {
        return {
          wrapperClass: f === allConstants.JOB_FIELDS.GENERAL.SCOPE ? '' : extraClasses,
          field: f,
          uniqueProps: f === allConstants.JOB_FIELDS.GENERAL.SCOPE ? {multiline: true, rowCount: 1} : {}
        };
      })
    );
  };

  const mapPanelsToEq = {
    [EL_FIELDS.BR_CIR.api_name]: [allConstants.EQUIPMENT.BR_CIR.api_name],
    [EL_FIELDS.SUB_PANELS.api_name]:[allConstants.EQUIPMENT.SUB_PANELS.api_name],
    [EL_FIELDS.EV_CAR.api_name]: [allConstants.EQUIPMENT.EV_CAR.api_name],
    [EL_FIELDS.DISC_BOX.api_name]: [allConstants.EQUIPMENT.DISC_BOX.api_name],
    [EL_FIELDS.MOTORS.api_name]: [allConstants.EQUIPMENT.MOTORS.api_name],
  };
  const fields = [
    EL_FIELDS.BR_CIR, EL_FIELDS.SUB_PANELS, EL_FIELDS.EV_CAR, EL_FIELDS.DISC_BOX, EL_FIELDS.MOTORS
  ];

  const brCirFields = [SHARED_FIELDS.QUANTITY, EL_FIELDS.VOLTS, EL_FIELDS.AMPS, allConstants.JOB_FIELDS.GENERAL.SCOPE];
  const subPanelsFeilds = [SHARED_FIELDS.QUANTITY, EL_FIELDS.AMPS, allConstants.JOB_FIELDS.GENERAL.SCOPE];
  const evCarFields = [EL_FIELDS.VOLTS, EL_FIELDS.AMPS, allConstants.JOB_FIELDS.GENERAL.SCOPE];
  const discBoxFields = [SHARED_FIELDS.QUANTITY,  EL_FIELDS.AMPS, allConstants.JOB_FIELDS.GENERAL.SCOPE];
  const motorsFields = [EL_FIELDS.KW, allConstants.JOB_FIELDS.GENERAL.SCOPE];

  const saveSubform = useMemo(()=> props.setEquipmentForm, []);

  const equipmentMap = {
    [allConstants.EQUIPMENT.BR_CIR.api_name]: {
      name: allConstants.EQUIPMENT.BR_CIR.api_name,
      fields: getFields(brCirFields)
    },
    [allConstants.EQUIPMENT.SUB_PANELS.api_name]: {
      name: allConstants.EQUIPMENT.SUB_PANELS.api_name,
      fields: getFields(subPanelsFeilds)
    },
    [allConstants.EQUIPMENT.EV_CAR.api_name]: {
      name: allConstants.EQUIPMENT.EV_CAR.api_name,
      fields: getFields(evCarFields)
    },
    [allConstants.EQUIPMENT.DISC_BOX.api_name]: {
      name: allConstants.EQUIPMENT.DISC_BOX.api_name,
      fields: getFields(discBoxFields)
    },
    [allConstants.EQUIPMENT.MOTORS.api_name]: {
      name: allConstants.EQUIPMENT.MOTORS.api_name,
      fields: getFields(motorsFields)
    }
  };
  const def = useMemo(()=>  mechanicalUtils.getSystemFormOptions(equipmentMap));

  const formOptions = {...def};

  const {updatedDefault} = mechanicalUtils.updateSystemDefaultValues('el', allConstants.SYSTEM_CONTENT.el, subforms,
    formOptions.defaultValues, JOB_TYPES.ELECTRICAL.api_name);

  formOptions.defaultValues = {...updatedDefault};

  const isEquipmentTabDisabled = (eqName)=> {
    if(canModifyEquipment.current) return false;
    try{
      const equipment = {...updatedDefault[eqName][0].fields};
      if(equipment.length > 1) return false;
      const eqValues = Object.values(equipment[0]).filter((v)=> v);
      return eqValues.length === 0;
    } catch(e){}
    return false;
  };


  const formMethods = useForm(formOptions);

  window.el_form = formMethods;
  const inputDefaultProps = mechanicalUtils.getSystemInputDefaultProps(formMethods);



  const content = fields.map((f)=> {
    return {
      name: f.api_name,
      label: f.label,
      isDisabled: isEquipmentTabDisabled(f.api_name),
      panel: ()=>  <EquipmentPanelWrapper {...props}
        formMethods={formMethods}
        inputs={Object.values(equipmentMap).map((piece)=> {
          return Object.values(piece.fields);
        })}
        eqName={f.api_name}
        addFields={def.defaultValues?.[mapPanelsToEq[f.api_name]][0]?.fields[0]}
        inputDefaultProps={inputDefaultProps}
        equipmentMap={equipmentMap}
        canModifyEquipment={props.canModifyEquipment}
      />
    };
  });

  return (
    <Grid item xs={12} md={10} sx={{maxWidth: '840px'}}>
      {!canModifyEquipment.current && <Box mt={2}>
        <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalRed}}>You can&apos;t change selected equipment for job submitted from Service Titan</Typography>
      </Box>}
      <TabsBlock content={content} {...inputDefaultProps}/>
    </Grid>
  );
};

ElectricalEquipment.propTypes = {
  draftData: PropTypes.instanceOf(Object),
  setEquipmentForm: PropTypes.func.isRequired,
  canModifyEquipment: PropTypes.bool
};

ElectricalEquipment.defaultProps = {
  canModifyEquipment: false,
};

export default ElectricalEquipment;

import React, {useEffect} from 'react';
import {ThemeProvider, Paper, Grid, Box, Typography} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import * as allConstants from '../../constants/allConstants';
import myTheme from '../../styles/myStyles';
import {globalUseClasses} from '../../styles/globalClasses';
import BlockedImage from '../../static/images/warningImage.png';
import {useForm, FormProvider} from 'react-hook-form';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {inputTypes} from '../../pieces/inputs/formInput';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ERROR_MSG} from '../../constants/allConstants';
import {useDispatch, useSelector} from 'react-redux';
import actions from '../../redux/actions';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';

const CheersConnectionRequiredPage = () => {
  const classes = globalUseClasses();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const {USER_FIELDS} = allConstants;

  const validationSchema = Yup.object().shape({
    [USER_FIELDS.CHEERS_USERNAME.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD),
    [USER_FIELDS.CHEERS_PASSWORD.api_name]: Yup.string().required(ERROR_MSG.REQUIRED_FIELD)
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  });

  const dispatch = useDispatch();

  useEffect(()=>{

    if(user[USER_FIELDS.HAS_CONNECTED_TO_CHEERS.api_name] || !user[USER_FIELDS.IS_CONNECT_TO_CHEERS_REQUESTED.api_name]) {
      navigate(allConstants.PATH.HOME);
    }
  }, [user]);


  const handleSubmit = async (data) => {
    const result = await dispatch(actions.connectUserToCheers(data));
    if(result) {
      // eslint-disable-next-line no-unused-vars
      const unwrapped = unwrapRequestResult({
        showSuccess: true,
        result: result,
        successMessage: 'You have successfully connected your ipermit account to cheers',
        id: 'connectUserToCheers',
        errorMessage: 'Failed connecting your ipermit account to cheers'
      });
      console.log('unwrapped', unwrapped);
    }
  };

  const inputDefaultProps = {
    viewMode: false,
    renderLabel: true,
    isEditable: true,
    preventCapitalize: true,
    fullWidth: true,
  };

  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          <Grid container xs={12} sx={{backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain}}>
            <Grid item xs={12} sm={10} md={6} lg={4} sx={{
              margin: '200px auto'
            }}>
              <Paper sx={{
                width: '100%',
                //height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px',
                borderRadius: '10px',

              }}>
                <img src={BlockedImage} alt={''}/>
                <Typography variant={'body1'}
                  align={'center'} style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    margin: '16px auto'
                  }}>
            Please connect your ipermit account to cheers before continue</Typography>
                <Box width={'100%'}>
                  <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(handleSubmit)} method={'POST'}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <CustomFormBlock
                            {...inputDefaultProps}
                            field={USER_FIELDS.CHEERS_USERNAME}
                          />

                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomFormBlock
                            {...inputDefaultProps}
                            inputType={inputTypes.password}
                            field={USER_FIELDS.CHEERS_PASSWORD}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'}>
                          <FormSubmitButtons submitText={'Connect to Cheers'} hideCancel={true}/>
                        </Box>
                      </Grid>
                    </form>
                  </FormProvider>

                </Box>


              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};


export default CheersConnectionRequiredPage;

import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel} from '@mui/material';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import * as allConstants from '../../../../constants/allConstants';
import AirHandler from '../../../../static/images/air_handler.png';
import Condenser from '../../../../static/images/condenser.png';
import Furnace from '../../../../static/images/furance.png';
import Gpu from '../../../../static/images/gas_package.png';
import HeatPumpCondenser from '../../../../static/images/heat_pump_condenser.png';
import Hpu from '../../../../static/images/heat_pump.png';
import IndoorCoil from '../../../../static/images/Coil.png';
import MsDucted from '../../../../static/images/mini_split_ducted.png';
import MsDuctless from '../../../../static/images/mini_split_ductless.png';
import DuctLeft from '../../../../static/images/R6_gray.png';
import DuctRight from '../../../../static/images/R8_gray.png';


const EquipmentSelector = (props)=> {
  const EQUIPMENT = allConstants.EQUIPMENT;
  const mapEquipmentToIcon = {
    [EQUIPMENT.FURNACE.api_name]: Furnace,
    [EQUIPMENT.AIR_HANDLER.api_name]: AirHandler,
    [EQUIPMENT.CONDENSER.api_name]: Condenser,
    [EQUIPMENT.GAS_PU.api_name]: Gpu,
    [EQUIPMENT.HEAT_PUMP_COND.api_name]: HeatPumpCondenser,
    [EQUIPMENT.HEAT_PUMP_PU.api_name]: Hpu,
    [EQUIPMENT.COIL.api_name]: IndoorCoil,
    [EQUIPMENT.MINI_SPLIT.api_name]: MsDuctless,
    [EQUIPMENT.MINI_SPLIT_DUCTED.api_name]: MsDucted,
    [EQUIPMENT.R6.api_name]: DuctLeft,
    [EQUIPMENT.R8.api_name]: DuctRight,
    [EQUIPMENT.R10.api_name]: DuctRight,
  };

  const imageTransformMap = {
    [EQUIPMENT.FURNACE.api_name]: 'scale(1.5) translateY(-16px)',
    [EQUIPMENT.AIR_HANDLER.api_name]: 'scale(1.6) translateY(-16px)',
    [EQUIPMENT.CONDENSER.api_name]: 'scale(1.4) translateY(-20px)',
    [EQUIPMENT.GAS_PU.api_name]: 'scale(1.6) translateY(-16px)',
    [EQUIPMENT.HEAT_PUMP_COND.api_name]: 'scale(1.2) translateY(-16px)',
    [EQUIPMENT.HEAT_PUMP_PU.api_name]: 'scale(1.2) translateY(-16px)',
    [EQUIPMENT.COIL.api_name]: 'scale(1.5) translateY(-16px)',
    [EQUIPMENT.MINI_SPLIT.api_name]: 'scale(1.2) translateY(-16px)',
    [EQUIPMENT.MINI_SPLIT_DUCTED.api_name]: 'scale(1.5) translateY(-16px)',
    [EQUIPMENT.R6.api_name]: 'scale(1.2) translateY(-16px)',
    [EQUIPMENT.R8.api_name]: 'scale(1.2) translateY(-16px)',
    [EQUIPMENT.R10.api_name]: 'scale(1.2) translateY(-16px)',
  };
  return  props.options.map((eq, index)=> {
    const eq_icon = mapEquipmentToIcon?.[eq.api_name];
    const disabled = !props.canModifyEquipment;
    return(
      <FormControlLabel key={eq.api_name + index}
        label={eq.display_name} sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          border: '1px solid #DBDDE2',
          borderRadius:'10px',
          boxSizing: 'border-box',
          height: '165px',
          minWidth: '195px',
          maxWidth: '195px',
          padding: '16px 5px 5px 5px',
          marginLeft: 0,
          backgroundColor: `${props.selected.includes(eq.api_name)? STYLE_CONSTANTS.COLORS.globalBlue : 'inherit'}`,
          '& .MuiTypography-root': {
            color: `${props.selected.includes(eq.api_name)? `${STYLE_CONSTANTS.COLORS.white} !important` : 'initial'}`,
          },
          '&:hover': {
            cursor: disabled ? 'not-allowed' : 'pointer',
            backgroundColor: `${props.selected.includes(eq.api_name)? STYLE_CONSTANTS.COLORS.globalBlue : disabled ? 'initial' : STYLE_CONSTANTS.COLORS.hoverColor}`,
            '& .MuiCheckbox-root': {
              backgroundColor: 'initial'
            }
          }
        }
        }
        control={<Checkbox disableRipple checked={props.selected.includes(eq.api_name)} disabled={disabled}
          icon={
            <img src={ eq_icon} alt={props.value} key={props.value} width="80px" height="auto"
              style={{minHeight: '60px', transform: imageTransformMap[eq.api_name]}} />}
          checkedIcon={
            <img src={eq_icon} alt={props.value} key={props.value} width="80px" height="auto" style={{
              transform: imageTransformMap[eq.api_name],
              minHeight: '60px',
              filter: 'brightness(0) invert(1)'
            }}/>
          }
          onChange={(e)=>props.handleChange(e, eq.api_name)} />}
      />);
  });
};

EquipmentSelector.propTypes = {
  options: PropTypes.instanceOf(Array),
  selected: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func
};

EquipmentSelector.defaultProps = {
  options: [],
  selected: [],
  handleChange: ()=> {console.log('[EquipmentSelector] handleChange is not provided.....');}
};

export default EquipmentSelector;



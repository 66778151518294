import React from 'react';
import PropTypes from 'prop-types';
import {TableCell, TableRow} from '@mui/material';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {makeStyles, withStyles} from '@mui/styles';
import {getStatusColor} from '../styles/styleUtils';

const useStyles = props=> makeStyles(theme=> {
  return{
    label_col: {
      paddingLeft: 0,
      fontWeight: '600',
      width: '150px',
    },
    status_col: {
      color: props.color,

    },
    date_col: {
      paddingRight: 0,
      width: '100px',
    },
  };
});
const StyledCell = withStyles({
  root: {
    borderBottom: 'none',
    paddingTop: 0,
    column: STYLE_CONSTANTS.COLORS.fontMain
  }
})(TableCell);

const StatusRow = (props)=> {
  const classes = useStyles({color: getStatusColor(props.status)})();
  const colon = props.header ? '': ':';
  return(
    <TableRow>
      <StyledCell className={classes.label_col}>{`${props.label} ${colon}`}</StyledCell>
      <StyledCell className={classes.status_col}>{props.status}</StyledCell>
      <StyledCell className={classes.date_col}>{props.date}</StyledCell>
    </TableRow>
  );
};

StatusRow.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  header: PropTypes.bool
};

StatusRow.defaultProps = {
  header: false,
};

export {StatusRow};

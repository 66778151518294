import {activateCompany, deactivateCompany} from './thunks/activateDeactivateCompany';
import {blockCompany, unblockCompany} from './thunks/blockUnblockCompany';
import {editCompany} from './thunks/editComapny';
import {inviteCompany} from './thunks/inviteCompany';
import {selfRegisterCompany} from './thunks/selfRegisterCompany';
import {acceptCompanyRegistration, rejectCompanyRegistration} from './thunks/verifyCompany';

const companyThunk = {
  activateCompany,
  deactivateCompany,
  blockCompany,
  unblockCompany,
  editCompany,
  inviteCompany,
  selfRegisterCompany,
  acceptCompanyRegistration,
  rejectCompanyRegistration,

};

export default companyThunk;

import userThunks from './user/userThunks';
import companyThunk from './companies/companyThunks';
import {subformActions} from './subforms/subformSlice';
import {clearState} from './generalActions';

const actions = {
  ...userThunks,
  ...companyThunk,
  ...subformActions,
  [clearState]: clearState
};
export default actions;
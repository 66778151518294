import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import * as allConstants from '../../../../constants/allConstants';
import {Box, Grid, Paper, Typography} from '@mui/material';
import EditFormButton from '../../../../pieces/editFormButton';
import {FormLabel, FormValue} from '../../../../pieces/displayJob/formLine';
import base_url from '../../../../baseUrls';
import ApiUtils from '../../../../utils/apiUtils';
import axios from 'axios';
import Utils from '../../../../utils/utils';
import {MapEditor} from '../../../../pieces/map/mapEditor';
import {useForm, FormProvider, useWatch, useFieldArray} from 'react-hook-form';
import {DistanceEditor} from '../../../../pieces/map/distanceEditor';
import FormSubmitButtons from '../../../../pieces/formSubmitButtons';
import {MapController} from '../../../../utils/mapUtils';
import Loader from '../../../misc/loader';
import {unwrapRequestResult} from '../../../../utils/unwrapRequestResult';
import CircularProgress from '@mui/material/CircularProgress';

const DistBlock = ({distances, index})=> {
  const distArr = Object.entries(distances);
  const getDistString = (obj)=> {
    return `${obj[allConstants.JOB_FIELDS.SHARED.FT.api_name] || '0'}' ${obj[allConstants.JOB_FIELDS.SHARED.INCH.api_name]|| '00'}"`;
  };

  return(
    <Box sx={{
      marginBottom: index === 1 ? '16px' : 'unset'
    }}>
      <Paper sx={{
        width: '300px',
        padding: '8px',
      }}>
        <Typography variant={'body1'} sx={{
          fontSize: {sx: '12px', md: '14px'},
          fontWeight: '600',
          marginBottom: '8px'
        }}>{`Mark #${index}`}</Typography>

        {distArr.map((row, index)=>{

          return(
            <Box display={'flex'} columnGap={2} key={`${index}${row[0]}`}>
              <Box width={'50px'}>
                <FormLabel label={row[0]}/>
              </Box>
              <Box>
                <FormValue value={getDistString(row[1])}/>
              </Box>

            </Box>
          );
        })}
      </Paper>
    </Box>
  );
};

DistBlock.propTypes= {
  distances: PropTypes.shape({
    [allConstants.JOB_FIELDS.SHARED.FT.api_name]: PropTypes.string.isRequired,
    [allConstants.JOB_FIELDS.SHARED.INCH.api_name]: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired

};

const MapImage = (props)=> {
  const [src, setSrc] = useState(null);
  const [fetching, setFetching] = useState(false);

  const fetch = async () => {

    let url = `${base_url.api}files/${props?.id}`;
    const authToken = await ApiUtils.getAccessToken();
    const data = await axios.get(url, {headers: {Authorization: authToken}, responseType: 'blob'});
    return data;
  };

  useEffect(()=> {
    setFetching(true);
    fetch()
      .then(response => {
        setFetching(false);
        const blob = new Blob([response.data], {type: 'blob'});
        var objectURL = URL.createObjectURL(blob);
        setSrc(objectURL);
      });
  }, []);

  if(fetching) {
    return <Box sx={{
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress />
    </Box>;
  }
  return <img src={src} alt={'map'}/>;
};

MapImage.propTypes = {
  id: PropTypes.string.isRequired
};

function MapTab(props) {
  const mapController = MapController();
  const [editMode, setEditMode] = useState(props?.map?.marks === undefined);
  const MECH_FIELDS  = allConstants.JOB_FIELDS.MECHANICAL;
  const marks = props?.[MECH_FIELDS.MAP.api_name]?.[MECH_FIELDS.MARKS.api_name];
  const [currMarks, setMarks] = useState(marks);
  const [marksCount, setMarksCount] = useState(marks ? marks.length : 1);
  const [distStrings, setDistString] = useState([]);
  // const defaultValues = useMemo(()=>props?.map?.marks || [], [props.map]);
  const [defaultValues, setDefaultValues] = useState(props?.map?.marks || []);
  const [isFetching, setFetching]=useState(false);
  const timer = React.createRef();
  const timestamp = React.createRef(new Date());
  const permitStatus = props?.[allConstants.JOB_FIELDS.PERMIT_STATUS.api_name];
  const [fileId, setFileId] = useState(props?.[allConstants.JOB_FIELDS.MECHANICAL.MAP.api_name]?.[allConstants.JOB_FIELDS.ATTACHMENT.FILE_ID.api_name]);

  const mapIsEditable = (permitStatus === allConstants.PERMIT_STATUSES.NEW.value ||
    permitStatus === allConstants.PERMIT_STATUSES.NEED_ATTENTION.value);

  // console.log('PS', permitStatus);
  // const { register, reset, handleSubmit } = useForm({
  //   defaultValues: useMemo(() => {
  //     return props.user;
  //   }, [props])
  // });
  const formMethods = useForm({
    defaultValues: {
      [`${MECH_FIELDS.MARKS.api_name}`]: defaultValues,
    }
  });
  window.dist = formMethods;

  const {remove} = useFieldArray({
    control: formMethods.control,
    name: `${MECH_FIELDS.MARKS.api_name}`
  });
  const watchMapDistances = useWatch({
    control: formMethods.control,
    name: `${MECH_FIELDS.MARKS.api_name}`
  });
  useEffect(()=> {
    return ()=> {
      if(timer && timer.current) {
        clearTimeout(timer.current);
      }
      timestamp.current = null;

    };
  },[]);

  // useEffect(()=> {
  //   setMarks(marks)
  // }, [marks])

  useEffect(()=>{
    if(!defaultValues && !Array.isArray(defaultValues)) return null;
    const newArr = [];
    defaultValues.forEach((mark, index)=> {
      newArr.push(mapController.getDistString(mark, index+1));
    });
    setDistString(newArr);
  }, []);

  useEffect(()=>{
    const dist = watchMapDistances;
    if(!dist) return null;
    const newArr = [];
    dist.forEach((mark, index)=> {
      newArr.push(mapController.getDistString(mark, index+1));
    });
    setDistString(newArr);
  }, [watchMapDistances, marksCount]);

  const editMapAction = ()=> {
    if(!mapIsEditable) return;
    setEditMode(!editMode);
    formMethods.reset();
  };

  const cancelEdit = ()=> {
    setEditMode(false);
    formMethods.reset();
  };

  const onSubmit= async ()=> {
    // setFetching(true)
    const data = {
      map:{},
      id: props.id
    };
    const mapFileId = await mapController.saveMapImage(()=>setFetching(true));

    if(mapFileId) {
      data.map.fileId = mapFileId;
    }
    const newMarks = Utils.formatMapDistances(formMethods.getValues()?.marks);
    data.map.marks = newMarks;
    // console.log('will submit data', data)
    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }
    const response = await fetch(
      `${base_url.api}jobs/edit`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(
          data,
        ),
      }
    );
    let submitted = await response.json();
    // console.log('response', submitted)

    // eslint-disable-next-line no-unused-vars
    timer.current = setTimeout(()=> {
      setFetching(false);
      setMarksCount(data.map?.[allConstants.JOB_FIELDS.MECHANICAL.MARKS.api_name].length);
      setMarks(data.map.marks);
      timestamp.current = new Date();
      unwrapRequestResult({
        showSuccess: true,
        result: submitted,
        successMessage: 'Map and distances are sucessfuly updated',
        id: 'map_update',
      });
      setFileId(mapFileId);
      setDefaultValues(newMarks);
      setEditMode(false);

      if(props.isMissingMap && submitted.status === 'success' && props.removeErrorAction) {
        props.removeErrorAction();
      }
    }, 2000);
  };

  const toggleMarkCount = ()=> {
    if(marksCount === 1) {
      setMarksCount(2);
    } else {
      setMarksCount(1);
      remove(1);
    }
  };

  //if(!marks) return null;
  if(isFetching) {
    return <Loader timeout={50}/>;
  }
  const showWarningMessage = ()=>{
    const jobNumber = props?.[allConstants.JOB_FIELDS.JOB_NUMBER.api_name] ? props[allConstants.JOB_FIELDS.JOB_NUMBER.api_name].toString() :'';
    if(jobNumber.indexOf('1') === 0) {
      return marksCount === 0;
    }
    return false;
  };

  return (
    <Box mt={2} sx={{
      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
      paddingRight: '16px',
      width: '100%'
    }}>
      <Box item sx={{
        width: '500px',
        'img': {
          width: 'inherit',
          height: '100%'
        },
        display: 'inline-block',
        float: 'left'
      }}>
        {!editMode && <MapImage
          timestamp={timestamp.current}
          id={fileId}
        />}
        {editMode &&  <Box sx={{paddingTop: '16px'}}><MapEditor mapRef={mapController.refs.mapRef}
          textRef={mapController.refs.textRef}
          distStrings={distStrings}
          marksCount={marksCount}
          address={Utils.createAddressString(props?.[allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name])}
          name={props?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.HO_NAME.api_name]}/>
        </Box> }
      </Box>
      <Box sx={{display: 'inline-block',
        width: 'calc(100% - 500px)',
      }}>
        <Grid container display={'flex'} flexDirection={'column'} paddingLeft={'36px'} >
          <Grid item xs={12}>
            <Box position={'relative'}>
              <Box sx={{
                position: 'absolute',
                right: 0,
              }}>
                <EditFormButton onClick={editMapAction} edit={!editMode} isEditable={mapIsEditable}/>
              </Box>
              {editMode &&   <Box sx={{paddingTop: '16px'}}><FormProvider {...formMethods} >
                <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST" >
                  <DistanceEditor marksCount={marksCount}
                    blockParent={'marks'}
                    toggleMarkCount={toggleMarkCount}
                    defaultValues={defaultValues}/>
                  <FormSubmitButtons cancelAction={cancelEdit} submitText={'Update'} cancelText={'Cancel'}/>
                </form>
              </FormProvider></Box>
              }
              {!editMode && showWarningMessage() && mapIsEditable && <Box sx={{paddingTop: '16px', maxWidth: '80%'}}>
                <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}
                  sx={{lineHeight: {xs:'initial', md:'36px'}}}
                >
                  Warning: Once you click on edit, the distances will no longer shown and you will have to fill them all in the fields.
                </Typography>

                {currMarks.map((mark, index)=> {
                  return (
                    <Box key={`mark${index}`}>
                      <DistBlock distances={mark} index={index+1} isEdit={editMode}/>
                    </Box>
                  );
                })}
              </Box>}


            </Box>
          </Grid>


        </Grid>
      </Box>
    </Box>

  );
}
MapTab.propTypes = {
  id: PropTypes.string.isRequired,
  map: PropTypes.shape({
    marks: PropTypes.arrayOf(PropTypes.shape({
      ft: PropTypes.string,
      inch: PropTypes.string
    }))
  }),
  isMissingMap: PropTypes.bool,
  removeErrorAction: PropTypes.func
};

MapTab.defaultProps = {
  map: {},
  isMissingMap: false,
  removeErrorAction: null,
};
export default MapTab;

import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {globalUseClasses} from '../../../../styles/globalClasses';
import clsx from 'clsx';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import {useFormContext} from 'react-hook-form';

export const Equipment = (props)=> {
  const classes = {...useCreateJobStyles(), ...globalUseClasses()};
  const FIELDS = allConstants.JOB_FIELDS.TEMPORARY_POWER;
  const subParent = `${allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name}`;
  const blockParent = `${props.parent}.${subParent}`;
  const formController = useFormContext();
  const values = formController.getValues()[allConstants.JOB_TYPES.TEMPORARY_POWER.api_name]?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name];

  const getFieldValue = (fieldName)=> {
    return values?.[fieldName];
  };

  return (
    <Grid container xs={12} justifyContent={'center'} columnGap={{md: 6, lg: 12}}>
      <Grid item xs={12} sm={6} md={4} lg={3} className={clsx(classes.general_col)}>
        <CustomFormBlock
          {...props}
          field={FIELDS.BARE_POLE_QUANTITY}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.BARE_POLE_QUANTITY.api_name)}
          renderLabel={true}
          inputType={inputTypes.number}
        />
        <CustomFormBlock
          {...props}
          field={FIELDS.TRAILER_HOOKUP_QUANTITY}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.TRAILER_HOOKUP_QUANTITY.api_name)}
          renderLabel={true}
          inputType={inputTypes.number}
        />
      </Grid>
      <Grid  item xs={12} sm={6} md={8} lg={9} className={clsx(classes.general_col)}  sx={{
        '& .MuiInputBase-root textarea': {
          width: '100%',
          maxWidth: '500px'
        }
      }}>
        <CustomFormBlock{...props}
          field={FIELDS.ADDITIONAL_EQUIPMENT}
          nested={true}
          parent={blockParent}
          defaultValue={getFieldValue(FIELDS.ADDITIONAL_EQUIPMENT.api_name)}
          renderLabel={true}
          fullWidth={true}
          multiline={true}
        />

      </Grid>
    </Grid>
  );
};
Equipment.propTypes = {
  parent: PropTypes.string.isRequired,
};

export default Equipment;

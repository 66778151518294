export const SCHEDULING_FIELDS = {
  START_DATE: {api_name: 'date', required: true, label: 'Start Date', display_label: 'Start Date'},
  START_TIME: {api_name: 'startTime', required: false, label: 'Start Time', display_label: 'Start Time'},
  END_TIME: {api_name: 'endTime', required: false, label: 'End Time', display_label: 'End Time'},
  NOTIFY_HO: {api_name: 'notifyHomeOwner', required: false, label: 'Notify Homeowner', display_label: 'Notify Homeowner'},
  NOTIFY_HO_EMAIL: {api_name: 'notifyByEmail', required: false, label: 'email', display_label: 'email'},
  NOTIFY_HO_PHONE: {api_name: 'notifyByPhoneNumber', required: false, label: 'text', display_label: 'text'},
  RATER_NAME: {api_name: 'name', required: false, label: 'Rater', display_label: 'Rater'},
  RATER_ID: {api_name: 'raterId', required: false, label: 'Rater ID', display_label: 'Rater ID'},
  COLLECT_AMOUNT: {api_name: 'collectAmount', required: false, label: 'Collect Amount', display_label: 'Collect Amount'},
  SCHEDULER_NOTES: {api_name: 'schedulerNotes', required: false, label: 'Scheduler Notes', display_label: 'Scheduler Notes'},
  LAST_SCHED_DATE:  {api_name: 'lastSchedDate', required: true, label: 'Last Sched Date', display_label: 'Last Sched Date'}, 
};

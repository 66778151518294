import React, {useState, useEffect} from 'react';
import {Box, Paper} from '@mui/material';
import {AnalyticsHeader} from '../../../pieces/analytics/analyticsBaseCard';
import clsx from 'clsx';
import GettingDataProgress from '../../../pieces/gettingDataProgress';
import NoDataFound from '../../../pieces/noDataFound';
import {useAnalyticsStyles} from '../analyticsStyles';
import {awsUtils} from '../../../utils/awsUtils';
import {ANALYTICS_TARGETS} from '../../../constants/options/analyticsTargets';
import {globalUseClasses} from '../../../styles/globalClasses';
import PropTypes from 'prop-types';
import MultipleBarChart from '../../../pieces/analytics/multipleBarChart';
import AnalyticsPageUtils from '../../../utils/analyticsPageUtils';
import {CustomMediaQuery} from '../../../hooks/customMediaQuery';

const AveragePermitCost = (props)=> {
  const mediaQueries = CustomMediaQuery();
  const classes = {...globalUseClasses(), ...useAnalyticsStyles()};
  const [data, setData] = useState(sessionStorage.getItem(props.sessionKey) ? JSON.parse(sessionStorage.getItem(props.sessionKey)) : []);
  const [fetching, setFetching] = useState(false);
  const [found, setFound] = useState(true);

  const fetchData = ()=> {
    const fetchUrl = awsUtils.createUrl({
      type: ANALYTICS_TARGETS.PERMIT_AVG_CITY_COST.api_request,
      company: props.company,
      params: {}
    });

    setFetching(true);

    fetch(`${fetchUrl}`)
      .then(response=> { return response.json(); }).then(
        (result) => {
          const data = AnalyticsPageUtils.prepareCostPerCityToDisplay(result.queryResult);
          setData(data);
          setFound(true);
          setFetching(false);
          sessionStorage.setItem(props.sessionKey, JSON.stringify(data));
        },
        (error) => {
          console.log('error...', error);
          setFound(false);
          setFetching(false);
        }
      );
  };

  useEffect(()=> {
    if (data.length === 0) {
      fetchData();
    }
  }, []);

  let content;
  if (fetching || data.length === 0) {
    content = <GettingDataProgress/>;
  } else if (!found) {
    content = <NoDataFound/>;
  } else {
    content =
        <MultipleBarChart
          width={mediaQueries.isXL ? 810 : null}
          y={data[1]} x={data[0]}
          blue={true} dataKey={'scheduled'}/>;
  }

  return (
    <Paper elevation={0} className={clsx(classes.info_paper)} sx={{width: mediaQueries.isXL ? '860px' : '720px'}}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <AnalyticsHeader title={'Average Permit Cost/City'}/>
      </Box>
      {content}
    </Paper>
  );
};

AveragePermitCost.propTypes = {
  company: PropTypes.func.isRequired,
  sessionKey: PropTypes.string,
};

export default AveragePermitCost;

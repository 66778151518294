const PRODUCT_CATEGORIES_OPTIONS = {
  SERVICES_AT_CITY: {display: 'Services At City', value: 'Services At City', showOrder: 0, def: false},
  PERMIT_FEES: {display: 'Permit Fees', value: 'Permit Fees', showOrder: 1, def: false},
  HERS_FEES: {display: 'HERS Fees', value: 'HERS Fees', showOrder: 2, def: false},
  SERVICES_AFTER_CITY: {display: 'Services After City', value: 'Services After City', showOrder: 3, def: false},
  TESTING: {display: 'Testing', value: 'Testing', showOrder: 4, def: false},
  DOCUMENTS: {display: 'Documents', value: 'Documents', showOrder: 5, def: false},
  OTHER: {display: 'Other', value: 'Other', showOrder: 6, def: false},
};

export {PRODUCT_CATEGORIES_OPTIONS};

import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {CustomInput} from './customInputComponent';
import {Controller} from 'react-hook-form';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import {IconButton, InputAdornment} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import fieldFormatter from '../../utils/fieldsToNormalize';
import {useFormContext} from 'react-hook-form';

export const CustomSelect = forwardRef((props, ref)=> {
  const formMethods = useFormContext();
  const [currentSelected, setCurrentSelected] = useState('');

  const getOptionsToRender = ()=> {
    const options = [];
    const currOptions = [...props.options];
    const additionalOption = verifyValueAndOptions();
    if(additionalOption) {
      currOptions.unshift(additionalOption);
    }
    currOptions.forEach((e, idx)=> {
      options.push(<MenuItem value={e.value} key={`${e.value}${idx}`}>{e.name}</MenuItem>);
    });
    return  options;
  };
  useEffect(()=>{
    setCurrentSelected(props.defaultValue);
  }, [props.viewMode]);


  const verifyValueAndOptions = ()=> {
    if(props.defaultValue) {
      const allowedValues = props.options.map((opt)=> opt.value);
      if(!allowedValues.includes(props.defaultValue)) {
        return {name: props.defaultValue, value: props.defaultValue};

      }
    }
    return undefined;
  };

  const renderOptions = getOptionsToRender();

  useEffect(()=> {
    if (!props.defaultValue) return;
    if(!currentSelected) {
      setCurrentSelected(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleSelect = (e)=> {
    setCurrentSelected(e.target.value);
    // formMethods.setValue(props.field_name, e.target.value ? fieldFormatter.getValueForDB(props.field.api_name, e.target.value) : undefined, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
  };

  useEffect(()=> {
    const currValue = formMethods.getValues(props.field_name);
    const normalizedValue = fieldFormatter.getValueForDB(props.field.api_name, currentSelected);
    //skip reset value to optimize performance of useFieldsArray subforms with multiple selects with default values
    //don't skip update if value expected to be transformed to boolean (Yes/No)
    if(!props.allowNone && (!currentSelected ||  (currValue === currentSelected && typeof normalizedValue !== 'boolean'))) return;
    formMethods.setValue(props.field_name, fieldFormatter.getValueForDB(props.field.api_name, normalizedValue), {
      shouldDirty: true, shouldTouch: true, shouldValidate: true
    });
  }, [currentSelected]);

  const {register, field_name} = props;
  useEffect(() => {
    register(field_name );
  }, [register, field_name]);


  const handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClick = (event)=> {
    event.preventDefault();
    event.stopPropagation();
    setCurrentSelected('');
    props.setValueToForm(props.field_name, '', {shouldDirty: true, shouldValidate: true});

  };

  const endAdornment = () => {
    if(!props.canClean) return null;
    if (currentSelected && currentSelected.length !== 0) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            color="secondary"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    return '';
  };

  return (
    <>
      <Controller key={currentSelected}
        name={props.field_name}
        control={props.control}
        defaultValue={props.defaultValue}
        {...props.register(props.field_name, {required: props.field.required})}
        render={() => (
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              // getContentAnchorEl: null,
            }}

            input={
              <CustomInput id={`${props.field_name}-input`}
                name={props.field_name.replace(/\./, '')}
                fullWidth={props.fullWidth}
                endAdornment={endAdornment()}
                error={props.hasError}
              />}
            onChange={handleSelect}
            disabled={!props.isEditable || props.viewMode}
            value={currentSelected}
            error={props.isError}>
            {renderOptions}

          </Select>
        )}/>
    </>
  );
});
CustomSelect.displayName = 'CustomSelect';
CustomSelect.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired,
    allowNone: PropTypes.bool,
  }
};
CustomSelect.defaultProps = {
  ...formBlockDefaultProps,
  ...{
    allowNone: false,
  }
};
export default CustomSelect;

import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AddButton = (props)=> {
  return(
    <Button variant={'contained'} color={'primary'}
      sx={{
        maxWidth: '30px',
        maxHeight: '30px',
        minWidth: '30px',
        minHeight: '30px',
        '& .MuiButton-startIcon': {
          margin: 0,
        }
      }}
      startIcon={<AddIcon />}
      onClick={props.action}
    />
  );
};

AddButton.propTypes = {
  action: PropTypes.func.isRequired,
};

export default AddButton;
import PropTypes from 'prop-types';
import React from 'react';
import {Area, AreaChart, Tooltip, XAxis, YAxis} from 'recharts';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Utils from '../../utils/utils';

const AreaChartPlot = (props)=> {
  const colorSchema = {
    area: props.blue ? STYLE_CONSTANTS.COLORS.chartBlue : STYLE_CONSTANTS.COLORS.chartGreen,
    line: props.blue ? STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.globalGreen,
  };
  const maxValue = Math.max(...props.y);


  const getDataToDisplay = ()=> {

    return props.x.map((m, i)=> {
      return {
        name: m,
        [props.dataKey]: props.y[i],

      };
    });
  };

  const data = getDataToDisplay();
  return (
    <AreaChart
      width={props.width ? props.width : 500}
      height={270}
      data={data}
      margin={{
        left: -35,
        right: 10,
        top: 10,
      }}
    >
      <XAxis dataKey="name" tickSize={0} dy={10} axisLine={false} fontSize={12} interval={0}
        tick={{fill: STYLE_CONSTANTS.COLORS.fontPale}}/>
      <YAxis tickSize={0} axisLine={false} tickCount={Utils.adjustTickCount(maxValue)} fontSize={12}
        tick={{fill: STYLE_CONSTANTS.COLORS.fontPale, dx: -3}}/>
      <Tooltip contentStyle={{border: `1px solid ${colorSchema.line}`, borderRadius: '6px'}}
        labelStyle={{color: STYLE_CONSTANTS.COLORS.fontDarkSecondary, fontWeight: '700'}}
        itemStyle={{color: colorSchema.line}}/>
      <Area type="monotone" dataKey={props.dataKey} stroke={colorSchema.line} fill={colorSchema.area} strokeWidth={2} margin={{right: 15, left: 15}}/>
    </AreaChart>
  );

};

AreaChartPlot.propTypes = {
  x: PropTypes.instanceOf(Array).isRequired,
  y:PropTypes.instanceOf(Array).isRequired,
  blue: PropTypes.bool,
  dataKey: PropTypes.string,
  width: PropTypes.string,

};
AreaChartPlot.defaultProps = {
  blue: false,
  dataKey: 'count',
  width: null,
};

export default React.memo(AreaChartPlot,
  (prevProps, nextProps)=> nextProps.y.length && prevProps.y.every((value, index) => value !== nextProps.y[index]));

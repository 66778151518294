import React, {useEffect} from 'react';
import {useSearchParams, Navigate} from 'react-router-dom';
import Utils from '../utils/utils';
import * as allConstants from '../constants/allConstants';
import LOCAL_STORAGE_KEYS from '../utils/localStorageKeys';

const ServerRedirect = () => {
  const REDIRECT_PATH = {
    userInvitation: allConstants.PATH.SIGN_UP,
    //todo: change redirect paths
    passwordRecovery: allConstants.PATH.SIGN_UP,
    ACCESS_TOKEN: allConstants.PATH.HOME
  };
  const [searchParams] = useSearchParams();

  useEffect(()=> {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.tokens);
  });

  const getRedirect = ()=> {

    const success = searchParams.get('success');
    const result = {path: undefined, success:''};
    if(!success) {
      return result;
    }
    const decoded = JSON.parse(Utils.decodeFromBase64(success));
    //console.log('decoded', decoded);
    if(!decoded) {
      return result;
    }
    if(decoded.redirectReason) {
      result.path = REDIRECT_PATH[decoded.redirectReason] ? REDIRECT_PATH[decoded.redirectReason] : allConstants.PATH.HOME;
      result.params = success ? success : '';
    }


    return result;
  };

  const {path, params} = getRedirect();
  if(path) {
    return <Navigate to={path+'?success='+params} replace />;
  }

  return (
    <div>
      <h5>server redirect failed</h5>
    </div>
  );
};
export default ServerRedirect ;
import {Box, Grid, Button, Typography} from '@mui/material';
import React from 'react';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import PropTypes from 'prop-types';
import axios from 'axios';
import base_url from '../../../../baseUrls';
import Utils from '../../../../utils/utils';
import ApiUtils from '../../../../utils/apiUtils';

function CityRequiredDocuments(props) {

  const getFile = (id)=> {
    const fetch = async () => {
      let url = `${base_url.api}files/${id}`;
      const authToken = await ApiUtils.getAccessToken();
      const data = await axios.get(url, {headers: {Authorization: authToken}, responseType: 'blob'});
      return data;
    };

    fetch()
      .then((response) => {
        const fileName = Utils.getContentDispositionFileName(response);
        const blob = new Blob([response.data], {type: 'blob'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log('Download file error: ', e);
        Utils.errorToastMessage('Please try again', 'get_files');
      });
  };

  return (
    <Grid item xl={12}>
      <Box sx={{display: 'flex', flexFlow: 'row', alignItems: 'center',}} gap={1}>
        <ReportGmailerrorredIcon color={'error'} sx={{fontSize: 28}}/>
        <Typography color={STYLE_CONSTANTS.COLORS.globalRed} sx={{fontSize: 20, fontWeight: 700, letterSpacing: '4px',}}>WARNING</Typography>
      </Box>
      <Typography color={STYLE_CONSTANTS.COLORS.globalRed} variant={'body1'} pb={2} sx={{letterSpacing: '1px',}}>THIS CITY SOMETIMES REQUIRES ADDITONAL DOCUMENTS FOR CERTAIN PERMITS, PLEASE DOWNLOAD AND COMPLETE THE ADDITIONALS FORMS AND ATTACH TO THE PERMIT REQUEST. THEY ARE</Typography>
      <Box display={'flex'} flexDirection={'column'} px={3} py={1} sx={{borderStyle: 'solid', borderColor: 'red', borderRadius: '8px', borderWidth: '2px', backgroundColor: 'white', alignItems: 'baseline'}}>
        {
          props.cityDocs.map((doc) => {
            return (
              <Box display={'flex'} gap={2} flexDirection={'row'} py={0.5} sx={{alignItems: 'center'}} key={doc.id}>
                <Box sx={{backgroundColor: 'black', width: '4px', height: '4px', borderRadius: '32px'}}></Box>
                <Button disableRipple sx={{padding: '0px', justifyContent: 'left'}} onClick={() => getFile(doc.fileId)}>{doc.name}</Button>
              </Box>
            );
          })
        }
      </Box>
    </Grid>
  );
}

CityRequiredDocuments.propTypes = {
  cityDocs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fileId: PropTypes.string,
    jobType: PropTypes.string,
  })),
};


export default CityRequiredDocuments;

import React, {useState, useCallback} from 'react';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import {NewPermissionGate} from '../../permissions/permissionGate';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../pieces/listPageHeader';
import usePagination from '../../hooks/usePagination';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {TAG_ELEMENTS, tagsPageRules} from '../../permissions/tagAccessControl';
import AllTagsGrid from './allTagsGrid';
import TagForm from './tagForm';
import TagModal from '../../pieces/modals/tagModal';
import ConfirmDelete from '../../pieces/modals/confirmDelete';
import base_url from '../../baseUrls';

const AllTagsView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();

  const [showAddTagPopup, setShowAddTagPopup] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const handleConfirmDeletePopupClose = () => setShowConfirmDeletePopup(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleAddTagOpen = useCallback(() => setShowAddTagPopup(true), []);
  const handleAddTagClose = () => setShowAddTagPopup(false);

  const [showEditTagPopup, setShowEditTagPopup] = useState(false);
  const handleEditTagClose = () => setShowEditTagPopup(false);


  const [selectedTag, setSelectedTag] = useState(undefined);


  const editTagHandler = (data)=> {

    console.log('edit tag handler');

    setSelectedTag(data);
    setShowEditTagPopup(true);
  };

  const editTagCancel = ()=> {
    setSelectedTag(undefined);
    setShowEditTagPopup(false);
  };

  const handleSuccess = ()=> {
    if(showAddTagPopup || showEditTagPopup || showConfirmDeletePopup) {
      window.location.reload();
    }
  };

  const deleteTagHandler = (data)=> {

    console.log('delete tag handler');
    
    setSelectedTag(data);
    setShowConfirmDeletePopup(true);
  };

  const getDeleteURL = ()=> {
    if(!selectedTag) return null;
    return `${base_url.api}tags/delete`;
  };

  const deleteCancelHandler = ()=> {
   
    setSelectedTag(undefined);
    setShowConfirmDeletePopup(false);
  };

  const headerComponent = <ListPageHeader header={'All Tags'}
    addHandler={handleAddTagOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {NewPermissionGate({
      user: viewController.user,
      elementPermissions: tagsPageRules[TAG_ELEMENTS.addTagButton],
    })}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>
            <TagModal open={showEditTagPopup}
              handleClose={handleEditTagClose}
              header={'Edit tag'}
              preventScroll={false}
            >
              <TagForm closeModal={handleEditTagClose}
                editMode={true}
                successAction={handleSuccess}
                tagData={selectedTag}/>
            </TagModal>

            <TagModal open={showAddTagPopup}
              handleClose={handleAddTagClose}
              header={'Create tag'}
              preventScroll={false}
            >
              <TagForm closeModal={handleAddTagClose}
                successAction={handleSuccess}
                editMode={false}/>
            </TagModal >

            <ConfirmDelete deleteUrl={getDeleteURL()}
              open={showConfirmDeletePopup}
              submitDeleteAction={handleConfirmDeletePopupClose}
              handleCancel={deleteCancelHandler}
              successAction={handleSuccess}
              idToDelete={selectedTag?.id}
              entityName={'tag'}
              recordName={selectedTag ? selectedTag.tagName : ''}
            />

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllTagsGrid 
                editTagHandler={editTagHandler}
                editTagCancel={editTagCancel}
                viewController={viewController}
                deleteTagHandler={deleteTagHandler}
                deleteTagCancel={deleteCancelHandler}

                // setBlocked={viewController.setBlocked}
                pagination={pagination}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
export default AllTagsView;

import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import {Box, Button, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {SystemEquipment} from './systemEquipment';
import actions from '../../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import mechanicalUtils from '../mechUtils';
import CancelSelectedEquipment from '../../../../../pieces/unselectAllEquipment';
import EquipmentSelector from '../equipmentSelector';
import ApiUtils from '../../../../../utils/apiUtils';
import * as STYLE_CONSTANTS from '../../../../../styles/styleConstants';
import Utils from '../../../../../utils/utils';
import {urlSearchParams} from '../../../../../baseUrls';

const SystemFormWrapper = (props)=> {
  const dispatch = useDispatch();
  const subforms = useSelector(state => state.subforms);
  const orderedOptions = props.options.map((f)=> f.api_name);
  const system = useMemo(()=> props.system, []);
  const saveSubform = useMemo(()=> props.setEquipmentForm, []);
  const def = useMemo(()=>  mechanicalUtils.getSystemFormOptions(props.equipmentMap, props.validationSchema));
  const draftId = Utils.getUrlParameter(urlSearchParams.draftId);

  const formOptions = {...def};

  const {initialSelected, updatedDefault} = mechanicalUtils.updateSystemDefaultValues(system, props.options, {...subforms}, formOptions.defaultValues);
  formOptions.defaultValues = {...formOptions.defaultValues, ...updatedDefault};

  let currentSelected = initialSelected;

  const mainFormEquipmentChecked = props.getSelectedEquipment(system);
  if(!draftId && props.canModifyEquipment && mainFormEquipmentChecked) {
    currentSelected = initialSelected.filter((el)=> mainFormEquipmentChecked.includes(el));
  }
  const [selected, setSelected] = useState(currentSelected);

  useEffect(()=> {
    props.saveSelectedEquipment(props.system, selected);
    if(props.setSelectedToParent){
      props.setSelectedToParent(selected);
    }
  }, [selected]);

  const formMethods = useForm(formOptions);
  // formMethods.trigger();

  useEffect(async ()=> {
    saveSubform(system, formMethods);
  }, [system]);

  useEffect(()=> {
    return ()=> {
      const currValues = formMethods.getValues();
      dispatch(actions.addSubform({jobType: allConstants.JOB_TYPES.HVAC_RES.api_name,
        subformName: props.system,
        subform: currValues
      }));
    };

  },[]);
  window[props.system] = formMethods;

  const handleChange = (e, name)=> {
    if(!props.canModifyEquipment) return;
    if(selected.filter((s)=> s).length === 0) {
      saveSubform(system, formMethods);
    }
    let newSelected = [...selected];
    if(e.target.checked) {
      newSelected[orderedOptions.indexOf(name)] = name;
    } else {
      formMethods.resetField(name, {defaultValue: [{...def.defaultValues[name][0]}]});
      newSelected[orderedOptions.indexOf(name)] = null;
    }
    setSelected(newSelected);
  };

  const cancelSelection = ()=> {
    props.resetSystemForm(props.system);
    setSelected(props.options.map((opt)=> {
      return null;
    }));
    formMethods.reset(def.defaultValues);
  };

  const inputDefaultProps = mechanicalUtils.getSystemInputDefaultProps(formMethods);
  // console.log('input def props', inputDefaultProps)

  const getEquipment = (name, index)=> {
    if(!name) return null;
    return <SystemEquipment
      errors={props.systemErrors?.[system]?.[name]}
      key={name+index}
      eqName={name}
      inputDefaultProps={inputDefaultProps}
      formMethods={formMethods}
      addFields={
        def.defaultValues?.[name][0]
      }
      fieldSet={props.equipmentMap[name]['fields']}
      canModifyEquipment={props.canModifyEquipment}
    />;
  };

  return(
    <>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} key={props.system}>
        <CancelSelectedEquipment cancelAction={cancelSelection}
          selected = {selected.filter((s)=> s).length !== 0} canModifyEquipment={props.canModifyEquipment}
        />

        {!props.canModifyEquipment && <Box mt={2}>
          <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalRed}}>You can&apos;t change selected equipment for job submitted from Service Titan</Typography>
        </Box>}


        {props.headerNote && props.headerNote}

        <Box display={'flex'} gap={3} flexWrap={'wrap'}>
          <EquipmentSelector handleChange={handleChange} selected={selected} options={props.options}
            canModifyEquipment={props.canModifyEquipment} />
        </Box>
        <Box>
          {selected.map((eq, index)=> getEquipment(eq, index))}
        </Box>
      </Box>
      {ApiUtils.isDevelopment() && <Box m={2} pt={3}>
        <Button variant="contained"
          color={'secondary'}
          onClick={async ()=> {
            await formMethods.trigger();
          }}
        >{`Show ${props.system} errors`}</Button>
      </Box>}
    </>
  );
};

SystemFormWrapper.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  system: PropTypes.string.isRequired,
  equipmentMap:PropTypes.instanceOf(Array).isRequired,
  validationSchema: PropTypes.any,
  setSelectedToParent: PropTypes.func,
  saveSelectedEquipment: PropTypes.func,
  getSelectedEquipment: PropTypes.func,
  headerNote: PropTypes.node,
  setEquipmentForm: PropTypes.func,
  resetSystemForm: PropTypes.func,
  canModifyEquipment: PropTypes.bool,
  systemErrors: PropTypes.instanceOf(Object) //object or undefined
};

SystemFormWrapper.defaultProps = {
  saveSelectedEquipment: ()=>{},
  getSelectedEquipment: ()=>{return [];},
  setSelectedToParent: null,
  validationSchema: undefined,
  headerNote: null,
  setEquipmentForm: ()=>{},
  resetSystemForm: ()=> {console.warn('resetSystemForm function is not passed');},
  systemErrors: {},
  canModifyEquipment: true
};
export default SystemFormWrapper;

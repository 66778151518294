import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import {cleanedServerState} from '../userInitialStates';

export const updateAdmin = createAsyncThunk(
  'users/updateAdmin',
  /**
   *
   * @param props
   * @param {string} props.newAdminUserId
   * @param {srting} props.companyId
   * @param thunkAPI
   * @return {Promise<RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown, {} extends {rejectedMeta: infer RejectedMeta} ? RejectedMeta : unknown>|*>}
   */
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}users/update-company-admin`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(props),
        }
      );
      let data = await response.json();
      //console.log('getCurrentUserData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateAdminReducer = {
  [updateAdmin.pending]: (state)=>{
    state.isFetching = true;
  },
  [updateAdmin.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...cleanedServerState};
    return newState;
  },
  [updateAdmin.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

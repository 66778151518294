import {useDispatch} from 'react-redux';
import Utils from './utils/utils';
import validator from './utils/validators/validator';
import store from './store';
import actions from './redux/actions';

const DevWindowBindings = ()=> {
  const dispatch = useDispatch();
  if(Utils.isDevEnv()) {
    window.validator = validator;
    window.store = store;
    window.actions = actions;
    window.dispatch = dispatch;
  }
  return null;
};

export default DevWindowBindings;

import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';

class SolarDisplayUtils {
  constructor() {
    this.solar_fields = allConstants.JOB_FIELDS.SOLAR;
  }
  getSolar(details, print=false){
    const solar_fields = [
      this.solar_fields.TOTAL_KW,
      this.solar_fields.PANELS,
      this.solar_fields.INVERTERS,
      this.solar_fields.MODULES,

    ];
    const solar_block = solar_fields.filter((e)=> Utils.valueIsSpecified(details?.[e.api_name])).map((e) => {
      return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e, print),details[e.api_name], print);
    });
    return solar_block;
  }


}

const solarDisplayUtils = new SolarDisplayUtils();
export {solarDisplayUtils};
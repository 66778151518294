import React from 'react';
import {Grid} from '@mui/material';
import Utils from '../../../../../utils/utils';
import * as allConstants from '../../../../../constants/allConstants';
import PropTypes from 'prop-types';
import {FormLabel, FormValue} from '../../../../../pieces/displayJob/formLine';
import {useJobDetailViewStyles} from '../../views/jobDetailViewStyles';
import {solarDisplayUtils} from './solarDisplayUtils';
import {jobDisplayUtils} from '../jobDisplayUtils';
import {CommercialSection} from '../commercialSection';


const SolarDetails = (props)=> {
  const {details} = props;
  const classes = useJobDetailViewStyles();

  const displaySolar = ()=> {
    const solarBlock = solarDisplayUtils.getSolar(details);
    if (!solarBlock) return null;
    const solarContent = jobDisplayUtils.getBlockComponentToDisplay(solarBlock);

    if (solarContent.length === 0) return null;
    return solarContent;
  };
  const getSolarScope = ()=> {
    let scope = details?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name];
    let scopeContent;
    if(scope && Utils.valueIsSpecified(scope)) {
      scopeContent = <>
        <FormLabel label={allConstants.JOB_FIELDS.GENERAL.SCOPE.display_label}/>
        <FormValue value={scope} />
      </>;

    }
    return scopeContent;
  };

  const content = displaySolar();
  const scopeContent = getSolarScope();
  const commercial = <CommercialSection details={details}/>;

  return(
    <>
      <Grid container xs={12}>
        {content &&
        <Grid item xs={12} md={4} columns={{xs: 4, sm: 8, md: 12}} className={classes.details_block}>
          {/*// <Grid item xs={12} md={4} columns={{xs: 4, sm: 8, md: 12}} sx={{marginBottom: {xs: '8px', sm: '16px',maxWidth: '340px !important'}}}>*/}
          {content}
        </Grid>
        }
        {scopeContent &&
        <Grid item xs={12} >
          {scopeContent}
        </Grid>
        }

      </Grid>
      <Grid container xs={12}>
        {commercial &&  commercial}
      </Grid>
    </>
  );
};
SolarDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
};

export {SolarDetails};

import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/system';

const DatesWrapper = (props)=> {
  return (
    <Box
      sx={{
        backgroundColor: props.color,
        borderRadius: '32px'
      }}
      alignContent={'center'}
      justifyContent={'space-around'}
      p={1}
      display={'flex'}
      flexDirection={'row'}
    >
      {props.children}
    </Box>
  );
};

DatesWrapper.propTypes = {
  color: PropTypes.string
};

DatesWrapper.defaultProps = {
  color: '#fff'
};

export {DatesWrapper};

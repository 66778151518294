import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import handleErrorMessage from '../../handleErrorMessage';

export const createPassword = createAsyncThunk(
  'users/change-password-recovery',
  async ({password, recoveryToken}, thunkAPI) => {
    try {
      const url = new URL(`${base_url.api}users/change-password-recovery`);

      const response = await fetch(
        url,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            newPassword: password,
            recoveryToken,
          }),
        }
      );
      //console.log('Response: ', response);
      let data = await response.json();

      if (data.status === 'success') {
        return {...data};
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createPasswordReducer = {
  [createPassword.fulfilled]: (state, {payload}) => {
    state.isFetching = false;
    state.isSuccess = true;
    state.resetPasswordEmailSent = false;
  },
  [createPassword.pending]: (state) => {
    state.isFetching = true;
  },
  [createPassword.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = handleErrorMessage(payload);
  },
};
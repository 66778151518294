import React from 'react';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {IconButton, Box, Chip} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import * as STYLES_CONSTANTS from '../../styles/styleConstants';
import * as allConstants from '../../constants/allConstants';
import DraftIcon from '../../static/images/draft2.svg';
import Spacer from '../spacer';


const MaximizableCellRenderer = props => {

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [showExpand, setShowExpand] = useState(false);

  const mouseEnteredCallback = () => {
    setShowExpand(true);
  };

  const mouseExitedCallback = () => {
    setShowExpand(false);
  };

  useEffect(() => {
    props.node.addEventListener('mouseEnter', mouseEnteredCallback);
    props.node.addEventListener('mouseLeave', mouseExitedCallback);
  }, []);

  useEffect(() => {
    return () => {
      props.node.removeEventListener('mouseLeave', mouseExitedCallback);
      props.node.removeEventListener('mouseEnter', mouseEnteredCallback);

    };
  }, []);

  const buttonClicked = () => {
    if(props.handleExpand) {
      props.handleExpand(props);
    }
  };

  const getValueToDisplay = () => {

    if(props.showInspectionResults) {
      //check if latest inspection falied and return with "F" span
      const {INSPECTION_FIELDS} = allConstants;
      const inspectionData = props.data?.[INSPECTION_FIELDS.INSPECTION.api_name];
      let latestTest = null;
      let latestResult = '';
      if (inspectionData) {
        const tests = inspectionData?.[INSPECTION_FIELDS.INSPECTION_RESULTS.api_name];
        if (tests && tests.length !== 0) {
          latestTest= tests[tests.length - 1];
          latestResult = latestTest[INSPECTION_FIELDS.RESULT.api_name];
        }
        if(latestResult && latestResult === 'Failed') {
          return (
            <Box display={'flex'} alignItems={'center'}>
              <span>{cellValue}</span>
              <Spacer x={1}/>
              <Chip label={'F'} variant={'contained'} sx={{
                height: '20px',
                fontSize: '12px',
                borderRadius: '2px',
                border: `1px solid ${STYLES_CONSTANTS.COLORS.warningRed}`,
                color: STYLES_CONSTANTS.COLORS.white,
                backgroundColor: STYLES_CONSTANTS.COLORS.warningRed,
                '& .MuiChip-label': {
                  padding: '0 6px'
                }
              }}/>
            </Box>

          );
        }
      }
    }
    return <span>{cellValue}</span>;
  };

  return (
    <>
      {getValueToDisplay()}
      {props.showWarning && <img src={DraftIcon} alt="" style={{width:'20px'}}/>}
      { showExpand && <IconButton onClick={buttonClicked} sx={{position:'absolute', right: '10px', top: '2px'
      }}>

        <OpenInFullIcon  fontSize="small" sx={{
          color: STYLES_CONSTANTS.COLORS.globalBlue,
        }}
        />
      </IconButton> }
    </>
  );
};

MaximizableCellRenderer.propTypes = {
  valueFormatted: PropTypes.string,
  value: PropTypes.number.isRequired,
  node: PropTypes.any,
  handleExpand: PropTypes.func,
  showWarning: PropTypes.bool,
  showInspectionResults: PropTypes.bool,
  data: PropTypes.instanceOf(Object)
};

MaximizableCellRenderer.defaultProps = {
  valueFormatted: '',
  node: null,
  handleExpand: null,
  showWarning: false,
  showInspectionResults: false,
  data: {}

};


export {MaximizableCellRenderer};

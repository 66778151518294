import * as allConstants from '../../../../../constants/allConstants';
import Utils from '../../../../../utils/utils';
import {jobDisplayUtils} from '../jobDisplayUtils';

class TempPowerDisplayUtils {
  constructor() {
    this.fields = allConstants.JOB_FIELDS.TEMPORARY_POWER;
  }
  getBuilderInfo = (details, print) => {
    const builder_fields = [
      this.fields.BUILDER_NAME,
      this.fields.BUILDER_COMPANY,
      allConstants.USER_FIELDS.EMAIL,
      allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER,
      allConstants.JOB_FIELDS.GENERAL.ALT_PHONE_NUMBER,
      allConstants.JOB_FIELDS.GENERAL.ADDRESS
    ];
    if(!details?.[this.fields.BUILDER_INFO.api_name]) return null;
    const builder_block = builder_fields.map((e) => {
      let value;
      if(e.api_name === this.fields.BUILDER_NAME.api_name) {
        const first = details[this.fields.BUILDER_INFO.api_name][allConstants.JOB_FIELDS.GENERAL.FIRST_NAME.api_name];
        const last = details[this.fields.BUILDER_INFO.api_name][allConstants.JOB_FIELDS.GENERAL.LAST_NAME.api_name];
        value = `${first} ${last}`.trim();
      } else if(e.api_name === allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name){
        value = Utils.createAddressString(details[this.fields.BUILDER_INFO.api_name][e.api_name]);
      } else {
        value = details[this.fields.BUILDER_INFO.api_name][e.api_name];
      }
      if (Utils.valueIsSpecified(value)) {
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e,print), value, print);
      }
      return undefined;
    });
    return builder_block.filter((el)=> el);
  }
  getMiscInfo = (details, print=false) => {
    const fields = [
      this.fields.JURISDICTION_CITY,
      this.fields.COUNTY,
      this.fields.BUILDING_USE,
      this.fields.BUILDING_PERMIT,
      this.fields.POWER_USAGE
    ];
    if(!details?.[this.fields.MISC_INFO.api_name]) return null;
    const block = fields.map((e) => {
      let value=details[this.fields.MISC_INFO.api_name][e.api_name];
      if (Utils.valueIsSpecified(value)) {
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e,print), value, print);
      }
      return undefined;
    });
    // console.log('builder_block', block);
    return block.filter((el)=> el);
  }
  getAdditionalEquipment = (details, print)=> {
    const fields = [
      this.fields.BARE_POLE_QUANTITY,
      this.fields.TRAILER_HOOKUP_QUANTITY,
    ];
    if(!details?.[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name]) return null;
    const block = fields.map((e) => {
      let value=details[allConstants.JOB_FIELDS.SHARED.EQUIPMENT.api_name][e.api_name];
      if (Utils.valueIsSpecified(value)) {
        return jobDisplayUtils.getRowOutput(jobDisplayUtils.getFormattedKey(e,print), value, print);
      }
      return undefined;
    });
    // console.log('builder_block', block);
    return block.filter((el)=> el);
  }
  getEquipment = (equipment, print = false) => {
    const EQ = allConstants.EQUIPMENT;
    const SHARED = allConstants.JOB_FIELDS.SHARED;
    const eq_count = {
      [EQ.TEMP_POWER_POLES.api_name]:0,
      [EQ.SUB_PANELS.api_name]: 0,
      [EQ.SUB_POLES.api_name]: 0,
      [EQ.SUB_DISTRO_PANELS.api_name]: 0,
      [EQ.TRANSFORMERS.api_name]: 0,
      [EQ.GENERATORS.api_name]: 0,
    };

    const subFieldSet = [
      SHARED.QUANTITY, allConstants.JOB_FIELDS.ELECTRICAL.AMPS, this.fields.CIRCUITS
    ];
    const transformerFieldSet = [
      SHARED.QUANTITY,
      this.fields.KVA
    ];

    const generatorsFieldSet = [
      SHARED.QUANTITY,
      allConstants.JOB_FIELDS.ELECTRICAL.KW
    ];

    const eq_ordered_fields = {
      [EQ.TEMP_POWER_POLES.api_name]: subFieldSet,
      [EQ.SUB_PANELS.api_name]: subFieldSet,
      [EQ.SUB_POLES.api_name]: subFieldSet,
      [EQ.SUB_DISTRO_PANELS.api_name]: subFieldSet,
      [EQ.TRANSFORMERS.api_name]: transformerFieldSet,
      [EQ.GENERATORS.api_name]: generatorsFieldSet,
    };

    if(!equipment) return null;

    return jobDisplayUtils.getEquipment({
      equipment: equipment,
      fieldOrder: eq_ordered_fields,
      count: eq_count,
      print: print
    });
  };
}

const tempPowerUtils = new TempPowerDisplayUtils();
export {tempPowerUtils};

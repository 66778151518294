import PATH from './routeConstants';

const HOME_PAGE_BUTTONS  = {
  JOBS: {text: 'Jobs', icon: 'list', type: 'small', classSuffix: 'jobs', path: PATH.JOBS},
  CREATE_JOBS: {text: 'Create jobs', icon: 'plus', type: 'large', classSuffix: 'create', path: PATH.CREATE_JOB},
  ANALYTICS: {text: 'Analytics', icon: 'piechart', type: 'large', classSuffix: 'analytics', path: PATH.ANALYTICS},
  USERS: {text: 'Users', icon: 'users', type: 'large', classSuffix: 'users', path: PATH.USERS},
  COMPANY: {text: 'Company', icon: 'briefcase', type: 'small', classSuffix: 'company', path: PATH.COMPANY},
  PRODUCTS: {text: 'Products', icon: 'products', type: 'small', classSuffix: 'products', path: PATH.PRODUCTS}
};

export default HOME_PAGE_BUTTONS;

import {Grid, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as allConstants from '../../../../constants/allConstants';
import {useGridViewState} from '../../../../hooks/useGridViewState';
import ColumnDefinition from '../../../../pieces/grids/columnGenerator';
import MyGrid from '../../../../pieces/grids/gridWrapper';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import Utils from '../../../../utils/utils';
import {DatesWrapper} from '../datesWrapper';

function PermitInfo(props) {
  // console.log('PI:', props);
  const PERMIT_FIELDS = allConstants.PERMIT_MODULE_FIELDS;

  const gridRef = useRef();
  const viewController = useGridViewState();
  const [rowData, setRowData] = useState();

  let columnDefs = useMemo(()=>{
    if(!rowData) return null;
    return [
      new ColumnDefinition({
        field: PERMIT_FIELDS.PERMIT_INFO,
        emptyHeader: true,
        width: 130,
        removeDefaultFilter: true,
        valueGetter: (params)=> {
          return rowData[params.node.rowIndex]?.name;
        },

      }),
      new ColumnDefinition({
        field: allConstants.PERMIT_MODULE_FIELDS.AMOUNT,
        removeDefaultFilter: true,
        valueGetter: (params)=> {
          if(!rowData[params.node.rowIndex]?.[PERMIT_FIELDS.AMOUNT.api_name]) return '-';
          return `$ ${rowData[params.node.rowIndex]?.[PERMIT_FIELDS.AMOUNT.api_name]}`;
        },

      }),
      new ColumnDefinition({
        field: allConstants.PERMIT_MODULE_FIELDS.CHECK_NUMBER,
        removeDefaultFilter: true,
        wrapText: true,
        valueGetter: (params)=> {
          return rowData[params.node.rowIndex]?.[PERMIT_FIELDS.CHECK_NUMBER.api_name]|| '-';
        },

      }),
      new ColumnDefinition({
        field: allConstants.PERMIT_MODULE_FIELDS.NUMBER,
        removeDefaultFilter: true,
        wrapText: true,
        valueGetter: (params)=> {
          return rowData[params.node.rowIndex]?.[PERMIT_FIELDS.NUMBER.api_name]|| '-';
        },

      }),
      new ColumnDefinition({
        field: allConstants.PERMIT_MODULE_FIELDS.DATE,
        removeDefaultFilter: true,
        wrapText: true,
        valueGetter: (params)=> {
          return Utils.formatIsoDateStringWithOptions(rowData[params.node.rowIndex]?.[PERMIT_FIELDS.DATE.api_name],
            {year: 'numeric', month: 'short', day: '2-digit'}) || '-';

        },

      }),
    ];
  }, [rowData]);
  const getData = (infoType)=> {
    const fields = [
      PERMIT_FIELDS.AMOUNT,
      PERMIT_FIELDS.CHECK_NUMBER,
      PERMIT_FIELDS.NUMBER,
      PERMIT_FIELDS.DATE
    ];
    const dataObj = {};
    fields.forEach((f)=> {
      let val = props?.[infoType]?.[f.api_name];
      if(!Utils.valueIsSpecified(val)) val = null;
      dataObj[f.api_name] = val;
    });
    return dataObj;
  };

  useEffect(()=>{
    const data = [
      {
        name: 'Permit',
        ...getData(PERMIT_FIELDS.PERMIT_INFO.api_name)
      },
      {
        name: 'License',
        ...getData(PERMIT_FIELDS.LICENSE_INFO.api_name)

      },
      {
        name: 'Misc',
        ...getData(PERMIT_FIELDS.MISC_INFO.api_name)
      }
    ];
    setRowData(data);
  }, []);

  const inspection = props?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name];


  return (
    <Grid container>
      <Grid item sx={{
        marginTop: '16px',
        marginBottom: '16px',
        minHeight: '1px',
        minWidth: '1px'
      }}>
        {Utils.valueIsSpecified(inspection) &&  <DatesWrapper color={STYLE_CONSTANTS.COLORS.lightGreen}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={2}
          >
            <Typography fontWeight={'bold'}>{allConstants.PERMIT_MODULE_FIELDS.INSPECTION.display_label}:</Typography>
            <Typography>{inspection}</Typography>
          </Box>
        </DatesWrapper>}
      </Grid>
      <Grid item xs={12} sx={{
        '& .ag-header-row': {
          backgroundColor: STYLE_CONSTANTS.COLORS.lightGreen
        },
        '.ag-header-cell-label': {
          justifyContent: 'center'
        },
        '.ag-cell:not(:first-child) .ag-cell-wrapper': {
          justifyContent: 'center'
        }

      }}>
        <MyGrid
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          registerGrid={viewController.setGridToParent}
        ></MyGrid>
      </Grid>
    </Grid>
  );
}

export default PermitInfo;

const ELEMENTS_PERMITS = {
  viewPermits: 'viewPermits',
  editPermitInfo: 'editPermitInfo',
};

const permitRules = Object.freeze({
  [ELEMENTS_PERMITS.viewPermits]: {
    policyName: undefined,
  },
  [ELEMENTS_PERMITS.editPermitInfo]: {
    policyName: undefined,
  }
});

export {permitRules, ELEMENTS_PERMITS};

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import {Map} from './map';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import {useCreateJobStyles} from '../../pages/jobs/createJob/createJobStyles';

const useStyles = makeStyles(()=> {
  return{
    textWrapper: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 600,
        color: 'black'
      }
    },
    bgBlack: {
      background: 'black',
      '& .MuiTypography-root': {
        color: 'white'
      }
    }

  };
});
const MapEditor = (props)=> {
  const classes = {...useCreateJobStyles(), ...useStyles()};
  return(
    <>
      <Box sx={{width: '500px', margin: 'auto'}}>
        <Typography variant={'body1'} sx={{fontWeight: 600,
          marginBottom: '8px',
          textTransform: 'uppercase',
          fontSize: '16px',
          color: 'red'
        }}>
          Please drag <span style={{color: 'black'}}>X</span> mark to indicate the position of the unit
        </Typography>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <Box ref={props.mapRef}>
          <Map id={'MyMap'}
            markersAmount={props.marksCount}
            address={props.address}
          />
          <Box ref={props.textRef} sx={{width: '500px'}} style={{visibility: 'hidden'}}>
            <Box id="owner-name" className={classes.textWrapper}>
              <Typography variant="subtitle1" sx={{fontWeight: 600}}>
                {props.name}
              </Typography>
            </Box>
            <Box id="address" className={classes.textWrapper}>
              <Typography variant="subtitle1">
                {props.address}
              </Typography>
            </Box>
            <Box id="distances1"  className={clsx(classes.textWrapper, classes.bgBlack)}>
              <Typography variant="subtitle1">
                {props.distStrings[0]}
              </Typography>
            </Box>
            {props.marksCount === 2 && props.distStrings.length > 1 && <Box id="distances2"  className={clsx(classes.textWrapper, classes.bgBlack)}>
              <Typography variant="subtitle1">
                {props.distStrings[1]}
              </Typography>
            </Box>}
          </Box>
        </Box>
      </Box>
    </>
  );
};

MapEditor.propTypes = {
  address: PropTypes.string.isRequired,
  marksCount: PropTypes.number.isRequired,
  distStrings: PropTypes.instanceOf(Array),
  name: PropTypes.string,
  mapRef: PropTypes.any,
  textRef: PropTypes.any,
};
MapEditor.defaultProps = {
  name: '',
  mapRef: null,
  textRef: null,
};

export {MapEditor};
